import React, { Component } from "react";
import { HTMLTable, Tag, AnchorButton, ControlGroup } from "@blueprintjs/core";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import { alchemyUrl } from "../../fns/util";
import _ from "lodash";

class Markdowner extends Component {
  renderers = {
    h1: ({ children, ...props }) => {
      return (
        <div>
          <Tag intent="primary" large>
            {children}
          </Tag>
        </div>
      );
    },
    h2: ({ children, ...props }) => {
      return (
        <div>
          <Tag intent="primary" large minimal>
            {children}
          </Tag>
        </div>
      );
    },
    h3: ({ children, ...props }) => {
      return (
        <div>
          <Tag intent="warning" large minimal>
            {children}
          </Tag>
        </div>
      );
    },
    table: ({ children, ...props }) => {
      return (
        <HTMLTable className="bp5-html-table" compact={true} striped={true}>
          {children}
        </HTMLTable>
      );
    },
    strong: ({ children, ...props }) => {
      if (this.props.isPplx) {
        return (
          <ControlGroup>
            <Tag intent="primary" multiline style={{ maxWidth: "29vw" }}>
              {children}
            </Tag>
            <AnchorButton
              icon="search"
              outlined
              intent="primary"
              href={`https://scholar.google.com/scholar?&q=${children}`}
              target="_blank"
            />
          </ControlGroup>
        );
      }
      return (
        <Tag intent="success" multiline>
          {children}
        </Tag>
      );
    },
    a: ({ children, href, ...props }) => {
      if (this.props.isAsst) {
        if (this.props.annotations && this.props.annotations.length) {
          let annotationSandboxLink = props.node.properties.href;
          let correctAnnotation = _.find(
            this.props.annotations,
            (x) => x.text === annotationSandboxLink
          );

          if (correctAnnotation) {
            return (
              <AnchorButton
                target="_blank"
                href={`${alchemyUrl}/assistant/GETFileContentOther/${correctAnnotation.file_path.file_id}`}
                icon="document-share"
                text={children}
              />
            );
          } else {
            return null;
          }
        }
      } else {
        return (
          <AnchorButton
            icon="document-share"
            outlined
            intent="primary"
            href={href}
            target="_blank"
            text={children}
          />
        );
      }
    },
    code: ({ children, inline, ...props }) => {
      if (inline) {
        return <code>{children}</code>;
      }
      return (
        <SyntaxHighlighter
          style={a11yDark}
          language={
            this.props.isPython
              ? "python"
              : props.className
              ? props.className.replace("language-", "")
              : "python"
          }
          showLineNumbers={true}
          wrapLongLines={true}
          customStyle={{
            fontSize: "small",
          }}
        >
          {children}
        </SyntaxHighlighter>
      );
    },
  };

  render() {
    return (
      <div
        style={{
          fontSize: "small",
        }}
        className="colfax"
      >
        <ReactMarkdown
          children={this.props.text}
          components={this.renderers}
          remarkPlugins={[remarkGfm]}
        />
      </div>
    );
  }
}

export default Markdowner;
