import { observable, decorate } from "mobx";
import { persist } from "mobx-persist";

export class ListStore {
  doctorList = [];
  siteList = [];
  panelList = [];
}

decorate(ListStore, {
  doctorList: [persist("list"), observable],
  siteList: [persist("list"), observable],
  panelList: [persist("list"), observable],
});
export default new ListStore();
