import {
  AnchorButton,
  Button,
  ControlGroup,
  EditableText,
  Tag,
} from "@blueprintjs/core";

import React from "react";
import { fixUrlPrefix, initials } from "../../fns/util";
import MutEx from "../../utils/MutEx";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import { inject, observer } from "mobx-react";

class InvestigationCard extends React.Component {
  state = {
    editingEye: false,
    editingSubtype: false,
    viewing: 0,
    touched: false,
    text: "",
    loading: false,
  };
  listOfEyes = ["Left Eye", "Right Eye", "Both Eyes"];
  renderNotDone = (data) => {
    let isDone = ["ACTIVATED", "UPLOADED", "PROCESSED"].includes(
      data.lifeCycle
    );
    let isProcessed = data.lifeCycle === "PROCESSED";
    let hosp = this.props.authStore.prefix;
    let link = `/files/${hosp}/opd/investigation-pdfs/${data.patient}/${data.visit}/${data._id}/report.pdf`;
    let aiLink = `/files/${hosp}/opd/investigation-pdfs/${data.patient}/${data.visit}/${data._id}/report_ai.pdf`;

    return (
      <div>
        <ControlGroup
          vertical
          style={{ marginTop: `${this.props.horizontal ? "0px" : "10px"}` }}
        >
          <ControlGroup style={{ maxWidth: "70vw" }}>
            {isDone ? (
              <Tag large minimal>
                {initials(data.eye)}
              </Tag>
            ) : (
              <>
                {this.props.templateEditing ? null : (
                  <MutEx
                    large={true}
                    minimal={false}
                    options={["RE", "BE", "LE"]}
                    onUpdate={(option) => {
                      let opt = {
                        RE: "Right Eye",
                        LE: "Left Eye",
                        BE: "Both Eyes",
                      }[option];
                      this.props.update(data._id, "eye", opt);
                    }}
                    selected={((x) => {
                      let lu = {
                        "Right Eye": "RE",
                        "Left Eye": "LE",
                        "Both Eyes": "BE",
                      };
                      return lu[x];
                    })(data.eye)}
                  />
                )}
              </>
            )}
            {data.packageParentLineItemId ? (
              <Tag minimal icon="box"></Tag>
            ) : null}
            {isDone ? (
              <Tag
                large
                intent="primary"
                minimal
                interactive
                onClick={() => this.props.openInvDrawer()}
              >
                {data.type}
              </Tag>
            ) : (
              <Tag
                large
                intent="primary"
                minimal
                onRemove={() => {
                  this.props.remove(data._id);
                }}
              >
                {this.props.templateEditing ? data.service : data.type}
              </Tag>
            )}

            {this.state.editingSubtype ? (
              <SingleSelectWrapper
                items={data.listOfSubTypes}
                onChange={(item) => {
                  this.props.update(data._id, "subtype", item);
                  this.setState({ editingSubtype: false });
                }}
              />
            ) : (
              <Tag
                minimal
                large
                interactive={!isDone}
                onClick={() => {
                  if (isDone) {
                  } else {
                    this.setState({ editingSubtype: true });
                  }
                }}
              >
                {data.subtype}
              </Tag>
            )}
            {data.type === "Biometry" ? (
              <>
                {" "}
                {isDone ? (
                  <ControlGroup>
                    <Tag minimal>
                      Target Refraction: {data.biometryTargetRefn.toFixed(2)}
                    </Tag>
                  </ControlGroup>
                ) : (
                  <ControlGroup>
                    <Tag minimal>Target Refraction</Tag>
                    <MutEx
                      options={["0.50", "0.00", "-0.50", "-1.00", "-1.50"]}
                      selected={data.biometryTargetRefn.toFixed(2)}
                      onUpdate={(item) =>
                        this.props.update(
                          data._id,
                          "biometryTargetRefn",
                          parseFloat(item)
                        )
                      }
                    />
                  </ControlGroup>
                )}
              </>
            ) : null}
            {isProcessed ? (
              <ControlGroup>
                <Button
                  minimal
                  icon="refresh"
                  disabled={this.props.invLinkDisable}
                  onClick={() => {
                    this.props.genPdf(data._id);
                  }}
                />
                <AnchorButton
                  minimal
                  loading={
                    this.props.invLinkDisable
                      ? true
                      : data.pdfKey
                      ? false
                      : true
                  }
                  icon="document-share"
                  href={fixUrlPrefix(link)}
                  target="_blank"
                />
              </ControlGroup>
            ) : null}
            {isProcessed && data.type === "DFP" ? (
              <AnchorButton
                loading={
                  this.props.invLinkDisable ? true : data.pdfKey ? false : true
                }
                href={fixUrlPrefix(aiLink)}
                target="_blank"
                minimal
                icon="predictive-analysis"
              />
            ) : null}
            {isProcessed ? (
              <Tag minimal large multiline>
                {data.summary}
              </Tag>
            ) : null}
          </ControlGroup>
          {this.props.templateEditing ? null : (
            <ControlGroup>
              <EditableText
                placeholder="Enter comments here."
                value={this.state.touched ? this.state.text : data.notes}
                onChange={(string) =>
                  this.setState({ text: string, touched: true })
                }
                onConfirm={() =>
                  this.props.update(
                    data._id,
                    "notes",
                    this.state.touched ? this.state.text : data.notes
                  )
                }
              />
            </ControlGroup>
          )}
        </ControlGroup>
      </div>
    );
  };

  render() {
    let style = {};
    if (!this.props.horizontal) {
      style = {
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
      };
    }
    return <div style={style}>{this.renderNotDone(this.props.data)}</div>;
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(InvestigationCard));
