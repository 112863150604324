import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  InputGroup,
  NumericInput,
  Tag,
  HTMLSelect,
} from "@blueprintjs/core";

import cogoToast from "cogo-toast";
import React from "react";

import _ from "lodash";

class CaptureReimbursement extends React.Component {
  state = {
    amount: 0,
    selectedItemId: null,
    claimId: null,
  };

  componentDidMount() {
    this.setState({ amount: this.props.max / 100 });
  }

  addPayment = () => {
    if (!this.state.amount) {
      cogoToast.error("Please enter a valid amount!", {
        position: "bottom-left",
      });
      return;
    }
    if (this.props.sites.length >= 2 && !this.state.site) {
      cogoToast.error("Please enter a valid site!", {
        position: "bottom-left",
      });
      return;
    }
    if (!this.state.mode) {
      cogoToast.error("Please enter a valid payment mode!", {
        position: "bottom-left",
      });
      return;
    }
    if (this.state.mode === "UPI" && !this.state.phone) {
      cogoToast.error("Please enter a valid phone number!", {
        position: "bottom-left",
      });
      return;
    }
    if (this.state.mode === "PayTM" && !this.state.phone) {
      cogoToast.error("Please enter a valid phone number!", {
        position: "bottom-left",
      });
      return;
    }
    if (this.state.mode === "Card" && !this.state.last4) {
      cogoToast.error("Please enter the last 4 digits of the card!", {
        position: "bottom-left",
      });
      return;
    }
    if (this.state.mode === "Card" && !this.state.cardName) {
      cogoToast.error("Please enter the name of the cardholder!", {
        position: "bottom-left",
      });
      return;
    }
    if (this.state.mode === "Cheque" && !this.state.chqNo) {
      cogoToast.error("Please enter the cheque number!", {
        position: "bottom-left",
      });
      return;
    }
    if (this.state.mode === "Cheque" && !this.state.chqDate) {
      cogoToast.error("Please enter the date of the cheque!", {
        position: "bottom-left",
      });
      return;
    }
    let reference = {};
    if (this.state.mode === "Card") {
      reference["last4"] = this.state.last4;
      reference["cardName"] = this.state.cardName;
    }
    if (this.state.mode === "Cheque") {
      reference["chqNo"] = this.state.chqNo;
      reference["chqDate"] = this.state.chqDate;
    }
    if (this.state.mode === "UPI" || this.state.mode === "PayTM") {
      reference["phone"] = this.state.phone;
    }

    let payload = {
      amountPaise: this.state.amount * 100,
      reference: reference,
      channel: this.state.mode,
      site: this.state.site || this.props.sites[0],
    };
    this.props.onPayment(payload);
  };
  renderAction = () => {
    if (!this.state.selectedItemId) {
      return null;
    }
    if (!this.state.amount) {
      return null;
    }
    if (!this.state.claimId) {
      return null;
    }
    return (
      <Button
        rightIcon="arrow-right"
        intent="success"
        text="Confirm Reimbursement Receipt"
        onClick={() =>
          this.props.recordReimbursement(
            this.state.selectedItemId,
            this.state.amount,
            this.state.claimId
          )
        }
      />
    );
  };

  renderItemSelector = () => {
    let items = [{ label: "Select", value: null }];
    _.map(this.props.items, (each) => {
      if (each.activated && each.payment.cashless) {
        let str = "";
        if (each.kind === "consultation") {
          str = "Consultation: " + each.resource;
        }
        if (each.kind === "investigation") {
          str =
            each.resource + //OCT
            " " +
            each.investigationDetail.specificKind + // RNFL
            " " +
            each.investigationDetail.applicable; // Both Eyes
        }
        str = str + " | " + each.payment.panel;
        items.push({ label: str, value: each._id });
      }
    });
    return (
      <HTMLSelect
        options={items}
        onChange={(event) => {
          this.setState({ selectedItemId: event.currentTarget.value });
        }}
      />
    );
  };
  render() {
    return (
      <Card
        className="bp5-dark"
        style={{
          backgroundColor: "#464B53",
          margin: "10px",
          textAlign: "left",
        }}
        elevation={Elevation.THREE}
      >
        <ControlGroup>
          <Tag large intent="primary">
            Capture Reimbursement
          </Tag>
          <Tag large minimal>
            Item
          </Tag>
          {this.renderItemSelector()}
          <Tag large minimal>
            Amount
          </Tag>
          <NumericInput
            buttonPosition="none"
            minorStepSize={0.01}
            min={0}
            clampValueOnBlur
            intent="primary"
            onValueChange={(value) => this.setState({ amount: value })}
          />
          <Tag large minimal>
            Claim Reference
          </Tag>
          <InputGroup
            onChange={(event) => this.setState({ claimId: event.target.value })}
          />
          {this.renderAction()}
        </ControlGroup>
      </Card>
    );
  }
}

export default CaptureReimbursement;
