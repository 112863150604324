import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { DateRangeInput } from "@blueprintjs/datetime";
import React from "react";
import { apiCallPostProgress, niceDate } from "../fns/util";
import GPTPrompter from "./GPTPrompter";
import CanonicalSearcher from "./CanonicalSearcher";
import { inject, observer } from "mobx-react";
import { apiCallPost } from "../fns/util";
import cogoToast from "cogo-toast";
import _ from "lodash";
import FilesEntity from "./FilesEntity";

class FilesSection extends React.Component {
  state = {
    dateRange: [new Date(), new Date()],
    selectedInputMode: "",
    numSearches: 0,
    files: [],
    isLoadingFiles: false,
    updating: [],
  };
  componentDidMount() {
    this.getByDateRange();
  }
  /** HANDLERS */
  createNew = async (payload) => {
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/extra/createInvestigationFile`,
        payload
      );
      this.setState({
        files: [...this.state.files, res],
      });
      cogoToast.success("Created new file.");
    } catch (err) {
      console.log(err);
      cogoToast.error("Error creating file.");
    }
  };
  getByDateRange = async () => {
    if (!this.props.data.patientId) return;
    if (!this.state.dateRange[0] || !this.state.dateRange[1]) return;
    this.setState({
      isLoadingFiles: true,
    });
    let payload = {
      patientId: this.props.data.patientId,
      fromDate: this.state.dateRange[0],
      toDate: this.state.dateRange[1],
    };
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/extra/getInvestigationFilesInDateRange`,
        payload
      );
      this.setState(
        {
          files: res,
        },
        () => this.getByVisitId()
      );
    } catch (err) {
      this.setState({
        isLoadingFiles: false,
      });
      console.log(err);
      cogoToast.error("Error getting files.");
    }
  };
  getByVisitId = async () => {
    if (!this.props.data.visitId) return;
    let payload = {
      patientId: this.props.data.patientId,
      visitId: this.props.data.visitId,
    };
    this.setState({
      isLoadingFiles: true,
    });
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/extra/getInvestigationFilesByAssocVisit`,
        payload
      );
      // deduplicate res with this.state.files by _id
      let files = this.state.files;
      res.forEach((each) => {
        let idx = _.findIndex(files, { _id: each._id });
        if (idx === -1) {
          files.push(each);
        }
      });
      this.setState(
        {
          files: files,
          isLoadingFiles: false,
          updating: files.map((each) => false),
        },
        () => this.props.onFiles(this.state.files)
      );
    } catch (err) {
      console.log(err);
      cogoToast.error("Error getting files.");
    }
  };
  onUpdate = async (data, x, index) => {
    let payload = {
      ...data,
      fileId: x._id,
      patientId: this.props.data.patientId,
    };
    try {
      let updating = this.state.updating;
      updating[index] = true;
      this.setState({ updating });
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/extra/updateInvestigationFile`,
        payload
      );
      cogoToast.success("Updated file.");
      let idx = _.findIndex(this.state.files, { _id: x._id });
      let files = this.state.files;
      files[idx] = res;
      updating[index] = false;
      this.setState({ files, updating }, () =>
        this.props.onFiles(this.state.files)
      );
    } catch (err) {
      let updating = this.state.updating;
      updating[index] = false;
      this.setState({ updating });
      console.log(err);
      cogoToast.error("Error updating file.");
    }
  };
  onUpload = async (data, each, index) => {
    let updating = this.state.updating;
    updating[index] = true;
    this.setState({ updating });
    let formData = new FormData();
    for (var i = 0; i < data.length; i++) {
      formData.append(`file${i}`, data[i]);
    }
    formData.append("fileId", each._id);
    try {
      let res = await apiCallPostProgress(
        `/clinical2/${this.props.authStore.prefix}/extra/uploadInvestigationFile`,
        formData,
        (done, total) => {
          cogoToast.loading(`Uploading: ${(done / total).toFixed(2) * 100}%`);
        }
      );
      let idx = _.findIndex(this.state.files, { _id: each._id });
      let files = this.state.files;
      files[idx] = res;
      updating[index] = false;
      this.setState({ files, updating }, () =>
        this.props.onFiles(this.state.files)
      );
      cogoToast.success("Uploaded file.");
    } catch (err) {
      let updating = this.state.updating;
      updating[index] = false;
      this.setState({ updating });
      console.log(err);
      cogoToast.error("Error uploading file.");
    }
  };
  onDelete = async (_id, index) => {
    try {
      let updating = this.state.updating;
      updating[index] = true;
      this.setState({ updating });
      await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/extra/deleteInvestigationFileWhole`,
        { fileId: _id, patientId: this.props.data.patientId }
      );
      cogoToast.success("Deleted file.");
      let idx = _.findIndex(this.state.files, { _id });
      let files = this.state.files;
      files.splice(idx, 1);
      updating[index] = false;
      this.setState({ files, updating }, () =>
        this.props.onFiles(this.state.files)
      );
    } catch (err) {
      let updating = this.state.updating;
      updating[index] = false;
      this.setState({ updating });
      console.log(err);
      cogoToast.error("Error deleting file.");
    }
  };
  onDeleteFile = async (_id, fileIndex, index) => {
    try {
      let updating = this.state.updating;
      updating[index] = true;
      this.setState({ updating });

      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/extra/deleteInvestigationFileAtIndex`,
        { fileId: _id, patientId: this.props.data.patientId, index: fileIndex }
      );
      cogoToast.success("Deleted file.");
      let idx = _.findIndex(this.state.files, { _id });
      let files = this.state.files;
      files[idx] = res;
      updating[index] = false;
      this.setState({ files, updating }, () =>
        this.props.onFiles(this.state.files)
      );
    } catch (err) {
      let updating = this.state.updating;
      updating[index] = false;
      this.setState({ updating });
      console.log(err);
      cogoToast.error("Error deleting file.");
    }
  };
  renderHeader = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "42vw" }}>
        <Card
          elevation={Elevation.THREE}
          style={{
            padding: "5px",
            width: "42vw",
          }}
        >
          <ControlGroup vertical>
            <ControlGroup>
              <Tag minimal>Currently you are entering:</Tag>
            </ControlGroup>
            <ControlGroup style={{ width: "30vw" }}>
              <Tag intent="success" className="forest_dark">
                Investigations & Assets
              </Tag>
              <Tag multiline minimal>
                Use this section to upload reports, files and images related to
                this patient.
              </Tag>
            </ControlGroup>
          </ControlGroup>
        </Card>{" "}
      </div>
    );
  };
  renderInputCard = () => {
    let options = ["AI", "Search"];

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Card
          elevation={Elevation.THREE}
          style={{
            padding: "5px",
          }}
        >
          <ControlGroup>
            <Tag minimal>Input Modes Available:</Tag>
            {options.map((each) => (
              <Button
                key={`input_mode_${each}`}
                className={
                  this.state.selectedInputMode === each
                    ? "colfax rose_dark"
                    : "colfax"
                }
                small
                minimal={this.state.selectedInputMode !== each}
                text={each}
                onClick={() => this.setState({ selectedInputMode: each })}
              />
            ))}
          </ControlGroup>
          {this.state.selectedInputMode === "AI" && this.renderGPTArea()}
          {this.state.selectedInputMode === "Search" &&
            this.renderClinicalSearch()}
        </Card>
      </div>
    );
  };
  renderGPTArea = () => {
    return (
      <GPTPrompter
        promptChain={["FILES_DICTATION_STRUCTURE"]}
        onDone={(output) => {
          let rawText = output;
          let arr = rawText
            .split("\n")
            .filter(Boolean)
            .map((each) => _.trim(each, " "))
            .map((each) => each.split("|||"));
          let processedData = [];
          for (var i = 0; i < arr.length; i++) {
            let newElem = {};
            newElem["entityName"] = arr[i][0];
            newElem["laterality"] = arr[i][1] === "NONE" ? "" : arr[i][1];
            newElem["organ"] = newElem["laterality"] ? "EYE" : "";
            newElem["notes"] = _.trim(arr[i][1], " ");
            processedData.push(newElem);
          }
          processedData.forEach((each) => {
            let payload = { ...each };
            payload["patientId"] = this.props.data.patientId;
            payload["fileFrom"] = each.entityName;
            payload["organ"] = each.organ || "";
            payload["anatomicalPart"] = each.anatomicalPart || "";
            payload["laterality"] = each.laterality || "";
            payload["associatedVisitId"] = this.props.data.visitId;
            payload["notes"] = each.notes || "";
            payload["data"] = each.data && each.data.length ? each.data : [];
            this.createNew(payload);
          });
        }}
      />
    );
  };
  renderClinicalSearch = () => {
    return (
      <div style={{ display: "flex" }}>
        <CanonicalSearcher
          searchTagName={"Investigations"}
          placeholder={"Investigations"}
          onChange={(item) => {
            let payload = {};
            payload["patientId"] = this.props.data.patientId;
            payload["fileFrom"] = item.entityName;
            payload["organ"] = item.organ || "";
            payload["anatomicalPart"] = item.anatomicalPart || "";
            payload["laterality"] = item.laterality || "";
            payload["associatedVisitId"] = this.props.data.visitId;
            payload["data"] = item.data && item.data.length ? item.data : [];
            this.createNew(payload);
          }}
          onSearch={() => {
            this.setState({
              numSearches: this.state.numSearches + 1,
            });
          }}
          entityType={"INVESTIGATIONS"}
        />
        {this.state.numSearches > 2 && (
          <Button
            small
            className="colfax"
            icon="add"
            text="Add Manually"
            onClick={() => {
              let payload = {};
              payload["patientId"] = this.props.data.patientId;
              payload["fileFrom"] = "Investigation";
              payload["organ"] = "";
              payload["anatomicalPart"] = "";
              payload["laterality"] = "";
              payload["associatedVisitId"] = this.props.data.visitId;
              payload["data"] = [];
              this.createNew(payload);
            }}
          />
        )}
      </div>
    );
  };
  renderDateRangeBar = () => {
    return (
      <Card
        elevation={Elevation.THREE}
        style={{
          padding: "5px",
        }}
      >
        <ControlGroup vertical>
          <ControlGroup>
            <Tag minimal>Get Files From:</Tag>
            <DateRangeInput
              className="colfax"
              allowSingleDayRange={true}
              highlightCurrentDay={true}
              endInputProps={{ className: "colfax", inputClassName: "colfax" }}
              startInputProps={{
                className: "colfax",
                inputClassName: "colfax",
              }}
              formatDate={(e) => niceDate(e)}
              parseDate={(e) => new Date(e)}
              value={this.state.dateRange}
              onChange={(dateRange) =>
                this.setState({ dateRange }, () => this.getByDateRange())
              }
            />
          </ControlGroup>
        </ControlGroup>
      </Card>
    );
  };
  renderEntities = () => {
    if (this.state.isLoadingFiles) {
      return <Spinner size={20} />;
    }
    let files = this.state.files;
    if (!files) {
      return;
    }
    return files.map((each, index) => {
      return (
        <div key={`${index}_${each.updatedAt}`}>
          <FilesEntity
            whichIndex={index}
            updating={this.state.updating[index]}
            key={`files_entity_${each._id}__${each.updatedAt}`}
            data={each}
            onUpdate={(data) => this.onUpdate(data, each, index)}
            onDelete={() => this.onDelete(each._id, index)}
            onUpload={(data) => this.onUpload(data, each, index)}
            onDeleteFile={(fileIndex) =>
              this.onDeleteFile(each._id, fileIndex, index)
            }
          />
        </div>
      );
    });
  };
  render() {
    return (
      <div
        style={{
          borderLeft: "1px solid #404854",
          paddingLeft: "10px",
        }}
      >
        <Card
          style={{
            padding: "3px",
            backgroundColor: "#111418",
            marginBottom: "10px",
          }}
        >
          {this.renderHeader()}
        </Card>
        <Card
          style={{
            padding: "3px",
            backgroundColor: "#111418",
            marginBottom: "10px",
          }}
        >
          {this.renderInputCard()}
        </Card>{" "}
        <Card
          style={{
            padding: "3px",
            backgroundColor: "#111418",
            marginBottom: "10px",
          }}
        >
          {this.renderDateRangeBar()}
        </Card>
        {this.renderEntities()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(FilesSection));
