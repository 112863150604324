import React from "react";
import { ControlGroup, Tag } from "@blueprintjs/core";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class GlassAge extends React.Component {
  state = { editingAge: false };
  listOfCG = [
    "No Glasses",
    "New",
    "Less than 6 months",
    "Less than 1 year",
    "Less than 2 years",
    "More than 2 years",
  ];
  renderGlassAge = () => {
    let obj = this.props.vision;
    return (
      <ControlGroup>
        <Tag minimal>Current Glasses</Tag>{" "}
        {this.state.editingAge ? (
          <SingleSelectWrapper
            items={this.listOfCG}
            selectedItem={obj.glassesAge}
            onChange={(item) => {
              this.props.updateVision("glassesAge", item);
              this.setState({ editingAge: false });
            }}
          />
        ) : (
          <Tag
            minimal
            interactive
            onClick={() => this.setState({ editingAge: true })}
            large={true}
          >
            {obj.glassesAge || "Age Not Recorded"}
          </Tag>
        )}
      </ControlGroup>
    );
  };
  render() {
    return <div style={{ marginLeft: "5px" }}>{this.renderGlassAge()}</div>;
  }
}
export default GlassAge;
