import { Button, Card, ControlGroup, Elevation, Tag } from "@blueprintjs/core";
import React from "react";

class DiagramPreview extends React.Component {
  render() {
    return (
      <div style={{ width: "35vw", marginTop: "10px" }}>
        <Card
          elevation={Elevation.FOUR}
          style={{
            padding: "5px",
          }}
        >
          <ControlGroup className="flex__push__apart">
            <ControlGroup>
              <Tag large className="rose__bg" intent="success">
                {this.props.data.nice}
              </Tag>
              <Button
                loading={this.props.isBusy}
                icon="media"
                outlined
                intent="warning"
                onClick={() => this.props.onView()}
              />
            </ControlGroup>
            <Button
              loading={this.props.isBusy}
              icon="trash"
              minimal
              intent="danger"
              onClick={() => this.props.onRemove()}
            />
          </ControlGroup>
          <img
            style={{ width: "100%", height: "auto" }}
            src={this.props.data.finalFileUrl || this.props.data.initialFileKey}
            alt={this.props.data.nice}
          />
        </Card>
      </div>
    );
  }
}
export default DiagramPreview;
