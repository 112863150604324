import { ControlGroup, NumericInput, Tag, Button } from "@blueprintjs/core";
import React from "react";

class OCTSliceEachMeasure extends React.Component {
  state = { touched: false, value: null };
  render() {
    return (
      <div>
        <ControlGroup>
          <Tag
            onRemove={() => this.props.removeHumanMeasure()}
            large
            minimal={!this.state.touched}
            intent={this.state.touched ? "danger" : "none"}
          >
            {this.props.data.description}
          </Tag>
          <NumericInput
            rightElement={
              <ControlGroup>
                {this.props.data.unit ? (
                  <Tag minimal>{this.props.data.unit}</Tag>
                ) : null}

                {this.state.touched ? (
                  <Button
                    icon="endorsed"
                    minimal={!this.state.touched}
                    intent="success"
                    onClick={() =>
                      this.props.editHumanMeasure(this.state.value)
                    }
                  />
                ) : null}
              </ControlGroup>
            }
            buttonPosition="none"
            value={
              this.state.touched ? this.state.value : this.props.data.value
            }
            onValueChange={(value) =>
              this.setState({ touched: true, value: value })
            }
          />
        </ControlGroup>
      </div>
    );
  }
}

export default OCTSliceEachMeasure;
