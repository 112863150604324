import React from "react";
import cogoToast from "cogo-toast";
import { inject, observer } from "mobx-react";
import { apiCallPost } from "../../../fns/util";
import _ from "lodash";
import ASPCurrent from "./ASPCurrent";
import { NonIdealState, Spinner, Tab, Tabs } from "@blueprintjs/core";

class ASPContainer extends React.Component {
  state = {
    loading: true,
    selectedTabId: "RE",
    data: {},
    selectedScanIdx: 0,
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      this.setState({ loading: true });
      cogoToast.loading("Loading data...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/getInvestigation`,
        { _id: this.props.data._id }
      );
      cogoToast.success("Loaded data.", { position: "bottom-left" });
      this.setState({ loading: false, data: res });
    } catch (err) {
      cogoToast.error(
        "There was an error loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  selectForPrint = async (processedId, status) => {
    try {
      cogoToast.loading("Selecting for print...", { position: "bottom-left" });
      await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/setPrintSelectionStatus`,
        { _id: this.props.data._id, processedId: processedId, status: !status }
      );
      let ss = this.state.data;
      let pp = ss.processed;
      let whichScan = _.findIndex(pp, (each) => each._id === processedId);
      pp[whichScan].selectForPrint = !status;
      ss.processed = pp;
      this.setState({ data: ss });
      cogoToast.success("Done.", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error setting data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  addFinding = async (item, _id) => {
    try {
      cogoToast.loading("Adding finding...", { position: "bottom-left" });
      let whichScan = _.find(
        this.state.data.processed,
        (each) => each._id === _id
      );
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/addAnnotation`,
        {
          _id: this.props.data._id,
          kind: item.payload.kind,
          key: item.payload.key,
          value: "Present",
          unit: item.payload.unit,
          description: item.payload.description,
          valueQualifiers: item.helper,
          againstId: _id,
          eye: whichScan.eye,
          splText: "",
        }
      );
      cogoToast.success("Added data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd }, () => this.updateASPSummary());
    } catch (err) {
      cogoToast.error(
        "There was an error loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  removeFinding = async (id) => {
    try {
      cogoToast.loading("Removing finding...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/removeAnnotation`,
        {
          _id: id,
        }
      );
      cogoToast.success("Removed data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd }, () => this.updateASPSummary());
    } catch (err) {
      cogoToast.error(
        "There was an error removing data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  editObjectInside = async (itemId, whichKey, value, scanId) => {
    try {
      cogoToast.loading("Editing finding...", { position: "bottom-left" });
      let item = _.find(
        this.state.data.annotations,
        (each) => each._id === itemId
      );
      item[whichKey === "qualifier" ? "valueQualifiers" : whichKey] = value;
      let payload = {
        ...item,
        againstId: scanId,
        annotationId: itemId,
        _id: this.props.data._id,
      };
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/editAnnotation`,
        payload
      );
      cogoToast.success("Edited data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd }, () => this.updateASPSummary());
    } catch (err) {
      cogoToast.error(
        "There was an error editing data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  updateASPSummary = () => {
    let re = _.filter(this.state.data.annotations, (each) => each.eye === "OD");
    let eyeStringRe = "";
    if (re.length) {
      eyeStringRe =
        "OD: " +
        _.concat(
          _.filter(re, (each) => each.kind === "ASP_HUMAN_FINDING").map(
            (each) =>
              `${each.description} ${
                each.valueQualifiers ? "(" + each.valueQualifiers + ")" : ""
              }`
          )
        ).join(", ");
    }

    let le = _.filter(this.state.data.annotations, (each) => each.eye === "OS");
    let eyeStringLe = "";
    if (le.length) {
      eyeStringLe =
        "OS: " +
        _.concat(
          _.filter(le, (each) => each.kind === "ASP_HUMAN_FINDING").map(
            (each) =>
              `${each.description} ${
                each.valueQualifiers ? "(" + each.valueQualifiers + ")" : ""
              }`
          )
        ).join(", ");
    }

    this.props.update(
      "INVESTIGATION",
      this.props.data._id,
      "summary",
      `${eyeStringRe} | ${eyeStringLe}`
    );
  };
  renderTabsOrNot = () => {
    if (this.state.loading) {
      return null;
    }
    let processed = this.state.data.processed;
    let processedLE = _.filter(processed, (each) => each.eye === "OS");
    let processedRE = _.filter(processed, (each) => each.eye === "OD");
    let le_ = processedLE.length;
    let re_ = processedRE.length;
    let both = false;
    if (le_ && re_) {
      both = true;
    }
    if (both) {
      return (
        <Tabs
          id="asp"
          large
          renderActiveTabPanelOnly={true}
          onChange={(newTabId) =>
            this.setState({
              selectedTabId: newTabId,
              selectedScanIdx: 0,
            })
          }
          selectedTabId={this.state.selectedTabId}
        >
          <Tab
            id="RE"
            title="Right Eye"
            panel={this.renderOneEye(
              processedRE,
              _.filter(this.state.data.annotations, (each) => each.eye === "OD")
            )}
          />{" "}
          <Tab
            id="LE"
            title="Left Eye"
            panel={this.renderOneEye(
              processedLE,
              _.filter(this.state.data.annotations, (each) => each.eye === "OS")
            )}
          />
        </Tabs>
      );
    } else {
      let which = "OD";
      let whichA = processedRE;
      if (le_) {
        which = "OS";
        whichA = processedLE;
      }

      return this.renderOneEye(
        whichA,
        _.filter(this.state.data.annotations, (each) => each.eye === which)
      );
    }
  };
  renderOneEye = (processed, annotations) => {
    let dd = _.filter(processed, (each) => each.kind === "ASP_IMAGE");
    let whichScan = _.find(
      dd,
      (each) =>
        each.sliceIndex ===
        (this.state.selectedScanIdx < dd.length
          ? this.state.selectedScanIdx
          : 0)
    );
    let humanFindings = _.filter(
      annotations,
      (each) => each.kind === "ASP_HUMAN_FINDING"
    );
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ASPCurrent
          addFinding={(item) => this.addFinding(item, whichScan._id)}
          humanFindings={_.filter(
            humanFindings,
            (each) => each.againstId === whichScan._id
          )}
          editObjectInside={(itemId, whichKey, value) =>
            this.editObjectInside(itemId, whichKey, value, whichScan._id)
          }
          remove={this.removeFinding}
          selectForPrint={() =>
            this.selectForPrint(whichScan._id, whichScan.selectForPrint)
          }
          data={whichScan}
          length={dd.length}
          invType={this.props.data.type}
          onLeft={() =>
            this.setState({
              selectedScanIdx:
                this.state.selectedScanIdx === 0
                  ? dd.length - 1
                  : this.state.selectedScanIdx - 1,
            })
          }
          onRight={() =>
            this.setState({
              selectedScanIdx:
                this.state.selectedScanIdx === dd.length - 1
                  ? 0
                  : this.state.selectedScanIdx + 1,
            })
          }
        />
      </div>
    );
  };
  render() {
    if (!this.state.data.processed || !this.state.data.processed.length) {
      return (
        <div>
          <NonIdealState icon={<Spinner />} title="Loading data..." />
        </div>
      );
    }
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {this.renderTabsOrNot()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(ASPContainer));
