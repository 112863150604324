import { ControlGroup, Tag } from "@blueprintjs/core";
import _ from "lodash";
import React from "react";
import { startLowerString } from "../fns/util";

class ClinicalEntityRenderPreview extends React.Component {
  state = {
    isHovering: false,
  };

  renderEntity = () => {
    let recentItems = this.props.recentItems;
    let recentItemIds = recentItems.map((each) => each._id);
    let myId = this.props.data._id;
    let isRecent = _.includes(recentItemIds, myId);
    let className = "no__bg";
    let recentType = "Updated";
    if (isRecent) {
      let which = _.filter(recentItems, (x) => x._id === myId);
      if (which.length > 1) {
        className = "cerulean__bg";
      } else {
        className = which[0].type === "ADD" ? "lime__bg" : "cerulean__bg";
        recentType = which[0].type === "ADD" ? "Added" : "Updated";
      }
    }
    return (
      <div
        onMouseOver={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
        onClick={() => this.props.onChoice()}
        className="colfax"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          textAlign: "left",
        }}
      >
        {this.state.isHovering ? (
          <ControlGroup>
            <Tag
              minimal
              icon="delete"
              intent="danger"
              className="no__bg"
              interactive
              onClick={() => this.props.onRemove()}
            ></Tag>
          </ControlGroup>
        ) : (
          <ControlGroup>
            <Tag minimal icon="dot" className="no__bg"></Tag>
          </ControlGroup>
        )}
        <div className="flex_box_wrap">
          {this.renderLaterality()}
          {this.renderAnatomicalPart()}
          <Tag minimal className={className} multiline>
            {this.props.data.entityName}
          </Tag>
          {isRecent ? (
            <Tag minimal className="no__bg" multiline>
              {recentType}!
            </Tag>
          ) : null}
          {this.renderAnaesthesia()}
          {this.renderInstructions()}
        </div>
      </div>
    );
  };
  renderLaterality = () => {
    if (this.props.isShort) {
      return null;
    }
    if (!this.props.data.laterality) {
      return null;
    }
    if (this.props.data.organ !== "EYE") {
      return null;
    }
    let str = "";
    if (this.props.data.laterality === "LEFT") {
      str = "LE";
    } else if (this.props.data.laterality === "RIGHT") {
      str = "RE";
    } else if (this.props.data.laterality === "BILATERAL") {
      str = "BE";
    } else {
      str = startLowerString(this.props.data.laterality);
    }
    return (
      <Tag minimal className="gray5__bg">
        {str}
      </Tag>
    );
  };
  renderAnatomicalPart = () => {
    if (this.props.isShort) {
      return null;
    }
    if (!this.props.data.anatomicalPart) {
      return null;
    }
    let str = startLowerString(this.props.data.anatomicalPart);

    return (
      <Tag minimal className="lime__bg">
        {str}
      </Tag>
    );
  };
  renderInstructions = () => {
    if (!this.props.data.instructions) {
      return null;
    }
    let str = startLowerString(this.props.data.instructions);

    return (
      <Tag minimal className="violet__bg" icon="circle-arrow-right" multiline>
        {str}
      </Tag>
    );
  };
  renderAnaesthesia = () => {
    if (!this.props.data.anaesthesia) {
      return null;
    }
    let niceStrings = {
      TOPICAL_ANAESTHESIA: "TA",
      LOCAL_ANAESTHESIA: "LA",
      REGIONAL_ANAESTHESIA: "RA",
      GENERAL_ANAESTHESIA: "GA",
    };
    let str = "";
    if (_.keys(niceStrings).includes(this.props.data.anaesthesia)) {
      str = niceStrings[this.props.data.anaesthesia];
    } else {
      str = startLowerString(this.props.data.anaesthesia);
    }
    return (
      <Tag minimal className="violet__bg" icon="circle-arrow-down" multiline>
        {str}
      </Tag>
    );
  };
  renderLinkedInvestigations = () => {
    if (!this.props.data.sxLinkedInvestigations) {
      return null;
    }
    let niceStrings = {
      BIOMETRY_OCT_SPECULAR: "Biometry, OCT and Specular",
      BIOMETRY_OCT_SYRINGING_SPECULAR: "Biometry, OCT, Syringing and Specular",
      OCT_DFP: "OCT and DFP",
      "ECG_X-RAY_PPBS": "ECG, X-Ray and PPBS",
    };
    let str = "";
    if (_.keys(niceStrings).includes(this.props.data.sxLinkedInvestigations)) {
      str = niceStrings[this.props.data.sxLinkedInvestigations];
    } else {
      str = _.startCase(_.lowerCase(this.props.data.sxLinkedInvestigations));
    }
    return (
      <div
        style={{
          fontSize: "smaller",
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          marginLeft: "45px",
        }}
      >
        {" "}
        <Tag minimal className="violet__bg" icon="circle-arrow-down" multiline>
          Pre-Surgery Investigations: {str}
        </Tag>
      </div>
    );
  };
  renderNotes = () => {
    if (!this.props.data.notes) {
      return null;
    }
    return (
      <div
        style={{
          fontSize: "smaller",
          textAlign: "left",
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          marginLeft: "45px",
        }}
      >
        <Tag minimal className="no__bg" multiline>
          {this.props.data.notes}
        </Tag>
      </div>
    );
  };
  render() {
    return (
      <div style={{ marginTop: "10px" }}>
        {this.renderEntity()}
        {this.renderLinkedInvestigations()}
        {this.renderNotes()}
      </div>
    );
  }
}

export default ClinicalEntityRenderPreview;
