import React from "react";
import {
  Button,
  NonIdealState,
  Card,
  Elevation,
  Tag,
  Drawer,
} from "@blueprintjs/core";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import {
  apiCallPost,
  niceTime,
  isInPastDateStrict,
  copyToClipboard,
} from "../fns/util";
import BillingSummary from "./BillingSummary";
import BillingServices from "./BillingServices";
import CaptureRefund from "./CaptureRefund";
import CapturePayment from "./CapturePayment";
import AddDiscount from "./AddDiscount";
import BillingLedgerItem from "./BillingLedgerItem";
import cogoToast from "cogo-toast";
import SetCashless from "./SetCashless";
import { v4 as uuidv4 } from "uuid";
import CaptureReimbursement from "./CaptureReimbursement";

class PresribedInvestigations extends React.Component {
  renderRoles = (data) => {
    const rows = [];
    for (let row of data) {
      for (let i of row.investigations) {
        rows.push(
          <tr>
            <td>{row.doctor}</td>
            <td>{i.fileFrom}</td>
            <td>{i.laterality + " -- " + i.notes}</td>
          </tr>
        );
      }
    }
    return rows;
  };
  render() {
    let data = this.props.data;
    return (
      <div style={{ margin: "20px" }} key={`${data._id}`}>
        <table class="bp5-html-table">
          <thead>
            <tr>
              <th>Doctor</th>
              <th>Investigation</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody> {this.renderRoles(data)}</tbody>
        </table>
      </div>
    );
  }
}

class BillDetail extends React.Component {
  state = {
    showPrescribedInvestigationsDrawer: false,
    prescribedInvestigations: [],
    activating: false,
    sites: [],
    genericPid: null,
    doctorList: [],
    panelNames: [],
    date: null,
    lineItems: [],
    tpa_wise: [],
    applied: 0,
    balance: 0,
    discounts: 0,
    numOfServices: 0,
    paid: 0,
    reimbursable: 0,
    reimbursed: 0,
    total: 0,
    unapplied: 0,
    unique_tpas: [],
    events: [],
  };

  onDateChange = () => {
    let propDate = this.props.billingStore.billingDate;
    let propDateArr = propDate.split("-");
    let date = new Date(this.props.billingStore.billingDate);
    this.setState(
      {
        date: date,
        year: propDateArr[0] - 0,
        month: propDateArr[1] - 1,
        day: propDateArr[2] - 0,
      },
      () => {
        this.setState(
          { genericPid: this.props.patientStore.genericPid },
          () => {
            this.getLineItems();
            this.getSummary();
            this.getEvents();
          }
        );
      }
    );
    this.setState({
      doctorList: toJS(this.props.listStore.doctorList),
      panelNames: toJS(this.props.listStore.panelList),
    });
  };
  componentDidMount() {
    if (this.props.billingStore.billingDate) {
      this.onDateChange();
    }
  }

  refreshPDFs = async () => {
    try {
      cogoToast.loading("Regenerating Documents...", {
        position: "bottom-left",
      });
      let payload = {
        genericPid: this.state.genericPid,
        year: this.state.year,
        monthIx: this.state.month,
        day: this.state.day,
      };
      await apiCallPost(
        `/billing/${this.props.authStore.prefix}/regenPdfs`,
        payload
      );
      cogoToast.success("Regenerated Documents", { position: "bottom-left" });

      this.getLineItems();
      this.getSummary();
      this.getEvents();
    } catch (err) {
      cogoToast.error(
        "There was an error regenerating the documents. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  getLineItems = async () => {
    try {
      cogoToast.loading("Getting line items...", { position: "bottom-left" });
      let payload = {
        genericPid: this.state.genericPid,
        year: this.state.year,
        monthIx: this.state.month,
        day: this.state.day,
      };
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/lineitems`,
        payload
      );
      cogoToast.success("Loaded line items.", { position: "bottom-left" });

      this.setState({ lineItems: res }, () => this.checkMultipleSites());
    } catch (err) {
      cogoToast.error(
        "There was an error loading line items. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  getPrescribedInvestigations = async () => {
    try {
      cogoToast.loading("Getting prescribed investigations...", {
        position: "bottom-left",
      });
      let payload = {
        genericPid: this.state.genericPid,
        year: this.state.year,
        monthIx: this.state.month,
        day: this.state.day,
      };
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/prescribedInvestigations`,
        payload
      );
      cogoToast.success("Getting prescribed investigations.", {
        position: "bottom-left",
      });

      this.setState({ prescribedInvestigations: res.prescribedInvestigations });
    } catch (err) {
      cogoToast.error(
        "There was an error loading prescribed investigations. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  checkMultipleSites = () => {
    let sites = [];
    _.map(this.state.lineItems, (each) => {
      sites.push(each.site);
    });
    this.setState({ sites: _.uniq(sites) });
  };
  getSummary = async () => {
    try {
      cogoToast.loading("Getting summary ...", { position: "bottom-left" });
      let payload = {
        genericPid: this.state.genericPid,
        year: this.state.year,
        monthIx: this.state.month,
        day: this.state.day,
      };
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/summarize`,
        payload
      );
      this.setState({
        applied: res.used_cash,
        balance: res.balance,
        discounts: res.total_for_discount || 0,
        numOfServices: res.numOfServices,
        paid: res.paid_cash,
        reimbursable: res.reimbursable,
        reimbursed: res.reimbursed,
        total: res.total_for_bill,
        total_cash: res.total_for_cash,
        unapplied: res.unused_cash,
        tpa_wise: res.tpa_wise,
        refund: res.refunded_cash,
        unique_tpas: res.unique_tpas,
      });
      cogoToast.success("Loaded summary.", { position: "bottom-left" });
      this.getEvents();
    } catch (err) {
      cogoToast.error(
        "There was an error loading summary. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  getEvents = async () => {
    try {
      cogoToast.loading("Getting event log...", { position: "bottom-left" });
      let payload = {
        genericPid: this.state.genericPid,
        year: this.state.year,
        monthIx: this.state.month,
        day: this.state.day,
      };
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/events/all`,
        payload
      );
      cogoToast.success("Loaded events.", { position: "bottom-left" });
      this.setState({ events: res });
    } catch (err) {
      cogoToast.error(
        "There was an error loading events. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  updateLineItemState = (lineItem) => {
    let current = this.state.lineItems;
    let idx = _.findIndex(current, (each) => each._id === lineItem._id);
    current[idx] = lineItem;
    this.setState({ current });
  };
  updateReferral = async (id, doc) => {
    try {
      cogoToast.loading("Updating Referral", { position: "bottom-left" });
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/lineitem/update/referrer`,
        {
          genericPid: this.state.genericPid,
          year: this.state.year,
          monthIx: this.state.month,
          day: this.state.day,
          lineItemId: id,
          doctorName: doc,
        }
      );
      cogoToast.success("Updated Referral", { position: "bottom-left" });
      this.updateLineItemState(res);
      this.getSummary();
    } catch (err) {
      cogoToast.error(
        "There was an error in updating referral. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  updateInvEye = async (id, eye = "Both Eyes", detail) => {
    /*   if (!eye) {
      cogoToast.error("Please choose the eye.", { position: "bottom-left" });
      return;
    } */
    try {
      cogoToast.loading("Updating Details", { position: "bottom-left" });
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/lineitem/update/investigation`,
        {
          genericPid: this.state.genericPid,
          year: this.state.year,
          monthIx: this.state.month,
          day: this.state.day,
          lineItemId: id,
          applicableEye: eye || undefined,
          specificType: detail || "Generic",
        }
      );
      this.updateLineItemState(res);
      this.getSummary();
      cogoToast.success("Updated Details", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating details. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  updatePanel = async (id, panel) => {
    try {
      cogoToast.loading("Updating Rate Details", { position: "bottom-left" });
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/lineitem/request/approval`,
        {
          genericPid: this.state.genericPid,
          year: this.state.year,
          monthIx: this.state.month,
          day: this.state.day,
          lineItemId: id,
          panel: panel || undefined,
        }
      );
      this.updateLineItemState(res);
      this.getSummary();
      cogoToast.success("Updated Details", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating details. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  updateApproval = async (id, panel) => {
    if (!id || !panel) {
      cogoToast.error("You haven't selected the panels completely.", {
        position: "bottom-left",
      });
      return;
    }
    try {
      cogoToast.loading("Confirming Rate Details", { position: "bottom-left" });
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/lineitem/approve`,
        {
          genericPid: this.state.genericPid,
          year: this.state.year,
          monthIx: this.state.month,
          day: this.state.day,
          lineItemId: id,
          panel: panel,
        }
      );
      this.updateLineItemState(res);
      this.getSummary();
      cogoToast.success("Confirmed Details", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in confirming details. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  resetApproval = async (id) => {
    if (!id) {
      cogoToast.error("We ran into an error. Please contact support.", {
        position: "bottom-left",
      });
      return;
    }
    try {
      cogoToast.loading("Resetting Panel", { position: "bottom-left" });
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/lineitem/reset`,
        {
          genericPid: this.state.genericPid,
          year: this.state.year,
          monthIx: this.state.month,
          day: this.state.day,
          lineItemId: id,
        }
      );
      this.updateLineItemState(res);
      this.getSummary();
      cogoToast.success("Reset Details", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in resetting details. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  recordReimbursement = async (id, amount, claim) => {
    let payload = {
      lineItemId: id,
      amountPaise: amount * 100,
      claimId: claim,
    };
    payload["genericPid"] = this.state.genericPid;
    payload["year"] = this.state.year;
    payload["monthIx"] = this.state.month;
    payload["day"] = this.state.day;

    try {
      cogoToast.loading("Capturing Reimbursement", { position: "bottom-left" });
      await apiCallPost(
        `/billing/${this.props.authStore.prefix}/events/add/reimbursement`,
        payload
      );
      this.getSummary();
      cogoToast.success("Captured Reimbursement", { position: "bottom-left" });
      this.showToggle("REIMBURSEMENT");
    } catch (err) {
      cogoToast.error(
        "There was an error in capturing reimbursement. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  addPayment = async (payload) => {
    // console.log("Outside");
    payload["genericPid"] = this.state.genericPid;
    payload["year"] = this.state.year;
    payload["monthIx"] = this.state.month;
    payload["day"] = this.state.day;

    try {
      cogoToast.loading("Capturing Payment", { position: "bottom-left" });
      await apiCallPost(
        `/billing/${this.props.authStore.prefix}/events/add/payment`,
        payload
      );
      this.getSummary();
      cogoToast.success("Captured Payment", { position: "bottom-left" });
      this.showToggle("PAYMENT");
    } catch (err) {
      cogoToast.error(
        "There was an error in capturing payment. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  setCashlessPayment = async (payload) => {
    payload["genericPid"] = this.state.genericPid;
    payload["year"] = this.state.year;
    payload["monthIx"] = this.state.month;
    payload["day"] = this.state.day;
    payload["reference"] = payload.claimId || payload.approvalBasis;

    try {
      cogoToast.loading("Setting Cashless Amount", { position: "bottom-left" });
      await apiCallPost(
        `/billing/${this.props.authStore.prefix}/events/set/payment/cashless`,
        payload
      );
      this.getSummary();
      cogoToast.success("Set Amount", { position: "bottom-left" });
      //  this.showToggle("PAYMENT");
    } catch (err) {
      cogoToast.error(
        "There was an error in setting cashless amount. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  updateCashless = async (id, cashless, panel) => {
    if (!id || !cashless || !panel) {
      cogoToast.error("You haven't selected the fields completely.", {
        position: "bottom-left",
      });
      return;
    }
    try {
      cogoToast.loading("Confirming Cashless Details", {
        position: "bottom-left",
      });
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/lineitem/update/cashless`,
        {
          genericPid: this.state.genericPid,
          year: this.state.year,
          monthIx: this.state.month,
          day: this.state.day,
          lineItemId: id,
          panel: panel,
          cashlessAmtPaise: cashless,
        }
      );
      this.updateLineItemState(res);
      this.getSummary();
      cogoToast.success("Confirmed Details", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in confirming details. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  activateItemEx = async (id) => {
    const lineItem = this.state.lineItems.find((each) => each._id === id);
    console.assert(lineItem, "Line item not found");
    if (lineItem.kind === "package_parent") {
      const packageItems = this.state.lineItems.filter(
        (each) => each.packageParentLnItem === id
      );
      await this.activateItem(id);
      for (const each of packageItems) {
        await this.activateItem(each._id);
      }
    } else {
      // base case
      await this.activateItem(id);
    }
  };

  activateItem = async (id) => {
    let payload = {
      lineItemId: id,
    };
    payload["genericPid"] = this.state.genericPid;
    payload["year"] = this.state.year;
    payload["monthIx"] = this.state.month;
    payload["day"] = this.state.day;

    try {
      this.setState({ activating: true });
      cogoToast.loading("Applying Payment", { position: "bottom-left" });
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/activate`,
        payload
      );
      this.updateLineItemState(res);
      cogoToast.success("Applied Payment", { position: "bottom-left" });
      this.getSummary();
      this.setState({ activating: false });
    } catch (err) {
      this.setState({ activating: false });
      cogoToast.error(
        "There was an error in applying payment. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  addDiscount = async (payload) => {
    payload["genericPid"] = this.state.genericPid;
    payload["year"] = this.state.year;
    payload["monthIx"] = this.state.month;
    payload["day"] = this.state.day;

    try {
      cogoToast.loading("Adding Discount", { position: "bottom-left" });
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/events/add/discount/local`,
        payload
      );
      this.updateLineItemState(res);
      this.getSummary();
      cogoToast.success("Added Discount", { position: "bottom-left" });
      this.showToggle("DISCOUNT");
    } catch (err) {
      cogoToast.error(
        "There was an error in adding discount. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  addRefund = async (payload) => {
    payload["genericPid"] = this.state.genericPid;
    payload["year"] = this.state.year;
    payload["monthIx"] = this.state.month;
    payload["day"] = this.state.day;

    try {
      cogoToast.loading("Recording Refund", { position: "bottom-left" });
      await apiCallPost(
        `/billing/${this.props.authStore.prefix}/events/add/refund`,
        payload
      );
      this.getSummary();
      cogoToast.success("Recorded Refund", { position: "bottom-left" });
      this.showToggle("REFUND");
    } catch (err) {
      cogoToast.error(
        "There was an error in recording refund. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  addNotes = async (text) => {
    let payload = {
      text: text,
    };
    payload["genericPid"] = this.state.genericPid;
    payload["year"] = this.state.year;
    payload["monthIx"] = this.state.month;
    payload["day"] = this.state.day;

    try {
      cogoToast.loading("Adding Note", { position: "bottom-left" });
      await apiCallPost(
        `/billing/${this.props.authStore.prefix}/events/add/note`,
        payload
      );
      this.getSummary();
      cogoToast.success("Added Note", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in adding the note. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  bookInvestigation = async (
    start,
    duration,
    site,
    type,
    doctor,
    scheduleId
  ) => {
    let obj = {
      uuid: uuidv4(),
      success: false,
      docketNumber: "NOT BOOKED",
      start: start,
      duration: duration,
      site: site,
      doctor: doctor,
      type: type,
      cost: 0,
      kind: "investigation",
      scheduleId: scheduleId || "NO_SCHEDULE_ID",
    };
    let payload = {
      new: false,
      patientPid: this.props.patientStore.genericPid,
      appointments: [obj],
      panel: "Self",
      context: "In Person - Same Day",
      remarks: "Booked By Billing Counter",
      symptoms: [],
      bookedTime: new Date(),
      choice: true,
      channel: "Billing Counter",
    };
    cogoToast.loading("Attempting to book investigation.", {
      position: "bottom-left",
    });
    try {
      let res = await apiCallPost(
        `/mgmt/${this.props.authStore.prefix}/register/appointment`,
        payload
      );

      res.map((each) => {
        if (each.success) {
          cogoToast.success(
            `Successfully booked with ${each.doctor} at ${niceTime(
              each.start
            )}. Docket: ${each.DocketNo}`,
            {
              position: "bottom-left",
            }
          );
        } else {
          cogoToast.error(
            `Error in booking with ${each.doctor} at ${niceTime(
              each.start
            )}. Error: ${each._error ? each._error : "Please check console"}`,
            {
              position: "bottom-left",
            }
          );
        }
        return null;
      });

      this.getLineItems();
      this.getEvents();
      this.getSummary();
      this.getPrescribedInvestigations();
    } catch (err) {
      cogoToast.error(
        "We ran into an error. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  showToggle = (arg) => {
    if (this.state.show === arg) {
      this.setState({ show: null });
    } else {
      this.setState({ show: arg });
    }
  };
  renderActions = () => {
    let summary = {
      total: this.state.total,
      total_cash: this.state.total_cash,
      less: this.state.discounts,
      paid: this.state.paid,
      balance: this.state.balance,
      refund: this.state.refund,
      unapplied: this.state.unapplied,
    };
    let isPast = isInPastDateStrict(this.props.billingStore.billingDate);
    if (isPast) {
      return null;
    }
    let scope = toJS(this.props.authStore.scope);
    let isMediclaim = scope.includes("mediclaim");
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          minimal
          icon="arrow-left"
          text="Go Back"
          intent="danger"
          onClick={() => this.setState({ redirect: true })}
        />
        <Tag minimal>Self-Paying Patients</Tag>
        <Button
          outlined
          icon="credit-card"
          text="Payment"
          intent="success"
          disabled={summary.balance <= 0}
          onClick={() => this.showToggle("PAYMENT")}
        />{" "}
        <Button
          outlined
          icon="percentage"
          text="Discount"
          intent="warning"
          disabled={summary.total <= 0}
          onClick={() => this.showToggle("DISCOUNT")}
        />
        <Button
          outlined
          icon="double-chevron-down"
          text="Refund"
          intent="danger"
          disabled={summary.balance >= 0}
          onClick={() => this.showToggle("REFUND")}
        />{" "}
        <Tag minimal>Panels</Tag>
        <Button
          outlined
          icon="document"
          text="Cashless"
          intent="success"
          disabled={true}
          onClick={() => this.showToggle("CASHLESS")}
        />
        <Button
          outlined
          icon="data-lineage"
          text="Reimbursement"
          intent="success"
          disabled={!isMediclaim}
          onClick={() => this.showToggle("REIMBURSEMENT")}
        />
        <Tag minimal>Appointments</Tag>
        <Button
          outlined
          intent="success"
          text="Book New Services"
          icon="lab-test"
          onClick={() => {
            copyToClipboard(this.state.genericPid);
            let s = this.props.patientStore.genericPid;
            let p = this.props.patientStore.patientPhone;
            let str = s;
            if (s.includes("via")) {
              str = p;
            }
            this.setState({
              goToBooking: true,
              bookingRoute: `/booking/${str}`,
            });
          }}
        />
        <Tag minimal>Refresh</Tag>
        <Button
          outlined
          intent="primary"
          text="PDFs"
          icon="refresh"
          onClick={() => this.refreshPDFs()}
        />
      </div>
    );
  };
  renderActionBars = () => {
    if (!this.state.show) {
      return null;
    }
    switch (this.state.show) {
      case "PAYMENT":
        return (
          <CapturePayment
            key={Math.floor(Math.random() * 1000)}
            max={this.state.balance}
            onPayment={(payload) => this.addPayment(payload)}
            sites={this.state.sites}
          />
        );
      case "DISCOUNT":
        return (
          <AddDiscount
            key={Math.floor(Math.random() * 1000)}
            docList={this.state.doctorList}
            items={this.state.lineItems}
            addDiscount={(payload) => this.addDiscount(payload)}
          />
        );
      case "REFUND":
        return (
          <CaptureRefund
            key={Math.floor(Math.random() * 1000)}
            max={this.state.unapplied}
            addRefund={(payload) => this.addRefund(payload)}
            sites={this.state.sites}
          />
        );
      case "CASHLESS":
        return (
          <SetCashless
            panels={this.state.unique_tpas}
            setCashless={(arg) => this.setCashlessPayment(arg)}
            sites={this.state.sites}
          />
        );
      case "REIMBURSEMENT":
        return (
          <CaptureReimbursement
            recordReimbursement={(id, amount, claim) =>
              this.recordReimbursement(id, amount, claim)
            }
            sites={this.state.sites}
            panels={this.state.unique_tpas}
            items={this.state.lineItems}
          />
        );
      default:
        return null;
    }
  };
  renderEvents = () => {
    if (!this.state.events || !this.state.events.length) {
      return null;
    }
    return this.state.events.map((each) => {
      return <BillingLedgerItem item={each} />;
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/list" />;
    }
    if (this.state.goToBooking) {
      return <Redirect to={this.state.bookingRoute} />;
    }
    if (!this.props.patientStore.genericPid) {
      return (
        <NonIdealState
          className="bp5-dark"
          icon="search"
          title="No patient selected."
          description="Please search for an existing patient or register a new patient."
          action={
            <Button
              minimal
              text="Search"
              icon="geosearch"
              onClick={() => this.setState({ redirect: "/" })}
            />
          }
        />
      );
    }
    return (
      <>
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            overflowX: "scroll",
          }}
        >
          <Drawer
            size="50%"
            title="Prescribed Investigations"
            className="colfax bp5-dark"
            icon="camera"
            isOpen={this.state.showPrescribedInvestigationsDrawer}
            onClose={() => {
              this.setState({
                showPrescribedInvestigationsDrawer: false,
              });
            }}
          >
            <PresribedInvestigations
              data={this.state.prescribedInvestigations}
            />
          </Drawer>
          <BillingServices
            isNew={this.props.patientStore.genericPid.includes("via")}
            activating={this.state.activating}
            date={this.state.date}
            onApprove={(id, panel) => this.updateApproval(id, panel)}
            onRequest={(id, panel) => this.updatePanel(id, panel)}
            onReferred={(id, doc) => this.updateReferral(id, doc)}
            onInvEye={(id, eye, detail) => this.updateInvEye(id, eye, detail)}
            items={this.state.lineItems}
            tpaUnapplied={this.state.tpaUnapplied}
            unapplied={this.state.unapplied}
            onActivate={(id) => this.activateItemEx(id)}
            doctorList={this.state.doctorList}
            onReset={(id) => this.resetApproval(id)}
            panelNames={this.state.panelNames}
            onCashlessUpdate={(id, cashless, panel) =>
              this.updateCashless(id, cashless, panel)
            }
            tpa_wise={this.state.tpa_wise}
          />
          <BillingSummary
            key={Math.floor(Math.random() * 1000)}
            onRefresh={() => this.getSummary()}
            sites={this.state.sites}
            summary={{
              total: this.state.total,
              total_cash: this.state.total_cash,
              less: this.state.discounts,
              paid: this.state.paid,
              balance: this.state.balance,
              refund: this.state.refund,
              unapplied: this.state.unapplied,
            }}
            unique_tpas={this.state.unique_tpas}
            toggle={(arg) => this.showToggle(arg)}
            onNotes={(text) => this.addNotes(text)}
            tpa_wise={this.state.tpa_wise}
            openPrescribedInvestigationsDrawer={async () => {
              await this.getPrescribedInvestigations();
              this.setState({
                showPrescribedInvestigationsDrawer: true,
              });
            }}
          />
        </div>
        <div
          style={{
            padding: "5px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Card
            className="bp5-dark"
            style={{
              backgroundColor: "#17191A",
              margin: "10px",
            }}
            elevation={Elevation.THREE}
          >
            {this.renderActions()}
          </Card>

          {this.renderActionBars()}
          {this.renderEvents()}
        </div>
      </>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(BillDetail));
