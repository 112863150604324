import { H3, Tag } from "@blueprintjs/core";
import React from "react";
import PrevEyeSx from "../elems/PrevEyeSx";
/* import SuggColl from "../SuggColl"; */
import SingleSearch from "./SingleSearch";

class PrevEyeSxPanel extends React.Component {
  renderTitle = () => {
    return (
      <H3>Is there a history of previous eye surgeries and procedures?</H3>
    );
  };
  renderCG = () => {
    return (
      <SingleSearch
        prefix="prev-sx"
        whichArray="sysEyeSx"
        add={this.props.add}
        placeholder="Previous Eye Sx"
      />
    );
  };

  renderPrevEyeDx = () => {
    if (!this.props.sysEyeSx || !this.props.sysEyeSx.length) {
      return (
        <Tag minimal large>
          No previous eye diseases added.
        </Tag>
      );
    }
    return this.props.sysEyeSx.map((each) => {
      return (
        <PrevEyeSx
          data={each}
          onRemove={() => this.props.removeArray("sysEyeSx", each._id)}
          onUpdate={(key, value) =>
            this.props.updateArray("sysEyeSx", each._id, key, value)
          }
        />
      );
    });
  };
  render() {
    return (
      <div style={{ padding: "20px", height: "100%" }}>
        {this.renderTitle()}
        <br />
        {/* <SuggColl
          ana_prefix=""
          prefix="prev-sx"
          visitId={this.props.visitId}
          addObjectInside={this.props.add}
        /> */}

        {this.renderCG()}
        <br />
        {this.renderPrevEyeDx()}
      </div>
    );
  }
}

export default PrevEyeSxPanel;
