import React from "react";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  Tag,
  /*   NonIdealState,
  Spinner, */
} from "@blueprintjs/core";
import { copyToClipboard } from "../../fns/util";
//const PinturaEditorModal = React.lazy(() => import("./PinModalLoad.js"));

class InvImage extends React.Component {
  state = {
    show: false,
  };

  render() {
    const editorConfig = {};
    editorConfig["utils"] = ["crop", "filter", "finetune"];
    editorConfig.locale.cropLabel = "Zoom";
    return (
      <div>
        <Card
          elevation={Elevation.FOUR}
          style={{
            backgroundColor: "black",
            width: "fit-content",
            padding: "10px",
          }}
        >
          {this.props.selector ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ControlGroup>
                <Button
                  minimal
                  icon="clipboard"
                  onClick={() => this.setState({ show: true })}
                />
              </ControlGroup>
            </div>
          ) : null}

          <InnerImageZoom
            src={this.props.src.psUrl}
            zoomType="hover"
            zoomPreload={true}
            width={(this.props.src.width * 400) / this.props.src.height}
            height="400"
            hideHint={true}
          />
          <ControlGroup>
            {this.props.src.width > 400 ? (
              <Tag minimal>Eye: {this.props.src.eye}</Tag>
            ) : null}

            {this.props.src.width > 500 ? (
              <Tag minimal>
                Raw Dimensions: {this.props.src.width} x {this.props.src.height}{" "}
              </Tag>
            ) : null}

            {this.props.src.width > 500 ? (
              <Tag
                minimal
                interactive
                onClick={() => copyToClipboard(this.props.src._id)}
              >
                {this.props.src._id}
              </Tag>
            ) : null}

            <Tag
              minimal
              interactive
              rightIcon="zoom-to-fit"
              onClick={() => this.setState({ show: true })}
            >
              Turbo Mode
            </Tag>
          </ControlGroup>
        </Card>
      </div>
    );
  }
}

export default InvImage;
