import {
  Card,
  Collapse,
  ControlGroup,
  Elevation,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import VisTable from "../VisTable";
import Dilation from "./Dilation";
import GlassAge from "./GlassAge";
import GlassInst from "./GlassInst";
import IOPTable from "./IOPTable";

class VisionSection extends React.Component {
  state = { collapse: true };
  computeBestVision = (v) => {
    let final = [null, null];
    if (v.has_uncorrected) final = ["Uncorrected", v.uncorrected];
    if (v.has_pgp) final = ["PGP", v.pgp];
    if (v.has_corrected_dry) final = ["Dry", v.corrected_dry];
    if (v.has_corrected_dilated) final = ["Dilated", v.corrected_dilated];
    if (v.has_corrected_pmt) final = ["PMT", v.corrected_pmt];
    if (v.has_corrected_rx) final = ["Rx", v.corrected_rx];
    return final;
  };
  renderVisString = () => {
    let bv = this.computeBestVision(this.props.vision);
    if (bv[0]) {
      let le = `LE: ${bv[1].le.v_dist} ${bv[1].le.v_near}`;
      let re = `RE: ${bv[1].re.v_dist} ${bv[1].re.v_near}`;
      return bv[0] + " " + re + "  |  " + le;
    } else {
      let nr = bv.notRecordedReason;
      let str = "Reason Unknown";
      if (nr && nr.length) {
        str = nr.join(",");
      }
      return "Vision Not Recorded: " + str;
    }
  };
  renderIopString = () => {
    let str = "";
    if (this.props.vision.at.re !== "---") {
      str = `${this.props.vision.at.re} mm Hg | ${this.props.vision.at.le} mm Hg`;
    } else {
      str = `${this.props.vision.nct.re} mm Hg | ${this.props.vision.nct.le} mm Hg`;
    }
    return str;
  };
  composeString = () => {
    let s1 = this.renderVisString();
    let s2 = this.renderIopString();
    return s1 + " IOP: " + s2;
  };
  renderHeader = () => {
    if (this.state.collapse) {
      return null;
    }
    return (
      <ControlGroup>
        <Tag
          intent="primary"
          interactive
          large
          onClick={() => this.setState({ collapse: !this.state.collapse })}
        >
          {this.composeString()}
        </Tag>
      </ControlGroup>
    );
  };
  renderCard = () => {
    return (
      <Card
        style={{ paddingLeft: "5px", background: "none" }}
        elevation={Elevation.FOUR}
      >
        {this.renderHeader()}
        <Collapse isOpen={this.state.collapse}>
          <div
            style={{ display: "flex", flexWrap: "wrap", marginBottom: "5px" }}
          >
            <VisTable
              past={this.props.lastVision}
              vision={this.props.vision}
              updateVision={this.props.updateVision}
              onCollapse={() => this.setState({ collapse: false })}
            />
            <IOPTable
              vision={this.props.vision}
              updateVision={this.props.updateVision}
            />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
              }}
            >
              <Dilation
                vertical={true}
                vision={this.props.vision}
                updateVision={this.props.updateVision}
              />
              <GlassAge
                vision={this.props.vision}
                updateVision={this.props.updateVision}
              />
            </div>
          </div>

          <GlassInst
            vision={this.props.vision}
            updateVision={this.props.updateVision}
          />
        </Collapse>
      </Card>
    );
  };
  render() {
    return <>{this.renderCard()}</>;
  }
}

export default VisionSection;
