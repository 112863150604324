import React from "react";
import { ControlGroup } from "@blueprintjs/core";
import NumInpVision from "./NumInpVision";
import { renderCorrectedIop } from "../../fns/util";

class IOPTable extends React.Component {
  rowCorrectedIOP = () => {
    let obj = this.props.vision;
    let flag = false;
    if (obj.cct.le || obj.cct.re) {
      flag = true;
    }
    if (!flag) {
      return null;
    }
    return (
      <tr>
        {" "}
        {flag ? <td>Corrected for CCT</td> : null}
        <td
          style={{
            backgroundColor: `${
              renderCorrectedIop(obj.cct.re, obj.nct.re) > 20 ? "red" : ""
            }`,
          }}
        >
          <ControlGroup>
            {renderCorrectedIop(obj.cct.re, obj.nct.re)}
          </ControlGroup>
        </td>
        <td
          style={{
            backgroundColor: `${
              renderCorrectedIop(obj.cct.le, obj.nct.le) > 20 ? "red" : ""
            }`,
            borderRight: "2px solid #A7B6C2",
          }}
        >
          <ControlGroup>
            {" "}
            {renderCorrectedIop(obj.cct.le, obj.nct.le)}
          </ControlGroup>
        </td>
        <td
          style={{
            backgroundColor: `${
              renderCorrectedIop(obj.cct.re, obj.at.re) > 20 ? "red" : ""
            }`,
          }}
        >
          <ControlGroup>
            {renderCorrectedIop(obj.cct.re, obj.at.re)}
          </ControlGroup>
        </td>
        <td
          style={{
            backgroundColor: `${
              renderCorrectedIop(obj.cct.le, obj.at.le) > 20 ? "red" : ""
            }`,
          }}
        >
          <ControlGroup>
            {renderCorrectedIop(obj.cct.le, obj.at.le)}
          </ControlGroup>
        </td>
      </tr>
    );
  };
  renderIop = () => {
    let obj = this.props.vision;
    let flag = false;
    if (obj.cct.le || obj.cct.re) {
      flag = true;
    }

    return (
      <table class="bp5-html-table bp5-html-table-condensed bp5-html-table-bordered bp5-html-table-striped">
        <thead>
          <tr>
            {" "}
            {flag ? <th></th> : null}
            <th>R/NCT</th>
            <th style={{ borderRight: "2px solid #A7B6C2" }}>L/NCT</th>
            <th>R/AT</th>
            <th>L/AT</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {flag ? <td>Measured</td> : null}

            <td style={{ backgroundColor: `${obj.nct.re > 20 ? "red" : ""}` }}>
              <ControlGroup>
                {" "}
                <NumInpVision
                  min={0}
                  max={120}
                  fix={0}
                  value={obj.nct.re}
                  major={3}
                  minor={1}
                  iop={true}
                  onUpdate={(item) => this.props.updateVision(`nct.re`, item)}
                />
              </ControlGroup>
            </td>
            <td
              style={{
                backgroundColor: `${obj.nct.le > 20 ? "red" : ""}`,
                borderRight: "2px solid #A7B6C2",
              }}
            >
              <ControlGroup>
                {" "}
                <NumInpVision
                  min={0}
                  max={120}
                  fix={0}
                  value={obj.nct.le}
                  major={3}
                  minor={1}
                  iop={true}
                  onUpdate={(item) => this.props.updateVision(`nct.le`, item)}
                />{" "}
              </ControlGroup>
            </td>

            <td style={{ backgroundColor: `${obj.at.re > 20 ? "red" : ""}` }}>
              <ControlGroup>
                <NumInpVision
                  min={0}
                  max={120}
                  value={obj.at.re}
                  fix={0}
                  major={3}
                  minor={1}
                  iop={true}
                  onUpdate={(item) => this.props.updateVision(`at.re`, item)}
                />{" "}
              </ControlGroup>
            </td>
            <td style={{ backgroundColor: `${obj.at.le > 20 ? "red" : ""}` }}>
              <ControlGroup>
                <NumInpVision
                  min={0}
                  max={120}
                  fix={0}
                  value={obj.at.le}
                  major={3}
                  minor={1}
                  iop={true}
                  onUpdate={(item) => this.props.updateVision(`at.le`, item)}
                />{" "}
              </ControlGroup>
            </td>
          </tr>
          {this.rowCorrectedIOP()}
        </tbody>
      </table>
    );
  };
  render() {
    return <div style={{ marginRight: "5px" }}>{this.renderIop()}</div>;
  }
}
export default IOPTable;
