import {
  Button,
  Navbar,
  NavbarHeading,
  Dialog,
  DialogBody,
  DialogFooter,
  Classes,
} from "@blueprintjs/core";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter } from "react-router-dom";
import LogoutButton from "../utils/LogOutButton";
import { withAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import {
  apiCallPost,
  niceTime,
  isInPastDateStrict,
  copyToClipboard,
} from "../fns/util";
import cogoToast from "cogo-toast";

class Navigation extends React.Component {
  state = {
    isModalOpen: false,
  };

  sudoChangeTo = async (id) => {
    try {
      cogoToast.loading("Switching affliation", { position: "top-center" });
      let res = await apiCallPost(`/sudo/switchUserAffliation`, {
        targetId: id,
      });
      this.props.authStore.prefix = res.prefix;
      this.props.authStore.isLoggedIn = res.true;
      this.props.authStore.scope = res.scope;
      this.props.authStore.name = res.name;
      this.props.authStore.sudoCanSwitchTo = res.sudoCanSwitchTo || [];
      cogoToast.success("Done!", { position: "top-center" });
      this.toggleOverlay();
      window.location.reload();
    } catch (err) {
      cogoToast.error("Error: " + JSON.stringify(err), {
        position: "top-center",
      });
    }
  };

  toggleOverlay = () => {
    {
      if (this.props.authStore.sudoCanSwitchTo.length > 0) {
        this.setState({ isModalOpen: !this.state.isModalOpen });
      }
    }
  };
  render() {
    return (
      <div>
        <Navbar className=" bp5-dark navbar" fixedToTop>
          <NavbarHeading
            className="navbar-heading"
            onClick={() => this.props.history.push("/")}
          >
            NewChart
          </NavbarHeading>
          <LogoutButton />
          <Button
            minimal
            intent="success"
            text={`${this.props.auth0.user.name} | ${_.startCase(
              this.props.authStore.prefix
            )}`}
            onClick={() => this.toggleOverlay()}
            className="navbar-button"
          />
          <Button
            minimal
            icon="search"
            intent="primary"
            className="navbar-button"
            onClick={() => {
              this.props.bookingStore.isList = false;
              this.props.history.push("/");
            }}
          />{" "}
          <Button
            minimal
            icon="calendar"
            intent="primary"
            className="navbar-button"
            onClick={() => this.props.history.push("/booking")}
          />
          <Button
            minimal
            icon="properties"
            intent="primary"
            className="navbar-button"
            onClick={() => {
              this.props.bookingStore.isList = true;
              this.props.history.push("/list");
            }}
          />
          <Button
            minimal
            icon="doughnut-chart"
            intent="primary"
            className="navbar-button"
            onClick={() => this.props.history.push("/clinicaldashboard")}
          />
          <Button
            minimal
            icon="mugshot"
            intent="primary"
            className="navbar-button"
            onClick={() => this.props.history.push("/patientdetails")}
          />
          <Button
            minimal
            icon="filter-list"
            intent="primary"
            className="navbar-button"
            onClick={() => this.props.history.push("/report")}
          />
          <Button
            minimal
            icon="people"
            intent="primary"
            className="navbar-button"
            onClick={() => this.props.history.push("/users")}
          />
          <Button
            minimal
            icon="code-block"
            intent="warning"
            className="navbar-button"
            onClick={() => this.props.history.push("/templates")}
          />{" "}
          <Button
            minimal
            icon="grid"
            intent="success"
            className="navbar-button"
            onClick={() => this.props.history.push("/schedules")}
          />
          <Button
            minimal
            icon="bank-account"
            intent="success"
            className="navbar-button"
            onClick={() => this.props.history.push("/cashtill")}
          />
          <Button
            className="navbar-button"
            icon="predictive-analysis"
            intent="danger"
            minimal
            onClick={() => this.props.history.push("/chat")}
          />
        </Navbar>
        <Dialog
          title="[SUDO] Change Current Hospital"
          icon="info-sign"
          isOpen={this.state.isModalOpen}
        >
          <DialogBody>
            {this.props.authStore.sudoCanSwitchTo.map((hp) => (
              <div>
                <Button
                  intent="success"
                  onClick={async () => await this.sudoChangeTo(hp._id)}
                  text={hp.name}
                />
                <br />
              </div>
            ))}
          </DialogBody>
          <DialogFooter
            actions={
              <Button
                intent="primary"
                text="Close"
                onClick={() => this.toggleOverlay()}
              />
            }
          />
        </Dialog>
      </div>
    );
  }
}

export default inject(
  "authStore",
  "bookingStore"
)(observer(withRouter(withAuth0(Navigation))));
