import React from "react";
import AsyncSelWrap from "../utils/AsyncSelWrap";
import { inject, observer } from "mobx-react";
import { apiCallPost, apiCallPostCancellable } from "../fns/util";
import cogoToast from "cogo-toast";
import { ControlGroup, Tag } from "@blueprintjs/core";
import _ from "lodash";

class CanonicalSearcher extends React.Component {
  state = { searching: false, searchResults: [] };

  search = async (string, sr, sing, dontUseCancellable = false) => {
    if (!string) {
      this.setState({ [sr]: [] });
      return;
    }
    try {
      this.setState({ [sing]: true, [sr]: [] });
      let API_URL = `/clinical2/${this.props.authStore.prefix}/canonical-search`;
      let PAYLOAD = { prefix: string, entityType: this.props.entityType };
      let res;
      if (dontUseCancellable) {
        res = await apiCallPost(API_URL, PAYLOAD);
      } else {
        res = await apiCallPostCancellable(API_URL, PAYLOAD);
      }
      if (res) {
        this.setState({ [sr]: res, [sing]: false });
        this.props.onSearch();
      }
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details."
      );
    }
  };
  render() {
    return (
      <ControlGroup>
        {this.props.isClinicalComplicatedThing ? null : (
          <Tag minimal>Search {this.props.searchTagName || ""}</Tag>
        )}
        <AsyncSelWrap
          width={this.props.width}
          isClinicalComplicatedThing={this.props.isClinicalComplicatedThing}
          onBlur={() => {
            if (this.props.isClinicalComplicatedThing) {
              this.props.onBlur();
            }
          }}
          onFocus={() => {
            if (this.props.isClinicalComplicatedThing) {
              this.search(
                this.props.trySearchingWithName,
                "searchResults",
                "searching"
              );
            }
          }}
          query={this.props.query}
          isColfax={true}
          placeholder={this.props.placeholder}
          searching={this.state.searching}
          items={this.state.searchResults.map((each) => {
            return {
              ...each,
              item: each.entityName,
            };
          })}
          selectedItem={null}
          update={(item) => {
            if (this.props.trySearchingWith) {
              this.props.onUpdate(item);
            }
            if (this.props.onType) {
              this.props.onType(item);
            }
            this.search(item, "searchResults", "searching");
          }}
          onChange={(item) => {
            this.props.onChange(item);
          }}
        />
      </ControlGroup>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(CanonicalSearcher));
