import React from "react";
import _ from "lodash";
import { ResponsiveBar } from "@nivo/bar";
import { ControlGroup, Tag } from "@blueprintjs/core";

class SpecularGraph extends React.Component {
  renderGraph = () => {
    let data = this.props.data.value;
    if (this.props.graphType === "area") {
      data = _.reverse(data);
    }
    return (
      <ResponsiveBar
        layout="horizontal"
        data={data}
        keys={["count"]}
        indexBy="bin"
        theme={{
          textColor: "white",
          fontFamily: "Axis",
          labels: { text: { fontFamily: "Axis" } },
          tooltip: {
            container: {
              backgroundColor: "black",
            },
          },
          grid: {
            line: {
              stroke: "#696363",
            },
          },
        }}
        label={(d) => `${d.value} %`}
        margin={{ top: 10, right: 10, bottom: 50, left: 80 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          itemTextColor: "white",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    );
  };
  render() {
    return (
      <div
        style={{
          height: "400px",
          width: "400px",
          marginLeft: "10px",
        }}
      >
        <ControlGroup>
          <Tag minimal large>
            {`${this.props.data.description} ${
              this.props.graphType === "area" ? "(μm²)" : ""
            }`}
          </Tag>
        </ControlGroup>
        {this.renderGraph()}
      </div>
    );
  }
}

export default SpecularGraph;
