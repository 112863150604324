import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { LicenseManager } from "ag-grid-enterprise";
import {
  Button,
  ControlGroup,
  InputGroup,
  Tag,
  Card,
  Elevation,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import { DateRangeInput } from "@blueprintjs/datetime";
import {
  niceDate,
  apiCallPost,
  niceDateTime,
  isInPastDateStrict,
  isInFutureDateStrict,
} from "../fns/util";
import cogoToast from "cogo-toast";
import { inject, observer } from "mobx-react";
import MultiSelectWrapper from "../utils/MultiSelectWrapper";
import _ from "lodash";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import { toJS } from "mobx";
import PDFExportPanel from "./pdfExport/PDFExportPanel.js";
import { parse } from "uuid";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-051028}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Radical_Health-Tech_Private_Limited}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{29_January_2025}____[v3]_[01]_MTczODEwODgwMDAwMA==167e3defabcd13ca47ac09151c6656b1"
);
class DataGrid extends React.Component {
  componentDidMount() {
    this.setState({ sites: toJS(this.props.listStore.siteList) });
  }
  typesOfGrids = [
    "Finance",
    "Appointments",
    "Bookings",
    "Packages",
    "CMOH Report",
    "CMOH Report (v2)",
    "Turbo",
    "Clinical (v2) Progress",
    "Clinical (v2) Entities",
    "Investigations & Assets",
    "Surgery Advice",
    "Cash Collection",
  ];
  financeColumns = [
    {
      headerName: "Bill Details",
      children: [
        {
          headerName: "User",
          field: "userName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Time",
          field: "ts",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Site",
          field: "site",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Patient",
      children: [
        {
          headerName: "Name",
          field: "patientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "PID",
          field: "genericPid",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Phone",
          field: "patientPhone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Age",
          field: "patientAge",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.format2Decimal(params.value),
        },
        {
          headerName: "Sex",
          field: "patientSex",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Event Details",
      children: [
        {
          headerName: "Type Of Event",
          field: "kindOfEvent",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Channel",
          field: "channel",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Advance Amount",
          field: "advanceAmount",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Amount",
          field: "amount",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Payment Reference",
          field: "reference",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
        },
        {
          headerName: "Discount Reason",
          field: "discountReason",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
        },
      ],
    },
    {
      headerName: "Extra Fields",
      children: [
        {
          headerName: "Billing: Day",
          field: "tsParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.day),
        },
        {
          headerName: "Billing: Month",
          field: "tsParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.month),
        },
        {
          headerName: "Billing: Quarter",
          field: "tsQtr",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Billing: Year",
          field: "tsParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.year),
        },
        {
          headerName: "Billing Time: Formatted",
          field: "tsFmt",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },

        {
          headerName: "_id",
          field: "_id",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
      ],
    },
  ];
  packageColumns = [
    {
      headerName: "Bill Details",
      children: [
        {
          headerName: "Billed By",
          field: "billedBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Billed At",
          field: "activatedAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Site",
          field: "site",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Patient",
      children: [
        {
          headerName: "Name",
          field: "patientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "PID",
          field: "genericPid",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Phone",
          field: "patientPhone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Age",
          field: "patientAge",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.format2Decimal(params.value),
        },
        {
          headerName: "Sex",
          field: "patientSex",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Event Details",
      children: [
        {
          headerName: "Resource",
          field: "resource",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Eye",
          field: "whichEye",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Referring Doctor",
          field: "referringDoctor",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          //   valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Panel",
          field: "ratePanel",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          //    valueFormatter: (params) => this.formatText(params.value),
        },

        {
          headerName: "Rate",
          field: "rate",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Discount",
          field: "discount",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Paid By Patient",
          field: "cash",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Cashless",
          field: "cashless",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
      ],
    },
    {
      headerName: "Extra Fields",
      children: [
        {
          headerName: "Billing Time: Formatted",
          field: "activatedAtFmt",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },

        {
          headerName: "_id",
          field: "_id",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
      ],
    },
  ];
  bookingsColumns = [
    {
      headerName: "Booking Details",
      children: [
        {
          headerName: "Booked By",
          field: "userName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Booked At",
          field: "ts",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Site",
          field: "site",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Context",
          field: "context",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Channel",
          field: "channel",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Choice",
          field: "choice",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Status",
          field: "appStatus",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Docket Number",
          field: "sysDocketNo",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          //    valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },

    {
      headerName: "Patient",
      children: [
        {
          headerName: "Name",
          field: "patientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "PID",
          field: "genericPid",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Phone",
          field: "patientPhone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Age",
          field: "patientAge",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.format2Decimal(params.value),
        },
        {
          headerName: "Sex",
          field: "patientSex",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Type",
          field: "typeOfPatient",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Details",
      children: [
        {
          headerName: "Appointment Time",
          field: "appTime",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Resource",
          field: "resource",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          //  valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Type Of Service",
          field: "kindOfLineItem",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Remarks",
          field: "remarks",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Extra Fields",
      children: [
        {
          headerName: "Booking: Day",
          field: "tsParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.day),
        },
        {
          headerName: "Booking: Month",
          field: "tsParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.month),
        },
        {
          headerName: "Booking: Quarter",
          field: "tsQtr",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Booking: Year",
          field: "tsParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.year),
        },
        {
          headerName: "Booking Time: Formatted",
          field: "tsFmt",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Appointment: Day",
          field: "appTimeParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.day),
        },
        {
          headerName: "Appointment: Month",
          field: "appTimeParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.month),
        },
        {
          headerName: "Appointment: Quarter",
          field: "appTimeQtr",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Appointment: Year",
          field: "appTimeParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.year),
        },
        {
          headerName: "Appointment Time: Formatted",
          field: "appTimeFmt",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "_id",
          field: "_id",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
      ],
    },
  ];
  appointmentsColumns = [
    {
      headerName: "Administrative Details",
      children: [
        {
          headerName: "Booked By",
          field: "bookedBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Booked At",
          field: "bookedAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Billed By",
          field: "billedBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Bill Number",
          field: "billingRef",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          //    valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Billed At",
          field: "activatedAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Walk In",
          field: "isWalkIn",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Status",
          field: "appStatus",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Site",
          field: "site",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Docket",
          field: "docketNo",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },

        {
          headerName: "Remarks",
          field: "remarks",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Cancelled By",
          field: "cancelledBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Cancellation Reason",
          field: "cancelReasonText",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },

    {
      headerName: "Patient",
      children: [
        {
          headerName: "Name",
          field: "patientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "PID",
          field: "genericPid",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Phone",
          field: "patientPhone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Age",
          field: "patientAge",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.format2Decimal(params.value),
        },
        {
          headerName: "Sex",
          field: "patientSex",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Details",
      children: [
        {
          headerName: "Appointment Time",
          field: "appTime",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Resource",
          field: "resource",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Subresource",
          field: "slotType",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Type Of Service",
          field: "kindOfLineItem",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },

        {
          headerName: "Referring Doctor",
          field: "referringDoctor",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          //   valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Panel",
          field: "ratePanel",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          //    valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Choice",
          field: "wasDocPtChoice",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },

        {
          headerName: "Rate",
          field: "rate",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Discount",
          field: "discount",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Paid By Patient",
          field: "cash",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Cashless",
          field: "cashless",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
      ],
    },
    {
      headerName: "Extra Fields",
      children: [
        {
          headerName: "Booking: Day",
          field: "bookedAtParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.day),
        },
        {
          headerName: "Booking: Month",
          field: "bookedAtParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.month),
        },
        {
          headerName: "Booking: Quarter",
          field: "bookedAtQtr",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Booking: Year",
          field: "bookedAtParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.year),
        },
        {
          headerName: "Booking Time: Formatted",
          field: "bookedAtFmt",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "Appointment: Day",
          field: "appTimeParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.day),
        },
        {
          headerName: "Appointment: Month",
          field: "appTimeParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.month),
        },
        {
          headerName: "Appointment: Quarter",
          field: "appTimeQtr",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Appointment: Year",
          field: "appTimeParts",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value.year),
        },
        {
          headerName: "Appointment Time: Formatted",
          field: "appTimeFmt",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
        {
          headerName: "_id",
          field: "_id",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
      ],
    },
  ];
  cmohReportColumns = [
    {
      headerName: "Patient Details",
      children: [
        {
          headerName: "PID",
          field: "_id",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
        },
        {
          headerName: "Name",
          field: "patientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Age Group",
          field: "AgeGroup",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Sex",
          field: "patientSex",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Age",
          field: "patientAge",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Clinical Details",
      children: [
        {
          headerName: "Glasses Advised",
          field: "glassesAdviceGiven",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Medicines Advised",
          field: "hasRxPrescribed",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Cataract Advised",
          field: "hasCataractSx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "DR Inj Advised",
          field: "hasDrInj",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Other Sx Advised",
          field: "hasOtherSx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "DR Diagnosed",
          field: "hasDRDx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Glaucoma Diagnosed",
          field: "hasGlaucomaDx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "RoP Diagnosed",
          field: "hasROPDx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "CoB Diagnosed",
          field: "hasCoBDx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Other Diagnosed",
          field: "hasOtherDx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "OPD Procedure Advised",
          field: "hasOPDProcedureAdv",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "OPD Procedure Done",
          field: "hasOPDProcedure",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
  ];

  cmohReportV2Columns = [
    {
      headerName: "Details",
      children: [
        {
          headerName: "Category",
          field: "Category",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
        },
        {
          headerName: "NumScreened",
          field: "NumScreened",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "GlassesAdviceGiven",
          field: "GlassesAdviceGiven",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "CataractSurgeryAdv",
          field: "CataractSurgery",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "DrInjectionAdv",
          field: "DrInjection",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "DRDiagnosed",
          field: "DRDiagnosed",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "GlaucomaDiagnosed",
          field: "GlaucomaDiagnosed",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "ROPDiagnosed",
          field: "ROP",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "SquintDiagnosed",
          field: "SquintDiagnosed",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "CoBDiagnosed",
          field: "CorenealBlindnessDiagnosed",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "PresbyopiaDiagnosed",
          field: "PresbyopiaDiagnosed",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "LowVisionDiagnosed",
          field: "LowVisionDiagnosed",
          filter: "agMultiColumnFilter",

          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "OtherDiseaseDiagnosed",
          field: "OtherDiseaseDiagnosed",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "RxPrescribed",
          field: "RxPrescribed",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
  ];

  cmohReportV2ExpandedColumns = [
    {
      headerName: "Patient Details",
      children: [
        {
          headerName: "PID",
          field: "_id",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
        },
        {
          headerName: "Name",
          field: "patientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Age Group",
          field: "AgeGroup",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Sex",
          field: "patientSex",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Age",
          field: "patientAge",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Clinical Details",
      children: [
        {
          headerName: "Glasses Advised",
          field: "glassesAdviceGiven",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Medicines Advised",
          field: "hasRxPrescribed",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Cataract Advised",
          field: "hasCataractSx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "DR Inj Advised",
          field: "hasDrInj",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Other Sx Advised",
          field: "hasOtherSx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "DR Diagnosed",
          field: "hasDRDx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Glaucoma Diagnosed",
          field: "hasGlaucomaDx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "RoP Diagnosed",
          field: "hasROPDx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "CoB Diagnosed",
          field: "hasCoBDx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Other Diagnosed",
          field: "hasOtherDx",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "OPD Procedure Advised",
          field: "hasOPDProcedureAdv",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "OPD Procedure Done",
          field: "hasOPDProcedure",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
  ];
  turboColumns = [
    {
      headerName: "Visit Details",
      children: [
        {
          headerName: "Patient",
          field: "visitPatientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Doctor",
          field: "visitDoctor",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Site",
          field: "visitBranch",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Date",
          field: "visitOnDay",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDate(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
      ],
    },
    {
      headerName: "Turbo Details",
      children: [
        {
          headerName: "Recording",
          field: "audioRecordingTime",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
        },
        {
          headerName: "Transcription",
          field: "audioTranscriptionTime",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
        },
        {
          headerName: "Polish",
          field: "correctionTime",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
        },
        {
          headerName: "Structure",
          field: "structuringTime",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
        },
        {
          headerName: "Parallel",
          field: "parallelTime",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
        },
        {
          headerName: "Total",
          field: "totalTime",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
        },
      ],
    },
    {
      headerName: "Meta Data",
      children: [
        {
          headerName: "CID",
          field: "correctionId",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
        },
        {
          headerName: "SID",
          field: "structuringId",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
  ];
  clinicalProgressColumns = [
    {
      headerName: "Patient Details",
      children: [
        {
          headerName: "PID",
          field: "patientPid",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
        },
        {
          headerName: "Name",
          field: "patientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Sex",
          field: "patientSex",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        // {
        //   headerName: "Is Dilated",
        //   field: "isDilationDone",
        //   filter: "agMultiColumnFilter",
        //   enableRowGroup: true,
        //   enablePivot: true,
        //   floatingFilter: true,
        //   aggFunc: "count",
        //   valueFormatter: (params) => this.formatText(params.value),
        // },
        {
          headerName: "Age",
          field: "patientAge",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.format2Decimal(params.value),
        },
      ],
    },
    {
      headerName: "Booking Details",
      children: [
        {
          headerName: "Date",
          field: "onDay",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDate(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Site",
          field: "branch",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Purpose",
          field: "purpose",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Doctor",
          field: "doctor",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Registration Timings",
      children: [
        {
          headerName: "Registration Time",
          field: "registrationTime",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
      ],
    },
    {
      headerName: "Refraction Timings",
      children: [
        {
          headerName: "Refcn Done",
          field: "autoRefcnDone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Refcn Done At",
          field: "autoRefcnDoneAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Refcn Done By",
          field: "autoRefcnDoneBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "NCT Timings",
      children: [
        {
          headerName: "NCT Done",
          field: "nctDone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "NCT Done At",
          field: "nctDoneAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "NCT Done By",
          field: "nctDoneBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },

        {
          headerName: "AT Done",
          field: "atDone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "AT Done At",
          field: "atDoneAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "AT Done By",
          field: "atDoneBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Vision Workup Timings",
      children: [
        {
          headerName: "Vision Done",
          field: "visionDone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Vision Done At",
          field: "visionDoneAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Vision Done By",
          field: "visionDoneBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Consultation Timings",
      children: [
        {
          headerName: "Checkout Done",
          field: "done",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Checkout At",
          field: "doneAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Checkout By",
          field: "doneBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Data Entry Timings",
      children: [
        {
          headerName: "Data Entry Done",
          field: "dataEntryDone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Data Entry Done At",
          field: "dataEntryDoneAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Data Entry By",
          field: "dataEntryDoneBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Counsellor Timings",
      children: [
        {
          headerName: "Counselling Done",
          field: "counselledDone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Counselling At",
          field: "counselledDoneAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Counselling By",
          field: "counselledDoneBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Addl. Counsellor Timings",
      children: [
        {
          headerName: "Addl. Counselling Done",
          field: "auxCounselledDone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Addl. Counselling At",
          field: "auxCounselledDoneAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Addl. Counselling By",
          field: "auxCounselledDoneBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Sx Counsellor Timings",
      children: [
        {
          headerName: "Sx Counselling Done",
          field: "sxCounselledDone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Sx Counselling At",
          field: "sxCounselledAt",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDateTime(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Sx Counselling By",
          field: "sxCounselledBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Duration",
      children: [
        {
          headerName: "Workup",
          field: "workupDuration",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "avg",
          valueFormatter: (params) => parseFloat(params.value).toFixed(2),
        },
        {
          headerName: "DE",
          field: "deDuration",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "avg",
          valueFormatter: (params) => parseFloat(params.value).toFixed(2),
        },
        {
          headerName: "Total",
          field: "totalDuration",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "avg",
          valueFormatter: (params) => parseFloat(params.value).toFixed(2),
        },
      ],
    },
    {
      headerName: "Progress Details",
      children: [
        {
          headerName: "_id",
          field: "_id",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
      ],
    },
  ];
  clinicalEntityColumns = [
    {
      headerName: "Patient Details",
      children: [
        {
          headerName: "PID",
          field: "patientPid",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
        },
        {
          headerName: "Name",
          field: "patientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Sex",
          field: "patientSex",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Age",
          field: "patientAge",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.format2Decimal(params.value),
        },
      ],
    },
    {
      headerName: "Booking Details",
      children: [
        {
          headerName: "Date",
          field: "onDay",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDate(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Site",
          field: "branch",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Purpose",
          field: "purpose",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Doctor",
          field: "doctor",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
    {
      headerName: "Entity Details",
      children: [
        {
          headerName: "Type",
          field: "entityType",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Section",
          field: "entitySection",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Subsection",
          field: "entitySubsection",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Organ",
          field: "entityOrgan",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Anatomical Part",
          field: "entityAnatomicalPart",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Laterality",
          field: "entityLaterality",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Name",
          field: "entityName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Is Diagnosis?",
          field: "entityIsDiagnosis",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "yc",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Created By",
          field: "entityCreatedBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "_id",
          field: "_id",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
        },
      ],
    },
  ];

  cashCollectionColumns = [
    {
      headerName: "Cash Collection Details",
      children: [
        {
          headerName: "Resource",
          field: "resource",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Cash",
          field: "cash",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Cashless",
          field: "cashless",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Discount",
          field: "discount",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatCurrency(params),
        },
        {
          headerName: "Number of Patients",
          field: "numBookings",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Number of Patients [New]",
          field: "numNewPt",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Number of Patients [Old]",
          field: "numOldPt",
          filter: "agNumberColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "sum",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Date",
          field: "date",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => params.value,
        },
      ],
    },
  ];

  iandAColumns = [
    {
      headerName: "Investigations & Assets",
      children: [
        {
          headerName: "PID",
          field: "patientPid",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
        },
        {
          headerName: "Name",
          field: "patientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Sex",
          field: "patientSex",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Age",
          field: "patientAge",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.format2Decimal(params.value),
        },
        {
          headerName: "Date",
          field: "onDay",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDate(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Test",
          field: "fileFrom",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Organ",
          field: "organ",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Anatomical Part",
          field: "anatomicalPart",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Laterality",
          field: "laterality",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Notes",
          field: "notes",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Created By",
          field: "createdBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Updated By",
          field: "updatedBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Uploaded By",
          field: "uploadedBy",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
  ];

  surgeryAdviceColumns = [
    {
      headerName: "Surgery Advice",
      children: [
        {
          headerName: "Site",
          field: "branch",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Date",
          field: "onDay",
          filter: "agDateColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          chartDataType: "time",
          valueFormatter: (params) => niceDate(params.value),
          filterParams: {
            comparator: function (filterDates, cellValue) {
              let date = new Date(cellValue);
              let isPast = isInPastDateStrict(date, filterDates);
              let isFuture = isInFutureDateStrict(date, filterDates);
              if (isPast) {
                return -1;
              } else if (isFuture) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Doctor Name",
          field: "doctor",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Surgery Advised",
          field: "entitySubsection",
          filter: "agMultiColumnFilter",
          // hide: true,
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          // filter: "agTextColumnFilter",
          // filterParams: {
          //   textCustomComparator: (filterValue, cellValue) => {
          //     console.log("dsds", cellValue);
          //     if (cellValue == null || cellValue === "") return false; // Exclude blanks
          //     return (
          //       cellValue
          //         .toString()
          //         .toLowerCase()
          //         .indexOf(filterValue.toLowerCase()) >= 0
          //     );
          //   },
          // },
          valueGetter: (params) => {
            if (params.data.entitySubsection === "SURGERY") {
              return params.data.entityName;
            } else {
              return "";
            }
          },
          valueFormatter: (params) => {
            return params.value;
          },
        },
        {
          headerName: "Eye Laterality",
          field: "entityLaterality",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Anaesthesia",
          field: "anaesthesia",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Surgery Free Text",
          field: "notes",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Patient PID",
          field: "patientPid",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },

        {
          headerName: "Patient Name",
          field: "patientName",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
        {
          headerName: "Age",
          field: "patientAge",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.format2Decimal(params.value),
        },
        {
          headerName: "Sex",
          field: "patientSex",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },

        {
          headerName: "Diagnosis",
          field: "entitySubsection",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueGetter: (params) => {
            if (params.data.entitySubsection === "DIAGNOSIS") {
              return params.data.entityName;
            }
            return "";
          },
          valueFormatter: (params) => {
            return params.value;
          },
        },

        {
          headerName: "Phone Number",
          field: "patientPhone",
          filter: "agMultiColumnFilter",
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          aggFunc: "count",
          valueFormatter: (params) => this.formatText(params.value),
        },
      ],
    },
  ];

  state = {
    gridApi: null,
    columnApi: null,
    typeOfGrid: null,
    sites: [],
    isLoading: false,
    selectedSites: [],
    waiting: false,
    quickFilterText: null,
    rowData: [],
    aggregations: {
      sum: (params) => this.sumAggregation(params),
      average: (params) => this.averageAggregation(params),
      max: (params) => this.maxAggregation(params),
      min: (params) => this.minAggregation(params),
      count: (params) => this.countAggregation(params),
      yc: (params) => this.yesCountAggregation(params),
    },
    columnDefs: [],
  };
  sumAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));

    arr = arr.filter((value) => !Number.isNaN(value));

    return _.sum(arr);
  };
  averageAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.mean(arr);
  };
  maxAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.max(arr);
  };
  minAggregation = (params) => {
    let arr = _.map(params.values, (each) => Number(each));
    arr = arr.filter((value) => !Number.isNaN(value));
    return _.min(arr);
  };
  countAggregation = (params) => {
    return params.values.length;
  };
  yesCountAggregation = (params) => {
    if (params.values.length < 1) return 0;
    let f = params.values[0];
    if (typeof f === "number") return _.sum(params.values);
    if (typeof f === "string")
      return _.filter(params.values, (v) => v === "Yes").length;
  };
  formatCurrency = (params) => {
    let num = Number(params.value);
    return num.toLocaleString("en-IN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      style: "currency",
      currency: "INR",
    });
  };
  formatText = (params) => {
    if (params === "NA") {
      return "";
    }
    return _.startCase(_.lowerCase(params));
  };
  format2Decimal = (params) => {
    return Number(params).toFixed(2) + " years";
  };
  getData = async () => {
    if (!this.state.typeOfGrid) {
      cogoToast.error("Please choose the type of data you want.", {
        position: "bottom-left",
      });
    }
    if (this.state.typeOfGrid === "Finance") {
      this.setState({ columnDefs: this.financeColumns });
      if (!this.state.selectedSites || !this.state.selectedSites.length) {
        cogoToast.error("Please select sites.", { position: "bottom-left" });
        return;
      }
      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          sites: this.state.selectedSites,
          selectedDateRange: this.state.selectedDateRange,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/events/payment`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "Appointments") {
      this.setState({ columnDefs: this.appointmentsColumns });
      if (!this.state.selectedSites || !this.state.selectedSites.length) {
        cogoToast.error("Please select sites.", { position: "bottom-left" });
        return;
      }
      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          sites: this.state.selectedSites,
          selectedDateRange: this.state.selectedDateRange,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/tables/appointments`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "Packages") {
      this.setState({ columnDefs: this.packageColumns });
      if (!this.state.selectedSites || !this.state.selectedSites.length) {
        cogoToast.error("Please select sites.", { position: "bottom-left" });
        return;
      }
      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          sites: this.state.selectedSites,
          selectedDateRange: this.state.selectedDateRange,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/tables/packages`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "Bookings") {
      this.setState({ columnDefs: this.bookingsColumns });

      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          selectedDateRange: this.state.selectedDateRange,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/tables/bookings`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "CMOH Report") {
      this.setState({ columnDefs: this.cmohReportColumns });

      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      if (!this.state.selectedSites || !this.state.selectedSites.length) {
        cogoToast.error("Please select sites.", { position: "bottom-left" });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          selectedDateRange: this.state.selectedDateRange,
          sites: this.state.selectedSites,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/tables/cmohReport`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "CMOH Report (v2)") {
      this.setState({ columnDefs: this.cmohReportV2Columns });

      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      if (!this.state.selectedSites || !this.state.selectedSites.length) {
        cogoToast.error("Please select sites.", {
          position: "bottom-left",
        });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          selectedDateRange: this.state.selectedDateRange,
          sites: this.state.selectedSites,
          doReduce: true,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/tables/cmohReport2`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "Turbo") {
      this.setState({ columnDefs: this.turboColumns });

      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      if (!this.state.selectedSites || !this.state.selectedSites.length) {
        cogoToast.error("Please select sites.", { position: "bottom-left" });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          createdIn: this.state.selectedDateRange,
          sites: this.state.selectedSites,
        };
        let res = await apiCallPost(
          `/clinical-stat-counter/${this.props.authStore.prefix}/getStats`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "Clinical (v2) Progress") {
      this.setState({ columnDefs: this.clinicalProgressColumns });

      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      if (!this.state.selectedSites || !this.state.selectedSites.length) {
        cogoToast.error("Please select sites.", {
          position: "bottom-left",
        });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          selectedDateRange: this.state.selectedDateRange,
          sites: this.state.selectedSites,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/tables/clinicalProgressReport`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "Clinical (v2) Entities") {
      this.setState({ columnDefs: this.clinicalEntityColumns });

      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      if (!this.state.selectedSites || !this.state.selectedSites.length) {
        cogoToast.error("Please select sites.", {
          position: "bottom-left",
        });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          selectedDateRange: this.state.selectedDateRange,
          sites: this.state.selectedSites,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/tables/clinicalEntityReport`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "Surgery Advice") {
      this.setState({ columnDefs: this.surgeryAdviceColumns });

      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      if (!this.state.selectedSites || !this.state.selectedSites.length) {
        cogoToast.error("Please select sites.", {
          position: "bottom-left",
        });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          selectedDateRange: this.state.selectedDateRange,
          sites: this.state.selectedSites,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/tables/surgeryAdviceReport`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "Cash Collection") {
      this.setState({ columnDefs: this.cashCollectionColumns });

      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      if (!this.state.selectedSites || !this.state.selectedSites.length) {
        cogoToast.error("Please select sites.", {
          position: "bottom-left",
        });
        return;
      }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          selectedDateRange: this.state.selectedDateRange,
          sites: this.state.selectedSites,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/tables/collectionReport`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
    if (this.state.typeOfGrid === "Investigations & Assets") {
      this.setState({ columnDefs: this.iandAColumns });

      if (
        !this.state.selectedDateRange ||
        !this.state.selectedDateRange[0] ||
        !this.state.selectedDateRange[1]
      ) {
        cogoToast.error("Please select date range.", {
          position: "bottom-left",
        });
        return;
      }
      // if (!this.state.selectedSites || !this.state.selectedSites.length) {
      //   cogoToast.error("Please select sites.", {
      //     position: "bottom-left",
      //   });
      //   return;
      // }
      try {
        this.setState({ rowData: [], isLoading: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let payload = {
          selectedDateRange: this.state.selectedDateRange,
          sites: this.state.selectedSites,
        };
        let res = await apiCallPost(
          `/finance/opd/${this.props.authStore.prefix}/tables/clinicalFilesReport`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });
        this.setState({ rowData: res, isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        cogoToast.error(
          "There was an error loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
  };
  renderGridDataOptions = () => {
    if (this.state.typeOfGrid === "Bookings") {
      return (
        <>
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "Appointments") {
      return (
        <>
          <Tag minimal large intent="success">
            Site
          </Tag>
          <MultiSelectWrapper
            intent="success"
            placeholder="Sites"
            items={this.state.sites}
            selectedItems={this.state.selectedSites}
            onChange={(items) => this.setState({ selectedSites: items })}
          />
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "Finance") {
      return (
        <>
          <Tag minimal large intent="success">
            Site
          </Tag>
          <MultiSelectWrapper
            intent="success"
            placeholder="Sites"
            items={this.state.sites}
            selectedItems={this.state.selectedSites}
            onChange={(items) => this.setState({ selectedSites: items })}
          />
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "Packages") {
      return (
        <>
          <Tag minimal large intent="success">
            Site
          </Tag>
          <MultiSelectWrapper
            intent="success"
            placeholder="Sites"
            items={this.state.sites}
            selectedItems={this.state.selectedSites}
            onChange={(items) => this.setState({ selectedSites: items })}
          />
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "CMOH Report") {
      return (
        <>
          <Tag minimal large intent="success">
            Site
          </Tag>
          <MultiSelectWrapper
            intent="success"
            placeholder="Sites"
            items={this.state.sites}
            selectedItems={this.state.selectedSites}
            onChange={(items) => this.setState({ selectedSites: items })}
          />
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "CMOH Report (v2)") {
      return (
        <>
          <Tag minimal large intent="success">
            Site
          </Tag>
          <MultiSelectWrapper
            intent="success"
            placeholder="Sites"
            items={this.state.sites}
            selectedItems={this.state.selectedSites}
            onChange={(items) => this.setState({ selectedSites: items })}
          />
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "Turbo") {
      return (
        <>
          <Tag minimal large intent="success">
            Site
          </Tag>
          <MultiSelectWrapper
            intent="success"
            placeholder="Sites"
            items={this.state.sites}
            selectedItems={this.state.selectedSites}
            onChange={(items) => this.setState({ selectedSites: items })}
          />
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "Clinical (v2) Progress") {
      return (
        <>
          <Tag minimal large intent="success">
            Site
          </Tag>
          <MultiSelectWrapper
            intent="success"
            placeholder="Sites"
            items={this.state.sites}
            selectedItems={this.state.selectedSites}
            onChange={(items) => this.setState({ selectedSites: items })}
          />
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "Clinical (v2) Entities") {
      return (
        <>
          <Tag minimal large intent="success">
            Site
          </Tag>
          <MultiSelectWrapper
            intent="success"
            placeholder="Sites"
            items={this.state.sites}
            selectedItems={this.state.selectedSites}
            onChange={(items) => this.setState({ selectedSites: items })}
          />
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "Cash Collection") {
      return (
        <>
          <Tag minimal large intent="success">
            Site
          </Tag>
          <MultiSelectWrapper
            intent="success"
            placeholder="Sites"
            items={this.state.sites}
            selectedItems={this.state.selectedSites}
            onChange={(items) => this.setState({ selectedSites: items })}
          />
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "Investigations & Assets") {
      return (
        <>
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
    if (this.state.typeOfGrid === "Surgery Advice") {
      return (
        <>
          <Tag minimal large intent="success">
            Site
          </Tag>
          <MultiSelectWrapper
            intent="success"
            placeholder="Sites"
            items={this.state.sites}
            selectedItems={this.state.selectedSites}
            onChange={(items) => this.setState({ selectedSites: items })}
          />
          <Tag minimal large intent="primary">
            Date Range
          </Tag>
          <DateRangeInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ selectedDateRange: value })}
            minDate={new Date(2021, 0, 1)}
            maxDate={new Date(2030, 0, 1)}
            allowSingleDayRange
            contiguousCalendarMonths={false}
          />
        </>
      );
    }
  };
  renderControlGroup = () => {
    return (
      <>
        <Tag large minimal intent="none">
          Choose Type
        </Tag>
        <SingleSelectWrapper
          items={this.typesOfGrids}
          className="bp5-dark"
          onChange={(item) => this.setState({ typeOfGrid: item })}
        />
        {this.renderGridDataOptions()}
      </>
    );
  };
  onGridReady = (params) => {
    this.setState({ gridApi: params.api, columnApi: params.columnApi });
  };

  render() {
    if (this.state.waiting) {
      return (
        <NonIdealState
          className="bp5-dark"
          icon={<Spinner />}
          title="Loading..."
          description="We're ensuring your data is consistent..."
        />
      );
    }
    return (
      <div style={{ padding: "30px", color: "white" }} className="bp5-dark">
        <div
          style={{
            display: "flex",
          }}
        >
          {" "}
          <ControlGroup
            className="bp5-dark"
            style={{
              paddingBottom: "10px",
              margin: "auto",
            }}
          >
            {this.renderControlGroup()}
            <Button
              minimal
              rightIcon="arrow-right"
              intent="success"
              text="Get Data"
              loading={this.state.isLoading}
              onClick={() => this.getData()}
            />
            <Tag minimal large intent="warning">
              Quick Search
            </Tag>
            <InputGroup
              onChange={(event) =>
                this.setState({ quickFilterText: event.target.value })
              }
            />{" "}
            <PDFExportPanel
              typeOfGrid={this.state.typeOfGrid}
              gridApi={this.state.gridApi}
              columnApi={this.state.columnApi}
            />
          </ControlGroup>
        </div>
        <div className="ag-theme-alpine-dark axis">
          <Card
            style={{
              width: "90vw",
              height: "80vh",
              margin: "auto",
              padding: "0px",
            }}
            elevation={Elevation.FOUR}
          >
            <AgGridReact
              defaultColDef={{
                sortable: true,
              }}
              ref="billingGrid"
              rowGroupPanelShow="always"
              suppressDragLeaveHidesColumns={true}
              suppressMakeColumnVisibleAfterUnGroup={true}
              quickFilterText={this.state.quickFilterText}
              cacheQuickFilter={true}
              groupMultiAutoColumn={true}
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
              rowSelection="multiple"
              rowDragManaged={true}
              enableCharts={true}
              enableRangeSelection={true}
              chartThemes={[
                "ag-default-dark",
                "ag-material-dark",
                "ag-pastel-dark",
                "ag-vivid-dark",
                "ag-solar-dark",
              ]}
              onGridReady={this.onGridReady}
              enableCellTextSelection={true}
              ensureDomOrder={true}
              enableRangeHandle={true}
              aggFuncs={this.state.aggregations}
              sideBar={true}
              statusBar={{
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                  },
                  {
                    statusPanel: "agSelectedRowCountComponent",
                    align: "left",
                  },
                  {
                    statusPanel: "agAggregationComponent",
                    align: "right",
                  },
                ],
              }}
            />
          </Card>
        </div>
      </div>
    );
  }
}

export default inject(
  "authStore",
  "bookingStore",
  "patientStore",
  "listStore"
)(observer(DataGrid));
