import { ControlGroup, H3, InputGroup, Tag, Button } from "@blueprintjs/core";
import React from "react";
import { dateFromNow, initials, niceDate } from "../../fns/util";
import DateEntry from "../../utils/DateEntry";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class BasicsPanel extends React.Component {
  state = {
    editingSex: false,
    editingFunctional: false,
    editingAge: false,
    editingPurpose: false,
  };
  sexes = ["Male", "Female", "Trans Male", "Trans Female", "Other"];
  listOfPurpose = [
    "First Visit",
    "Follow-Up Visit",
    "Routine Visit",
    "Focused Visit",
    "Wellness Visit",
    "Internal Referral",
    "External Referral",
    "Pre-Operative Visit",
    "Post-Operative Visit",
  ];
  renderTitle = () => {
    return <H3>Confirm Before Starting:</H3>;
  };
  renderName = () => {
    return (
      <ControlGroup>
        <Tag large minimal>
          Name
        </Tag>
        <Tag large intent="primary" minimal>
          {this.props.data.patientName}
        </Tag>
      </ControlGroup>
    );
  };
  renderAge = () => {
    return (
      <ControlGroup>
        <Tag large minimal>
          Age
        </Tag>
        {this.state.editingAge ? (
          <DateEntry
            date={this.props.data.dob}
            onUpdate={(date) => {
              this.setState({ editingAge: false });
              this.props.updateScalar("dob", date);
            }}
          />
        ) : (
          <Tag
            large
            intent="primary"
            minimal
            interactive
            onClick={() => this.setState({ editingAge: true })}
          >
            {niceDate(this.props.data.dob)} |{" "}
            {dateFromNow(this.props.data.dob, true)}
          </Tag>
        )}
      </ControlGroup>
    );
  };
  renderSex = () => {
    return (
      <ControlGroup>
        <Tag large minimal>
          Sex
        </Tag>
        {this.state.editingSex ? (
          <SingleSelectWrapper
            items={this.sexes}
            selectedItem={this.props.data.sex}
            onChange={(item) => {
              this.props.updateScalar("sex", item);
              this.setState({ editingSex: false });
            }}
          />
        ) : (
          <Tag
            large
            intent="primary"
            minimal
            interactive
            onClick={() => this.setState({ editingSex: true })}
          >
            {this.props.data.sex}
          </Tag>
        )}
      </ControlGroup>
    );
  };
  renderPurpose = () => {
    return (
      <ControlGroup>
        <Tag large minimal>
          Purpose
        </Tag>
        {this.state.editingPurpose ? (
          <SingleSelectWrapper
            items={this.listOfPurpose}
            selectedItem={this.props.data.todayVisit.purpose}
            onChange={(item) => {
              this.props.editVisitProps("purpose", item);
              this.setState({ editingPurpose: false });
            }}
          />
        ) : (
          <Tag
            large
            intent="primary"
            minimal
            interactive
            onClick={() => this.setState({ editingPurpose: true })}
          >
            {this.props.data.todayVisit.purpose}
          </Tag>
        )}
      </ControlGroup>
    );
  };
  renderDoctor = () => {
    return (
      <ControlGroup>
        <Tag large minimal>
          Doctor
        </Tag>
        <Tag large intent="success" minimal>
          {this.props.data.todayVisit.doctor}
        </Tag>{" "}
        <Tag large intent="warning" minimal>
          {this.props.data.todayVisit.branch}
        </Tag>
      </ControlGroup>
    );
  };
  renderFunctionalEyes = () => {
    return (
      <ControlGroup>
        {" "}
        <Tag large minimal>
          Patient Can See Through
        </Tag>
        {this.state.editingFunctional ? (
          <SingleSelectWrapper
            items={["Left Eye", "Right Eye", "Both Eyes", "No Eye"]}
            selectedItem={this.props.data.functionalEyes}
            onChange={(item) => {
              this.props.updateScalar("functionalEyes", item);
              this.setState({ editingFunctional: false });
            }}
          />
        ) : (
          <Tag
            interactive
            onClick={() => this.setState({ editingFunctional: true })}
            minimal={this.props.data.functionalEyes === "Both Eyes"}
            intent={
              this.props.data.functionalEyes === "Both Eyes"
                ? "primary"
                : "danger"
            }
          >
            Functional Eyes: {initials(this.props.data.functionalEyes)}
          </Tag>
        )}
      </ControlGroup>
    );
  };
  renderReferral = () => {
    return (
      <ControlGroup>
        <Tag minimal large>
          Referral
        </Tag>{" "}
        <Tag minimal>List</Tag>
        <SingleSelectWrapper
          items={this.props.listDocs}
          selectedItem={this.props.data.todayVisit.referredBy}
          onChange={(item) => this.props.editVisitProps("referredBy", item)}
        />
        <Tag minimal>Write</Tag>
        <InputGroup
          value={
            this.state.touched
              ? this.state.referredBy
              : this.props.data.todayVisit.referredBy
          }
          onChange={(event) =>
            this.setState({ touched: true, referredBy: event.target.value })
          }
          rightElement={
            <Button
              icon="endorsed"
              intent="success"
              minimal
              onClick={() =>
                this.props.editVisitProps(
                  "referredBy",
                  this.state.touched
                    ? this.state.referredBy
                    : this.props.data.todayVisit.referredBy
                )
              }
            />
          }
        />
      </ControlGroup>
    );
  };
  render() {
    return (
      <div style={{ padding: "20px", height: "100%" }}>
        {this.renderTitle()}
        {this.renderName()}
        {this.renderAge()}
        {this.renderSex()}
        {this.renderPurpose()}
        {this.renderFunctionalEyes()}
        {this.renderReferral()}
        <br />
        <br />
        {this.renderDoctor()}
      </div>
    );
  }
}

export default BasicsPanel;
