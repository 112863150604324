import { Button, ControlGroup } from "@blueprintjs/core";
import { DateRangeInput } from "@blueprintjs/datetime";
import React from "react";
import { niceDate } from "../fns/util";

import MultiSelectWrapper from "../utils/MultiSelectWrapper";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import { inject, observer } from "mobx-react";
import _ from "lodash";

const newDate = () => new Date();

var today = newDate();
var tomorrow = new Date(newDate().setDate(newDate().getDate() + 1));
var yesterday = new Date(newDate().setDate(newDate().getDate() - 1));
var weekLater = new Date(newDate().setDate(newDate().getDate() + 7));
var weekEarlier = new Date(newDate().setDate(newDate().getDate() - 8));
var monthLater = new Date(newDate().setDate(newDate().getDate() + 30));
var monthEarlier = new Date(newDate().setDate(newDate().getDate() - 31));

class ParamSelector extends React.Component {
  state = {
    waiting: false,
    head: null,
    selectedDateRange: [],
    beginDurationTemplate: null,
    endDurationTemplate: null,
  };
  componentDidMount() {
    this.setState({ head: this.props.condition.head || null });
    if (
      this.props.condition.head &&
      this.props.condition.head.includes("Relative")
    ) {
      this.setState({
        beginDurationTemplate: this.props.condition.value[0],
        endDurationTemplate: this.props.condition.value[1],
      });
    }
  }
  dateItems = [
    "Today",
    "Yesterday",
    "Tomorrow",
    "All - Past",
    "All - Future",
    "Last 7 Days",
    "Last 14 Days",
    "Last 30 Days",
    "Last 60 Days",
    "Next 7 Days",
    "Next 14 Days",
    "Next 30 Days",
    "Next 60 Days",
  ];
  /** Renders */

  renderMultiSelector = () => {
    let str = _.camelCase(this.state.head) + "List";
    return (
      <MultiSelectWrapper
        selectedItems={this.props.condition.value || []}
        items={this.props[str]}
        intent="success"
        onChange={(items) => {
          let it = items.filter((each) => each);
          this.props.onChange(this.props.id, {
            id: this.props.id,
            head: this.state.head,
            value: it,
          });
        }}
      />
    );
  };

  renderDateRangeTemplate = () => {
    return (
      <>
        <SingleSelectWrapper
          items={this.dateItems}
          selectedItem={this.state.beginDurationTemplate}
          onChange={(item) =>
            this.setState({ beginDurationTemplate: item }, () =>
              this.props.onChange(this.props.id, {
                id: this.props.id,
                head: this.state.head,
                value: [
                  this.state.beginDurationTemplate,
                  this.state.endDurationTemplate,
                ],
              })
            )
          }
        />{" "}
        <SingleSelectWrapper
          items={this.dateItems}
          selectedItem={this.state.endDurationTemplate}
          onChange={(item) =>
            this.setState({ endDurationTemplate: item }, () =>
              this.props.onChange(this.props.id, {
                id: this.props.id,
                head: this.state.head,
                value: [
                  this.state.beginDurationTemplate,
                  this.state.endDurationTemplate,
                ],
              })
            )
          }
        />
      </>
    );
  };
  renderDates = () => {
    return (
      <DateRangeInput
        formatDate={(date) => niceDate(date)}
        parseDate={(str) => new Date(str)}
        onChange={(value) =>
          this.setState({ selectedDateRange: value }, () =>
            this.props.onChange(this.props.id, {
              id: this.props.id,
              head: this.state.head,
              value: this.state.selectedDateRange,
            })
          )
        }
        minDate={new Date(1900, 0, 1)}
        maxDate={new Date(2030, 0, 1)}
        allowSingleDayRange
        shortcuts={[
          { label: "Today", dateRange: [today, today] },
          {
            label: "Tomorrow",
            dateRange: [tomorrow, tomorrow],
          },
          {
            label: "Next 7 Days",
            dateRange: [today, weekLater],
          },
          {
            label: "Next Month",
            dateRange: [today, monthLater],
          },
          {
            label: "Yesterday",
            dateRange: [yesterday, yesterday],
          },
          {
            label: "Last 7 Days",
            dateRange: [weekEarlier, yesterday],
          },
          {
            label: "Last Month",
            dateRange: [monthEarlier, yesterday],
          },
        ]}
        contiguousCalendarMonths={false}
        value={[
          new Date(
            this.state.selectedDateRange.length
              ? this.state.selectedDateRange[0]
              : this.props.condition.value
              ? this.props.condition.value[0]
              : null
          ),
          new Date(
            this.state.selectedDateRange.length
              ? this.state.selectedDateRange[1]
              : this.props.condition.value
              ? this.props.condition.value[1]
              : null
          ),
        ]}
      />
    );
  };
  renderWhich = () => {
    if (!this.state.head) {
      return null;
    }
    let multiSelectors = [
      "Doctor",
      "Site",
      "Appointment Status",
      "Booked By",
      "Investigation",
      "Clinical Lifecycle",
    ];
    if (multiSelectors.includes(this.state.head)) {
      return this.renderMultiSelector();
    }

    if (this.state.head.includes("Relative")) {
      return this.renderDateRangeTemplate();
    }
    if (this.state.head.includes("Specific")) {
      return this.renderDates();
    }
  };
  renderParameterHead = () => {
    return (
      <SingleSelectWrapper
        items={this.props.allowedConditions}
        selectedItem={this.state.head || this.props.condition.head}
        onChange={(item) => this.setState({ head: item })}
      />
    );
  };
  renderDelete = () => {
    return (
      <Button
        minimal
        icon="trash"
        intent="danger"
        onClick={() => this.props.onRemove(this.props.id)}
      />
    );
  };
  render() {
    return (
      <ControlGroup>
        {this.renderParameterHead()}
        {this.renderWhich()}
        {this.renderDelete()}
      </ControlGroup>
    );
  }
}

export default inject("authStore", "bookingStore")(observer(ParamSelector));
