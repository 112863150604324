import React from "react";
import { inject, observer } from "mobx-react";
import { apiCallPost } from "../fns/util";
import cogoToast from "cogo-toast";
import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  NonIdealState,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import _ from "lodash";
import SingleIDSelectWrapper from "../utils/SingleIDSelectWrapper";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";

class ScheduleSummary extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    loadedSites: [],
    rows: [],
    selectedSite: null,
    selectedDoc: null,
  };
  componentDidMount() {
    this.getSites();
  }

  getSites = async () => {
    try {
      let resSites = await apiCallPost(
        `/rai/${this.props.authStore.prefix}/getAllSitesAndGeography`,
        {}
      );
      try {
        let resTT = await apiCallPost(
          `/rai/${this.props.authStore.prefix}/getTimeTable`,
          {}
        );
        try {
          let resDocs = await apiCallPost(
            `/rai/${this.props.authStore.prefix}/getAllConsultants`,
            {}
          );
          resDocs = _.sortBy(resDocs, "name");
          this.setState({
            rows: resTT.rows,
            loadedSites: _.keys(resSites),
            loadedDocs: resDocs,
            isLoading: false,
            isError: false,
          });
        } catch (err) {
          cogoToast.error("Failed to load doctors.");
          this.setState({ isLoading: false, isError: true });
        }
      } catch (err) {
        cogoToast.error("Failed to load summaries.");
        this.setState({ isLoading: false, isError: true });
      }
    } catch (err) {
      cogoToast.error("Failed to load sites.");
      this.setState({ isLoading: false, isError: true });
    }
  };

  renderAddDoc = () => {
    return (
      <Card elevation={Elevation.FOUR}>
        <ControlGroup>
          <Tag
            large
            intent="primary"
            onRemove={() => this.setState({ selectedDoc: null })}
          >
            Search Doctors
          </Tag>
          <SingleIDSelectWrapper
            showSelectProp={true}
            width="300px"
            items={this.state.loadedDocs.map((each) => {
              return {
                ...each,
                searchName: `${each.shortcode || ""} | ${each.name}`,
              };
            })}
            selectedItem={
              this.state.selectedDoc
                ? {
                    ...this.state.selectedDoc,
                    searchName: `${this.state.selectedDoc.shortcode || ""} | ${
                      this.state.selectedDoc.name
                    }`,
                  }
                : null
            }
            labelKey="searchName"
            onChange={(item) => {
              this.setState({ selectedDoc: item });
            }}
          />
          <Tag
            large
            intent="primary"
            onRemove={() => this.setState({ selectedSite: null })}
          >
            Search Site
          </Tag>
          <SingleSelectWrapper
            showSelectProp={true}
            items={this.state.loadedSites}
            selectedItem={this.state.selectedSite}
            onChange={(item) => this.setState({ selectedSite: item })}
          />
        </ControlGroup>
      </Card>
    );
  };
  renderRows = () => {
    if (!this.state.rows.length) {
      return <NonIdealState icon="warning-sign" title="No data loaded." />;
    }
    return (
      <div>
        {this.state.changed ? (
          <Button
            icon="endorsed"
            text="Save"
            outlined
            intent="success"
            onClick={async () => {
              try {
                this.setState({ isLoading: true });
                let res = await apiCallPost(
                  `/chrono/${this.props.authStore.prefix}/updateTimeTable`,
                  { rows: this.state.rows }
                );
                this.setState({
                  selectedDoc: null,
                  isLoading: false,
                  rows: res.rows,
                  changed: false,
                });
              } catch (err) {
                this.setState({ isLoading: false });
                cogoToast.error("We ran into an error.");
              }
            }}
          />
        ) : null}
        <br />
        <br />
        <table class="bp5-html-table bp5-html-table-bordered bp5-html-table-condensed bp5-html-table-striped ">
          <thead>
            <tr>
              <th>Doctor</th>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
              <th>Sunday</th>
            </tr>
          </thead>
          <tbody>{this.renderEachDocRow()}</tbody>
        </table>
      </div>
    );
  };
  renderEachDocRow = () => {
    let rows = this.state.rows;
    return rows.map((each) => {
      if (this.state.selectedDoc) {
        if (each.doctorId !== this.state.selectedDoc._id) {
          return null;
        }
      }
      let flag = false;
      if (this.state.selectedSite) {
        for (var i = 0; i < each.summaries.length; i++) {
          if (
            each.summaries[i].firstHalf.site &&
            each.summaries[i].firstHalf.site === this.state.selectedSite
          ) {
            flag = true;
          }
          if (
            each.summaries[i].secondHalf.site &&
            each.summaries[i].secondHalf.site === this.state.selectedSite
          ) {
            flag = true;
          }
        }
      } else {
        flag = true;
      }
      if (!flag) {
        return null;
      }
      return (
        <>
          {" "}
          <tr>
            <td rowSpan={2}>{each.doctorName}</td>
            <td>{this.renderSummary(each.summaries[0], true)}</td>
            <td>{this.renderSummary(each.summaries[1], true)}</td>
            <td>{this.renderSummary(each.summaries[2], true)}</td>
            <td>{this.renderSummary(each.summaries[3], true)}</td>
            <td>{this.renderSummary(each.summaries[4], true)}</td>
            <td>{this.renderSummary(each.summaries[5], true)}</td>
            <td>{this.renderSummary(each.summaries[6], true)}</td>
          </tr>
          <tr>
            <td>{this.renderSummary(each.summaries[0], false)}</td>
            <td>{this.renderSummary(each.summaries[1], false)}</td>
            <td>{this.renderSummary(each.summaries[2], false)}</td>
            <td>{this.renderSummary(each.summaries[3], false)}</td>
            <td>{this.renderSummary(each.summaries[4], false)}</td>
            <td>{this.renderSummary(each.summaries[5], false)}</td>
            <td>{this.renderSummary(each.summaries[6], false)}</td>
          </tr>
        </>
      );
    });
  };
  renderSummary = (summ, isFirstHalf) => {
    let oo = summ.secondHalf;
    if (isFirstHalf) {
      oo = summ.firstHalf;
    }
    return (
      <ControlGroup vertical>
        <Tag
          minimal={!oo.site}
          intent={
            oo.site ? (oo.type === "IPD" ? "primary" : "success") : "danger"
          }
          icon={oo.site ? (oo.type === "IPD" ? "cut" : "prescription") : null}
        >
          {oo.site || "NA"}
        </Tag>
        {/*  {oo.site ? <Tag minimal>{oo.site || "NA"}</Tag> : null} */}
      </ControlGroup>
    );
  };
  render() {
    if (this.state.isLoading) {
      return (
        <div>
          <NonIdealState icon={<Spinner />} title="Loading" />
        </div>
      );
    }
    if (this.state.isError) {
      return (
        <div>
          <NonIdealState icon="warning-sign" title="We ran into an error." />
        </div>
      );
    }
    return (
      <div style={{ margin: "20px" }}>
        {this.renderAddDoc()}
        <Card elevation={Elevation.FOUR} style={{ marginTop: "20px" }}>
          {" "}
          {this.renderRows()}
        </Card>
      </div>
    );
  }
}

export default inject("authStore")(observer(ScheduleSummary));
