import {
  Button,
  ControlGroup,
  Dialog,
  InputGroup,
  Tag,
  Alert,
  Spinner,
} from "@blueprintjs/core";
import React from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import { apiCallPost } from "../fns/util";
import ParamSelector from "./ParamSelector";
import cogoToast from "cogo-toast";

class MagicBar extends React.Component {
  state = {
    waiting: false,
    doctorList: [],
    siteList: [],
    investigationList: [],
    bookedByList: [],
    appointmentStatusList: ["Booked", "Cancelled", "Orphaned", "Registered"],
    clinicalLifecycleList: [
      "Billed",
      "Vision Done",
      "Dilation Done",
      "Checked Out",
      "Data Entry Done",
      "Counselling Done",
      "Sx Counselling Done",
    ],
    conditions: [],
    splitName: null,
    allowedConditions: [],
    alertOpen: false,
  };
  componentDidMount() {
    this.setState({
      doctorList: this.props.listStore.doctorList,
      siteList: this.props.listStore.siteList,
    });
    this.getInvestigationList();
    this.getBookedByList();
    if (!this.props.new) {
      this.setState(
        {
          conditions: this.props.conditions || [],
          splitName: this.props.splitName || null,
        },
        () => this.getConditionsAvailable()
      );
    } else {
      this.setState(
        {
          conditions: [],
          splitName: null,
        },
        () => this.getConditionsAvailable()
      );
    }
  }
  getInvestigationList = async () => {
    this.setState({ waiting: true });
    try {
      let res = await apiCallPost(
        `/rai/${this.props.authStore.prefix}/getProceduresAndSubprocedures`,
        {}
      );
      let filtered = _.keys(res);
      this.setState({ waiting: false, investigationList: _.uniq(filtered) });
    } catch (error) {
      cogoToast.error(
        "We ran into an error. Please check the console for more details.",
        { position: "bottom-left" }
      );
      this.setState({ waiting: false });
      console.log(error);
    }
  };
  getBookedByList = async () => {
    this.setState({ waiting: true });
    try {
      let res = await await apiCallPost(
        `/lists/${this.props.authStore.prefix}/getUsers`,
        {}
      );
      let filtered = res.map((each) => each.name);
      this.setState({ waiting: false, bookedByList: filtered });
    } catch (error) {
      cogoToast.error(
        "We ran into an error. Please check the console for more details.",
        { position: "bottom-left" }
      );
      this.setState({ waiting: false });
      console.log(error);
    }
  };
  /**HAS Potential BUG */
  componentDidUpdate(prevProps) {
    if (prevProps.childKey !== this.props.childKey) {
      if (!this.props.new) {
        this.setState(
          {
            conditions: this.props.conditions,
            splitName: this.props.splitName,
          },
          () => this.getConditionsAvailable()
        );
      } else {
        this.setState(
          {
            conditions: [],
            splitName: null,
          },
          () => this.getConditionsAvailable()
        );
      }
    }
  }
  getConditionsAvailable = () => {
    let cc = [
      "Doctor",
      "Site",
      "Investigation",
      "Appointment Dates: Specific",
      "Appointment Dates: Relative",
      "Appointment Status",
      "Booking Dates: Specific",
      "Booking Dates: Relative",
      "Booked By",
      "Clinical Lifecycle",
    ];
    if (!this.state.conditions.length) {
      this.setState({ allowedConditions: cc });
      return;
    }
    let existing = _.map(this.state.conditions, (each) => each.head);
    let ccF = _.difference(cc, existing);

    if (existing.includes("Appointment Dates: Specific")) {
      _.remove(ccF, function (a) {
        return a === "Appointment Dates: Relative";
      });
    }
    if (existing.includes("Appointment Dates: Relative")) {
      _.remove(ccF, function (a) {
        return a === "Appointment Dates: Specific";
      });
    }
    if (existing.includes("Booking Dates: Relative")) {
      _.remove(ccF, function (a) {
        return a === "Booking Dates: Specific";
      });
    }
    if (existing.includes("Booking Dates: Specific")) {
      _.remove(ccF, function (a) {
        return a === "Booking Dates: Relative";
      });
    }

    this.setState({ allowedConditions: ccF });
  };
  renderSplitFilterOption = () => {
    return this.state.conditions.map((each) => {
      return (
        <ParamSelector
          key={each.id}
          condition={each}
          id={each.id}
          doctorList={_.concat(
            this.state.doctorList,
            this.state.investigationList
          )}
          siteList={this.state.siteList}
          bookedByList={this.state.bookedByList}
          investigationList={this.state.investigationList}
          visitStatusList={this.state.visitStatusList}
          appointmentStatusList={this.state.appointmentStatusList}
          clinicalLifecycleList={this.state.clinicalLifecycleList}
          allowedConditions={this.state.allowedConditions}
          onChange={(id, arg) => this.handleChangeParameter(id, arg)}
          onRemove={(id) => this.handleRemoveParameter(id)}
        />
      );
    });
  };
  handleAddParameter = () => {
    this.setState({
      conditions: [
        ...this.state.conditions,
        {
          id: this.state.conditions.length + 1,
          head: null,
          value: null,
        },
      ],
    });
    this.getConditionsAvailable();
  };
  handleChangeParameter = (id, arg) => {
    var currentConditions = _.cloneDeep(this.state.conditions);
    var objIndex = _.findIndex(currentConditions, (each) => each.id === id);
    currentConditions[objIndex] = arg;
    this.setState({ conditions: currentConditions }, () => {
      this.getConditionsAvailable();
    });
  };
  handleRemoveParameter = (id) => {
    var currentConditions = _.cloneDeep(this.state.conditions);
    _.remove(currentConditions, (each) => each.id === id);
    this.setState({ conditions: currentConditions }, () =>
      this.getConditionsAvailable()
    );
  };
  handleSplitSave = () => {
    if (!this.state.splitName) {
      cogoToast.error("Please add a name for this split.", {
        position: "bottom-left",
      });
      return;
    }

    if (!this.state.conditions.length) {
      cogoToast.error("Please add at least one condition.", {
        position: "bottom-left",
      });
      return;
    }

    /*
    /** Check duplicate names */
    if (this.props.takenNames.includes(this.state.splitName)) {
      if (this.props.new) {
        cogoToast.error(
          "It seems like a split with that name already exists.",
          {
            position: "bottom-left",
          }
        );
        return;
      }
    }
    /** Check condition nulls */
    let allow = true;
    _.map(this.state.conditions, (each) => {
      if (!each.value) {
        cogoToast.error("It seems like the condition parameter is empty.", {
          position: "bottom-left",
        });
        allow = false;
        return;
      }
      if (!each.value.length) {
        cogoToast.error("It seems like the condition parameter is empty.", {
          position: "bottom-left",
        });
        allow = false;
        return;
      }
      if (each.head.includes("Dates")) {
        if (!each.value[0]) {
          cogoToast.error(
            "It seems like the starting point of the duration is empty.",
            {
              position: "bottom-left",
            }
          );
          allow = false;
          return;
        }
        if (!each.value[1]) {
          cogoToast.error(
            "It seems like the ending point of the duration is empty.",
            {
              position: "bottom-left",
            }
          );
          allow = false;
          return;
        }
      }
    });
    let payload = {
      conditions: this.state.conditions,
      splitName: this.state.splitName,
      _id: this.props.dbKey,
    };
    if (allow) {
      if (this.props.new) {
        this.props.onSave(payload);
      } else {
        this.props.onUpdate(payload);
      }
    }
  };
  handleDelete = () => {
    this.setState({ alertOpen: false });
    this.props.onDelete(this.state.splitName, this.props.dbKey);
  };
  render() {
    if (this.state.waiting) {
      return <Spinner />;
    }

    return (
      <Dialog
        key={this.props.key}
        isOpen={this.props.isOpen}
        className="bp5-dark dialog"
        style={{ padding: "20px" }}
        title={
          this.props.new
            ? "Creating New Split"
            : `Editing Split: ${this.state.splitName}`
        }
        onClose={() => this.props.onClose()}
        canEscapeKeyClose
        canOutsideClickClose
      >
        {this.state.waiting ? (
          <Spinner />
        ) : (
          <>
            {" "}
            <br /> <br />
            {this.renderSplitFilterOption()}
            <br /> <br />
            {this.state.allowedConditions.length ? (
              <Button
                minimal
                intent="primary"
                icon="series-add"
                text="Add Condition"
                onClick={() => this.handleAddParameter()}
              />
            ) : (
              <Button
                minimal
                intent="none"
                icon="series-add"
                text="All Conditions Added"
                disabled
              />
            )}
            <br /> <br />
            {this.state.conditions.length ? (
              <>
                <ControlGroup>
                  <Tag intent="primary">Name</Tag>
                  <InputGroup
                    placeholder="Name This Split"
                    fill
                    disabled={!this.props.new}
                    value={this.state.splitName}
                    onChange={(event) =>
                      this.setState({ splitName: event.target.value })
                    }
                  />

                  <Button
                    icon="endorsed"
                    minimal
                    text="Save"
                    intent="success"
                    onClick={() => this.handleSplitSave()}
                  />
                </ControlGroup>
                <br />
                <Alert
                  className="bp5-dark"
                  cancelButtonText="Go Back"
                  confirmButtonText="Delete Split"
                  icon="warning-sign"
                  intent="danger"
                  canEscapeKeyCancel
                  canOutsideClickCancel
                  isOpen={this.state.alertOpen}
                  onCancel={() => this.setState({ alertOpen: false })}
                  onConfirm={() => this.handleDelete()}
                >
                  <p>Are you sure you want to delete this split?</p>
                </Alert>
                {this.props.new ? null : (
                  <Button
                    minimal
                    intent="danger"
                    icon="trash"
                    text="Delete Split"
                    onClick={() => this.setState({ alertOpen: true })}
                  />
                )}
              </>
            ) : null}
          </>
        )}
      </Dialog>
    );
  }
}

export default inject(
  "authStore",
  "bookingStore",
  "listStore"
)(observer(MagicBar));
