import React from "react";
import { ControlGroup, Tag } from "@blueprintjs/core";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class PupRx extends React.Component {
  state = { editingPupilL: false, editingPupilR: false };
  listOfPRs = [
    "RRR",
    "NSNR",
    "NSRL",
    "Sluggish",
    "Brisk",
    "Non-Reactive",
    "RAPD",
    "Pharmacologically dilated",
    "Fixed dilated",
  ];
  renderPupil = () => {
    return (
      <ControlGroup>
        <Tag minimal large>
          Pupil
        </Tag>
        <Tag minimal large>
          R
        </Tag>
        {this.state.editingPupilR ? (
          <SingleSelectWrapper
            items={this.listOfPRs}
            selectedItem={this.props.vision.pupillaryReaction.re}
            onChange={(item) => {
              this.props.updateVision("pupillaryReaction.re", item);
              this.setState({ editingPupilR: false });
            }}
          />
        ) : (
          <Tag
            large
            minimal
            intent="primary"
            interactive
            onClick={() => this.setState({ editingPupilR: true })}
          >
            {this.props.vision.pupillaryReaction.re}
          </Tag>
        )}
        <Tag minimal large>
          L
        </Tag>
        {this.state.editingPupilL ? (
          <SingleSelectWrapper
            items={this.listOfPRs}
            selectedItem={this.props.vision.pupillaryReaction.le}
            onChange={(item) => {
              this.props.updateVision("pupillaryReaction.le", item);
              this.setState({ editingPupilL: false });
            }}
          />
        ) : (
          <Tag
            large
            minimal
            intent="primary"
            interactive
            onClick={() => this.setState({ editingPupilL: true })}
          >
            {this.props.vision.pupillaryReaction.le}
          </Tag>
        )}
      </ControlGroup>
    );
  };
  render() {
    return <div>{this.renderPupil()}</div>;
  }
}

export default PupRx;
