import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  InputGroup,
  NonIdealState,
  NumericInput,
  Spinner,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import React from "react";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import { DateInput } from "@blueprintjs/datetime";
import { niceDate, apiCallPost, yearFromNow } from "../fns/util";
import MultiSelectWrapper from "../utils/MultiSelectWrapper";
import moment from "moment";
import cogoToast from "cogo-toast";
import { toJS } from "mobx";
import AsyncSelWrap from "../utils/AsyncSelWrap";

class PatientDetails extends React.Component {
  state = {
    oldSearching: false,
    oldSearchResults: [],
    waiting: false,
    changed: false,
    genericPid: null,
    name: null,
    phones: [],
    idProofs: [],
    emails: [],
    sex: null,
    salutation: null,
    dob: null,
    address: null,
    geocode: {
      al0: null,
      al1: null,
      al2: null,
      al3: null,
    },
    panels: [],
    preDx: [null],
    allergies: [],
    disabilities: [],
    healthcareWorker: null,
    notes: null,
    addNewID: false,
    newIDCountry: null,
    newIDType: null,
    newIDNumber: null,
    addPhone: false,
    newPhone: null,
    addEmail: false,
    newEmail: null,
    addInsurance: false,
    newInsuranceNumber: null,
    newInsurancePanel: null,
    showNewGeos: false,
    newGeoCodes: [],
    newGeoCodeIdx: 0,
  };
  countryList = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
  ];
  indiaList = [
    "Aadhar",
    "EPIC",
    "Driving License",
    "Passport",
    "OCI Card",
    "POI Card",
    "PAN Card",
  ];
  otherList = ["Passport", "UN Refugee Card"];
  diseaseConditions = [
    "Diabetes",
    "Hypertension",
    "Cardiac Disease",
    "Thyroid Disease",
    "Prostate Disease",
    "Rheumatoid Arthritis",
    "Glaucoma",
    "Retinitis Pigmentosa",
    "Diabetic Retinopathy",
    "Age-Related Macular Degeneration",
  ];
  allergies = [
    "Penicillin Related Antibiotics",
    "Antibiotics with Sulfonamides",
    "Other Antibiotics",
    "Anticonvulsants",
    "NSAIDs",
    "Chemotherapy Drugs",
    "Tropicamide",
    "Dyes",
    "Other",
  ];
  disabilities = [
    "Permanent Visual Impairment",
    "Deaf or Hard of Hearing",
    "Mental Health Conditions",
    "Intellectual Disability",
    "Brain Injury",
    "Autism Spectrum Disorder",
    "Physical Disability",
    "Celebral Palsy",
  ];
  healthcareWorkers = [
    "Doctor",
    "Ophthalmologist",
    "Healthcare Staff",
    "Healthcare Nurse",
    "Hospital Personnel",
  ];

  componentDidMount() {
    this.routeParamMatch();
  }

  /** APIs */
  getPatientDetails = async (genericPid) => {
    this.setState({
      waiting: true,
      genericPid: genericPid,
    });
    if (genericPid) {
      try {
        let res = await apiCallPost(
          `/util/${this.props.authStore.prefix}/patient/getAtlas`,
          { genericPid: genericPid }
        );

        this.setState(
          {
            name: res.name,
            phones: res.phones,
            idProofs: res.idProofs,
            emails: res.emails,
            sex: res.sex,
            salutation: res.salutation,
            dob: new Date(res.dob),
            address: res.address,
            geocode: res.geocode,
            panels: res.panels,
            preDx: res.preDx,
            allergies: res.allergies,
            disabilities: res.disabilities,
            healthcareWorker: res.healthcareWorker,
            notes: res.notes,
            addlSearchTerm: res.addlSearchTerm || "",
            changed: false,
            waiting: false,
          },
          () => this.ageFromDate(new Date(res.dob))
        );

        if (res.panels.length) {
          let p = res.panels.map((each) => each.panel);
          this.props.patientStore.panels = p;
        } else {
          this.props.patientStore.panels = [];
        }
        if (this.props.patientStore.patientName !== res.name) {
          this.props.patientStore.patientName = res.name;
        }
        let newAge = new Date().getFullYear() - new Date(res.dob).getFullYear();
        if (this.props.patientStore.patientAge !== newAge) {
          this.props.patientStore.patientAge = newAge;
        }
        if (this.props.patientStore.patientPhone !== res.phone) {
          this.props.patientStore.patientPhone = res.phone;
        }
        if (this.props.patientStore.genericPid !== res.genericPid) {
          this.props.patientStore.genericPid = res.genericPid;
        }
        if (this.props.patientStore.patientSex !== res.sex) {
          this.props.patientStore.patientSex = res.sex;
        }
      } catch (err) {
        this.setState({ waiting: false });
        cogoToast.error("Error fetching patient details.", {
          position: "bottom-left",
        });
        console.log(err);
      }
    }
  };
  getGeoCodes = async () => {
    let address = this.state.address;
    let dummyAddr = [
      {
        al0: "Loading",
        al1: "Loading",
        al2: "Loading",
        al3: "Loading",
      },
    ];
    this.setState({ newGeoCodes: dummyAddr, showNewGeos: true });
    try {
      cogoToast.loading("Getting Geocodes...", {
        position: "bottom-left",
      });
      let res = await apiCallPost(
        `/util/${this.props.authStore.prefix}/geocode`,
        { address: address }
      );

      this.setState({ newGeoCodes: res, showNewGeos: true });
      cogoToast.success("Please check the automatic geocodes generated.", {
        position: "bottom-left",
      });
    } catch (err) {
      cogoToast.error("Error getting geocodes.", {
        position: "bottom-left",
      });
      console.log(err);
    }
  };
  updatePatientDetails = async () => {
    let payload = {
      genericPid: this.state.oPtPid,
      name: this.state.name,
      phones: this.state.phones,
      idProofs: this.state.idProofs,

      emails: this.state.emails,
      sex: this.state.sex,
      salutation: this.state.salutation,
      dob: this.state.dob,
      address: this.state.address,
      geocode: this.state.geocode,
      panels: this.state.panels,
      preDx: this.state.preDx,
      allergies: this.state.allergies,
      disabilities: this.state.disabilities,
      healthcareWorker: this.state.healthcareWorker,
      notes: this.state.notes,
      addlSearchTerm: this.state.addlSearchTerm,
    };
    try {
      cogoToast.loading("Updating patient details...", {
        position: "bottom-left",
      });
      await apiCallPost(
        `/util/${this.props.authStore.prefix}/patient/update`,
        payload
      );
      this.setState({ changed: false });
      cogoToast.success("Updated patient details.", {
        position: "bottom-left",
      });
    } catch (err) {
      cogoToast.error("Error updating patient details.", {
        position: "bottom-left",
      });
      console.log(err);
    }
  };
  /** Renders */
  renderAddIDProof = () => {
    if (!this.state.addNewID) {
      return null;
    }
    return (
      <ControlGroup>
        <Tag intent="primary">Add ID</Tag>
        <Tag minimal> Country </Tag>
        <SingleSelectWrapper
          items={this.countryList}
          onChange={(item) =>
            this.setState({ newIDCountry: item, changed: true })
          }
        />
        <Tag minimal> Type </Tag>
        {this.state.newIDCountry ? (
          <SingleSelectWrapper
            items={
              this.state.newIDCountry === "India"
                ? this.indiaList
                : this.otherList
            }
            onChange={(item) =>
              this.setState({ newIDType: item, changed: true })
            }
          />
        ) : null}

        <Tag minimal>Number</Tag>
        {this.state.newIDType ? (
          <InputGroup
            onChange={(event) =>
              this.setState({ newIDNumber: event.target.value, changed: true })
            }
          />
        ) : null}
        <Button
          icon="endorsed"
          minimal
          disabled={
            !this.state.newIDNumber ||
            !this.state.newIDCountry ||
            !this.state.newIDType
          }
          intent="success"
          onClick={() => {
            let idObj = {
              country: this.state.newIDCountry,
              type: this.state.newIDType,
              reference: this.state.newIDNumber,
            };
            let ids = this.state.idProofs;
            ids.push(idObj);
            this.setState({ idProofs: ids, addNewID: false });
          }}
        />
      </ControlGroup>
    );
  };
  renderIDProofs = () => {
    return this.state.idProofs.map((each) => {
      return (
        <ControlGroup>
          <Tag intent="primary">ID</Tag>
          <Tag minimal>{each.country}</Tag>
          <Tag minimal>{each.type}</Tag>
          <Tag minimal>{each.reference}</Tag>
          <Button
            icon="trash"
            minimal
            intent="danger"
            onClick={() =>
              cogoToast.info(
                "You can't delete ID documents directly. Please raise a support request on Discord.",
                { position: "bottom-left" }
              )
            }
          />
        </ControlGroup>
      );
    });
  };
  renderAddPhone = () => {
    if (!this.state.addPhone) {
      return null;
    }
    return (
      <ControlGroup>
        <Tag intent="success">Phone</Tag>
        <InputGroup
          onChange={(event) => this.setState({ newPhone: event.target.value })}
        />
        <Button
          icon="endorsed"
          minimal
          disabled={!this.state.newPhone}
          intent="success"
          onClick={() => {
            if (!this.state.newPhone.includes("+")) {
              cogoToast.error("Please enter number including country code.", {
                position: "bottom-left",
              });
              return;
            }
            let phones = this.state.phones;
            phones.push(this.state.newPhone);
            this.setState({
              changed: true,
              phones: phones,
              newPhone: null,
              addPhone: false,
            });
          }}
        />
        <Tag minimal>Eg: +919830012345 (Include Country Code)</Tag>
      </ControlGroup>
    );
  };
  removePhone = (number) => {
    let phones = this.state.phones;
    _.remove(phones, (each) => each === number);
    this.setState({ phones: phones, changed: true });
  };
  renderPhones = () => {
    return this.state.phones.map((each) => {
      return (
        <ControlGroup>
          <Tag intent="success">Phone</Tag>
          <Tag minimal>{each}</Tag>
          <Button
            icon="trash"
            minimal
            intent="danger"
            onClick={() => this.removePhone(each)}
          />
        </ControlGroup>
      );
    });
  };
  removeEmail = (email) => {
    let emails = this.state.emails;
    _.remove(emails, (each) => each === email);
    this.setState({ emails: emails, changed: true });
  };
  renderAddEmail = () => {
    if (!this.state.addEmail) {
      return null;
    }
    return (
      <ControlGroup>
        <Tag intent="success">Email</Tag>
        <InputGroup
          onChange={(event) => this.setState({ newEmail: event.target.value })}
        />
        <Button
          icon="endorsed"
          minimal
          disabled={!this.state.newEmail}
          intent="success"
          onClick={() => {
            let emails = this.state.emails;
            emails.push(this.state.newEmail);
            this.setState({
              changed: true,
              emails: emails,
              newEmail: null,
              addEmail: false,
            });
          }}
        />
        <Tag minimal>Eg: jane@gmail.com</Tag>
      </ControlGroup>
    );
  };
  renderEmails = () => {
    return this.state.emails.map((each) => {
      return (
        <ControlGroup>
          <Tag intent="success" minimal>
            Email
          </Tag>

          <Tag minimal>{each}</Tag>
          <Button
            icon="trash"
            minimal
            intent="danger"
            onClick={() => this.removeEmail(each)}
          />
        </ControlGroup>
      );
    });
  };
  renderAddressGeocode = () => {
    if (this.state.showNewGeos) {
      return null;
    }
    return (
      <ControlGroup>
        {" "}
        <Tag intent="success" minimal>
          AL0
        </Tag>
        <Tag minimal>{this.state.geocode.al0}</Tag>
        <Tag intent="success" minimal>
          {" "}
          AL1
        </Tag>
        <Tag minimal>{this.state.geocode.al1}</Tag>
        <Tag intent="success" minimal>
          {" "}
          AL2
        </Tag>
        <Tag minimal>{this.state.geocode.al2}</Tag>
        <Tag intent="success" minimal>
          {" "}
          AL3
        </Tag>
        <Tag minimal>{this.state.geocode.al3}</Tag>
      </ControlGroup>
    );
  };
  renderAddInsurance = () => {
    if (!this.state.addInsurance) {
      return null;
    }
    let p = toJS(this.props.listStore.panelList);
    return (
      <ControlGroup>
        <Tag intent="primary">Panel</Tag>
        <SingleSelectWrapper
          items={p}
          onChange={(item) => this.setState({ newInsurancePanel: item })}
        />
        <Tag intent="primary" minimal>
          Policy Number
        </Tag>
        <InputGroup
          onChange={(event) =>
            this.setState({ newInsuranceNumber: event.target.value })
          }
        />
        <Button
          icon="endorsed"
          minimal
          intent="success"
          disabled={
            !this.state.newInsuranceNumber || !this.state.newInsurancePanel
          }
          onClick={() => {
            let panels = this.state.panels;
            panels.push({
              panel: this.state.newInsurancePanel,
              reference: this.state.newInsuranceNumber,
            });
            this.setState({
              changed: true,
              panels: panels,
              newInsuranceNumber: null,
              newInsurancePanel: null,
              addInsurance: false,
            });
          }}
        />
      </ControlGroup>
    );
  };
  renderInsurance = () => {
    return this.state.panels.map((each) => {
      return (
        <ControlGroup>
          <Tag intent="primary">Panel</Tag>
          <Tag minimal>{each.panel}</Tag>
          <Tag intent="primary" minimal>
            Policy Number
          </Tag>
          <Tag minimal>{each.reference}</Tag>
        </ControlGroup>
      );
    });
  };
  renderUnsavedTop = () => {
    return (
      <Button
        intent="success"
        icon="floppy-disk"
        text="Unsaved Changes! Please click to save."
        outlined
        onClick={() => this.updatePatientDetails()}
      />
    );
  };

  renderNewGeoCodes = () => {
    if (!this.state.showNewGeos) {
      return null;
    }
    if (!this.state.newGeoCodes) {
      return <Tag>No Geocode Found. Please Try Again.</Tag>;
    }
    let idx = this.state.newGeoCodeIdx;
    let item = this.state.newGeoCodes[idx];
    return (
      <ControlGroup>
        {" "}
        <Tag intent="success" minimal>
          AL0
        </Tag>
        <Tag minimal>{item.al0}</Tag>
        <Tag intent="success" minimal>
          {" "}
          AL1
        </Tag>
        <Tag minimal>{item.al1}</Tag>
        <Tag intent="success" minimal>
          {" "}
          AL2
        </Tag>
        <Tag minimal>{item.al2}</Tag>
        <Tag intent="success" minimal>
          {" "}
          AL3
        </Tag>
        <Tag minimal>{item.al3}</Tag>
        <Button
          icon="chevron-left"
          intent="primary"
          minimal
          onClick={() =>
            this.setState({
              newGeoCodeIdx:
                (this.state.newGeoCodeIdx - 1) % this.state.newGeoCodes.length,
            })
          }
        />
        <Button
          icon="chevron-right"
          intent="primary"
          minimal
          onClick={() =>
            this.setState({
              newGeoCodeIdx:
                (this.state.newGeoCodeIdx + 1) % this.state.newGeoCodes.length,
            })
          }
        />
        <Button
          icon="endorsed"
          intent="success"
          minimal
          onClick={() => {
            this.setState({
              geocode: item,
              changed: true,
              showNewGeos: false,
            });
          }}
        />
        <Button
          icon="cross"
          intent="danger"
          minimal
          onClick={() => this.setState({ showNewGeos: false })}
        />
      </ControlGroup>
    );
  };
  dateFromAge = (number) => {
    let d = new Date();
    d.setMonth(d.getMonth() - number * 12);
    this.setState({ dob: d });
  };
  ageFromDate = (date) => {
    let a = moment().diff(moment(date), "years");
    this.setState({ age: a });
  };
  renderForm = () => {
    if (!this.state.oPtPid) {
      return null;
    }
    if (this.state.waiting) {
      return (
        <NonIdealState
          icon={<Spinner />}
          description="Fetching Patient Details..."
          title="Patient Details"
        />
      );
    }
    return (
      <ControlGroup vertical>
        {this.state.changed ? this.renderUnsavedTop() : null}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {" "}
          <div style={{ marginRight: "20px" }}>
            <ControlGroup>
              <Tag large intent="primary">
                {" "}
                PID: {this.state.oPtPid}{" "}
              </Tag>
              <Tag minimal large intent="primary">
                Personal Information
              </Tag>
            </ControlGroup>

            <ControlGroup>
              <Tag intent="primary">Name</Tag>
              <InputGroup
                value={this.state.name}
                placeholder="Jane Doe"
                onChange={(event) =>
                  this.setState({ name: event.target.value, changed: true })
                }
              />
              <Tag minimal>Eg: Jane Doe</Tag>
            </ControlGroup>
            {this.props.isSpecs ? null : (
              <>
                <ControlGroup>
                  <Tag intent="primary">Salutation</Tag>
                  <SingleSelectWrapper
                    selectedItem={this.state.salutation}
                    items={["Mr", "Ms", "Mrs", "Dr", "Prof", "Mx"]}
                    onChange={(item) =>
                      this.setState({ salutation: item, changed: true })
                    }
                  />
                  <Tag minimal>Eg: Ms</Tag>
                </ControlGroup>
                <ControlGroup>
                  <Tag intent="primary">DoB</Tag>
                  <DateInput
                    value={this.state.dob}
                    maxDate={new Date()}
                    minDate={new Date(1900, 0, 1)}
                    parseDate={(str) => new Date(str)}
                    formatDate={(date) => niceDate(new Date(date))}
                    onChange={(selectedDate) => {
                      this.setState({ dob: selectedDate, changed: true }, () =>
                        this.ageFromDate(selectedDate)
                      );
                    }}
                  />
                  <Tag minimal>Eg: 1-Jan-1970</Tag>
                </ControlGroup>
                <ControlGroup>
                  <Tag intent="primary">Age</Tag>
                  <NumericInput
                    allowNumericCharactersOnly
                    value={this.state.age}
                    onValueChange={(number) => {
                      this.setState({ age: number, changed: true });
                      this.dateFromAge(number);
                    }}
                  />

                  <Tag minimal>Eg: 51 (In Years)</Tag>
                </ControlGroup>
                <ControlGroup>
                  <Tag intent="primary">Sex</Tag>
                  <SingleSelectWrapper
                    selectedItem={this.state.sex}
                    items={[
                      "Female",
                      "Male",
                      "Trans Male",
                      "Trans Female",
                      "Other",
                    ]}
                    onChange={(item) =>
                      this.setState({ sex: item, changed: true })
                    }
                  />
                  <Tag minimal>Eg: Female</Tag>
                </ControlGroup>
                {this.renderIDProofs()}
                {this.renderAddIDProof()}
                <ControlGroup>
                  <Tag intent="primary">ID</Tag>
                  <Button
                    outlined
                    intent="primary"
                    icon="id-number"
                    text="Add ID Proof"
                    onClick={() =>
                      this.setState({ addNewID: !this.state.addNewID })
                    }
                  />
                </ControlGroup>
              </>
            )}
          </div>
          <div style={{ marginRight: "20px" }}>
            <ControlGroup>
              <Tag minimal large intent="success">
                Contact Information
              </Tag>
            </ControlGroup>
            {this.renderPhones()}
            {this.renderAddPhone()}
            <ControlGroup>
              <Tag intent="success">Phones</Tag>
              <Button
                outlined
                intent="success"
                icon="mobile-phone"
                text="Add Phone"
                onClick={() =>
                  this.setState({ addPhone: !this.state.addPhone })
                }
              />
            </ControlGroup>
            {this.renderEmails()}
            {this.renderAddEmail()}
            <ControlGroup>
              <Tag intent="success" minimal>
                Emails
              </Tag>
              <Button
                outlined
                intent="success"
                icon="envelope"
                text="Add Email"
                onClick={() =>
                  this.setState({ addEmail: !this.state.addEmail })
                }
              />
            </ControlGroup>
            <ControlGroup>
              <Tag intent="success">Address</Tag>

              <ControlGroup vertical>
                <ControlGroup>
                  {" "}
                  <TextArea
                    value={this.state.address}
                    onChange={(event) =>
                      this.setState({
                        address: event.target.value,
                        changed: true,
                      })
                    }
                  />{" "}
                  <Button
                    icon="path-search"
                    minimal
                    onClick={() => {
                      this.setState({ showNewGeos: !this.state.showNewGeos });
                      this.getGeoCodes();
                    }}
                  />
                </ControlGroup>
                {this.renderNewGeoCodes()}
                {this.renderAddressGeocode()}
              </ControlGroup>
            </ControlGroup>
          </div>
          <div style={{ marginRight: "20px" }}>
            <ControlGroup>
              <Tag minimal large intent="warning">
                Health Information
              </Tag>
            </ControlGroup>
            <ControlGroup>
              <Tag intent="warning">Conditions</Tag>
              <MultiSelectWrapper
                items={this.diseaseConditions}
                intent="warning"
                selectedItems={this.state.preDx}
                onChange={(items) =>
                  this.setState({ preDx: items, changed: true })
                }
              />
              <Tag minimal>Eg: Diabetes</Tag>
            </ControlGroup>
            <ControlGroup>
              <Tag intent="warning">Allergies</Tag>
              <MultiSelectWrapper
                items={this.allergies}
                intent="warning"
                selectedItems={this.state.allergies}
                onChange={(items) =>
                  this.setState({ allergies: items, changed: true })
                }
              />
              <Tag minimal>Eg: Penicillin</Tag>
            </ControlGroup>
            <ControlGroup>
              <Tag intent="warning">Disability</Tag>
              <MultiSelectWrapper
                items={this.disabilities}
                intent="warning"
                selectedItems={this.state.disabilities}
                onChange={(items) =>
                  this.setState({ disabilities: items, changed: true })
                }
              />
              <Tag minimal>Eg: Physical Disability</Tag>
            </ControlGroup>
            <ControlGroup>
              <Tag intent="warning">Healthcare Worker</Tag>
              <SingleSelectWrapper
                selectedItem={this.state.healthcareWorker}
                items={this.healthcareWorkers}
                onChange={(item) =>
                  this.setState({ healthcareWorker: item, changed: true })
                }
              />
              <Tag minimal>Eg: Doctor</Tag>
            </ControlGroup>
          </div>
          <div style={{ marginRight: "20px" }}>
            <ControlGroup>
              {" "}
              <Tag minimal large intent="primary">
                Insurance Information
              </Tag>
            </ControlGroup>
            {this.renderInsurance()}
            {this.renderAddInsurance()}
            <ControlGroup>
              <Tag intent="primary" minimal>
                Insurance
              </Tag>
              <Button
                outlined
                intent="primary"
                icon="document"
                text="Add Insurance"
                onClick={() =>
                  this.setState({ addInsurance: !this.state.addInsurance })
                }
              />
            </ControlGroup>{" "}
          </div>
          <div style={{ marginRight: "20px" }}>
            <ControlGroup>
              <Tag minimal large intent="none">
                Other Information
              </Tag>
            </ControlGroup>
            <ControlGroup vertical>
              <Tag intent="none" minimal>
                Notes
              </Tag>
              <TextArea
                value={this.state.notes}
                onChange={(event) =>
                  this.setState({ notes: event.target.value, changed: true })
                }
              />
              <Tag intent="none" minimal>
                Addl. Search Terms
              </Tag>
              <InputGroup
                value={this.state.addlSearchTerm}
                placeholder="<Addl. Search Criteria>"
                onChange={(event) =>
                  this.setState({
                    addlSearchTerm: event.target.value,
                    changed: true,
                  })
                }
              />
            </ControlGroup>
          </div>
        </div>
      </ControlGroup>
    );
  };
  searchPatients = async (string) => {
    if (!string) {
      this.setState({ oldSearchResults: [] });
      return;
    }
    try {
      this.setState({
        oldSearchResults: true,
        oPtOldRx: null,
        oPtName: null,
        oPtPid: null,
        oPtPhone: null,
        oPtAge: null,
        oPt: null,
      });

      let res = await apiCallPost(
        `/rai/${this.props.authStore.prefix}/search`,
        {
          prefix: string,
        }
      );
      this.setState({
        oldSearchResults: res.map((each) => {
          return {
            item: _.startCase(_.lowerCase(each.name)),
            entityTypeString: each.genericPid,
            helper: each.phone,
            helper2: yearFromNow(each.dob),
            data: each,
          };
        }),
        oldSearching: false,
      });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  promoteOld = async (data) => {
    try {
      this.setState({ waiting: true });
      await apiCallPost(
        `/mgmt/${this.props.authStore.prefix}/promote-atlas/patient`,
        data
      );
      this.getPatientDetails(this.state.oPtPid);
    } catch (err) {
      console.log(err);
      cogoToast.error("Failed to promote patient.");
    }
  };
  renderSearch = () => {
    return (
      <ControlGroup>
        <Tag minimal large>
          Search
        </Tag>
        <AsyncSelWrap
          autoFocus={true}
          placeholder="Patients..."
          searching={this.state.oldSearching}
          items={_.take(this.state.oldSearchResults, 10)}
          selectedItem={null}
          update={(item) => this.searchPatients(item)}
          onChange={(item) => {
            this.setState(
              {
                oPtName: item.item,
                oPtPid: item.entityTypeString,
                oPtPhone: item.helper,
                oPtAge: item.helper2,
                oPt: item.data,
              },
              () => {
                this.promoteOld(item.data);
              }
            );
          }}
        />
      </ControlGroup>
    );
  };
  routeParamMatch = () => {
    let ss = this.props.match.params.searchStr;
    if (ss) {
      this.searchPatients(ss);
    }
  };
  render() {
    return (
      <div>
        <Card
          elevation={Elevation.FOUR}
          style={{
            backgroundColor: "#26292c",
            margin: "20px",
          }}
        >
          {this.renderSearch()}
          {this.renderForm()}
        </Card>
      </div>
    );
  }
}
export default inject(
  "bookingStore",
  "patientStore",
  "authStore",
  "listStore"
)(observer(PatientDetails));
