import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  InputGroup,
  NonIdealState,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import { apiCallPost, apiCallPostCancellable } from "../../fns/util";
import { inject, observer } from "mobx-react";
import cogoToast from "cogo-toast";
import AsyncSelWrap from "../../utils/AsyncSelWrap";
import ClinicalEntity from "../ClinicalEntity";
import { generateMasterVisitEntities } from "../masterVisitEntities";
import _ from "lodash";
import { QuickScore } from "quick-score";

class FavouritesLanding extends React.Component {
  masterVisitEntities = generateMasterVisitEntities(
    this.props.authStore.prefix
  );
  state = {
    isLoading: true,
    isBusy: false,
    currentlyAdding: "",
    searching: false,
    searchResults: [],
    currentlySelectedItem: null,
    allFavourites: [],
    loadedFavourites: [],
    searchText: "",
  };
  componentDidMount() {
    this.getMyFavourites();
  }
  getMyFavourites = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await apiCallPost(
        `/clinical-favourites/${this.props.authStore.prefix}/getMyFavourites`,
        {}
      );
      this.setState({
        allFavourites: res,
        isLoading: false,
        loadedFavourites: res,
      });
    } catch (err) {
      this.setState({ isLoading: false });
      cogoToast.error("Error fetching favourites");
      console.log(err);
    }
  };

  handleLocalSearch = (string) => {
    if (!string) {
      this.setState({
        loadedFavourites: this.state.allFavourites,
      });
      return;
    }
    if (string.length < 4) {
      this.setState({
        loadedFavourites: this.state.allFavourites,
      });
      return;
    }
    let qs = new QuickScore(this.state.allFavourites, {
      keys: ["payload.entityName"],
    });
    let res = qs.search(string);
    this.setState({
      loadedFavourites: _.map(res, (each) => each.item),
    });
  };
  searchOptions = {
    ALLERGIES: {
      sectionName: "HISTORY",
      subsectionName: "ALLERGY",
    },
    CASE_HISTORY: {
      sectionName: "HISTORY",
      subsectionName: "CASE_HISTORY",
    },
    SYMPTOMS: {
      sectionName: "HISTORY",
      subsectionName: "SYMPTOMS",
    },
    SIGNS_AND_DISEASES: {
      sectionName: "EXAMINATION",
      subsectionName: "OBSERVATIONS",
    },
    MEDICINES: {
      sectionName: "PLAN",
      subsectionName: "MEDICATIONS",
    },
    ADVICE: {
      sectionName: "PLAN",
      subsectionName: "ADVICE",
    },
    PROCEDURES: {
      sectionName: "PLAN",
      subsectionName: "SURGERY",
    },
    NEXT_STEPS: {
      sectionName: "PLAN",
      subsectionName: "NEXT_STEPS",
    },
  };
  search = async (string, sr, sing, dontUseCancellable = false) => {
    if (!string) {
      this.setState({ [sr]: [] });
      return;
    }
    try {
      this.setState({ [sing]: true, [sr]: [] });
      let API_URL = `/clinical2/${this.props.authStore.prefix}/canonical-search`;
      let PAYLOAD = { prefix: string, entityType: this.state.currentlyAdding };
      let res;
      if (dontUseCancellable) {
        res = await apiCallPost(API_URL, PAYLOAD);
      } else {
        res = await apiCallPostCancellable(API_URL, PAYLOAD);
      }
      if (res) {
        this.setState({ [sr]: res, [sing]: false });
      }
    } catch (err) {
      console.log(err);
    }
  };
  renderAdderBar = () => {
    if (this.props.mode === "CLINICAL") {
      return (
        <div style={{ marginBottom: "10px", width: "100%" }}>
          <Card
            elevation={Elevation.FOUR}
            className="card__container"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ControlGroup>
              <Tag intent="success" className="rose__bg" large>
                Favourites
              </Tag>
            </ControlGroup>
            <ControlGroup>
              <InputGroup
                className="colfax"
                placeholder="Search favourites"
                onChange={(e) => {
                  this.setState(
                    {
                      searchText: e.target.value,
                    },
                    () => this.handleLocalSearch(e.target.value)
                  );
                }}
                value={this.state.searchText}
                fill
              />
              <Button
                icon="reset"
                onClick={() => {
                  this.getMyFavourites();
                }}
              />
            </ControlGroup>
          </Card>
        </div>
      );
    }
    return (
      <div style={{ marginBottom: "10px" }}>
        <Card elevation={Elevation.FOUR} className="card__container">
          <ControlGroup>
            <Tag intent="success" className="rose__bg" large>
              Favourites
            </Tag>
            <SingleSelectWrapper
              disabled={this.state.isBusy}
              items={_.keys(this.searchOptions)}
              isColfax
              onChange={(item) =>
                this.setState({
                  currentlyAdding: item,
                })
              }
            />
            {["CASE_HISTORY", "NEXT_STEPS"].includes(
              this.state.currentlyAdding
            ) ? (
              <Button
                loading={this.state.isBusy}
                icon="add"
                className="colfax"
                text="Add Item"
                onClick={() => this.addItem()}
              />
            ) : (
              <>
                {this.state.currentlyAdding ? (
                  <AsyncSelWrap
                    disabled={this.state.isBusy}
                    isColfax={true}
                    searching={this.state.searching}
                    items={this.state.searchResults.map((each) => {
                      return {
                        ...each,
                        item: each.entityName,
                      };
                    })}
                    selectedItem={null}
                    update={(item) => {
                      this.search(item, "searchResults", "searching");
                    }}
                    onChange={(item) => {
                      this.setState({
                        currentlySelectedItem: item,
                      });
                    }}
                  />
                ) : null}
              </>
            )}
            {["CASE_HISTORY", "NEXT_STEPS"].includes(
              this.state.currentlyAdding
            ) ? null : (
              <>
                {this.state.currentlySelectedItem ? (
                  <Button
                    loading={this.state.isBusy}
                    icon="add"
                    className="colfax"
                    text="Add Item"
                    onClick={() => this.addItem()}
                  />
                ) : null}
              </>
            )}
          </ControlGroup>
        </Card>
      </div>
    );
  };
  addItem = async () => {
    if (!this.state.currentlyAdding) {
      return null;
    }
    let { sectionName, subsectionName } =
      this.searchOptions[this.state.currentlyAdding];
    let section = _.find(this.masterVisitEntities, {
      sectionName: sectionName,
    });
    let subsection = _.find(section.subsections, {
      subsectionName: subsectionName,
    });
    let constructItem = {};
    let fields = subsection.fields;
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i];

      if (this.state.currentlySelectedItem) {
        let item = this.state.currentlySelectedItem;
        constructItem[field.keyName] = item[field.keyName]
          ? item[field.keyName]
          : field.keyType === "BOOLEAN"
          ? false
          : "";
      }
    }

    if (this.state.currentlyAdding === "CASE_HISTORY") {
      constructItem["entityName"] =
        "This is a sample case history. Please edit it.";
    }
    if (this.state.currentlyAdding === "NEXT_STEPS") {
      constructItem["entityName"] = "REVIEW";
      constructItem["instructions"] = "As and when required.";
    }
    constructItem["entityType"] = this.state.currentlyAdding;
    constructItem["provenance"] = "FAVOURITE";
    constructItem["canonicalId"] =
      this.state.currentlySelectedItem && this.state.currentlySelectedItem._id
        ? [this.state.currentlySelectedItem._id]
        : [];
    constructItem["canonicalLinkedBy"] =
      this.state.currentlySelectedItem && this.state.currentlySelectedItem.id
        ? "CLINICAL_SEARCH"
        : "";
    constructItem["edited"] = false;
    constructItem["notes"] = "";
    constructItem["numSearchesBeforeAdding"] = 0;
    constructItem["sectionName"] = section.sectionName;
    constructItem["subsectionName"] = subsection.subsectionName;
    if (
      constructItem["organ"] &&
      constructItem["organ"] === "EYE" &&
      !constructItem["laterality"]
    ) {
      constructItem["laterality"] = "BILATERAL";
    }

    try {
      this.setState({
        isBusy: true,
      });
      let res = await apiCallPost(
        `/clinical-favourites/${this.props.authStore.prefix}/addItemToFavourite`,
        {
          payload: constructItem,
        }
      );
      this.setState({
        loadedFavourites: [res, ...this.state.loadedFavourites],
        isBusy: false,
        currentlySelectedItem: null,
        searching: false,
        searchResults: [],
      });
    } catch (err) {
      this.setState({
        isBusy: false,
      });
      console.log(err);
      cogoToast.error("Error adding item");
    }
  };
  updateItem = async (_id, payload) => {
    try {
      this.setState({
        isBusy: true,
      });
      let idx = _.findIndex(this.state.loadedFavourites, { _id: _id });
      if (this.props.mode === "CLINICAL") {
        let newFavourites = [...this.state.loadedFavourites];
        newFavourites[idx].payload = payload;
        newFavourites[idx].updatedAt = new Date();
        this.setState({
          loadedFavourites: newFavourites,
        });
        return;
      }
      let res = await apiCallPost(
        `/clinical-favourites/${this.props.authStore.prefix}/updateItemInFavourite`,
        { _id: _id, payload: payload }
      );
      let newFavourites = [...this.state.loadedFavourites];
      newFavourites[idx] = res;
      this.setState({
        loadedFavourites: newFavourites,
        isBusy: false,
        currentlySelectedItem: null,
        searching: false,
        searchResults: [],
      });
    } catch (err) {
      this.setState({
        isBusy: false,
      });
      console.log(err);
      cogoToast.error("Error updating item");
    }
  };
  removeItem = async (_id) => {
    try {
      this.setState({
        isBusy: true,
      });
      let idx = _.findIndex(this.state.loadedFavourites, { _id: _id });
      await apiCallPost(
        `/clinical-favourites/${this.props.authStore.prefix}/deleteItemFromFavourite`,
        { _id: _id }
      );
      let newFavourites = [...this.state.loadedFavourites];
      newFavourites.splice(idx, 1);
      this.setState({
        loadedFavourites: newFavourites,
        isBusy: false,
        currentlySelectedItem: null,
        searching: false,
        searchResults: [],
      });
    } catch (err) {
      this.setState({
        isBusy: false,
      });
      console.log(err);
      cogoToast.error("Error removing item");
    }
  };
  renderFavourites = () => {
    if (!this.state.loadedFavourites.length) {
      return (
        <NonIdealState icon="warning-sign" description="No favourites found." />
      );
    }
    let ff = this.state.loadedFavourites;
    if (this.props.mode === "CLINICAL") {
      let ffCats = ff.map((each) => each.payload.subsectionName);
      let ffCatsUnique = _.uniq(ffCats);

      // sort ffCatsUnique in order of the keys of this.searchOptions

      ffCatsUnique.sort((a, b) => {
        let aIdx = _.findIndex(_.keys(this.searchOptions), (each) => {
          return each === a;
        });
        let bIdx = _.findIndex(_.keys(this.searchOptions), (each) => {
          return each === b;
        });
        return aIdx - bIdx;
      });

      return _.uniq(ffCats).map((eachSubsection) => {
        let sample = _.find(ff, {
          payload: { subsectionName: eachSubsection },
        });
        let pp = sample.payload;
        let { sectionName, subsectionName } = pp;
        let section = _.find(this.masterVisitEntities, {
          sectionName: sectionName,
        });
        let subsection = _.find(section.subsections, {
          subsectionName: subsectionName,
        });
        let relevant = _.filter(ff, {
          payload: { subsectionName: eachSubsection },
        });
        return (
          <div>
            <Tag large intent="success" className="lime__bg">
              {subsection.subsectionNiceName}
            </Tag>
            {relevant.map((each, index) => {
              return (
                <ClinicalEntity
                  mode={this.props.mode}
                  key={`${index}__${each._id}__${each.updatedAt}`}
                  entity={each.payload}
                  data={each.payload}
                  subsection={subsection}
                  updateVisitEntity={(item) => {
                    this.updateItem(each._id, item);
                  }}
                  removeVisitEntity={() => {
                    this.removeItem(each._id);
                  }}
                  addVisitEntity={() => {
                    this.props.addVisitEntity(each.payload);
                  }}
                />
              );
            })}
          </div>
        );
      });
    }
    return this.state.loadedFavourites.map((each, index) => {
      let pp = each.payload;
      let { sectionName, subsectionName } = pp;
      let section = _.find(this.masterVisitEntities, {
        sectionName: sectionName,
      });
      let subsection = _.find(section.subsections, {
        subsectionName: subsectionName,
      });
      return (
        <ClinicalEntity
          mode={this.props.mode}
          key={`${index}__${each._id}__${each.updatedAt}`}
          entity={each.payload}
          data={each.payload}
          subsection={subsection}
          updateVisitEntity={(item) => {
            this.updateItem(each._id, item);
          }}
          removeVisitEntity={() => {
            this.removeItem(each._id);
          }}
          addVisitEntity={() => {
            this.props.addVisitEntity(each.payload);
          }}
        />
      );
    });
  };
  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        {this.renderAdderBar()}
        {this.renderFavourites()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(FavouritesLanding));
