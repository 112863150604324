/**
 *
 * keyTypes:
 * TYPE {isLong}
 * OPTIONS
 */
import _ from "lodash";
const masterVisitEntitiesBaseData = [
  {
    sectionName: "HISTORY",
    sectionNiceName: "History",
    helper:
      "Document the patient's past and current medical history, context of current visit, and other background here.",
    subsections: [
      {
        subsectionName: "ALLERGY",
        subsectionNiceName: "Allergies",
        fieldType: "SEARCH",
        searchEntityType: "ALLERGIES",
        hasNotes: true,
        useInTemplate: true,
        aiInTemplate: false,
        includeForTenants: [],
        fields: [
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Allergy",
            searchMap: "data.allergy",
            isMandatory: true,
          },
          {
            keyType: "TYPE",
            keyName: "icd11",
            keyNameNice: "ICD-11",
            searchMap: "data.icd11",
            isMandatory: false,
          },
        ],
        helper: "Search and add allergies the patient might have.",
        notesHelper: "Enter any notes about the patient's allergy here.",
      },
      {
        subsectionName: "PAST_DISEASE",
        subsectionNiceName: "Previous Diagnoses",
        fieldType: "SEARCH",
        searchEntityType: "SIGNS_AND_DISEASES",
        hasNotes: true,
        useInTemplate: true,
        aiInTemplate: false,
        includeForTenants: [],
        fields: [
          {
            keyType: "OPTIONS",
            keyName: "organ",
            keyNameNice: "Organ",
            searchMap: null,
            isMandatory: true,
            keyOptions: "ORGANS",
          },
          {
            keyType: "OPTIONS",
            keyName: "laterality",
            keyNameNice: "Laterality",
            searchMap: null,
            isMandatory: true,
            keyOptions: "LATERALITIES",
          },
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Diagnosis",
            searchMap: "data.disease",
            isMandatory: true,
          },
          {
            keyType: "TYPE",
            keyName: "icd11",
            keyNameNice: "ICD-11",
            searchMap: "data.icd11",
            isMandatory: false,
          },
        ],
        helper:
          "Search and add previous diseases the patient has been diagnosed with",
        notesHelper: "Enter any notes about the patient's diagnosis here.",
      },
      {
        subsectionName: "PAST_PROCEDURE",
        subsectionNiceName: "Previous Procedures",
        fieldType: "SEARCH",
        searchEntityType: "PROCEDURES",
        hasNotes: true,
        useInTemplate: true,
        aiInTemplate: false,
        includeForTenants: [],
        fields: [
          {
            keyType: "OPTIONS",
            keyName: "organ",
            keyNameNice: "Organ",
            searchMap: null,
            isMandatory: true,
            keyOptions: "ORGANS",
          },
          {
            keyType: "OPTIONS",
            keyName: "laterality",
            keyNameNice: "Laterality",
            searchMap: null,
            isMandatory: true,
            keyOptions: "LATERALITIES",
          },
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Surgery",
            searchMap: "data.surgery",
            isMandatory: true,
            isLong: true,
          },
        ],
        helper: "Search and add previous surgeries the patient has undergone",
        notesHelper: "Enter any notes about the patient's surgery here.",
      },
      {
        subsectionName: "CURRENT_MEDICATIONS",
        subsectionNiceName: "Current Medicines",
        fieldType: "SEARCH",
        searchEntityType: "MEDICINES",
        hasNotes: true,
        useInTemplate: false,
        includeForTenants: [],
        fields: [
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Medicine",
            searchMap: "data.brand",
            isMandatory: true,
            isLong: true,
          },
          {
            keyType: "TYPE",
            keyName: "molecule",
            keyNameNice: "Molecule",
            searchMap: "data.molecules",
            isMandatory: false,
            isLong: true,
          },
        ],
        helper:
          "Search and add current medications the patient is taking here.",
        notesHelper: "Enter any notes about this medication here.",
      },
      {
        subsectionName: "FAMILY_HISTORY",
        subsectionNiceName: "Family History",
        fieldType: "SEARCH",
        searchEntityType: "SIGNS_AND_DISEASES",
        hasNotes: true,
        useInTemplate: true,
        aiInTemplate: false,
        includeForTenants: [],
        fields: [
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Disease",
            searchMap: "data.disease",
            isMandatory: true,
          },
          {
            keyType: "TYPE",
            keyName: "icd11",
            keyNameNice: "ICD-11",
            searchMap: "data.icd11",
            isMandatory: false,
          },
        ],
        helper:
          "Search and add any family history of diseases the patient has here.",
        notesHelper: "Enter any notes about this history, such as lineage.",
      },
      {
        subsectionName: "SYMPTOMS",
        subsectionNiceName: "Symptoms",
        fieldType: "SEARCH",
        searchEntityType: "SYMPTOMS",
        hasNotes: true,
        useInTemplate: true,
        aiInTemplate: false,
        includeForTenants: [],
        fields: [
          {
            keyType: "OPTIONS",
            keyName: "organ",
            keyNameNice: "Organ",
            searchMap: null,
            isMandatory: true,
            keyOptions: "ORGANS",
          },
          {
            keyType: "OPTIONS",
            keyName: "laterality",
            keyNameNice: "Laterality",
            searchMap: null,
            isMandatory: true,
            keyOptions: "LATERALITIES",
          },
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Symptom",
            searchMap: "data.symptom",
            isMandatory: true,
          },
        ],
        helper: "Search and add any symptoms the patient is experiencing here.",
        notesHelper:
          "Enter any notes about this symptom - such as progression, duration or onset.",
      },
      {
        subsectionName: "BIRTH_HISTORY",
        subsectionNiceName: "Birth History",
        fieldType: "TYPE_LONG",
        useInTemplate: false,
        hasNotes: false,
        hasVoice: true,
        hasAI: true,
        useAIActions: ["CASE_HISTORY"],
        afterAIActions: (data) => {
          return { isArray: false, processedData: { entityName: data } };
        },
        includeForTenants: ["disha", "lotus", "NCTraining", "Adnan"],
        fields: [
          {
            keyType: "TYPE_LONG",
            keyName: "entityName",
            keyNameNice: "Birth History",
            isMandatory: true,
          },
        ],
        helper: "Use this section to enter the patient's birth history.",
      },
      {
        subsectionName: "SOCIAL_HISTORY",
        subsectionNiceName: "Social History",
        fieldType: "SEARCH",
        searchEntityType: "SOCIAL_HISTORY",
        useInTemplate: false,
        hasNotes: true,
        hasVoice: true,
        hasAI: true,
        useAIActions: ["CASE_HISTORY"],
        afterAIActions: (data) => {
          return { isArray: false, processedData: { entityName: data } };
        },
        includeForTenants: ["lotus", "NCTraining"],
        fields: [
          {
            keyType: "TYPE_LONG",
            keyName: "entityName",
            keyNameNice: "Social History",
            isMandatory: true,
          },
        ],
        helper: "Use this section to enter the patient's social history.",
      },
      {
        subsectionName: "CASE_HISTORY",
        subsectionNiceName: "Case History",
        fieldType: "TYPE_LONG",
        useInTemplate: false,
        hasNotes: false,
        hasVoice: true,
        hasAI: true,
        useAIActions: ["CASE_HISTORY"],
        afterAIActions: (data) => {
          return { isArray: false, processedData: { entityName: data } };
        },
        includeForTenants: [],
        fields: [
          {
            keyType: "TYPE_LONG",
            keyName: "entityName",
            keyNameNice: "Case History",
            isMandatory: true,
          },
        ],
        helper: "Use this section to enter the patient's case history.",
      },
    ],
  },
  {
    sectionName: "EXAMINATION",
    sectionNiceName: "Examination",
    helper:
      "Record and view patient's physical exam findings and observed clinical signs.",
    subsections: [
      {
        subsectionName: "OBSERVATIONS",
        subsectionNiceName: "Observations",
        fieldType: "SEARCH",
        searchEntityType: "SIGNS_AND_DISEASES",
        hasNotes: true,
        hasVoice: true,
        hasAI: true,
        useInTemplate: true,
        aiInTemplate: false,
        useAIActions: ["FINDINGS_DICTATION_STRUCTURE"],
        afterAIActions: (data) => {
          let rawText = data;
          let arr = rawText
            .split("\n")
            .filter(Boolean)
            .map((each) => _.trim(each, " "))
            .map((each) => each.split(","));
          let structuredArr = [];
          for (var i = 0; i < arr.length; i++) {
            let newElem = {};
            let thisRow = arr[i];
            newElem["organ"] = "EYE";
            newElem["icd11"] = "";
            newElem["anatomicalPart"] = arr[i][0];
            newElem["laterality"] = arr[i][1];
            newElem["isDiagnosis"] = arr[i][2] === "FALSE" ? false : true;
            // remove first 3 elements from thisRow
            thisRow.splice(0, 3);
            newElem["entityName"] = thisRow.join(", ");
            structuredArr.push(newElem);
          }
          return { isArray: true, processedData: structuredArr };
        },
        includeForTenants: [],
        fields: [
          {
            keyType: "OPTIONS",
            keyName: "organ",
            keyNameNice: "Organ",
            searchMap: null,
            isMandatory: true,
            keyOptions: "ORGANS",
          },
          {
            keyType: "OPTIONS",
            keyName: "anatomicalPart",
            keyNameNice: "Anatomical Part",
            searchMap: "helper",
            isMandatory: false,
            keyOptions: "EYE_ANATOMICAL_PARTS",
          },
          {
            keyType: "OPTIONS",
            keyName: "laterality",
            keyNameNice: "Laterality",
            searchMap: null,
            isMandatory: true,
            keyOptions: "LATERALITIES",
          },
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Observation",
            searchMap: "data.item",
            isMandatory: true,
            isLong: true,
          },
          {
            keyType: "TYPE",
            keyName: "icd11",
            keyNameNice: "ICD-11",
            searchMap: "data.icd11",
            isMandatory: false,
          },
          {
            keyType: "BOOLEAN",
            keyName: "isDiagnosis",
            keyNameNice: "Diagnosis",
            keyNameFalse: "Sign",
            trueIcon: "diagnosis",
            falseIcon: "intelligence",
            searchMap: null,
            isMandatory: true,
            keyOptions: "LATERALITIES",
          },
        ],
        helper: "Search and add observations and diagnoses.",
        notesHelper:
          "Enter any notes about this observation or assessment of the patient here.",
      },
      {
        subsectionName: "DIAGNOSIS",
        subsectionNiceName: "Diagnosis",
        fieldType: "SEARCH",
        searchEntityType: "SIGNS_AND_DISEASES",
        hasNotes: true,
        hasVoice: true,
        hasAI: true,
        useInTemplate: true,
        aiInTemplate: false,
        useAIActions: ["FINDINGS_DICTATION_STRUCTURE"],
        afterAIActions: (data) => {
          let rawText = data;
          let arr = rawText
            .split("\n")
            .filter(Boolean)
            .map((each) => _.trim(each, " "))
            .map((each) => each.split(","));
          let structuredArr = [];
          for (var i = 0; i < arr.length; i++) {
            let newElem = {};
            let thisRow = arr[i];
            newElem["organ"] = "EYE";
            newElem["icd11"] = "";
            newElem["anatomicalPart"] = arr[i][0];
            newElem["laterality"] = arr[i][1];
            newElem["isDiagnosis"] = true;
            // remove first 3 elements from thisRow
            thisRow.splice(0, 3);
            newElem["entityName"] = thisRow.join(", ");
            structuredArr.push(newElem);
          }
          return { isArray: true, processedData: structuredArr };
        },
        includeForTenants: [],
        fields: [
          {
            keyType: "OPTIONS",
            keyName: "organ",
            keyNameNice: "Organ",
            searchMap: null,
            isMandatory: true,
            keyOptions: "ORGANS",
          },
          {
            keyType: "OPTIONS",
            keyName: "anatomicalPart",
            keyNameNice: "Anatomical Part",
            searchMap: "helper",
            isMandatory: false,
            keyOptions: "EYE_ANATOMICAL_PARTS",
          },
          {
            keyType: "OPTIONS",
            keyName: "laterality",
            keyNameNice: "Laterality",
            searchMap: null,
            isMandatory: true,
            keyOptions: "LATERALITIES",
          },
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Observation",
            searchMap: "data.item",
            isMandatory: true,
            isLong: true,
          },
          {
            keyType: "TYPE",
            keyName: "icd11",
            keyNameNice: "ICD-11",
            searchMap: "data.icd11",
            isMandatory: false,
          },
          {
            keyType: "BOOLEAN",
            keyName: "isDiagnosis",
            keyNameNice: "Diagnosis",
            keyNameFalse: "Sign",
            trueIcon: "diagnosis",
            falseIcon: "intelligence",
            searchMap: null,
            isMandatory: true,
            keyOptions: "LATERALITIES",
          },
        ],
        helper: "Search and add diagnoses.",
        notesHelper:
          "Enter any notes about this diagnosis or assessment of the patient here.",
      },
      {
        subsectionName: "EXAMINATION_NOTES",
        subsectionNiceName: "Examination Notes",
        fieldType: "TYPE_LONG",
        useInTemplate: false,
        hasNotes: false,
        hasVoice: true,
        voiceCleanUpPrompt: "POLISH_TRANSCRIPT_CASE_HISTORY",
        hasAI: true,
        useAIActions: ["CASE_HISTORY"],
        afterAIActions: (data) => {
          return { isArray: false, processedData: { entityName: data } };
        },
        includeForTenants: [],
        fields: [
          {
            keyType: "TYPE_LONG",
            keyName: "entityName",
            keyNameNice: "Examination Notes",
            isMandatory: true,
          },
        ],
        helper: "Use this section to enter notes about your examination.",
      },
    ],
  },
  {
    sectionName: "PLAN",
    sectionNiceName: "Plan",
    helper: "Outline recommended treatment strategies for the patient.",
    subsections: [
      {
        subsectionName: "MEDICATIONS",
        subsectionNiceName: "Medicines",
        fieldType: "SEARCH",
        searchEntityType: "MEDICINES",
        hasNotes: true,
        hasVoice: true,
        hasAI: true,
        useInTemplate: true,
        aiInTemplate: false,
        useAIActions: ["MEDICINE_DICTATION_STRUCTURE"],
        afterAIActions: (data) => {
          let rawText = data;
          let arr = rawText
            .split("\n")
            .filter(Boolean)
            .map((each) => _.trim(each, " "))
            .map((each) => each.split("|||"));

          let structuredArr = [];
          for (var i = 0; i < arr.length; i++) {
            let newElem = {};
            newElem["entityName"] = arr[i][0];
            newElem["molecules"] = "";
            newElem["instructions"] = _.trim(arr[i][1], " ");
            structuredArr.push(newElem);
          }
          return { isArray: true, processedData: structuredArr };
        },
        includeForTenants: [],
        fields: [
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Medicine",
            searchMap: "data.brand",
            isMandatory: true,
            isLong: true,
          },
          {
            keyType: "TYPE",
            keyName: "molecule",
            keyNameNice: "Molecule",
            searchMap: "data.molecules",
            isMandatory: false,
            isLong: true,
          },
          {
            keyType: "TYPE_LONG",
            keyName: "instructions",
            keyNameNice: "Instructions",
            searchMap: "data.durationText",
            isMandatory: true,
            isLong: true,
            hasParser: true,
          },
        ],
        helper: "Search and add medicines.",
        notesHelper: "Enter any notes about this medication here.",
      },
      {
        subsectionName: "ADVICE",
        subsectionNiceName: "Advice",
        fieldType: "SEARCH",
        searchEntityType: "ADVICE",
        hasNotes: true,
        hasVoice: true,
        hasAI: true,
        useInTemplate: true,
        aiInTemplate: false,
        useAIActions: ["ADVICE_DICTATION_STRUCTURE"],
        afterAIActions: (data) => {
          let rawText = data;
          let arr = rawText
            .split("\n")
            .filter(Boolean)
            .map((each) => _.trim(each, " "))
            .map((each) => each.split("|||"));

          let structuredArr = [];
          for (var i = 0; i < arr.length; i++) {
            let newElem = {};
            newElem["entityName"] = arr[i][0];
            if (arr[i][1]) {
              newElem["instructions"] = _.trim(arr[i][1], " ");
            }
            structuredArr.push(newElem);
          }
          return { isArray: true, processedData: structuredArr };
        },
        includeForTenants: [],
        fields: [
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Advice",
            searchMap: "data.text",
            isMandatory: true,
            isLong: true,
          },
          {
            keyType: "TYPE_LONG",
            keyName: "instructions",
            keyNameNice: "Instructions",
            searchMap: "data.durationText",
            isMandatory: false,
            isLong: true,
            hasParser: true,
          },
        ],
        helper: "Search and add advice.",
        notesHelper: "Enter any notes about this advice here.",
      },
      {
        subsectionName: "SURGERY",
        subsectionNiceName: "Surgery",
        fieldType: "SEARCH",
        searchEntityType: "PROCEDURES",
        hasNotes: true,
        hasVoice: true,
        hasAI: true,
        useInTemplate: true,
        aiInTemplate: false,
        useAIActions: ["SURGERY_DICTATION_STRUCTURE"],
        afterAIActions: (data) => {
          let rawText = data;
          let arr = rawText
            .split("\n")
            .filter(Boolean)
            .map((each) => _.trim(each, " "))
            .map((each) => each.split("|||"));
          let structuredArr = [];
          for (var i = 0; i < arr.length; i++) {
            let newElem = {};
            newElem["organ"] = "EYE";
            newElem["laterality"] =
              arr[i][0] === "NOT_MENTIONED" ? null : arr[i][0];
            newElem["anaesthesia"] = arr[i][1];
            newElem["entityName"] = arr[i][2];
            newElem["notes"] = _.trim(arr[i][3], " ");
            structuredArr.push(newElem);
          }
          return { isArray: true, processedData: structuredArr };
        },
        includeForTenants: [],
        fields: [
          {
            keyType: "OPTIONS",
            keyName: "organ",
            keyNameNice: "Organ",
            searchMap: null,
            isMandatory: true,
            keyOptions: "ORGANS",
          },
          {
            keyType: "OPTIONS",
            keyName: "laterality",
            keyNameNice: "Laterality",
            searchMap: null,
            isMandatory: true,
            keyOptions: "LATERALITIES",
          },
          {
            keyType: "TYPE",
            keyName: "entityName",
            keyNameNice: "Surgery",
            isMandatory: true,
            isLong: true,
          },
          {
            keyType: "OPTIONS",
            keyName: "anaesthesia",
            keyNameNice: "Anaesthesia",
            isMandatory: true,
            keyOptions: "ANAESTHESIA",
          },
          {
            keyType: "OPTIONS",
            keyName: "sxLinkedInvestigations",
            keyNameNice: "Investigations",
            isMandatory: false,
            keyOptions: "SURGERY_INVESTIGATION_OPTIONS",
          },
        ],
        helper: "Search and add surgery.",
        notesHelper: "Enter any notes about this surgery here.",
      },
      {
        subsectionName: "NEXT_STEPS",
        subsectionNiceName: "Next Steps",
        fieldType: "TYPE",
        hasNotes: true,
        hasNotes: true,
        hasVoice: true,
        useInTemplate: true,
        aiInTemplate: true,
        hasAI: true,
        useAIActions: ["NEXT_STEPS_DICTATION_STRUCTURE"],
        afterAIActions: (data) => {
          let rawText = data;
          let arr = rawText
            .split("\n")
            .filter(Boolean)
            .map((each) => _.trim(each, " "))
            .map((each) => each.split("|||"));
          let structuredArr = [];
          for (var i = 0; i < arr.length; i++) {
            let newElem = {};
            newElem["entityName"] = arr[i][0];
            newElem["instructions"] = _.trim(arr[i][1], " ");
            newElem["notes"] = _.trim(arr[i][3], " ");
            structuredArr.push(newElem);
          }
          return { isArray: true, processedData: structuredArr };
        },
        includeForTenants: [],
        fields: [
          {
            keyType: "OPTIONS",
            keyName: "entityName",
            keyNameNice: "Next Step",
            isMandatory: true,
            keyOptions: "NEXT_STEP_OPTIONS",
          },
          {
            keyType: "TYPE",
            keyName: "instructions",
            keyNameNice: "Instructions",
            isMandatory: false,
            isLong: true,
          },
        ],
        helper: "Search and add follow up notes.",
        notesHelper: "Enter any notes about this action here.",
      },
    ],
  },
];

// I am committing crimes here. I am sorry.

const sectionNiceNameOverwrite = {};

const subsectionNiceNameOverwrite = {
  /**
   * Terminology Change in New Chart
   * Symptom to Chief complaint,
   * Case history to History of Present Illness,
   * Previous diagnosis - History of past illness,
   * Current medicine - Drug History
   */
  nayonika: {
    HISTORY: {
      SYMPTOMS: "Chief Complaint",
      CASE_HISTORY: "History of Present Illness",
      PAST_DISEASE: "History of Past Illness",
      CURRENT_MEDICATIONS: "Drug History",
      PAST_PROCEDURE: "Previous Procedures",
    },
    EXAMINATION: {},
    PLAN: {},
  },
  lotus: {
    HISTORY: { SYMPTOMS: "Chief Complaint" },
    PLAN: { SURGERY: "Surgery Adviced", MEDICATIONS: "Medicines Prescribed" },
  },
  // Dr Adnan added by suckru
  Adnan: {
    HISTORY: {
      SYMPTOMS: "Chief Complaint",
    },
    // EXAMINATION: {},
    // PLAN: {}
  },
};

const subsectionOrderOverwrite = {
  nayonika: {
    HISTORY: [
      "SYMPTOMS",
      "CASE_HISTORY",
      "PAST_DISEASE",
      "FAMILY_HISTORY",
      "CURRENT_MEDICATIONS",
      "PAST_PROCEDURE",
      "ALLERGY",
    ],
    EXAMINATION: [],
    PLAN: [],
  },
  lotus: {
    HISTORY: [
      "SYMPTOMS",
      "CASE_HISTORY",
      "BIRTH_HISTORY",
      "PAST_DISEASE",
      "PAST_PROCEDURE",
      "CURRENT_MEDICATIONS",
      "FAMILY_HISTORY",
      "SOCIAL_HISTORY",
      "ALLERGY",
    ],
    EXAMINATION: [],
    PLAN: ["MEDICATIONS", "SURGERY", "ADVICE", "NEXT_STEPS"],
  },
  // Dr Adnan added by suckru
  Adnan: {
    HISTORY: [
      "SYMPTOMS",
      "PAST_DISEASE",
      "PAST_PROCEDURE",
      "ALLERGY",
      "CURRENT_MEDICATIONS",
      "BIRTH_HISTORY",
      "FAMILY_HISTORY",
      "SOCIAL_HISTORY",
      "CASE_HISTORY",
    ],
    EXAMINATION: [],
    PLAN: [],
  },
};

export const generateMasterVisitEntities = (shortcode) => {
  const baseData = _.cloneDeep(masterVisitEntitiesBaseData);
  for (let i = 0; i < baseData.length; i++) {
    // Fix section names
    let section = baseData[i];
    const tenantSectionNiceName = _.get(
      sectionNiceNameOverwrite,
      `${shortcode}.${section.sectionName}`,
      null
    );
    if (tenantSectionNiceName) {
      section.sectionNiceName = tenantSectionNiceName;
    }

    // Fix subsection inclusions
    section.subsections = section.subsections.filter((subsection) => {
      const includeForTenants = subsection.includeForTenants || [];
      const shouldInclude = includeForTenants.includes(shortcode);
      return includeForTenants.length == 0 || shouldInclude;
    });

    // Fix subsection names
    for (let j = 0; j < section.subsections.length; j++) {
      let subsection = section.subsections[j];
      const tenantSubsectionNiceName = _.get(
        subsectionNiceNameOverwrite,
        `${shortcode}.${section.sectionName}.${subsection.subsectionName}`,
        null
      );
      if (tenantSubsectionNiceName) {
        subsection.subsectionNiceName = tenantSubsectionNiceName;
      }
    }

    // Fix subsection order
    const tenantOrder = _.get(
      subsectionOrderOverwrite,
      `${shortcode}.${section.sectionName}`,
      []
    );
    if (tenantOrder.length > 0) {
      section.subsections = _.sortBy(section.subsections, (o) => {
        return tenantOrder.indexOf(o.subsectionName);
      });
    }
  }
  return baseData;
};
