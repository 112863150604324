import { observable, decorate } from "mobx";
import { persist } from "mobx-persist";

export class BillingStore {
  billingDate = null;
}

decorate(BillingStore, {
  billingDate: [persist, observable],
});
export default new BillingStore();
