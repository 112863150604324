import React from "react";
import { ControlGroup, Tag } from "@blueprintjs/core";
import _ from "lodash";

class SpecularMeasures extends React.Component {
  getParam = (k) => {
    return _.find(this.props.data, (each) => each.key === k);
  };
  renderParams = () => {
    let params = [
      "SPECULAR_numCells",
      "SPECULAR_density",
      "SPECULAR_avgArea",
      "SPECULAR_stdDevArea",
      "SPECULAR_coeffVariation",
      "SPECULAR_maxArea",
      "SPECULAR_minArea",
      "SPECULAR_hexagonalCells6A",
      "SPECULAR_cct",
    ];
    return params.map((each) => {
      if (this.getParam(each) && this.getParam(each)["value"]) {
        return (
          <ControlGroup>
            <Tag minimal large>
              {this.getParam(each)["description"]}
            </Tag>
            <Tag intent="primary" large minimal>
              {" "}
              {this.getParam(each)["value"]}{" "}
              {this.getParam(each)["unit"] === "unitless"
                ? ""
                : this.getParam(each)["unit"].includes("^-2")
                ? "per " + this.getParam(each)["unit"].replace("^-2", "²")
                : this.getParam(each)["unit"].replace("^2", "²")}
            </Tag>
          </ControlGroup>
        );
      }
      return null;
    });
  };

  render() {
    return (
      <div style={{ marginLeft: "10px", marginRight: "10px" }}>
        <ControlGroup>
          <Tag large intent="primary">
            Automated Measurements
          </Tag>
        </ControlGroup>
        <ControlGroup vertical>{this.renderParams()}</ControlGroup>
      </div>
    );
  }
}

export default SpecularMeasures;
