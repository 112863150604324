import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import _ from "lodash";

class ClinicalLoader extends React.Component {
  state = {
    bgColourArray: [
      "#383E47",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
    ],
    borderColourArray: [
      "#26292c",
      "#26292c",
      "#26292c",
      "#004D46",
      "#26292c",
      "#26292c",
      "#26292c",
      "#26292c",
      "#26292c",
      "#26292c",
      "#26292c",
      "#26292c",
      "#26292c",
      "#26292c",
      "#26292c",
    ],
    borderStyleArray: [
      "solid",
      "solid",
      "solid",
      "solid",
      "solid",
      "solid",
      "solid",
      "solid",
      "solid",
      "solid",
    ],
  };
  componentDidMount() {
    this.interval = setInterval(
      () =>
        this.setState({
          bgColourArray: _.shuffle(this.state.bgColourArray),
          borderColourArray: _.shuffle(this.state.borderColourArray),
          borderStyleArray: _.shuffle(this.state.borderStyleArray),
        }),
      100
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  renderRow = (word) => {
    let letters = word.split("");
    let numLetters = letters.length;
    let indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    let chosenIndicesForLetter = _.sampleSize(indices, numLetters).sort(
      (a, b) => a - b
    );
    return indices.map((each) => {
      let indexInChosen = _.findIndex(
        chosenIndicesForLetter,
        (x) => x === each
      );
      let whichLetter = " ";
      if (indexInChosen > -1) {
        whichLetter = letters[indexInChosen];
      }
      return (
        <Tag
          className="colfax"
          large
          minimal
          style={{
            width: "35px",
            textAlign: "center",
            background: `${_.shuffle(this.state.bgColourArray)[each]}`,
            borderStyle: `${_.shuffle(this.state.borderStyleArray)[each]}`,
            borderWidth: "1px",
            borderColor: `${_.shuffle(this.state.borderColourArray)[each]}`,
          }}
        >
          {whichLetter}
        </Tag>
      );
    });
  };
  render() {
    return (
      <div className="center__in__div colfax">
        <ControlGroup vertical className="colfax">
          <ControlGroup className="colfax">
            {this.props.text
              ? this.renderRow(this.props.text)
              : this.renderRow("THINKING")}
          </ControlGroup>
        </ControlGroup>
      </div>
    );
  }
}

export default ClinicalLoader;
