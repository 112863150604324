import React from "react";
import _ from "lodash";
import { ControlGroup, Tag } from "@blueprintjs/core";
import cogoToast from "cogo-toast";

class IOLCalc extends React.Component {
  renderHeaderCells = () => {
    let h = _.concat(
      ["Lens", "By", "Type", "Algorithm", "Emmetropia"],
      this.props.data[0].bucketRefnHeader.map((each) => {
        if (each > 0) {
          return "+" + each.toFixed(2);
        } else {
          return each.toFixed(2);
        }
      })
    );
    return h.map((each) => {
      return <th>{each}</th>;
    });
  };
  renderHead = () => {
    return (
      <tr>
        <th colSpan={5}>Lens Details</th>
        <th colSpan={this.props.data[0].bucketRefnHeader.length}>
          IOL Calculations
        </th>
      </tr>
    );
  };
  renderCells = (each) => {
    return each.map((e) => {
      return (
        <td>
          <ControlGroup>
            <Tag
              large
              intent="primary"
              minimal
              interactive
              onClick={() =>
                cogoToast.info(
                  "Once Sx is integrated, clicking here will proceed to surgery booking."
                )
              }
            >
              {e.dioptre === "-" ? "-" : e.dioptre.toFixed(2)}
            </Tag>
            <Tag minimal>
              {e.calcRefn === "-" ? "-" : e.calcRefn.toFixed(2)}
            </Tag>
          </ControlGroup>
        </td>
      );
    });
  };
  renderRow = (each) => {
    return (
      <tr>
        <td>{each.lensName}</td>
        <td>{each.lensManufacturer}</td>
        <td>{_.startCase(_.lowerCase(each.lensType))}</td>
        <td>{each.calculationFormula}</td>
        <td>{each.lensEmmetropia}</td>
        {this.renderCells(each.lensTableRow)}
      </tr>
    );
  };
  renderRows = () => {
    return this.props.data.map((each) => {
      return this.renderRow(each);
    });
  };
  renderTable = () => {
    return (
      <table class="bp5-html-table bp5-html-table-condensed  bp5-html-table-striped">
        <thead>
          {this.renderHead()}
          <tr>{this.renderHeaderCells()}</tr>
        </thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  };
  render() {
    return (
      <div style={{ marginTop: "20px" }}>
        <ControlGroup>
          <Tag large intent="primary">
            Personalised IOL Recommendations
          </Tag>
        </ControlGroup>
        {this.renderTable()}
      </div>
    );
  }
}

export default IOLCalc;
