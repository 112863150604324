import { ControlGroup, Button, InputGroup, Tag } from "@blueprintjs/core";
import React from "react";
import _ from "lodash";
import MutEx from "../../utils/MutEx";

class Medicine extends React.Component {
  state = { touched: false, text: "" };
  parseDuration = (arg, helper) => {
    /**
     * Samples: 3, 3h, 34w, h,m,y,d
     */
    var lastChar = arg ? arg.slice(-1) : "";
    var durationString = "";
    var unit = "";
    if (lastChar === "c") {
      durationString = "to continue till next consultation";
    } else if (lastChar === "r") {
      durationString = "till the end of the phial";
    } else if (lastChar === "h") {
      unit = arg.substring(0, arg.length - 1);
      if (unit === "1" || !unit) {
        if (helper === "for ") helper = helper + "1 ";
        return (durationString = helper + "hour");
      }

      durationString = "every " + unit + " hours";
    } else if (lastChar === "d") {
      unit = arg.substring(0, arg.length - 1);
      if (unit === "1" || !unit) {
        if (helper === "for ") helper = helper + "1 ";
        return (durationString = helper + "day");
      }
      durationString = helper + unit + " days";
    } else if (lastChar === "w") {
      unit = arg.substring(0, arg.length - 1);
      if (unit === "1" || !unit) {
        if (helper === "for ") helper = helper + "1 ";
        return (durationString = helper + "week");
      }
      durationString = helper + unit + " weeks";
    } else if (lastChar === "m") {
      unit = arg.substring(0, arg.length - 1);
      if (unit === "1" || !unit) {
        if (helper === "for ") helper = helper + "1 ";
        return (durationString = helper + "month");
      }
      durationString = helper + unit + " months";
    } else if (lastChar === "y") {
      unit = arg.substring(0, arg.length - 1);
      if (unit === "1" || !unit) {
        if (helper === "for ") helper = helper + "1 ";
        return (durationString = helper + "year");
      }
      durationString = helper + unit + " years";
    } else {
      /** Just a number */
      unit = arg;
      if (unit === "1" || !unit) {
        if (helper === "for ") helper = helper + "1 ";
        return (durationString = helper + "day");
      }
      durationString = helper + unit + " days";
    }

    return durationString;
  };
  capitaliseFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  parseSpecialItem = (arg) => {
    if (arg === "sos") {
      return "only when needed as SOS";
    }
    if (arg === "hs") {
      return "at bedtime";
    }
    if (arg === "ac") {
      return "before meals";
    }
    if (arg === "am") {
      return "in the morning";
    }
    if (arg === "pm") {
      return "at night";
    }
    if (arg === "ampm") {
      return "in the morning and at night";
    }
    if (arg === "pc") {
      return "after meals";
    }
    if (arg === "food") {
      return "with food";
    }
    if (arg === "rice") {
      return "in rice grain amounts";
    }
    if (arg === "tiny") {
      return "in rice grain amounts";
    }
    if (arg === "rest") {
      return "till the rest of the phial";
    }
    if (arg === "sx") {
      return "prior to surgery";
    }
  };
  parseEye = (arg) => {
    if (["BE", "OU", "b", "be", "ou"].includes(arg)) return " in both eyes.";
    if (["LE", "OS", "l", "le", "os"].includes(arg))
      return " in left eye only.";
    if (["RE", "OD", "r", "re", "od"].includes(arg))
      return " in right eye only.";
  };
  parseFrequency = (arg) => {
    /**
     * Samples: 2, 1/3, 1/1w, 1/2m, 1/4h
     */
    /**
     * Let's split this by "/"
     */

    var numOfTimes = _.split(arg, "/")[0];
    var period = _.split(arg, "/")[1];
    var numOfTimesString = "";
    if (numOfTimes === "1") {
      numOfTimesString = "once ";
    } else if (numOfTimes === "2") {
      numOfTimesString = "twice ";
    } else if (numOfTimes === "3") {
      numOfTimesString = "thrice ";
    } else {
      numOfTimesString = numOfTimes + " times ";
    }

    /**
     * Now let's parse period.
     */
    var periodString = "";
    if (!period) {
      periodString = "daily ";
    } else {
      periodString = this.parseDuration(period, "every ");
    }
    var freqDurString = numOfTimesString + periodString;
    return freqDurString;
  };
  constructStaggerString = (start, end, dur) => {
    var str = "";
    for (var i = start; i >= end; i--) {
      if (i === end) {
        str += ", and finally ";
      } else if (i !== start) {
        str += ", then ";
      }
      var f = this.parseFrequency(i);
      var d = this.parseDuration(dur, "for ");
      str += f + d;
    }
    return str;
  };
  parseMedFreq = (arg) => {
    var medFreqDurElementArray = _.split(arg, ",");
    var medFreqString = "";

    for (var i = 0; i < medFreqDurElementArray.length; i++) {
      if (i && i !== medFreqDurElementArray.length - 1) {
        medFreqString = medFreqString + ", then ";
      }
      if (i === medFreqDurElementArray.length - 1 && i) {
        medFreqString = medFreqString + ", and finally ";
      }
      /** Each element in this looks like this:
       * 2x1w,1/3x1w,1/1wx1w,1/2wxc
       */
      /** First, we split it into two strings. */
      var durElement = "";
      var freqElement = _.split(medFreqDurElementArray[i], "x")[0];
      durElement = _.split(medFreqDurElementArray[i], "x")[1];

      /** If the frequency element has an ellipsis, this is a staggered short code. */

      if (freqElement.includes("...") && durElement) {
        /**
         * Structure: freqElement: 4...1x5m ==> 4x5m,3x5m,2x5m,1x5m
         */
        var start = parseInt(freqElement.charAt(0));
        var end = parseInt(freqElement.substr(-1));
        medFreqString = this.constructStaggerString(start, end, durElement);
      } else {
        var freqString = this.parseFrequency(freqElement);
        var durString = this.parseDuration(durElement, "for ");
        medFreqString = medFreqString + freqString + " " + durString;
      }
      /** For Loop Ends */
    }
    return medFreqString;
  };
  parseSpecial = (arg) => {
    var specialString = "";
    if (arg) {
      for (var i = 0; i < arg.length; i++) {
        if (i && i !== arg.length - 1) {
          specialString = specialString + ", ";
        }
        if (i === arg.length - 1 && i) {
          specialString = specialString + ", and ";
        }
        var specialItemStr = this.parseSpecialItem(arg[i]);
        specialString = specialString + specialItemStr;
      }
    }
    return specialString;
  };
  renderParser = () => {
    let theText = this.state.touched
      ? this.state.text
      : this.props.data.duration;
    if (theText && theText.charAt(0) === "!") {
      var str = theText.slice(1, theText.length);
      return [
        <Tag
          large
          intent="primary"
          minimal
          multiline
          style={{ maxWidth: "30%" }}
        >
          {theText ? str : "Enter something above to start!"}
        </Tag>,
        str,
        "",
      ];
    }
    const rawArray = _.split(theText, " ").filter(Boolean);
    var whichEye = "";
    if (
      [
        "LE",
        "RE",
        "BE",
        "OS",
        "OD",
        "OU",
        "b",
        "be",
        "l",
        "le",
        "r",
        "re",
        "os",
        "od",
        "ou",
      ].includes(rawArray[0])
    ) {
      whichEye = this.parseEye(rawArray[0]);
      rawArray.shift();
    } else {
      /**
       * If not, this is not an eye medicine.
       */
      whichEye = null;
    }
    /** Now the first element will be dosage */
    /**2x1w,1/3x1wk,1/1wx1w,1/2wxc */
    var medFreqDurArray = rawArray[0];
    /** Second element will be special instructions */
    /**
     * Now, it might be that this is exclusively special; and there is no medFreqDurArray
     */
    var medFreqString = "";
    var special;
    var flagHasMed = false;
    if (medFreqDurArray && medFreqDurArray.match(/^\d/)) {
      medFreqString = this.parseMedFreq(medFreqDurArray);
      flagHasMed = true;
      rawArray.shift();
      special = rawArray;
    } else {
      medFreqString = "";
      special = rawArray;
    }

    var specialString = this.parseSpecial(special);

    var instructionString = "";
    var compounderString = "; ";

    if (specialString && flagHasMed) {
      compounderString = "; ";
    } else {
      compounderString = "";
    }
    if (whichEye) {
      instructionString =
        medFreqString + compounderString + specialString + " -- " + whichEye;
    } else {
      instructionString =
        medFreqString + compounderString + specialString + ".";
    }
    instructionString = this.capitaliseFirst(instructionString);

    return [
      <Tag large intent="primary" minimal multiline style={{ maxWidth: "30%" }}>
        {theText ? instructionString : "Enter something above to start!"}
      </Tag>,
      instructionString,
      whichEye,
    ];
  };
  updateDurationText = () => {
    if (this.props.data.durationText !== this.renderParser()[1]) {
    }
  };
  render() {
    let data = this.props.data;
    return (
      <ControlGroup style={{ marginTop: "5px" }} vertical>
        <ControlGroup>
          <Tag
            large
            intent={
              data.type === "old"
                ? data.checked
                  ? "primary"
                  : "danger"
                : "primary"
            }
            minimal={data.type === "old" ? (data.checked ? true : false) : true}
            onRemove={() => this.props.remove(data._id)}
          >
            {data.brand}
          </Tag>
          <Tag minimal large intent="warning">
            {data.molecules}
          </Tag>
          {this.props.templateEditing ? null : (
            <MutEx
              large={true}
              minimal={false}
              options={["RE", "BE", "LE"]}
              onUpdate={(option) => {
                this.props.update(data._id, "eye", option);
              }}
              selected={this.props.data.eye}
            />
          )}
          <InputGroup
            large
            value={
              this.state.touched ? this.state.text : this.props.data.duration
            }
            onChange={(event) =>
              this.setState({ touched: true, text: event.target.value })
            }
          />
          {this.renderParser()[0]}
          {data.type === "old" && !data.checked ? (
            <Button
              minimal
              icon="tick"
              onClick={() => this.props.update(data._id, "checked", true)}
            />
          ) : null}
          {this.state.touched ? (
            <Button
              large
              text="Save Instruction"
              minimal
              icon="endorsed"
              intent="success"
              onClick={() => {
                this.props.update(data._id, "durationObject", {
                  duration: this.state.touched
                    ? this.state.text
                    : this.props.data.duration,
                  durationText: this.renderParser()[1],
                });

                this.setState({ touched: false });
              }}
            />
          ) : null}
        </ControlGroup>
      </ControlGroup>
    );
  }
}

export default Medicine;
