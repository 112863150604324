import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";

import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class SysDx extends React.Component {
  state = {
    touched: false,
    text: null,
    editingDuration: false,
  };
  listOfWhens = [
    "Newly Diagnosed",
    "Less than 6 months",
    "6 months to 1 year",
    "1 to 2 years",
    "2 to 5 years",
    "5 to 10 years",
    "More than 10 years",
  ];
  render() {
    return (
      <div>
        <ControlGroup>
          <Tag
            intent="primary"
            minimal
            large
            onRemove={() => {
              this.props.onRemove();
            }}
          >
            {this.props.data.disease}
          </Tag>
          {this.state.editingDuration ? (
            <SingleSelectWrapper
              items={this.listOfWhens}
              selectedItem={this.props.data.since}
              onChange={(item) => {
                this.setState({ editingDuration: false });
                this.props.onUpdate("since", item);
              }}
            />
          ) : (
            <Tag
              minimal
              interactive
              onClick={() => this.setState({ editingDuration: true })}
            >
              {this.props.data.since}
            </Tag>
          )}
          <Tag
            minimal
            interactive
            onClick={() => this.props.onUpdate("onRx", !this.props.data.onRx)}
          >
            {this.props.data.onRx ? "On Rx" : "Not On Rx"}
          </Tag>
        </ControlGroup>
      </div>
    );
  }
}

export default SysDx;
