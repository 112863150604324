import { ControlGroup } from "@blueprintjs/core";
import React from "react";

import OCTSliceEachMeasure from "./OCTSliceEachMeasure";

class OCTSliceMeasures extends React.Component {
  renderEachMeasure = () => {
    if (this.props.humanMeasures.length) {
      return this.props.humanMeasures.map((each) => {
        return (
          <OCTSliceEachMeasure
            key={each.value}
            data={each}
            editHumanMeasure={(value) =>
              this.props.editHumanMeasure(value, each)
            }
            removeHumanMeasure={() => this.props.removeHumanMeasure(each._id)}
          />
        );
      });
    }
  };
  render() {
    return (
      <div style={{ marginTop: "5px" }}>
        <ControlGroup vertical>{this.renderEachMeasure()}</ControlGroup>
      </div>
    );
  }
}

export default OCTSliceMeasures;
