import { Button, ControlGroup, H3, InputGroup, Tag } from "@blueprintjs/core";
import React from "react";
import SuggColl from "../SuggColl";
import SingleSearch from "./SingleSearch";

class CRxPanel extends React.Component {
  state = {
    crxText: "",
  };
  renderTitle = () => {
    return <H3>Is the patient on any medications currently?</H3>;
  };
  renderCG = () => {
    return (
      <SingleSearch
        prefix="med"
        whichArray="prev_medicines"
        add={this.props.add}
        placeholder="Medicines"
      />
    );
  };
  renderEachCRx = (data) => {
    return (
      <Tag
        intent="primary"
        minimal
        large
        onRemove={() => {
          this.props.removeCrx("prev_medicines", data._id);
        }}
      >
        {data.molecules}
      </Tag>
    );
  };
  addMedManual = () => {
    let obj = {
      brand: "-manual-entry-",
      checked: false,
      duration: "-manual-entry-",
      durationText: "-manual-entry-",
      molecules: this.state.crxText,
      route: "-manual-entry-",
      type: "new",
      _id_sr: "-manual-entry-",
      parentEntity: undefined,
    };
    this.props.add("prev_medicines", obj);
    this.setState({ crxText: "" });
  };
  renderCRxAdd = () => {
    return (
      <ControlGroup>
        <Tag large minimal icon="new-grid-item">
          Add Medicines Manually
        </Tag>
        <InputGroup
          value={this.state.crxText}
          onChange={(event) => this.setState({ crxText: event.target.value })}
          rightElement={
            this.state.crxText ? (
              <Button
                icon="add"
                minimal
                intent="success"
                onClick={() => this.addMedManual()}
              />
            ) : null
          }
        />
      </ControlGroup>
    );
  };
  renderCRx = () => {
    if (!this.props.prev_medicines || !this.props.prev_medicines.length) {
      return (
        <ControlGroup>
          <Tag minimal icon="prescription" large>
            No Current Medications Recorded
          </Tag>
        </ControlGroup>
      );
    }
    return (
      <ControlGroup vertical>
        {this.props.prev_medicines.map((each) => {
          return this.renderEachCRx(each);
        })}
      </ControlGroup>
    );
  };

  render() {
    return (
      <div style={{ padding: "20px", height: "100%" }}>
        {this.renderTitle()}
        <br />
        <SuggColl
          ana_prefix=""
          prefix="med"
          visitId={this.props.visitId}
          addObjectInside={this.props.add}
        />
        {this.renderCG()}
        {this.renderCRxAdd()}
        <br />
        {this.renderCRx()}
      </div>
    );
  }
}

export default CRxPanel;
