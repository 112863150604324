import React from "react";
import Paint from "./Paint";
import { masterDiagramOptions } from "../masterDiagramOptions";
import {
  Button,
  Card,
  ControlGroup,
  Drawer,
  Elevation,
  Tag,
} from "@blueprintjs/core";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import { inject, observer } from "mobx-react";
import cogoToast from "cogo-toast";
import _ from "lodash";
import { apiCallPost, apiCallPostProgress } from "../../fns/util";
import DiagramPreview from "./DiagramPreview";

class PaintWrapper extends React.Component {
  state = {
    selectedDiagram: null,
    isBusy: true,
    diagramsInVisit: [],
    selectedDiagramToEdit: null,
  };
  componentDidMount() {
    this.getDiagrams();
  }
  getDiagrams = async () => {
    this.setState({
      isBusy: true,
    });

    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/diagram/getByVisit`,
        {
          visitId: this.props.data.visitId,
        }
      );
      this.setState({
        isBusy: false,
        diagramsInVisit: res,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error getting diagrams");
      this.setState({ isBusy: false });
    }
  };
  addDiagram = async () => {
    this.setState({
      isBusy: true,
    });
    let which = this.state.selectedDiagram;
    let diagram = _.find(masterDiagramOptions, { nice: which });
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/diagram/createNew`,
        {
          visitId: this.props.data.visitId,
          patientId: this.props.data.patientId,
          payload: {
            diagramType: diagram.diagramType,
            nice: diagram.nice,
            short: diagram.short,
            hasLaterality: diagram.hasLaterality,
            organ: diagram.organ,
            imageState: {},
            initialFileKey: diagram.url,
            finalFileKey: "",
          },
        }
      );
      this.setState({
        isBusy: false,
        diagramsInVisit: [res, ...this.state.diagramsInVisit],
      });
      cogoToast.success("Diagram added");
    } catch (err) {
      cogoToast.error("Error adding diagram");
      this.setState({ isBusy: false });
    }
  };
  deleteDiagram = async (diagramId) => {
    this.setState({
      isBusy: true,
    });
    try {
      await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/diagram/delete`,
        {
          diagramId: diagramId,
        }
      );
      let dd = this.state.diagramsInVisit;
      let idx = _.findIndex(dd, { _id: diagramId });
      dd.splice(idx, 1);
      this.setState({
        isBusy: false,
        diagramsInVisit: dd,
        selectedDiagramToEdit: null,
      });
      cogoToast.success("Diagram deleted");
    } catch (err) {
      cogoToast.error("Error deleting diagram");
      this.setState({ isBusy: false });
    }
  };
  uploadDiagramFile = async (iwr, diagramId) => {
    cogoToast.loading("Updating diagram file");
    this.setState({
      isBusy: true,
    });
    try {
      const formData = new FormData();
      formData.append("diagramId", diagramId);
      formData.append("file", iwr.dest);
      let res = await apiCallPostProgress(
        `/clinical2/${this.props.authStore.prefix}/diagram/uploadDiagramFile`,
        formData,
        (done, total) => {}
      );
      let finalFileKey = res.finalFileKey;
      this.updateDiagram(diagramId, finalFileKey, iwr);
    } catch (err) {
      cogoToast.error("Error updating diagram");
      this.setState({ isBusy: false });
    }
  };
  updateDiagram = async (diagramId, finalFileKey, iwr) => {
    let whichID = diagramId;
    let mm = _.find(this.state.diagramsInVisit, { _id: whichID }).diagramType;
    let diagram = _.find(masterDiagramOptions, { diagramType: mm });

    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/diagram/update`,
        {
          diagramId: diagramId,
          payload: {
            diagramType: diagram.diagramType,
            nice: diagram.nice,
            short: diagram.short,
            hasLaterality: diagram.hasLaterality,
            organ: diagram.organ,
            imageState: iwr.imageState,
            initialFileKey: diagram.url,
            finalFileKey: finalFileKey,
          },
        }
      );
      let dd = this.state.diagramsInVisit;
      let idx = _.findIndex(dd, { _id: diagramId });
      dd[idx] = res;
      this.setState({
        isBusy: false,
        diagramsInVisit: dd,
        selectedDiagramToEdit: null,
      });
      cogoToast.success("Diagram updated");
    } catch (err) {
      cogoToast.error("Error adding diagram");
      this.setState({ isBusy: false });
    }
  };
  renderDiagramsInVisit = () => {
    if (this.state.diagramsInVisit.length === 0) return null;
    return this.state.diagramsInVisit.map((each) => {
      return (
        <div>
          <DiagramPreview
            isBusy={this.state.isBusy}
            data={each}
            onRemove={() => this.deleteDiagram(each._id)}
            onView={() =>
              this.setState({
                selectedDiagramToEdit: each._id,
              })
            }
          />
        </div>
      );
    });
  };
  renderWhichDiagramSelector = () => {
    let diagramOptions = masterDiagramOptions.map((each) => each.nice);
    return (
      <Card
        elevation={Elevation.FOUR}
        style={{
          padding: "5px",
        }}
      >
        <ControlGroup vertical>
          <ControlGroup>
            <Tag minimal>Currently you are using:</Tag>
          </ControlGroup>
          <ControlGroup style={{ width: "35vw" }}>
            <Tag intent="success" className="forest_dark" multiline large>
              Diagrams
            </Tag>
            <Tag multiline minimal>
              Draw and add clinical diagrams to the visit documentation.
            </Tag>
          </ControlGroup>
          <ControlGroup>
            <Tag minimal>Choose a diagram:</Tag>
            <SingleSelectWrapper
              items={diagramOptions}
              isColfax={true}
              selectedItem={this.state.selectedDiagram}
              onChange={(item) =>
                this.setState({
                  selectedDiagram: item,
                })
              }
            />
            {this.state.selectedDiagram ? (
              <Button
                icon="add"
                intent="success"
                outlined
                loading={this.state.isBusy}
                onClick={() => this.addDiagram()}
              />
            ) : null}
          </ControlGroup>
        </ControlGroup>
      </Card>
    );
  };
  render() {
    return (
      <div className="colfax">
        {this.renderWhichDiagramSelector()}
        {this.renderDiagramsInVisit()}
        <Drawer
          size="90%"
          title="Image Editor"
          className="colfax bp5-dark"
          icon="media"
          isOpen={this.state.selectedDiagramToEdit}
          onClose={() => {
            this.setState({
              selectedDiagramToEdit: false,
            });
          }}
        >
          <Paint
            config={_.find(
              this.state.diagramsInVisit,
              (x) => x._id === this.state.selectedDiagramToEdit
            )}
            onProcess={(imageWriterResult) => {
              this.uploadDiagramFile(
                imageWriterResult,
                this.state.selectedDiagramToEdit
              );
            }}
          />
        </Drawer>
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(PaintWrapper));
