import React from "react";
import Markdowner from "./Markdowner";
import {
  alchemyUrl,
  apiCallPostAI,
  apiCallPostCancellable,
  apiCallPostStreamingForAI,
} from "../../fns/util";
import cogoToast from "cogo-toast";
import {
  Button,
  Card,
  Tag,
  Elevation,
  InputGroup,
  ControlGroup,
  TextArea,
  NonIdealState,
} from "@blueprintjs/core";
import ClinicalLoader from "../ClinicalLoader";
import _ from "lodash";

class HorizonLanding extends React.Component {
  state = {
    alchemyToken: "",
    isLoading: true,
    isError: false,
    isBusyPplx: false,
    isBusyAsst: false,
    pplxChatId: "",
    pplxInput: "",
    pplxStreamingResponse: "",
    threadId: "",
    userInputText: "",
    asstMessages: [],
    runId: "",
  };
  componentDidMount() {
    this.getAlchemyToken();
  }
  getAlchemyToken = async () => {
    const alToken = localStorage.getItem("alchemyToken");
    if (alToken) {
      this.setState({ alchemyToken: alToken }, () => {
        this.createAssistantThread();
      });
      return;
    }

    try {
      let res = await apiCallPostCancellable("/gateway/alchemy", {});
      if (res) {
        this.setState(
          {
            alchemyToken: res.token,

            isError: false,
          },
          () => {
            localStorage.setItem("alchemyToken", res.token);
            this.createAssistantThread();
          }
        );
      }
    } catch (err) {
      this.setState({ isError: true });
    }
  };
  /** PPLX */
  getPplxChatId = async () => {
    try {
      let payload = {
        applicationCtxId: "HORIZON",
        promptTag: null,
        promptId: null,
        promptHead: [],
        model: "pplx-70b-online",
        tool: "NC_HARLEY_HORIZON",
        engine: "perplexity",
        temperature: 0.3,
      };
      let res = await apiCallPostAI(
        this.state.alchemyToken,
        "/chat/createChatConversation",
        payload
      );
      this.setState({
        pplxChatId: res._id,
        pplxInput: "",
        pplxStreamingResponse: "",
        isLoading: false,
      });
    } catch (err) {
      cogoToast.error("Error setting up AI.");
      this.setState({ isError: true });
    }
  };
  generateChatCompletionPplxSSE = async () => {
    this.setState({
      isBusyPplx: true,
    });
    let searchString = this.state.pplxInput;
    let content =
      `I am writing a research paper on the topic:` +
      searchString +
      `. Please find 5 papers that are good sources of citations for this. In your response, write the title of the paper (in bold), and then 3 key bullet points of the key insights from the paper. Don't output anything other than the title and the 3 bullet points.`;
    let payload = {
      conversationId: this.state.pplxChatId,
      functions: [],
      function_call: null,
      lastContext: {
        role: "user",
        content: content,
      },
    };
    try {
      await apiCallPostStreamingForAI(
        "/chat/generateChatCompletionPplxSSE",
        this.state.alchemyToken,
        payload,
        (data) => {
          this.setState({
            pplxStreamingResponse: this.state.pplxStreamingResponse + data,
          });
        },
        () => {
          this.setState({
            isBusyPplx: false,
          });
        }
      );
    } catch (err) {
      cogoToast.error("Error setting up AI.");
      this.setState({
        isBusyPplx: false,
      });
    }
  };
  renderPerplexity = () => {
    return (
      <div
        style={{
          width: "36vw",
        }}
      >
        <Card elevation={Elevation.TWO} className="card__container">
          <ControlGroup>
            <Tag className="cerulean__bg" large intent="success">
              Hunter
            </Tag>{" "}
            <Button
              icon="reset"
              loading={this.state.isLoading || this.state.isBusyPplx}
              onClick={() => this.getPplxChatId()}
            />
            <InputGroup
              inputClassName="colfax"
              disabled={this.state.isLoading || this.state.isBusyPplx}
              fill
              value={this.state.pplxInput}
              onChange={(e) => {
                this.setState({ pplxInput: e.target.value });
              }}
            />
            <Button
              icon="clean"
              loading={this.state.isLoading || this.state.isBusyPplx}
              onClick={() => this.generateChatCompletionPplxSSE()}
              disabled={!this.state.pplxInput}
            />
          </ControlGroup>
        </Card>
        <Card
          elevation={Elevation.FOUR}
          style={{
            height: "80vh",
            width: "36vw",
            overflowY: "scroll",
            backgroundColor: "#17191a",
          }}
        >
          <Markdowner text={this.state.pplxStreamingResponse} isPplx={true} />
        </Card>
      </div>
    );
  };
  /** ASST */
  createAssistantThread = async () => {
    try {
      const response = await apiCallPostAI(
        this.state.alchemyToken,
        "/assistant/createThread",
        {
          applicationCtxId: "NC_HORIZON_ASSISTANT",
          tool: "NC_HORIZON_ASSISTANT",
        }
      );
      this.setState({ threadId: response.id }, () => this.getPplxChatId());
    } catch (error) {
      this.setState({
        isError: true,
      });
      console.error("Error creating assistant thread:", error);
    }
  };
  sendUserMessage = async () => {
    if (this.state.threadId) {
      try {
        await apiCallPostAI(
          this.state.alchemyToken,
          "/assistant/addMessageToThread",
          {
            threadId: this.state.threadId,
            content: this.state.userInputText,
          }
        );
        this.setState({
          isBusyAsst: true,
        });
        const runResponse = await apiCallPostAI(
          this.state.alchemyToken,
          "/assistant/queueThreadRun",
          {
            threadId: this.state.threadId,
            assistantId: "asst_VrT6ie2ZIIw3iBhf7EpG5pcN", // Horizon Bot
          }
        );
        this.setState({
          runId: runResponse.id,
        });
        this.checkForUpdates();
      } catch (error) {
        console.error("Error in assistants mode:", error);
      }
    }
  };
  sendUserMessageWithFile = async (fileId) => {
    let status = "in_progress";
    const intervalId = setInterval(async () => {
      const s = await this.getRunStatus();
      if (s === "completed" || s === "requires_action") {
        clearInterval(intervalId);
        status = s;
        if (status === "completed") {
          if (this.state.threadId) {
            try {
              await apiCallPostAI(
                this.state.alchemyToken,
                "/assistant/addMessageToThread",
                {
                  threadId: this.state.threadId,
                  content: "This file has the result of the query.",
                  file_ids: [fileId],
                }
              );
              this.setState({
                isBusyAsst: true,
              });
              const runResponse = await apiCallPostAI(
                this.state.alchemyToken,
                "/assistant/queueThreadRun",
                {
                  threadId: this.state.threadId,
                  assistantId: "asst_VrT6ie2ZIIw3iBhf7EpG5pcN", // Horizon Bot
                }
              );
              this.setState({
                runId: runResponse.id,
              });
              this.checkForUpdates();
            } catch (error) {
              console.error("Error in assistants mode:", error);
            }
          }
        }
      }
    }, 2000);
  };
  checkForUpdates = async () => {
    const intervalId = setInterval(async () => {
      let r1 = await this.getThreadMessages();

      let r2 = await this.getRunSteps();
      let r1R = _.reverse(r1);
      let final = r1R;
      if (r2 && r2.length > 0) {
        final = [...r1R, ...r2];
      }
      let finalSorted = _.sortBy(final, (x) => x.timestamp);
      this.setState({
        asstMessages: finalSorted,
      });
      const status = await this.getRunStatus();
      // console.log(new Date(), status, r1, r2);
      if (status === "completed" || status === "requires_action") {
        clearInterval(intervalId);
        let r1 = await this.getThreadMessages();
        let r2 = await this.getRunSteps();
        //  console.log(new Date(), "INSIDE", r1, r2);
        let r1R = _.reverse(r1);
        let final = r1R;
        if (r2 && r2.length > 0) {
          final = [...r1R, ...r2];
        }
        let finalSorted = _.sortBy(final, (x) => x.timestamp);
        this.setState({
          asstMessages: finalSorted,
        });
        if (status === "requires_action") {
          let runSteps = await this.getRunStepsForExecution();
          //  console.log(new Date(), "INSIDE_REQ_ACTION", runSteps);

          await this.executeFunctionsFromInProgressSteps(runSteps.data);
          this.setState({ asstMessages: finalSorted });
        } else {
          this.setState({ isBusyAsst: false, userInputText: "" });
        }
      }
    }, 2000);
  };
  getThreadMessages = async () => {
    try {
      const threadMessages = await apiCallPostAI(
        this.state.alchemyToken,
        "/assistant/getThreadMessages",
        {
          threadId: this.state.threadId,
          afterMessageId: null,
        }
      );
      let tm = threadMessages.data;
      let transformedArray = this.transformAsstMessages(tm);
      return transformedArray;
    } catch (error) {
      console.error("Error getting run steps:", error);
    }
  };
  transformAsstMessages = (arr) => {
    const transformedArray = [];
    for (const element of arr) {
      const { role, content, created_at } = element;
      for (const contentItem of content) {
        if (contentItem.type === "text") {
          transformedArray.push({
            role,
            content: contentItem.text.value,
            type: "text",
            annotations: contentItem.text.annotations
              ? contentItem.text.annotations
              : [],
            timestamp: created_at,
          });
        } else if (contentItem.type === "image_file") {
          transformedArray.push({
            role,
            content: contentItem.image_file.file_id,
            type: "image",
            timestamp: created_at,
          });
        }
      }
    }
    return transformedArray;
  };
  getRunSteps = async () => {
    try {
      const stepsResponse = await apiCallPostAI(
        this.state.alchemyToken,
        "/assistant/getRunSteps",
        {
          threadId: this.state.threadId,
          runId: this.state.runId,
        }
      );
      let stepData = this.handleRunStepsResponse(stepsResponse.data);
      return stepData;
    } catch (error) {
      console.error("Error getting run steps:", error);
    }
  };
  getRunStepsForExecution = async () => {
    try {
      const stepsResponse = await apiCallPostAI(
        this.state.alchemyToken,
        "/assistant/getRunSteps",
        {
          threadId: this.state.threadId,
          runId: this.state.runId,
        }
      );

      return stepsResponse;
    } catch (error) {
      console.error("Error getting run steps:", error);
    }
  };
  submitToolOutputs = async (payload, checkUpdates) => {
    try {
      await apiCallPostAI(
        this.state.alchemyToken,
        "/assistant/submitToolOutputs",
        payload
      );
      if (checkUpdates) {
        this.checkForUpdates();
      }
    } catch (error) {
      console.error("Error submitting tool outputs:", error);
    }
  };
  handleRunStepsResponse = (steps) => {
    let arr = [];
    for (const step of steps) {
      if (step.type === "tool_calls") {
        let step_details = step.step_details;
        let toolCallsInStep = step_details.tool_calls;

        if (step.status === "completed") {
          const { tool_calls } = step.step_details;
          for (const call of tool_calls) {
            if (call.type === "code_interpreter") {
              const { input } = call.code_interpreter;
              arr.push({
                role: "assistant",
                content: input,
                type: "code",
                timestamp: step.created_at,
              });
            } else if (call.type === "function") {
              const functionName = call.function.name;
              const functionArgs = JSON.parse(call.function.arguments);
              const argsString = Object.entries(functionArgs)
                .map(([key, value]) => `${key}: ${value}`)
                .join(", ");
              const functionCallString = `${functionName}({${argsString}})`;
              arr.push({
                role: "assistant",
                content: functionCallString,
                type: "code",
                timestamp: step.created_at,
              });
            }
          }
        }
        if (step.status === "in_progress") {
          const { tool_calls } = step.step_details;
          for (const call of tool_calls) {
            if (call.type === "code_interpreter" || call.type === "function") {
              arr.push({
                role: "assistant",
                content: "Harley is writing code...",
                type: "running_code",
                timestamp: step.created_at,
              });
            }
          }
        }
      } else {
        // if not tool_call
        continue;
      }
    }
    return arr;
  };
  add_numbers = (args) => {
    return args.number1 + args.number2;
  };
  runSqlQuery = async (args) => {
    try {
      const res = await apiCallPostAI(
        this.state.alchemyToken,
        "/fcall/runSqlQuery",
        args
      );
      return ["OK", res];
    } catch (error) {
      return ["ERROR", error.response.data];
    }
  };
  run_mongodb_atlas_aggregation_query = async (args) => {
    let payload = {};
    if (_.isObject(args) && !_.isArray(args)) {
      let key = _.keys(args)[0];
      payload = {
        pipeline: args[key],
      };
    } else {
      payload = {
        pipeline: args,
      };
    }
    try {
      const res = await apiCallPostAI(
        this.state.alchemyToken,
        "/fcall/runFunctionMongoDBAgg",
        payload
      );
      return ["OK", res];
    } catch (error) {
      return ["ERROR", error.response.data];
    }
  };
  executeFunctionsFromInProgressSteps = async (stepsArray) => {
    for (const step of stepsArray) {
      if (step.type === "tool_calls" && step.status === "in_progress") {
        const toolCalls = step.step_details.tool_calls;
        for (const call of toolCalls) {
          if (call.type === "function") {
            if (!_.keys(call.function).includes("output")) {
              try {
                const functionName = call.function.name;
                const args = JSON.parse(call.function.arguments);
                if (typeof this[functionName] === "function") {
                  let r = await this[functionName](args);
                  if (r[0] === "OK") {
                    let payload = {
                      threadId: this.state.threadId,
                      runId: this.state.runId,
                      tool_outputs: [
                        {
                          tool_call_id: call.id,
                          output: "OK - File will be sent in the next message.",
                        },
                      ],
                    };
                    await this.submitToolOutputs(payload, false);
                    await this.sendUserMessageWithFile(r[1].output.id);
                  } else {
                    let payload = {
                      threadId: this.state.threadId,
                      runId: this.state.runId,
                      tool_outputs: [
                        {
                          tool_call_id: call.id,
                          output: "ERROR " + r[1],
                        },
                      ],
                    };
                    await this.submitToolOutputs(payload, true);
                  }
                } else {
                  console.warn(`Function ${functionName} not found`);
                }
              } catch (e) {
                console.error("Error parsing arguments: ", e);
              }
            }
          }
        }
      }
    }
  };
  getRunStatus = async () => {
    try {
      const statusResponse = await apiCallPostAI(
        this.state.alchemyToken,
        "/assistant/getRunStatus",
        {
          threadId: this.state.threadId,
          runId: this.state.runId,
        }
      );
      return statusResponse.status;
    } catch (error) {
      console.error("Error getting run status:", error);
      return null;
    }
  };
  renderInputBox = () => {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          margin: "0px",
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            margin: "0px",
            fontSize: "medium",
          }}
        >
          <TextArea
            fill
            disabled={this.state.isBusyAsst}
            className="colfax"
            small
            style={{ paddingRight: "50px", height: "100%", fontSize: "small" }}
            large={true}
            intent="none"
            onChange={(e) => {
              this.setState({ userInputText: e.target.value });
            }}
            value={this.state.userInputText}
            placeholder="Type your message..."
          />
          <Button
            loading={this.state.isBusyAsst}
            className="colfax"
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
            }}
            icon="arrow-up"
            onClick={() => this.sendUserMessage()}
          />
        </div>
      </div>
    );
  };
  renderUserMessage = (text) => {
    return (
      <div className="user_message_container colfax">
        <Tag large intent="success" className="turquoise__bg">
          You
        </Tag>
        <div className="chat_bubble user_message">
          <Markdowner text={text} />
        </div>
      </div>
    );
  };
  renderAIMessage = (text, type = null, annotations = []) => {
    if (type === "running_code") {
      return (
        <div className="ai_message_container colfax">
          <Tag large intent="primary" className="cerulean__bg">
            Harley
          </Tag>

          <ClinicalLoader text="CODING" />
        </div>
      );
    }
    if (type === "text") {
      return (
        <div className="ai_message_container colfax">
          <Tag large intent="primary" className="cerulean__bg">
            Harley
          </Tag>
          <div className="chat_bubble ai_message">
            <Markdowner text={text} annotations={annotations} isAsst={true} />
          </div>
        </div>
      );
    } else if (type === "image") {
      return (
        <div className="ai_message_container colfax">
          <Tag large intent="primary" className="lime__bg">
            Images from Harley
          </Tag>
          <div className="chat_bubble ai_message_image">
            <img
              src={`${alchemyUrl}/assistant/GETFileContentImg/${text}`}
              alt="Assistant Provided"
              width="90%"
            />
          </div>
        </div>
      );
    } else if (type === "code") {
      let cc = text;
      // markdown it by putting backticks
      cc = "```python\n" + cc + "\n```";
      return (
        <div className="ai_message_container colfax">
          <Tag large intent="primary" className="rose__bg">
            Code from Harley
          </Tag>
          <Markdowner text={cc} isPython={true} />
        </div>
      );
    }
  };
  renderMessages = () => {
    let messages = this.state.asstMessages;
    if (!messages.length && !this.state.isBusyAsst) {
      return <NonIdealState icon="clean" description="Start chatting!" />;
    }
    return messages.map((message) => {
      if (message.role === "user") {
        return this.renderUserMessage(message.content);
      } else {
        return this.renderAIMessage(
          message.content,
          message.type ? message.type : null,
          message.annotations ? message.annotations : []
        );
      }
    });
  };
  renderAssistantArea = () => {
    return (
      <div
        style={{
          width: "61vw",
        }}
      >
        <Card elevation={Elevation.TWO} className="card__container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ControlGroup>
              {" "}
              <Tag className="rose__bg" large intent="success">
                Harley
              </Tag>{" "}
              <Button
                icon="reset"
                loading={this.state.isBusyAsst}
                onClick={() => {
                  this.setState(
                    {
                      userInputText: "",
                      asstMessages: [],
                      threadId: "",
                      runId: "",
                    },
                    () => {
                      this.createAssistantThread();
                    }
                  );
                }}
              />
            </ControlGroup>
            {this.state.isBusyAsst ? <ClinicalLoader /> : null}
          </div>
        </Card>
        <Card
          style={{
            width: "61vw",
            height: "80vh",
            backgroundColor: "#17191a",
            fontSize: "medium",
            overflowY: "scroll",
            padding: "0px",
          }}
          elevation={Elevation.FOUR}
        >
          <div style={{ height: "85%", overflowY: "scroll", padding: "10px" }}>
            {this.renderMessages()}
          </div>
          <div style={{ height: "15%", margin: "0px" }}>
            {this.renderInputBox()}
          </div>
        </Card>
      </div>
    );
  };
  /** GENERAL RENDER */
  render() {
    if (this.state.isLoading) {
      return (
        <div>
          <NonIdealState icon="clean" description="Loading..." />
        </div>
      );
    }
    if (this.state.isError) {
      return (
        <div>
          <NonIdealState
            icon="warning-sign"
            description="We ran into an error."
          />
        </div>
      );
    }
    return (
      <div
        className="colfax"
        style={{
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {this.renderPerplexity()}
          {this.renderAssistantArea()}
        </div>
      </div>
    );
  }
}

export default HorizonLanding;
