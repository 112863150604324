import {
  AnchorButton,
  Button,
  Card,
  Collapse,
  ControlGroup,
  Elevation,
  InputGroup,
  NonIdealState,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import { masterParameterOptions } from "../masterParameterOptions";
import { inject, observer } from "mobx-react";
import cogoToast from "cogo-toast";
import { apiCallPost, niceDateTime } from "../../fns/util";

class TemplateLanding extends React.Component {
  state = {
    searchText: "",
    showOnlyMine: false,
    speciality: "",
    templateType: "",
    showNewForm: false,
    newType: "",
    newName: "",
    newSpeciality: "",
    isCreatingNew: false,
    isSearching: false,
    searchResults: [],
    hasSearched: false,
  };
  componentDidMount() {
    if (this.props.isClinical) {
      this.searchTemplates();
    }
  }
  createNewTemplate = async () => {
    try {
      let data = {
        templateName: this.state.newName,
        templateType: this.state.newType,
        speciality: this.state.newSpeciality,
      };
      if (!data.templateName || !data.templateType || !data.speciality) {
        cogoToast.error("Please fill all fields");
        return;
      }
      this.setState({
        isCreatingNew: true,
      });
      let res = await apiCallPost(
        `/clinical-template2/${this.props.authStore.prefix}/createTemplate`,
        data
      );

      this.setState({
        showNewForm: false,
        isCreatingNew: false,
        searchResults: [res, ...this.state.searchResults],
      });
    } catch (err) {
      this.setState({
        isCreatingNew: false,
      });
      console.log(err);
      cogoToast.error("Error creating new template");
    }
  };
  searchTemplates = async () => {
    try {
      this.setState({
        isSearching: true,
        hasSearched: true,
      });
      let data = {
        prefix: this.state.searchText,
        showOnlyMine: this.state.showOnlyMine,
        speciality: this.state.speciality || null,
        templateType: this.props.isClinical
          ? "CLINICAL"
          : this.state.templateType || null,
      };
      let res = await apiCallPost(
        `/clinical-template2/${this.props.authStore.prefix}/searchTemplates`,
        data
      );
      this.setState({
        searchResults: res,
      });
      this.setState({
        isSearching: false,
      });
    } catch (err) {
      this.setState({
        isSearching: false,
      });
      console.log(err);
      cogoToast.error("Error searching templates");
    }
  };
  renderHeader = () => {
    return (
      <Card
        className="card__container"
        elevation={Elevation.FOUR}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ControlGroup className="card__header">
          {!this.props.isClinical && (
            <>
              {" "}
              <Tag intent="success" className="rose__bg" large>
                Templates
              </Tag>
              <Tag
                intent="success"
                className="cerulean__bg"
                large
                icon="search"
              >
                Search
              </Tag>
            </>
          )}
          <InputGroup
            inputClassName="colfax"
            disabled={this.state.isCreatingNew || this.state.isSearching}
            value={this.state.searchText}
            placeholder="Search..."
            onChange={(e) => {
              this.setState({ searchText: e.target.value });
            }}
          />
          {!this.props.isClinical && (
            <>
              {" "}
              <Tag intent="success" className="cerulean__bg">
                Type
              </Tag>
              <SingleSelectWrapper
                disabled={this.state.isCreatingNew || this.state.isSearching}
                items={masterParameterOptions.TEMPLATE_TYPES}
                isColfax={true}
                onChange={(item) => {
                  this.setState({ templateType: item });
                }}
              />
            </>
          )}{" "}
          <Tag intent="success" className="cerulean__bg">
            Category
          </Tag>
          <SingleSelectWrapper
            disabled={this.state.isCreatingNew || this.state.isSearching}
            items={masterParameterOptions.OPHTHALMIC_SPECIALITIES}
            isColfax={true}
            onChange={(e) => {
              this.setState({ speciality: e });
            }}
          />
          <Button
            disabled={this.state.isCreatingNew || this.state.isSearching}
            small
            onClick={() =>
              this.setState({ showOnlyMine: !this.state.showOnlyMine })
            }
            icon="array-boolean"
            className="colfax"
            text={this.state.showOnlyMine ? "My Templates" : "All Templates"}
          />
          <Button
            disabled={this.state.isCreatingNew || this.state.isSearching}
            loading={this.state.isSearching}
            icon="search"
            className="colfax lime__bg"
            intent="success"
            onClick={this.searchTemplates}
          />
        </ControlGroup>
        {!this.props.isClinical && (
          <Button
            disabled={this.state.isCreatingNew || this.state.isSearching}
            minimal
            icon="add"
            text="Create New"
            className="colfax indigo__bg"
            onClick={() => {
              this.setState({ showNewForm: !this.state.showNewForm });
            }}
          />
        )}
      </Card>
    );
  };
  renderNewTemplateForm = () => {
    return (
      <Collapse isOpen={this.state.showNewForm}>
        <Card className="card__container" elevation={Elevation.FOUR}>
          <ControlGroup>
            <Tag large intent="success" className="forest__bg">
              Create A New Template
            </Tag>
            <Button
              disabled={this.state.isCreatingNew || this.state.isSearching}
              onClick={() => {
                this.setState({ showNewForm: false });
              }}
              icon="double-chevron-up"
              text="Close"
              className="colfax"
              minimal
            />
          </ControlGroup>
          <ControlGroup
            vertical
            style={{ marginTop: "15px", marginBottom: "15px" }}
          >
            <ControlGroup>
              <Tag intent="success" className="cerulean__bg" large>
                What is the type of template you want to create?
              </Tag>
              <SingleSelectWrapper
                disabled={this.state.isCreatingNew || this.state.isSearching}
                items={masterParameterOptions.TEMPLATE_TYPES}
                isColfax={true}
                onChange={(e) => {
                  this.setState({ newType: e });
                }}
              />
            </ControlGroup>
            <ControlGroup>
              <Tag intent="success" className="cerulean__bg" large>
                Choose a category for this template
              </Tag>
              <SingleSelectWrapper
                disabled={this.state.isCreatingNew || this.state.isSearching}
                items={masterParameterOptions.OPHTHALMIC_SPECIALITIES}
                isColfax={true}
                onChange={(e) => {
                  this.setState({ newSpeciality: e });
                }}
              />
            </ControlGroup>
            <ControlGroup>
              <Tag intent="success" className="cerulean__bg" large>
                Give this template a name so you can easily search for it and
                find it.
              </Tag>
              <InputGroup
                inputClassName="colfax"
                disabled={this.state.isCreatingNew || this.state.isSearching}
                value={this.state.newName}
                onChange={(e) => {
                  this.setState({ newName: e.target.value });
                }}
              />
            </ControlGroup>
          </ControlGroup>
          <ControlGroup>
            <Button
              onClick={this.createNewTemplate}
              loading={this.state.isCreatingNew}
              icon="add"
              text="Create"
              className="colfax lime__bg"
              intent="success"
            />
          </ControlGroup>
        </Card>
      </Collapse>
    );
  };
  renderSearchResults = () => {
    if (!this.state.hasSearched)
      return (
        <NonIdealState
          icon="search"
          description="You can search templates from the control bar above."
        />
      );
    if (this.state.isSearching) return <Spinner />;
    if (this.state.searchResults.length === 0)
      return (
        <NonIdealState icon="warning-sign" description="No results found." />
      );
    return (
      <div style={{ width: "100%", margin: "auto" }}>
        {this.props.isClinical ? (
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Tag minimal>Laterality</Tag>
            <SingleSelectWrapper
              isColfax={true}
              items={masterParameterOptions.LATERALITIES}
              selectedItem={this.state.selectedLaterality}
              onChange={(e) => {
                this.setState({ selectedLaterality: e });
              }}
            />
          </div>
        ) : null}
        <table className="bp5-dark bp5-html-table bp5-compact bp5-html-table-bordered bp5-html-table-striped colfax table_vertical_align_center">
          <thead>
            <tr>
              <th>Name</th>
              {!this.props.isClinical && <th>Type</th>}{" "}
              {!this.props.isClinical && <th>Category</th>}
              <th>Description</th>
              {!this.props.isClinical && <th>Created</th>}{" "}
              {!this.props.isClinical && <th>Uses</th>}
              {!this.props.isClinical ? <th>View</th> : <th>Use</th>}
            </tr>
          </thead>
          <tbody>
            {this.state.searchResults.map((each, index) => {
              return (
                <tr key={index}>
                  <td>{each.templateName}</td>
                  {!this.props.isClinical && <td>{each.templateType}</td>}
                  {!this.props.isClinical && <td>{each.speciality}</td>}
                  <td>{each.templateDescription}</td>
                  {!this.props.isClinical && (
                    <td>
                      <ControlGroup vertical>
                        <Tag large intent="success">
                          {each.createdBy}
                        </Tag>
                        <Tag intent="success" minimal>
                          {niceDateTime(each.createdAt)}
                        </Tag>
                      </ControlGroup>
                    </td>
                  )}
                  {!this.props.isClinical && <td>{each.nUses}</td>}
                  {!this.props.isClinical ? (
                    <td>
                      <AnchorButton
                        target="_blank"
                        href={`/templates/${each._id}`}
                        icon="document-share"
                        className="colfax"
                        text="View Template"
                      />
                    </td>
                  ) : (
                    <td>
                      <Button
                        icon="endorsed"
                        text="Use"
                        className="colfax"
                        disabled={!this.state.selectedLaterality}
                        onClick={() =>
                          this.props.onApplyTemplate(
                            each._id,
                            this.state.selectedLaterality
                          )
                        }
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  render() {
    return (
      <div className="colfax" style={{ padding: "10px" }}>
        {this.renderHeader()}
        {this.renderNewTemplateForm()}
        {this.renderSearchResults()}
      </div>
    );
  }
}
export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(TemplateLanding));
