import React from "react";
import {
  Callout,
  Button,
  H4,
  Collapse,
  Tag,
  Spinner,
  ControlGroup,
} from "@blueprintjs/core";
import SuggCollClick from "./SuggCollClick";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import { apiCallPost } from "../fns/util";
import cogoToast from "cogo-toast";

class SuggColl extends React.Component {
  state = { collapse: true, suggestions: [], loading: true };
  normals = {
    "extraocular muscles": "Movements Full and Free",
    "lids and adnexa": "WNL",
    conjunctiva: "WNL",
    sclera: "WNL",
    cornea: "Clear",
    "anterior chamber": "Deep and Quiet",
    iris: "WNL",
    lens: "Clear",
    vitreous: "Clear",
    retina: "WNL",
    disc: "WNL",
  };
  caps = {
    "extraocular muscles": "Extraocular Muscles",
    "lids and adnexa": "Lids and Adnexa",
    conjunctiva: "Conjunctiva",
    sclera: "Sclera",
    cornea: "Cornea",
    "anterior chamber": "Anterior Chamber",
    iris: "Iris",
    lens: "Lens",
    vitreous: "Vitreous",
    retina: "Retina",
    disc: "Disc",
  };
  componentDidMount() {
    this.getSuggest();
  }
  componentDidUpdate(prev) {
    if (prev.prefix !== this.props.prefix) {
      this.getSuggest();
      return;
    }
    if (prev.ana_prefix !== this.props.ana_prefix) {
      this.setState({ suggestions: [] }, () => this.getSuggest());
      return;
    }
  }
  getSuggest = async () => {
    if (!this.props.prefix) {
      return;
    }
    if (!this.props.visitId) {
      return;
    }
    try {
      this.setState({ loading: true, suggestions: [] });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/suggest`,
        {
          visitId: this.props.visitId,
          maxResults: 6,
          ana_prefix: _.lowerCase(this.props.ana_prefix) || "",
          prefix: this.props.prefix,
        }
      );

      let sorted = _.reverse(_.sortBy(res, (each) => each.freq));
      let arr = sorted.map((each) => each.suggestedEntity);
      /*  this.setState({ suggestions: arr, loading: false }, () =>
        this.addNormalSigns()
      ); */

      try {
        let res2 = await apiCallPost(
          `/clinical/${this.props.authStore.prefix}/unisearch-freq`,
          {
            prefix: this.props.prefix,
            ana_prefix: this.props.ana_prefix || "",
            maxResults: 6,
          }
        );
        let arr2 = [];

        let sorted2 = _.reverse(_.sortBy(res2, (each) => each.freq));
        arr2 = sorted2.map((each) => each.suggestedEntity);

        arr = _.concat(arr, arr2);
        arr = _.uniqBy(arr, "item");
        this.setState({ suggestions: arr, loading: false }, () =>
          this.addNormalSigns()
        );
      } catch (err2) {
        console.log(err2);
        cogoToast.error("Error in getting suggestions", {
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("Error in getting suggestions", {
        position: "bottom-left",
      });
      this.setState({ suggestions: [], loading: false }, () =>
        this.addNormalSigns()
      );
    }
  };
  addNormalSigns = () => {
    if (this.props.prefix === "sign" && this.props.ana_prefix) {
      let normie = this.normals[_.lowerCase(this.props.ana_prefix)];
      let nn = {
        _id: "No View",
        helper: this.caps[_.lowerCase(this.props.ana_prefix)],
        listOfQualifiers: [],
        helper2: "",
        entityArray: "findings",
        entityType: "sign",
        item: "No View",
        data: {
          item: "No View of " + this.caps[_.lowerCase(this.props.ana_prefix)],
          anatomy: this.caps[_.lowerCase(this.props.ana_prefix)],
          _id: "No View",
          checked: false,
          qualifier: "",
        },
      };

      let dd = {
        _id: "Normal",
        helper: this.caps[_.lowerCase(this.props.ana_prefix)],
        listOfQualifiers: [],
        helper2: "",
        entityArray: "findings",
        entityType: "sign",
        item: normie,
        data: {
          item: normie,
          anatomy: this.caps[_.lowerCase(this.props.ana_prefix)],
          _id: "No View",
          checked: false,
          qualifier: "",
        },
      };

      let ss = this.state.suggestions;
      ss.unshift(dd);
      ss.unshift(nn);
      this.setState({ suggestions: ss });
    }
  };

  renderSuggestions = () => {
    if (this.state.loading) {
      return null;
    }
    if (!this.state.suggestions || !this.state.suggestions.length) {
      return <Tag minimal>No suggestions generated</Tag>;
    }
    return this.state.suggestions.map((each) => {
      let dd = each.data;
      dd["_id_sr"] = each._id;
      dd["type"] = "new";
      dd["checked"] = false;
      if (each.entityArray === "findings") {
        dd["sinceLastVisit"] = null;
        dd["anatomy"] = each.helper;
        dd["listOfQualifiers"] = each.listOfQualifiers;
        dd["qualifier"] = each.helper2;
      }
      if (each.entityArray === "eyeDx") {
        dd["query"] = false;
        dd["anatomy"] = each.helper;
      }
      return (
        <SuggCollClick
          text={each.item}
          onSingleClick={() => {
            let whichArray = each.entityArray;
            let whichKey = "";

            if (each.entityArray === "findings") {
              dd["site"] = "Left Eye";
              whichKey = each.helper;
            }

            if (["eyeDx"].includes(each.entityArray)) {
              dd["site"] = "Left Eye";
            }
            this.props.addObjectInside(whichArray, dd, whichKey);
          }}
          onDoubleClick={() => {
            let whichArray = each.entityArray;
            let whichKey = "";
            if (each.entityArray === "findings") {
              dd["site"] = "Right Eye";
              whichKey = each.helper;
            }
            if (["eyeDx"].includes(each.entityArray)) {
              dd["site"] = "Right Eye";
            }
            this.props.addObjectInside(whichArray, dd, whichKey);
          }}
        />
      );
    });
  };
  renderCommon = () => {
    return (
      <Collapse isOpen={this.state.collapse}>
        <Callout
          title={
            <H4>
              <ControlGroup>
                {" "}
                <Button
                  minimal
                  text="Suggestions"
                  intent="warning"
                  onClick={() =>
                    this.setState({ collapse: !this.state.collapse })
                  }
                />
                {this.state.loading ? (
                  <Spinner size={20} />
                ) : (
                  <Button
                    minimal
                    icon="refresh"
                    onClick={() => {
                      this.getSuggest();
                    }}
                  />
                )}
              </ControlGroup>

              {/* <Button
                minimal
                icon="thumbs-up"
                intent="success"
                onClick={() =>
                  this.setState({ collapse: !this.state.collapse })
                }
              />
              <Button
                minimal
                icon="thumbs-down"
                intent="danger"
                onClick={() =>
                  this.setState({ collapse: !this.state.collapse })
                } />*/}
            </H4>
          }
          intent="primary"
          icon={null}
          style={{ width: "fit-content" }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            {this.renderSuggestions()}
          </div>
        </Callout>
      </Collapse>
    );
  };
  renderCC = () => {
    if (this.state.collapse) {
      return null;
    }
    return (
      <Button
        text="Show Suggestions"
        intent="warning"
        minimal
        icon="predictive-analysis"
        onClick={() => this.setState({ collapse: true })}
      />
    );
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        {this.renderCC()}
        {this.renderCommon()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(SuggColl));
