import React from "react";
import { inject, observer } from "mobx-react";

import { Redirect } from "react-router-dom";
import {
  apiCallPost,
  apiCallPostCancellable,
  niceTime,
  timeStringHrs,
} from "../fns/util";
import _ from "lodash";
import cogoToast from "cogo-toast";
import { ControlGroup, Tag } from "@blueprintjs/core";
import moment from "moment";

class BookingViewThread extends React.Component {
  state = {
    redirect: null,
    tIn: null,
    focusElem: null,
  };
  constructor(props) {
    super(props);
    this.currentFocus = React.createRef();
    this.focus = this.focus.bind(this);
  }
  getNewDetails = async (pid) => {
    try {
      let v = await apiCallPost(
        `/sidepane/${this.props.authStore.prefix}/previousVisits`,
        {
          PID: pid,
        }
      );

      this.props.patientStore.patientPreviousVisits = v;
      /* if (v !== undefined) {
        await set(pid, { previousVisits: v, visitsLastUpdated: new Date() });
      } */
    } catch (err) {
      console.log(err);
      cogoToast.error(
        "There was an error updating the list of appointments automatically.",
        { position: "bottom-left" }
      );
    }
  };

  getClinical = async (pid) => {
    this.props.patientStore.clinicalRecordToday = false;
    this.props.patientStore.tps = [];
    this.props.patientStore.glassRxLink = null;
    this.props.patientStore.rxLink = null;
    this.props.patientStore.visitDone = null;

    try {
      let cc = await apiCallPostCancellable(
        `/clinical/${this.props.authStore.prefix}/sidePane`,
        {
          genericPid: pid,
        }
      );
      if (cc) {
        this.props.patientStore.clinicalRecordToday = cc.clinicalRecordToday;
        this.props.patientStore.tps = cc.touchpoints;
        this.props.patientStore.glassRxLink = cc.glassRxLink;
        this.props.patientStore.rxLink = cc.rxLink;
        this.props.patientStore.visitDone = cc.visitDone;
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("There was an error updating clinical record.", {
        position: "bottom-left",
      });
    }
  };
  focus = async (element) => {
    this[element].div.focus();

    /** WTF??? */
    this.props.bookingStore.whichPatientInFocus = this[element].prSt.promoteKey;
    /** ??? */
    this.props.patientStore.patientName = this[element].prSt.name;
    this.props.patientStore.patientPhone = this[element].prSt.phone;
    this.props.patientStore.genericPid = this[element].prSt.pid;
    this.props.patientStore.patientAge = this[element].prSt.age;
    this.props.patientStore.patientSex = this[element].prSt.sex;
    this.props.patientStore.addlSearchTerm =
      this[element].prSt.raw.addlSearchTerm;
    this.props.patientStore.panels = this[element].prSt.panels;

    /** PATTERN TO UPDATE ARCHIVE */
    let pid = this[element].prSt.pid;
    let patientExistsInIdb = null;
    if (patientExistsInIdb) {
      if (patientExistsInIdb.previousVisits) {
        this.props.patientStore.patientPreviousVisits =
          patientExistsInIdb.previousVisits;

        let lastUpdate = new Date(patientExistsInIdb.visitsLastUpdated);

        let now = new Date();

        if (now.getTime() - lastUpdate.getTime() >= 5 * 60 * 1000) {
          this.getNewDetails(pid);
          this.getClinical(pid);
        }
      } else {
        // call the API now.
        this.props.patientStore.patientPreviousVisits = {};
        this.getNewDetails(pid);
        this.getClinical(pid);
      }
    } else {
      this.props.patientStore.patientPreviousVisits = {};
      this.getNewDetails(pid);
      this.getClinical(pid);
    }
    /** PATTERN END */
  };

  handleKeyPress = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      this.props.down();
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      this.props.up();
    }
    if (event.key === "Escape") {
      event.preventDefault();
      this.props.escape(event);
    }
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleSelect();
    }
    if (event.key === "b") {
      event.preventDefault();
      this.setState({ redirect: "/booking" });
    }

    if (event.key === "c") {
      event.preventDefault();
      this.setState({ redirect: "/clinic" });
    }

    if (event.key === "m") {
      event.preventDefault();
      this.setState({ redirect: "/billing" });
    }
  };

  /** WTF */
  handleSelect = () => {
    this.props.bookingStore.chosenPatientForBooking = this.props;
  };
  renderThreadContents = () => {
    if (!this.props.bookingStore.isList) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <Tag minimal large intent="success">
            {_.startCase(_.lowerCase(this.props.name))}
          </Tag>{" "}
          <Tag minimal large intent="warning">
            {this.props.pid}
          </Tag>
          &nbsp; &nbsp; &nbsp;
          <Tag minimal>
            {this.props.age} / {this.props.sex}
          </Tag>{" "}
          &nbsp; &nbsp; &nbsp;
          <Tag minimal>{this.props.phone}</Tag> &nbsp; &nbsp; &nbsp;{" "}
          {_.startCase(_.lowerCase(this.props.address))}
        </div>
      );
    }
    if (this.props.raw.hasToday) {
      let remarks = _.startCase(
        _.lowerCase(
          _.filter(
            this.props.raw.events.map((each) => each.remarks),
            (ee) => ee !== "<None>"
          ).join(", ")
        )
      );

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ControlGroup>
            <Tag
              style={{ fontSize: "larger" }}
              large
              intent={
                this.props.raw.globalFlag.firstRegnTime
                  ? this.props.raw.globalFlag.lastCheckoutTime
                    ? "success"
                    : "primary"
                  : "none"
              }
            >
              {_.startCase(_.lowerCase(this.props.name))}
            </Tag>{" "}
            <Tag
              minimal
              intent={
                this.props.raw.globalFlag.firstRegnTime
                  ? this.props.raw.globalFlag.lastCheckoutTime
                    ? "success"
                    : "primary"
                  : "none"
              }
            >
              {this.props.pid}
            </Tag>
            <div style={{ marginLeft: "10px" }}>
              {this.renderGlobalTimes(this.props.raw.globalFlag)}
            </div>{" "}
          </ControlGroup>
          <div style={{ display: "flex" }}>
            {this.renderConsultation(this.props.raw.clinical_visits)}
          </div>
          <div style={{ display: "flex" }}>
            {this.renderLineItems(this.props.raw.lineitems, true)}
          </div>
          {this.props.raw.globalFlag.firstRegnTime ? null : (
            <div style={{ marginLeft: "10px", display: "flex" }}>{remarks}</div>
          )}
        </div>
      );
    } else if (!this.props.raw.hasToday) {
      let numOfLineItems = this.props.raw.lineitems
        ? this.props.raw.lineitems.length
        : 0;
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <Tag
            minimal
            large
            intent={
              this.props.raw.globalFlag.firstRegnTime
                ? this.props.raw.globalFlag.lastCheckoutTime
                  ? "success"
                  : "primary"
                : "none"
            }
          >
            {_.startCase(_.lowerCase(this.props.name))}
          </Tag>
          <div style={{ display: "flex" }}>
            {numOfLineItems ? (
              <>
                {numOfLineItems > 4 ? (
                  <>
                    {
                      (this.renderLineItems(
                        _.slice(this.props.raw.lineitems, 0, 4)
                      ),
                      false)
                    }{" "}
                    and {numOfLineItems - 4} more appointments.
                  </>
                ) : (
                  <>{this.renderLineItems(this.props.raw.lineitems, false)}</>
                )}
              </>
            ) : (
              "No appointments in this period."
            )}
          </div>
        </div>
      );
    }
  };
  renderGlobalTimes = (gf) => {
    let diff = 0;
    let diffStyle = "";
    if (gf.firstRegnTime) {
      diff =
        moment(new Date(gf.firstRegnTime)).diff(
          moment(new Date(gf.firstApptTime))
        ) / 1000;
      if (diff < 5 * 60) {
        diffStyle = "success";
      } else if (diff < 20 * 60) {
        diffStyle = "warning";
      } else {
        diffStyle = "danger";
      }
    }
    let hospTimeLast = gf.lastCheckoutTime || new Date();
    let timeInHosp = Math.round(
      moment(new Date(hospTimeLast)).diff(moment(new Date(gf.firstRegnTime))) /
        (1000 * 60)
    );
    let timeStyle = "";
    if (timeInHosp <= 120) {
      timeStyle = "success";
    } else if (timeInHosp <= 180) {
      timeStyle = "warning";
    } else {
      timeStyle = "danger";
    }

    return (
      <ControlGroup>
        <ControlGroup vertical>
          {" "}
          <Tag
            icon="time"
            minimal
            intent={gf.firstRegnTime ? "primary" : "none"}
          >
            {niceTime(gf.firstApptTime)}{" "}
          </Tag>
          {gf.firstRegnTime ? (
            <Tag icon="bank-account" minimal intent={diffStyle}>
              {niceTime(gf.firstRegnTime)}{" "}
            </Tag>
          ) : (
            <Tag icon="disable" minimal intent="danger">
              Not Registered
            </Tag>
          )}
        </ControlGroup>
        {/* {gf.firstRegnTime ? (
          <Tag large minimal intent={timeStyle} icon="stopwatch">
            {timeStringHrs(timeInHosp)}
          </Tag>
        ) : null} */}
      </ControlGroup>
    );
  };
  renderConsultation = (cv) => {
    if (cv && cv.length) {
      return cv.map((each) => {
        return (
          <div style={{ marginLeft: "10px" }}>
            {this.renderEachConsultation(each)}
          </div>
        );
      });
    }
  };
  renderEachConsultation = (ccc) => {
    let diffRegnVision = 0;
    let styleRegnVision = "";
    let diffVisionDx = 0;
    let styleVisionDx = "";
    let visionIcon = "eye-open";
    /** Vision Icons & Styling */
    let vxTime = ccc.visionDoneAt || new Date();
    diffRegnVision = Math.round(
      moment(new Date(vxTime)).diff(moment(new Date(ccc.registrationTime))) /
        (1000 * 60)
    );

    if (diffRegnVision < 15) {
      styleRegnVision = "success";
    } else if (diffRegnVision < 30) {
      styleRegnVision = "warning";
    } else {
      styleRegnVision = "danger";
    }
    if (ccc.dilationDoneAt) {
      visionIcon = "tint";
    }

    let dxTime = ccc.done ? ccc.updatedAt : new Date();
    diffVisionDx = Math.round(
      moment(new Date(dxTime)).diff(moment(new Date(ccc.visionDoneAt))) /
        (1000 * 60)
    );

    if (diffVisionDx <= 45) {
      styleVisionDx = "success";
    } else if (diffVisionDx < 60) {
      styleVisionDx = "warning";
    } else {
      styleVisionDx = "danger";
    }

    return (
      <>
        {" "}
        <ControlGroup>
          <Tag intent="primary" minimal large>
            {ccc.doctor || "None"}
          </Tag>{" "}
          {/* <ControlGroup vertical>
            <Tag
              minimal={ccc.visionDoneAt}
              icon={visionIcon}
              intent={styleRegnVision}
            >
              {ccc.visionDoneAt ? (
                <>{niceTime(ccc.visionDoneAt)}</>
              ) : (
                `Waiting: ${timeStringHrs(diffRegnVision)}`
              )}
            </Tag>
            <Tag minimal={ccc.done} icon="diagnosis" intent={styleVisionDx}>
              {ccc.done ? (
                <>{niceTime(ccc.updatedAt)}</>
              ) : ccc.visionDoneAt ? (
                `Waiting: ${timeStringHrs(diffVisionDx)}`
              ) : (
                "Awaiting Vision"
              )}{" "}
            </Tag>
          </ControlGroup> */}
        </ControlGroup>{" "}
      </>
    );
  };
  renderLineItems = (li, hasToday) => {
    if (li && li.length) {
      return li.map((each) => {
        return (
          <div style={{ marginLeft: "10px" }}>
            {this.renderEachLineItem(each, hasToday)}
          </div>
        );
      });
    }
  };
  renderEachLineItem = (lll, hasToday) => {
    if (lll.kind === "investigation") {
      return (
        <ControlGroup vertical>
          <Tag
            minimal
            large={lll.activated}
            intent={lll.activated ? "primary" : "none"}
          >
            {`${lll.resource} - ${lll.investigationDetail.specificKind}`}
          </Tag>
          {lll.activated ? null : <Tag minimal>{lll.sysDocketNo}</Tag>}
        </ControlGroup>
      );
    }
    if (lll.kind === "consultation") {
      if (lll.activated && hasToday) {
        return null;
      }
      return (
        <ControlGroup vertical>
          <Tag minimal>{lll.resource}</Tag>
          <Tag minimal>
            {lll.site} - {lll.sysDocketNo}
          </Tag>
        </ControlGroup>
      );
    }
  };
  render() {
    var prSt = this.props;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div
        className="thread-unselected"
        ref={(div) => (this[this.props.name] = { div, prSt })}
        tabIndex={0}
        onKeyDownCapture={this.handleKeyPress}
        onClick={() => {
          this.focus(this.props.name, this.props.raw);
        }}
      >
        {this.renderThreadContents()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "patientStore"
)(observer(BookingViewThread));
