import { ResponsiveHeatMap } from "@nivo/heatmap";
import { ResponsiveFunnel } from "@nivo/funnel";
import { ResponsivePie } from "@nivo/pie";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import React from "react";
import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  Icon,
  NonIdealState,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import { toJS } from "mobx";
import { apiCallPost } from "../../fns/util";
import cogoToast from "cogo-toast";

class ClinicDashboard extends React.Component {
  state = {
    chosenSite: null,
    chosenDoctor: null,
    doctorList: [],
    heatMapData: [],
    funnelData: [],
    gettingSite: false,
    gettingDoctor: false,
    gettingPie: false,
    isMobile: false,
    openAccordionIndex: null,
    accordionData: {},
  };

  componentDidMount() {
    if (window.innerWidth < 768) {
      this.setState({ isMobile: true });
    }

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  toggleAccordion = async (row, index) => {
    const { openAccordionIndex } = this.state;
    if (openAccordionIndex === index) {
      this.setState({ openAccordionIndex: null });
    } else {
      this.setState({ openAccordionIndex: index });

      try {
        cogoToast.loading("Loading dashboard for " + row.name, {
          position: "bottom-left",
        });
        let payload = { referringDoctorId: row._id };
        let res = await apiCallPost(
          `/clinical/dashboard/${this.props.authStore.prefix}/referring-doc-investigation`,
          payload
        );
        cogoToast.success("Loaded data.", { position: "bottom-left" });

        this.setState((prevState) => ({
          accordionData: {
            ...prevState.accordionData,
            [index]: res,
          },
        }));

        console.log("Accordion data", this.state.accordionData);
      } catch (err) {
        cogoToast.error(
          "There was an error in loading data. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
  };
  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  getHeatMap = async () => {
    try {
      this.setState({
        heatMapData: [],
        chosenDoctor: null,
        doctorList: [],
        gettingSite: true,
        openAccordionIndex: null,
        accordionData: {},
      });
      cogoToast.loading("Loading dashboard for " + this.state.chosenSite, {
        position: "bottom-left",
      });
      let payload = { site: this.state.chosenSite };
      let res = await apiCallPost(
        `/clinical/dashboard/${this.props.authStore.prefix}/site-data`,
        payload
      );
      cogoToast.success("Loaded data.", { position: "bottom-left" });

      this.setState({ heatMapData: res, gettingSite: false }, () => {
        let arr = res.map((x) => x.name);
        this.setState({ doctorList: arr });
      });
    } catch (err) {
      cogoToast.error(
        "There was an error in loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  getFunnel = async () => {
    try {
      this.setState({
        funnelData: [],
        gettingDoctor: true,
      });
      cogoToast.loading("Loading dashboard for " + this.state.chosenDoctor, {
        position: "bottom-left",
      });
      let payload = {
        doctor: this.state.chosenDoctor,
        site: this.state.chosenSite,
      };
      let res = await apiCallPost(
        `/clinical/dashboard/${this.props.authStore.prefix}/site-doc-funnel`,
        payload
      );
      if (!res || !res.length) {
        this.setState({ funnelData: [], gettingDoctor: false });
        return;
      }
      let arr = [];
      _.map(res, (each) => {
        let obj = {
          id: each._id,
          value: each.value,
          label: each.label,
        };
        arr.push(obj);
      });

      this.setState({ funnelData: arr, gettingDoctor: false });
      cogoToast.success("Data loaded.", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  getPie = async (key) => {
    try {
      this.setState({
        pieData: [],
        gettingPie: true,
      });
      cogoToast.loading("Loading data for " + this.state.chosenDoctor, {
        position: "bottom-left",
      });
      let payload = {
        doctor: this.state.chosenDoctor,
        site: this.state.chosenSite,
        entityType: key,
      };
      let res = await apiCallPost(
        `/clinical/dashboard/${this.props.authStore.prefix}/site-doc-type-pie`,
        payload
      );
      cogoToast.success("Loaded data.", { position: "bottom-left" });
      if (!res || !res.length) {
        this.setState({ pieData: [], gettingPie: false });
        return;
      }

      let arr = [];
      _.map(res, (each) => {
        let obj = {
          id: each._id,
          value: each.count,
          label: each._id,
        };
        arr.push(obj);
      });

      this.setState({ pieData: arr, gettingPie: false });
    } catch (err) {
      cogoToast.error(
        "There was an error in loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  renderControls = () => {
    let siteList = toJS(this.props.listStore.siteList);
    return (
      <Card
        style={{ background: "none", height: "auto", width: "100%" }}
        elevation={Elevation.FOUR}
      >
        <ControlGroup vertical>
          <Tag large intent="warning" minimal>
            Quick View Dashboard
          </Tag>
          <Tag large intent="primary" minimal>
            Choose Site
          </Tag>
          <SingleSelectWrapper
            items={siteList}
            selectedItem={this.state.chosenSite}
            onChange={(item) => this.setState({ chosenSite: item })}
          />
          <Button
            minimal
            disabled={!this.state.chosenSite}
            intent="success"
            text="Get Data for Site"
            icon="map-marker"
            loading={this.state.gettingSite}
            rightIcon="arrow-right"
            onClick={() => this.getHeatMap()}
          />
          <br />
          {this.state.chosenSite ? (
            <>
              <Tag large intent="danger" minimal>
                Choose Doctor
              </Tag>
              <SingleSelectWrapper
                items={this.state.doctorList}
                selectedItem={this.state.chosenDoctor}
                onChange={(item) =>
                  this.setState({
                    chosenDoctor: item,
                    funnelData: [],
                    pieData: [],
                  })
                }
              />
              <br />{" "}
              <Button
                minimal
                disabled={!this.state.chosenDoctor}
                intent="success"
                text="Get Data for Doctor"
                icon="diagnosis"
                loading={this.state.gettingDoctor}
                rightIcon="arrow-right"
                onClick={() => this.getFunnel()}
              />
            </>
          ) : (
            <Tag minimal large multiline>
              Please choose site to view list of doctors.
            </Tag>
          )}
        </ControlGroup>
      </Card>
    );
  };

  renderOverallCard = () => {
    return (
      <Card
        style={{ background: "none", height: "auto", width: "100%" }}
        elevation={Elevation.FOUR}
      >
        {this.state.gettingSite ? (
          <NonIdealState icon={<Spinner />} title="Getting data for site..." />
        ) : (
          <> {this.renderHeatMap()}</>
        )}
      </Card>
    );
  };
  renderPieCard = () => {
    if (this.state.gettingPie) {
      return (
        <Card
          style={{ background: "none", height: "60vh", width: "59vw" }}
          elevation={Elevation.FOUR}
        >
          <NonIdealState
            icon={<Spinner />}
            title="Getting data for doctor..."
          />
        </Card>
      );
    }
    return (
      <Card
        style={{ background: "none", height: "60vh", width: "59vw" }}
        elevation={Elevation.FOUR}
      >
        {this.renderPie()}
      </Card>
    );
  };
  renderFunnelCard = () => {
    if (this.state.gettingDoctor) {
      return (
        <Card
          style={{ background: "none", height: "60vh", width: "39vw" }}
          elevation={Elevation.FOUR}
        >
          <NonIdealState
            icon={<Spinner />}
            title="Getting data for doctor..."
          />
        </Card>
      );
    }
    return (
      <Card
        style={{ background: "none", height: "60vh", width: "39vw" }}
        elevation={Elevation.FOUR}
      >
        {this.renderFunnel()}
      </Card>
    );
  };
  renderHeatMap = () => {
    const { openAccordionIndex, accordionData } = this.state;
    if (!this.state.heatMapData || !this.state.heatMapData.length) {
      return (
        <Tag minimal large>
          No data available.
        </Tag>
      );
    }

    return (
      // <ResponsiveHeatMap
      //   data={this.state.heatMapData}
      //   keys={[
      //     "PreBooked",
      //     "Walkins",
      //     "Billed",
      //     "Vision",
      //     "Done",
      //     "Counselled",
      //     "SxCounselled",
      //     "InvestigationsBooked",
      //     "SxAdvised",
      //   ]}
      //   indexBy="Name"
      //   margin={{ top: 70, right: 10, bottom: 10, left: 200 }}
      //   forceSquare={false}
      //   axisTop={{
      //     orient: "top",
      //     tickSize: 5,
      //     tickPadding: 5,
      //     tickRotation: -90,
      //     legend: "",
      //     legendOffset: 36,
      //   }}
      //   axisRight={null}
      //   axisBottom={null}
      //   axisLeft={{
      //     orient: "left",
      //     tickSize: 5,
      //     tickPadding: 5,
      //     tickRotation: 0,
      //   }}
      //   theme={{
      //     textColor: "white",
      //     fontFamily: "Axis",
      //     labels: { text: { fontFamily: "Axis" } },
      //     fontSize: "medium",
      //     tooltip: {
      //       container: {
      //         backgroundColor: "black",
      //       },
      //     },
      //     grid: {
      //       line: {
      //         stroke: "#696363",
      //       },
      //     },
      //   }}
      //   cellOpacity={1}
      //   cellBorderColor={{ from: "color", modifiers: [["darker", 0.4]] }}
      //   labelTextColor={{ from: "color", modifiers: [["darker", 2.8]] }}
      //   animate={true}
      //   motionConfig="wobbly"
      //   motionStiffness={80}
      //   motionDamping={9}
      //   hoverTarget="cell"
      //   cellHoverOthersOpacity={0.25}
      // />
      <div
        style={{ padding: "20px", display: "flex", justifyContent: "center" }}
      >
        <div
          style={{
            overflowX: "auto",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Name</th>
                <th style={{ minWidth: "100px" }}>Scheduled</th>
                <th style={{ minWidth: "100px" }}>Seen</th>
                <th style={{ minWidth: "100px" }}>
                  <span>Waiting</span>
                  <br></br>
                  <span>1st Half</span>
                </th>

                <th
                  style={{ borderRight: "1px solid #ddd", minWidth: "100px" }}
                >
                  <span>Waiting</span>
                  <br></br>
                  <span>2nd Half</span>
                </th>
                <th style={{ minWidth: "100px" }}>Investigated</th>
                <th style={{ minWidth: "100px" }}>Sx Advised</th>
              </tr>
            </thead>
            <tbody>
              {this.state.heatMapData.map((row, index) => (
                <React.Fragment key={row.name}>
                  <tr
                    onClick={() => this.toggleAccordion(row, index)}
                    style={{
                      cursor: "pointer",
                      background: index % 2 === 0 ? "#17191a" : "#0e1012",
                      borderBottom:
                        index === this.state.heatMapData.length - 1
                          ? "none"
                          : "1px solid #ddd",
                    }}
                  >
                    <td
                      style={{
                        textAlign: "left",
                        padding: "12px",
                        verticalAlign: "middle",
                      }}
                    >
                      {openAccordionIndex === index ? (
                        <Icon icon="caret-down" />
                      ) : (
                        <Icon icon="caret-right" />
                      )}
                      {row.name}
                    </td>
                    <td style={{ padding: "12px", verticalAlign: "middle" }}>
                      {row.noOfBookings ? row.noOfBookings : 0}
                    </td>
                    <td style={{ padding: "12px", verticalAlign: "middle" }}>
                      {row.noOfPatientsCheckedout
                        ? row.noOfPatientsCheckedout
                        : 0}
                    </td>
                    <td
                      style={{
                        padding: "12px",
                        verticalAlign: "middle",
                      }}
                    >
                      {row.noOfPatientsRemainingBefore130
                        ? row.noOfPatientsRemainingBefore130
                        : 0}
                    </td>
                    <td
                      style={{
                        padding: "12px",
                        verticalAlign: "middle",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      {row.noOfPatientsRemainingAfter130
                        ? row.noOfPatientsRemainingAfter130
                        : 0}
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      {row.noOfInvestigations ? row.noOfInvestigations : 0}
                    </td>
                    <td style={{ padding: "12px", verticalAlign: "middle" }}>
                      {row.noOfSurgeriesAdvised ? row.noOfSurgeriesAdvised : 0}
                    </td>
                  </tr>
                  {openAccordionIndex === index && (
                    <tr>
                      <td colSpan="8" style={{ padding: "0" }}>
                        <div
                          style={{
                            padding: "10px",
                            backgroundColor: "#17191a",
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                            color: "#fff",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "5px",
                              textAlign: "left",
                              transition: "all 0.3s ease",
                            }}
                          ></div>
                          {accordionData[index] ? (
                            accordionData[index]?.map((item, i) => (
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  alignItems: "center",
                                  marginBottom: "5px",
                                  marginLeft: "2rem",
                                  background:
                                    i % 2 === 0 ? "#0e1012" : "#17191a",
                                  borderRadius: "4px",
                                  padding: "5px",
                                }}
                              >
                                <div style={{ flex: 1 }}>{item?._id}</div>
                                <div style={{ flex: 1, marginLeft: "10px" }}>
                                  {item?.count}
                                </div>
                              </div>
                            ))
                          ) : (
                            <NonIdealState
                              icon={<Spinner />}
                              title="Getting data ..."
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  render() {
    const { isMobile } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          padding: "20px",
        }}
        className="bp5-dark"
      >
        <div style={{ width: isMobile ? "100%" : "30%" }}>
          {this.renderControls()}
        </div>
        <div
          style={{
            width: isMobile ? "100%" : "70%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {this.renderOverallCard()}
        </div>
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "listStore"
)(observer(ClinicDashboard));
