import {
  Button,
  Card,
  Collapse,
  ControlGroup,
  Elevation,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import _ from "lodash";
import { nanoid } from "nanoid";
import { masterVisitParameters } from "./masterVisitParameters";
import { initials } from "../fns/util";
import { inject, observer } from "mobx-react";

class ClinicalVisitParametersPreview extends React.Component {
  state = {
    areDetailsOpen: this.props.authStore.prefix === "lotus" ? true : false,
  };
  renderPatientParametersAsAdded = () => {
    let patientParameters = this.props.data.visitParameters;
    if (!patientParameters) return null;
    if (!patientParameters.length) return null;
    // First we group the patient parameters by their TYPE_ --> parameterType. This is because we want to render them in separate tables.

    let typesOfParams = _.uniq(
      patientParameters.map((each) => each.parameterType)
    );
    typesOfParams.sort((a, b) => {
      return (
        masterVisitParameters.map((item) => item.parameterType).indexOf(a) -
        masterVisitParameters.map((item) => item.parameterType).indexOf(b)
      );
    });

    return typesOfParams.map((eachType, tableIndex) => {
      let typeSample = _.find(
        patientParameters,
        (x) => x.parameterType === eachType
      );
      let hasLaterality = typeSample.hasLaterality;
      let hasSubTypes = typeSample.parameterSubtype !== "";
      let whichCase;
      if (hasSubTypes && hasLaterality) {
        whichCase = 0;
      } else if (hasSubTypes && !hasLaterality) {
        whichCase = 1;
      } else if (!hasSubTypes && hasLaterality) {
        whichCase = 2;
      } else if (!hasSubTypes && !hasLaterality) {
        whichCase = 3;
      }
      let whichSubTypes = _.filter(
        patientParameters,
        (x) => x.parameterType === eachType
      );
      // sort subtypes
      if (whichSubTypes.length && whichSubTypes.length > 1) {
        whichSubTypes.sort((a, b) => {
          let master = masterVisitParameters;
          let masterSample = _.find(
            master,
            (x) => x.parameterType === a.parameterType
          );
          let subtypesInMaster = masterSample.parameterSubtypes;
          return (
            subtypesInMaster
              .map((item) => item.parameterSubtype)
              .indexOf(a.parameterSubtype) -
            subtypesInMaster
              .map((item) => item.parameterSubtype)
              .indexOf(b.parameterSubtype)
          );
        });
      }
      if (whichCase === 0) {
        return (
          <table className="bp5-html-table bp5-compact">
            <thead>
              <tr style={{ fontSize: "small" }}>
                <th rowSpan={2} className="first_cell">
                  {typeSample.short}
                </th>
                {typeSample.data.map((each, index) => {
                  if (index === 0) {
                    return (
                      <th
                        colSpan={typeSample.data.length / 2}
                        className="vision_right_border first_cell"
                      >
                        {initials(each.laterality)}
                      </th>
                    );
                  } else if (index === typeSample.data.length / 2) {
                    return (
                      <th
                        colSpan={typeSample.data.length / 2}
                        className="first_cell"
                        style={{ paddingLeft: "5px" }}
                      >
                        &nbsp;&nbsp;{initials(each.laterality)}
                      </th>
                    );
                  } else {
                    return null;
                  }
                })}
              </tr>
              {typeSample.data.length > 2 && (
                <tr style={{ fontSize: "smaller" }}>
                  {typeSample.data.map((each, index) => {
                    return (
                      <th
                        key={nanoid()}
                        className={
                          index === typeSample.data.length / 2 - 1
                            ? "vision_right_border narrow_cell"
                            : "narrow_cell"
                        }
                      >
                        {each.short}
                      </th>
                    );
                  })}
                </tr>
              )}
            </thead>
            <tbody>
              {whichSubTypes.map((each, rowIndex) => {
                return (
                  <tr key={nanoid()} style={{ fontSize: "smaller" }}>
                    <td className="narrow_cell first_cell">
                      <Tag
                        intent="success"
                        onRemove={() => {
                          this.props.removeVisitParameter(each._id);
                        }}
                        className="green__bg"
                      >
                        {each.subtypeShort}
                      </Tag>
                    </td>
                    {each.data.map((eachData, cellIndex) => {
                      return (
                        <td
                          key={nanoid()}
                          className={
                            cellIndex === each.data.length / 2 - 1
                              ? "vision_right_border narrow_cell"
                              : "narrow_cell"
                          }
                        >
                          {this.renderCellValue(
                            eachData,
                            cellIndex,
                            each._id,
                            rowIndex,
                            tableIndex,
                            whichSubTypes.length,
                            typesOfParams.length
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      }
      if (whichCase === 1) {
        return (
          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <ControlGroup>
              {typeSample.short}
              <div>
                {whichSubTypes.map((each, rowIndex) => {
                  return (
                    <ControlGroup>
                      {" "}
                      <Tag
                        intent="success"
                        className="turquoise__bg"
                        onRemove={() => {
                          this.props.removeVisitParameter(each._id);
                        }}
                      >
                        {each.subtypeShort}
                      </Tag>
                      {each.data.map((eachData, cellIndex) => {
                        return (
                          <>
                            {this.renderCellValue(
                              eachData,
                              cellIndex,
                              each._id,
                              rowIndex,
                              tableIndex,
                              whichSubTypes.length,
                              typesOfParams.length
                            )}
                          </>
                        );
                      })}
                    </ControlGroup>
                  );
                })}
              </div>
            </ControlGroup>
          </div>
        );
      }
      if (whichCase === 2) {
        return (
          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <ControlGroup>
              <Tag intent="success" className="turquoise__bg">
                {typeSample.nice}
              </Tag>
              <div>
                {whichSubTypes.map((each, rowIndex) => {
                  return (
                    <ControlGroup>
                      <Button
                        minimal
                        small
                        icon="cross"
                        onClick={() => {
                          this.props.removeVisitParameter(each._id);
                        }}
                      />
                      {each.data.map((eachData, cellIndex) => {
                        return (
                          <ControlGroup>
                            <Tag intent="success" className="green__bg">
                              {initials(eachData.laterality)}
                            </Tag>
                            {this.renderCellValue(
                              eachData,
                              cellIndex,
                              each._id,
                              rowIndex,
                              tableIndex,
                              whichSubTypes.length,
                              typesOfParams.length
                            )}
                          </ControlGroup>
                        );
                      })}
                    </ControlGroup>
                  );
                })}
              </div>
            </ControlGroup>
          </div>
        );
      }
      if (whichCase === 3) {
        return (
          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <ControlGroup>
              <Tag intent="success" className="turquoise__bg">
                {typeSample.nice}
              </Tag>
              <div>
                {whichSubTypes.map((each, rowIndex) => {
                  return (
                    <ControlGroup>
                      <Button
                        small
                        minimal
                        icon="cross"
                        onClick={() => {
                          this.props.removeVisitParameter(each._id);
                        }}
                      />
                      {each.data.map((eachData, cellIndex) => {
                        return (
                          <ControlGroup>
                            {this.renderCellValue(
                              eachData,
                              cellIndex,
                              each._id,
                              rowIndex,
                              tableIndex,
                              whichSubTypes.length,
                              typesOfParams.length
                            )}
                          </ControlGroup>
                        );
                      })}
                    </ControlGroup>
                  );
                })}
              </div>
            </ControlGroup>
          </div>
        );
      }
      return;
    });
  };
  renderCellValue = (eachData) => {
    if (Array.isArray(eachData.value)) {
      const text = eachData.value.join(", ");
      return (
        <Tag className="no__bg" minimal>
          {text}
        </Tag>
      );
    }
    return (
      <Tag className="no__bg" minimal>
        {eachData.value || ""}
      </Tag>
    );
  };
  render() {
    return (
      <div>
        <Card elevation={Elevation.FOUR} className="card__container">
          <div className="flex__push__apart">
            <ControlGroup>
              <Tag
                large
                className={
                  this.state.areDetailsOpen ? "cerulean__bg" : "forest__bg"
                }
                intent="success"
                onClick={() => this.props.onChoice("VISIT_PARAMETERS")}
              >
                <b>Optometry</b>
              </Tag>
            </ControlGroup>
            <ControlGroup>
              <Button
                onClick={() =>
                  this.setState({
                    areDetailsOpen: !this.state.areDetailsOpen,
                  })
                }
                icon={
                  this.state.areDetailsOpen
                    ? "double-chevron-up"
                    : "double-chevron-down"
                }
              />
            </ControlGroup>
          </div>
        </Card>
        <Collapse isOpen={this.state.areDetailsOpen}>
          {" "}
          <div
            style={{
              fontSize: "smaller",
              overflowX: "scroll",
              padding: "10px",
            }}
          >
            {this.renderPatientParametersAsAdded()}
          </div>
        </Collapse>
      </div>
    );
  }
}
// export default ClinicalVisitParametersPreview;
export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(ClinicalVisitParametersPreview));
