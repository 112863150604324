import { ControlGroup, Tag, AnchorButton, Button } from "@blueprintjs/core";
import React from "react";
import { inject, observer } from "mobx-react";
import { niceDate } from "../../fns/util";
import _ from "lodash";
import { generateMasterVisitEntities } from "../masterVisitEntities";
import { masterVisitParameters } from "../masterVisitParameters";
import cogoToast from "cogo-toast";

class PastVisit extends React.Component {
  state = {};
  masterVisitEntities = generateMasterVisitEntities(
    this.props.authStore.prefix
  );
  componentDidMount() {}
  componentWillUnmount() {}

  sortSubsectionEntities = (sectionName, entities, masterVisitEntities) => {
    const section = masterVisitEntities.find(
      (v) => v.sectionName === sectionName
    );
    const subsectionsInThisRx = section.subsections;

    return _.sortBy(entities, (e) => {
      let subsectionIndex = _.findIndex(
        subsectionsInThisRx,
        (x) => x.subsectionName === e.subsectionName
      );
      return subsectionIndex;
    });

    // return _.sortBy(sort((a, b) => {
    //   let aIndex = _.findIndex(
    //     subsectionsInThisRx,
    //     (x) => x.subsectionName === a
    //   );
    //   let bIndex = _.findIndex(
    //     subsectionsInThisRx,
    //     (x) => x.subsectionName === b
    //   );
    //   return aIndex - bIndex;
    // });
  };

  sortClinicalParams = (params, masterVisitParameters) => {
    return _.sortBy(params, (e) => {
      let paramIndex = _.findIndex(
        masterVisitParameters,
        (x) => x.parameterType === e.parameterType
      );
      return paramIndex;
    });
  };

  fixLaterality = (lt) => {
    if (lt == null) return null;
    if (lt === "BILATERAL") return "BE";
    if (lt === "RIGHT_EYE") return "RE";
    if (lt === "RIGHT") return "RE";
    if (lt === "LEFT_EYE") return "LE";
    if (lt === "LEFT") return "LE";
    return null;
  };

  fixCasing = (hd) => {
    return _.startCase(_.toLower(hd));
  };

  renderTagged = (e, intent, minimal) => {
    if (e == null) return null;
    if (e === "") return null;
    return (
      <Tag minimal={minimal} intent={intent} multiline={true}>
        {e}
      </Tag>
    );
  };
  renderBySection = (entities, tags) => {
    const groupedSubSections = _.groupBy(entities, "subsectionName");
    const renderDivs = [];

    for (let ss of Object.keys(groupedSubSections)) {
      const ents = groupedSubSections[ss];

      const renderEnts = ents.map((e) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {this.renderTagged(this.fixLaterality(e.laterality), "warning", true)}{" "}
          {this.renderTagged(
            this.fixCasing(e.anatomicalPart || ""),
            "success",
            false
          )}{" "}
          {this.renderTagged(e.entityName, "success", true)}{" "}
          {this.renderTagged(
            _.truncate(e.instructions || "", {
              length: 100,
              omission: "...",
            }),
            "none",
            true
          )}{" "}
          {this.renderTagged(e.notes, "none", true)}{" "}
          <Button
            small
            minimal
            icon="duplicate"
            className="colfax"
            disabled={e.copied}
            onClick={() => {
              const newEntity = {
                ...e,
                _id: undefined,
                updatedAt: new Date().toISOString(),
                createdAt: new Date().toISOString(),
                visitId: this.props.currentVisitId,
                // provenance: "COPIED_FROM_HISTORY",
                sourceVisitId: e.visitId,
                edited: false,
                isShortForm: true,
              };
              e.copied = true;
              this.props.addVisitEntity(newEntity);
              this.forceUpdate();
              cogoToast.success(`Added to ${this.fixCasing(ss)}`, {
                position: "top-center",
                hideAfter: 2,
              });
            }}
          />
        </div>
      ));

      renderDivs.push(
        <ControlGroup vertical>
          <Tag intent="primary">{this.fixCasing(ss)}</Tag>
          <div>{renderEnts}</div>
        </ControlGroup>
      );
    }
    return renderDivs;
  };
  renderMetadata = (index) => {
    const obj = this.props.pastVisitData[index];
    return (
      <ControlGroup vertical>
        <Tag minimal>{niceDate(obj.registrationTime)} </Tag>
        <Tag minimal intent="primary">
          Doc: {obj.doctor}
        </Tag>
        <Tag minimal>{obj.branch}</Tag>
        <Tag minimal>{obj.purpose}</Tag>
        <Tag minimal>Optom: {obj.visionDoneBy || ""}</Tag>
        <Tag minimal>{obj._id}</Tag>
        <ControlGroup>
          <AnchorButton
            minimal
            icon="prescription"
            target="_blank"
            href={obj.rxPdfLink}
          />
          <AnchorButton
            minimal
            icon="eye-on"
            target="_blank"
            href={obj.glassRxPdfLink}
          />
        </ControlGroup>
      </ControlGroup>
    );
  };

  renderWorkUp = (index) => {
    const obj = this.props.pastVisitData[index];
    const cparams = this.sortClinicalParams(obj.cparams, masterVisitParameters);

    const renderDivs = [];
    for (let cp of cparams) {
      const renderTblRows = [];
      for (let p of cp.data) {
        if (p.value == null) continue;
        if (p.value === "") continue;
        const tblCells = [];
        if (p.laterality)
          tblCells.push(<td>{this.fixLaterality(p.laterality)}</td>);
        if (p.short) tblCells.push(<td>{p.short}</td>);
        if (p.value) tblCells.push(<td>{p.value}</td>);
        if (p.unit) tblCells.push(<td>{p.unit}</td>);
        if (tblCells.length === 0) continue;
        renderTblRows.push(<tr>{tblCells}</tr>);
      }
      if (renderTblRows.length === 0) continue;

      renderDivs.push(
        <ControlGroup vertical>
          <Tag intent="primary">
            {cp.short} {cp.subtypeShort ? `- ${cp.subtypeShort}` : ""}
          </Tag>
          <div>{renderTblRows}</div>
        </ControlGroup>
      );
    }

    return renderDivs;
  };

  renderHistory = (index) => {
    const obj = this.props.pastVisitData[index];
    const releventEnts = obj.entities.filter(
      (e) => e.sectionName === "HISTORY"
    );

    const sortedEnts = this.sortSubsectionEntities(
      "HISTORY",
      releventEnts,
      this.masterVisitEntities
    );

    // const renderEnts = sortedEnts.map((e) => (
    //   <div>
    //     {e.subsectionName} {e.entityName} {e.notes}
    //   </div>
    // ));
    const renderEnts = this.renderBySection(sortedEnts, []);

    return (
      <ControlGroup vertical>
        <div>{renderEnts}</div>
      </ControlGroup>
    );
  };

  renderExam = (index) => {
    const obj = this.props.pastVisitData[index];
    const releventEnts = obj.entities.filter(
      (e) => e.sectionName === "EXAMINATION"
    );

    const sortedEnts = this.sortSubsectionEntities(
      "EXAMINATION",
      releventEnts,
      this.masterVisitEntities
    );

    // const renderEnts = sortedEnts.map((e) => (
    //   <div>
    //     {e.subsectionName} {e.entityName} {e.notes}
    //   </div>
    // ));
    const renderEnts = this.renderBySection(sortedEnts, []);

    return (
      <ControlGroup vertical>
        <div>{renderEnts}</div>
      </ControlGroup>
    );

    // const obj = this.props.pastVisitData[index];
    // const histEnts = obj.entities
    //   .filter((e) => e.sectionName === "EXAMINATION")
    //   .map((e) => (
    //     <div>
    //       {e.subsectionName} {e.entityName} {e.notes}
    //     </div>
    //   ));

    // return (
    //   <ControlGroup vertical>
    //     <div>{histEnts}</div>
    //   </ControlGroup>
    // );
  };

  renderInvestigations = (index) => {
    const obj = this.props.pastVisitData[index];
    return (
      <ControlGroup vertical>
        <div>Investigations</div>
      </ControlGroup>
    );
  };

  renderPlan = (index) => {
    const obj = this.props.pastVisitData[index];
    const releventEnts = obj.entities.filter(
      (e) => e.sectionName === "PLAN" && e.subsectionName !== "SURGERY"
    );

    const sortedEnts = this.sortSubsectionEntities(
      "PLAN",
      releventEnts,
      this.masterVisitEntities
    );

    // const renderEnts = sortedEnts.map((e) => (
    //   <div>
    //     {e.subsectionName} {e.entityName} {e.notes}
    //   </div>
    // ));
    const renderEnts = this.renderBySection(sortedEnts, []);

    return (
      <ControlGroup vertical>
        <div>{renderEnts}</div>
      </ControlGroup>
    );

    // const obj = this.props.pastVisitData[index];
    // const histEnts = obj.entities
    //   .filter((e) => e.sectionName === "PLAN" && e.subsectionName !== "SURGERY")
    //   .map((e) => (
    //     <div>
    //       {e.subsectionName} {e.entityName} {e.notes}
    //     </div>
    //   ));

    // return (
    //   <ControlGroup vertical>
    //     <div>{histEnts}</div>
    //   </ControlGroup>
    // );
  };

  renderSxPlan = (index) => {
    const obj = this.props.pastVisitData[index];
    const releventEnts = obj.entities.filter(
      (e) => e.sectionName === "PLAN" && e.subsectionName === "SURGERY"
    );

    const sortedEnts = this.sortSubsectionEntities(
      "PLAN",
      releventEnts,
      this.masterVisitEntities
    );

    const renderEnts = this.renderBySection(sortedEnts, []);

    return renderEnts;

    // const renderEnts = sortedEnts.map((e) => (
    //   <div>
    //     {e.subsectionName} {e.entityName} {e.notes}
    //   </div>
    // ));

    // return (
    //   <ControlGroup vertical>
    //     <div>{renderEnts}</div>
    //   </ControlGroup>
    // );
    // const obj = this.props.pastVisitData[index];
    // const histEnts = obj.entities
    //   .filter((e) => e.sectionName === "PLAN" && e.subsectionName === "SURGERY")
    //   .map((e) => (
    //     <div>
    //       {e.subsectionName} {e.entityName} {e.notes}
    //     </div>
    //   ));

    // return (
    //   <ControlGroup vertical>
    //     <div>{histEnts}</div>
    //   </ControlGroup>
    // );
  };

  renderHistoryTableRows = () => {
    let pvd = this.props.pastVisitData;
    if (this.props.pastVisitDataOrder === "CHRONO") {
      pvd = _.reverse(pvd);
    }
    return this.props.pastVisitData.map((v, index) => (
      <tr style={{ width: "100%", overflow: "scroll" }}>
        <td>
          <ControlGroup vertical>{this.renderMetadata(index)}</ControlGroup>
        </td>
        <td>
          <ControlGroup vertical>{this.renderWorkUp(index)}</ControlGroup>
        </td>
        <td>
          <ControlGroup vertical>{this.renderHistory(index)}</ControlGroup>
        </td>
        <td>
          <ControlGroup vertical>{this.renderExam(index)}</ControlGroup>
        </td>
        {/* <td>
          <ControlGroup vertical>
            {this.renderInvestigations(index)}
          </ControlGroup>
        </td> */}
        <td>
          <ControlGroup vertical>{this.renderPlan(index)}</ControlGroup>
        </td>
        <td>
          <ControlGroup vertical>{this.renderSxPlan(index)}</ControlGroup>
        </td>
      </tr>
    ));
  };

  renderHistoryTable = () => {
    return (
      <table
        className="bp5-html-table bp5-html-table-condensed  bp5-html-table-striped bp5-html-table-bordered"
        style={{ width: "100%", overflow: "scroll" }}
      >
        <thead>
          <tr>
            <th>Metadata</th>
            <th>Workup</th>
            <th>History</th>
            <th>Exam</th>
            {/* <th>Investigations</th> */}
            <th>Plan</th>
            <th>Sx. Plan</th>
          </tr>
        </thead>
        <tbody style={{ width: "100%", overflow: "scroll" }}>
          {this.renderHistoryTableRows()}
        </tbody>
      </table>
    );
  };

  render() {
    return this.renderHistoryTable();
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(PastVisit));
