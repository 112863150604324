import React from "react";
import { ControlGroup, Tag, Button } from "@blueprintjs/core";
import MutEx from "../../utils/MutEx";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import { niceTime } from "../../fns/util";

class Dilation extends React.Component {
  dilatingAgents = [
    "Tropicacyl",
    "Tropicacyl + Phenylephrine",
    "Cyclopentolate",
    "Homatropine",
    "Atropine",
    "Do not dilate",
  ];
  dil = {
    dilated: false,
    dilatedWhen: new Date(),
    dilatedWith: "Tropicacyl",
    preventDilation: true,
    orderDilation: false,
    dilEyes: "BE",
  };
  render() {
    if (this.props.vision.preventDilation) {
      return (
        <div style={{ marginLeft: "5px" }}>
          <ControlGroup vertical>
            <Tag
              intent="danger"
              large
              interactive
              onClick={() => {
                this.props.updateVision("preventDilation", false);
              }}
            >
              Do not dilate
            </Tag>
          </ControlGroup>
        </div>
      );
    }
    if (this.props.vision.dilated) {
      return (
        <div style={{ marginLeft: "5px" }}>
          <ControlGroup vertical={this.props.vertical}>
            <Tag minimal intent="primary" large={!this.props.vertical}>
              Dilated: {this.props.vision.dilEyes}{" "}
              {this.props.vision.dilatedWith}
            </Tag>
            <Tag minimal>{niceTime(this.props.vision.dilatedWhen)}</Tag>
          </ControlGroup>
        </div>
      );
    }
    return (
      <div style={{ marginLeft: "5px" }}>
        <ControlGroup vertical={this.props.vertical}>
          <Tag minimal large={!this.props.vertical}>
            Dilate
          </Tag>
          <MutEx
            options={["RE", "BE", "LE"]}
            selected={this.props.vision.dilEyes}
            onUpdate={(item) => this.props.updateVision("dilEyes", item)}
          />
          <SingleSelectWrapper
            items={this.dilatingAgents}
            selectedItem={this.props.vision.dilatedWith}
            onChange={(item) => {
              this.props.updateVision("dilatedWith", item);
            }}
          />
          <Button
            minimal
            icon="small-tick"
            onClick={() => {
              if (!this.props.vision.dilatedWith) {
                return;
              }
              if (!this.props.vision.dilEyes) {
                return;
              }
              this.props.updateVision("dilated", true);
              this.props.updateVision("dilatedWhen", new Date());
            }}
          />
        </ControlGroup>
      </div>
    );
  }
}

export default Dilation;
