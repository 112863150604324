import { observable, decorate } from "mobx";
import { persist } from "mobx-persist";

export class AuthStore {
  isLoggedIn = null;
  prefix = "";
  scope = [];
  name = null;
  sudoCanSwitchTo = [];
  supportLegacyPid = false;
  rxClinicalOptions = {};
  supportAddlSearch = false;
}

decorate(AuthStore, {
  name: [persist, observable],
  isLoggedIn: [persist, observable],
  prefix: [persist, observable],
  scope: [persist("list"), observable],
  sudoCanSwitchTo: [persist("list"), observable],
  supportLegacyPid: [persist, observable],
  rxClinicalOptions: [persist("object"), observable],
  supportAddlSearch: [persist, observable],
});
export default new AuthStore();
