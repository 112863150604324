import { Button, Collapse } from "@blueprintjs/core";
import React from "react";

class CodeRender extends React.Component {
  state = {
    isOpen: false,
  };
  render() {
    return (
      <div>
        <Button
          small
          minimal
          className="colfax"
          icon="code"
          text={!this.state.isOpen ? "Show Code" : "Hide Code"}
          onClick={() =>
            this.setState({
              isOpen: !this.state.isOpen,
            })
          }
        />
        <Collapse isOpen={this.state.isOpen}>
          <pre
            style={{
              backgroundColor: "#26292c",
              fontFamily: "monospace",
              whiteSpace: "pre-wrap",
              fontSize: "small",
            }}
          >
            {this.props.text}
          </pre>
        </Collapse>
      </div>
    );
  }
}

export default CodeRender;
