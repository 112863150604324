import { create } from "mobx-persist";
import bookingStore from "./bookingStore";
import authStore from "./authStore";
import billingStore from "./billingStore";
import patientStore from "./patientStore";
import listStore from "./listStore";

const hydrate = create({
  storage: localStorage,
});

class RootStore {
  bookingStore = bookingStore;
  authStore = authStore;
  billingStore = billingStore;
  patientStore = patientStore;
  listStore = listStore;
  isHydrationComplete = false;
  constructor() {
    Promise.all([
      hydrate("authStore", this.authStore),
      hydrate("bookingStore", this.bookingStore),
      hydrate("billingStore", this.billingStore),
      hydrate("patientStore", this.patientStore),
      hydrate("listStore", this.listStore),
    ])
      .then(() => {
        this.setHydration(true);
        console.log("Application Hydrated.");
      })
      .catch((err) => {
        this.setHydration(false);
        console.log(err);
      });
  }
  getHydration() {
    return this.isHydrationComplete;
  }
  setHydration(x) {
    this.isHydrationComplete = x;
  }
}

export default new RootStore();
