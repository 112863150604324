import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@blueprintjs/core";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      onClick={() => {
        localStorage.clear();
        loginWithRedirect();
      }}
      minimal
      large
      icon="log-in"
      intent="success"
    >
      Log In
    </Button>
  );
};

export default LoginButton;
