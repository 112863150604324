import { H3, Tag } from "@blueprintjs/core";
import React from "react";
import SuggColl from "../SuggColl";
import SingleSearch from "./SingleSearch";

class AllergyPanel extends React.Component {
  renderTitle = () => {
    return <H3>Does the patient have any known allergies?</H3>;
  };
  renderCG = () => {
    return (
      <SingleSearch
        prefix="all"
        whichArray="allergies"
        add={this.props.add}
        placeholder="Allergies"
      />
    );
  };

  renderEachAllergy = (data) => {
    return (
      <Tag
        intent="danger"
        large
        onRemove={() => {
          this.props.removeArray("allergies", data._id);
        }}
      >
        {data.allergy}
      </Tag>
    );
  };

  renderAllergies = () => {
    if (!this.props.allergies || !this.props.allergies.length) {
      return (
        <Tag
          minimal
          icon="virus"
          large
          interactive
          onClick={() => this.props.onSearch("all", "allergies")}
        >
          No Allergies Recorded
        </Tag>
      );
    }
    return this.props.allergies.map((each) => {
      return this.renderEachAllergy(each);
    });
  };

  render() {
    return (
      <div style={{ padding: "20px", height: "100%" }}>
        {this.renderTitle()}
        <br />
        <SuggColl
          ana_prefix=""
          prefix="all"
          visitId={this.props.visitId}
          addObjectInside={this.props.add}
        />
        {this.renderCG()}
        <br />
        {this.renderAllergies()}
      </div>
    );
  }
}

export default AllergyPanel;
