import React from "react";
import { Button } from "@blueprintjs/core";

class MutEx extends React.Component {
  renderButtons = () => {
    let list = this.props.options;
    return list.map((each) => {
      let intent = "none";
      if (this.props.selected === each) {
        intent = "success";
      }
      return (
        <Button
          large={this.props.large}
          text={each}
          intent={intent}
          outlined={this.props.minimal}
          onClick={() => this.props.onUpdate(each)}
        />
      );
    });
  };
  render() {
    return <div>{this.renderButtons()}</div>;
  }
}

export default MutEx;
