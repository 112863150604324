import {
  Button,
  ControlGroup,
  InputGroup,
  NumericInput,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import { dateFromNow, isInFutureDate, niceDate } from "../fns/util";

class DateEntry extends React.Component {
  state = {
    calcMode: false,
    date: null,
    month: null,
    year: null,
  };
  componentDidMount() {
    if (this.props.date) {
      let d = new Date(this.props.date).getDate();
      let m = new Date(this.props.date).getMonth() + 1;
      let y = new Date(this.props.date).getFullYear();
      this.setState({ date: d, month: m, year: y });
    } else {
      let d = new Date().getDate();
      let m = new Date().getMonth() + 1;
      this.setState({ date: d, month: m });
    }
  }
  renderDMY = () => {
    return (
      <ControlGroup>
        <Tag icon="calendar" minimal></Tag>
        <NumericInput
          style={{ width: "4em" }}
          placeholder="12"
          buttonPosition="none"
          onValueChange={(n) => this.setState({ date: n })}
          max="31"
          min="1"
          clampValueOnBlur
        />
        <NumericInput
          style={{ width: "4em" }}
          placeholder="11"
          buttonPosition="none"
          max="12"
          min="1"
          clampValueOnBlur
          onValueChange={(n) => this.setState({ month: n })}
        />
        {this.yearInput()}
      </ControlGroup>
    );
  };
  renderDateControl = () => {
    let d = new Date(this.state.year, this.state.month - 1, this.state.date);
    let intent = "primary";
    if (isInFutureDate(d)) {
      intent = "danger";
    } else if (this.state.year < 1910) {
      intent = "danger";
    }
    return (
      <ControlGroup>
        <Tag minimal>Born on</Tag>
        <Tag minimal intent={intent}>
          {niceDate(d)}
        </Tag>
        <Tag minimal>ie</Tag>
        <Tag minimal intent={intent}>
          {dateFromNow(d)}
        </Tag>
      </ControlGroup>
    );
  };
  yearInput = () => {
    return (
      <InputGroup
        style={{
          width: "6em",
          backgroundColor: `${this.state.calcMode ? "#394B59" : "#17191a"}`,
        }}
        placeholder="-75 | 1973"
        onChange={(event) => {
          let e = event.target.value;
          if (e.charAt(0) === "-") {
            if (!this.state.calcMode) {
              this.setState({ calcMode: true });
            }
            e = parseInt(e.substring(1));
            let d = new Date().getFullYear();
            this.setState({ year: d - e });
          } else {
            if (this.state.calcMode) {
              this.setState({ calcMode: false });
            }
            this.setState({ year: e });
          }
        }}
      />
    );
  };
  sendDate = () => {
    let d = new Date(this.state.year, this.state.month - 1, this.state.date);
    this.props.onUpdate(d);
  };
  render() {
    return (
      <div>
        <ControlGroup>
          {" "}
          <ControlGroup vertical>
            {this.renderDMY()}
            {this.renderDateControl()}
          </ControlGroup>
          <Button
            minimal
            icon="endorsed"
            intent="success"
            disabled={!this.state.year}
            onClick={() => this.sendDate()}
          />
        </ControlGroup>
      </div>
    );
  }
}

export default DateEntry;
