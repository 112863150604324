import { Collapse, Tag } from "@blueprintjs/core";
import React from "react";
import OCTVert from "./OCTVert";
import _ from "lodash";

class OCTHori extends React.Component {
  state = {
    isOpen: true,
  };
  getSubParam = (data, param, partial = false) => {
    if (partial) {
      return _.filter(data, (each) => each.key.includes(param));
    }
    return _.find(data, (each) => each.key === param);
  };
  renderEachTypeMeasure = (inp) => {
    let data = inp[0].subMeasurements;
    let regions = ["parafovea", "perifovea", "rnfl"];
    let axes = ["thickness", "volume", "height"];
    let measuresOfType = [];
    _.forEach(regions, (eachRegion) => {
      _.forEach(axes, (eachAxis) => {
        let str = eachRegion + "_" + eachAxis;
        let obj = {};
        let avgCaseStr = str + "_average";
        obj["average"] = this.getSubParam(data, avgCaseStr, false);
        obj["others"] = this.getSubParam(data, str, true);
        _.remove(obj["others"], (each) => each.key.includes("average"));
        _.remove(obj["others"], (each) =>
          each.key.includes("rnfl_thickness_section")
        );
        if (!_.isEmpty(obj["average"])) {
          measuresOfType.push(obj);
        }
      });
    });
    return (
      <div style={{ display: "flex", flexWrap: "wrap", overflow: "scroll" }}>
        {measuresOfType.map((each) => {
          return (
            <div style={{ marginRight: "5px" }}>
              <OCTVert data={each} />
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    return (
      <div style={{ marginTop: "5px" }}>
        <Tag
          large
          intent="primary"
          interactive
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          {this.props.head}
        </Tag>
        <Collapse isOpen={this.state.isOpen}>
          {this.renderEachTypeMeasure(this.props.data)}
        </Collapse>
      </div>
    );
  }
}

export default OCTHori;
