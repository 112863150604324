import React from "react";
import _ from "lodash";
import EachUserRole from "./EachUserRole";

class UserRoleBox extends React.Component {
  state = {};

  roleMaps = {
    AR: {
      keyName: "autoRefcnDone",
      niceName: "Initial Assessment Time",
      eventName: "AR",
    },
    NCT: { keyName: "nctDone", niceName: "NCT", eventName: "NCT" },
    AT: { keyName: "atDone", niceName: "AT", eventName: "AT" },
    VISION_DONE: {
      keyName: "visionDone",
      niceName: "Vision",
      eventName: "VISION_DONE",
    },
    CONSULTATION_START: {
      keyName: "startDone",
      niceName: "Entry into Doctor's Chamber",
      eventName: "CONSULTATION_START",
    },
    CONSULTATION: {
      keyName: "done",
      niceName: "Exit from Doctor's Chamber",
      eventName: "CONSULTATION",
    },
    DATA_ENTRY: {
      keyName: "dataEntryDone",
      niceName: "Data Entry",
      eventName: "DATA_ENTRY",
    },
    COUNSELLING_DONE: {
      keyName: "counselledDone",
      niceName: "Counselling",
      eventName: "COUNSELLING_DONE",
    },
    AUX_COUNSELLING_DONE: {
      keyName: "auxCounselledDone",
      niceName: "Addl. Counselling",
      eventName: "AUX_COUNSELLING_DONE",
    },

    SX_COUNSELLING_DONE: {
      keyName: "sxCounselledDone",
      niceName: "Sx Counselling",
      eventName: "SX_COUNSELLING_DONE",
    },
  };
  renderRoles = () => {
    let keys = _.keys(this.roleMaps);
    let visitData = this.props.visitData;
    return keys.map((each) => {
      let whichRole = this.roleMaps[each];
      return (
        <EachUserRole
          whichRole={whichRole}
          visitData={visitData}
          onUpdateVisitData={(p) => this.props.onUpdateVisitData(p)}
        />
      );
    });
  };
  render() {
    let visitData = this.props.visitData;
    return (
      <div
        style={{ margin: "20px", overflow: "auto" }}
        key={`${visitData._id}__${visitData.updatedAt}`}
      >
        <table>
          <thead>
            <tr>
              <th>Activity</th>
              <th>User</th>
              <th>Time</th>
              <th> </th>
            </tr>
          </thead>
          <tbody> {this.renderRoles()}</tbody>
        </table>
      </div>
    );
  }
}
export default UserRoleBox;
