import { observable, decorate } from "mobx";
import { persist } from "mobx-persist";

class SingleVisit {
  AdmitDate = null;
  BranchName = null;
  ClinicName = null;
  ConsultantName = null;
  DocketNo = null;
  FileUrl = null;
  GlassFileUrl = null;
  OPDID = null;
  Status = null;
  SubClinic = null;
  Type = null;
  latest = null;
}

decorate(SingleVisit, {
  AdmitDate: [persist, observable],
  BranchName: [persist, observable],
  ClinicName: [persist, observable],
  ConsultantName: [persist, observable],
  DocketNo: [persist, observable],
  FileUrl: [persist, observable],
  GlassFileUrl: [persist, observable],
  OPDID: [persist, observable],
  Status: [persist, observable],
  SubClinic: [persist, observable],
  Type: [persist, observable],
  latest: [persist, observable],
});

class ArrayOfVisitsSingleDay {
  visits = [new SingleVisit()];
}

class ClinicalTouchPoint {
  createdAt = null;
  doneBy = null;
  doneById = null;
  onDay = null;
  patient = null;
  ts = null;
  type = null;
  updatedAt = null;
  __v = null;
  _id = null;
}

decorate(ClinicalTouchPoint, {
  createdAt: [persist, observable],
  doneBy: [persist, observable],
  doneById: [persist, observable],
  onDay: [persist, observable],
  patient: [persist, observable],
  ts: [persist, observable],
  type: [persist, observable],
  updatedAt: [persist, observable],
  __v: [persist, observable],
  _id: [persist, observable],
});

class CTPArray {
  tps = [new ClinicalTouchPoint()];
}

decorate(CTPArray, {
  tps: [persist("list"), observable],
});

decorate(ArrayOfVisitsSingleDay, {
  visits: [persist("list"), observable],
});

class ArrayOfVisitsObject {
  object = { date: null, events: new ArrayOfVisitsSingleDay() };
}
decorate(ArrayOfVisitsObject, {
  arrayOfVisits: [persist("object"), observable],
});
class ArchiveOfVisitsObject {
  object = { genericPid: null, visits: new ArrayOfVisitsObject() };
}

decorate(ArchiveOfVisitsObject, {
  archiveOfVisits: [persist("object"), observable],
});

export class PatientStore {
  patientName = null;
  patientPhone = null;
  genericPid = null;
  patientAge = null;
  patientSex = null;
  patientEmail = null;
  patientDob = null;
  clinicalRecordToday = null;
  hasVisitCompletedToday = null;
  /** TO DO */
  patientAddress = null;
  patientPreviousVisits = new ArrayOfVisitsObject();
  archive = [new ArchiveOfVisitsObject()];
  panels = [];
  tps = [new ClinicalTouchPoint()];
  glassRxLink = null;
  rxLink = null;
  visitDone = null;
  addlSearchTerm = null;
}

decorate(PatientStore, {
  patientName: [persist, observable],
  patientPhone: [persist, observable],
  genericPid: [persist, observable],
  patientAge: [persist, observable],
  patientSex: [persist, observable],
  patientEmail: [persist, observable],
  patientDob: [persist, observable],
  glassRxLink: [persist, observable],
  rxLink: [persist, observable],
  patientAddress: [persist, observable],
  addlSearchTerm: [persist, observable],
  patientPreviousVisits: [persist("object"), observable],
  archive: [persist("list"), observable],
  panels: [persist("list"), observable],
  tps: [persist("list", observable)],
  visitDone: [persist, observable],
});
export default new PatientStore();
