import { Button, ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import Finding from "../../elems/Finding";
import SingleSearch from "../../history-wizard/SingleSearch";
import InvImage from "../InvImage";

class DFPCurrent extends React.Component {
  state = {
    showGuide: false,
  };
  renderInfoBar = () => {
    return (
      <ControlGroup>
        <Button
          minimal
          icon="chevron-left"
          onClick={() => this.props.onLeft()}
        />
        <Tag minimal>Image</Tag>
        <Tag minimal intent="primary">
          {this.props.data.sliceIndex + 1}
        </Tag>
        <Tag minimal>of {this.props.length}</Tag>
        <Tag
          intent={this.props.data.selectForPrint ? "success" : "warning"}
          minimal
          interactive
          onClick={() => this.props.selectForPrint()}
        >
          {this.props.data.selectForPrint ? "Selected" : "Not Selected"}
        </Tag>
        <Button
          minimal
          icon="chevron-right"
          onClick={() => this.props.onRight()}
        />
      </ControlGroup>
    );
  };

  renderSearch = () => {
    return (
      <SingleSearch
        investigationType={this.props.invType}
        placeholder="DFP Observation"
        add={(item) => {
          this.props.addFinding(item);
        }}
      />
    );
  };
  renderFindings = () => {
    if (this.props.humanFindings.length) {
      let hf = this.props.humanFindings;
      return hf.map((each) => {
        let obj = each;
        obj["type"] = "new";
        obj["qualifier"] = each.valueQualifiers;
        obj["item"] = each.description;
        obj["listOfQualifiers"] = each.listOfQualifiers;
        return (
          <Finding
            data={obj}
            duplicate={() => {}}
            remove={this.props.remove}
            editObjectInside={this.props.editObjectInside}
          />
        );
      });
    }
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {this.renderInfoBar()}
          <InvImage src={this.props.data} selector={false} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        {this.props.data.selectForPrint ? (
          <div>
            <ControlGroup style={{ marginBottom: "5px" }}>
              <ControlGroup>
                {" "}
                <Tag large intent="primary">
                  Findings
                </Tag>
              </ControlGroup>

              {this.renderSearch()}
            </ControlGroup>

            {this.renderFindings()}
          </div>
        ) : null}
      </div>
    );
  }
}

export default DFPCurrent;
