import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import { apiCallPost } from "../../fns/util";
import AsyncSelWrap from "../../utils/AsyncSelWrap";
import { inject, observer } from "mobx-react";
import cogoToast from "cogo-toast";
import { _ } from "ag-grid-community";

class SingleSearch extends React.Component {
  state = {
    searching: false,
    searchResults: [],
  };

  searchInv = async (string) => {
    if (!string) {
      this.setState({ searchResults: [] });
      return;
    }
    try {
      this.setState({ searching: true });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/searchApplicableFindings`,
        { investigationType: this.props.investigationType, query: string }
      );
      _.forEach(res, (each) => {
        each["entityTypeString"] = this.props.placeholder;
      });
      this.setState({ searchResults: res, searching: false });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  search = async (string) => {
    if (this.props.investigationType) {
      this.searchInv(string);
      return;
    }
    if (!string) {
      this.setState({ searchResults: [] });
      return;
    }
    try {
      this.setState({ searching: true });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/unisearch`,
        { prefix: this.props.prefix, query: string }
      );
      this.setState({ searchResults: res, searching: false });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  render() {
    return (
      <ControlGroup>
        <Tag large minimal>
          Search
        </Tag>
        <AsyncSelWrap
          placeholder={`Search ${this.props.placeholder}...`}
          searching={this.state.searching}
          items={this.state.searchResults}
          selectedItem={null}
          update={(item) => this.search(item)}
          onChange={(item) => {
            if (this.props.investigationType) {
              this.props.add(item);
            } else {
              let dd = item.data;
              dd["_id_sr"] = item._id;
              dd["type"] = "new";
              dd["checked"] = false;
              this.props.add(this.props.whichArray, dd);
            }
          }}
        />
      </ControlGroup>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(SingleSearch));
