import { H3, Tag } from "@blueprintjs/core";
import React from "react";
import SysSx from "../elems/SysSx";
import SuggColl from "../SuggColl";
import SingleSearch from "./SingleSearch";

class SysSxPanel extends React.Component {
  renderTitle = () => {
    return <H3>Has the patient undergone systemic surgeries or procedures?</H3>;
  };

  renderCG = () => {
    return (
      <SingleSearch
        prefix="sys-sx"
        whichArray="sysSx"
        add={this.props.add}
        placeholder="Systemic Sx"
      />
    );
  };

  renderPrevEyeDx = () => {
    if (!this.props.sysSx || !this.props.sysSx.length) {
      return (
        <Tag minimal large>
          No previous eye diseases added.
        </Tag>
      );
    }
    return this.props.sysSx.map((each) => {
      return (
        <SysSx
          data={each}
          onUpdate={(key, value) =>
            this.props.update("sysSx", each._id, key, value)
          }
          onRemove={() => this.props.removeArray("sysSx", each._id)}
        />
      );
    });
  };
  render() {
    return (
      <div style={{ padding: "20px", height: "100%" }}>
        {this.renderTitle()}
        <br />
        <SuggColl
          ana_prefix=""
          prefix="sys-sx"
          visitId={this.props.visitId}
          addObjectInside={this.props.add}
        />
        {this.renderCG()}
        <br />
        {this.renderPrevEyeDx()}
      </div>
    );
  }
}

export default SysSxPanel;
