import React from "react";
import Subjective from "./elems/Subjective";
import cogoToast from "cogo-toast";
import {
  Alert,
  AnchorButton,
  Button,
  ControlGroup,
  NonIdealState,
  Spinner,
  Tag,
  Tab,
  Tabs,
  Card,
  Elevation,
} from "@blueprintjs/core";
import Hotkeys from "react-hot-keys";
import _ from "lodash";
import Objective from "./elems/Objective";
import Analysis from "./elems/Analysis";
import PlanMedicines from "./elems/PlanMedicines";
import PlanAdvice from "./elems/PlanAdvice";
import PlanSurgery from "./elems/PlanSurgery";
import PlanFollowUp from "./elems/PlanFollowUp";
import Investigation from "./elems/Investigation";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import { apiCallPost, initials, fixUrlPrefix, isDateToday } from "../fns/util";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";

import SyntaxTable from "./elems/SyntaxTable";
import Omni from "../utils/Omni";
import PrefixTable from "./elems/PrefixTable";
import HistoryDrop from "./HistoryDrop";
import HistoryWizard from "./history-wizard/HistoryWizard";
import VisionWizard from "./vision-wizard/VisionWizard";

import Sex from "./elems/Sex";
import Age from "./elems/Age";
import VisionSection from "./elems/VisionSection";

import Context from "./elems/Context";
import ObsWizard from "./obs-wizard/ObsWizard";
import InvDrawer from "./inv/InvDrawer";

import DailyIframe from "@daily-co/daily-js";
import DetectRTC from "detectrtc";
import MutEx from "../utils/MutEx";
import PlanSysInv from "./elems/PlanSysInv";
import { nanoid } from "nanoid";
import TemplateDrawer from "./templates/TemplateDrawer";

class Clinical extends React.Component {
  state = {
    landingId: null,
    dailyCoToken: null,
    videoCanStart: false,
    invLinkDisable: false,
    layout: true,
    makeVisitComplimentary: false,
    editingFunctional: false,
    onAddInvFromSxList: [],
    docList: [],
    showTable: false,
    showPrefix: false,
    showPast: false,
    showInv: false,
    showTemplateDrawer: false,
    historyWizardOpen: false,
    visionWizardOpen: false,
    obsWizardOpen: false,
    flagObject: 0,
    omniOpen: false,
    searching: false,
    searchResults: [],
    items: [],
    tempInv: {},
    moveInvProc: false,
    hasVisitToday: true,
    patientName: "",
    genericPid: "",
    patientId: "",
    sex: "",
    dob: new Date(),
    patientAddress: "",
    colorVision: "",
    allergies: [],
    sysEyeSx: [],
    sysSx: [],
    sysDx: [],
    famHist: [],
    prevEyeDx: [],
    quickActions: [],
    pastVisits: [],
    geocode: {},
    parts: ["Lids and Adnexa", "Anterior Segment", "Posterior Segment"],
    functionalEyes: "",
    todayVisit: {
      doctorId: "",
      _id: "",
      site: "",
      pdfUrl: "",
      purpose: "",
      registrationTime: null,
      areSectionsNormal: [],
      vision: {
        _id: "",
        cct: {
          re: "",
          le: "",
        },
        contrast: {
          le: "",
          re: "",
        },
        schirmer: {
          le: "",
          re: "",
        },
        worth: "",
        uncorrected: {
          le: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
          re: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
        },
        pgp: {
          le: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
          re: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
        },
        corrected_dry: {
          le: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
          re: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
        },
        corrected_dilated: {
          le: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
          re: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
        },
        corrected_pmt: {
          le: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
          re: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
        },
        corrected_rx: {
          le: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
          re: {
            v_dist: "",
            v_ph: "",
            v_far: "",
            sph: "",
            cyl: "",
            axis: "",
            add: "",
          },
        },
        at: {
          le: "",
          re: "",
        },
        nct: {
          le: "",
          re: "",
        },
        hasUncorrected: false,
        hasPgp: true,
        has: false,
        hasCorrectedDry: false,
        hasCorrectedDil: false,
        hasCorrectedPmt: false,
        hasCorrectedRx: false,
        glassAdvice: "",
        glassesAge: "",
        dilated: false,
        dilatedWhen: null,
        dilatedWith: "",
        preventDilation: false,
        orderDilation: false,
        visionDone: false,
        pupillaryReaction: "",
        notRecordedReason: [],
      },
      subjectiveNotes: [
        {
          _id: "",
          text: "",
          lastEditedAt: null,
        },
      ],
      findingNotes: [
        {
          _id: "",
          text: "",
          lastEditedAt: null,
        },
      ],
      dxNotes: [
        {
          _id: "",
          text: "",
          lastEditedAt: null,
        },
      ],
      symptoms: [],
      prev_medicines: [],
      findings: {
        "Extraocular Muscles": [],
        "Lids and Adnexa": [],
        Conjunctiva: [],
        Sclera: [],
        Cornea: [],
        "Anterior Chamber": [],
        Iris: [],
        Lens: [],
        Vitreous: [],
        Retina: [],
        Disc: [],
      },
      eyeDx: [],
      medicines: [],
      sysinv: [],
      advNotes: [
        {
          _id: "",
          text: "",
          lastEditedAt: null,
        },
      ],
      advice: [],
      plannedEyeSx: [],
      procedures: [],
      followup: [
        {
          _id: "",
          refer: null,
          referTo: null,
          referralNote: "",
          when: "",
        },
      ],
      followupWith: [],
      investigations: [],
    },
  };
  rtcDetails = null;
  canStart = true;
  listOfPurpose = [
    "Emergency Visit",
    "First Visit",
    "Follow-Up",
    "Internal Referral",
    "External Referral",
    "Pre-Operative Visit",
    "Post-Operative Visit",
  ];
  videoEvents = [
    "joining-meeting",
    "joined-meeting",
    "left-meeting",
    "error",
    "camera-error",
    "started-camera",
    "participant-joined",
    "participant-updated",
    "participant-left",
    "network-connection",
    "network-quality-change",
    "app-message",
    "fullscreen",
    "exited-fullscreen",
  ];
  componentDidMount() {
    this.setState(
      {
        waiting: true,
        genericPid: this.props.match.params.patientId,
        visitIdToLoad: this.props.match.params.visitId,
      },
      () => {
        this.getLanding();
        this.getDailyCoToken();
      }
    );
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.patientId !== this.props.match.params.patientId
    ) {
      this.setState(
        {
          waiting: true,
          genericPid: this.props.match.params.patientId,
          visitIdToLoad: this.props.match.params.visitId,
        },
        () => {
          this.getLanding();
          this.getDailyCoToken();
        }
      );
    }
    if (prevProps.match.params.visitId !== this.props.match.params.visitId) {
      this.setState(
        {
          waiting: true,
          genericPid: this.props.match.params.patientId,
          visitIdToLoad: this.props.match.params.visitId,
        },
        () => {
          this.getLanding();
          this.getDailyCoToken();
        }
      );
    }
  }
  getPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };
  checkBrowserCompatibility = async () => {
    DetectRTC.load(function () {});
    this.rtcDetails = DetectRTC;

    if (DetectRTC.osName === "Android") {
      if (DetectRTC.browser.name !== "Chrome") {
        cogoToast.error(
          "We only support the browser Chrome on Android. Please login again from Chrome. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (DetectRTC.osName === "iOS") {
      if (DetectRTC.browser.name !== "Safari") {
        cogoToast.error(
          "We only support the browser Safari on iOS. Please login again from Safari. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (DetectRTC.osName.includes("Windows")) {
      if (DetectRTC.browser.name !== "Chrome") {
        cogoToast.error(
          "We only support the browser Chrome on Windows. Please login again from Chrome. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (DetectRTC.osName === "Mac OS X") {
      if (
        DetectRTC.browser.name !== "Safari" &&
        DetectRTC.browser.name !== "Chrome"
      ) {
        cogoToast.error(
          "We only support the browsers Safari and Chrome on Mac OS X. Please login again from Safari or Chrome. If you think this is an error, contact support."
        );
        return false;
      }
    }

    if (
      !DetectRTC.isWebsiteHasMicrophonePermissions ||
      !DetectRTC.isWebsiteHasWebcamPermissions
    ) {
      const havePermission = await this.getPermission();

      if (havePermission) {
        return true;
      } else {
        cogoToast.error(
          "You have either denied permission to the camera or the microphone. Please check your browser settings and try again."
        );
        return false;
      }
    }

    if (
      DetectRTC.hasMicrophone &&
      DetectRTC.hasSpeakers &&
      DetectRTC.hasWebcam
    ) {
      return true;
    } else {
      cogoToast.error(
        "We could not detect your microphone, speaker or webcam."
      );
    }
  };
  getDailyCoToken = async () => {
    try {
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/getDailyCoToken`,
        { genericPid: this.state.genericPid }
      );
      this.setState({
        dailyCoToken: res,
      });
    } catch (err) {
      cogoToast.error(
        "There was an error in getting video call token. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  attemptVideoCall = async () => {
    const havePermission = await this.checkBrowserCompatibility();
    if (!havePermission) {
      cogoToast.error(
        "There was an error in starting the call. Please contact support."
      );
      return;
    } else {
      this.setState({ videoCanStart: true, layout: false });
    }
  };

  preventPast = () => {
    let dt = new Date(this.state.todayVisit.onDay);
    return isDateToday(dt);
  };
  getQuickActions = async (num, pre) => {
    try {
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/unisearch-freq`,
        {
          prefix: pre,
          ana_prefix: "",
          maxResults: num,
        }
      );

      _.forEach(res, (each) => {
        each.text = each._id;
        each.prefix = pre;
      });
      if (pre === "adv") {
        _.forEach(res, (each) => {
          each.icon = "lifesaver";
        });
      }
      if (pre === "dx") {
        _.forEach(res, (each) => {
          each.icon = "diagnosis";
        });
      }
      if (pre === "med") {
        _.forEach(res, (each) => {
          each.icon = "new-prescription";
        });
      }
      if (pre === "sign") {
        _.forEach(res, (each) => {
          each.icon = "new-grid-item";
        });
      }
      if (pre === "sym") {
        _.forEach(res, (each) => {
          each.icon = "new-grid-item";
        });
      }
      let qq = this.state.quickActions;
      qq = _.concat(qq, res);
      this.setState({ quickActions: qq });
    } catch (err) {
      cogoToast.error("Failed to load quick action items", {
        position: "bottom-right",
      });
      console.log(err);
    }
  };
  getDocs = async () => {
    try {
      let resDoc = await apiCallPost(
        `/rai/${this.props.authStore.prefix}/getAllConsultants`,
        {}
      );
      let doctorList = [];
      _.map(resDoc, (each) => doctorList.push(each.name));

      let refOptions = [
        "Cardiologist",
        "Neurologist",
        "Orthopedician",
        "Rheumatologist",
        "Dermatologist",
        "Diabetologist",
        "Physician",
        "Pediatrician",
        "ENT Specialist",
        "Otolaryngologist",
        "Glaucoma Specialist",
        "Cataract Surgeon",
        "Refractive Surgeon",
        "Cornea Specialist",
        "Retina Specialist",
        "Oculoplasty Surgeon",
        "Pediatric Ophthalmologist",
        "Neuro-Ophthalmologist",
        "Uveitis Specialist",
      ];
      let qq = _.concat(refOptions, doctorList);
      this.setState({ docList: qq }, () => this.getPrefs());
    } catch (err) {
      cogoToast.error("Failed to load doctor list", {
        position: "bottom-right",
      });
      console.log(err);
    }
  };
  getPrefs = async () => {
    try {
      cogoToast.loading("Loading ...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/users/${this.props.authStore.prefix}/getMyPrefs`,
        {}
      );
      this.setState({
        layout: res["ui_scroll"].value === "tabs",
      });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating state. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  getLanding = async () => {
    try {
      cogoToast.loading("Loading ...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/landing`,
        {
          genericPid: this.state.genericPid,
          visitId: this.state.visitIdToLoad,
        }
      );
      this.setState(
        {
          geocode: res.geocode,
          pastVisits: res.pastVisits,
          todayVisit: res.todayVisit,
          patientAddress: res.address,
          allergies: res.allergies,
          famHist: res.famHist,
          colorVision: res.colorVision,
          dob: res.dob,
          patientName: res.name,
          sex: res.sex,
          sysEyeSx: res.sysEyeSx,
          sysDx: res.sysDx,
          sysSx: res.sysSx,
          patientId: res._id,
          waiting: false,
          quickActions: [],
          prevEyeDx: res.prevEyeDx,
          functionalEyes: res.functionalEyes,
        },
        () => {
          this.getDocs();
          this.getInvProc();
          this.updatePartsOfFindings();
        }
      );
    } catch (err) {
      cogoToast.error(
        "There was an error in updating state. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  editVisitProps = async (key, value) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    try {
      cogoToast.loading("Updating " + key, { position: "bottom-left" });

      await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/visitlevel/props/edit`,
        {
          patientId: this.state.patientId,
          visitId: this.state.todayVisit._id,
          key: key,
          value: value,
        }
      );
      let tt = this.state.todayVisit;
      tt[key] = value;
      this.setState({ todayVisit: tt });
      cogoToast.success("Updated " + key, { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating state. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  addTopLevelArray = async (whichArray, addToArray, whichKey = "") => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      //   return;
    }
    try {
      if (whichArray === "findings") {
        let mm = [
          ["Lids and Adnexa", "Extraocular Muscles"],
          [
            "Conjunctiva",
            "Sclera",
            "Cornea",
            "Anterior Chamber",
            "Iris",
            "Lens",
          ],
          ["Vitreous", "Retina", "Disc"],
        ];
        let whichPart = "";
        if (mm[0].includes(whichKey)) {
          whichPart = "Lids and Adnexa";
        } else if (mm[1].includes(whichKey)) {
          whichPart = "Anterior Segment";
        } else if (mm[2].includes(whichKey)) {
          whichPart = "Posterior Segment";
        }
        if (this.state.todayVisit.areSectionsNormal.includes(whichPart)) {
          cogoToast.error(
            "You have already marked " + whichPart + " as normal."
          );
          return;
        } else {
          let tt = this.state.parts;
          _.remove(tt, (each) => each === whichPart);
          this.setState({ parts: tt });
        }
      }

      cogoToast.loading("Updating " + whichArray, { position: "bottom-left" });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/visitlevel/array/add`,
        {
          patientId: this.state.patientId,
          visitId: this.state.todayVisit._id,
          array: whichArray,
          props: addToArray,
        }
      );
      let tt = this.state.todayVisit;
      let path = [];
      path.push(whichArray);
      if (whichKey) {
        path.push(whichKey);
      }
      let kk = _.get(tt, path);
      kk.push(res);
      _.set(tt, path, kk);
      this.setState({ todayVisit: tt, flagObject: this.state.flagObject + 1 });
      cogoToast.success("Updated " + whichArray, { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating state. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  onBulkAdd = async (obj, eye) => {
    cogoToast.info("Starting addition from template...");
    let keys = _.keys(obj);
    let promises = [];
    for (var i = 0; i < keys.length; i++) {
      let currentArray = keys[i];
      let currentItems = obj[currentArray];

      if (currentArray === "symptoms") {
        for (var j = 0; j < currentItems.length; j++) {
          currentItems[j].site = eye;
          let p = apiCallPost(
            `/clinical/${this.props.authStore.prefix}/visitlevel/array/add`,
            {
              patientId: this.state.patientId,
              visitId: this.state.todayVisit._id,
              array: currentArray,
              props: currentItems[j],
            }
          );
          promises.push(p);
        }
      }
      if (currentArray === "findings") {
        let arrayToAdd = [];
        if (eye === "LE") {
          arrayToAdd = _.cloneDeep(currentItems);
          for (var kk = 0; kk < currentItems.length; kk++) {
            arrayToAdd[kk].site = "Left Eye";
          }
        }
        if (eye === "RE") {
          arrayToAdd = _.cloneDeep(currentItems);
          for (kk = 0; kk < currentItems.length; kk++) {
            arrayToAdd[kk].site = "Right Eye";
          }
        }
        if (eye === "BE") {
          arrayToAdd = _.cloneDeep(currentItems);
          for (kk = 0; kk < currentItems.length; kk++) {
            arrayToAdd[kk].site = "Right Eye";
          }
          let arrayToAdd2 = _.cloneDeep(currentItems);
          for (kk = 0; kk < currentItems.length; kk++) {
            arrayToAdd2[kk].site = "Left Eye";
          }
          arrayToAdd = _.concat(arrayToAdd, arrayToAdd2);
        }

        for (j = 0; j < arrayToAdd.length; j++) {
          let p = apiCallPost(
            `/clinical/${this.props.authStore.prefix}/visitlevel/array/add`,
            {
              patientId: this.state.patientId,
              visitId: this.state.todayVisit._id,
              array: currentArray,
              props: arrayToAdd[j],
            }
          );
          promises.push(p);
        }
      }

      if (currentArray === "eyeDx") {
        for (j = 0; j < currentItems.length; j++) {
          currentItems[j].site =
            eye === "BE"
              ? "Both Eyes"
              : eye === "RE"
              ? "Right Eye"
              : "Left Eye";
          let p = apiCallPost(
            `/clinical/${this.props.authStore.prefix}/visitlevel/array/add`,
            {
              patientId: this.state.patientId,
              visitId: this.state.todayVisit._id,
              array: currentArray,
              props: currentItems[j],
            }
          );
          promises.push(p);
        }
      }
      if (
        ["advice", "medicines", "plannedEyeSx", "sysinv"].includes(currentArray)
      ) {
        for (j = 0; j < currentItems.length; j++) {
          let p = apiCallPost(
            `/clinical/${this.props.authStore.prefix}/visitlevel/array/add`,
            {
              patientId: this.state.patientId,
              visitId: this.state.todayVisit._id,
              array: currentArray,
              props: currentItems[j],
            }
          );
          promises.push(p);
        }
      }
    }
    Promise.all(promises)
      .then(async () => {
        if (promises.length) {
          cogoToast.success("Added clinical items");
        }
        /** Investigations */
        for (var u = 0; u < keys.length; u++) {
          let currentArray = keys[u];
          let currentItems = obj[currentArray];
          if (currentArray === "investigations") {
            for (var v = 0; v < currentItems.length; v++) {
              if (currentItems[v].kind === "INVESTIGATION") {
                await this.bookNewProcedure({ data: currentItems[v] });
              } else if (currentItems[v].kind === "PACKAGE") {
                currentItems[v]["item"] = currentItems[v].service;
                await this.bookNewPackage(currentItems[v]);
              }
            }
          }
        }
        /** Follow Up */
        for (u = 0; u < keys.length; u++) {
          let currentArray = keys[u];
          let currentItems = obj[currentArray];
          if (currentArray === "follow_up_template") {
            let fuObj = currentItems[0];

            let fuCurrent = this.state.todayVisit.followup[0];
            let fuId = fuCurrent._id;
            fuCurrent["referTo"] =
              fuObj.referTo === "Me" ? null : fuObj.referTo || null;
            fuCurrent["refer"] = fuObj.referTo === "Me" ? false : true;
            fuCurrent["when"] = fuObj.when || null;
            try {
              await apiCallPost(
                `/clinical/${this.props.authStore.prefix}/visitlevel/array/ud`,
                {
                  patientId: this.state.patientId,
                  visitId: this.state.todayVisit._id,
                  array: "followup",
                  elementId: fuId,
                  props: fuCurrent,
                }
              );
            } catch (err) {
              cogoToast.error("Error updating follow up.");
            }
          }
        }

        this.getLanding();
        this.setState({ showTemplateDrawer: false });
        cogoToast.success("Template addition completed.");
      })
      .catch((err) => {
        cogoToast.error("We ran into an error.");
        console.log(err);
      });
  };
  editSx = async (whichArray, elementId, props) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      //  return;
    }
    try {
      cogoToast.loading("Updating " + whichArray, { position: "bottom-left" });
      let tt = this.state.todayVisit;
      let kk = _.get(tt, whichArray);
      let idx = _.findIndex(kk, (each) => each._id === elementId);
      kk[idx] = props;
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/visitlevel/array/ud`,
        {
          patientId: this.state.patientId,
          visitId: this.state.todayVisit._id,
          array: whichArray,
          elementId: elementId,
          props: kk[idx],
        }
      );
      _.set(tt, whichArray, res);
      this.setState({ todayVisit: tt, flagObject: this.state.flagObject + 1 });
      cogoToast.success("Updated " + whichArray, { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating state. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  editTopLevelArray = async (whichArray, elementId, key, value) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    try {
      cogoToast.loading("Updating " + whichArray, { position: "bottom-left" });
      let tt = this.state.todayVisit;
      let kk = _.get(tt, whichArray);
      let idx = _.findIndex(kk, (each) => each._id === elementId);
      if (whichArray === "medicines" && key === "durationObject") {
        kk[idx]["duration"] = value.duration;
        kk[idx]["durationText"] = value.durationText;
      } else {
        kk[idx][key] = value;
      }
      let leat = ["subjectiveNotes", "dxNotes", "advNotes", "findingNotes"];
      if (leat.includes(whichArray)) {
        kk[idx]["lastEditedAt"] = new Date();
      }
      let chks = ["symptoms", "eyeDx", "medicines", "advice"];
      if (chks.includes(whichArray) && kk[idx].type === "old") {
        kk[idx].checked = true;
      }
      if (typeof whichArray === "object" && whichArray[0] === "findings") {
        kk[idx].checked = true;
      }
      let arr = whichArray;
      if (typeof whichArray === "object") {
        arr = whichArray[0];
      }
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/visitlevel/array/ud`,
        {
          patientId: this.state.patientId,
          visitId: this.state.todayVisit._id,
          array: arr,
          elementId: elementId,
          props: kk[idx],
        }
      );
      _.set(tt, whichArray, res);
      this.setState({ todayVisit: tt, flagObject: this.state.flagObject + 1 });
      cogoToast.success("Updated " + whichArray, { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating state. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  removeTopLevelArray = async (whichArray, elementId, bulkElementIds = []) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      // return;
    }
    try {
      cogoToast.loading("Updating " + whichArray, { position: "bottom-left" });
      let tt = this.state.todayVisit;
      let arr = whichArray;
      if (typeof whichArray === "object") {
        arr = whichArray[0];
      }
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/visitlevel/array/rm`,
        {
          patientId: this.state.patientId,
          visitId: this.state.todayVisit._id,
          array: arr,
          elementId: elementId || "",
          bulkElementIds: bulkElementIds,
        }
      );
      _.set(tt, whichArray, res);
      if (bulkElementIds.length && whichArray === "findings") {
        this.getLanding();
      } else {
        this.setState(
          { todayVisit: tt, flagObject: this.state.flagObject + 1 },
          () => {
            if (whichArray.includes("findings")) {
              let anat = whichArray[1];
              let mm = [
                ["Lids and Adnexa", "Extraocular Muscles"],
                [
                  "Conjunctiva",
                  "Sclera",
                  "Cornea",
                  "Anterior Chamber",
                  "Iris",
                  "Lens",
                ],
                ["Vitreous", "Retina", "Disc"],
              ];
              let whichPart = "";
              let idx = -1;
              if (mm[0].includes(anat)) {
                whichPart = "Lids and Adnexa";
                idx = 0;
              } else if (mm[1].includes(anat)) {
                whichPart = "Anterior Segment";
                idx = 1;
              } else if (mm[2].includes(anat)) {
                whichPart = "Posterior Segment";
                idx = 2;
              }
              let anatFs = this.state.todayVisit["findings"][anat];
              if (anatFs.length === 0) {
                let flag = true;
                for (var i = 0; i < mm[idx].length; i++) {
                  let anatOt = this.state.todayVisit["findings"][mm[idx][i]];
                  if (anatOt.length > 0) {
                    flag = false;
                  }
                }
                if (flag) {
                  // last remaining
                  let pp = this.state.parts;
                  pp.push(whichPart);
                  pp = _.uniq(pp);
                  this.setState({ parts: pp });
                }
              }
            }
          }
        );
      }
    } catch (err) {
      cogoToast.error(
        "There was an error in updating state. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  updatePartsOfFindings = () => {
    let mm = [
      ["Lids and Adnexa", "Extraocular Muscles"],
      ["Conjunctiva", "Sclera", "Cornea", "Anterior Chamber", "Iris", "Lens"],
      ["Vitreous", "Retina", "Disc"],
    ];
    let parts = ["Lids and Adnexa", "Anterior Segment", "Posterior Segment"];
    let findings = this.state.todayVisit.findings;
    for (var i = 0; i < mm.length; i++) {
      for (var j = 0; j < mm[i].length; j++) {
        if (findings[mm[i][j]].length) {
          parts.splice(i, 1);
          break;
        }
      }
    }
    this.setState({ parts: parts });
  };

  editObjectInside = async (whichObject, whichKey, elementId, value) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    try {
      cogoToast.loading("Updating " + whichObject, { position: "bottom-left" });
      let tt = this.state.todayVisit;
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/visitlevel/objects/edit`,
        {
          patientId: this.state.patientId,
          visitId: this.state.todayVisit._id,
          object: whichObject,
          key: whichKey,
          objectId: elementId,
          value: value,
        }
      );
      tt[whichObject] = res;
      this.setState({ todayVisit: tt, flagObject: this.state.flagObject + 1 });
      cogoToast.success("Updated " + whichObject, { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating state. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  removeObjectInside = async (whichObject, whichKey, elementId) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    let tt = this.state.todayVisit;
    cogoToast.loading("Updating " + whichKey, { position: "bottom-left" });
    let oo = tt[whichObject];
    let kk = oo[whichKey];
    _.remove(kk, (each) => each._id === elementId);
    this.setState({ todayVisit: tt });
    cogoToast.success("Updated " + whichKey, { position: "bottom-left" });
  };
  removeAllPrevious = async (whichArray) => {
    let fnds = _.flattenDeep(_.values(this.state.todayVisit[whichArray]));
    let elemsToRemove = [];
    for (var i = 0; i < fnds.length; i++) {
      if (fnds[i].type === "old") {
        elemsToRemove.push(fnds[i]._id);
      }
    }
    if (elemsToRemove.length) {
      this.removeTopLevelArray(whichArray, "", elemsToRemove);
    }
  };
  updateScalar = async (whichKey, value) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    try {
      cogoToast.loading("Updating " + whichKey, { position: "bottom-left" });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/toplevel/props/edit`,
        {
          patientId: this.state.patientId,
          key: whichKey,
          value: value,
        }
      );
      this.setState({ [whichKey]: res });
      cogoToast.success("Updated " + whichKey, { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating values. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  addArray = async (whichKey, addToArray) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    try {
      cogoToast.loading("Updating " + whichKey, { position: "bottom-left" });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/toplevel/add`,
        {
          patientId: this.state.patientId,
          key: whichKey,
          props: addToArray,
        }
      );
      let tt = this.state[whichKey];
      tt.push(res);
      this.setState({ [whichKey]: tt });
      cogoToast.success("Updated " + whichKey, { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating values. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  removeArray = async (whichKey, elementId) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    try {
      cogoToast.loading("Updating " + whichKey, { position: "bottom-left" });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/toplevel/rm`,
        {
          patientId: this.state.patientId,
          key: whichKey,
          elementId: elementId,
        }
      );

      this.setState({ [whichKey]: res });
      cogoToast.success("Updated " + whichKey, { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating values. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  updateArray = async (whichKey, elementId, key, value) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    try {
      cogoToast.loading("Updating " + whichKey, { position: "bottom-left" });
      let kk = this.state[whichKey];
      let idx = _.findIndex(kk, (each) => each._id === elementId);
      kk[idx][key] = value;

      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/toplevel/ud`,
        {
          patientId: this.state.patientId,
          key: whichKey,
          elementId: elementId,
          props: kk[idx],
        }
      );
      this.setState({ [whichKey]: res });
      cogoToast.success("Updated " + whichKey, { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating values. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  onKeyDown(keyName, event) {
    if (keyName === "command+k" || keyName === "shift+k") {
      event.preventDefault();
      this.setState({ omniOpen: true, prefix: "" });
    }
    if (keyName === "command+j" || keyName === "shift+j") {
      event.preventDefault();

      this.setState({ showPast: true });
    }
    if (keyName === "command+;" || keyName === "control+;") {
      event.preventDefault();

      this.setState({ showTable: true });
    }
    if (keyName === "command+i" || keyName === "control+i") {
      event.preventDefault();

      this.setState({ showPrefix: true });
    }
    if (keyName === "command+." || keyName === "control+.") {
      event.preventDefault();

      this.setState({ showInv: true });
    }
    if (
      keyName === "command+b" ||
      keyName === "control+b" ||
      keyName === "shift+b"
    ) {
      event.preventDefault();

      this.setState({ showTemplateDrawer: true });
    }
  }
  search = async (string) => {
    if (!string) {
      this.setState({ searchResults: [] });
      return;
    }

    let prefixes = [
      "sym",
      "sign",
      "dx",
      "adv",
      "plan-sx",
      "inv",
      "proc",
      "med",
      "all",
      "fh",
      "sys-dx",
      "sys-sx",
      "prev-sx",
      "fu",
      "sysinv",
    ];

    let txtPs = string.split(" ");

    let hit = null;
    for (var i = 0; i < prefixes.length; i++) {
      if (prefixes[i] === txtPs[0]) {
        hit = prefixes[i];
        break;
      }
    }
    let pp = { prefix: hit || this.state.prefix || "", query: string };
    try {
      this.setState({ searching: true });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/unisearch`,
        pp
      );
      this.setState({ searchResults: res, searching: false });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  onSearch = (prefix, whichArray, query = "") => {
    this.setState({
      prefix: prefix,
      whichArray: whichArray,
      omniOpen: true,
      showTable: false,
      showPrefix: false,
      showInv: false,
      query: query,
    });
    if (query) {
      this.search(query);
    }
  };

  bookNewProcedure = async (item) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    cogoToast.loading(
      "Please wait, checking if this procedure is available at this site...",
      {
        position: "bottom-left",
      }
    );
    try {
      let props = { site: this.state.todayVisit.branch };
      props["service"] = item.data.service;
      props["serviceSubType"] = item.data.serviceSubType;
      let res = await apiCallPost(
        `/invproc/${this.props.authStore.prefix}/check-compat-inv`,
        props
      );

      if (res.status === "ok") {
        if (res.resources.length === 1) {
          this.bookInv(item, res.resources[0]._id);
        } else {
          this.setState({
            showInvResourceChoice: true,
            tempInvResourceChoices: res.resources,
            tempSelectedInvResource: null,
            omniOpen: false,
            tempInv: item,
          });
        }
      } else {
        this.setState({ omniOpen: false, tempInv: item }, () => {
          this.setState({ moveInvProc: true });
        });
      }
      return;
    } catch (err) {
      cogoToast.error(
        "There was an error in checking compatibilty for this procedure. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  bookNewPackage = async (item) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    cogoToast.loading(
      "Please wait, checking if this package is available at this site...",
      {
        position: "bottom-left",
      }
    );
    try {
      let props = { site: this.state.todayVisit.branch };
      props["linkedSearchPkgText"] = item.item;

      let res = await apiCallPost(
        `/invproc/${this.props.authStore.prefix}/check-compat-pkg`,
        props
      );

      if (res.status === "ok") {
        let pkg = res.packages[0];
        let pp = {
          packageId: pkg._id,
          referringDoctorId: this.state.todayVisit.doctorId,
          components: [],
        };
        let pkc = [];
        for (var i = 0; i < pkg.components.length; i++) {
          let aa = {
            applicableEyes: "Both Eyes",
            resourceId: pkg.components[i].aliasId,
            procedure: pkg.components[i].parentProcedure,
            subprocedure: pkg.components[i].capability,
            site: this.state.todayVisit.branch,
            chairTime: 10,
            time: new Date(),
          };
          pkc.push(aa);
        }
        pp["components"] = pkc;
        this.bookInv(pp, null, true);
      } else {
        this.setState({ omniOpen: false, tempInv: item }, () => {
          this.setState({ moveInvProc: true });
        });
      }
    } catch (err) {
      cogoToast.error(
        "There was an error in checking compatibilty for this procedure. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  renderResourceChoices = () => {
    if (!this.state.showInvResourceChoice) {
      return null;
    }
    if (!this.state.tempInvResourceChoices.length) {
      return null;
    }
    let arr = this.state.tempInvResourceChoices.map((each) => each.alias);
    let isSelected = this.state.tempSelectedInvResource
      ? _.find(
          this.state.tempInvResourceChoices,
          (each) => each._id === this.state.tempSelectedInvResource
        ).alias
      : null;

    return (
      <MutEx
        options={arr}
        selected={isSelected}
        minimal={true}
        onUpdate={(str) => {
          this.setState({
            tempSelectedInvResource: _.find(
              this.state.tempInvResourceChoices,
              (each) => each.alias === str
            )._id,
          });
        }}
      />
    );
  };
  bookInv = async (item, resourceId, isPackage = false) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    let payload = {
      patientInfo: {
        patientType: "OLD",
        genericPid: this.state.genericPid,
      },
      consultationBookings: [],
      packageBookings: isPackage ? [item] : [],
      procedureBookings: isPackage
        ? []
        : [
            {
              applicableEyes: "Both Eyes",
              resourceId: resourceId,
              procedure: item.data.service,
              subprocedure: item.data.serviceSubType,
              site: this.state.todayVisit.branch,
              chairTime: 10,
              time: new Date(),
              referringDoctorId: this.state.todayVisit.doctorId,
            },
          ],
      auxInfo: {
        symptoms: [],
        remarks: "Booked via Clinical Page",
        wasDocPtChoice: true,
        bookingChannel: "In Person",
        isEmergency: false,
      },
    };
    try {
      cogoToast.loading("Booking...");
      await apiCallPost(
        `/chrono/${this.props.authStore.prefix}/register-appointment2-v2`,
        payload
      );
      cogoToast.success("Booked successfully.");
      this.setState(
        {
          omniOpen: false,
          showInvResourceChoice: false,
          tempInvResourceChoices: [],
          tempSelectedInvResource: null,
          tempInv: {},
        },
        () => this.getInvProc()
      );
    } catch (err) {
      cogoToast.error(
        "There was an error in booking this procedure. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  getInvProc = async () => {
    if (!this.state.todayVisit._id) {
      return null;
    }
    try {
      let resB = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/visitlevel/array/getInvestigations`,
        {
          patientId: this.state.patientId,
          visitId: this.state.todayVisit._id,
        }
      );
      let tt = this.state.todayVisit;
      tt["investigations"] = resB;
      this.setState({ todayVisit: tt });
    } catch (err) {
      cogoToast.error(
        "There was an error in getting procedures. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  updateInvProc = async (type, elementId, key, value) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    let elem = _.find(
      this.state.todayVisit.investigations,
      (each) => each._id === elementId
    );
    let isPackage = elem.packageParentLineItemId;
    if (isPackage && key === "subtype") {
      cogoToast.error("This is part of a package, and can't be changed.");
      return;
    }

    if (isPackage && key === "eye") {
      let allP = _.filter(
        this.state.todayVisit.investigations,
        (each) => each.packageParentLineItemId === elem.packageParentLineItemId
      );
      try {
        cogoToast.loading("Updating...");
        await Promise.all(
          allP.map((each) => {
            return apiCallPost(
              `/invproc/${this.props.authStore.prefix}/update`,
              {
                type: type,
                elementId: each._id,
                patientId: this.state.patientId,
                visitId: this.state.todayVisit._id,
                key: key,
                value: value,
              }
            );
          })
        );
        this.getInvProc();
      } catch (err) {
        cogoToast.error(
          "There was an error in updating. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }

    try {
      await apiCallPost(`/invproc/${this.props.authStore.prefix}/update`, {
        type: type,
        elementId: elementId,
        patientId: this.state.patientId,
        visitId: this.state.todayVisit._id,
        key: key,
        value: value,
      });
      this.getInvProc();
      cogoToast.success("Updated procedure.", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  genPdfInvProc = async (invId) => {
    try {
      this.setState({ invLinkDisable: true });
      cogoToast.loading("Generating PDFs", {
        position: "bottom-left",
      });
      await apiCallPost(`/invproc/${this.props.authStore.prefix}/regenPdf`, {
        patientId: this.state.patientId,
        visitId: this.state.todayVisit._id,
        investigationId: invId,
        type: "INVESTIGATION",
      });
      this.setState({ invLinkDisable: false });
      cogoToast.success("Generated PDFs", {
        position: "bottom-left",
      });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  removeInvProc = async (elementId) => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    let elem = _.find(
      this.state.todayVisit.investigations,
      (each) => each._id === elementId
    );
    let isPackage = elem.packageParentLineItemId;

    if (isPackage) {
      let allP = _.filter(
        this.state.todayVisit.investigations,
        (each) => each.packageParentLineItemId === elem.packageParentLineItemId
      );
      try {
        cogoToast.loading("Removing...");
        let delDocks = await Promise.all(
          allP.map((each) => {
            return apiCallPost(
              `/invproc/${this.props.authStore.prefix}/removeHelper`,
              {
                elementId: each._id,
                patientId: this.state.patientId,
              }
            );
          })
        );
        try {
          await Promise.all(
            delDocks.map((each) => {
              return apiCallPost(
                `/mgmt/${this.props.authStore.prefix}/cancel/bookingV2`,
                {
                  DocketNo: each.sysDocketNo,
                  reasonText: "Cancelled via Clinical Page",
                  reasonCategory: ["Clinical Page"],
                }
              );
            })
          );
          try {
            cogoToast.loading("Removing package...");
            await apiCallPost(
              `/mgmt/${this.props.authStore.prefix}/cancel/packageParent`,
              {
                packageParentLineItemId: elem.packageParentLineItemId,
                reasonText: "Cancelled via Clinical Page",
                reasonCategory: ["Clinical Page"],
              }
            );
            this.getInvProc();
            cogoToast.success("Removed package.");
          } catch (err) {
            cogoToast.error(
              "There was an error in removing package. Please check the console for more details.",
              { position: "bottom-left" }
            );
            console.log(err);
          }
          this.getInvProc();
        } catch (err) {
          cogoToast.error(
            "There was an error in removing package. Please check the console for more details.",
            { position: "bottom-left" }
          );
          console.log(err);
        }
      } catch (err) {
        cogoToast.error(
          "There was an error in getting package procedure details. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    } else {
      try {
        cogoToast.loading("Removing procedure...");
        let res = await apiCallPost(
          `/invproc/${this.props.authStore.prefix}/removeHelper`,
          {
            elementId: elementId,
            patientId: this.state.patientId,
          }
        );
        try {
          await apiCallPost(
            `/mgmt/${this.props.authStore.prefix}/cancel/bookingV2`,
            {
              DocketNo: res.sysDocketNo,
              reasonText: "Cancelled via Clinical Page",
              reasonCategory: ["Clinical Page"],
            }
          );
          this.getInvProc();
          cogoToast.success("Removed procedure.", {
            position: "bottom-left",
          });
        } catch (err) {
          cogoToast.error(
            "There was an error in removing procedure. Please check the console for more details.",
            { position: "bottom-left" }
          );
          console.log(err);
        }
      } catch (err) {
        cogoToast.error(
          "There was an error in getting procedure details. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    }
  };
  makeComp = async () => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    try {
      cogoToast.loading("Applying complimentary", { position: "bottom-left" });
      await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/discountThisVisit`,
        {
          reason: "Doctor Discretion",
          patientId: this.state.patientId,
          visitId: this.state.todayVisit._id,
        }
      );

      let tt = this.state.todayVisit;
      tt["complimentary"] = true;
      this.setState({ todayVisit: tt, makeVisitComplimentary: false });
      cogoToast.success("This visit is now complimentary.", {
        position: "bottom-left",
      });
    } catch (err) {
      cogoToast.error(
        "There was an error in updating. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  checkout = async () => {
    let check = this.preventPast();
    if (!check) {
      cogoToast.error(
        "You can only make changes to visits on the day of the visit."
      );
      return;
    }
    try {
      this.setState({ glassRxLink: null, rxLink: null });

      await apiCallPost(`/clinical/${this.props.authStore.prefix}/checkout`, {
        patientId: this.state.patientId,
        visitId: this.state.todayVisit._id,
      });
      let tt = this.state.todayVisit;
      tt["done"] = true;
      this.setState({
        todayVisit: tt,
      });
      cogoToast.success("Checked out", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in checking out. Please check the console for more details. " +
          (err.response.data.msg || ""),
        { position: "top-center" }
      );
      console.log(err);
    }
  };
  /* genPDF = async () => {
    try {
      this.setState({ glassRxLink: null, rxLink: null });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/generatePdf`,
        {
          patientId: this.state.patientId,
          visitId: this.state.todayVisit._id,
        }
      );
      this.setState({ glassRxLink: res.glassRxPdfLink, rxLink: res.rxPdfLink });
      cogoToast.success("Generated PDFs", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error in regenerating PDFs. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  }; */
  onAddOmni = (item) => {
    let ptLevel = [
      "allergies",
      "sysEyeSx",
      "sysSx",
      "sysDx",
      "famHist",
      "prevEyeDx",
    ];
    let compat = ["investigations", "procedures"];
    if (this.state.whichArray === "prevEyeDx") {
      item.entityArray = "prevEyeDx";
    }
    if (ptLevel.includes(item.entityArray)) {
      let dd = item.data;
      dd["_id_sr"] = item._id;
      this.addArray(item.entityArray, item.data);
    } else if (compat.includes(item.entityArray)) {
      if (item.data.kind === "INVESTIGATION") {
        this.bookNewProcedure(item);
      } else if (item.data.kind === "PACKAGE") {
        this.bookNewPackage(item);
      }
      // this.invproc(item);
    } else {
      if (item.entityArray === "followupWith") {
        let dd = item.data;
        dd["_id_sr"] = item._id;
        this.addTopLevelArray("followupWith", dd);
      }
      if (item.entityArray === "findings") {
        let dd = item.data;
        dd["_id_sr"] = item._id;
        dd["type"] = "new";
        dd["checked"] = false;
        dd["site"] = "Right Eye";
        dd["sinceLastVisit"] = null;
        dd["anatomy"] = item.helper;
        dd["listOfQualifiers"] = item.listOfQualifiers;
        dd["qualifier"] = item.helper2;

        this.addTopLevelArray("findings", dd, item.helper);
        let cc = _.cloneDeep(dd);
        cc["site"] = "Left Eye";
        this.addTopLevelArray("findings", cc, item.helper);
      }
      if (item.entityArray === "symptoms") {
        let dd = item.data;
        dd["_id_sr"] = item._id;
        dd["type"] = "new";
        dd["checked"] = false;

        this.addTopLevelArray("symptoms", dd);
      }
      if (item.entityArray === "eyeDx") {
        let dd = item.data;
        dd["_id_sr"] = item._id;
        dd["type"] = "new";
        dd["checked"] = false;
        dd["query"] = false;
        dd["site"] = "Both Eyes";
        dd["anatomy"] = item.helper;
        this.addTopLevelArray("eyeDx", dd);
      }
      if (item.entityArray === "advice") {
        let dd = item.data;
        dd["_id_sr"] = item._id;
        dd["type"] = "new";
        dd["checked"] = false;
        this.addTopLevelArray("advice", dd);
      }
      if (item.entityArray === "medicines") {
        let dd = item.data;
        dd["_id_sr"] = item._id;
        dd["type"] = "new";
        dd["checked"] = false;
        this.addTopLevelArray("medicines", dd);
      }
      if (item.entityArray === "sysinv") {
        let dd = item.data;
        dd["_id_sr"] = item._id;
        dd["type"] = "new";
        dd["checked"] = false;
        dd["value"] =
          item.data.typeOfTest === "Boolean"
            ? false
            : item.data.typeOfTest === "Quantitative"
            ? 0
            : "Notes";
        this.addTopLevelArray("sysinv", dd);
      }
    }
  };
  renderVideo = () => {
    var isMobileDevice = this.rtcDetails
      ? this.rtcDetails.isMobileDevice
      : null;
    var height = "60vh";
    var width = "50vw";
    var right = "1em";
    var bottom = "1em";
    if (isMobileDevice) {
      height = "100vh";
      width = "100vw";
      right = "0em";
      bottom = "0em";
    }
    if (this.state.videoCanStart && this.canStart) {
      let callFrame = DailyIframe.createFrame({
        showLeaveButton: "true",
        showFullscreenButton: "true",
        url: this.state.dailyCoToken.url,
        token: this.state.dailyCoToken.token,
        iframeStyle: {
          position: "fixed",
          border: "1px solid black",
          width: width,
          height: height,
          right: right,
          bottom: bottom,
        },
      });
      callFrame.join().then((e) => console.log(e));
      this.canStart = false;
      this.videoEvents.forEach((event) => {
        callFrame.on(event, (e) => {
          if (event === "left-meeting") {
            this.setState({
              videoCanStart: false,
            });
            this.canStart = true;
            this.videoEvents.forEach((event) => {
              callFrame.off(event, (e) => console.log(e));
            });
            callFrame.leave();
            callFrame.destroy();
          }
          if (event === "error") {
            cogoToast.error(e.errorMsg);
          }
        });
      });
    }
  };
  renderHeader = () => {
    return (
      <>
        {this.renderVideo()}
        <ControlGroup>
          {this.state.pastVisits.length ? (
            <Tag
              minimal
              icon="clipboard"
              interactive
              intent="warning"
              onClick={() => this.setState({ showPast: true })}
            >
              {this.state.pastVisits.length}
            </Tag>
          ) : null}{" "}
          {this.state.dailyCoToken &&
          this.state.dailyCoToken.meetingRoomExists ? (
            <Button
              icon="mobile-video"
              minimal
              intent="success"
              onClick={() => this.attemptVideoCall()}
            />
          ) : null}
          <Tag
            large
            style={{
              fontSize: "larger",
            }}
            intent="primary"
          >
            {_.startCase(_.lowerCase(this.state.patientName))}
          </Tag>{" "}
          <Tag intent="primary" minimal>
            {this.state.genericPid}
          </Tag>
          {this.state.editingFunctional ? (
            <SingleSelectWrapper
              items={["Left Eye", "Right Eye", "Both Eyes", "Neither Eye"]}
              selectedItem={this.state.functionalEyes}
              onChange={(item) => {
                this.updateScalar("functionalEyes", item);
                this.setState({ editingFunctional: false });
              }}
            />
          ) : (
            <Tag
              interactive
              onClick={() => this.setState({ editingFunctional: true })}
              minimal={this.state.functionalEyes === "Both Eyes"}
              intent={
                this.state.functionalEyes === "Both Eyes" ? "none" : "danger"
              }
            >
              Functional Eyes: {initials(this.state.functionalEyes)}
            </Tag>
          )}
          <Sex sex={this.state.sex} update={this.updateScalar} />
          <Age dob={this.state.dob} update={this.updateScalar} />
          <Tag minimal>
            {this.state.geocode
              ? this.state.geocode.formatted_address || "No Address Recorded"
              : "No Geocode Recorded"}
          </Tag>
          {this.state.editingPurpose ? (
            <SingleSelectWrapper
              items={this.listOfPurpose}
              selectedItem={this.state.todayVisit.purpose}
              onChange={(item) => {
                this.setState({ editingPurpose: false });
                this.editVisitProps("purpose", item);
              }}
            />
          ) : (
            <Tag
              minimal
              interactive
              intent="success"
              onClick={() => this.setState({ editingPurpose: true })}
            >
              {this.state.todayVisit.purpose}
            </Tag>
          )}{" "}
          {this.renderActionItems()}
        </ControlGroup>
      </>
    );
  };
  onAddInvFromSx = (strList) => {
    let arr = strList.split(",").filter(Boolean);
    if (arr.length) {
      arr = arr.map((each) => _.trim(each));
    } else {
      return;
    }
    let a = this.state.onAddInvFromSxList;
    a.push(arr);
    a = _.uniq(_.flattenDeep(a));
    let adviceList = [
      "GVP",
      "EGVP",
      "Multiple explained",
      "NVP",
      "Loading Doses",
    ];
    let list = _.flattenDeep(_.concat(a, adviceList));
    this.setState({ onAddInvFromSxList: list });
  };
  renderInvFromSx = () => {
    if (!this.state.onAddInvFromSxList.length) {
      return null;
    }

    return (
      <div>
        <ControlGroup>
          {" "}
          <Tag
            large
            minimal
            intent="warning"
            interactive
            rightIcon="cross"
            onClick={() => this.setState({ onAddInvFromSxList: [] })}
          >
            Add suggested investigations or advice based on surgeries planned
          </Tag>
        </ControlGroup>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.state.onAddInvFromSxList.map((each) => {
            return (
              <Tag
                large
                minimal
                interactive
                onClick={() => {
                  this.setState({ omniOpen: true, query: each }, () => {
                    this.search(each);
                    let x = this.state.onAddInvFromSxList;
                    let idx = _.findIndex(x, (y) => y === each);

                    x.splice(idx, 1);
                    this.setState({ onAddInvFromSxList: x });
                  });
                }}
              >
                {each}
              </Tag>
            );
          })}
        </div>
      </div>
    );
  };
  renderOneTab = () => {
    let scope = toJS(this.props.authStore.scope);
    return (
      <>
        <Card
          elevation={Elevation.FOUR}
          style={{
            backgroundColor: "#17191a",
            width: `${this.state.videoCanStart ? "50vw" : ""}`,
          }}
        >
          {" "}
          <Tabs id="ClinicalTabs">
            <Tab
              id="clinical"
              title="Clinical Note"
              panel={
                <>
                  <Context
                    vision={this.state.todayVisit.vision}
                    colourVision={this.state.colorVision}
                    updateVision={(path, value) =>
                      this.editObjectInside(
                        "vision",
                        path,
                        this.state.todayVisit.vision._id,

                        value
                      )
                    }
                    prevEyeDx={this.state.prevEyeDx}
                    sysEyeSx={this.state.sysEyeSx}
                    sysSx={this.state.sysSx}
                    famHist={this.state.famHist}
                    sysDx={this.state.sysDx}
                    updateScalar={this.updateScalar}
                    allergies={this.state.allergies}
                    prev_medicines={this.state.todayVisit.prev_medicines}
                    removeArray={this.removeArray}
                    updateArray={this.updateArray}
                    onSearch={(prefix, whichArray) => {
                      this.onSearch(prefix, whichArray);
                    }}
                    addCrx={(obj) =>
                      this.addTopLevelArray("prev_medicines", obj)
                    }
                    removeCrx={this.removeTopLevelArray}
                  />
                  <br />
                  <Subjective
                    addObjectInside={this.addTopLevelArray}
                    visitId={this.state.todayVisit._id}
                    symptoms={this.state.todayVisit.symptoms}
                    subjectiveNotes={this.state.todayVisit.subjectiveNotes}
                    add={this.addTopLevelArray}
                    update={this.editTopLevelArray}
                    remove={(symptomId) =>
                      this.removeTopLevelArray("symptoms", symptomId)
                    }
                  />
                  <br />
                  <Objective
                    removeAllPrevious={() => this.removeAllPrevious("findings")}
                    visitId={this.state.todayVisit._id}
                    key={this.state.flagObject}
                    parts={this.state.parts}
                    normies={this.state.todayVisit.areSectionsNormal}
                    data={this.state.todayVisit.findings}
                    editObjectInside={this.editTopLevelArray}
                    addObjectInside={this.addTopLevelArray}
                    update={this.editTopLevelArray}
                    remove={this.removeTopLevelArray}
                    mutMultUpdate={this.editVisitProps}
                    findingNotes={this.state.todayVisit.findingNotes}
                  />
                  <br />
                  <Analysis
                    addObjectInside={this.addTopLevelArray}
                    visitId={this.state.todayVisit._id}
                    dxNotes={this.state.todayVisit.dxNotes}
                    add={this.addTopLevelArray}
                    data={this.state.todayVisit.eyeDx}
                    update={this.editTopLevelArray}
                    remove={(dxId) => this.removeTopLevelArray("eyeDx", dxId)}
                  />{" "}
                  <br />
                  <Investigation
                    invLinkDisable={this.state.invLinkDisable}
                    genPdf={this.genPdfInvProc}
                    openInvDrawer={() => this.setState({ showInv: true })}
                    data={this.state.todayVisit.investigations}
                    update={this.updateInvProc}
                    remove={(invId) => this.removeInvProc(invId)}
                    onSearch={this.onSearch}
                    onInv={() => this.setState({ showInv: true })}
                  />{" "}
                  <br />
                  <PlanSysInv
                    key={
                      this.state.todayVisit.sysinv
                        ? this.state.todayVisit.sysinv.join("__")
                        : nanoid()
                    }
                    data={this.state.todayVisit.sysinv}
                    update={this.editTopLevelArray}
                    remove={this.removeTopLevelArray}
                    onSearch={this.onSearch}
                  />{" "}
                  <br />
                  <PlanMedicines
                    removeAllPrevious={() =>
                      this.removeAllPrevious("medicines")
                    }
                    addObjectInside={this.addTopLevelArray}
                    visitId={this.state.todayVisit._id}
                    onShowTable={() =>
                      this.setState({ showTable: !this.state.showTable })
                    }
                    add={this.addTopLevelArray}
                    data={this.state.todayVisit.medicines}
                    update={this.editTopLevelArray}
                    remove={(medId) =>
                      this.removeTopLevelArray("medicines", medId)
                    }
                  />
                  <br /> <br />
                  <PlanAdvice
                    removeAllPrevious={() => this.removeAllPrevious("advice")}
                    addObjectInside={this.addTopLevelArray}
                    visitId={this.state.todayVisit._id}
                    advNotes={this.state.todayVisit.advNotes}
                    add={this.addTopLevelArray}
                    data={this.state.todayVisit.advice}
                    update={this.editTopLevelArray}
                    remove={(advId) =>
                      this.removeTopLevelArray("advice", advId)
                    }
                  />
                  <br /> <br />
                  <PlanSurgery
                    data={this.state.todayVisit.plannedEyeSx}
                    add={this.addTopLevelArray}
                    update={this.editSx}
                    remove={(sxId) =>
                      this.removeTopLevelArray("plannedEyeSx", sxId)
                    }
                    onAddInvFromSx={this.onAddInvFromSx}
                  />{" "}
                  {this.renderInvFromSx()}
                  <br /> <br />
                  <PlanFollowUp
                    fuItems={this.state.todayVisit.followupWith}
                    docList={this.state.docList}
                    data={this.state.todayVisit.followup}
                    update={this.editTopLevelArray}
                    onSearch={this.onSearch}
                    remove={(fuId) =>
                      this.removeTopLevelArray("followupWith", fuId)
                    }
                  />
                  <br />
                </>
              }
            />

            <Tabs.Expander />
            <ControlGroup>
              <Button
                minimal
                icon={
                  this.state.layout
                    ? "double-caret-horizontal"
                    : "double-caret-vertical"
                }
                onClick={() =>
                  this.setState({
                    layout: !this.state.layout,
                  })
                }
              />
              {scope.includes("doctor") ? (
                <>
                  <Button
                    text="Observations"
                    icon="offline"
                    minimal
                    intent="success"
                    onClick={() => this.setState({ obsWizardOpen: true })}
                  />
                </>
              ) : null}
              {scope.includes("optom") ? (
                <>
                  {" "}
                  <Button
                    text="History"
                    icon="offline"
                    minimal
                    intent="primary"
                    onClick={() => this.setState({ historyWizardOpen: true })}
                  />
                  <Button
                    text="Vision"
                    icon="offline"
                    minimal
                    intent="warning"
                    onClick={() => this.setState({ visionWizardOpen: true })}
                  />
                </>
              ) : null}

              <Button
                onClick={() => {
                  this.setState({ omniOpen: true, prefix: "" });
                }}
                minimal
                icon="search"
              />
            </ControlGroup>
          </Tabs>{" "}
        </Card>
      </>
    );
  };
  renderTabs = () => {
    let scope = toJS(this.props.authStore.scope);

    return (
      <Hotkeys
        keyName="control+i, command+i, shift+k, command+k, shift+j, command+j, control+;, command+;, command+., control+., command+b, control+b, shift+b"
        onKeyDown={this.onKeyDown.bind(this)}
      >
        <SyntaxTable
          showTable={this.state.showTable}
          onClose={() => this.setState({ showTable: false })}
        />
        <TemplateDrawer
          onBulkAdd={(obj, eye) => this.onBulkAdd(obj, eye)}
          showTemplateDrawer={this.state.showTemplateDrawer}
          onClose={() => this.setState({ showTemplateDrawer: false })}
        />
        <InvDrawer
          showInv={this.state.showInv}
          onClose={() => this.setState({ showInv: false })}
          data={this.state.todayVisit.investigations}
          updateInv={this.updateInvProc}
          removeInv={(invId) => this.removeInvProc(invId)}
          patientData={{
            patientName: this.state.patientName,
            genericPid: this.state.genericPid,
            sex: this.state.sex,
            dob: this.state.dob,
          }}
          onSearch={(prefix, whichArray) => this.onSearch(prefix, whichArray)}
        />
        <PrefixTable
          showPrefix={this.state.showPrefix}
          onSearch={(prefix, whichArray) => this.onSearch(prefix, whichArray)}
          onClose={() => this.setState({ showPrefix: false })}
        />
        {this.state.pastVisits.length ? (
          <HistoryDrop
            genericPid={this.state.genericPid}
            data={this.state.pastVisits}
            showPast={this.state.showPast}
            onClose={() => this.setState({ showPast: false })}
          />
        ) : null}
        <Alert
          className="axis bp5-dark"
          cancelButtonText="Cancel"
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          confirmButtonText="Add As Follow Up Procedure"
          icon="move"
          intent="success"
          isOpen={this.state.moveInvProc}
          onCancel={() => this.setState({ moveInvProc: false, tempInv: {} })}
          onConfirm={() => {
            let dd = this.state.tempInv;
            dd["data"]["type"] = this.state.tempInv.data.service;
            dd["data"]["subtype"] = this.state.tempInv.data.serviceSubType;
            dd["entityArray"] = "followupWith";
            this.onAddOmni(dd);
            this.setState({ moveInvProc: false, tempInv: {} });
          }}
        >
          {_.isEmpty(this.state.tempInv) ? null : (
            <>
              {" "}
              {this.state.tempInv.data.service} -{" "}
              {this.state.tempInv.data.serviceSubType} is not available at{" "}
              {this.state.todayVisit.branch}. Do you want to move it to follow
              up instead so that the patient can get this test done at a
              different site?{" "}
            </>
          )}
        </Alert>
        <Alert
          className="axis bp5-dark"
          cancelButtonText="Cancel"
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          confirmButtonText="Choose Resource"
          icon="add-to-artifact"
          intent="success"
          isOpen={this.state.showInvResourceChoice}
          onCancel={() =>
            this.setState({
              showInvResourceChoice: false,
              tempInvResourceChoices: [],
              tempSelectedInvResource: null,
              tempInv: {},
            })
          }
          onConfirm={() => {
            this.bookInv(
              this.state.tempInv,
              this.state.tempSelectedInvResource
            );
            this.setState({ showInvResourceChoice: false });
          }}
        >
          {_.isEmpty(this.state.tempInv) ? null : (
            <>
              {" "}
              {this.state.tempInv.data.service} -{" "}
              {this.state.tempInv.data.serviceSubType} is available at{" "}
              {this.state.todayVisit.branch} in two different resources. Which
              one do you want to book with? <br />
              <br />
              {this.renderResourceChoices()}
            </>
          )}
        </Alert>
        <Alert
          className="axis bp5-dark"
          cancelButtonText="Cancel"
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          confirmButtonText="Make This Visit Complimentary"
          icon="credit-card"
          intent="success"
          isOpen={this.state.makeVisitComplimentary}
          onCancel={() => this.setState({ makeVisitComplimentary: false })}
          onConfirm={() => this.makeComp()}
        >
          <>
            Are you sure you want to make this visit complimentary? <br />
            <br />
            You will not be able to reverse this decision without contacting
            support.
          </>
        </Alert>
        <Omni
          isOpen={this.state.omniOpen}
          query={this.state.query}
          onClose={() => this.setState({ omniOpen: false })}
          placeholder="Universal Search..."
          searching={this.state.searching}
          items={this.state.searchResults}
          selectedItem={null}
          update={(item) => {
            this.setState({ query: item });
            this.search(item);
          }}
          onChange={(item) => {
            this.onAddOmni(item);
          }}
        />

        <div
          className="large-left-panel bp5-dark"
          style={{
            height: "95vh",
            padding: "10px",
            overflow: "scroll",
          }}
        >
          <div id="portal-mag" />
          {this.renderHeader()}
          <VisionSection
            lastVision={this.state.pastVisits}
            vision={this.state.todayVisit.vision}
            updateVision={(path, value) =>
              this.editObjectInside(
                "vision",
                path,
                this.state.todayVisit.vision._id,

                value
              )
            }
            updateScalar={this.updateScalar}
          />
          <br />
          {this.state.layout ? (
            <>
              <Card
                elevation={Elevation.FOUR}
                style={{ backgroundColor: "#17191a" }}
              >
                {" "}
                <Tabs id="ClinicalTabs">
                  <Tab
                    id="subjective"
                    title="Subjective"
                    panel={
                      <>
                        <Context
                          vision={this.state.todayVisit.vision}
                          colourVision={this.state.colorVision}
                          updateVision={(path, value) =>
                            this.editObjectInside(
                              "vision",
                              path,
                              this.state.todayVisit.vision._id,

                              value
                            )
                          }
                          prevEyeDx={this.state.prevEyeDx}
                          sysEyeSx={this.state.sysEyeSx}
                          sysSx={this.state.sysSx}
                          famHist={this.state.famHist}
                          sysDx={this.state.sysDx}
                          updateScalar={this.updateScalar}
                          allergies={this.state.allergies}
                          prev_medicines={this.state.todayVisit.prev_medicines}
                          removeArray={this.removeArray}
                          updateArray={this.updateArray}
                          onSearch={(prefix, whichArray) => {
                            this.onSearch(prefix, whichArray);
                          }}
                          addCrx={(obj) =>
                            this.addTopLevelArray("prev_medicines", obj)
                          }
                          removeCrx={this.removeTopLevelArray}
                        />

                        <br />
                        <Subjective
                          addObjectInside={this.addTopLevelArray}
                          visitId={this.state.todayVisit._id}
                          symptoms={this.state.todayVisit.symptoms}
                          subjectiveNotes={
                            this.state.todayVisit.subjectiveNotes
                          }
                          add={this.addTopLevelArray}
                          update={this.editTopLevelArray}
                          remove={(symptomId) =>
                            this.removeTopLevelArray("symptoms", symptomId)
                          }
                        />
                      </>
                    }
                  />
                  <Tab
                    id="objective"
                    title="Objective"
                    panel={
                      <Objective
                        removeAllPrevious={() =>
                          this.removeAllPrevious("findings")
                        }
                        visitId={this.state.todayVisit._id}
                        key={this.state.flagObject}
                        parts={this.state.parts}
                        normies={this.state.todayVisit.areSectionsNormal}
                        data={this.state.todayVisit.findings}
                        editObjectInside={this.editTopLevelArray}
                        addObjectInside={this.addTopLevelArray}
                        update={this.editTopLevelArray}
                        remove={this.removeTopLevelArray}
                        mutMultUpdate={this.editVisitProps}
                        findingNotes={this.state.todayVisit.findingNotes}
                      />
                    }
                  />
                  <Tab
                    id="assessment"
                    title="Assessment"
                    panel={
                      <>
                        <Analysis
                          addObjectInside={this.addTopLevelArray}
                          visitId={this.state.todayVisit._id}
                          dxNotes={this.state.todayVisit.dxNotes}
                          add={this.addTopLevelArray}
                          data={this.state.todayVisit.eyeDx}
                          update={this.editTopLevelArray}
                          remove={(dxId) =>
                            this.removeTopLevelArray("eyeDx", dxId)
                          }
                        />{" "}
                        <br />
                        <Investigation
                          invLinkDisable={this.state.invLinkDisable}
                          genPdf={this.genPdfInvProc}
                          openInvDrawer={() => this.setState({ showInv: true })}
                          data={this.state.todayVisit.investigations}
                          update={this.updateInvProc}
                          remove={(invId) => this.removeInvProc(invId)}
                          onSearch={this.onSearch}
                          onInv={() => this.setState({ showInv: true })}
                        />{" "}
                        <br />
                        <PlanSysInv
                          key={
                            this.state.todayVisit.sysinv
                              ? this.state.todayVisit.sysinv.join("__")
                              : nanoid()
                          }
                          data={this.state.todayVisit.sysinv}
                          update={this.editTopLevelArray}
                          remove={this.removeTopLevelArray}
                          onSearch={this.onSearch}
                        />{" "}
                      </>
                    }
                  />
                  <Tab
                    id="plan"
                    title="Plan"
                    panel={
                      <>
                        <PlanMedicines
                          removeAllPrevious={() =>
                            this.removeAllPrevious("medicines")
                          }
                          addObjectInside={this.addTopLevelArray}
                          visitId={this.state.todayVisit._id}
                          onShowTable={() =>
                            this.setState({ showTable: !this.state.showTable })
                          }
                          add={this.addTopLevelArray}
                          data={this.state.todayVisit.medicines}
                          update={this.editTopLevelArray}
                          remove={(medId) =>
                            this.removeTopLevelArray("medicines", medId)
                          }
                        />
                        <br /> <br />
                        <PlanAdvice
                          removeAllPrevious={() =>
                            this.removeAllPrevious("advice")
                          }
                          addObjectInside={this.addTopLevelArray}
                          visitId={this.state.todayVisit._id}
                          advNotes={this.state.todayVisit.advNotes}
                          add={this.addTopLevelArray}
                          data={this.state.todayVisit.advice}
                          update={this.editTopLevelArray}
                          remove={(advId) =>
                            this.removeTopLevelArray("advice", advId)
                          }
                        />
                        <br /> <br />
                        <PlanSurgery
                          onAddInvFromSx={this.onAddInvFromSx}
                          data={this.state.todayVisit.plannedEyeSx}
                          add={this.addTopLevelArray}
                          update={this.editSx}
                          remove={(sxId) =>
                            this.removeTopLevelArray("plannedEyeSx", sxId)
                          }
                        />{" "}
                        {this.renderInvFromSx()}
                        <br /> <br />
                        <PlanFollowUp
                          fuItems={this.state.todayVisit.followupWith}
                          docList={this.state.docList}
                          data={this.state.todayVisit.followup}
                          update={this.editTopLevelArray}
                          onSearch={this.onSearch}
                          remove={(fuId) =>
                            this.removeTopLevelArray("followupWith", fuId)
                          }
                        />
                        <br />
                      </>
                    }
                  />
                  <Tabs.Expander />
                  <ControlGroup>
                    <Button
                      minimal
                      icon={
                        this.state.layout
                          ? "double-caret-horizontal"
                          : "double-caret-vertical"
                      }
                      onClick={() =>
                        this.setState({
                          layout: !this.state.layout,
                        })
                      }
                    />
                    {scope.includes("doctor") ? (
                      <>
                        <Button
                          text="Observations"
                          icon="offline"
                          minimal
                          intent="success"
                          onClick={() => this.setState({ obsWizardOpen: true })}
                        />
                      </>
                    ) : null}
                    {scope.includes("optom") ? (
                      <>
                        {" "}
                        <Button
                          text="History"
                          icon="offline"
                          minimal
                          intent="primary"
                          onClick={() =>
                            this.setState({ historyWizardOpen: true })
                          }
                        />
                        <Button
                          text="Vision"
                          icon="offline"
                          minimal
                          intent="warning"
                          onClick={() =>
                            this.setState({ visionWizardOpen: true })
                          }
                        />
                      </>
                    ) : null}

                    <Button
                      onClick={() => {
                        this.setState({ omniOpen: true, prefix: "" });
                      }}
                      minimal
                      icon="search"
                    />
                  </ControlGroup>
                </Tabs>{" "}
              </Card>
            </>
          ) : (
            <>{this.renderOneTab()}</>
          )}
        </div>
      </Hotkeys>
    );
  };
  renderActionItems = () => {
    let scope = toJS(this.props.authStore.scope);
    if (scope.includes("doctor")) {
      return (
        <ControlGroup>
          {this.state.todayVisit.complimentary ? (
            <Tag minimal>Complimentary Visit</Tag>
          ) : (
            <>
              {/* <Tag
              interactive
              minimal
              icon="dollar"
              onClick={() => this.setState({ makeVisitComplimentary: true })}
            >
              Make Visit Complimentary
            </Tag> */}
            </>
          )}
          {this.state.todayVisit.investigations &&
          this.state.todayVisit.investigations.length ? (
            <Button
              intent="primary"
              minimal
              icon="media"
              onClick={() => this.setState({ showInv: !this.state.showInv })}
            />
          ) : null}
          <Button
            minimal
            icon="code-block"
            intent="primary"
            onClick={() => this.setState({ showTemplateDrawer: true })}
          />
          {this.state.todayVisit.vision.visionDone ? null : (
            <Button
              icon="endorsed"
              text="Work Up Done"
              minimal
              onClick={() => {
                this.editObjectInside(
                  "vision",
                  "visionDone",
                  this.state.todayVisit.vision._id,
                  true
                );
              }}
            />
          )}
          {!this.state.todayVisit.done ? (
            <Button
              icon="endorsed"
              text="Check Out"
              minimal
              onClick={() => this.checkout()}
            />
          ) : (
            <>
              <AnchorButton
                minimal
                target="_blank"
                icon="prescription"
                href={fixUrlPrefix(this.state.todayVisit.rxPdfLink)}
              />
              <AnchorButton
                minimal
                target="_blank"
                icon="eye-on"
                href={fixUrlPrefix(this.state.todayVisit.glassRxPdfLink)}
              />
            </>
          )}
        </ControlGroup>
      );
    }
    if (scope.includes("optom")) {
      return (
        <ControlGroup>
          {this.state.todayVisit.vision.visionDone ? (
            <>
              {!this.state.todayVisit.done ? (
                <Tag minimal>Awaiting Consultation</Tag>
              ) : (
                <>
                  {" "}
                  <AnchorButton
                    minimal
                    target="_blank"
                    icon="prescription"
                    href={fixUrlPrefix(this.state.todayVisit.rxPdfLink)}
                  />
                  <AnchorButton
                    minimal
                    target="_blank"
                    icon="eye-on"
                    href={fixUrlPrefix(this.state.todayVisit.glassRxPdfLink)}
                  />
                </>
              )}
            </>
          ) : (
            <Button
              text="Work Up Done"
              icon="endorsed"
              minimal
              onClick={() => {
                this.editObjectInside(
                  "vision",
                  "visionDone",
                  this.state.todayVisit.vision._id,
                  true
                );
              }}
            />
          )}
        </ControlGroup>
      );
      // show vision  if not done
    }
    if (scope.includes("clinical")) {
      return (
        <ControlGroup>
          {!this.state.todayVisit.done ? (
            <Tag minimal>Awaiting Consultation</Tag>
          ) : (
            <>
              <AnchorButton
                minimal
                target="_blank"
                icon="prescription"
                href={fixUrlPrefix(this.state.todayVisit.rxPdfLink)}
              />
              <AnchorButton
                minimal
                target="_blank"
                icon="eye-on"
                href={fixUrlPrefix(this.state.todayVisit.glassRxPdfLink)}
              />
            </>
          )}
        </ControlGroup>
      );
    }
  };
  render() {
    if (this.state.waiting) {
      return (
        <NonIdealState
          className="bp5-dark"
          icon={<Spinner />}
          title="Loading Clinical Record..."
        />
      );
    }
    return (
      <div className="bp5-dark">
        <HistoryWizard
          visitId={this.state.todayVisit._id}
          listDocs={this.state.docList}
          updateScalar={this.updateScalar}
          editVisitProps={this.editVisitProps}
          vision={this.state.todayVisit.vision}
          isOpen={this.state.historyWizardOpen}
          onClose={() => this.setState({ historyWizardOpen: false })}
          data={this.state}
          updateTop={(id, value) => {
            this.setState({ [id]: value });
          }}
          add={this.addTopLevelArray}
          update={this.editTopLevelArray}
          remove={(symptomId) =>
            this.removeTopLevelArray("symptoms", symptomId)
          }
          removeCrx={this.removeTopLevelArray}
          addArray={this.addArray}
          updateArray={this.updateArray}
          removeArray={this.removeArray}
        />{" "}
        <VisionWizard
          vision={this.state.todayVisit.vision}
          isOpen={this.state.visionWizardOpen}
          updateVision={(path, value) =>
            this.editObjectInside(
              "vision",
              path,
              this.state.todayVisit.vision._id,
              value
            )
          }
          colourVision={this.state.colorVision}
          updateScalar={this.updateScalar}
          onClose={() => this.setState({ visionWizardOpen: false })}
        />
        <ObsWizard
          visitId={this.state.todayVisit._id}
          data={this.state.todayVisit.findings}
          isOpen={this.state.obsWizardOpen}
          editObjectInside={this.editTopLevelArray}
          addObjectInside={this.addTopLevelArray}
          update={this.editTopLevelArray}
          remove={this.removeTopLevelArray}
          mutMultUpdate={this.editVisitProps}
          onClose={() => this.setState({ obsWizardOpen: false })}
        />
        {this.renderTabs()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(Clinical));
