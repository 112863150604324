import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";

import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class FamHist extends React.Component {
  state = {
    touched: false,
    text: null,
    editingLineage: false,
  };

  lineages = ["Paternal", "Maternal", "Both Parents", "Sibling"];

  render() {
    return (
      <div>
        <ControlGroup>
          <Tag
            intent="primary"
            minimal
            large
            onRemove={() => {
              this.props.onRemove();
            }}
          >
            {this.props.data.disease}
          </Tag>
          {this.state.editingLineage ? (
            <SingleSelectWrapper
              items={this.lineages}
              selectedItem={this.props.data.lineage}
              onChange={(item) => {
                this.setState({ editingLineage: false });
                this.props.onUpdate("lineage", item);
              }}
            />
          ) : (
            <Tag
              minimal
              large
              interactive
              onClick={() => this.setState({ editingLineage: true })}
            >
              {this.props.data.lineage}
            </Tag>
          )}
        </ControlGroup>
      </div>
    );
  }
}

export default FamHist;
