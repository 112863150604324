import React from "react";

import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import _ from "lodash";

import {
  Button,
  ControlGroup,
  InputGroup,
  Spinner,
  FormGroup,
  TextArea,
  Dialog,
  Tag,
} from "@blueprintjs/core";
import { QuickScore } from "quick-score";
import BookingThreadContainer from "./BookingThreadContainer";
import { apiCallPostCancellable, apiCallPost } from "../fns/util";
import cogoToast from "cogo-toast";
import MultiSelectWrapper from "../utils/MultiSelectWrapper";
import MutEx from "../utils/MutEx";

class Search extends React.Component {
  state = {
    searchWith: "",
    list: [],
    waiting: false,
    showDialog: false,
    redirect: null,
    newEnquiryNumber: null,
    enquiryCategories: [],
    enquiryText: null,
    isSettingsOpen: false,
    sortBy: null,
  };

  componentDidMount() {
    let loc = window.location.pathname;

    if (loc.includes("list")) {
      this.props.bookingStore.isList = true;
      this.setState({ list: this.props.list });
    } else {
      this.props.bookingStore.isList = false;
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.bookingStore.isList) {
      if (prevProps.id !== this.props.id) {
        this.setState({ list: this.props.list });
      }
    }
  }
  submitEnquiry = async () => {
    try {
      let payload = {
        new: true,
        newNumber: this.state.newEnquiryNumber || "NA",
        category: this.state.enquiryCategories,
        comments: this.state.enquiryText || "NA",
        whichPatient: "NA",
        whichName: "NA",
        whichPhone: "NA",
        whichAddress: "NA",
      };

      cogoToast.loading("Logging enquiry.", { position: "bottom-right" });
      /** SEGMENT */
      this.props.bookingStore.enquiryEnd = new Date();
      try {
        await apiCallPost(
          `/enquiry/${this.props.authStore.prefix}/addEnquiry`,
          payload
        );
        cogoToast.success("Logged Enquiry.", { position: "bottom-right" });
        this.setState({ showDialog: false });
      } catch (err) {
        cogoToast.error(
          "Something went wrong. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  onEnquiry = () => {
    return (
      <Dialog
        isOpen={this.state.showDialog}
        className="bp5-dark dialog"
        canEscapeKeyClose
        canOutsideClickClose
        onClose={() => this.setState({ showDialog: false })}
        title="Enquiry for New Patients"
      >
        <div
          style={{ padding: "20px", minWidth: "fit-content" }}
          className="dialog"
        >
          <FormGroup
            helperText="Phone Number"
            label="Phone"
            labelFor="text-input"
            inline
          >
            <InputGroup
              onChange={(event) =>
                this.setState({ newEnquiryNumber: event.target.value })
              }
            />
          </FormGroup>

          <FormGroup
            helperText="Type of Enquiry"
            label="Category"
            labelFor="text-input"
            inline
          >
            <MultiSelectWrapper
              items={[
                "Mediclaim Desk",
                "Surgery Counselling",
                "Surgery Confirmation",
                "Surgery Cancellation",
                "Clarification on Medicines",
                "Doctor Availability",
                "Hospital Timings",
                "Location of Hospital",
                "Cancellation of Apppointment",
                "Flying Patient Enquiry",
                "No Sound",
                "Other",
              ]}
              intent="success"
              placeholder="Search"
              selectedItems={[]}
              onChange={(items) => this.setState({ enquiryCategories: items })}
            />
          </FormGroup>
          <FormGroup
            helperText="Enter Notes Here."
            label="Comments"
            labelFor="text-input"
            inline
          >
            <TextArea
              onChange={(event) =>
                this.setState({ enquiryText: event.target.value })
              }
            />
          </FormGroup>
          <Button
            fill
            text="Log Query"
            intent="success"
            onClick={() => this.submitEnquiry()}
          />
        </div>
      </Dialog>
    );
  };
  handleSearch = async (arg) => {
    if (arg.length === 0) {
      this.setState({ searchWith: "", list: this.props.list });
      return;
    }
    if (arg.length < 4) return null;

    this.setState({ waiting: true });
    if (this.props.bookingStore.isList) {
      let qs = new QuickScore(this.props.list, [
        "name",
        "phone",
        "pid",
        "email",
        "sysPid",
        "text",
        "address",
        "docketNo",
        "referTo",
      ]);
      let results = qs.search(arg);

      let items = [];

      results.map((each) => items.push(each.item));
      this.setState({ list: items, waiting: false });
    } else {
      /** SEGMENT */
      if (new Date() - this.props.bookingStore.searchStart >= 60 * 1000 * 5) {
        this.props.bookingStore.searchesToAction = 0;
        this.props.bookingStore.searchStart = new Date();
        this.props.bookingStore.recordsViewed = 0;
        this.props.bookingStore.enquiryStart = new Date();
        this.props.bookingStore.enquiryEnd = new Date();
        this.props.bookingStore.bookingStart = new Date();
        this.props.bookingStore.datesSearched = 0;
        this.props.bookingStore.bookingEnd = new Date();
      }
      this.props.bookingStore.searchesToAction += 1;
      this.props.bookingStore.searchStart = new Date();
      /*   let results = await apiCallPostCancellable(
        `/mgmt/${this.props.authStore.prefix}/search/patient`,
        {
          prefix: arg,
        }
      ); */
      let results = await apiCallPostCancellable(
        `/rai/${this.props.authStore.prefix}/search`,
        {
          prefix: arg,
        }
      );
      let items = [];
      if (results) {
        results.map((each) => items.push(each));
        this.setState({ list: items, waiting: false });
      }
    }
  };
  renderPatientList = () => {
    if (this.state.waiting) {
      return (
        <div className="searchbar-help">
          <Spinner size={20} />
        </div>
      );
    }
    if (!this.state.list || !this.state.list.length) {
      return (
        <div className="searchbar-help">
          No Search Results. Please enter at least 4 characters.
        </div>
      );
    }
    let list = this.state.list;
    if (this.state.sortBy) {
      if (this.state.sortBy === "Alphabetically") {
        list = _.sortBy(list, (each) => each.name);
      }
      if (this.state.sortBy === "Registration Time") {
        list = _.sortBy(list, (each) => each.globalFlag.firstRegnTime);
      }
      if (this.state.sortBy === "Appointment Time") {
        list = _.sortBy(list, (each) => each.globalFlag.firstApptTime);
      }
    }

    return (
      <BookingThreadContainer
        key={Math.floor(Math.random() * 1000)}
        escape={() => this.setState({ searchWith: "", list: [] })}
        listOfThreads={list}
      />
    );
  };
  renderButtons = () => {
    if (this.props.bookingStore.isList) {
      return (
        <>
          <Tag
            icon="settings"
            minimal
            intent={this.state.sortBy ? "primary" : "none"}
            interactive
            onClick={() =>
              this.setState({ isSettingsOpen: !this.state.isSettingsOpen })
            }
          ></Tag>
          {this.state.isSettingsOpen ? (
            <>
              <Tag minimal>Sort By</Tag>
              <MutEx
                options={[
                  "Appointment Time",
                  "Registration Time",
                  "Alphabetically",
                ]}
                selected={this.state.sortBy}
                onUpdate={(item) => this.setState({ sortBy: item })}
                large={true}
              />
            </>
          ) : null}
          <Button
            text={` ${this.state.list.length} Result${
              this.state.list.length >= 1 ? "s" : ""
            }`}
            icon="refresh"
            minimal
            onClick={() => this.props.onRefresh("HARD")}
          />
        </>
      );
    }

    return (
      <>
        <Button
          text="New Enquiry"
          icon="help"
          minimal
          onClick={() => this.setState({ showDialog: true })}
        />
        {this.onEnquiry()}
        <Button
          text="New Booking"
          icon="add"
          minimal
          onClick={() => {
            cogoToast.info("Booking For A New Patient", {
              position: "bottom-left",
            });
            this.props.bookingStore.newPatient = true;
            this.props.patientStore.patientName = null;
            this.props.patientStore.genericPid = null;
            this.props.patientStore.patientSex = null;
            this.props.patientStore.patientAge = null;
            this.props.patientStore.patientPhone = null;
            this.props.patientStore.patientEmail = null;
            this.props.patientStore.panels = null;
            /** CHECK */
            this.props.bookingStore.whichPatientInFocus = {};
            this.props.bookingStore.isReschedule = false;
            this.props.bookingStore.rescheduleDocketNo = null;
            this.props.bookingStore.rescheduleDetails = {};

            this.setState({ redirect: "/booking" });
          }}
        />
      </>
    );
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <ControlGroup className="bp5-dark ">
          <InputGroup
            large
            fill
            className="bp5-dark"
            placeholder="Enter name, patient ID or phone number to start searching. Please
          enter at least 4 characters."
            onChange={(event) => this.handleSearch(event.target.value)}
            leftIcon="search"
          />
          {this.renderButtons()}
        </ControlGroup>

        {this.renderPatientList()}
      </div>
    );
  }
}
export default inject(
  "bookingStore",
  "authStore",
  "patientStore"
)(observer(Search));
