import React from "react";
import { generateMasterVisitEntities } from "./masterVisitEntities";
import {
  Button,
  Card,
  Collapse,
  ControlGroup,
  Elevation,
  Tag,
} from "@blueprintjs/core";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import ClinicalEntityRenderPreview from "./ClinicalEntityRenderPreview";
import { startLowerString } from "../fns/util";

class ClinicalSectionRenderPreview extends React.Component {
  state = { areDetailsOpen: true };
  masterVisitEntities = generateMasterVisitEntities(
    this.props.authStore.prefix
  );
  returnSectionNiceName = () => {
    let section = this.props.data[0].sectionName;
    let sectionDefinitions = this.masterVisitEntities;
    let whichSection = _.find(
      sectionDefinitions,
      (x) => x.sectionName === section
    );
    let nice = whichSection.sectionNiceName;
    return nice;
  };
  renderHeader = () => {
    return (
      <Card elevation={Elevation.FOUR} className="card__container">
        <div className="flex__push__apart">
          <ControlGroup>
            <Tag
              large
              className={
                this.state.areDetailsOpen ? "cerulean__bg" : "forest__bg"
              }
              intent="success"
            >
              <b>{this.returnSectionNiceName()}</b>
            </Tag>
          </ControlGroup>
          <ControlGroup>
            <Button
              onClick={() =>
                this.setState({
                  areDetailsOpen: !this.state.areDetailsOpen,
                })
              }
              icon={
                this.state.areDetailsOpen
                  ? "double-chevron-up"
                  : "double-chevron-down"
              }
            />
          </ControlGroup>
        </div>
      </Card>
    );
  };
  renderSubsections = () => {
    let subsections = _.uniq(this.props.data.map((v) => v.subsectionName));
    let section = this.props.data[0].sectionName;
    let sectionDefinitions = this.masterVisitEntities;
    let whichSection = _.find(
      sectionDefinitions,
      (x) => x.sectionName === section
    );
    let subsectionsList = whichSection.subsections;

    // sort subsections as per order in subsectionsList
    subsections.sort((a, b) => {
      let aIndex = _.findIndex(subsectionsList, (x) => x.subsectionName === a);
      let bIndex = _.findIndex(subsectionsList, (x) => x.subsectionName === b);
      return aIndex - bIndex;
    });
    return subsections.map((each, index) => {
      let whichSubsection = _.find(
        subsectionsList,
        (x) => x.subsectionName === each
      );
      let nice = whichSubsection.subsectionNiceName;

      let whichEntities = _.filter(
        this.props.data,
        (x) => x.subsectionName === each
      );

      return (
        <div style={{ marginBottom: "10px" }} key={index}>
          <ControlGroup>
            <Tag
              minimal
              large
              onClick={() => {
                let tabId = `${section}__${whichSubsection.subsectionName}`;
                this.props.onChoice(tabId);
              }}
            >
              {nice}
            </Tag>
          </ControlGroup>
          {nice === "Observations" ? (
            <>{this.renderObservationsTable(whichEntities, nice)}</>
          ) : (
            <div style={{ marginTop: "10px" }}>
              {whichEntities.map((each, entityIndex) => {
                return (
                  <ClinicalEntityRenderPreview
                    recentItems={this.props.recentItems}
                    key={entityIndex}
                    data={each}
                    onChoice={() => {
                      let tabId = `${section}__${whichSubsection.subsectionName}`;
                      this.props.onChoice(tabId);
                    }}
                    onRemove={() => this.props.onRemove(each._id)}
                  />
                );
              })}
            </div>
          )}
        </div>
      );
    });
  };
  transformObservations = (data) => {
    if (!data.length) {
      return {
        RIGHT: [],
        LEFT: [],
        OTHER: [],
      };
    }
    let transformedData = {};

    data.forEach((item) => {
      if (!transformedData[item.anatomicalPart]) {
        transformedData[item.anatomicalPart] = {
          RIGHT: [],
          LEFT: [],
          OTHER: [],
        };
      }

      if (item.laterality === "RIGHT" || item.laterality === "BILATERAL") {
        transformedData[item.anatomicalPart].RIGHT.push(item);
      }

      if (item.laterality === "LEFT" || item.laterality === "BILATERAL") {
        transformedData[item.anatomicalPart].LEFT.push(item);
      }

      if (
        item.laterality !== "RIGHT" &&
        item.laterality !== "LEFT" &&
        item.laterality !== "BILATERAL"
      ) {
        transformedData[item.anatomicalPart].OTHER.push(item);
      }
    });

    let sortOrder = [
      "EYE_GENERAL",
      "ORBIT",
      "EXTRA_OCULAR_MUSCLES",
      "LACRIMAL_SAC",
      "LIDS_AND_ADNEXA",
      "CONJUNCTIVA",
      "SCLERA",
      "CORNEA",
      "ANTERIOR_CHAMBER",
      "LENS",
      "IRIS",
      "PUPIL",
      "CHOROID",
      "CILIARY_BODY",
      "DISC",
      "UVEA",
      "VITREOUS",
      "RETINA",
      "MACULA",
    ];
    let sortedKeys = Object.keys(transformedData).sort((a, b) => {
      let aIndex = sortOrder.indexOf(a);
      let bIndex = sortOrder.indexOf(b);
      return aIndex - bIndex;
    });

    let sortedTransformedData = {};
    sortedKeys.forEach((key) => {
      sortedTransformedData[key] = transformedData[key];
    });

    return sortedTransformedData;
  };
  hasOtherData = (data) => {
    return Object.values(data).some((item) => item.OTHER.length > 0);
  };
  renderObservationsTable = (whichEntities) => {
    let transformedData = this.transformObservations(whichEntities);
    let showOtherColumn = this.hasOtherData(transformedData);
    if (!whichEntities.length) {
      return null;
    }
    let section = "EXAMINATION";
    let whichSubsection = {
      subsectionName: "OBSERVATIONS",
    };
    return (
      <table className="bp5-dark bp5-html-table bp5-compact bp5-html-table-bordered bp5-html-table-striped colfax table_vertical_align_center">
        <thead>
          <tr>
            <th></th>
            <th>
              <Tag minimal className="gray5__bg">
                RE
              </Tag>
            </th>
            <th>
              <Tag minimal className="gray5__bg">
                LE
              </Tag>
            </th>
            {showOtherColumn && (
              <th>
                <Tag minimal className="gray5__bg"></Tag>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {Object.keys(transformedData).map((anatomicalPart) => (
            <tr key={anatomicalPart}>
              <td>
                <Tag minimal className="lime_dark">
                  {startLowerString(anatomicalPart)}
                </Tag>
              </td>
              <td>
                {transformedData[anatomicalPart].RIGHT.map((entity, index) => (
                  <ClinicalEntityRenderPreview
                    onChoice={() => {
                      let tabId = `${section}__${whichSubsection.subsectionName}`;
                      this.props.onChoice(tabId);
                    }}
                    recentItems={this.props.recentItems}
                    isShort={true}
                    key={index}
                    data={entity}
                    onRemove={() => this.props.onRemove(entity._id, "RIGHT")}
                  />
                ))}
              </td>
              <td>
                {transformedData[anatomicalPart].LEFT.map((entity, index) => (
                  <ClinicalEntityRenderPreview
                    onChoice={() => {
                      let tabId = `${section}__${whichSubsection.subsectionName}`;
                      this.props.onChoice(tabId);
                    }}
                    recentItems={this.props.recentItems}
                    isShort={true}
                    key={index}
                    data={entity}
                    onRemove={() => this.props.onRemove(entity._id, "LEFT")}
                  />
                ))}
              </td>
              {showOtherColumn && (
                <td>
                  {transformedData[anatomicalPart].OTHER.map(
                    (entity, index) => (
                      <ClinicalEntityRenderPreview
                        onChoice={() => {
                          let tabId = `${section}__${whichSubsection.subsectionName}`;
                          this.props.onChoice(tabId);
                        }}
                        recentItems={this.props.recentItems}
                        isShort={true}
                        key={index}
                        data={entity}
                        onRemove={() => this.props.onRemove(entity._id)}
                      />
                    )
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  render() {
    return (
      <div>
        {this.renderHeader()}
        <Collapse isOpen={this.state.areDetailsOpen}>
          <Card elevation={Elevation.FOUR} className="card__container">
            {this.renderSubsections()}
          </Card>
        </Collapse>
      </div>
    );
  }
}
// export default ClinicalSectionRenderPreview;
export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(ClinicalSectionRenderPreview));
