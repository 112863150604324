import React from "react";
import {
  Button,
  Card,
  Collapse,
  ControlGroup,
  Elevation,
  Tag,
} from "@blueprintjs/core";
import _ from "lodash";
import { nanoid } from "nanoid";

class FilesPreview extends React.Component {
  state = { areDetailsOpen: true };

  renderHeader = () => {
    return (
      <Card elevation={Elevation.FOUR} className="card__container">
        <div className="flex__push__apart">
          <ControlGroup>
            <Tag
              large
              className={
                this.state.areDetailsOpen ? "cerulean__bg" : "forest__bg"
              }
              intent="success"
              onClick={() => {
                this.props.onChoice("FILES");
              }}
            >
              <b>Investigations & Assets</b>
            </Tag>
          </ControlGroup>
          <ControlGroup>
            <Button
              onClick={() =>
                this.setState({
                  areDetailsOpen: !this.state.areDetailsOpen,
                })
              }
              icon={
                this.state.areDetailsOpen
                  ? "double-chevron-up"
                  : "double-chevron-down"
              }
            />
          </ControlGroup>
        </div>
      </Card>
    );
  };
  renderEachInvestigation = () => {
    let files = this.props.data;
    return files.map((each, index) => {
      return (
        <div
          key={`${each.updatedAt}__${index}`}
          onClick={() => this.props.onChoice("FILES")}
        >
          <div
            className="colfax"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              textAlign: "left",
              fontSize: "small",
            }}
          >
            <div className="flex_box_wrap">
              {each.laterality === "LEFT"
                ? "LE"
                : each.laterality === "RIGHT"
                ? "RE"
                : each.laterality === "BILATERAL"
                ? "BE"
                : ""}
              <Tag minimal className="no__bg" multiline>
                {each.fileFrom}
              </Tag>
            </div>{" "}
          </div>
          {each.notes && (
            <div
              style={{
                fontSize: "smaller",
                textAlign: "left",
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                marginLeft: "40px",
              }}
            >
              <Tag minimal className="no__bg" multiline>
                {each.notes}
              </Tag>
            </div>
          )}
          {each.data.length > 0 && (
            <div
              style={{
                fontSize: "smaller",
                textAlign: "left",
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                marginLeft: "40px",
              }}
            >
              {each.data.map((x) => {
                if (x.value) {
                  return (
                    <div>
                      <ControlGroup>
                        <Tag minimal>
                          {x.key}: {x.value} {x.unit}
                        </Tag>
                      </ControlGroup>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      );
    });
  };
  render() {
    return (
      <div key={nanoid()}>
        {this.renderHeader()}
        <Collapse isOpen={this.state.areDetailsOpen}>
          <Card elevation={Elevation.FOUR} className="card__container">
            {this.renderEachInvestigation()}
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default FilesPreview;
