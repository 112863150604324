import {
  ControlGroup,
  InputGroup,
  Elevation,
  Card,
  Button,
  Tag,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import React from "react";
import { apiCallPost } from "../fns/util";
import UserCard from "./UserCard";
import { inject, observer } from "mobx-react";
import cogoToast from "cogo-toast";
import _ from "lodash";
import { toJS } from "mobx";
import { QuickScore } from "quick-score";
class UserManagement extends React.Component {
  state = {
    searchText: null,
    newEmail: null,
    newName: null,
    userList: [],
    list: [],
  };
  componentDidMount() {
    this.getUsers();
  }

  getUsers = async () => {
    try {
      cogoToast.loading("Fetching users...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/users/${this.props.authStore.prefix}/get`,
        {}
      );
      cogoToast.success("Fetched users.", { position: "bottom-left" });

      this.setState({ userList: res, list: res });
    } catch (err) {
      cogoToast.error(
        "There was an error in fetching users. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  setPrefs = async (_id, name, value) => {
    try {
      cogoToast.loading("Updating preferences...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/users/${this.props.authStore.prefix}/setPrefs`,
        { _id: _id, name: `${name}.value`, value: value }
      );
      cogoToast.success("Updated preference.", { position: "bottom-left" });
      this.refreshUser(_id, res);
    } catch (err) {
      cogoToast.error(
        "There was an error in updating preferences. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  refreshUser = (id, object) => {
    let currentList = this.state.userList;
    let idx = _.findIndex(currentList, (each) => each._id === id);
    currentList[idx] = object;
    this.setState({ userList: currentList });
  };
  toggleSuspension = async (id) => {
    try {
      cogoToast.loading("Changing status...", { position: "bottom-left" });

      let res = await apiCallPost(
        `/users/${this.props.authStore.prefix}/toggleSuspension`,
        { _id: id }
      );
      cogoToast.success("Changed status.", { position: "bottom-left" });

      this.refreshUser(id, res);
    } catch (err) {
      cogoToast.error(
        "There was an error in changing status. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  inviteNewUser = async () => {
    if (!this.state.newEmail) {
      cogoToast.error("It seems like the email entered is blank.", {
        position: "bottom-left",
      });
      return;
    }
    if (!this.state.newName) {
      cogoToast.error("It seems like the name entered is blank.", {
        position: "bottom-left",
      });
      return;
    }
    try {
      cogoToast.loading("Adding new user...", { position: "bottom-left" });

      let res = await apiCallPost(
        `/users/${this.props.authStore.prefix}/invite`,
        { email: this.state.newEmail, name: this.state.newName }
      );
      cogoToast.success("Added new user.", { position: "bottom-left" });
      let currentList = this.state.userList;
      currentList.unshift(res);
      this.setState({ newEmail: null, userList: currentList });
    } catch (err) {
      cogoToast.error(
        "There was an error in adding new user. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  handleSearch = async (arg) => {
    if (arg.length === 0) {
      this.setState({ searchText: "", list: this.state.userList });
      return;
    }
    if (arg.length < 4) return null;

    this.setState({ waiting: true });

    let qs = new QuickScore(this.state.userList, ["email", "name"]);
    let results = qs.search(arg);

    let items = [];

    results.map((each) => items.push(each.item));
    this.setState({ list: items, waiting: false });
  };

  renderUserCards = () => {
    if (this.state.waiting) {
      return (
        <NonIdealState
          className="bp5-dark"
          icon={<Spinner />}
          title="Searching..."
          description={`Searching through ${this.state.userList.length} users...`}
        />
      );
    }
    if (!this.state.userList.length) {
      return (
        <NonIdealState
          className="bp5-dark"
          icon={<Spinner />}
          title="Loading..."
          description="Fetching data..."
        />
      );
    }

    return this.state.list.map((each) => {
      let scopes = toJS(this.props.authStore.scope);
      return (
        <UserCard
          user={each}
          myScope={scopes}
          toggle={(id) => this.toggleSuspension(id)}
          scopeChange={(id, items) => this.updateScopes(id, items)}
          updatePrefs={this.setPrefs}
        />
      );
    });
  };
  updateScopes = async (id, items) => {
    try {
      cogoToast.loading("Updating scope...", { position: "bottom-left" });

      let res = await apiCallPost(
        `/users/${this.props.authStore.prefix}/updateScope`,
        { _id: id, scopes: items }
      );
      cogoToast.success("Updated scope", { position: "bottom-left" });

      this.refreshUser(id, res);
    } catch (err) {
      cogoToast.error(
        "There was an error in updating scopes. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  render() {
    let scopes = toJS(this.props.authStore.scope);
    return (
      <div>
        {scopes.includes("users") ? (
          <Card
            style={{
              backgroundColor: "#17191A",
              margin: "30px",
            }}
            className="bp5-dark"
            elevation={Elevation.THREE}
          >
            <ControlGroup>
              <Tag minimal>Search Existing User</Tag>
              <InputGroup
                fill
                placeholder="Please enter at least 4 characters to start searching..."
                value={this.state.searchText}
                rightElement={
                  <Tag minimal>
                    # of Users Loaded: {this.state.userList.length}
                  </Tag>
                }
                onChange={(event) =>
                  this.setState(
                    {
                      searchText: event.target.value,
                    },
                    () => this.handleSearch(event.target.value)
                  )
                }
              />
              <Tag minimal>Add New User</Tag>
              <InputGroup
                value={this.state.newName}
                placeholder="Name"
                onChange={(event) =>
                  this.setState({ newName: event.target.value })
                }
              />
              <InputGroup
                value={this.state.newEmail}
                placeholder="Email"
                onChange={(event) =>
                  this.setState({ newEmail: event.target.value })
                }
              />
              <Button
                minimal
                icon="new-person"
                intent="success"
                text="Invite User"
                onClick={() => this.inviteNewUser()}
              />
            </ControlGroup>
          </Card>
        ) : null}
        {this.renderUserCards()}
      </div>
    );
  }
}

export default inject("bookingStore", "authStore")(observer(UserManagement));
