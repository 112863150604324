import React, { useState, useEffect } from "react";
import printDoc from "./printDoc";

const PDFExportPanel = (props) => {
  const [PDF_PAGE_ORITENTATION] = useState("landscape");
  const [PDF_WITH_HEADER_IMAGE] = useState(false);
  const [PDF_WITH_FOOTER_PAGE_COUNT] = useState(true);
  const [PDF_HEADER_HEIGHT] = useState(12);
  const [PDF_ROW_HEIGHT] = useState(10);

  const [PDF_ODD_BKG_COLOR] = useState("#fcfcfc");
  const [PDF_EVEN_BKG_COLOR] = useState("#fff");
  const [PDF_WITH_CELL_FORMATTING] = useState(true);
  const [PDF_WITH_COLUMNS_AS_LINKS] = useState(true);

  const [PDF_SELECTED_ROWS_ONLY] = useState(false);

  const PDF_HEADER_COLOR = "#f8f8f8";
  const PDF_INNER_BORDER_COLOR = "#dde2eb";
  const PDF_OUTER_BORDER_COLOR = "#babfc7";
  const PDF_LOGO = "";

  useEffect(() => {}, []);

  const submitFormHandler = (event) => {
    event.preventDefault();

    const printParams = {
      PDF_HEADER_COLOR,
      PDF_INNER_BORDER_COLOR,
      PDF_OUTER_BORDER_COLOR,
      PDF_LOGO,
      PDF_PAGE_ORITENTATION,
      PDF_WITH_HEADER_IMAGE,
      PDF_WITH_FOOTER_PAGE_COUNT,
      PDF_HEADER_HEIGHT,
      PDF_ROW_HEIGHT,
      PDF_ODD_BKG_COLOR,
      PDF_EVEN_BKG_COLOR,
      PDF_WITH_CELL_FORMATTING,
      PDF_WITH_COLUMNS_AS_LINKS,
      PDF_SELECTED_ROWS_ONLY,
    };

    printDoc(printParams, props.gridApi, props.columnApi, props.typeOfGrid);
  };

  return (
    <form onSubmit={submitFormHandler}>
      <button type="submit" className="bp5-button">
        Export to PDF
      </button>
    </form>
  );
};

export default PDFExportPanel;
