import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  InputGroup,
  NumericInput,
  Tag,
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import cogoToast from "cogo-toast";
import React from "react";
import { niceDate } from "../fns/util";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";

const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};
class CapturePayment extends React.Component {
  state = {
    loading: false,
    site: null,
    amount: null,
    mode: null,
    last4: null,
    cardName: null,
    chqNo: null,
    chqDate: null,
    phone: null,
  };

  paymentModes = ["Cash", "Card", "Cheque", "UPI", "PayTM"];
  componentDidMount() {
    this.setState({ amount: this.props.max / 100 });
  }
  renderDetail = () => {
    if (!this.state.mode) {
      return null;
    }
    if (this.state.mode === "Cash") {
      return;
    }
    if (this.state.mode === "Card") {
      return (
        <>
          <Tag large minimal>
            Last 4 Digits
          </Tag>
          <InputGroup
            onChange={(event) => this.setState({ last4: event.target.value })}
          />
          <Tag large minimal>
            Cardholder Name
          </Tag>
          <InputGroup
            onChange={(event) =>
              this.setState({ cardName: event.target.value })
            }
          />
        </>
      );
    }
    if (this.state.mode === "Cheque") {
      return (
        <>
          <Tag large minimal>
            Cheque Number
          </Tag>
          <InputGroup
            onChange={(event) => this.setState({ chqNo: event.target.value })}
          />
          <Tag large minimal>
            Dated
          </Tag>
          <DateInput
            formatDate={(date) => niceDate(date)}
            parseDate={(str) => new Date(str)}
            onChange={(value) => this.setState({ chqDate: value })}
            maxDate={new Date()}
            placeholder="Choose Date"
          />
        </>
      );
    }
    if (this.state.mode === "UPI" || this.state.mode === "PayTM") {
      return (
        <>
          <Tag large minimal>
            Linked Phone Number
          </Tag>
          <InputGroup
            onChange={(event) => this.setState({ phone: event.target.value })}
          />
        </>
      );
    }
  };
  addPayment = () => {
    if (!this.state.amount) {
      cogoToast.error("Please enter a valid amount!", {
        position: "bottom-left",
      });
      this.setState({ loading: false });
      return;
    }
    if (this.props.sites.length >= 2 && !this.state.site) {
      cogoToast.error("Please enter a valid site!", {
        position: "bottom-left",
      });
      this.setState({ loading: false });
      return;
    }
    if (!this.state.mode) {
      cogoToast.error("Please enter a valid payment mode!", {
        position: "bottom-left",
      });
      this.setState({ loading: false });
      return;
    }
    if (this.state.mode === "UPI" && !this.state.phone) {
      cogoToast.error("Please enter a valid phone number!", {
        position: "bottom-left",
      });
      this.setState({ loading: false });
      return;
    }
    if (this.state.mode === "PayTM" && !this.state.phone) {
      cogoToast.error("Please enter a valid phone number!", {
        position: "bottom-left",
      });
      this.setState({ loading: false });
      return;
    }
    if (this.state.mode === "Card" && !this.state.last4) {
      cogoToast.error("Please enter the last 4 digits of the card!", {
        position: "bottom-left",
      });
      this.setState({ loading: false });
      return;
    }
    if (this.state.mode === "Card" && !this.state.cardName) {
      cogoToast.error("Please enter the name of the cardholder!", {
        position: "bottom-left",
      });
      this.setState({ loading: false });
      return;
    }
    if (this.state.mode === "Cheque" && !this.state.chqNo) {
      cogoToast.error("Please enter the cheque number!", {
        position: "bottom-left",
      });
      this.setState({ loading: false });
      return;
    }
    if (this.state.mode === "Cheque" && !this.state.chqDate) {
      cogoToast.error("Please enter the date of the cheque!", {
        position: "bottom-left",
      });
      this.setState({ loading: false });
      return;
    }
    let reference = {};
    if (this.state.mode === "Card") {
      reference["last4"] = this.state.last4;
      reference["cardName"] = this.state.cardName;
    }
    if (this.state.mode === "Cheque") {
      reference["chqNo"] = this.state.chqNo;
      reference["chqDate"] = this.state.chqDate;
    }
    if (this.state.mode === "UPI" || this.state.mode === "PayTM") {
      reference["phone"] = this.state.phone;
    }
    let payload = {
      amountPaise: this.state.amount * 100,
      reference: reference,
      channel: this.state.mode,
      site: this.state.site || this.props.sites[0],
    };
    this.props.onPayment(payload);
  };
  addPaymentDebounced = debounce(this.addPayment, 1000);
  renderAction = () => {
    if (!this.state.mode) {
      return null;
    }
    if (!this.state.amount) {
      return null;
    }

    if (this.state.mode === "Cash") {
      return (
        <Button
          loading={this.state.loading}
          rightIcon="arrow-right"
          intent="success"
          text="Confirm Cash Receipt"
          onClick={() => {
            this.setState({ loading: true }, () => this.addPaymentDebounced());
          }}
        />
      );
    }

    if (this.state.mode === "Card" && this.state.cardName && this.state.last4) {
      return (
        <Button
          loading={this.state.loading}
          rightIcon="arrow-right"
          intent="success"
          text="Confirm Card Transaction"
          onClick={() => {
            this.setState({ loading: true }, () => this.addPaymentDebounced());
          }}
        />
      );
    }
    if (
      this.state.mode === "Cheque" &&
      this.state.chqDate &&
      this.state.chqNo
    ) {
      return (
        <Button
          loading={this.state.loading}
          rightIcon="arrow-right"
          intent="success"
          text="Confirm Cheque Receipt"
          onClick={() => {
            this.setState({ loading: true }, () => this.addPaymentDebounced());
          }}
        />
      );
    }
    if (this.state.mode === "UPI" && this.state.phone) {
      return (
        <Button
          loading={this.state.loading}
          rightIcon="arrow-right"
          intent="success"
          text="Confirm UPI Payment"
          onClick={() => {
            this.setState({ loading: true }, () => this.addPaymentDebounced());
          }}
        />
      );
    }
    if (this.state.mode === "PayTM" && this.state.phone) {
      return (
        <Button
          loading={this.state.loading}
          rightIcon="arrow-right"
          intent="success"
          text="Confirm PayTM Payment"
          onClick={() => {
            this.setState({ loading: true }, () => this.addPaymentDebounced());
          }}
        />
      );
    }
  };
  renderSiteSelector = () => {
    if (this.props.sites.length >= 2) {
      return (
        <>
          {" "}
          <Tag large minimal>
            Site
          </Tag>
          <SingleSelectWrapper
            className="bp5-dark"
            items={this.props.sites}
            onChange={(item) => {
              this.setState({ site: item });
            }}
          />
        </>
      );
    }
    return null;
  };
  render() {
    return (
      <Card
        className="bp5-dark"
        style={{
          backgroundColor: "#464B53",
          margin: "10px",
          textAlign: "left",
        }}
        elevation={Elevation.THREE}
      >
        <ControlGroup vertical={this.props.isVertical}>
          <Tag large intent="primary">
            Capture Payment
          </Tag>
          <Tag large minimal>
            Amount
          </Tag>
          <NumericInput
            buttonPosition="none"
            minorStepSize={0.01}
            min={0}
            max={this.props.max / 100}
            clampValueOnBlur
            intent="primary"
            value={this.state.amount}
            onValueChange={(value) => this.setState({ amount: value })}
          />
          {this.renderSiteSelector()}
          <Tag large minimal>
            Mode of Payment
          </Tag>
          <SingleSelectWrapper
            className="bp5-dark"
            items={this.paymentModes}
            onChange={(item) => {
              this.setState({ mode: item });
            }}
          />
          {this.renderDetail()}
          {this.renderAction()}
        </ControlGroup>
      </Card>
    );
  }
}

export default CapturePayment;
