import {
  Card,
  Collapse,
  ControlGroup,
  Elevation,
  Tag,
  EditableText,
  Button,
} from "@blueprintjs/core";
import React from "react";
import AsyncSelWrap from "../../utils/AsyncSelWrap";
import _ from "lodash";
import Finding from "./Finding";
import { apiCallPost } from "../../fns/util";
import { inject, observer } from "mobx-react";
import cogoToast from "cogo-toast";
import MutMult from "../../utils/MutMult";
import SuggColl from "../SuggColl";
import SuggCollClick from "../SuggCollClick";

class Objective extends React.Component {
  state = {
    isOpen: false,
    collapse: true,
    showWnls: true,
    searchResultsLe: [],
    searchingLe: false,
    searchResultsRe: [],
    searchingRe: false,
    searchResultsBe: [],
    searchingBe: false,
  };
  normals = {
    "Extraocular Muscles": "Movements Full and Free",
    "Lids and Adnexa": "WNL",
    Conjunctiva: "WNL",
    Sclera: "WNL",
    Cornea: "Clear",
    "Anterior Chamber": "Deep and Quiet",
    Iris: "WNL",
    Lens: "Clear",
    Vitreous: "Clear",
    Retina: "WNL",
    Disc: "WNL",
  };

  renderHeading = () => {
    return (
      <ControlGroup>
        <Tag
          large
          intent="primary"
          interactive
          onClick={() => this.setState({ collapse: !this.state.collapse })}
        >
          Observations
        </Tag>
        {this.state.collapse ? (
          <>
            {" "}
            <AsyncSelWrap
              placeholder="Right Eye Signs..."
              searching={this.state.searchingRe}
              items={this.state.searchResultsRe}
              selectedItem={null}
              update={(item) =>
                this.search(item, "searchResultsRe", "searchingRe")
              }
              onChange={(item) => {
                let dd = item.data;
                dd["_id_sr"] = item._id;
                dd["type"] = "new";
                dd["checked"] = false;
                dd["site"] = "Right Eye";
                dd["sinceLastVisit"] = null;
                dd["anatomy"] = item.helper;
                dd["listOfQualifiers"] = item.listOfQualifiers;
                dd["qualifier"] = item.helper2;
                this.props.addObjectInside("findings", dd, item.helper);
              }}
            />
            <AsyncSelWrap
              placeholder="Both Eyes Signs..."
              searching={this.state.searchingBe}
              items={this.state.searchResultsBe}
              selectedItem={null}
              update={(item) =>
                this.search(item, "searchResultsBe", "searchingBe")
              }
              onChange={(item) => {
                let dd = item.data;
                dd["_id_sr"] = item._id;
                dd["type"] = "new";
                dd["checked"] = false;
                dd["site"] = "Left Eye";
                dd["anatomy"] = item.helper;
                dd["sinceLastVisit"] = null;
                dd["listOfQualifiers"] = item.listOfQualifiers;
                dd["qualifier"] = item.helper2;
                this.props.addObjectInside("findings", dd, item.helper);
                let cc = _.cloneDeep(dd);
                cc["site"] = "Right Eye";
                this.props.addObjectInside("findings", cc, item.helper);
              }}
            />
            <AsyncSelWrap
              placeholder="Left Eye Signs..."
              searching={this.state.searchingLe}
              items={this.state.searchResultsLe}
              selectedItem={null}
              update={(item) =>
                this.search(item, "searchResultsLe", "searchingLe")
              }
              onChange={(item) => {
                let dd = item.data;
                dd["_id_sr"] = item._id;
                dd["type"] = "new";
                dd["checked"] = false;
                dd["site"] = "Left Eye";
                dd["sinceLastVisit"] = null;
                dd["anatomy"] = item.helper;
                dd["listOfQualifiers"] = item.listOfQualifiers;
                dd["qualifier"] = item.helper2;
                this.props.addObjectInside("findings", dd, item.helper);
              }}
            />
            <Tag minimal>Normal: </Tag>
            <MutMult
              options={this.props.parts}
              selected={this.props.normies}
              onUpdate={(items) => {
                this.props.mutMultUpdate("areSectionsNormal", items);
              }}
            />
            <Button
              minimal
              icon="trash"
              text="Remove Previous"
              intent="danger"
              onClick={() => this.props.removeAllPrevious()}
            />
          </>
        ) : (
          this.renderShort()
        )}
      </ControlGroup>
    );
  };
  renderTable = () => {
    let data = this.props.data;
    let anats = _.keys(data);
    let hasSomething = false;
    for (var i = 0; i < anats.length; i++) {
      if (data[anats[i]].length) {
        hasSomething = true;
      }
    }
    if (!hasSomething) {
      return null;
    }
    return (
      <table
        className="bp5-html-table   bp5-html-table-striped"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th>Anatomy</th>
            <th>Right Eye</th>
            <th>Left Eye</th>
          </tr>
        </thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  };
  renderEachCell = (list, anat) => {
    return list.map((each) => {
      return (
        <Finding
          data={each}
          editObjectInside={(elementId, key, value) =>
            this.props.editObjectInside(
              ["findings", anat],
              elementId,
              key,
              value
            )
          }
          duplicate={() => {
            let dd = _.cloneDeep(each);
            dd["site"] = each.site === "Right Eye" ? "Left Eye" : "Right Eye";
            this.props.addObjectInside("findings", dd, anat);
          }}
          remove={(findingId) =>
            this.props.remove(["findings", anat], findingId)
          }
        />
      );
    });
  };
  renderFindings = (which, anat) => {
    let right = _.filter(anat, (each) => each.site === "Right Eye");
    let left = _.filter(anat, (each) => each.site === "Left Eye");
    return (
      <tr>
        <td>{which}</td>
        <td>
          <ControlGroup vertical>
            {this.renderEachCell(right, which)}
          </ControlGroup>
        </td>
        <td>
          <ControlGroup vertical>
            {this.renderEachCell(left, which)}
          </ControlGroup>
        </td>
      </tr>
    );
  };
  renderEachNoFinding = (each) => {
    return (
      <div style={{ marginRight: "5px" }}>
        <ControlGroup>
          <SuggCollClick
            text={each}
            onSingleClick={() => {
              let dd = {
                type: "new",
                item: this.normals[each],
                anatomy: each,
                _id_sr: "Normal",
                site: "Left Eye",
                checked: false,
                qualifier: null,
                listOfQualifiers: [],
              };
              this.props.addObjectInside("findings", dd, each);
            }}
            onDoubleClick={() => {
              let dd = {
                type: "new",
                item: this.normals[each],
                anatomy: each,
                _id_sr: "Normal",
                site: "Right Eye",
                checked: false,
                qualifier: null,
                listOfQualifiers: [],
              };
              this.props.addObjectInside("findings", dd, each);
            }}
          />
          <SuggCollClick
            text={null}
            icon="eye-off"
            onSingleClick={() => {
              let dd = {
                type: "new",
                item: "No View of " + each,
                anatomy: each,
                _id_sr: "No View",
                site: "Left Eye",
                checked: false,
                qualifier: null,
                listOfQualifiers: [],
              };
              this.props.addObjectInside("findings", dd, each);
            }}
            onDoubleClick={() => {
              let dd = {
                type: "new",
                item: "No View of " + each,
                anatomy: each,
                _id_sr: "No View",
                site: "Right Eye",
                checked: false,
                qualifier: null,
                listOfQualifiers: [],
              };
              this.props.addObjectInside("findings", dd, each);
            }}
          />
        </ControlGroup>
      </div>
    );
  };
  renderNoFindings = (list) => {
    if (!this.state.showWnls) {
      return null;
    }
    return list.map((each) => {
      return this.renderEachNoFinding(each);
    });
  };
  renderRows = () => {
    let data = this.props.data;
    let anatomies = _.keys(data);
    return anatomies.map((each) => {
      if (data[each].length) {
        return this.renderFindings(each, data[each]);
      } else {
        return null;
      }
    });
  };
  renderFooter = () => {
    let data = this.props.data;
    let anatomies = _.keys(data);
    let wnls = [];
    for (var i = 0; i < anatomies.length; i++) {
      if (!data[anatomies[i]].length) {
        wnls.push(anatomies[i]);
      }
    }

    if (!wnls.length) {
      return null;
    }
    return (
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
        <Tag
          minimal
          large
          interactive
          onClick={() => this.setState({ showWnls: !this.state.showWnls })}
        >
          No View/WNL
        </Tag>
        &nbsp; &nbsp;
        {this.renderNoFindings(wnls)}
      </div>
    );
  };
  renderShort = () => {
    let data = this.props.data;
    let kk = _.keys(data);
    let list = [];

    for (var i = 0; i < kk.length; i++) {
      let anat = data[kk[i]];
      let elems = anat.map((each) => each.item);
      list = _.concat(list, elems);
    }
    list = _.uniq(list);
    if (!list.length) {
      return (
        <Tag minimal large>
          No findings added.
        </Tag>
      );
    }
    let txt = list.join(", ");
    return (
      <Tag minimal large multiline>
        {txt}
      </Tag>
    );
  };
  search = async (string, sr, sing) => {
    if (!string) {
      this.setState({ [sr]: [] });
      return;
    }
    try {
      this.setState({ [sing]: true, [sr]: [] }, async () => {
        let res = await apiCallPost(
          `/clinical/${this.props.authStore.prefix}/unisearch`,
          { prefix: "sign", query: string }
        );
        this.setState({ [sr]: res, [sing]: false });
      });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  renderNotes = () => {
    return (
      <div
        style={{ textAlign: "left", marginTop: "10px", marginBottom: "10px" }}
      >
        <EditableText
          multiline
          placeholder="You can use this space to keep your notes about the progression of the signs, or anything worth mentioning that you have noticed."
          value={
            this.state.notesTouched
              ? this.state.notesText
              : this.props.findingNotes[0].text
          }
          onChange={(event) =>
            this.setState({ notesText: event, notesTouched: true })
          }
          onConfirm={() =>
            this.props.update(
              "findingNotes",
              this.props.findingNotes[0]._id,
              "text",
              this.state.notesTouched
                ? this.state.notesText
                : this.props.findingNotes[0].text
            )
          }
        />
      </div>
    );
  };
  render() {
    return (
      <Card
        style={{ padding: "0px", background: "none" }}
        elevation={Elevation.FOUR}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "start",
            justifyContent: "left",
            padding: "10px",
          }}
        >
          {this.renderHeading()}
          <Collapse isOpen={this.state.collapse}>
            <SuggColl
              ana_prefix=""
              prefix="sign"
              visitId={this.props.visitId}
              addObjectInside={this.props.addObjectInside}
            />
            {this.renderTable()}
            {this.renderFooter()}
            {this.renderNotes()}
          </Collapse>
        </div>
      </Card>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(Objective));
