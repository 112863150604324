import React from "react";
import { Button, ControlGroup, FileInput, Tag } from "@blueprintjs/core";
import RecordRTC from "recordrtc";
import cogoToast from "cogo-toast";
import TextWithEllipsis from "../TextWithEllipsis";

class TurboAudio extends React.Component {
  state = {
    isRecording: false,
    isPaused: false,
    recordedBlob: null,
    recordingTime: 0,
    selectedFile: null,
  };

  startRecording = () => {
    this.setState({ isRecording: true, isPaused: false });
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.recordRTC = new RecordRTC(stream, {
          type: "audio",
          disableLogs: true,
        });
        this.recordRTC.startRecording();
        this.startTimer();
      })
      .catch((err) => {
        cogoToast.error("Failed to start recording.");
      });
  };
  stopRecording = () => {
    this.setState({ isRecording: false, isPaused: false });
    this.stopTimer();
    this.recordRTC.stopRecording(() => {
      let blob = this.recordRTC.getBlob();
      let url = URL.createObjectURL(blob);
      this.setState({ recordedBlob: blob, recordedUrl: url }, () => {
        this.props.onAudioFile(
          this.state.recordedBlob,
          this.state.recordingTime
        );
      });
    });
  };

  pauseRecording = () => {
    this.setState({ isPaused: true });
    this.recordRTC.pauseRecording();
    this.stopTimer();
  };

  resumeRecording = () => {
    this.setState({ isPaused: false });
    this.recordRTC.resumeRecording();
    this.startTimer();
  };

  startTimer = () => {
    this.timerID = setInterval(() => {
      this.setState({ recordingTime: this.state.recordingTime + 1 });
    }, 1000);
  };

  stopTimer = () => {
    clearInterval(this.timerID);
    this.timerID = null;
  };

  formatTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${seconds}`;
  };

  handleFileChange = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
  };

  handleFileGo = () => {
    this.props.onAudioFile(this.state.selectedFile);
  };

  handleFileDelete = () => {
    this.setState({ selectedFile: null });
  };

  render() {
    const { isRecording, isPaused, recordingTime, selectedFile } = this.state;
    const { hasAudioFileArrived } = this.props;

    return (
      <ControlGroup style={{ marginTop: "10px" }}>
        {!isRecording && !selectedFile && (
          <Button
            onClick={this.startRecording}
            disabled={hasAudioFileArrived}
            className="colfax"
            icon="record"
            intent="danger"
          >
            Start Recording
          </Button>
        )}

        {!isRecording && !selectedFile && (
          <ControlGroup>
            <Tag minimal>or</Tag>
            <FileInput
              className="colfax"
              text="Upload Audio..."
              onInputChange={this.handleFileChange}
              disabled={hasAudioFileArrived}
            />
          </ControlGroup>
        )}

        {selectedFile && (
          <ControlGroup>
            <Button
              onClick={this.handleFileGo}
              disabled={hasAudioFileArrived}
              className="colfax cerulean__bg"
              icon="cloud-upload"
              intent="success"
            >
              Upload {this.state.selectedFile.name} and start!
            </Button>
            <Button
              className="colfax"
              icon="trash"
              onClick={this.handleFileDelete}
              disabled={hasAudioFileArrived}
              intent="danger"
            ></Button>
          </ControlGroup>
        )}

        {isRecording && !isPaused && (
          <ControlGroup>
            <Tag className="colfax" minimal>
              {" "}
              <TextWithEllipsis text="Recording" />
              {this.formatTime(recordingTime)}
            </Tag>
            <Button onClick={this.pauseRecording} icon="pause" intent="warning">
              Pause
            </Button>
            <Button
              onClick={this.stopRecording}
              icon="endorsed"
              intent="success"
              className="lime__bg colfax"
            >
              Done
            </Button>
          </ControlGroup>
        )}

        {isPaused && (
          <ControlGroup>
            <Tag className="colfax" minimal>
              Paused at {this.formatTime(recordingTime)}
            </Tag>
            <Button
              className="colfax"
              onClick={this.resumeRecording}
              intent="success"
              icon="play"
            >
              Resume
            </Button>
            <Button
              onClick={this.stopRecording}
              icon="endorsed"
              intent="success"
              className="lime__bg colfax"
            >
              Done
            </Button>
          </ControlGroup>
        )}
      </ControlGroup>
    );
  }
}

export default TurboAudio;
