import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class VASelector extends React.Component {
  state = {
    isEditing: false,
  };

  listOfVAs = [
    "6/6",
    "6/6P",
    "6/9",
    "6/9P",
    "6/12",
    "6/12P",
    "6/18",
    "6/18P",
    "6/24",
    "6/24P",
    "6/36",
    "6/36P",
    "6/60",
    "6/60P",
    "5/60",
    "5/60P",
    "4/60",
    "4/60P",
    "3/60",
    "3/60P",
    "2/60",
    "2/60P",
    "1/60",
    "1/60P",
    "FC 3m",
    "FC 2m",
    "FC 1m",
    "FC 1/2m",
    "FCCF",
    "HMCF",
    "PL+ PR accurate",
    "PL+ PR inaccurate",
    "PL+ PR-",
    "PL- PR accurate",
    "PL- PR inaccurate",
    "PL- PR-",
  ];
  listOfNear = [
    "N5",
    "N6",
    "N8",
    "N10",
    "N12",
    "N14",
    "N18",
    "N24",
    "N36",
    "N48",
    "N5P",
    "N6P",
    "N8P",
    "N10P",
    "N12P",
    "N14P",
    "N18P",
    "N24P",
    "N36P",
    "N48P",
  ];

  listOfCG = [
    "New",
    "Less than 6 months",
    "Less than 1 year",
    "Less than 2 years",
    "More than 2 years",
  ];
  update = (item) => {
    this.props.onUpdate(item);
    this.setState({ isEditing: false });
  };
  render() {
    if (!this.state.isEditing) {
      return (
        <ControlGroup>
          <Tag
            large
            minimal
            interactive={!this.props.old}
            onClick={() => {
              if (!this.props.old) {
                this.setState({ isEditing: true });
              }
            }}
            intent={
              this.props.type === "CG"
                ? "warning"
                : this.props.old
                ? "warning"
                : null
            }
          >
            {this.props.vision}
          </Tag>
        </ControlGroup>
      );
    }

    if (this.props.type === "VA") {
      return (
        <SingleSelectWrapper
          items={this.listOfVAs}
          selectedItem={this.props.vision}
          onChange={(item) => this.update(item)}
        />
      );
    }
    if (this.props.type === "VA_NEAR") {
      return (
        <SingleSelectWrapper
          items={this.listOfNear}
          selectedItem={this.props.vision}
          onChange={(item) => this.update(item)}
        />
      );
    }
  }
}

export default VASelector;
