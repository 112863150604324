import {
  Card,
  ControlGroup,
  Elevation,
  InputGroup,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import { inject, observer } from "mobx-react";
import React from "react";

import _ from "lodash";
import { masterParameterOptions } from "../masterParameterOptions";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class TemplateHeader extends React.Component {
  state = {
    arePanelAddressDetailsOpen: false,
    areCommentsOpen: false,
    enteredComment: "",
  };
  componentDidMount() {
    this.setState({ ...this.props.data });
  }
  renderTemplateDetails = () => {
    return (
      <div>
        <Card elevation={Elevation.FOUR} className="card__container">
          <div className="flex__push__apart">
            <ControlGroup>
              <Tag intent="warning" large>
                Template
              </Tag>
              <Tag intent="success" className="turquoise__bg">
                Name
              </Tag>
              <InputGroup
                inputClassName="colfax"
                value={this.state.templateName}
                onChange={(e) => {
                  this.setState({ templateName: e.target.value });
                }}
                onBlur={() => {
                  this.props.onTemplatePropUpdate(
                    "templateName",
                    this.state.templateName
                  );
                }}
              />
              <Tag intent="success" className="violet__bg">
                Type
              </Tag>{" "}
              <SingleSelectWrapper
                selectedItem={this.state.templateType}
                showSelectProp={true}
                items={masterParameterOptions.TEMPLATE_TYPES}
                isColfax={true}
                onChange={(item) => {
                  this.setState({ templateType: item }, () => {
                    this.props.onTemplatePropUpdate(
                      "templateType",
                      this.state.templateType
                    );
                  });
                }}
              />
              <Tag intent="success" className="sepia__bg">
                Category
              </Tag>
              <SingleSelectWrapper
                selectedItem={this.state.speciality}
                showSelectProp={true}
                items={masterParameterOptions.OPHTHALMIC_SPECIALITIES}
                isColfax={true}
                onChange={(item) => {
                  this.setState({ speciality: item }, () => {
                    this.props.onTemplatePropUpdate(
                      "speciality",
                      this.state.speciality
                    );
                  });
                }}
              />
            </ControlGroup>
            <ControlGroup>
              <Tag large className="turquoise__bg" intent="success">
                Uses: {this.state.nUses}
              </Tag>
            </ControlGroup>
          </div>
        </Card>
      </div>
    );
  };
  renderTemplateDescription = () => {
    return (
      <div>
        <Card elevation={Elevation.FOUR} className="card__container">
          <TextArea
            className="colfax"
            fill
            autoResize
            placeholder="Enter a description of this template here."
            value={this.state.templateDescription}
            onChange={(e) => {
              this.setState({ templateDescription: e.target.value });
            }}
            onBlur={() => {
              this.props.onTemplatePropUpdate(
                "templateDescription",
                this.state.templateDescription
              );
            }}
          />
        </Card>
      </div>
    );
  };

  render() {
    return (
      <div className="colfax">
        {this.renderTemplateDetails()}
        {this.renderTemplateDescription()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(TemplateHeader));
