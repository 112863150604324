import { Button, ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import Finding from "../../elems/Finding";
import SingleSearch from "../../history-wizard/SingleSearch";
import InvImage from "../InvImage";
import _ from "lodash";
import OCTGuiding from "./OCTGuiding";
import OCTSliceMeasures from "./OCTSliceMeasures";
import SingleSelectWrapper from "../../../utils/SingleSelectWrapper";

class OCTCurrent extends React.Component {
  state = {
    showGuide: false,
  };
  renderInfoBar = () => {
    return (
      <ControlGroup>
        <Button
          icon="swap-vertical"
          minimal
          onClick={() => this.props.flipImages()}
        />
        <Tag minimal intent="warning">
          SSI: {this.props.ssi}{" "}
        </Tag>
        <Button
          minimal
          icon="chevron-left"
          onClick={() => this.props.onLeft()}
        />
        <Tag minimal>Image</Tag>
        <Tag minimal intent="primary">
          {this.props.data.sliceIndex + 1}
        </Tag>
        <Tag minimal>of {this.props.length}</Tag>
        <Tag
          intent={this.props.data.selectForPrint ? "success" : "warning"}
          minimal
          interactive
          onClick={() => this.props.selectForPrint()}
        >
          {this.props.data.selectForPrint ? "Selected" : "Not Selected"}
        </Tag>
        <Button
          text="Show/Hide Guiding Image"
          minimal
          onClick={() => this.setState({ showGuide: !this.state.showGuide })}
        />
        <Button
          minimal
          icon="chevron-right"
          onClick={() => this.props.onRight()}
        />
      </ControlGroup>
    );
  };
  renderMeasures = () => {
    let appm = this.props.applicable;
    let appOptions = appm.map((each) => each.description);
    return (
      <OCTSliceMeasures
        humanMeasures={this.props.humanMeasures}
        options={appOptions}
        editHumanMeasure={this.props.editHumanMeasure}
        removeHumanMeasure={this.props.removeHumanMeasure}
      />
    );
  };
  addHumanMeasure = (item) => {
    let appm = this.props.applicable;
    let which = _.find(appm, (each) => each.description === item);
    this.props.addHumanMeasure(which);
  };
  renderAdd = () => {
    let appm = this.props.applicable;
    let appOptions = appm.map((each) => each.description);
    return (
      <ControlGroup>
        <Tag minimal>Add</Tag>
        <SingleSelectWrapper
          items={appOptions}
          onChange={(item) => this.addHumanMeasure(item)}
        />
      </ControlGroup>
    );
  };
  renderSearch = () => {
    return (
      <SingleSearch
        investigationType={this.props.invType}
        placeholder="OCT Observation"
        add={(item) => {
          this.props.addFinding(item);
        }}
      />
    );
  };
  renderFindings = () => {
    if (this.props.humanFindings.length) {
      let hf = this.props.humanFindings;
      return hf.map((each) => {
        let obj = each;
        obj["type"] = "new";
        obj["qualifier"] = each.valueQualifiers;
        obj["item"] = each.description;
        obj["listOfQualifiers"] = each.listOfQualifiers;
        return (
          <Finding
            data={obj}
            duplicate={() => {}}
            remove={this.props.removeHumanMeasure}
            editObjectInside={this.props.editObjectInside}
          />
        );
      });
    }
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {this.renderInfoBar()}
          <InvImage src={this.props.data} selector={false} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.state.showGuide ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ControlGroup>
                <Tag minimal large>
                  Guiding Image
                </Tag>
              </ControlGroup>
              {this.props.guiding && (
                <OCTGuiding imageSrc={this.props.guiding} />
              )}
            </div>
          ) : null}
        </div>
        {this.props.data.selectForPrint ? (
          <div>
            <ControlGroup style={{ marginBottom: "5px" }}>
              <ControlGroup>
                {" "}
                <Tag large intent="primary">
                  Findings
                </Tag>
              </ControlGroup>

              {this.renderSearch()}
            </ControlGroup>

            {this.renderFindings()}
            <ControlGroup style={{ marginBottom: "5px", marginTop: "15px" }}>
              {" "}
              <Tag large intent="primary">
                Manual Measurements
              </Tag>
              {this.renderAdd()}
            </ControlGroup>
            {this.renderMeasures()}
          </div>
        ) : null}
      </div>
    );
  }
}

export default OCTCurrent;
