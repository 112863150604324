import {
  AnchorButton,
  Button,
  Card,
  ControlGroup,
  Elevation,
  FileInput,
  InputGroup,
  Spinner,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import React from "react";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import { masterParameterOptions } from "./masterParameterOptions";
import { inject, observer } from "mobx-react";
import cogoToast from "cogo-toast";
import { apiCallPost } from "../fns/util";

class FilesEntity extends React.Component {
  state = {
    fileFrom: "",
    organ: "",
    anatomicalPart: "",
    laterality: "",
    notes: "",
    data: [],
    toBeUploaded: [],
    isGettingFiles: true,
  };

  componentDidMount() {
    this.setState(
      {
        ...this.props.data,
      },
      () => this.getFileURLs()
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data.updatedAt !== this.props.data.updatedAt) {
      this.setState(
        {
          ...this.props.data,
        },
        () => this.getFileURLs()
      );
    }
  }
  getFileURLs = async () => {
    try {
      this.setState({ isGettingFiles: true });
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/extra/getInvestigationFilesUrl`,
        {
          fileId: this.props.data._id,
        }
      );
      this.setState({
        fileUrls: res.urls,
        isGettingFiles: false,
      });
    } catch (err) {
      this.setState({
        isGettingFiles: false,
      });
      console.log(err);
      cogoToast.error("Error getting file URLs");
    }
  };
  renderFields = () => {
    return (
      <ControlGroup vertical>
        <ControlGroup>
          <Tag minimal>Type</Tag>
          <Button
            minimal
            small
            disabled={this.props.updating}
            icon="trash"
            intent="danger"
            onClick={() => this.props.onDelete()}
          />
          <InputGroup
            disabled={this.props.updating}
            inputClassName="colfax"
            value={this.state.fileFrom}
            onChange={(e) =>
              this.setState({
                fileFrom: e.target.value,
              })
            }
            onBlur={() => this.props.onUpdate(this.state)}
          />
        </ControlGroup>
        <ControlGroup>
          <Tag minimal>Organ</Tag>
          <SingleSelectWrapper
            disabled={this.props.updating}
            isColfax={true}
            showSelectProp={true}
            placeholder="Organ"
            items={masterParameterOptions["ORGANS"]}
            selectedItem={this.state.organ}
            onChange={(item) => {
              this.setState(
                {
                  organ: item,
                },
                () => {
                  this.props.onUpdate(this.state);
                }
              );
            }}
          />
        </ControlGroup>
        {this.state.organ === "EYE" && (
          <ControlGroup>
            <Tag minimal>Laterality</Tag>
            <SingleSelectWrapper
              disabled={this.props.updating}
              isColfax={true}
              showSelectProp={true}
              placeholder="Laterality"
              items={masterParameterOptions["LATERALITIES"]}
              selectedItem={this.state.laterality}
              onChange={(item) => {
                this.setState(
                  {
                    laterality: item,
                  },
                  () => {
                    this.props.onUpdate(this.state);
                  }
                );
              }}
            />
          </ControlGroup>
        )}
      </ControlGroup>
    );
  };
  renderNotes = () => {
    return (
      <div style={{ marginTop: "10px" }}>
        <ControlGroup>
          <Tag large minimal>
            Notes
          </Tag>
          <Tag minimal className="rose_dark">
            You can enter your interpretation, comments, notes or analysis here.
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <TextArea
            disabled={this.props.updating}
            autoResize
            fill
            className="colfax"
            inputClassName="colfax"
            value={this.state.notes}
            onChange={(e) =>
              this.setState({
                notes: e.target.value,
              })
            }
            onBlur={() => this.props.onUpdate(this.state)}
          />
        </ControlGroup>
      </div>
    );
  };
  renderData = () => {
    if (!this.state.data.length) return null;
    return (
      <ControlGroup vertical style={{ marginTop: "10px" }}>
        <ControlGroup>
          <Tag large minimal>
            Data
          </Tag>
          <Tag minimal className="cerulean_dark">
            Measurements and metrics can be entered here.
          </Tag>
        </ControlGroup>
        {this.state.data.map((item, index) => {
          return (
            <ControlGroup>
              <Tag minimal>{item.key}</Tag>
              <InputGroup
                disabled={this.props.updating}
                className="colfax small_input"
                value={item.value}
                onChange={(e) => {
                  let data = this.state.data;
                  data[index].value = e.target.value;
                  this.setState({ data });
                }}
                onBlur={() => this.props.onUpdate(this.state)}
              />
              {item.unit && <Tag minimal>{item.unit}</Tag>}
            </ControlGroup>
          );
        })}
      </ControlGroup>
    );
  };
  renderFileUploader = () => {
    return (
      <ControlGroup vertical style={{ marginTop: "10px" }}>
        <ControlGroup>
          <Tag large minimal>
            Assets
          </Tag>
          <Tag minimal className="forest_dark">
            You can upload multiple files (PDFs or images) here.
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <FileInput
            disabled={this.props.updating}
            onInputChange={(e) => {
              this.setState({
                toBeUploaded: [...this.state.toBeUploaded, e.target.files[0]],
              });
            }}
          />
          {this.state.toBeUploaded.length > 0 && (
            <Button
              disabled={this.props.updating}
              icon="cloud-upload"
              onClick={() => {
                this.props.onUpload(this.state.toBeUploaded);
                this.setState({ toBeUploaded: [] });
              }}
            />
          )}
        </ControlGroup>
        {this.state.toBeUploaded.length > 0 && (
          <ControlGroup>
            <ControlGroup>
              <Tag minimal multiline>
                Ready to upload {this.state.toBeUploaded.length} files
              </Tag>
            </ControlGroup>
            <ControlGroup vertical>
              {this.state.toBeUploaded.map((each, index) => {
                return (
                  <ControlGroup>
                    <Tag
                      minimal
                      onRemove={() => {
                        if (this.props.updating) {
                          return;
                        }
                        let toBeUploaded = this.state.toBeUploaded;
                        toBeUploaded.splice(index, 1);
                        this.setState({ toBeUploaded });
                      }}
                    >
                      {each.name}
                    </Tag>
                  </ControlGroup>
                );
              })}
            </ControlGroup>
          </ControlGroup>
        )}
      </ControlGroup>
    );
  };
  renderFiles = () => {
    if (this.state.isGettingFiles) {
      return <Spinner size={20} />;
    }
    if (!this.state.fileUrls) return null;
    if (!this.state.fileUrls.length) return null;
    return (
      <ControlGroup
        vertical
        style={{
          marginTop: "10px",
        }}
      >
        <ControlGroup>
          <Tag large minimal>
            Uploaded Files
          </Tag>
          <Tag minimal className="lime_dark">
            You can view previously uploaded assets here.
          </Tag>
        </ControlGroup>
        <ControlGroup vertical>
          {this.state.fileUrls.map((each, index) => {
            return (
              <ControlGroup>
                <Button
                  minimal
                  small
                  disabled={this.props.updating}
                  icon="trash"
                  intent="danger"
                  onClick={() => this.props.onDeleteFile(index)}
                />
                <Tag
                  minimal
                  // onRemove={() => {
                  //   if (this.props.updating) {
                  //     return;
                  //   }
                  //   this.props.onDeleteFile(index);
                  // }}
                >
                  {this.state.fileNames[index]}
                </Tag>
                <AnchorButton
                  href={each}
                  target="_blank"
                  icon="document-share"
                />
              </ControlGroup>
            );
          })}
        </ControlGroup>
      </ControlGroup>
    );
  };
  render() {
    return (
      <Card
        elevation={Elevation.THREE}
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          padding: "10px",
          backgroundColor: "#1C2127",
        }}
      >
        <ControlGroup>
          <Tag large intent="success" className="rose_dark">
            #{this.props.whichIndex + 1}
          </Tag>
        </ControlGroup>
        {this.renderFields()}
        {this.renderNotes()}
        {this.renderData()}
        {this.renderFileUploader()}
        {this.renderFiles()}
      </Card>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(FilesEntity));
