import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";

import MutEx from "../../utils/MutEx";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class FUItem extends React.Component {
  state = {
    editingSite: false,
    editingSubtype: false,
  };
  listOfEyes = ["Left Eye", "Right Eye", "Both Eyes"];
  listOfWhens = ["At Next Visit", "Prior To Next Visit", "Prior To Surgery"];

  renderFUItem = (data) => {
    return (
      <ControlGroup style={{ marginTop: "5px" }}>
        {data.kind === "SYSTEMIC" ? null : (
          <>
            <MutEx
              large={true}
              minimal={false}
              options={["RE", "BE", "LE"]}
              onUpdate={(option) => {
                let opt = {
                  RE: "Right Eye",
                  LE: "Left Eye",
                  BE: "Both Eyes",
                }[option];
                this.props.update(data._id, "eye", opt);
              }}
              selected={((x) => {
                let lu = {
                  "Right Eye": "RE",
                  "Left Eye": "LE",
                  "Both Eyes": "BE",
                };
                return lu[x];
              })(data.eye)}
            />
          </>
        )}
        <Tag
          large
          intent="primary"
          interactive
          onRemove={() => this.props.remove(data._id)}
        >
          {data.type}
        </Tag>
        {data.listOfSubtypes.length ? (
          <>
            {this.state.editingSubtype ? (
              <SingleSelectWrapper
                items={data.listOfSubtypes}
                onChange={(item) => {
                  this.props.update(data._id, "subtype", item);
                  this.setState({ editingSubtype: false });
                }}
              />
            ) : (
              <Tag
                minimal
                large
                interactive
                onClick={() => this.setState({ editingSubtype: true })}
              >
                {data.subtype}
              </Tag>
            )}{" "}
          </>
        ) : null}
        {this.state.editingWhen ? (
          <SingleSelectWrapper
            items={this.listOfWhens}
            onChange={(item) => {
              this.props.update(data._id, "when", item);
              this.setState({ editingWhen: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large
            interactive
            onClick={() => this.setState({ editingWhen: true })}
          >
            {data.when}
          </Tag>
        )}
      </ControlGroup>
    );
  };

  render() {
    return <div>{this.renderFUItem(this.props.data)}</div>;
  }
}

export default FUItem;
