import React from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import { toJS } from "mobx";
import {
  AnchorButton,
  Button,
  Card,
  ControlGroup,
  Elevation,
  NumericInput,
  Tag,
} from "@blueprintjs/core";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import { apiCallPost, indianRupee, niceDateTime } from "../fns/util";
import cogoToast from "cogo-toast";

class CashTill extends React.Component {
  componentDidMount() {
    this.setState({ sites: toJS(this.props.listStore.siteList) });
  }
  state = {
    sites: [],
    selectedSite: null,
    isLoadingData: false,
    till: null,
    dailyCashCollected: null,
  };
  getData = async () => {
    try {
      this.setState({ isLoadingData: true });
      let res = await apiCallPost(
        `/finance/opd/${this.props.authStore.prefix}/getMyCashTill`,
        { site: this.state.selectedSite }
      );
      this.setState({
        isLoadingData: false,
        till: res.till,
        dailyCashCollected: res.dailyCashCollected,
      });
    } catch (err) {
      cogoToast.error("Failed to load data");
      this.setState({ isLoadingData: false });
    }
  };
  updateData = async () => {
    try {
      this.setState({ isLoadingData: true });
      let res = await apiCallPost(
        `/finance/opd/${this.props.authStore.prefix}/updateMyCashTill`,
        { _id: this.state.till._id, cash: this.state.till.cash }
      );
      this.setState({
        isLoadingData: false,
        till: res.till,
        touched: false,
      });
    } catch (err) {
      cogoToast.error("Failed to load data");
      this.setState({ isLoadingData: false });
    }
  };
  renderHeader = () => {
    return (
      <ControlGroup>
        <Tag minimal large intent="primary">
          Choose Site
        </Tag>
        <SingleSelectWrapper
          items={this.state.sites}
          onChange={(item) => this.setState({ selectedSite: item })}
          selectedItem={this.state.selectedSite}
        />
        {this.state.selectedSite ? (
          <Button
            text="Get data"
            outlined
            loading={this.state.isLoadingData}
            onClick={() => this.getData()}
          />
        ) : null}
      </ControlGroup>
    );
  };
  renderTill = () => {
    if (!this.state.till) {
      return null;
    }
    return (
      <div>
        <ControlGroup>
          <Tag large minimal intent="primary">
            User
          </Tag>
          <Tag large intent="primary">
            {this.state.till.createdBy}
          </Tag>{" "}
          <Tag large minimal intent="success">
            Site
          </Tag>
          <Tag large intent="success">
            {this.state.till.site}
          </Tag>
          <Tag large minimal intent="warning">
            Last Updated
          </Tag>
          <Tag large intent="warning">
            {niceDateTime(this.state.till.updatedAt)}
          </Tag>
          {this.state.touched ? (
            <Button
              icon="endorsed"
              intent="success"
              text="Save"
              loading={this.state.isLoadingData}
              onClick={() => this.updateData()}
            />
          ) : null}
          <AnchorButton
            target="_blank"
            icon="print"
            href={`https://print.newchart.care/cashtill/${this.state.till._id}`}
          />
        </ControlGroup>
        <br />
        <br />
        {this.renderEachDenom("2000")}
        {this.renderEachDenom("500")}
        {this.renderEachDenom("200")}
        {this.renderEachDenom("100")}
        {this.renderEachDenom("50")}
        {this.renderEachDenom("20")}
        {this.renderEachDenom("10")}
        {this.renderEachDenom("5")}
        {this.renderEachDenom("2")}
        {this.renderEachDenom("1")}
        <br />
        <br />
        {this.renderCheck()}
      </div>
    );
  };
  renderCheck = () => {
    let sum = 0;
    for (var i = 0; i < this.state.till.cash.length; i++) {
      sum = parseInt(
        sum +
          Number(this.state.till.cash[i].denom) * this.state.till.cash[i].count
      );
    }

    return (
      <ControlGroup vertical>
        <ControlGroup>
          <Tag minimal large>
            Total Entered
          </Tag>
          <Tag minimal large intent="warning">
            {indianRupee(sum, 0, false)}
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <Tag minimal large>
            Total Should Be:
          </Tag>
          <Tag minimal large intent="warning">
            {indianRupee(this.state.dailyCashCollected, 0, false)}
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <Tag
            minimal={this.state.dailyCashCollected === sum}
            intent={
              this.state.dailyCashCollected === sum ? "success" : "danger"
            }
            large
          >
            {this.state.dailyCashCollected === sum ? "Exact Match" : "Mismatch"}
          </Tag>
        </ControlGroup>
      </ControlGroup>
    );
  };
  renderEachDenom = (denom) => {
    let whichDenomIdx = _.findIndex(
      this.state.till.cash,
      (each) => each.denom === denom
    );
    let whichDenom = this.state.till.cash[whichDenomIdx];
    return (
      <ControlGroup style={{ marginTop: "5px" }}>
        <Tag minimal large style={{ width: "6em" }}>
          {indianRupee(whichDenom.denom, 0, false)}
        </Tag>
        <NumericInput
          value={whichDenom.count}
          buttonPosition="none"
          onValueChange={(valueAsNumber) => {
            let ss = this.state.till;
            let cc = ss.cash;
            cc[whichDenomIdx].count = valueAsNumber;
            ss.cash = cc;
            this.setState({ till: ss, touched: true });
          }}
        />
        <Tag minimal large intent="primary">
          {indianRupee(Number(whichDenom.denom) * whichDenom.count, 0, false)}
        </Tag>
      </ControlGroup>
    );
  };
  render() {
    return (
      <div>
        <Card style={{ margin: "20px" }} elevation={Elevation.FOUR}>
          {this.renderHeader()}
          <br />
          {this.renderTill()}
        </Card>
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "patientStore",
  "billingStore",
  "listStore"
)(observer(CashTill));
