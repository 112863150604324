import {
  ControlGroup,
  Tag,
  Card,
  Elevation,
  InputGroup,
  Button,
} from "@blueprintjs/core";
import React from "react";
import ColourVision from "./ColourVision";
import ContrastSens from "./ContrastSens";
import FamHist from "./FamHist";
import Diagnosis from "./Diagnosis";
import PrevEyeSx from "./PrevEyeSx";
import PupRx from "./PupRx";
import Schirmer from "./Schirmer";
import WorthFourDot from "./WorthFourDot";
import SysDx from "./SysDx";
import SysSx from "./SysSx";
import SingleSearch from "../history-wizard/SingleSearch";
import CentralCornealThickness from "./CentralCornealThickness";

class Context extends React.Component {
  state = {
    crxText: null,
    showSearchCRx: false,
  };
  renderOptometryOne = () => {
    return (
      <ControlGroup vertical>
        <PupRx
          vision={this.props.vision}
          updateVision={this.props.updateVision}
        />
        <ContrastSens
          vision={this.props.vision}
          updateVision={this.props.updateVision}
        />
        <CentralCornealThickness
          vision={this.props.vision}
          updateVision={this.props.updateVision}
        />
        <ControlGroup>
          {" "}
          <ColourVision
            colourVision={this.props.colourVision}
            updateScalar={this.props.updateScalar}
          />
        </ControlGroup>
      </ControlGroup>
    );
  };
  renderOptometryTwo = () => {
    return (
      <ControlGroup vertical>
        <Schirmer
          vision={this.props.vision}
          updateVision={this.props.updateVision}
        />
        <WorthFourDot
          vision={this.props.vision}
          updateVision={this.props.updateVision}
        />
      </ControlGroup>
    );
  };
  prevEyeDx = () => {
    if (!this.props.prevEyeDx || !this.props.prevEyeDx.length) {
      return (
        <ControlGroup>
          {" "}
          <Tag
            minimal
            large
            interactive
            onClick={() => this.props.onSearch("dx", "prevEyeDx")}
            icon="diagnosis"
          >
            No Eye Diagnosis Recorded
          </Tag>
        </ControlGroup>
      );
    }
    return this.props.prevEyeDx.map((each) => {
      return (
        <Diagnosis
          isOld={true}
          data={each}
          remove={() => this.props.removeArray("prevEyeDx", each._id)}
          update={(key, value) =>
            this.props.updateArray("prevEyeDx", each._id, key, value)
          }
        />
      );
    });
  };
  renderPrevEyeDx = () => {
    return (
      <div>
        <Tag intent="primary" minimal>
          Previous Eye Dx
        </Tag>
      </div>
    );
  };
  prevEyeSx = () => {
    if (!this.props.sysEyeSx || !this.props.sysEyeSx.length) {
      return (
        <ControlGroup>
          {" "}
          <Tag
            minimal
            large
            interactive
            onClick={() => this.props.onSearch("prev-sx", "sysEyeSx")}
            icon="cut"
          >
            No Eye Surgeries Recorded
          </Tag>
        </ControlGroup>
      );
    }
    return this.props.sysEyeSx.map((each) => {
      return (
        <PrevEyeSx
          data={each}
          onRemove={() => this.props.removeArray("sysEyeSx", each._id)}
          onUpdate={(key, value) =>
            this.props.updateArray("sysEyeSx", each._id, key, value)
          }
        />
      );
    });
  };
  renderPrevEyeSx = () => {
    return (
      <div>
        <Tag
          intent="primary"
          minimal
          interactive
          onClick={() => this.props.onSearch("prev-sx", "sysEyeSx")}
        >
          Previous Eye Sx
        </Tag>
        <br />
      </div>
    );
  };
  famHist = () => {
    if (!this.props.famHist || !this.props.famHist.length) {
      return (
        <ControlGroup>
          {" "}
          <Tag
            minimal
            large
            interactive
            onClick={() => this.props.onSearch("fh", "famHist")}
            icon="history"
          >
            No Family History Recorded
          </Tag>
        </ControlGroup>
      );
    }
    return this.props.famHist.map((each) => {
      return (
        <FamHist
          data={each}
          onRemove={() => this.props.removeArray("famHist", each._id)}
          onUpdate={(key, value) =>
            this.props.updateArray("famHist", each._id, key, value)
          }
        />
      );
    });
  };

  renderEachAllergy = (data) => {
    return (
      <Tag
        intent="danger"
        large
        onRemove={() => {
          this.props.removeArray("allergies", data._id);
        }}
      >
        {data.allergy}
      </Tag>
    );
  };
  renderAllergies = () => {
    if (!this.props.allergies || !this.props.allergies.length) {
      return (
        <ControlGroup>
          <Tag
            minimal
            icon="virus"
            large
            interactive
            onClick={() => this.props.onSearch("all", "allergies")}
          >
            No Allergies Recorded
          </Tag>
        </ControlGroup>
      );
    }
    return this.props.allergies.map((each) => {
      return this.renderEachAllergy(each);
    });
  };
  renderSysDx = () => {
    if (!this.props.sysDx || !this.props.sysDx.length) {
      return (
        <ControlGroup>
          {" "}
          <Tag
            minimal
            large
            interactive
            onClick={() => this.props.onSearch("sys-dx", "sysDx")}
            icon="diagnosis"
          >
            No Systemic Diseases Recorded
          </Tag>
        </ControlGroup>
      );
    }
    return this.props.sysDx.map((each) => {
      return (
        <SysDx
          data={each}
          onRemove={() => this.props.removeArray("sysDx", each._id)}
          onUpdate={(key, value) =>
            this.props.updateArray("sysDx", each._id, key, value)
          }
        />
      );
    });
  };
  renderSysSx = () => {
    if (!this.props.sysSx || !this.props.sysSx.length) {
      return (
        <ControlGroup>
          <Tag
            minimal
            interactive
            large
            onClick={() => this.props.onSearch("sys-sx", "sysSx")}
            icon="cut"
          >
            No Systemic Surgeries Recorded
          </Tag>
        </ControlGroup>
      );
    }
    return this.props.sysSx.map((each) => {
      return (
        <SysSx
          data={each}
          onRemove={() => this.props.removeArray("sysSx", each._id)}
          onUpdate={(key, value) =>
            this.props.updateArray("sysSx", each._id, key, value)
          }
        />
      );
    });
  };
  addMedManual = () => {
    let obj = {
      brand: "-manual-entry-",
      checked: false,
      duration: "-manual-entry-",
      durationText: "-manual-entry-",
      molecules: this.state.crxText,
      route: "-manual-entry-",
      type: "new",
      _id_sr: "-manual-entry-",
      parentEntity: undefined,
    };
    this.props.addCrx(obj);
    this.setState({ crxText: "" });
  };

  renderCRxAdd = () => {
    return (
      <ControlGroup>
        <Tag minimal icon="new-grid-item"></Tag>
        <InputGroup
          value={this.state.crxText}
          onChange={(event) => this.setState({ crxText: event.target.value })}
          rightElement={
            this.state.crxText ? (
              <Button
                icon="add"
                minimal
                intent="success"
                onClick={() => this.addMedManual()}
              />
            ) : null
          }
        />
      </ControlGroup>
    );
  };
  renderCRxSearchOrAdd = () => {
    if (this.state.showSearchCRx) {
      return (
        <SingleSearch
          prefix="med"
          placeholder="Medicines"
          whichArray="prev_medicines"
          add={(whichArray, obj) => {
            this.props.addCrx(obj);
          }}
        />
      );
    } else {
      return this.renderCRxAdd();
    }
  };
  renderEachCRx = (data) => {
    return (
      <Tag
        intent="primary"
        minimal
        large
        onRemove={() => {
          this.props.removeCrx("prev_medicines", data._id);
        }}
      >
        {data.molecules}
      </Tag>
    );
  };
  renderCRx = () => {
    if (!this.props.prev_medicines || !this.props.prev_medicines.length) {
      return (
        <ControlGroup>
          <Tag minimal icon="prescription" large>
            No Current Medications Recorded
          </Tag>
        </ControlGroup>
      );
    }
    return (
      <ControlGroup vertical>
        {this.props.prev_medicines.map((each) => {
          return this.renderEachCRx(each);
        })}
      </ControlGroup>
    );
  };

  render() {
    return (
      <div style={{ display: "flex", flexWrap: "wrap", alignItems: "start" }}>
        <Card
          elevation={Elevation.FOUR}
          style={{
            padding: "10px",
            backgroundColor: "#26292c",
            marginRight: "10px",
          }}
        >
          <ControlGroup>
            <Tag large intent="primary">
              Work Up
            </Tag>
          </ControlGroup>
          {this.renderOptometryOne()}
          {this.renderOptometryTwo()}
        </Card>
        <Card
          elevation={Elevation.FOUR}
          style={{
            padding: "10px",
            backgroundColor: "#26292c",
            marginRight: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <>
              <Tag large intent="warning">
                Eye History
              </Tag>
            </>
            <div>
              <Tag
                large
                intent="primary"
                interactive
                onClick={() => this.props.onSearch("dx", "prevEyeDx")}
              >
                +Dx
              </Tag>
              <Tag
                large
                intent="primary"
                interactive
                onClick={() => this.props.onSearch("prev-sx", "sysEyeSx")}
              >
                +Sx
              </Tag>
            </div>
          </div>
          <div>
            {" "}
            {this.prevEyeDx()}
            {this.prevEyeSx()}
          </div>
        </Card>
        <Card
          elevation={Elevation.FOUR}
          style={{
            padding: "10px",
            backgroundColor: "#26292c",
            marginRight: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <>
              <Tag large intent="warning">
                Current Medications
              </Tag>
            </>
            <div>
              <Tag
                large
                intent="primary"
                interactive
                icon={this.state.showSearchCRx ? "search" : "edit"}
                onClick={() =>
                  this.setState({ showSearchCRx: !this.state.showSearchCRx })
                }
              ></Tag>
            </div>
          </div>
          <div>{this.renderCRxSearchOrAdd()}</div>
          <div> {this.renderCRx()}</div>
        </Card>

        <Card
          elevation={Elevation.FOUR}
          style={{
            padding: "10px",
            backgroundColor: "#26292c",
            marginRight: "10px",
          }}
        >
          <ControlGroup>
            <Tag
              large
              intent="primary"
              interactive
              onClick={() => this.props.onSearch("all", "allergies")}
            >
              Allergies
            </Tag>
          </ControlGroup>
          <ControlGroup>{this.renderAllergies()}</ControlGroup>

          <ControlGroup>
            <Tag
              large
              intent="primary"
              interactive
              onClick={() => this.props.onSearch("fh", "famHist")}
            >
              Family History
            </Tag>
          </ControlGroup>
          {this.famHist()}
        </Card>
        <Card
          elevation={Elevation.FOUR}
          style={{
            padding: "10px",
            backgroundColor: "#26292c",
            marginRight: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <>
              <Tag large intent="warning">
                Systemic History
              </Tag>
            </>
            <div>
              <Tag
                large
                intent="primary"
                interactive
                onClick={() => this.props.onSearch("sys-dx", "sysDx")}
              >
                +Dx
              </Tag>
              <Tag
                large
                intent="primary"
                interactive
                onClick={() => this.props.onSearch("sys-sx", "sysSx")}
              >
                +Sx
              </Tag>
            </div>
          </div>
          <div>{this.renderSysDx()}</div>
          <div> {this.renderSysSx()}</div>
        </Card>
      </div>
    );
  }
}

export default Context;
