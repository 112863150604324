import { NumericInput } from "@blueprintjs/core";
import React from "react";

class NumInpVision extends React.Component {
  state = {
    value: null,
    touched: false,
    editing: false,
  };
  clamp = (number, min, max) => {
    return Math.max(min, Math.min(number, max));
  };
  render() {
    if (!this.state.editing) {
      let vv = this.props.value;

      if (!["---", "-"].includes(vv)) {
        let isPos = "";

        vv = parseFloat(vv);
        if (vv > 0 && !this.props.iop) {
          isPos = "+";
        }
        vv = isPos + vv.toFixed(this.props.fix);
      }
      let value = this.props.value;
      if (["---", "-"].includes(vv)) {
        value = 0;
      }
      return (
        <div
          style={{
            color: `${this.props.old ? "#FFB366" : ""}`,
          }}
          onClick={() => {
            if (!this.props.old) {
              this.setState({
                editing: true,
                touched: true,
                value: value,
              });
            }
          }}
        >
          {vv}
        </div>
      );
    }

    return (
      <NumericInput
        style={{ width: "4em" }}
        buttonPosition="none"
        majorStepSize={this.props.major}
        minorStepSize={this.props.minor}
        stepSize={this.props.minor}
        onValueChange={(valueAsNumber, valueAsString) =>
          this.setState({ value: valueAsString, touched: true })
        }
        min={this.props.min}
        max={this.props.max}
        clampValueOnBlur={true}
        defaultValue={this.props.value}
        value={this.state.touched ? this.state.value : this.props.value}
        onBlur={() => {
          this.setState({ editing: false });
          this.props.onUpdate(
            this.state.touched
              ? this.clamp(this.state.value, this.props.min, this.props.max)
              : this.props.value
          );
        }}
      />
    );
  }
}

export default NumInpVision;
