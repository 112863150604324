import React from "react";
import { Button, ControlGroup, InputGroup, Tag } from "@blueprintjs/core";
class CentralCornealThickness extends React.Component {
  state = {
    editingPupilL: false,
    editingPupilR: false,
    valueL: "",
    valueR: "",
  };
  listOfPRs = [
    "0.05",
    "0.20",
    "0.35",
    "0.50",
    "0.65",
    "0.80",
    "0.95",
    "1.10",
    "1.25",
    "1.40",
    "1.55",
    "1.70",
    "1.85",
    "2.00",
    "2.15",
    "2.30",
  ];
  renderPupil = () => {
    return (
      <ControlGroup>
        <Tag minimal large>
          Central Corneal Thickness
        </Tag>

        <Tag minimal large>
          R
        </Tag>

        {this.state.editingPupilR ? (
          <InputGroup
            onChange={(event) => this.setState({ valueR: event.target.value })}
            style={{ width: "6em" }}
            rightElement={
              <Button
                icon="endorsed"
                onClick={() => {
                  this.props.updateVision("cct.re", this.state.valueR || "");
                  this.setState({ editingPupilR: false });
                }}
              />
            }
          />
        ) : (
          <Tag
            minimal
            large
            intent="primary"
            interactive
            onClick={() => this.setState({ editingPupilR: true })}
          >
            {this.props.vision.cct.re} {this.props.vision.cct.re ? "µm" : ""}
          </Tag>
        )}

        <Tag minimal large>
          L
        </Tag>
        {this.state.editingPupilL ? (
          <InputGroup
            onChange={(event) => this.setState({ valueL: event.target.value })}
            style={{ width: "6em" }}
            rightElement={
              <Button
                icon="endorsed"
                onClick={() => {
                  this.props.updateVision("cct.le", this.state.valueL || "");
                  this.setState({ editingPupilL: false });
                }}
              />
            }
          />
        ) : (
          <Tag
            minimal
            large
            interactive
            intent="primary"
            onClick={() => this.setState({ editingPupilL: true })}
          >
            {this.props.vision.cct.le} {this.props.vision.cct.le ? "µm" : ""}
          </Tag>
        )}
      </ControlGroup>
    );
  };
  render() {
    return <div>{this.renderPupil()}</div>;
  }
}

export default CentralCornealThickness;
