import React from "react";
import _ from "lodash";
import { ControlGroup, Tag } from "@blueprintjs/core";

class VFAVis extends React.Component {
  state = { showNum: false, spectrum: false };

  renderTableNum = () => {
    return this.data2.map((row, rowIdx) => {
      return <tr>{this.renderRowNum(row, rowIdx)}</tr>;
    });
  };
  renderCellNum = (each, rowIdx, cellIdx) => {
    let ss = {};
    let row = this.data2[rowIdx];
    if (each === "") {
    } else {
      if (cellIdx === 0) {
        ss["borderLeft"] = "1px solid #555b64";
      } else if (cellIdx === row.length - 1) {
        ss["borderRight"] = "1px solid #555b64";
      } else if (row[cellIdx - 1] === "") {
        ss["borderLeft"] = "1px solid #555b64";
      } else if (row[cellIdx + 1] === "") {
        ss["borderRight"] = "1px solid #555b64";
      }

      if (rowIdx === 0) {
        ss["borderTop"] = "1px solid #555b64";
      } else if (rowIdx === this.data2.length - 1) {
        ss["borderBottom"] = "1px solid #555b64";
      } else if (this.data2[rowIdx - 1][cellIdx] === "") {
        ss["borderTop"] = "1px solid #555b64";
      } else if (this.data2[rowIdx + 1][cellIdx] === "") {
        ss["borderBottom"] = "1px solid #555b64";
      }
    }
    if (cellIdx === 3) {
      ss["borderRight"] = "1px solid #939496";
    }
    if (rowIdx === 3) {
      ss["borderBottom"] = "1px solid #939496";
    }
    return <td style={ss}>{each}</td>;
  };
  renderRowNum = (row, rowIdx) => {
    return row.map((e, cellIdx) => {
      return this.renderCellNum(e, rowIdx, cellIdx);
    });
  };
  renderCellVis = (each, cellIdx, rowIdx) => {
    let ss = {};
    let row = this.props.data[rowIdx];
    if (each === "") {
    } else {
      if (cellIdx === 0) {
        ss["borderLeft"] = "1px solid #555b64";
      } else if (cellIdx === row.length - 1) {
        ss["borderRight"] = "1px solid #555b64";
      } else if (row[cellIdx - 1] === "") {
        ss["borderLeft"] = "1px solid #555b64";
      } else if (row[cellIdx + 1] === "") {
        ss["borderRight"] = "1px solid #555b64";
      }

      if (rowIdx === 0) {
        ss["borderTop"] = "1px solid #555b64";
      } else if (rowIdx === this.props.data.length - 1) {
        ss["borderBottom"] = "1px solid #555b64";
      } else if (this.props.data[rowIdx - 1][cellIdx] === "") {
        ss["borderTop"] = "1px solid #555b64";
      } else if (this.props.data[rowIdx + 1][cellIdx] === "") {
        ss["borderBottom"] = "1px solid #555b64";
      }
    }
    if (cellIdx === this.props.columnOffset) {
      ss["borderRight"] = "1px solid #939496";
    }
    if (rowIdx === this.props.rowOffset) {
      ss["borderBottom"] = "1px solid #939496";
    }
    if (each === "") {
    } else {
      let e2 = parseInt(each);
      if (typeof each === "string" && _.startsWith(each, "<")) {
        e2 = 0;
      }
      let color = "";

      if (this.props.spectrum) {
        if (this.props.range) {
          ss["backgroundColor"] = `hsl(${(
            ((e2 - this.props.min) / (this.props.max - this.props.min)) *
            200
          ).toString(10)}, 60%, 55%)`;
          let factor =
            (e2 - this.props.min) / (this.props.max - this.props.min);
          if (factor > 0.2) {
            color = "rgba(0,0,0, 1)";
          } else {
            color = "rgba(255,255,255, 1)";
          }
        } else {
          ss["backgroundColor"] = `hsl(${(
            (e2 / this.props.fovea) *
            180
          ).toString(10)}, 60%, 55%)`;
          let factor = e2 / this.props.fovea;
          if (factor > 0.2) {
            color = "rgba(0,0,0, 1)";
          } else {
            color = "rgba(255,255,255, 1)";
          }
        }
      } else {
        if (this.props.range) {
          ss["backgroundColor"] = `rgba(255, 255, 255, ${
            (e2 - this.props.min) / (this.props.max - this.props.min)
          })`;
          let factor =
            (e2 - this.props.min) / (this.props.max - this.props.min);
          if (factor > 0.4) {
            color = "rgba(0,0,0, 1)";
          } else {
            color = "rgba(255,255,255, 1)";
          }
        } else {
          ss["backgroundColor"] = `rgba(255, 255, 255, ${
            e2 / this.props.fovea
          })`;
          let factor = e2 / this.props.fovea;
          if (factor > 0.4) {
            color = "rgba(0,0,0, 1)";
          } else {
            color = "rgba(255,255,255, 1)";
          }
        }
      }
      ss["color"] = color;
    }
    return <td style={ss}>{this.props.showNum ? each : ""}</td>;
  };
  renderRowVis = (each, rowIdx) => {
    return each.map((e, idx) => {
      return this.renderCellVis(e, idx, rowIdx);
    });
  };
  renderTableVis = () => {
    return this.props.data.map((each, rowIdx) => {
      return <tr>{this.renderRowVis(each, rowIdx)}</tr>;
    });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: "5px",
        }}
      >
        <ControlGroup style={{ marginTop: "5px", marginBottom: "5px" }} fill>
          <Tag large minimal fill>
            {this.props.title}
          </Tag>
        </ControlGroup>
        <table className="bp5-html-table bp5-html-table-condensed">
          <tbody>{this.renderTableVis()}</tbody>
        </table>
      </div>
    );
  }
}

export default VFAVis;
