import { H3, Tag } from "@blueprintjs/core";
import React from "react";
import SysDx from "../elems/SysDx";
import SuggColl from "../SuggColl";
import SingleSearch from "./SingleSearch";

class SysDxPanel extends React.Component {
  renderTitle = () => {
    return <H3>Has the patient been diagnosed with any systemic diseases?</H3>;
  };
  renderCG = () => {
    return (
      <SingleSearch
        prefix="sys-dx"
        whichArray="sysDx"
        add={this.props.add}
        placeholder="Systemic Dx"
      />
    );
  };

  renderPrevEyeDx = () => {
    if (!this.props.sysDx || !this.props.sysDx.length) {
      return (
        <Tag minimal large>
          No systemic diseases added.
        </Tag>
      );
    }
    return this.props.sysDx.map((each) => {
      return (
        <SysDx
          data={each}
          onUpdate={(key, value) =>
            this.props.update("sysDx", each._id, key, value)
          }
          onRemove={() => this.props.removeArray("sysDx", each._id)}
        />
      );
    });
  };
  render() {
    return (
      <div style={{ padding: "20px", height: "100%" }}>
        {this.renderTitle()}
        <br />
        <SuggColl
          ana_prefix=""
          prefix="sys-dx"
          visitId={this.props.visitId}
          addObjectInside={this.props.add}
        />
        {this.renderCG()}
        <br />
        {this.renderPrevEyeDx()}
      </div>
    );
  }
}

export default SysDxPanel;
