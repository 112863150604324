import {
  H3,
  FormGroup,
  Switch,
  TextArea,
  AnchorButton,
  Button,
  Card,
  ControlGroup,
  Elevation,
  InputGroup,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { DatePicker } from "@blueprintjs/datetime";
import React from "react";
import _ from "lodash";
import DateEntry from "../utils/DateEntry";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import {
  apiCallPost,
  areTwoDatesSame,
  copyToClipboard,
  fixUrlPrefix,
  isDateToday,
  lastDayOfMonth,
  niceDate,
  niceDateTime,
  niceDateWithWeek,
  niceTime,
  yearFromNow,
  niceDateTimeWithWeek,
} from "../fns/util";
import AsyncSelWrap from "../utils/AsyncSelWrap";
import Symptom from "../opto/elems/Symptom";
import { inject, observer } from "mobx-react";
import SingleIDSelectWrapper from "../utils/SingleIDSelectWrapper";
import cogoToast from "cogo-toast";
import SysDx from "../opto/elems/SysDx";
import moment from "moment";
import MutEx from "../utils/MutEx";
import { nanoid } from "nanoid";
import MultiSelectWrapper from "../utils/MultiSelectWrapper";

class BookingTopV2 extends React.Component {
  state = {
    /** RAI */
    isActivelyBooking: true,
    bookingLoading: false,
    reasonText: "",
    reasonCategory: [],
    loadedPurposes: [],
    loadedDocs: [],
    loadedSites: [],
    loadedProcs: [],
    loading: true,
    /** What Type */
    cgChoose: null,
    /** Bookings */
    consultationBookings: [],
    procedureBookings: [],
    packageBookings: [],
    remarks: null,
    isEmergency: false,
    wasDocPtChoice: false,
    bookingChannel: "",
    /** Booking Package */
    packageSite: null,
    loadingPackagesAtSite: false,
    loadedPackagesAtSite: [],
    selectedPackage: null,
    packageEye: null,
    packageDate: null,
    loadingPackageSlots: false,
    componentSlots: [],
    currentlyDraftingPackage: {
      nanoid: null,
      packageId: null,
      packageAlias: null,
      components: [],
    },
    /** Booking Procedure */
    procedureSite: null,
    procProcedure: null,
    procSubprocedure: null,
    procDate: null,
    procEye: null,
    loadingResourceProc: false,
    loadedProcResources: [],
    procResource: null,
    loadingResourceProcTime: false,
    loadedTimewiseProcSlots: {},
    loadedTimewiseProcSlotsCalcSlotsOverall: {},
    /** Booking By Doc */
    choosingDocSite: null,
    choosingByDoctor: true,
    selectedDocId: null,
    selectedDoc: null,
    currentMonth: new Date(),
    loadingDocCalendar: false,
    docCalendarLoaded: null,
    selectedDocDate: null,
    selectedDocDateSite: null,
    loadingDocDateSitePurposes: false,
    loadedDocDateSitePurposes: null,
    selectedDocDateSitePurpose: null,
    selectedDocDateSitePurposeWor: null,
    selectedDocDateSitePurposeIsToday: null,
    loadingDocDateSitePurposeTimeBar: false,
    loadedDocDateSitePurposeTimebars: null,
    selectedSlot: null, // TO DO: ARRAY
    /** Symptom Search */
    symSearching: false,
    symSearchResults: [],
    symptoms: [],
    /** SysDx Search */
    sysDxSearching: false,
    sysDxSearchResults: [],
    sysDx: [],

    /** Announcements */
    loadingAnn: false,
    loadedAnn: [],
    /** Right Panel*/
    visibleCard: null,
    /** New Patient */
    editingDoB: false,
    nPtName: "",
    nPtPhone: "",
    nPtWhatsApp: "",
    nPtDoB: null,
    nPtSex: null,
    nPtAddress: null,
    nPtGeoCode: null,
    nPtGeoCodeText: null,
    nPtLegacyPid: null,
    /** Old Patient*/
    oPtOldRxSearching: false,
    oldSearching: false,
    oldSearchResults: [],
    oPtName: null,
    oPtPid: null,
    oPtPhone: null,
    oPtAge: null,
    oPt: null,
    oPtOldRx: null,
    deleteDockets: [],
    /** */
    cgDoc: null,
    cgSite: null,
    /** Enquiry */
    loggingEnquiry: false,
    newEnquiry: false,
    newEnquiryNumber: "",
    enquiryCategories: [],
    enquiryText: "",
    enquiryPID: "",
  };
  bookingChannelChoices = [
    "In Person",
    "Telephonic",
    "Online",
    "SMS",
    "Email",
    "WhatsApp",
    "Other",
  ];
  cancellationReasons = [
    "Booked In Error (Internal)",
    "Booked In Error (Patient)",
    "Medical Issue Resolved",
    "Doctor Unavailability",
    "Equipment Unavailability",
    "Location Unavailability",
    "Location Mismatch",
    "Doctor Mismatch",
    "Specialty Mismatch",
    "Already Visited Same Doctor",
    "Already Visited Different Doctor",
    "Already Visited Different Hospital",
    "Appointment Delay",
    "Too Ill To Attend",
    "Anxiety",
    "Other Medical Issues",
    "Family Concerns",
    "Work Concerns",
    "Financial Conerns",
    "Low Perceived Severity",
    "External Factors",
    "Other",
  ];
  /** RAI STUFF */
  componentDidMount() {
    this.routeParamMatch();
    this.getSiteOptions();
  }
  getSiteOptions = async () => {
    try {
      let resSites = await apiCallPost(
        `/rai/${this.props.authStore.prefix}/getAllSitesAndGeography`,
        {}
      );

      try {
        let resDocs = await apiCallPost(
          `/rai/${this.props.authStore.prefix}/getAllConsultants`,
          {}
        );
        resDocs = _.sortBy(resDocs, "name");
        try {
          let resPur = await apiCallPost(
            `/rai/${this.props.authStore.prefix}/getAllConsultationPurposes`,
            {}
          );
          try {
            let resProcs = await apiCallPost(
              `/rai/${this.props.authStore.prefix}/getProceduresAndSubproceduresEx`,
              {}
            );
            this.setState({
              loadedProcs: resProcs,
              loadedPurposes: resPur,
              loadedDocs: resDocs,
              loadedSites: _.keys(resSites),
              loading: false,
            });
          } catch (err) {
            console.log(err);
            cogoToast.error("Failed to load procedures");
          }
        } catch (err) {
          console.log(err);
          cogoToast.error("Couldn't fetch purposes");
        }
      } catch (err) {
        console.log(err);
        cogoToast.error("Couldn't fetch doctors");
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("Couldn't fetch sites");
    }
  };
  /** RAI STUFF ENDS */
  /** ROUTE STUFF */

  routeParamMatch = () => {
    let ss = this.props.match.params.searchStr;
    if (ss) {
      this.setState({ visibleCard: "OLD" }, () => {
        // focus.
        this.searchPatients(ss);
      });
    }
  };
  /** DOC CALENDAR STUFF */
  /** Renders */
  renderDay(day) {
    const selectedDay = day.getDate();
    let dcl = this.state.docCalendarLoaded;
    let dclKeys = _.keys(dcl);
    let idx = _.findIndex(dclKeys, (each) => areTwoDatesSame(each, day));
    const cellStyle = {
      position: "relative",
      margin: "5px",
    };
    const centerStyle = { textAlign: "center" };
    if (idx <= -1) {
      return (
        <div>
          <div style={cellStyle} className="bp5-dark">
            <ControlGroup vertical>
              <Tag minimal intent="none">
                NA
              </Tag>
              <div style={centerStyle}>{selectedDay}</div>
            </ControlGroup>
          </div>
        </div>
      );
    }

    let todaySites = _.keys(dcl[dclKeys[idx]]);
    if (!todaySites.length) {
      return (
        <div>
          <div style={cellStyle} className="bp5-dark">
            <ControlGroup vertical>
              <Tag minimal intent="none">
                NA
              </Tag>
              <div style={centerStyle}>{selectedDay}</div>
            </ControlGroup>
          </div>
        </div>
      );
    }

    let ts = todaySites.map((each) => {
      let sFlag = true;
      let s = dcl[dclKeys[idx]][each]["status"];
      let b = dcl[dclKeys[idx]][each]["booked"];
      let m = dcl[dclKeys[idx]][each]["maximum"];
      let i = "";
      if (s === "OK") {
        sFlag = false;
        if (b / m > 0.8) {
          i = "danger";
        } else if (b / m > 0.5) {
          i = "warning";
        } else {
          i = "success";
        }
      }

      return {
        site: each,
        booked: b,
        maximum: m,
        intent: i,
        status: _.startCase(_.lowerCase(s)),
        sFlag: sFlag,
      };
    });
    return (
      <div>
        <div style={cellStyle} className="bp5-dark">
          <ControlGroup vertical>
            {ts.map((each) => {
              return (
                <Tag intent={each.intent}>
                  {each.sFlag ? (
                    <>
                      {" "}
                      {each.site}: {each.status}
                    </>
                  ) : (
                    <>
                      {each.site}: {each.booked}/{each.maximum}{" "}
                    </>
                  )}
                </Tag>
              );
            })}

            <div style={centerStyle}>{selectedDay}</div>
          </ControlGroup>
        </div>
      </div>
    );
  }
  renderCalendar = () => {
    let today = new Date();
    let maxDate = new Date(today.setMonth(today.getMonth() + 14));
    if (this.state.cgChoose === "Package") {
      if (
        this.state.packageSite &&
        this.state.selectedPackage &&
        this.state.packageEye
      ) {
        return (
          <DatePicker
            dayPickerProps={{
              showOutsideDays: false,
              firstDayOfWeek: 1,
            }}
            onChange={(date) =>
              this.setState(
                {
                  packageDate: date,
                },
                () => this.getProcedureSlotsForPackage()
              )
            }
            maxDate={new Date(maxDate)}
            minDate={new Date()}
            initialMonth={new Date(this.state.currentMonth)}
            locale="en-IN"
          />
        );
      }
    }
    if (this.state.cgChoose === "Procedure") {
      if (
        this.state.procedureSite &&
        this.state.procProcedure &&
        this.state.procSubprocedure &&
        this.state.procEye
      ) {
        return (
          <DatePicker
            dayPickerProps={{
              showOutsideDays: false,
              firstDayOfWeek: 1,
            }}
            onChange={(date) =>
              this.setState(
                {
                  procDate: date,
                  loadedProcResources: [],
                  procResource: null,
                  loadedTimewiseProcSlots: {},
                  loadedTimewiseProcSlotsCalcSlotsOverall: {},
                },
                () => this.getProceduresAtSiteOn()
              )
            }
            maxDate={new Date(maxDate)}
            minDate={new Date()}
            initialMonth={new Date(this.state.currentMonth)}
            locale="en-IN"
          />
        );
      }
    }
    if (this.state.cgChoose === "Doctor") {
      if (this.state.loadingDocCalendar) {
        return <Spinner />;
      }
      if (!this.state.docCalendarLoaded) {
        return (
          <Tag minimal large>
            No data found.
          </Tag>
        );
      }

      if (this.state.selectedDocId) {
        return (
          <DatePicker
            dayPickerProps={{
              firstDayOfWeek: 1,
              onMonthChange: (date) => {
                this.setState({ currentMonth: date });
                this.getDoctorCalendar(date);
              },
              renderDay: (day) => this.renderDay(day),
              showOutsideDays: false,
            }}
            onChange={(date) =>
              this.setState({
                selectedDocDate: date,
                selectedDocDateSite: null,
                loadingDocDateSitePurposes: false,
                loadedDocDateSitePurposes: null,
                selectedDocDateSitePurpose: null,
                selectedDocDateSitePurposeWor: null,
                selectedDocDateSitePurposeIsToday: null,
                loadingDocDateSitePurposeTimeBar: false,
                loadedDocDateSitePurposeTimebars: null,
              })
            }
            maxDate={new Date(maxDate)}
            minDate={new Date()}
            initialMonth={new Date(this.state.currentMonth)}
            locale="en-IN"
          />
        );
      } else {
        return;
      }
    }
  };
  renderDocForDate = () => {
    if (this.state.cgChoose === "Doctor") {
      if (this.state.selectedDocDate) {
        return (
          <ControlGroup vertical>
            <ControlGroup>
              {" "}
              <Tag minimal large>
                Booking For:
              </Tag>
              <Tag large intent="primary">
                Booking For: {niceDateWithWeek(this.state.selectedDocDate)}
              </Tag>
            </ControlGroup>
            <ControlGroup>
              <Tag minimal large>
                Site:
              </Tag>
              {this.renderDocSiteChooser()}
            </ControlGroup>
            <ControlGroup>
              {" "}
              <Tag minimal large>
                Purpose:
              </Tag>
              {this.renderPurposeChooser()}
            </ControlGroup>
            {this.renderTimeBarChooser()}
          </ControlGroup>
        );
      }
    }
  };
  renderDocSiteChooser = () => {
    if (this.state.cgChoose !== "Doctor") {
      return null;
    }
    if (!this.state.selectedDocId) {
      return <Tag minimal>Please choose doctor.</Tag>;
    }
    if (!this.state.selectedDocDate) {
      return <Tag minimal>Please choose date.</Tag>;
    }
    const day = this.state.selectedDocDate;
    let dcl = this.state.docCalendarLoaded;
    let dclKeys = _.keys(dcl);

    let idx = _.findIndex(dclKeys, (each) => areTwoDatesSame(each, day));
    if (idx <= -1) {
      return <Tag minimal>No information found for this selection.</Tag>;
    }
    let todaySites = _.keys(dcl[dclKeys[idx]]);
    if (!todaySites.length) {
      return <Tag minimal>No schedule available for this date.</Tag>;
    }
    let ts = todaySites.map((each) => {
      let sFlag = true;
      let s = dcl[dclKeys[idx]][each]["status"];
      let t = dcl[dclKeys[idx]][each]["total"];
      let b = dcl[dclKeys[idx]][each]["booked"];
      let m = dcl[dclKeys[idx]][each]["maximum"];
      let i = "";
      if (["OK", "LEAVE (P)"].includes(s)) {
        sFlag = false;
        if (b / m > 0.8) {
          i = "danger";
        } else if (b / m > 0.5) {
          i = "warning";
        } else {
          i = "success";
        }
      }

      return {
        site: each,
        total: t,
        booked: b,
        maximum: m,
        intent: i,
        status: _.startCase(_.lowerCase(s)),
        sFlag: sFlag,
      };
    });
    return (
      <ControlGroup>
        {ts.map((each) => {
          return (
            <>
              <Button
                onClick={() =>
                  this.setState(
                    {
                      selectedDocDateSite: each.site,
                      loadingDocDateSitePurposes: false,
                      loadedDocDateSitePurposes: null,
                      selectedDocDateSitePurpose: null,
                      selectedDocDateSitePurposeWor: null,
                      selectedDocDateSitePurposeIsToday: null,
                      loadingDocDateSitePurposeTimeBar: false,
                      loadedDocDateSitePurposeTimebars: null,
                    },
                    () => {
                      this.getDoctorPurposeBySiteDate();
                    }
                  )
                }
                outlined={this.state.selectedDocDateSite !== each.site}
                disabled={each.sFlag}
                intent={each.intent}
                text={
                  each.sFlag ? (
                    <>
                      {" "}
                      {each.site}: {each.status}
                    </>
                  ) : (
                    <>
                      {each.site}: {each.booked}/{each.maximum}{" "}
                    </>
                  )
                }
              />
              {this.state.selectedDocDateSite === each.site ? (
                <Tag minimal intent={each.intent}>
                  Available Now: {each.total - each.booked}
                </Tag>
              ) : null}
            </>
          );
        })}
      </ControlGroup>
    );
  };
  renderPurposeChooser = () => {
    if (this.state.cgChoose !== "Doctor") {
      return null;
    }
    if (!this.state.selectedDocId) {
      return null;
    }
    if (!this.state.selectedDocDate) {
      return null;
    }
    if (!this.state.selectedDocDate) {
      return null;
    }
    if (this.state.loadingDocDateSitePurposes) {
      return <Spinner />;
    }
    let pKeys = _.keys(this.state.loadedDocDateSitePurposes);
    if (!pKeys.length) {
      return <Tag minimal>No purposes found.</Tag>;
    }
    let ps = pKeys.map((each) => {
      let t = this.state.loadedDocDateSitePurposes[each]["total"];
      let b = this.state.loadedDocDateSitePurposes[each]["booked"];
      let wor =
        this.state.loadedDocDateSitePurposes[each]["allowOpdWalkInsAnytime"];
      let m = this.state.loadedDocDateSitePurposes[each]["maximum"];
      let wrAmt =
        this.state.loadedDocDateSitePurposes[each]["walkInOpdReservedAmt"];
      let isToday = this.state.loadedDocDateSitePurposes[each]["isToday"];

      let nora =
        this.state.loadedDocDateSitePurposes[each]["newOpdReservedAmt"];

      let clickable = true;
      if (!isToday) {
        t = Math.round(t - (t * wrAmt) / 100);
      }
      if (t - b <= 0) {
        // total < booked
        clickable = false;
        if (isToday && wor) {
          clickable = true;
        }
      }

      let i = "";

      if (b / t > 0.8) {
        i = "danger";
      } else if (b / t > 0.5) {
        i = "warning";
      } else {
        i = "success";
      }

      return {
        purpose: each,
        total: t,
        booked: b,
        intent: i,
        maximum: m,
        clickable: clickable,
        wor: wor,
        isToday: isToday,
        nora: nora,
      };
    });
    return (
      <ControlGroup>
        {ps.map((each) => {
          return (
            <Button
              onClick={() =>
                this.setState(
                  {
                    selectedDocDateSitePurpose: each.purpose,
                    selectedDocDateSitePurposeWor: each.wor,
                    selectedDocDateSitePurposeIsToday: each.isToday,
                    loadingDocDateSitePurposeTimeBar: false,
                    loadedDocDateSitePurposeTimebars: null,
                  },
                  () => this.getDoctorTimebarByPurposeSiteDate()
                )
              }
              disabled={!each.clickable}
              outlined={this.state.selectedDocDateSitePurpose !== each.purpose}
              intent={each.intent}
              text={`${each.purpose}: ${each.booked}/${each.total} (Max: ${
                each.maximum
              }, New: ${Math.round((each.total * each.nora) / 100)})`}
            />
          );
        })}
      </ControlGroup>
    );
  };
  df = (tm) => {
    const spl = tm.split(":");
    const lf = parseInt(spl[0]);
    const rf = parseInt(spl[1]);
    return lf * 100 + rf;
  };
  renderTimeBarChooser = () => {
    if (this.state.cgChoose !== "Doctor") {
      return null;
    }
    if (!this.state.selectedDocId) {
      return null;
    }
    if (!this.state.selectedDocDate) {
      return null;
    }
    if (!this.state.selectedDocDate) {
      return null;
    }
    if (!this.state.selectedDocDateSitePurpose) {
      return null;
    }
    if (this.state.loadingDocDateSitePurposeTimeBar) {
      return <Spinner />;
    }
    let tKeys0 = _.keys(this.state.loadedDocDateSitePurposeTimebars);
    let tKeys = _.sortBy(tKeys0, (each) => this.df(each.split(" -")[0]));

    if (!tKeys.length) {
      return (
        <Tag minimal large>
          No availabilities found.
        </Tag>
      );
    }
    return tKeys.map((each, index) => {
      if (index === tKeys.length - 1) {
        return this.renderEachTimeBar(
          this.state.loadedDocDateSitePurposeTimebars[each],
          true
        );
      }
      return this.renderEachTimeBar(
        this.state.loadedDocDateSitePurposeTimebars[each]
      );
    });
  };
  // renderEachTimeBarOld = (data, isLast = false) => {
  //   let b = data.booked;
  //   let ct = data.chairtime;
  //   let sT = niceTime(new Date(data.tmStart));
  //   let eT = niceTime(new Date(data.tmEnd));
  //   let dur = data.cumulativeDuration;

  //   let tmStartM = moment(new Date(data.tmStart));
  //   let tmEndM = moment(new Date(data.tmEnd));

  //   let canBookTiming = tmStartM
  //     .clone()
  //     .add(dur + ct, "minutes")
  //     .isSameOrBefore(tmEndM);

  //   let bookingTime = tmStartM.clone().add(dur, "minutes");
  //   let intent = "";
  //   let timeInterval = tmEndM.clone().diff(tmStartM, "minutes");
  //   let tNow = new Date();
  //   let tNowM = moment(tNow);
  //   let bookingNowDiff = bookingTime.clone().isSameOrAfter(tNowM);

  //   let cb = canBookTiming;
  //   let worFlag = false;
  //   /*   console.log(
  //     "Can Book",
  //     cb,
  //     this.state.selectedDocDateSitePurposeIsToday,
  //     this.state.selectedDocDateSitePurposeWor
  //   ); */
  //   if (!cb) {
  //     if (
  //       this.state.selectedDocDateSitePurposeIsToday &&
  //       this.state.selectedDocDateSitePurposeWor
  //     ) {
  //       cb = true;
  //       worFlag = true;
  //     }
  //   }
  //   if (!bookingNowDiff && isLast) {
  //     if (
  //       this.state.selectedDocDateSitePurposeIsToday &&
  //       this.state.selectedDocDateSitePurposeWor
  //     ) {
  //       bookingNowDiff = true;
  //       cb = true;
  //       worFlag = true;
  //       bookingTime = new Date();
  //     }
  //   }
  //   cb = cb && bookingNowDiff;
  //   if (dur / timeInterval > 0.8) {
  //     intent = "danger";
  //   } else if (dur / timeInterval > 0.5) {
  //     intent = "warning";
  //   } else {
  //     intent = "success";
  //   }

  //   return (
  //     <ControlGroup>
  //       <Tag minimal>
  //         {sT} to {eT}
  //       </Tag>
  //       <Tag minimal intent={intent}>
  //         Booked: {dur} of {timeInterval} mins with {b} appointments
  //       </Tag>
  //       {cb ? (
  //         <>
  //           {" "}
  //           <Button
  //             text={niceTime(bookingTime)}
  //             icon="double-chevron-right"
  //             intent={worFlag ? "warning" : "primary"}
  //             onClick={() =>
  //               this.addConsult(
  //                 this.state.selectedDocId,
  //                 this.state.selectedDocDateSite,
  //                 this.state.selectedDocDateSitePurpose,
  //                 new Date(bookingTime),
  //                 ct,
  //                 this.state.selectedDocDateSitePurpose,
  //                 this.state.selectedDoc,
  //                 data
  //               )
  //             }
  //           />
  //           {worFlag ? (
  //             <Tag minimal large>
  //               Walk-In Overrides Allowed
  //             </Tag>
  //           ) : null}
  //         </>
  //       ) : (
  //         <Tag minimal large intent="danger">
  //           No booking possible.
  //         </Tag>
  //       )}
  //     </ControlGroup>
  //   );
  // };
  renderEachTimeBar = (data, isLast = false) => {
    // returns booked, chairtime, tmStart, tmEnd, cumulativeDuration --> for each 30 min slot.
    let b = data.booked; // total # of appts booked
    let ct = data.chairtime; // chairtime as currently defined
    let sT = niceTime(new Date(data.tmStart)); // start time
    let eT = niceTime(new Date(data.tmEnd)); // end time
    let dur = data.cumulativeDuration; // total time booked already - cumulative
    /** ADDITIONAL */
    let allowedMaxInSlot = Math.round(data.slotStaggeredTotalInChunk); // # of appts allowed in slot

    let tmStartM = moment(new Date(data.tmStart)); // moment
    let tmEndM = moment(new Date(data.tmEnd)); // moment

    let canBookTiming = tmStartM
      .clone()
      .add(dur + ct, "minutes")
      .isSameOrBefore(tmEndM);

    // if start time + cumulative + chair time <= end time --> can book.

    let bookingTime = tmStartM.clone().add(dur, "minutes"); // booking time
    let intent = ""; // UI sugar
    let timeInterval = tmEndM.clone().diff(tmStartM, "minutes"); // UI sugar
    let tNow = new Date(); // right now
    let tNowM = moment(tNow); // moment of right now
    let bookingNowDiff = bookingTime.clone().isSameOrAfter(tNowM); // booking time is in future

    let cb = canBookTiming;
    /** ADDITIONAL */
    let maxInSlotWithinLimits = b < allowedMaxInSlot; // if booked < total allowed in slot chunk, we are good. if = or > --> no booking possible.
    cb = cb && maxInSlotWithinLimits;
    let worFlag = false;

    if (!cb) {
      // start time + cum + chair time is > end time, BUT
      if (
        this.state.selectedDocDateSitePurposeIsToday && // this is today
        this.state.selectedDocDateSitePurposeWor // and there is walk in override
      ) {
        cb = true; // override
        worFlag = true; // set
      }
    }
    if (!bookingNowDiff && isLast) {
      // booking time is in past BUT this is the last time bar
      if (
        this.state.selectedDocDateSitePurposeIsToday && // this is today
        this.state.selectedDocDateSitePurposeWor // there is walk in over ride
      ) {
        bookingNowDiff = true; // override
        cb = true; // override
        worFlag = true; // set
        bookingTime = new Date(); // set forcefully
      }
    }
    cb = cb && bookingNowDiff; // therefore, can book based on availability + can book based on future OR has been overriden

    if (dur / timeInterval > 0.8) {
      intent = "danger";
    } else if (dur / timeInterval > 0.5) {
      intent = "warning";
    } else {
      intent = "success";
    }
    // UI sugar
    /** ADDITION IN SECOND TAG */
    return (
      <ControlGroup>
        <Tag minimal>
          {sT} to {eT}
        </Tag>
        <Tag minimal intent={intent}>
          Booked: {dur} of {timeInterval} mins with {b}/{allowedMaxInSlot}{" "}
          appointments
        </Tag>
        {cb ? (
          <>
            {" "}
            <Button
              text={niceTime(bookingTime)}
              icon="double-chevron-right"
              intent={worFlag ? "warning" : "primary"}
              onClick={() =>
                this.addConsult(
                  this.state.selectedDocId,
                  this.state.selectedDocDateSite,
                  this.state.selectedDocDateSitePurpose,
                  new Date(bookingTime),
                  ct,
                  this.state.selectedDocDateSitePurpose,
                  this.state.selectedDoc,
                  data
                )
              }
            />
            {worFlag ? (
              <Tag minimal large>
                Walk-In Overrides Allowed
              </Tag>
            ) : null}
          </>
        ) : (
          <Tag minimal large intent="danger">
            No booking possible.
          </Tag>
        )}
      </ControlGroup>
    );
  };

  renderResourceProcedure = () => {
    if (this.state.cgChoose === "Procedure") {
      if (
        !(
          this.state.procDate &&
          this.state.procProcedure &&
          this.state.procSubprocedure &&
          this.state.procedureSite
        )
      ) {
        return null;
      }
      if (this.state.loadingResourceProc) {
        return <Spinner />;
      }
      let ll = this.state.loadedProcResources;
      if (!ll.length) {
        return (
          <Tag minimal large>
            No resources available at {this.state.procedureSite} on{" "}
            {niceDate(this.state.procDate)} for {this.state.procProcedure} -{" "}
            {this.state.procSubprocedure}
          </Tag>
        );
      }
      let llM = ll.map((each) => {
        return { label: each.alias, _id: each._id };
      });
      if (llM.length === 1) {
        return (
          <div>
            <ControlGroup>
              <Tag minimal large>
                Resource
              </Tag>
              <Tag minimal large intent="primary">
                {llM[0].label}
              </Tag>
            </ControlGroup>
            {this.renderProcedureTimebars()}
          </div>
        );
      }
      return (
        <div>
          {" "}
          <ControlGroup>
            <Tag minimal large>
              Resource
            </Tag>
            <SingleIDSelectWrapper
              showSelectProp={true}
              items={llM}
              selectedItem={this.state.procResource}
              labelKey="label"
              onChange={(item) =>
                this.setState(
                  {
                    procResource: item,
                    loadingResourceProcTime: false,
                    loadedTimewiseProcSlots: {},
                    loadedTimewiseProcSlotsCalcSlotsOverall: {},
                  },
                  () => this.getProcedureSlotsForSubprocedureTimewise()
                )
              }
            />
          </ControlGroup>
          {this.renderProcedureTimebars()}
        </div>
      );
    }
  };
  renderProcedureTimebars = (arg) => {
    let cgChoose = this.state.cgChoose;
    if (
      cgChoose === "Procedure" &&
      !(
        this.state.procDate &&
        this.state.procProcedure &&
        this.state.procSubprocedure &&
        this.state.procedureSite
      )
    ) {
      return null;
    }
    if (
      cgChoose === "Package" &&
      !(
        this.state.packageSite &&
        this.state.selectedPackage &&
        this.state.packageEye &&
        this.state.packageDate
      )
    ) {
      return null;
    }
    if (cgChoose === "Procedure" && this.state.loadingResourceProcTime) {
      return (
        <ControlGroup>
          <Spinner />
        </ControlGroup>
      );
    }
    if (cgChoose === "Package" && this.state.loadingPackageSlots) {
      return (
        <ControlGroup>
          <Spinner />
        </ControlGroup>
      );
    }
    if (
      cgChoose === "Procedure" &&
      _.isEmpty(this.state.loadedTimewiseProcSlots)
    ) {
      return (
        <ControlGroup>
          <Tag minimal large>
            No availability found.
          </Tag>
        </ControlGroup>
      );
    }
    if (cgChoose === "Package" && _.isEmpty(arg.slots.chunkWise)) {
      return (
        <ControlGroup>
          <Tag minimal large>
            No availability found.
          </Tag>
        </ControlGroup>
      );
    }
    let ltps;
    if (cgChoose === "Procedure") {
      ltps = this.state.loadedTimewiseProcSlots;
    } else if (cgChoose === "Package") {
      ltps = arg.slots.chunkWise;
      let kk = _.keys(ltps);
      for (var k = 0; k < kk.length; k++) {
        ltps[kk[k]]["_id"] = arg._id;
        ltps[kk[k]]["procedure"] = arg.parentProcedure;
        ltps[kk[k]]["site"] = this.state.packageSite;
        ltps[kk[k]]["subprocedure"] = arg.capability;
        ltps[kk[k]]["alias"] = arg.alias;
        ltps[kk[k]]["aliasId"] = arg.aliasId;
      }
    }
    let sample = _.sample(ltps);
    let maxPerDay =
      cgChoose === "Procedure"
        ? this.state.loadedTimewiseProcSlotsCalcSlotsOverall.maximumPerDay
        : arg.slots.calcSlotsOverall.maximumPerDay;
    let wIR =
      cgChoose === "Procedure"
        ? this.state.loadedTimewiseProcSlotsCalcSlotsOverall.walkinReservedAmt
        : arg.slots.calcSlotsOverall.walkinReservedAmt;
    let isToday;
    if (cgChoose === "Procedure") {
      isToday = isDateToday(this.state.procDate);
    } else {
      isToday = isDateToday(this.state.packageDate);
    }
    let available = maxPerDay;
    if (!isToday) {
      //   available = Math.round(maxPerDay - maxPerDay * (wIR / 100));
      available = Math.round(maxPerDay - wIR);
    }
    let dd = _.values(ltps);
    let totalBookedAlready = 0;
    for (var i = 0; i < dd.length; i++) {
      totalBookedAlready += dd[i].nBooked;
    }
    let exceededSlots = totalBookedAlready >= available;
    let vv = dd.map((each) => moment(each.tmStart));
    let vvSorted = vv.sort((a, b) => a.diff(b));
    let vLast = _.last(vvSorted);
    let tNow = new Date();
    let tNowM = moment(tNow);
    let bookingNowDiff = moment(vLast).clone().isSameOrBefore(tNowM);
    let exceededTime = bookingNowDiff;
    if (!isToday) {
      if (!exceededSlots) {
        let tKeys0 = _.keys(ltps);
        let tKeys = _.sortBy(tKeys0, (each) => this.df(each.split(" -")[0]));

        if (!tKeys.length) {
          return (
            <ControlGroup>
              <Tag minimal large>
                No availabilities found.
              </Tag>
            </ControlGroup>
          );
        }

        return tKeys.map((each) => {
          return this.renderEachProcTimeBar(
            ltps[each],
            cgChoose === "Package" ? true : false
          );
        });
      } else {
        return (
          <ControlGroup>
            <Tag large intent="danger" icon="warning-sign">
              {totalBookedAlready} of {available} available slots booked
              already. No further booking possible.
            </Tag>
          </ControlGroup>
        );
      }
    } else {
      if (exceededTime) {
        return (
          <ControlGroup>
            <Tag large intent="danger" icon="warning-sign">
              This resource is available only till {niceTime(new Date(vLast))}.
              No further booking is possible.
            </Tag>{" "}
          </ControlGroup>
        );
      } else {
        if (!exceededSlots) {
          let tKeys0 = _.keys(ltps);
          let tKeys = _.sortBy(tKeys0, (each) => this.df(each.split(" -")[0]));
          if (!tKeys.length) {
            return (
              <ControlGroup>
                <Tag minimal large>
                  No availabilities found.
                </Tag>
              </ControlGroup>
            );
          }
          return tKeys.map((each) => {
            return this.renderEachProcTimeBar(
              ltps[each],
              cgChoose === "Package" ? true : false
            );
          });
        } else {
          let timeNow = new Date();
          let timeToBook = moment(timeNow).clone().add(10, "minutes").toDate();
          return (
            <ControlGroup vertical>
              <ControlGroup>
                <Tag large intent="danger" icon="warning-sign">
                  {totalBookedAlready} of {available} available slots booked
                  already.
                </Tag>{" "}
              </ControlGroup>
              <ControlGroup>
                <Tag minimal large>
                  Override:
                </Tag>
                <Button
                  text={niceTime(timeToBook)}
                  intent="primary"
                  icon="double-chevron-right"
                  onClick={() => {
                    if (cgChoose !== "Package") {
                      this.addProc(
                        this.state.procEye,
                        this.state.procResource._id,
                        this.state.procProcedure,
                        this.state.procSubprocedure,
                        this.state.procedureSite,
                        sample.chairtime,
                        new Date(timeToBook),
                        this.state.procResource
                      );
                    } else {
                      this.addProcToPackage(
                        this.state.packageEye,
                        arg._id,
                        arg.parentProcedure,
                        arg.capability,
                        this.state.packageSite,
                        sample.chairtime,
                        new Date(timeToBook),
                        arg
                      );
                    }
                  }}
                />
              </ControlGroup>
            </ControlGroup>
          );
        }
      }
    }
  };
  renderEachProcTimeBar = (data, isPackage) => {
    let b = data.nBooked;
    let ct = data.chairtime;
    let sT = niceTime(new Date(data.tmStart));
    let eT = niceTime(new Date(data.tmEnd));
    let dur = data.cumulativeDuration;
    let tmStartM = moment(new Date(data.tmStart));
    let tmEndM = moment(new Date(data.tmEnd));
    let ctM =
      this.state.procEye === "BE"
        ? ct * data.ophthalmicChairtimeMultiplier
        : ct;
    let canBookTiming = tmStartM
      .clone()
      .add(dur + ctM, "minutes")
      .isSameOrBefore(tmEndM);
    let bookingTime = tmStartM.clone().add(dur, "minutes");
    let intent = "";
    let timeInterval = tmEndM.clone().diff(tmStartM, "minutes");
    let tNow = new Date();
    let tNowM = moment(tNow);
    let bookingNowDiff = bookingTime.clone().isSameOrAfter(tNowM);
    let cb = canBookTiming && bookingNowDiff;
    let worFlag = false;
    if (!cb) {
      if (isDateToday(this.state.procDate)) {
        cb = true;
        worFlag = true;
      }
    }
    cb = cb && bookingNowDiff;

    if (dur / timeInterval > 0.8) {
      intent = "danger";
    } else if (dur / timeInterval > 0.5) {
      intent = "warning";
    } else {
      intent = "success";
    }
    return (
      <ControlGroup>
        {isPackage ? null : (
          <>
            {" "}
            <Tag minimal>
              {sT} to {eT}
            </Tag>
            <Tag minimal intent={intent}>
              Booked: {dur} of {timeInterval} mins with {b} procedures
            </Tag>
          </>
        )}

        {cb ? (
          <>
            <Button
              minimal={isPackage}
              text={niceTime(bookingTime)}
              icon="double-chevron-right"
              intent={worFlag && !isPackage ? "warning" : "primary"}
              onClick={() => {
                if (!isPackage) {
                  this.addProc(
                    this.state.procEye,
                    this.state.procResource._id,
                    this.state.procProcedure,
                    this.state.procSubprocedure,
                    this.state.procedureSite,
                    ctM,
                    new Date(bookingTime),
                    this.state.procResource
                  );
                } else {
                  this.addProcToPackage(
                    this.state.packageEye,
                    data._id,
                    data.procedure,
                    data.subprocedure,
                    this.state.packageSite,

                    ctM,
                    new Date(bookingTime),
                    data
                  );
                }
              }}
            />
            {worFlag && !isPackage ? (
              <Tag minimal large>
                Walk-In Overrides Allowed
              </Tag>
            ) : null}
          </>
        ) : (
          <>
            {isPackage ? null : (
              <Tag minimal large intent="danger">
                No booking possible.
              </Tag>
            )}
          </>
        )}
      </ControlGroup>
    );
  };
  renderPackageProcedureTimebars = () => {
    if (this.state.cgChoose === "Package") {
      if (this.state.loadingPackageSlots) {
        return (
          <ControlGroup>
            <Spinner />
          </ControlGroup>
        );
      }
      if (
        this.state.selectedPackage &&
        this.state.packageDate &&
        !this.state.componentSlots.length
      ) {
        return (
          <ControlGroup>
            <Tag minimal large>
              No component slots found.
            </Tag>
          </ControlGroup>
        );
      }

      return this.state.componentSlots.map((each) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginBottom: "10px",
            }}
          >
            <div>
              <ControlGroup vertical>
                <Tag intent="success">{each.parentProcedure}</Tag>
                <Tag minimal intent="success">
                  {each.capability}
                </Tag>
                <Tag minimal intent="primary">
                  {each.alias}
                </Tag>
              </ControlGroup>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                maxWidth: "80%",
                marginLeft: "5px",
              }}
            >
              {this.renderProcedureTimebars(each)}
            </div>
          </div>
        );
      });
    }
  };
  renderConsult = () => {
    if (!this.state.consultationBookings.length) {
      return (
        <ControlGroup>
          <Tag minimal large>
            No consultations added.
          </Tag>
        </ControlGroup>
      );
    }
    return this.state.consultationBookings.map((each) => {
      return (
        <ControlGroup>
          <Tag intent="primary" large>
            {each.doc.name}
          </Tag>
          <Tag intent="primary" large minimal>
            {each.site}
          </Tag>
          <Tag intent="warning" large minimal>
            {each.purpose}
          </Tag>
          <Tag intent="warning" large minimal>
            {niceDateTimeWithWeek(each.time)}
          </Tag>
          <Tag intent="warning" large minimal>
            {each.chairTime} mins
          </Tag>
          {each.bookingResult ? (
            <Button
              outlined
              icon="duplicate"
              minimal
              onClick={() => {
                const str = `${each.doc.name} - ${
                  each.site
                } - ${niceDateTimeWithWeek(each.time)} - ${
                  each.bookingResult.statusOk
                    ? each.bookingResult.docketNo
                    : each.bookingResult.error
                }`;
                copyToClipboard(str);
              }}
              large
            >
              {each.bookingResult.statusOk
                ? each.bookingResult.docketNo
                : each.bookingResult.error}
            </Button>
          ) : (
            <Button
              intent="danger"
              icon="trash"
              minimal
              onClick={() =>
                this.deleteAppt(each.nanoid, "consultationBookings")
              }
            />
          )}
        </ControlGroup>
      );
    });
  };
  renderProcedures = (arg) => {
    let dd = arg || this.state.procedureBookings;
    if (!dd.length) {
      return (
        <ControlGroup>
          <Tag minimal large>
            No procedures added.
          </Tag>
        </ControlGroup>
      );
    }

    return dd.map((each) => {
      return (
        <ControlGroup>
          <Tag intent="primary" large>
            {each.procedure}
          </Tag>
          <Tag intent="primary" large minimal>
            {each.subprocedure}
          </Tag>
          {_.find(this.state.loadedProcs, (e) => e.shortname === each.procedure)
            .isSystemic ? null : (
            <Tag minimal large>
              {each.applicableEyes}
            </Tag>
          )}
          <Tag intent="primary" large minimal>
            {each.site}
          </Tag>
          <Tag intent="warning" large minimal>
            {each.resource.label}
          </Tag>
          <Tag intent="warning" large minimal>
            {niceDateTimeWithWeek(each.time)}
          </Tag>
          <Tag intent="warning" large minimal>
            {each.chairTime} mins
          </Tag>
          {arg ? null : (
            <>
              <Tag minimal large>
                Referred By:
              </Tag>
              {each.bookingResult ? (
                <Tag large minimal>
                  {each.referringDoc.name}
                </Tag>
              ) : (
                <SingleIDSelectWrapper
                  showSelectProp={true}
                  width="200px"
                  items={this.state.loadedDocs.map((each) => {
                    return {
                      ...each,
                      searchName: `${each.shortcode || ""} | ${each.name}`,
                    };
                  })}
                  selectedItem={each.referringDoc || null}
                  labelKey="searchName"
                  onChange={(item) => {
                    this.updateProcWithReferDoc(
                      each.nanoid,
                      "referringDoc",
                      item,
                      false
                    );
                    this.updateProcWithReferDoc(
                      each.nanoid,
                      "referringDoctorId",
                      item._id,
                      false
                    );
                  }}
                />
              )}

              {each.bookingResult ? (
                <>
                  <Tag
                    minimal
                    large
                    intent={each.bookingResult.statusOk ? "success" : "warning"}
                  >
                    {each.bookingResult.statusOk
                      ? each.bookingResult.docketNo
                      : each.bookingResult.error}
                  </Tag>
                </>
              ) : (
                <Button
                  intent="danger"
                  icon="trash"
                  minimal
                  onClick={() =>
                    this.deleteAppt(each.nanoid, "procedureBookings")
                  }
                />
              )}
            </>
          )}
        </ControlGroup>
      );
    });
  };
  renderPackage = () => {
    if (!this.state.packageBookings.length) {
      return (
        <ControlGroup>
          <Tag minimal large>
            No packages added.
          </Tag>
        </ControlGroup>
      );
    }
    return this.state.packageBookings.map((each) => {
      let c = each.components;
      let sample = _.sample(c);
      let flag = false;
      if (sample.bookingResult) {
        flag = true;
      }
      return (
        <Card
          style={{ background: "none", padding: "0px" }}
          elevation={Elevation.FOUR}
        >
          <ControlGroup vertical>
            <ControlGroup>
              <Tag large intent="success">
                {each.packageAlias}
              </Tag>
              <Tag large minimal intent="success">
                Comprising {each.components.length} underlying procedures
              </Tag>
              <Tag minimal large>
                Referred By:
              </Tag>
              <SingleIDSelectWrapper
                showSelectProp={true}
                width="200px"
                items={this.state.loadedDocs.map((each) => {
                  return {
                    ...each,
                    searchName: `${each.shortcode || ""} | ${each.name}`,
                  };
                })}
                selectedItem={each.referringDoc || null}
                labelKey="searchName"
                onChange={(item) => {
                  this.updateProcWithReferDoc(
                    each.nanoid,
                    "referringDoc",
                    item,
                    true
                  );
                  this.updateProcWithReferDoc(
                    each.nanoid,
                    "referringDoctorId",
                    item._id,
                    true
                  );
                }}
              />
              {!flag ? (
                <Button
                  intent="danger"
                  icon="trash"
                  minimal
                  onClick={() =>
                    this.deleteAppt(each.nanoid, "packageBookings")
                  }
                />
              ) : (
                <Tag minimal large>
                  Booked
                </Tag>
              )}
            </ControlGroup>
            {this.renderProcedures(each.components)}
          </ControlGroup>
        </Card>
      );
    });
  };
  renderPackageCompletion = () => {
    if (this.state.cgChoose !== "Package") {
      return null;
    }
    if (!this.state.currentlyDraftingPackage.packageId) {
      return null;
    }
    if (!this.state.packageDate) {
      return null;
    }
    let cp = this.state.currentlyDraftingPackage;
    let cpcc = cp.components;
    let ct = cpcc.map((each) => {
      return {
        pp: each.parentProcedure,
        child: each.capability,
        chosenTime: each.chosenTime,
        time: each.payload ? each.payload.time : null,
      };
    });
    let flag = true;
    for (var i = 0; i < ct.length; i++) {
      if (!ct[i].chosenTime) {
        flag = false;
      }
    }
    return (
      <ControlGroup>
        <Tag minimal>Time Chosen For: </Tag>
        {ct.map((each) => {
          return (
            <ControlGroup vertical>
              <Tag
                intent={each.chosenTime ? "success" : "danger"}
                minimal={each.chosenTime}
              >
                {each.pp} - {each.child}
              </Tag>
              <Tag
                minimal={each.chosenTime}
                icon="timeline-events"
                intent={each.chosenTime ? "success" : "danger"}
              >
                {each.chosenTime ? niceDateTime(each.time) : "Not chosen"}
              </Tag>
            </ControlGroup>
          );
        })}
        {flag ? (
          <Button
            minimal
            icon="endorsed"
            intent="success"
            onClick={() => this.addPackage()}
          />
        ) : null}
      </ControlGroup>
    );
  };
  /** APIs */
  addConsult = (
    doctorId,
    site,
    purpose,
    time,
    chairTime,
    slotPurpose,
    doc,
    slotData
  ) => {
    let payload = {
      doctorId: doctorId,
      site: site,
      purpose: purpose,
      time: time,
      chairTime: chairTime,
      slotPurpose: slotPurpose,
      doc: doc,
      nanoid: nanoid(),
      slotData: slotData,
    };
    let c = this.state.consultationBookings;
    c.push(payload);
    this.setState({ consultationBookings: c }, () =>
      cogoToast.success("Added consultation")
    );
  };
  deleteAppt = (nanoid, type) => {
    let c = this.state[type];
    let idx = _.findIndex(c, (each) => each.nanoid === nanoid);
    c.splice(idx, 1);
    this.setState({ [type]: c });
  };
  addProc = (
    applicableEyes,
    resourceId,
    procedure,
    subprocedure,
    site,
    chairTime,
    time,
    resource
  ) => {
    let payload = {
      applicableEyes: applicableEyes,
      resourceId: resourceId,
      procedure: procedure,
      time: time,
      chairTime: chairTime,
      subprocedure: subprocedure,
      resource: resource,
      site: site,
      nanoid: nanoid(),
    };
    let c = this.state.procedureBookings;
    c.push(payload);
    this.setState({ procedureBookings: c }, () =>
      cogoToast.success("Added procedure")
    );
  };
  addProcToPackage = (
    applicableEyes,
    resourceId,
    procedure,
    subprocedure,
    site,
    chairTime,
    time,
    resource
  ) => {
    let payload = {
      applicableEyes: applicableEyes,
      resourceId: resourceId,
      procedure: procedure,
      time: time,
      chairTime: chairTime,
      subprocedure: subprocedure,
      resource: resource,
      site: site,
    };
    let c = this.state.currentlyDraftingPackage;
    let cc = c.components;
    let idx = _.findIndex(cc, (each) => each._id === resourceId);
    cc[idx]["payload"] = payload;
    cc[idx]["payload"]["resource"]["label"] = resource.alias;
    cc[idx]["chosenTime"] = true;

    c.components = cc;
    this.setState({ currentlyDraftingPackage: c });
  };
  addPackage = () => {
    let p = this.state.packageBookings;
    let c = this.state.currentlyDraftingPackage;
    let payload = {
      packageAlias: c.packageAlias,
      packageId: c.packageId,
      components: [],
      nanoid: nanoid(),
    };

    let cc = [];
    for (var x = 0; x < c.components.length; x++) {
      let y = {
        applicableEyes: c.components[x].payload.applicableEyes,
        resourceId: c.components[x].payload.resource.aliasId,
        procedure: c.components[x].payload.procedure,
        subprocedure: c.components[x].payload.subprocedure,
        site: c.components[x].payload.site,
        chairTime: c.components[x].payload.chairTime,
        time: c.components[x].payload.time,
        resource: c.components[x].payload.resource,
      };
      cc.push(y);
    }
    payload.components = cc;
    p.push(payload);
    this.setState(
      {
        packageBookings: p,
        selectedPackage: null,
        packageEye: null,
        packageDate: null,
        loadingPackageSlots: false,
        componentSlots: [],
        currentlyDraftingPackage: {
          nanoid: null,
          packageId: null,
          packageAlias: null,
          components: [],
        },
      },
      () => cogoToast.success("Added Package.")
    );
  };
  addSlotToBooking = (bookingTime, ct) => {
    let obj = {
      when: niceTime(bookingTime),
      chairtime: ct,
      doc: this.state.selectedDoc.name,
      site: this.state.selectedDocDateSite,
      purpose: this.state.selectedDocDateSitePurpose,
      date: this.state.selectedDocDate,
    };
    this.setState({ selectedSlot: obj });
  };
  getDoctorCalendar = async (date) => {
    if (!this.state.choosingByDoctor) {
      return null;
    }
    if (!this.state.selectedDocId) {
      return null;
    }

    try {
      this.setState({
        loadingDocCalendar: true,
        docCalendarLoaded: null,
        selectedDocDate: null,
        selectedDocDateSite: null,
      });
      let res = await apiCallPost(
        `/chrono/${this.props.authStore.prefix}/getDoctorCalendar`,
        {
          doctorId: this.state.selectedDocId,
          startDate: new Date(new Date(date).setDate(1)),
          endDate: new Date(new Date(date).setDate(lastDayOfMonth(date))),
        }
      );
      this.setState({ loadingDocCalendar: false, docCalendarLoaded: res });
    } catch (err) {
      this.setState({ loadingDocCalendar: false });
      console.log(err);
    }
  };
  getDoctorPurposeBySiteDate = async () => {
    if (
      !this.state.selectedDocId ||
      !this.state.selectedDocDate ||
      !this.state.selectedDocDateSite
    ) {
      return;
    }
    try {
      this.setState({ loadingDocDateSitePurposes: true });
      let res = await apiCallPost(
        `/chrono/${this.props.authStore.prefix}/getDoctorSlotsAt`,
        {
          doctorId: this.state.selectedDocId,
          date: this.state.selectedDocDate,
          site: this.state.selectedDocDateSite,
        }
      );
      this.setState({
        loadedDocDateSitePurposes: res,
        loadingDocDateSitePurposes: false,
      });
    } catch (err) {
      this.setState({ loadingDocDateSitePurposes: false });
      console.log(err);
      cogoToast.error("We ran into an error.");
    }
  };
  getDoctorTimebarByPurposeSiteDate = async () => {
    if (
      !this.state.selectedDocId ||
      !this.state.selectedDocDate ||
      !this.state.selectedDocDateSite ||
      !this.state.selectedDocDateSitePurpose
    ) {
      return;
    }
    let leaves = [];
    let dtSite =
      this.state.docCalendarLoaded[this.state.selectedDocDate][
        this.state.selectedDocDateSite
      ];
    if (dtSite.status === "LEAVE (P)") {
      try {
        this.setState({ loadingDocDateSitePurposeTimeBar: true });
        let resLeaves = await apiCallPost(
          `/chrono/${this.props.authStore.prefix}/getLeavesAt`,
          {
            doctorId: this.state.selectedDocId,
            date: this.state.selectedDocDate,
            site: this.state.selectedDocDateSite,
            bookend: dtSite.leaveDayType,
          }
        );
        leaves = resLeaves;
      } catch (err) {
        console.log(err);
        cogoToast.error("We ran into an error checking for leaves.");
      }
    }

    try {
      this.setState({ loadingDocDateSitePurposeTimeBar: true });
      let res = await apiCallPost(
        `/chrono/${this.props.authStore.prefix}/getDoctorSlotsForPurposeTimewise`,
        {
          doctorId: this.state.selectedDocId,
          date: this.state.selectedDocDate,
          site: this.state.selectedDocDateSite,
          purpose: this.state.selectedDocDateSitePurpose,
        }
      );
      if (leaves.length) {
        let resCopy = res;
        for (let lv of leaves) {
          let { from, to } = lv;
          for (let key in resCopy) {
            let isInvalidStart = moment(resCopy[key].tmStart).isSameOrAfter(
              moment(from)
            );
            let isInvalidEnd = moment(resCopy[key].tmEnd).isSameOrBefore(
              moment(to)
            );
            if (isInvalidStart && isInvalidEnd) {
              delete res[key];
            }
          }
        }
        this.setState({
          loadedDocDateSitePurposeTimebars: res,
          loadingDocDateSitePurposeTimeBar: false,
        });
      } else {
        this.setState({
          loadedDocDateSitePurposeTimebars: res,
          loadingDocDateSitePurposeTimeBar: false,
        });
      }
    } catch (err) {
      this.setState({ loadingDocDateSitePurposeTimeBar: false });
      console.log(err);
      cogoToast.error("We ran into an error.");
    }
  };
  getProceduresAtSiteOn = async () => {
    try {
      this.setState({ loadingResourceProc: true });
      let res = await apiCallPost(
        `/chrono/${this.props.authStore.prefix}/getProceduresAtSiteOn`,
        {
          site: this.state.procedureSite,
          date: this.state.procDate,
          procedure: this.state.procProcedure,
          subprocedure: this.state.procSubprocedure,
        }
      );
      this.setState(
        { loadingResourceProc: false, loadedProcResources: res },
        () => {
          if (res.length === 1) {
            let llM = res.map((each) => {
              return { label: each.alias, _id: each._id };
            });
            this.singleResourceSpecialCase(llM[0]);
          }
        }
      );
    } catch (err) {
      this.setState({ loadingResourceProc: false });

      console.log(err);
      cogoToast.error("Unable to get booking availabilities.");
    }
  };
  singleResourceSpecialCase = (item) => {
    this.setState(
      {
        procResource: item,
        loadingResourceProcTime: false,
        loadedTimewiseProcSlots: {},
        loadedTimewiseProcSlotsCalcSlotsOverall: {},
      },
      () => this.getProcedureSlotsForSubprocedureTimewise()
    );
  };
  getProcedureSlotsForSubprocedureTimewise = async () => {
    // First check if the date selected is valid.

    try {
      this.setState({ loadingResourceProcTime: true });
      let resDates = await apiCallPost(
        `/chrono/${this.props.authStore.prefix}/getClosuresAndHolidays`,
        {
          startDate: this.state.procDate,
          endDate: this.state.procDate,
          site: this.state.procedureSite,
        }
      );
      if (resDates[0].status !== "OK") {
        cogoToast.info(
          "The selected date is not available for booking. Reason: " +
            _.startCase(_.lowerCase(resDates[0].status))
        );
        this.setState({ loadingResourceProcTime: false });
        return;
      }
    } catch (err) {
      console.log(err);
      cogoToast.error("Failed to get available dates.");
      this.setState({ loadingResourceProcTime: false });
    }
    try {
      this.setState({ loadingResourceProcTime: true });
      let res = await apiCallPost(
        `/chrono/${this.props.authStore.prefix}/getProcedureSlotsForSubprocedureTimewise`,
        {
          site: this.state.procedureSite,
          date: this.state.procDate,
          procedure: this.state.procProcedure,
          subprocedure: this.state.procSubprocedure,
          resourceId: this.state.procResource._id,
        }
      );

      this.setState({
        loadingResourceProcTime: false,
        loadedTimewiseProcSlots: res.chunkWise,
        loadedTimewiseProcSlotsCalcSlotsOverall: res.calcSlotsOverall,
      });
    } catch (err) {
      this.setState({ loadingResourceProcTime: false });

      console.log(err);
      cogoToast.error("Unable to get booking availabilities.");
    }
  };
  getPackagesAtSiteOn = async () => {
    try {
      this.setState({ loadingPackagesAtSite: true });
      let res = await apiCallPost(
        `/chrono/${this.props.authStore.prefix}/getPackagesAtSiteOn`,
        {
          site: this.state.packageSite,
        }
      );

      this.setState({
        loadingPackagesAtSite: false,
        loadedPackagesAtSite: res,
      });
    } catch (err) {
      this.setState({ loadingPackagesAtSite: false });
      cogoToast.error("Failed to load packages at site.");
      console.log(err);
    }
  };
  getProcedureSlotsForPackage = async () => {
    let comp = this.state.selectedPackage.components;
    this.setState({ loadingPackageSlots: true });
    let result = await Promise.all(
      comp.map((each) => {
        return apiCallPost(
          `/chrono/${this.props.authStore.prefix}/getProcedureSlotsForSubprocedureTimewise`,
          {
            site: this.state.packageSite,
            date: this.state.packageDate,
            procedure: each.parentProcedure,
            subprocedure: each.capability,
            resourceId: each.aliasId,
          }
        );
      })
    );

    let isDateOkayRes = await apiCallPost(
      `/chrono/${this.props.authStore.prefix}/getClosuresAndHolidays`,
      {
        site: this.state.packageSite,
        startDate: this.state.packageDate,
        endDate: this.state.packageDate,
      }
    );
    if (isDateOkayRes[0].status !== "OK") {
      cogoToast.info(
        "The selected date is not available for booking. Reason: " +
          _.startCase(_.lowerCase(isDateOkayRes[0].status))
      );
      let cc0 = comp.map((each) => {
        return { ...each, slots: [] };
      });
      this.setState({ loadingPackageSlots: false, componentSlots: cc0 });
      return;
    } else {
      let cc = comp.map((each, index) => {
        return { ...each, slots: result[index] };
      });
      this.setState({ componentSlots: cc, loadingPackageSlots: false });
    }
  };
  updateProcWithReferDoc = (nanoid, param, value, isPackage) => {
    if (!isPackage) {
      let c = this.state.procedureBookings;
      let itemIdx = _.findIndex(c, (each) => each.nanoid === nanoid);
      _.set(c[itemIdx], param, value);
      this.setState({ procedureBookings: c });
    } else {
      let c = this.state.packageBookings;
      let itemIdx = _.findIndex(c, (each) => each.nanoid === nanoid);
      _.set(c[itemIdx], param, value);

      this.setState({ packageBookings: c });
    }
  };

  /** DOC CALENDAR STUFF ENDS */

  /** HISTORY STUFF */
  /**Renders */
  renderSymptomSearch = () => {
    return (
      <div>
        <ControlGroup>
          <Tag minimal>Search and Add Symptoms</Tag>{" "}
          <AsyncSelWrap
            placeholder="Symptoms..."
            searching={this.state.symSearching}
            items={this.state.symSearchResults}
            selectedItem={null}
            update={(item) => this.searchSymptoms(item)}
            onChange={(item) => {
              let dd = item.data;
              dd["_id"] = item._id;
              dd["_id_sr"] = item._id;
              dd["type"] = "new";
              dd["checked"] = false;
              let idx = _.findIndex(
                this.state.symptoms,
                (each) => each._id === item._id
              );
              if (idx <= -1) {
                this.setState({ symptoms: [dd, ...this.state.symptoms] });
              } else {
                cogoToast.error(
                  "It seems that the symptom you selected is already added."
                );
              }
            }}
          />
        </ControlGroup>
        <ControlGroup vertical>{this.renderSymptoms()}</ControlGroup>
      </div>
    );
  };
  renderSymptoms = () => {
    if (!this.state.symptoms.length) {
      return (
        <ControlGroup>
          <Tag minimal large intent="danger">
            No symptoms added.
          </Tag>
        </ControlGroup>
      );
    }
    return this.state.symptoms.map((each) => {
      return (
        <ControlGroup>
          <Symptom
            data={each}
            isSmall={true}
            remove={(id) => {
              let sym = this.state.symptoms;
              let idx = _.findIndex(sym, (each) => each._id === id);
              sym.splice(idx, 1);
              this.setState({ symptoms: sym });
            }}
            update={(id, prop, val) => {
              let sym = this.state.symptoms;
              let idx = _.findIndex(sym, (each) => each._id === id);
              let ss = sym[idx];
              _.set(ss, prop, val);
              sym[idx] = ss;
              this.setState({ symptoms: sym });
            }}
          />
        </ControlGroup>
      );
    });
  };
  renderSysDxSearch = () => {
    return (
      <div>
        <ControlGroup>
          <Tag minimal>Search and Add Systemic History</Tag>{" "}
          <AsyncSelWrap
            placeholder="Systemic Diseases..."
            searching={this.state.sysDxSearching}
            items={this.state.sysDxSearchResults}
            selectedItem={null}
            update={(item) => this.searchSysDx(item)}
            onChange={(item) => {
              let dd = item.data;
              dd["_id"] = item._id;
              dd["_id_sr"] = item._id;
              dd["type"] = "new";
              dd["checked"] = false;
              dd["onRx"] = true;
              let idx = _.findIndex(
                this.state.sysDx,
                (each) => each._id === item._id
              );
              if (idx <= -1) {
                this.setState({ sysDx: [dd, ...this.state.sysDx] });
              } else {
                cogoToast.error(
                  "It seems that the systemic disease you selected is already added."
                );
              }
            }}
          />
        </ControlGroup>
        <ControlGroup vertical>{this.renderSysDx()}</ControlGroup>
      </div>
    );
  };
  renderSysDx = () => {
    if (!this.state.sysDx.length) {
      return (
        <ControlGroup>
          <Tag minimal large intent="danger">
            No systemic diseases added.
          </Tag>
        </ControlGroup>
      );
    }
    return this.state.sysDx.map((each) => {
      return (
        <ControlGroup>
          <SysDx
            data={each}
            onRemove={() => {
              let sysDx = this.state.sysDx;
              let idx = _.findIndex(sysDx, (e) => e._id === each._id);
              sysDx.splice(idx, 1);
              this.setState({ sysDx: sysDx });
            }}
            onUpdate={(prop, val) => {
              let sysDx = this.state.sysDx;
              let idx = _.findIndex(sysDx, (e) => e._id === each._id);
              let ss = sysDx[idx];
              _.set(ss, prop, val);
              sysDx[idx] = ss;
              this.setState({ sysDx: sysDx });
            }}
          />
        </ControlGroup>
      );
    });
  };
  /** APIs */
  searchSymptoms = async (string) => {
    if (!string) {
      this.setState({ symSearchResults: [] });
      return;
    }
    try {
      this.setState({ symSearching: true });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/unisearch`,
        { prefix: "sym", query: string }
      );
      this.setState({ symSearchResults: res, symSearching: false });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  searchSysDx = async (string) => {
    if (!string) {
      this.setState({ sysDxSearchResults: [] });
      return;
    }
    try {
      this.setState({ sysDxSearching: true });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/unisearch`,
        { prefix: "sys-dx", query: string }
      );
      this.setState({ sysDxSearchResults: res, sysDxSearching: false });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  /** HISTORY STUFF ENDS */

  /** ANNOUNCEMENTS */
  /** Renders */
  renderAnnouncements = () => {
    if (this.state.loadingAnn) {
      return <Spinner />;
    }
    if (!this.state.loadedAnn.length) {
      return (
        <ControlGroup>
          <Tag minimal large>
            No announcements found.
          </Tag>
        </ControlGroup>
      );
    }
    return this.state.loadedAnn.map((each) => {
      return (
        <Card
          elevation={Elevation.FOUR}
          style={{
            marginTop: "20px",
            marginRight: "20px",
            backgroundColor: "#26292c",
          }}
        >
          <div>
            <ControlGroup>
              <Tag minimal intent="primary">
                {each.userName}
              </Tag>
              <Tag minimal intent="warning">
                {niceDateTime(each.ts)}
              </Tag>
            </ControlGroup>
          </div>
          <br />
          {each.message}
        </Card>
      );
    });
  };
  /** APIs */
  getAnnouncements = async () => {
    try {
      this.setState({ loadingAnn: true });
      let res = await apiCallPost(
        `/chrono/${this.props.authStore.prefix}/getBookingAnnouncements`,
        {}
      );

      this.setState({ loadingAnn: false, loadedAnn: res });
    } catch (err) {
      console.log(err);
      cogoToast.error(
        "There was an error fetching announcements. Please try again."
      );
      this.setState({ loadingAnn: false });
    }
  };
  /** ANNOUNCEMENTS ENDS */

  /** STATS */
  /** Renders */
  renderStats = () => {};

  /** APIs */
  getStats = () => {};

  /** STATS ENDS */

  /** BOOKING - PATIENT */
  renderNewPatientForm = () => {
    return (
      <div>
        <Card
          elevation={Elevation.FOUR}
          style={{
            backgroundColor: "#26292c",
            marginRight: "20px",
          }}
        >
          <ControlGroup>
            <Tag minimal large>
              New Patient
            </Tag>
          </ControlGroup>
          <br />
          <ControlGroup vertical>
            <ControlGroup>
              <Tag minimal>Name</Tag>
              <InputGroup
                value={this.state.nPtName}
                onChange={(event) =>
                  this.setState({ nPtName: event.target.value })
                }
              />
            </ControlGroup>
            <ControlGroup>
              <Tag minimal>Phone</Tag>
              <InputGroup
                value={this.state.nPtPhone}
                onChange={(event) =>
                  this.setState({ nPtPhone: event.target.value })
                }
              />
            </ControlGroup>
            {/*  <ControlGroup>
              <Tag minimal>WhatsApp</Tag>
              <InputGroup
                value={this.state.nPtWhatsApp}
                onChange={(event) =>
                  this.setState({ nPtWhatsApp: event.target.value })
                }
              />
            </ControlGroup> */}
            <ControlGroup>
              <Tag minimal>Sex</Tag>
              <SingleSelectWrapper
                selectedItem={this.state.nPtSex}
                items={["Male", "Female", "Trans", "Non-Binary", "Other"]}
                onChange={(item) => this.setState({ nPtSex: item })}
              />
            </ControlGroup>
            <ControlGroup>
              <Tag minimal>DoB</Tag>
              {this.state.nPtDoB ? (
                <>
                  {!this.state.editingDoB ? (
                    <Tag
                      minimal
                      large
                      interactive
                      onClick={() => this.setState({ editingDoB: true })}
                    >
                      {niceDate(this.state.nPtDoB)}
                    </Tag>
                  ) : (
                    <DateEntry
                      date={this.state.nPtDoB}
                      onUpdate={(d) =>
                        this.setState({ nPtDoB: d, editingDoB: false })
                      }
                    />
                  )}
                </>
              ) : (
                <DateEntry
                  date={this.state.nPtDoB}
                  onUpdate={(d) =>
                    this.setState({ nPtDoB: d, editingDoB: false })
                  }
                />
              )}
            </ControlGroup>
            <ControlGroup vertical>
              <ControlGroup>
                <Tag minimal>Address</Tag>
                <InputGroup
                  value={this.state.nPtAddress}
                  onChange={(event) =>
                    this.setState({ nPtAddress: event.target.value })
                  }
                  rightElement={
                    this.state.nPtAddress ? (
                      <Button
                        minimal
                        icon="geosearch"
                        onClick={() => this.getGeoCode()}
                      />
                    ) : null
                  }
                />
              </ControlGroup>
              {this.state.nPtAddress ? (
                <ControlGroup>
                  <Tag minimal>Geocode</Tag>
                  <Tag minimal>
                    {this.state.nPtGeoCode
                      ? this.state.nPtGeoCodeText
                      : "No geocode added."}
                  </Tag>
                </ControlGroup>
              ) : null}
              {this.props.authStore.supportLegacyPid ? (
                <ControlGroup>
                  <Tag minimal>Legacy PID</Tag>
                  <InputGroup
                    value={this.state.nPtLegacyPid}
                    onChange={(event) =>
                      this.setState({ nPtLegacyPid: event.target.value })
                    }
                  />
                </ControlGroup>
              ) : null}
              {this.props.authStore.supportAddlSearch ? (
                <ControlGroup>
                  <Tag minimal>Addl. Search Terms (Old MRD Numbers, etc)</Tag>
                  <InputGroup
                    value={this.state.nPtAddleSearchTerm}
                    onChange={(event) =>
                      this.setState({ nPtAddleSearchTerm: event.target.value })
                    }
                  />
                </ControlGroup>
              ) : null}
            </ControlGroup>
          </ControlGroup>
        </Card>
      </div>
    );
  };
  renderOldPatientDetails = () => {
    return (
      <div>
        <Card
          elevation={Elevation.FOUR}
          style={{
            backgroundColor: "#26292c",
            marginRight: "20px",
          }}
        >
          <ControlGroup>
            <Tag minimal>Search</Tag>
            <AsyncSelWrap
              autoFocus={true}
              placeholder="Patients..."
              searching={this.state.oldSearching}
              items={_.take(this.state.oldSearchResults, 10)}
              selectedItem={null}
              update={(item) => this.searchPatients(item)}
              onChange={(item) => {
                this.setState(
                  {
                    oPtName: item.item,
                    oPtPid: item.entityTypeString,
                    oPtPhone: item.helper,
                    oPtAge: item.helper2,
                    oPt: item.data,
                  },
                  () => {
                    this.promoteOld(item.data);
                    this.getRxOld(this.state.oPtPid);
                  }
                );
              }}
            />
          </ControlGroup>
          <br />
          {this.state.oPt ? (
            <>
              <ControlGroup>
                <Tag large minimal intent="success">
                  {this.state.oPtName}
                </Tag>
                <Tag minimal>{this.state.oPtPid}</Tag>
              </ControlGroup>
              <ControlGroup>
                <Tag minimal intent="primary">
                  {this.state.oPtPhone}
                </Tag>
                <Tag minimal intent="warning">
                  {this.state.oPtAge}
                </Tag>
              </ControlGroup>
              <br />
              {this.renderOldRx()}
            </>
          ) : null}
        </Card>
      </div>
    );
  };
  renderOldRx = () => {
    if (!this.state.oPt) {
      return null;
    }
    if (this.state.oPtOldRxSearching) {
      return <Spinner />;
    }
    if (!this.state.oPtOldRx) {
      return null;
    }

    if (!_.keys(this.state.oPtOldRx).length) {
      return (
        <Tag minimal large>
          No visits found.
        </Tag>
      );
    }
    let oPtOldRx = this.state.oPtOldRx;
    let oPtOldRxArr = _.flattenDeep(_.values(oPtOldRx));
    let oPtOldRxArrPruned = _.filter(oPtOldRxArr, (each) =>
      ["REGISTERED", "BOOKED"].includes(each.Status)
    );
    let pastFilterConsultRegistered = _.filter(
      oPtOldRxArrPruned,
      (each) => each.Type === "OPD" && each.Status === "REGISTERED"
    );
    let pfcrMt = pastFilterConsultRegistered.map((each) => {
      return { ...each, mt: moment(each.AdmitDate) };
    });
    let today = moment().startOf("day");
    let pfcrMtSorted = pfcrMt.sort((a, b) => a.mt.diff(b.mt));
    let idx1 = _.findIndex(pfcrMtSorted, (each) =>
      each.mt.isSameOrAfter(today)
    );
    idx1 = idx1 >= 0 ? idx1 : pfcrMtSorted.length - 1;
    let imPrv = pfcrMtSorted[idx1];
    let futureConsults = _.filter(
      oPtOldRxArrPruned,
      (each) => each.Status === "BOOKED"
    );

    return (
      <div>
        {idx1 >= 0
          ? this.renderPrevConsult(
              imPrv.BranchName,
              imPrv.Time,
              imPrv.ConsultantName,
              imPrv.FileUrl
            )
          : null}
        <br />
        {futureConsults.length ? (
          <ControlGroup>
            <Tag minimal large>
              Upcoming
            </Tag>
          </ControlGroup>
        ) : null}
        {futureConsults.length ? (
          <>
            {futureConsults.map((each) => {
              return (
                <div style={{ marginTop: "5px" }}>
                  {this.renderFutureConsult(
                    each.Time,
                    each.BranchName,
                    each.SubClinic,
                    each.DocketNo,
                    each.Remarks
                  )}
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    );
  };
  renderFutureConsult = (ts, BranchName, SubClinic, DocketNo, Remarks) => {
    return (
      <ControlGroup vertical>
        <ControlGroup>
          <Tag
            minimal
            large
            intent={
              this.state.deleteDockets.includes(DocketNo) ? "danger" : "primary"
            }
          >
            {niceDateTime(ts)}
          </Tag>{" "}
          <Tag
            minimal
            large
            interactive
            onClick={() => copyToClipboard(DocketNo)}
          >
            {DocketNo}
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <Tag minimal large>
            {BranchName}
          </Tag>
          <Tag minimal large>
            {SubClinic}
          </Tag>
          <Tag
            icon={this.state.deleteDockets.includes(DocketNo) ? "trash" : null}
            large={this.state.deleteDockets.includes(DocketNo)}
            minimal={!this.state.deleteDockets.includes(DocketNo)}
            intent={
              this.state.deleteDockets.includes(DocketNo) ? "danger" : "none"
            }
            interactive
            onClick={() => this.delDocket(DocketNo)}
          >
            {this.state.deleteDockets.includes(DocketNo) ? "Delete" : "Booked"}
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <Tag multiline minimal>
            {Remarks}
          </Tag>
        </ControlGroup>
      </ControlGroup>
    );
  };
  delDocket = (DocketNo) => {
    let d = this.state.deleteDockets;
    if (d.includes(DocketNo)) {
      _.remove(d, (each) => each === DocketNo);
    } else {
      d.push(DocketNo);
    }
    this.setState({ deleteDockets: d });
  };
  renderPrevConsult = (branch, ts, consultant, FileUrl) => {
    return (
      <ControlGroup vertical>
        <ControlGroup>
          <Tag minimal large>
            Last Visit
          </Tag>
          <Tag minimal large intent="warning">
            {niceDateTime(new Date(ts))}
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <AnchorButton
            minimal
            icon="prescription"
            href={fixUrlPrefix(FileUrl)}
            target="_blank noreferrer"
          />
          <Tag minimal large>
            {consultant}
          </Tag>
          <Tag minimal large>
            {branch}
          </Tag>
        </ControlGroup>
      </ControlGroup>
    );
  };
  /** APIs */
  searchPatients = async (string) => {
    if (!string) {
      this.setState({ oldSearchResults: [] });
      return;
    }
    try {
      this.setState({
        oldSearchResults: true,
        oPtOldRx: null,
        oPtName: null,
        oPtPid: null,
        oPtPhone: null,
        oPtAge: null,
        oPt: null,
      });
      /*    let res = await apiCallPost(
        `/mgmt/${this.props.authStore.prefix}/search/patient`,
        {
          prefix: string,
        }
      ); */
      let res = await apiCallPost(
        `/rai/${this.props.authStore.prefix}/search`,
        {
          prefix: string,
        }
      );
      this.setState({
        oldSearchResults: res.map((each) => {
          return {
            item: _.startCase(_.lowerCase(each.name)),
            entityTypeString: each.genericPid,
            helper: each.phone,
            helper2: yearFromNow(each.dob),
            data: each,
          };
        }),
        oldSearching: false,
      });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  promoteOld = async (data) => {
    try {
      await apiCallPost(
        `/mgmt/${this.props.authStore.prefix}/promote-atlas/patient`,
        data
      );
    } catch (err) {
      console.log(err);
      cogoToast.error("Failed to promote patient.");
    }
  };
  getRxOld = async (pid) => {
    try {
      this.setState({ oPtOldRxSearching: true });
      let v = await apiCallPost(
        `/sidepane/${this.props.authStore.prefix}/previousVisits`,
        {
          PID: pid,
        }
      );
      this.setState({ oPtOldRx: v, oPtOldRxSearching: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Failed to fetch old prescriptions.");
      this.setState({ oPtOldRxSearching: false });
    }
  };

  getGeoCode = async () => {
    try {
      let res = await apiCallPost(
        `/util/${this.props.authStore.prefix}/geocode`,
        { address: this.state.nPtAddress }
      );

      if (!res.length) {
        this.setState({ nPtGeoCodeText: "No hits.", nPtGeoCode: null });
      } else {
        this.setState({
          nPtGeoCode: res[0],
          nPtGeoCodeText: `${res[0].al0}, ${res[0].al1}, ${res[0].al2}, ${res[0].al3}`,
        });
      }
    } catch (err) {
      cogoToast.error("Error getting geocodes.");
      console.log(err);
    }
  };
  /** BOOKING - PATIENT ENDS */
  /** DRAFT CARD */
  renderAppt = () => {
    if (!this.state.selectedSlot) {
      return (
        <ControlGroup>
          {" "}
          <Tag large minimal>
            No Appointment Added.
          </Tag>
        </ControlGroup>
      );
    }

    return (
      <ControlGroup>
        <Tag large intent="primary">
          {this.state.selectedSlot.doc}
        </Tag>
        <Tag large intent="success">
          {this.state.selectedSlot.when}
        </Tag>
        <Tag large intent="success">
          {niceDateWithWeek(this.state.selectedSlot.date)}
        </Tag>
        <Tag large intent="warning">
          {this.state.selectedSlot.purpose}
        </Tag>
        <Tag large intent="primary">
          {this.state.selectedSlot.chairtime} mins
        </Tag>
        <Button
          icon="trash"
          intent="danger"
          minimal
          onClick={() => this.setState({ selectedSlot: null })}
        />
      </ControlGroup>
    );
  };
  /** DRAFT CARD ENDS  */
  /** UNSORTED */
  renderChooseDoc = () => {
    if (this.state.cgChoose === "Doctor") {
      let docsList = this.state.loadedDocs;
      if (!this.state.choosingDocSite) {
        docsList = docsList.map((each) => {
          return {
            ...each,
            searchName: `${each.shortcode || ""} | ${
              each.name
            } ▸ ${each.department.join(", ")}`,
          };
        });
      } else if (this.state.choosingDocSite === "Any") {
        docsList = docsList.map((each) => {
          return {
            ...each,
            searchName: `${each.shortcode || ""} | ${
              each.name
            } ▸ ${each.department.join(", ")}`,
          };
        });
      } else {
        let d = _.filter(docsList, (each) =>
          each.sites.includes(this.state.choosingDocSite)
        );

        docsList = d.map((each) => {
          return {
            ...each,
            searchName: `${each.shortcode || ""} | ${
              each.name
            } ▸ ${each.department.join(", ")}`,
          };
        });
      }

      return (
        <>
          <Tag minimal>Filter Site</Tag>
          <SingleSelectWrapper
            showSelectProp={true}
            selectedItem={this.state.choosingDocSite}
            // items={["Any", ...this.state.loadedSites]}
            items={
              this.state.loadedSites.length > 1
                ? ["Any", ...this.state.loadedSites]
                : [...this.state.loadedSites]
            }
            onChange={(item) =>
              this.setState({
                choosingDocSite: item,
                selectedDoc: null,
                selectedDocId: null,
                loadingDocCalendar: false,
                docCalendarLoaded: null,
                selectedDocDate: null,
                selectedDocDateSite: null,
                loadingDocDateSitePurposes: false,
                loadedDocDateSitePurposes: null,
                selectedDocDateSitePurpose: null,
                selectedDocDateSitePurposeWor: null,
                selectedDocDateSitePurposeIsToday: null,
                loadingDocDateSitePurposeTimeBar: false,
                loadedDocDateSitePurposeTimebars: null,
              })
            }
          />
          <Tag minimal>Doctor</Tag>
          <SingleIDSelectWrapper
            showSelectProp={true}
            width="300px"
            items={docsList}
            selectedItem={this.state.selectedDoc}
            labelKey="searchName"
            onChange={(item) => {
              this.setState(
                {
                  selectedDoc: item,
                  selectedDocId: item._id,
                  loadingDocCalendar: false,
                  docCalendarLoaded: null,
                  selectedDocDate: null,
                  selectedDocDateSite: null,
                  loadingDocDateSitePurposes: false,
                  loadedDocDateSitePurposes: null,
                  selectedDocDateSitePurpose: null,
                  selectedDocDateSitePurposeWor: null,
                  selectedDocDateSitePurposeIsToday: null,
                  loadingDocDateSitePurposeTimeBar: false,
                  loadedDocDateSitePurposeTimebars: null,
                },
                () => {
                  this.getDoctorCalendar(this.state.currentMonth || new Date());
                }
              );
            }}
          />
        </>
      );
    }
  };
  renderChooseProcedure = () => {
    if (this.state.cgChoose === "Procedure") {
      return (
        <>
          <Tag minimal>Site</Tag>
          <SingleSelectWrapper
            showSelectProp={true}
            selectedItem={this.state.procedureSite}
            items={this.state.loadedSites}
            onChange={(item) =>
              this.setState({
                procedureSite: item,
                procProcedure: null,
                procSubprocedure: null,
                procDate: null,
                procEye: null,
                loadingResourceProc: false,
                loadedProcResources: [],
                procResource: null,
                loadingResourceProcTime: false,
                loadedTimewiseProcSlots: {},
                loadedTimewiseProcSlotsCalcSlotsOverall: {},
              })
            }
          />
          {this.state.procedureSite ? (
            <>
              {" "}
              <Tag minimal>Procedure</Tag>
              <SingleSelectWrapper
                showSelectProp={true}
                selectedItem={this.state.procProcedure}
                items={this.state.loadedProcs.map((each) => each.shortname)}
                onChange={(item) => {
                  console.log(item);
                  this.setState({
                    procProcedure: item,
                    procSubprocedure: null,
                    procDate: null,
                    procEye: null,
                    loadingResourceProc: false,
                    loadedProcResources: [],
                    procResource: null,
                    loadingResourceProcTime: false,
                    loadedTimewiseProcSlots: {},
                    loadedTimewiseProcSlotsCalcSlotsOverall: {},
                  });
                }}
              />
              {this.state.procProcedure === "Retinal Laser" ? (
                <Tag intent="danger" icon="warning-sign" multiline>
                  Please book a retinal laser consultation after booking the
                  retinal laser procedure.
                </Tag>
              ) : null}
              {this.state.procProcedure ? (
                <>
                  <Tag minimal>Function</Tag>
                  <SingleSelectWrapper
                    showSelectProp={true}
                    selectedItem={this.state.procSubprocedure}
                    items={
                      _.find(
                        this.state.loadedProcs,
                        (each) => each.shortname === this.state.procProcedure
                      ).subprocedures
                    }
                    onChange={(item) =>
                      this.setState(
                        {
                          procSubprocedure: item,
                          procDate: null,
                          procEye: null,
                          loadingResourceProc: false,
                          loadedProcResources: [],
                          procResource: null,
                          loadingResourceProcTime: false,
                          loadedTimewiseProcSlots: {},
                          loadedTimewiseProcSlotsCalcSlotsOverall: {},
                        },
                        () => {
                          if (
                            _.find(
                              this.state.loadedProcs,
                              (each) =>
                                each.shortname === this.state.procProcedure
                            ).isSystemic
                          ) {
                            this.setState({ procEye: "BE" });
                          }
                        }
                      )
                    }
                  />
                  {!_.find(
                    this.state.loadedProcs,
                    (each) => each.shortname === this.state.procProcedure
                  ).isSystemic ? (
                    <>
                      {" "}
                      <MutEx
                        options={["LE", "BE", "RE"]}
                        selected={this.state.procEye}
                        onUpdate={(item) => this.setState({ procEye: item })}
                      />
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </>
      );
    }
  };
  renderPackageOptions = () => {
    if (!this.state.packageSite) {
      return null;
    }
    if (this.state.loadingPackagesAtSite) {
      return (
        <ControlGroup>
          <Spinner size="20" />
        </ControlGroup>
      );
    }
    if (!this.state.loadedPackagesAtSite.length) {
      return (
        <ControlGroup>
          <Tag minimal>No packages found.</Tag>
        </ControlGroup>
      );
    }
    return (
      <ControlGroup>
        <Tag minimal large>
          Choose Package
        </Tag>
        <SingleIDSelectWrapper
          showSelectProp={true}
          width="300px"
          items={this.state.loadedPackagesAtSite.map((each) => {
            return {
              ...each,
              searchName: each.packageAlias,
            };
          })}
          selectedItem={this.state.selectedPackage}
          labelKey="searchName"
          onChange={(item) =>
            this.setState(
              {
                selectedPackage: item,
                packageDate: null,
                loadingPackageSlots: false,
                componentSlots: [],
                currentlyDraftingPackage: {
                  nanoid: nanoid(),
                  packageId: item._id,
                  packageAlias: item.packageAlias,
                  components: item.components.map((e) => {
                    return { ...e, chosenTime: false };
                  }),
                },
              },
              () => {
                if (item.isSystemic) {
                  this.setState({ packageEye: "BE" });
                }
              }
            )
          }
        />
        {this.state.selectedPackage ? (
          this.state.selectedPackage.isSystemic ? null : (
            <MutEx
              options={["LE", "BE", "RE"]}
              selected={this.state.packageEye}
              onUpdate={(item) => this.setState({ packageEye: item })}
            />
          )
        ) : null}
      </ControlGroup>
    );
  };

  renderChoosePackage = () => {
    if (this.state.cgChoose === "Package") {
      return (
        <>
          <Tag minimal>Site</Tag>
          <SingleSelectWrapper
            showSelectProp={true}
            selectedItem={this.state.packageSite}
            items={this.state.loadedSites}
            onChange={(item) =>
              this.setState(
                {
                  packageSite: item,
                  loadingPackagesAtSite: false,
                  loadedPackagesAtSite: [],
                  selectedPackage: null,
                  packageEye: null,
                  packageDate: null,
                  loadingPackageSlots: false,
                  componentSlots: [],
                },
                () => this.getPackagesAtSiteOn()
              )
            }
          />
          {this.renderPackageOptions()}
        </>
      );
    }
  };
  renderCardRight = () => {
    return (
      <div>
        <div style={{ marginTop: "20px" }}>
          <ControlGroup>
            <Button
              disabled={!this.state.isActivelyBooking}
              intent={this.state.visibleCard === "NEW" ? "success" : "none"}
              text="New"
              outlined={this.state.visibleCard !== "NEW"}
              onClick={() => this.setState({ visibleCard: "NEW" })}
            />
            <Button
              disabled={!this.state.isActivelyBooking}
              intent={this.state.visibleCard === "OLD" ? "success" : "none"}
              text="Old"
              outlined={this.state.visibleCard !== "OLD"}
              onClick={() => this.setState({ visibleCard: "OLD" })}
            />
            <Button
              intent={this.state.visibleCard === "ANN" ? "success" : "none"}
              text="Announcements"
              outlined={this.state.visibleCard !== "ANN"}
              onClick={() =>
                this.setState({ visibleCard: "ANN" }, () =>
                  this.getAnnouncements()
                )
              }
            />
            <Button
              intent={this.state.visibleCard === "ENQ" ? "success" : "none"}
              text="Enquiry"
              outlined={this.state.visibleCard !== "ENQ"}
              onClick={() => this.setState({ visibleCard: "ENQ" })}
            />
            {/* <Tag minimal large>
              Stats
            </Tag> */}
          </ControlGroup>
        </div>
        {this.state.visibleCard === "ANN" ? (
          <>
            <br />
            {this.renderAnnouncements()}
          </>
        ) : null}
        {this.state.visibleCard === "NEW" ? (
          <>
            <br />
            {this.renderNewPatientForm()}
          </>
        ) : null}
        {this.state.visibleCard === "OLD" ? (
          <>
            <br />
            {this.renderOldPatientDetails()}
          </>
        ) : null}
        {this.state.visibleCard === "ENQ" ? (
          <>
            <br />
            {this.onEnquiry()}
          </>
        ) : null}
      </div>
    );
  };
  /** Slot and Draft Card */
  renderChooseSlot = () => {
    return (
      <div style={{ width: "73vw", overflow: "auto" }}>
        <Card style={{ margin: "20px" }} elevation={Elevation.FOUR}>
          <ControlGroup>
            <MutEx
              options={["Doctor", "Procedure", "Package"]}
              selected={this.state.cgChoose}
              onUpdate={(item) => {
                this.setState({ cgChoose: item });
              }}
            />
            {this.renderChooseDoc()}
            {this.renderChooseProcedure()}
            {this.renderChoosePackage()}
          </ControlGroup>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div>{this.renderCalendar()}</div>
            <div>{this.renderDocForDate()}</div>
            <div>{this.renderResourceProcedure()}</div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>{this.renderPackageCompletion()}</div>
              <div>{this.renderPackageProcedureTimebars()}</div>
            </div>
          </div>
        </Card>
      </div>
    );
  };
  resetState = () => {
    this.setState({
      isActivelyBooking: true,
      bookingLoading: false,
      reasonText: "",
      reasonCategory: [],
      /** What Type */
      cgChoose: null,
      /** Bookings */
      consultationBookings: [],
      procedureBookings: [],
      packageBookings: [],
      remarks: null,
      isEmergency: false,
      wasDocPtChoice: false,
      bookingChannel: "",
      /** Booking Package */
      packageSite: null,
      loadingPackagesAtSite: false,
      loadedPackagesAtSite: [],
      selectedPackage: null,
      packageEye: null,
      packageDate: null,
      loadingPackageSlots: false,
      componentSlots: [],
      currentlyDraftingPackage: {
        nanoid: null,
        packageId: null,
        packageAlias: null,
        components: [],
      },
      /** Booking Procedure */
      procedureSite: null,
      procProcedure: null,
      procSubprocedure: null,
      procDate: null,
      procEye: null,
      loadingResourceProc: false,
      loadedProcResources: [],
      procResource: null,
      loadingResourceProcTime: false,
      loadedTimewiseProcSlots: {},
      /** Booking By Doc */
      choosingByDoctor: true,
      selectedDocId: null,
      selectedDoc: null,
      currentMonth: new Date(),
      loadingDocCalendar: false,
      docCalendarLoaded: null,
      selectedDocDate: null,
      selectedDocDateSite: null,
      loadingDocDateSitePurposes: false,
      loadedDocDateSitePurposes: null,
      selectedDocDateSitePurpose: null,
      selectedDocDateSitePurposeWor: null,
      selectedDocDateSitePurposeIsToday: null,
      loadingDocDateSitePurposeTimeBar: false,
      loadedDocDateSitePurposeTimebars: null,
      selectedSlot: null, // TO DO: ARRAY
      /** Symptom Search */
      symSearching: false,
      symSearchResults: [],
      symptoms: [],
      /** SysDx Search */
      sysDxSearching: false,
      sysDxSearchResults: [],
      sysDx: [],

      /** Announcements */
      loadingAnn: false,
      loadedAnn: [],
      /** Right Panel*/
      visibleCard: null,
      /** New Patient */
      editingDoB: false,
      nPtName: "",
      nPtPhone: "",
      nPtWhatsApp: "",
      nPtDoB: null,
      nPtSex: null,
      nPtAddress: null,
      nPtGeoCode: null,
      nPtGeoCodeText: null,
      /** Old Patient*/
      oPtOldRxSearching: false,
      oldSearching: false,
      oldSearchResults: [],
      oPtName: null,
      oPtPid: null,
      oPtPhone: null,
      oPtAge: null,
      oPt: null,
      oPtOldRx: null,
      deleteDockets: [],
      /** */
      cgDoc: null,
      cgSite: null,
    });
  };
  renderActionBar = () => {
    if (!this.state.isActivelyBooking) {
      return (
        <Button
          outlined
          fill
          intent="success"
          minimal
          text="Reset"
          onClick={() => this.resetState()}
        />
      );
    }
    if (!this.state.visibleCard) {
      return null;
    }
    if (this.state.visibleCard === "ANN") {
      return null;
    }
    if (this.state.visibleCard === "OLD") {
      if (!this.state.oPtPid) {
        return null;
      }
    }
    let isThereAppt = false;
    let pkgCount, consultCount, procCount, delCount;
    if (this.state.packageBookings.length) {
      pkgCount = this.state.packageBookings.length;
      isThereAppt = true;
    } else {
      pkgCount = 0;
    }
    if (this.state.consultationBookings.length) {
      consultCount = this.state.consultationBookings.length;
      isThereAppt = true;
    } else {
      consultCount = 0;
    }
    if (this.state.procedureBookings.length) {
      procCount = this.state.procedureBookings.length;
      isThereAppt = true;
    } else {
      procCount = 0;
    }
    let isThereDelete = false;
    if (this.state.deleteDockets.length) {
      delCount = this.state.deleteDockets.length;
      isThereDelete = true;
    } else {
      delCount = 0;
    }
    if (isThereDelete && !isThereAppt) {
      return (
        <ControlGroup>
          <Tag minimal large>
            Cancellation Reason
          </Tag>
          <InputGroup
            placeholder="Reason"
            value={this.state.reasonText}
            onChange={(event) =>
              this.setState({ reasonText: event.target.value })
            }
          />
          <MultiSelectWrapper
            items={this.cancellationReasons}
            selectedItems={this.state.reasonCategory}
            onChange={(items) => this.setState({ reasonCategory: items })}
          />

          <Button
            onClick={() =>
              this.setState({ bookingLoading: true }, () =>
                this.deleteBooking()
              )
            }
            loading={this.state.bookingLoading}
            intent="danger"
            fill
            icon="trash"
            text={`Cancelling ${delCount} Appointment${
              delCount > 1 ? "s" : ""
            }`}
          />
        </ControlGroup>
      );
    }
    if (isThereDelete && isThereAppt) {
      return (
        <Button
          onClick={() => this.createBooking()}
          loading={this.state.bookingLoading}
          intent="warning"
          fill
          icon="timeline-events"
          text={`Cancelling ${delCount} Appointment${
            delCount > 1 ? "s" : ""
          } and Booking ${consultCount} Consultation${
            consultCount > 1 ? "s" : ""
          }, ${procCount} Procedure${
            procCount > 1 ? "s" : ""
          } and ${pkgCount} Package${pkgCount > 1 ? "s" : ""}`}
        />
      );
    }
    if (!isThereDelete && isThereAppt) {
      return (
        <Button
          loading={this.state.bookingLoading}
          intent="success"
          fill
          icon="endorsed"
          onClick={() => this.createBooking()}
          text={`Booking ${consultCount} Consultation${
            consultCount > 1 ? "s" : ""
          }, ${procCount} Procedure${
            procCount > 1 ? "s" : ""
          } and ${pkgCount} Package${pkgCount > 1 ? "s" : ""}`}
        />
      );
    }
  };
  renderDraftCard = () => {
    let cl = this.state.consultationBookings.length;
    let pl = this.state.packageBookings.length;
    let ppl = this.state.procedureBookings.length;
    let total = cl + pl + ppl;
    return (
      <div>
        <Card
          elevation={Elevation.FOUR}
          style={{
            margin: "20px",
            backgroundColor: "#202B33",
            overflow: "scroll",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {this.state.deleteDockets.length ? (
                <>
                  {" "}
                  <ControlGroup>
                    <Tag large intent="danger">
                      Dockets Scheduled for Deletion:
                    </Tag>
                    <Tag large intent="warning">
                      {this.state.deleteDockets.join(", ")}
                    </Tag>
                  </ControlGroup>
                </>
              ) : null}
              {total ? (
                <>
                  <ControlGroup>
                    <Tag large intent="success">
                      Currently Booking:
                    </Tag>
                  </ControlGroup>
                  <br />
                  {this.renderConsult()}
                  {this.renderProcedures()}
                  {this.renderPackage()}
                  <br />
                  {this.renderSymptomSearch()}
                  <br />
                  <ControlGroup>
                    <Tag minimal large>
                      Remarks
                    </Tag>
                    <InputGroup
                      value={this.state.remarks}
                      onChange={(event) =>
                        this.setState({ remarks: event.target.value })
                      }
                    />
                  </ControlGroup>{" "}
                </>
              ) : (
                <ControlGroup>
                  <Tag large minimal>
                    No current bookings added
                  </Tag>
                </ControlGroup>
              )}
              <br />
            </div>
            {total ? (
              <div>
                <ControlGroup vertical>
                  <Tag
                    interactive
                    onClick={() =>
                      this.setState({ isEmergency: !this.state.isEmergency })
                    }
                    large
                    minimal={!this.state.isEmergency}
                    intent={this.state.isEmergency ? "danger" : "none"}
                  >
                    {this.state.isEmergency ? "Emergency" : "Regular"}
                  </Tag>
                  <Tag
                    interactive
                    onClick={() =>
                      this.setState({
                        wasDocPtChoice: !this.state.wasDocPtChoice,
                      })
                    }
                    large
                    minimal={!this.state.wasDocPtChoice}
                    intent={this.state.wasDocPtChoice ? "primary" : "none"}
                  >
                    {this.state.wasDocPtChoice
                      ? "Patient's Choice"
                      : "Operator's Choice"}
                  </Tag>
                  <SingleSelectWrapper
                    showSelectProp={true}
                    selectedItem={this.state.bookingChannel}
                    items={this.bookingChannelChoices}
                    onChange={(item) => this.setState({ bookingChannel: item })}
                  />
                </ControlGroup>
              </div>
            ) : null}
          </div>
          <br />
          {this.renderActionBar()}
        </Card>
      </div>
    );
  };
  /** Enquiries */
  submitEnquiry = async () => {
    let payload = {
      new: this.state.newEnquiry,
      newNumber: this.state.newEnquiryNumber || "NA",
      category: this.state.enquiryCategories,
      comments: this.state.enquiryText || "NA",
      whichPatient: this.state.newEnquiry ? "NA" : this.state.enquiryPID,
      whichName: "NA",

      whichPhone: "NA",
      whichAddress: "NA",
    };
    /*  if (!this.state.newEnquiry) {
        await apiCallPost(
          `/mgmt/${this.props.authStore.prefix}/promote/patient`,
          this.props.bookingStore.whichPatientInFocus
        );
      } */
    this.setState({ loggingEnquiry: true });
    cogoToast.loading("Logging enquiry.");
    try {
      await apiCallPost(
        `/enquiry/${this.props.authStore.prefix}/addEnquiry`,
        payload
      );
      cogoToast.success("Logged Enquiry.");
      this.setState({ loggingEnquiry: false });
    } catch (err) {
      cogoToast.error(
        "Something went wrong. Please check the console for more details."
      );
      this.setState({ loggingEnquiry: false });
      console.log(err);
    }
  };
  onEnquiry = () => {
    return (
      <div>
        <Card
          elevation={Elevation.FOUR}
          style={{
            backgroundColor: "#26292c",
            marginRight: "20px",
          }}
        >
          <H3>Enquiry</H3>
          <FormGroup
            helperText="New Patient?"
            label="New?"
            labelFor="text-input"
            inline
          >
            <Switch
              onChange={(e) =>
                this.setState({ newEnquiry: !this.state.newEnquiry })
              }
            />
          </FormGroup>
          {this.state.newEnquiry ? (
            <FormGroup
              helperText="Phone Number"
              label="Phone"
              labelFor="text-input"
              inline
            >
              <InputGroup
                onChange={(event) =>
                  this.setState({ newEnquiryNumber: event.target.value })
                }
              />
            </FormGroup>
          ) : (
            <FormGroup
              helperText="Patient ID"
              label="PID"
              labelFor="text-input"
              inline
            >
              <InputGroup
                onChange={(event) =>
                  this.setState({ enquiryPID: event.target.value })
                }
              />
            </FormGroup>
          )}

          <FormGroup
            helperText="Type of Enquiry"
            label="Category"
            labelFor="text-input"
            inline
          >
            <MultiSelectWrapper
              items={[
                "Mediclaim Desk",
                "Surgery Counselling",
                "Surgery Confirmation",
                "Surgery Cancellation",
                "Clarification on Medicines",
                "Doctor Availability",
                "Hospital Timings",
                "Location of Hospital",
                "Cancellation of Apppointment",
                "Flying Patient Enquiry",
                "No Sound",
                "Other",
              ]}
              intent="success"
              placeholder="Search"
              selectedItems={[]}
              onChange={(items) => this.setState({ enquiryCategories: items })}
            />
          </FormGroup>
          <FormGroup
            helperText="Enter Notes Here."
            label="Comments"
            labelFor="text-input"
            inline
          >
            <TextArea
              onChange={(event) =>
                this.setState({ enquiryText: event.target.value })
              }
            />
          </FormGroup>
          <Button
            loading={this.state.loggingEnquiry}
            fill
            text="Log Query"
            intent="success"
            onClick={() => this.submitEnquiry()}
          />
        </Card>
      </div>
    );
  };

  /** Slot and Draft Card Ends */
  /** New Booking */
  deleteBooking = async () => {
    if (!this.state.deleteDockets.length) {
      this.setState({ bookingLoading: false });
      return;
    }
    cogoToast.loading("Deleting old appointments");
    let flag = false;
    if (this.state.deleteDockets.length) {
      let consultationBookings = this.state.consultationBookings;
      let packageBookings = this.state.packageBookings;
      let procedureBookings = this.state.procedureBookings;
      let total =
        consultationBookings.length +
        packageBookings.length +
        procedureBookings.length;
      if (total) {
        flag = true;
      }
    }
    if (!flag) {
      // Has booking
      if (!this.state.reasonText) {
        cogoToast.error("Reason for cancellation not specified");
        this.setState({ bookingLoading: false });
        return;
      }
      if (!this.state.reasonCategory.length) {
        this.setState({ bookingLoading: false });

        cogoToast.error("Categories for cancellation not specified");
        return;
      }
    }
    try {
      await Promise.all(
        this.state.deleteDockets.map((each) => {
          return apiCallPost(
            `/mgmt/${this.props.authStore.prefix}/cancel/bookingV2`,
            {
              DocketNo: each,
              reasonText: flag ? "Rescheduling" : this.state.reasonText,
              reasonCategory: flag
                ? ["Rescheduling"]
                : this.state.reasonCategory,
            }
          );
        })
      );
      this.setState({
        bookingLoading: false,
        isActivelyBooking: false,
        deleteDockets: [],
      });
      cogoToast.success("Deleted old appointments");
    } catch (err) {
      console.log(err);
      cogoToast.error("Error in cancellation.");
      this.setState({ bookingLoading: false });
    }
  };
  createBooking = async () => {
    if (this.state.visibleCard === "ANN") {
      return null;
    }

    let patientInfo = {};
    let payload = {};

    let consultationBookings = this.state.consultationBookings;
    let packageBookings = this.state.packageBookings;
    let procedureBookings = this.state.procedureBookings;
    for (var k = 0; k < procedureBookings.length; k++) {
      let ae = procedureBookings[k].applicableEyes;
      if (ae === "LE") {
        procedureBookings[k].applicableEyes = "Left Eye";
      }
      if (ae === "RE") {
        procedureBookings[k].applicableEyes = "Right Eye";
      }
      if (ae === "BE") {
        procedureBookings[k].applicableEyes = "Both Eyes";
      }
    }
    for (var j = 0; j < packageBookings.length; j++) {
      for (k = 0; k < packageBookings[j].components.length; k++) {
        let ae = packageBookings[j].components[k].applicableEyes;

        if (ae === "LE") {
          packageBookings[j].components[k].applicableEyes = "Left Eye";
        }
        if (ae === "RE") {
          packageBookings[j].components[k].applicableEyes = "Right Eye";
        }
        if (ae === "BE") {
          packageBookings[j].components[k].applicableEyes = "Both Eyes";
        }
      }
    }

    let total =
      consultationBookings.length +
      packageBookings.length +
      procedureBookings.length;
    if (!total) {
      cogoToast.error("Nothing to be booked");
      return;
    }
    for (var a = 0; a < consultationBookings.length; a++) {
      let x = consultationBookings[a];
      if (!x.chairTime) {
        cogoToast.error("Chair time in consultation not provided.");
        return;
      }
      if (!x.doctorId) {
        cogoToast.error("Doctor ID in consultation not provided.");
        return;
      }
      if (!x.purpose) {
        cogoToast.error("Purpose in consultation not provided.");
        return;
      }
      if (!x.site) {
        cogoToast.error("Site in consultation not provided.");
        return;
      }
      if (!x.slotPurpose) {
        cogoToast.error("Slot Purpose in consultation not provided.");
        return;
      }
      if (!x.time) {
        cogoToast.error("Time in consultation not provided.");
        return;
      }
    }
    for (a = 0; a < procedureBookings.length; a++) {
      let x = procedureBookings[a];
      if (!x.applicableEyes) {
        cogoToast.error("Applicable Eyes in procedure not provided.");
        return;
      }
      if (!x.chairTime) {
        cogoToast.error("Chair Time in procedure not provided.");
        return;
      }
      if (!x.procedure) {
        cogoToast.error("Procedure in procedure not provided.");
        return;
      }

      if (!x.referringDoctorId) {
        cogoToast.error("Referring Doctor ID in procedure not provided.");
        return;
      }
      if (!x.resourceId) {
        cogoToast.error("Resource ID in procedure not provided.");
        return;
      }
      if (!x.site) {
        cogoToast.error("Site in procedure not provided.");
        return;
      }
      if (!x.subprocedure) {
        cogoToast.error("Subprocedure in procedure not provided.");
        return;
      }
      if (!x.time) {
        cogoToast.error("Time in procedure not provided.");
        return;
      }
    }
    for (a = 0; a < packageBookings.length; a++) {
      let x = packageBookings[a];
      if (!x.packageId) {
        cogoToast.error("Applicable Eyes in package not provided.");
        return;
      }

      if (!x.referringDoctorId) {
        cogoToast.error("Referring Doctor ID in package not provided.");
        return;
      }
      if (!x.components.length) {
        cogoToast.error("Components in package not provided.");
        return;
      }
      for (var d = 0; d < x.components.length; d++) {
        let c = x.components[d];
        if (!c.applicableEyes) {
          cogoToast.error("Applicable Eyes in package procedure not provided.");
          return;
        }
        if (!c.chairTime) {
          cogoToast.error("Chair Time in package procedure not provided.");
          return;
        }
        if (!c.procedure) {
          cogoToast.error("Procedure in package procedure not provided.");
          return;
        }

        if (!c.resourceId) {
          cogoToast.error("Resource ID in package procedure not provided.");
          return;
        }
        if (!c.site) {
          cogoToast.error("Site in package procedure not provided.");
          return;
        }
        if (!c.subprocedure) {
          cogoToast.error("Subprocedure in package procedure not provided.");
          return;
        }
        if (!c.time) {
          cogoToast.error("Time in package procedure not provided.");
          return;
        }
      }
    }
    let auxInfo = {
      symptoms: this.state.symptoms,
      remarks: this.state.remarks || "",
      wasDocPtChoice: this.state.wasDocPtChoice,
      bookingChannel: this.state.bookingChannel,
      isEmergency: this.state.isEmergency,
    };
    if (!auxInfo.bookingChannel) {
      cogoToast.error("Booking Channel not provided");
      return;
    }

    if (this.state.visibleCard === "OLD") {
      cogoToast.loading("Creating booking for pre-registered patient...");
      patientInfo["patientType"] = "OLD";
      patientInfo["genericPid"] = this.state.oPtPid;
      if (!patientInfo.patientType) {
        cogoToast.error("Patient type not specified");
        return;
      }
      if (!patientInfo.genericPid) {
        cogoToast.error("PID not specified");
        return;
      }
    }

    if (this.state.visibleCard === "NEW") {
      if (!this.state.nPtSex) {
        cogoToast.error("Sex not mentioned");
        return;
      }
      if (!this.state.nPtName) {
        cogoToast.error("Name not mentioned");
        return;
      }
      if (!this.state.nPtDoB) {
        cogoToast.error("DoB not mentioned");
        return;
      }
      if (!this.state.nPtAddress) {
        cogoToast.error("Address not mentioned");
        return;
      }
      if (!this.state.nPtGeoCode) {
        cogoToast.error("Geocode not mentioned");
        return;
      }
      if (!this.state.nPtPhone) {
        cogoToast.error("Phone not mentioned");
        return;
      }
      patientInfo["patientType"] = "NEW";
      patientInfo["sysPid"] = null;
      patientInfo["newNumber"] = this.state.nPtPhone;
      patientInfo["newName"] = this.state.nPtName;
      patientInfo["newDob"] = this.state.nPtDoB;
      patientInfo["newSex"] = this.state.nPtSex;
      patientInfo["newAddress"] = this.state.nPtAddress;
      patientInfo["geocode"] = this.state.nPtGeoCode;
      patientInfo["newLegacyPid"] = this.state.nPtLegacyPid || "";
      patientInfo["newAddlSearchTerm"] = this.state.nPtAddleSearchTerm || "";
    }

    payload["patientInfo"] = patientInfo;
    payload["consultationBookings"] = consultationBookings;
    payload["packageBookings"] = packageBookings;
    payload["procedureBookings"] = procedureBookings;
    payload["auxInfo"] = auxInfo;

    if (payload.patientInfo.patientType === "OLD") {
      if (payload.consultationBookings.length) {
        try {
          let res = await Promise.all(
            payload.consultationBookings.map((each) => {
              return apiCallPost(
                `/chrono/${this.props.authStore.prefix}/checkConstraintOldNewPts`,
                {
                  patientType: "OLD",
                  doctorId: each.doctorId,
                  doctorName: each.doc.name,
                  site: each.site,
                  purpose: each.purpose,
                  time: each.time,
                  slotPurpose: each.slotPurpose,
                  slotId: each.slotData.underlyingSlotId,
                  tmStart: each.slotData.tmStart,
                  tmEnd: each.slotData.tmEnd,
                  slotStaggeredTotal: each.slotData.slotStaggeredTotal,
                }
              );
            })
          );
          for (var i = 0; i < res.length; i++) {
            if (!res[i].allowBooking) {
              cogoToast.error(
                `${res[i].doctorName} has a limit of ${res[i].nLimit} old patients; ${res[i].nOldInSlot} already booked. No further bookings for old patients possible. `
              );

              return;
            }
          }
        } catch (err) {
          this.setState({ bookingLoading: false });
          cogoToast.error("Error in checking new patient reserved slots.");
          return;
        }
      }
    }
    try {
      cogoToast.loading("Booking appointments...");
      this.setState({ bookingLoading: true });
      let res = await apiCallPost(
        `/chrono/${this.props.authStore.prefix}/register-appointment2-v2`,
        payload
      );
      this.setState(
        {
          consultationBookings: res.consultationBookings,
          packageBookings: res.packageBookings,
          procedureBookings: res.procedureBookings,
          isActivelyBooking: false,
        },
        () => this.deleteBooking()
      );
      cogoToast.success("Booked successfully.");
    } catch (err) {
      this.setState({ bookingLoading: false });
      cogoToast.error("There was in error in booking appointment");
      console.log(err);
    }
  };
  render() {
    if (this.state.loading) {
      return (
        <Card
          elevation={Elevation.FOUR}
          style={{
            margin: "20px",
            backgroundColor: "#26292c",
          }}
        >
          <Spinner />
        </Card>
      );
    }
    return (
      <div className="bp5-dark">
        <div style={{ display: "flex" }}>
          <div style={{ width: "73vw" }}>
            {this.renderChooseSlot()}
            {this.renderDraftCard()}
          </div>
          <div style={{ width: "27vw", overflow: "scroll" }}>
            {this.renderCardRight()}
          </div>
        </div>
      </div>
    );
  }
}

export default inject("authStore")(observer(BookingTopV2));
