import { ControlGroup, Tag, Button } from "@blueprintjs/core";
import React from "react";
import { copyToClipboard, yearFromNow } from "../../fns/util";
import _ from "lodash";

class InvActivated extends React.Component {
  render() {
    return (
      <div>
        <ControlGroup>
          <Tag large minimal>
            {this.props.uploaded
              ? "This investigation is currently being uploaded and processed. Please check back soon. "
              : " This investigation has been activated. Please use the upload codes below to upload the results."}
          </Tag>
        </ControlGroup>
        <br />
        <ControlGroup vertical>
          <ControlGroup>
            <Tag large intent="primary">
              {this.props.data.type}
            </Tag>
            <Tag large>{this.props.data.subtype}</Tag>

            <Tag large intent="warning">
              {this.props.data.eye}
            </Tag>
            <Tag large minimal intent="warning" icon="warning-sign">
              Please note carefully!
            </Tag>
          </ControlGroup>
          <br /> <br />
          <ControlGroup>
            <Tag minimal>Upload Code</Tag>
            <Tag large intent="success">
              {this.props.data._id}
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() => copyToClipboard(this.props.data._id)}
            />
          </ControlGroup>
          <ControlGroup>
            <Tag minimal>PID</Tag>
            <Tag large intent="primary">
              {this.props.patientData.genericPid}
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() => copyToClipboard(this.props.patientData.genericPid)}
            />
          </ControlGroup>
          <ControlGroup>
            <Tag minimal>Name</Tag>
            <Tag large intent="primary">
              {_.startCase(_.lowerCase(this.props.patientData.patientName))}
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() =>
                copyToClipboard(
                  _.startCase(_.lowerCase(this.props.patientData.patientName))
                )
              }
            />
          </ControlGroup>
          <ControlGroup>
            <Tag minimal>Sex</Tag>
            <Tag large intent="primary">
              {this.props.patientData.sex}
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() => copyToClipboard(this.props.patientData.sex)}
            />
          </ControlGroup>
          <ControlGroup>
            <Tag minimal>Age</Tag>
            <Tag large intent="primary">
              {yearFromNow(this.props.patientData.dob)[0]} &nbsp;{" "}
              {yearFromNow(this.props.patientData.dob)[1]}
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() =>
                copyToClipboard(yearFromNow(this.props.patientData.dob)[0])
              }
            />
          </ControlGroup>
          <ControlGroup>
            <Tag minimal>DoB</Tag>
            <Tag large intent="primary">
              {new Date(this.props.patientData.dob).toLocaleDateString(
                "en-GB",
                {}
              )}
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() =>
                copyToClipboard(
                  new Date(this.props.patientData.dob).toLocaleDateString(
                    "en-GB",
                    {}
                  )
                )
              }
            />
          </ControlGroup>
        </ControlGroup>
      </div>
    );
  }
}

export default InvActivated;
