import React from "react";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import { Tag } from "@blueprintjs/core";

class Sex extends React.Component {
  state = {
    editingSex: false,
  };
  sexes = ["Male", "Female", "Trans Male", "Trans Female", "Other"];
  render() {
    if (this.state.editingSex) {
      return (
        <SingleSelectWrapper
          items={this.sexes}
          selectedItem={this.props.sex}
          onChange={(item) => {
            this.props.update("sex", item);
            this.setState({ editingSex: false });
          }}
        />
      );
    }
    return (
      <Tag
        minimal
        interactive
        onClick={() => this.setState({ editingSex: true })}
      >
        {this.props.sex}
      </Tag>
    );
  }
}

export default Sex;
