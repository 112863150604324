import React from "react";
import { ControlGroup, Tag } from "@blueprintjs/core";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class Schirmer extends React.Component {
  state = { editingPupilL: false, editingPupilR: false };
  listOfPRs = [
    "0 mm",
    "1 mm",
    "2 mm",
    "3 mm",
    "4 mm",
    "5 mm",
    "6 mm",
    "7 mm",
    "8 mm",
    "9 mm",
    "10 mm",
    "11 mm",
    "12 mm",
    "13 mm",
    "14 mm",
    "15 mm",
    "16 mm",
    "17 mm",
    "18 mm",
    "19 mm",
    "20 mm",
    "More than 20 mm",
  ];
  renderPupil = () => {
    return (
      <ControlGroup>
        <Tag minimal large>
          Schirmer's Test
        </Tag>
        <Tag minimal large>
          R
        </Tag>

        {this.state.editingPupilR ? (
          <SingleSelectWrapper
            items={this.listOfPRs}
            selectedItem={this.props.vision.schirmer.re}
            onChange={(item) => {
              this.props.updateVision("schirmer.re", item);
              this.setState({ editingPupilR: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large
            intent="primary"
            interactive
            onClick={() => this.setState({ editingPupilR: true })}
          >
            {this.props.vision.schirmer.re}
          </Tag>
        )}

        <Tag minimal large>
          L
        </Tag>
        {this.state.editingPupilL ? (
          <SingleSelectWrapper
            items={this.listOfPRs}
            selectedItem={this.props.vision.schirmer.le}
            onChange={(item) => {
              this.props.updateVision("schirmer.le", item);
              this.setState({ editingPupilL: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large
            intent="primary"
            interactive
            onClick={() => this.setState({ editingPupilL: true })}
          >
            {this.props.vision.schirmer.le}
          </Tag>
        )}
      </ControlGroup>
    );
  };
  render() {
    return <div>{this.renderPupil()}</div>;
  }
}

export default Schirmer;
