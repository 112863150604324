import {
  Tag,
  Tab,
  Tabs,
  NonIdealState,
  Spinner,
  Drawer,
  ControlGroup,
  Button,
} from "@blueprintjs/core";
import React from "react";
import { generateMasterVisitEntities } from "./masterVisitEntities";
import ClinicalSubsection from "./ClinicalSubsection";
import { inject, observer } from "mobx-react";
import { apiCallPost } from "../fns/util";
import cogoToast from "cogo-toast";
import _ from "lodash";
import ClinicalVisitParametersSection from "./ClinicalVisitParametersSection";
import FilesSection from "./FilesSection";
import ClinicalRxPanel from "./ClinicalRxPanel";
import ClinicalPatientHeader from "./ClinicalPatientHeader";
import TurboMode from "./turbo/TurboMode";
import TemplateHeader from "./templates/TemplateHeader";
import TemplateLanding from "./templates/TemplateLanding";
import ChatterLanding from "./chatter/ChatterLanding";
import FavouritesLanding from "./templates/FavouritesLanding";
import UserRoleBox from "./UserRoleBox";
import PaintWrapper from "./paint/PaintWrapper";
import PastVisit from "./past-visit-dropdown/PastVisit";
import moment from "moment";

class ClinicalV2 extends React.Component {
  masterVisitEntities = generateMasterVisitEntities(
    this.props.authStore.prefix
  );
  state = {
    isLoading: true,
    isError: false,
    mode: "CLINICAL",
    name: "",
    patientId: "",
    visitId: "",
    selectedTabId: "TURBO_MODE",
    visitEntities: [],
    visitParameters: [],
    files: [],
    recentItems: [],
    showHarleyDrawer: false,
    showPastVisitsDrawer: false,
    pastVisitData: [1, 2, 3],
    pastVisitDataOrder: "REVERSE_CHRONO",
    visitData: null,
  };
  componentDidMount() {
    if (this.props.match.path.includes("clinical")) {
      this.setState(
        {
          mode: "CLINICAL",
        },
        () => this.getPatientInfo()
      );
    } else if (this.props.match.path.includes("templates")) {
      this.setState(
        {
          mode: "TEMPLATE",
          templateId: this.props.match.params.templateId,
        },
        () => this.getTemplateEntities()
      );
    }
    this.interval = setInterval(() => {
      const currentTime = new Date();
      if (this.state.recentItems.length === 0) {
        return;
      }
      const newRecentItems = this.state.recentItems.filter((item) => {
        return currentTime - new Date(item.ts) <= 3000;
      });
      this.setState({ recentItems: newRecentItems });
    }, 3000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  ensureSameDate = () => {
    // always allow template edits.
    if (this.state.mode === "TEMPLATE") {
      return true;
    }
    const now = new Date();
    const rxDate = new Date(this.state.visitData.updatedAt);
    if (moment(rxDate).isSame(now, "day")) {
      return true;
    } else {
      cogoToast.error(
        "The Prescription cannot be edited after the day it was created."
      );
      return false;
    }
  };
  /** GET */
  getPatientInfo = async () => {
    this.setState({
      isLoading: true,
    });
    let patientId = this.props.match.params.patientId;
    let visitId = this.props.match.params.visitId;
    if (!patientId || !visitId) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      cogoToast.error("Error getting patient info.");
      return;
    }
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/extra/getPatientInfo`,
        {
          patientId,
        }
      );
      this.setState(
        {
          name: res.name,
          phone: res.phone,
          sex: res.sex,
          sysPid: res.sysPid,
          patientId: res._id,
          dob: res.dob,
          address: res.address,
          visitId,
          panels: res.panels,
        },
        () => this.getVisitEntities()
      );
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
      cogoToast.error("Error getting patient info.");
    }
  };
  getVisitEntities = async () => {
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/cventity/getByVisit`,
        {
          visitId: this.state.visitId,
        }
      );
      this.setState(
        {
          visitEntities: res,
        },
        () => this.getVisitParameters()
      );
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
      cogoToast.error("Error getting visit entities.");
    }
  };
  getTemplateEntities = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await apiCallPost(
        `/clinical-template2/${this.props.authStore.prefix}/loadTemplateById`,
        {
          templateId: this.state.templateId,
        }
      );
      this.setState({
        templateId: res._id,
        isLoading: false,
        templateName: res.templateName,
        speciality: res.speciality,
        templateType: res.templateType,
        templateDescription: res.templateDescription,
        nUses: res.nUses,
        visitEntities: res.entities,
      });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
      cogoToast.error("Error getting visit entities.");
    }
  };
  getVisitParameters = async () => {
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/cparam/getByVisit`,
        {
          visitId: this.state.visitId,
        }
      );
      this.setState(
        {
          visitParameters: res,
        },
        () => this.getVisitLifeCycle()
      );
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
      cogoToast.error("Error getting visit parameters.");
    }
  };

  loadPastVisits = async () => {
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/getPastVisits`,
        {
          genericPid: this.state.patientId,
          currentVisit: this.state.visitId,
        }
      );
      this.setState({
        pastVisitData: res.otherVisits || [],
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error getting past visits.");
    }
  };
  /** HANDLERS */
  getVisitLifeCycle = async () => {
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/getLifecycleEvents`,
        {
          visitId: this.state.visitId,
          patientId: this.state.patientId,
        }
      );
      this.setState(
        {
          lifecycleEvents: res,
        },
        () => this.getVisitData()
      );
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
      cogoToast.error("Error getting visit parameters.");
    }
  };
  getVisitData = async () => {
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/getVisitData`,
        {
          visitId: this.state.visitId,
          patientId: this.state.patientId,
        }
      );
      this.setState({
        visitData: res,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
      cogoToast.error("Error getting visit information.");
    }
  };
  onUpdateVisitDataProp = async (prop, value) => {
    if (!this.ensureSameDate()) return;
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/updateVisitDataProp`,
        {
          visitId: this.state.visitId,
          prop: prop,
          value: value,
        }
      );
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
      cogoToast.error("Error updating lifecycle.");
    }
  };
  updateLifecycle = async (type) => {
    if (!this.ensureSameDate()) return;
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/addLifecycleEvent`,
        {
          visitId: this.state.visitId,
          patientId: this.state.patientId,
          event: type,
        }
      );

      this.setState({
        lifecycleEvents: [...this.state.lifecycleEvents, res],
      });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
      cogoToast.error("Error updating lifecycle.");
    }
  };
  addVisitEntity = async (item) => {
    if (!this.ensureSameDate()) return;
    let i = await this.tryToFindSearchMatch(item);
    let prefix, payload;
    if (this.state.mode === "CLINICAL") {
      prefix = "clinical2";
      payload = {
        payload: i,
        visitId: this.state.visitId,
        patientId: this.state.patientId,
      };
    } else {
      prefix = "clinical-template2";
      payload = {
        payload: i,
        templateId: this.state.templateId,
      };
    }
    try {
      let res = await apiCallPost(
        `/${prefix}/${this.props.authStore.prefix}/cventity/createNew`,
        payload
      );
      this.setState({
        visitEntities: [...this.state.visitEntities, res],
        recentItems: [
          ...this.state.recentItems,
          {
            _id: res._id,
            type: "ADD",
            ts: new Date(),
          },
        ],
      });
    } catch (err) {
      cogoToast.error("Error adding entity.");
      console.log(err);
    }
  };
  removeVisitEntity = async (_id, laterality = null) => {
    if (!this.ensureSameDate()) return;
    if (laterality) {
      let item = _.find(this.state.visitEntities, (each) => each._id === _id);
      if (item.laterality === "BILATERAL") {
        if (laterality === "RIGHT") {
          item.laterality = "LEFT";
          this.updateVisitEntity(item);
          return;
        } else if (laterality === "LEFT") {
          item.laterality = "RIGHT";
          this.updateVisitEntity(item);
          return;
        } else {
        }
      }
    }
    let prefix, payload;
    if (this.state.mode === "CLINICAL") {
      prefix = "clinical2";
      payload = {
        cvEntityId: _id,
      };
    } else {
      prefix = "clinical-template2";
      payload = {
        entityId: _id,
        templateId: this.state.templateId,
      };
    }
    try {
      await apiCallPost(
        `/${prefix}/${this.props.authStore.prefix}/cventity/delete`,
        payload
      );
      let idx = _.findIndex(
        this.state.visitEntities,
        (each) => each._id === _id
      );
      let newVisitEntities = this.state.visitEntities;
      newVisitEntities.splice(idx, 1);
      this.setState({ visitEntities: newVisitEntities });
    } catch (err) {
      cogoToast.error("Error removing entity.");
      console.log(err);
    }
  };
  updateVisitEntity = async (item) => {
    if (!this.ensureSameDate()) return;
    let i = await this.tryToFindSearchMatch(item);

    i.numSearchesBeforeAdding = item.numSearchesBeforeAdding
      ? item.numSearchesBeforeAdding
      : 0;
    let prefix, payload;
    if (this.state.mode === "CLINICAL") {
      prefix = "clinical2";
      payload = {
        cvEntityId: i._id,
        payload: i,
      };
      if (
        payload.payload.organ == "EYE" &&
        payload.payload.anatomicalPart == null
      ) {
        payload.payload.anatomicalPart = "EYE_GENERAL";
      } else if (
        payload.payload.organ === "SYSTEMIC" /* &&
        payload.payload.anatomicalPart == null */
      ) {
        payload.payload.anatomicalPart = "";
      }
    } else {
      prefix = "clinical-template2";
      payload = {
        payload: i,
        entityId: i._id,
        templateId: this.state.templateId,
      };
      if (
        payload.payload.organ == "EYE" &&
        payload.payload.anatomicalPart == null
      ) {
        payload.payload.anatomicalPart = "EYE_GENERAL";
      } else if (
        payload.payload.organ === "SYSTEMIC" /* &&
        payload.payload.anatomicalPart == null */
      ) {
        payload.payload.anatomicalPart = "";
      }
    }

    try {
      let res = await apiCallPost(
        `/${prefix}/${this.props.authStore.prefix}/cventity/update`,
        payload
      );
      let idx = _.findIndex(
        this.state.visitEntities,
        (each) => each._id === i._id
      );
      let newVisitEntities = this.state.visitEntities;
      newVisitEntities[idx] = { ...res, isShortForm: false }; // Do not auto-collapse on update
      this.setState({
        visitEntities: newVisitEntities,
        recentItems: [
          ...this.state.recentItems,
          {
            _id: i._id,
            type: "UPDATE",
            ts: new Date(),
          },
        ],
      });
    } catch (err) {
      cogoToast.error("Error updating entity.");
      this.setState({
        visitEntities: [...this.state.visitEntities],
      });
      console.log(err);
    }
  };
  tryToFindSearchMatch = async (item) => {
    if (item.canonicalId.length) {
      return item;
    }
    let itemName = item.entityName;
    let itemType = item.entityType;
    if (!itemType || !itemName) {
      return item;
    }
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/canonical-search`,
        { prefix: itemName, entityType: itemType }
      );
      if (res.length && res.length === 1) {
        // guard against partial matches
        const accept = res[0].__searchText
          .toLowerCase()
          .includes(itemName.toLowerCase());
        if (!accept) {
          return item;
        }
        let canonicalId = res[0]._id;
        item.canonicalId = [canonicalId];
        item.entityName = res[0].entityName;
        let keys = Object.keys(res[0]);
        for (var i = 0; i < keys.length; i++) {
          let key = keys[i];
          if (key !== "_id" && key !== "entityName") {
            item[key] = item[key] ? item[key] : res[0][key];
          }
        }
        item.canonicalLinkedBy = "AUTO_SEARCH";
        return item;
      } else {
        return item;
      }
    } catch (err) {
      return item;
    }
  };
  addVisitParameter = async (data) => {
    if (!this.ensureSameDate()) return;
    let payload = {
      patientId: this.state.patientId,
      visitId: this.state.visitId,
      payload: data,
    };
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/cparam/createNew`,
        payload
      );
      this.setState({ visitParameters: [...this.state.visitParameters, res] });
    } catch (err) {
      cogoToast.error("Error adding parameter.");
      console.log(err);
    }
  };
  removeVisitParameter = async (_id) => {
    if (!this.ensureSameDate()) return;
    try {
      await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/cparam/delete`,

        {
          cparamId: _id,
        }
      );
      let idx = _.findIndex(this.state.visitParameters, (x) => x._id === _id);
      let newVisitParameters = this.state.visitParameters;
      newVisitParameters.splice(idx, 1);
      this.setState({ visitParameters: newVisitParameters });
    } catch (err) {
      cogoToast.error("Error adding parameter.");
      console.log(err);
    }
  };
  updateVisitParameter = async (param) => {
    if (!this.ensureSameDate()) return;
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/cparam/update`,
        {
          cparamId: param._id,
          payload: param,
        }
      );
      let idx = _.findIndex(
        this.state.visitParameters,
        (x) => x._id === param._id
      );
      let newVisitParameters = this.state.visitParameters;
      newVisitParameters[idx] = res;
      this.setState({ visitParameters: newVisitParameters });
    } catch (err) {
      cogoToast.error("Error adding parameter.");
      console.log(err);
    }
  };
  updateTemplateProp = async (key, value) => {
    try {
      cogoToast.loading("Updating template property...");
      await apiCallPost(
        `/clinical-template2/${this.props.authStore.prefix}/editTemplateProp`,
        {
          templateId: this.state.templateId,
          key: key,
          value: value,
        }
      );
      this.setState({ [key]: value });
      cogoToast.success("Updated template property.");
    } catch (err) {
      cogoToast.error("Error updating value.");
      console.log(err);
    }
  };
  applyTemplate = async (templateId, laterality) => {
    let payload = {
      patientId: this.state.patientId,
      visitId: this.state.visitId,
      templateId: templateId,
      metadata: {
        laterality: laterality,
      },
    };
    try {
      let res = await apiCallPost(
        `/clinical-template2/${this.props.authStore.prefix}/applyTemplate`,
        payload
      );
      this.setState({
        visitEntities: [...this.state.visitEntities, ...res],
        recentItems: [
          ...this.state.recentItems,
          ...res.map((each) => {
            return {
              _id: each._id,
              type: "ADD",
              ts: new Date(),
            };
          }),
        ],
      });
    } catch (err) {
      cogoToast.error("Error adding from template.");
      console.log(err);
    }
  };

  /** RENDERS */

  renderTabPanel = () => {
    if (!this.state.selectedTabId) {
      return <div>Nothing selected</div>;
    }
    console.log(this.state.visitEntities.length, "visitEntities");
    return (
      <ClinicalSubsection
        recentItems={this.state.recentItems}
        mode={this.state.mode}
        visitEntities={this.state.visitEntities}
        reverseEntityOrder={
          this.state.mode === "CLINICAL" &&
          this.props.authStore.rxClinicalOptions.reverseClinicalEntityOrder
        }
        addVisitEntity={this.addVisitEntity}
        removeVisitEntity={this.removeVisitEntity}
        updateVisitEntity={this.updateVisitEntity}
        key={`${this.state.selectedTabId}__${this.state.visitEntities
          .map((x) => `${x._id}__${x.updatedAt}`)
          .join("__")}`}
        sectionName={this.state.selectedTabId.split("__")[0]}
        subsectionName={this.state.selectedTabId.split("__")[1]}
        defaultClinicalSearchMode={
          this.props.authStore.rxClinicalOptions.defaultClinicalSearchMode ||
          "AI"
        }
      />
    );
  };
  renderTabs = () => {
    const { selectedTabId } = this.state;
    if (this.state.visitData?.restrictedToFiles) {
      return (
        <div style={{ paddingLeft: "5px", width: "100%" }}>
          <Tabs
            vertical
            id="CLINICAL_V2_TABS"
            onChange={(newTabId) => this.setState({ selectedTabId: newTabId })}
            selectedTabId={selectedTabId}
          >
            <div
              key={`tag-files`}
              style={{
                overflowY: "scroll",
              }}
            >
              <Tag intent="success" className="lime__bg">
                Files
              </Tag>
            </div>
            <Tab
              key="FILES"
              id="FILES"
              title="Investigations & Assets"
              panel={
                <div
                  style={{
                    overflowY: "scroll",
                  }}
                >
                  <FilesSection
                    onFiles={(files) => this.setState({ files })}
                    data={this.state}
                    addVisitParameter={(data) => this.addVisitParameter(data)}
                    updateVisitParameter={(data) =>
                      this.updateVisitParameter(data)
                    }
                    removeVisitParameter={(id) => this.removeVisitParameter(id)}
                  />
                </div>
              }
            />
          </Tabs>
        </div>
      );
    }
    let isClinical = this.state.mode === "CLINICAL";
    const allTabs = isClinical
      ? [
          <div key={`tag-turbo`}>
            <Tag intent="success" className="rose__bg">
              Turbo Mode
            </Tag>
          </div>,
          <Tab
            key="TURBO_MODE"
            id="TURBO_MODE"
            title="Turbo Mode"
            panel={
              <div
                style={{
                  overflowY: "scroll",
                }}
              >
                <TurboMode
                  data={this.state}
                  addVisitEntity={this.addVisitEntity}
                  onFile={(file) =>
                    this.setState({ files: [...this.state.files, file] })
                  }
                  key={this.state.selectedTabId}
                />
              </div>
            }
          />,
          <Tab
            key="TEMPLATES"
            id="TEMPLATES"
            title="Templates"
            panel={
              <div
                style={{
                  width: "100%",
                  overflowY: "scroll",
                }}
              >
                <TemplateLanding
                  isClinical={true}
                  onApplyTemplate={(id, laterality) => {
                    cogoToast.loading("Adding entities from template..");
                    this.applyTemplate(id, laterality);
                  }}
                />
              </div>
            }
          />,
          <Tab
            key="FAVOURITES"
            id="FAVOURITES"
            title="Favourites"
            panel={
              <div
                style={{
                  overflowY: "scroll",
                  width: "100%",
                }}
              >
                <FavouritesLanding
                  mode="CLINICAL"
                  addVisitEntity={(item) => {
                    cogoToast.loading("Adding entity from favourites..");
                    this.addVisitEntity(item);
                  }}
                />
              </div>
            }
          />,
          <Tab
            key="DIAGRAMS"
            id="DIAGRAMS"
            title="Diagrams"
            panel={
              <div
                style={{
                  overflowY: "scroll",
                  width: "100%",
                }}
              >
                <PaintWrapper data={this.state} />
              </div>
            }
          />,
          <div key={`tag-optometry`}>
            <Tag intent="success" className="sepia__bg">
              Optometry
            </Tag>
          </div>,
          <Tab
            key="VISIT_PARAMETERS"
            id="VISIT_PARAMETERS"
            title="Work Up"
            panel={
              <div
                style={{
                  overflowY: "scroll",
                }}
              >
                <ClinicalVisitParametersSection
                  data={this.state}
                  addVisitParameter={async (data) =>
                    await this.addVisitParameter(data)
                  }
                  updateVisitParameter={(data) =>
                    this.updateVisitParameter(data)
                  }
                  removeVisitParameter={(id) => this.removeVisitParameter(id)}
                />
              </div>
            }
          />,
        ]
      : [];
    let haveAllergies =
      this.state.visitEntities.filter((item) => item.entityType === "ALLERGIES")
        .length > 0;

    this.masterVisitEntities.forEach((section, sectionIndex) => {
      allTabs.push(
        <div key={`tag-${sectionIndex}`} className={haveAllergies}>
          <Tag intent="success">{section.sectionNiceName}</Tag>
        </div>
      );
      section.subsections.forEach((subsection) => {
        if (!isClinical && !subsection.useInTemplate) return;
        const tabId = `${section.sectionName}__${subsection.subsectionName}`;

        if (
          section.sectionName === "HISTORY" &&
          subsection.subsectionName === "ALLERGY"
        ) {
          allTabs.push(
            <Tab
              icon={haveAllergies ? "virus" : null}
              style={haveAllergies ? { color: "#F05D0E" } : null}
              key={tabId}
              id={tabId}
              title={subsection.subsectionNiceName}
              panel={
                <div
                  style={{
                    overflowY: "scroll",
                  }}
                >
                  {this.renderTabPanel()}
                </div>
              }
            />
          );
        } else {
          allTabs.push(
            <Tab
              key={tabId}
              id={tabId}
              title={subsection.subsectionNiceName}
              panel={
                <div
                  style={{
                    overflowY: "scroll",
                  }}
                >
                  {this.renderTabPanel()}
                </div>
              }
            />
          );
        }
      });
    });
    if (isClinical) {
      allTabs.push(
        <div
          key={`tag-files`}
          style={{
            overflowY: "scroll",
          }}
        >
          <Tag intent="success" className="lime__bg">
            Files
          </Tag>
        </div>
      );

      allTabs.push(
        <Tab
          key="FILES"
          id="FILES"
          title="Investigations & Assets"
          panel={
            <div
              style={{
                overflowY: "scroll",
              }}
            >
              <FilesSection
                onFiles={(files) => this.setState({ files })}
                data={this.state}
                addVisitParameter={(data) => this.addVisitParameter(data)}
                updateVisitParameter={(data) => this.updateVisitParameter(data)}
                removeVisitParameter={(id) => this.removeVisitParameter(id)}
              />
            </div>
          }
        />
      );
    }
    return (
      <div style={{ paddingLeft: "5px", width: "100%" }}>
        <Tabs
          vertical
          id="CLINICAL_V2_TABS"
          onChange={(newTabId) => this.setState({ selectedTabId: newTabId })}
          selectedTabId={selectedTabId}
        >
          {allTabs}
        </Tabs>
      </div>
    );
  };

  render() {
    if (this.state.isError) {
      return (
        <NonIdealState
          className="bp5-dark"
          icon="warning-sign"
          title="We ran into an error."
        />
      );
    }
    if (this.state.isLoading) {
      return (
        <NonIdealState
          className="bp5-dark"
          icon={<Spinner />}
          title="Loading Clinical Record..."
        />
      );
    }
    let composerStyle = {
      width: ["VISIT_PARAMETERS"].includes(this.state.selectedTabId)
        ? "100vw"
        : "59vw",
      height: "85vh",
      marginBottom: "20px",
    };
    let previewStyle = {
      width: "39vw",
      height: "90vh",
      marginBottom: "20px",
      overflowY: "scroll",
    };
    return (
      <div style={{ padding: "5px" }}>
        <Drawer
          size="80%"
          title="Harley"
          className="colfax bp5-dark"
          icon="predictive-analysis"
          isOpen={this.state.showHarleyDrawer}
          onClose={() => {
            this.setState({
              showHarleyDrawer: false,
            });
          }}
        >
          <ChatterLanding
            mode="CLINICAL"
            data={this.state.visitEntities}
            visitId={this.state.visitId}
          />
        </Drawer>
        <Drawer
          size="80%"
          title="Set Roles & Times"
          className="colfax bp5-dark"
          icon="people"
          isOpen={this.state.showUsersDrawer}
          onClose={() => {
            this.setState({
              showUsersDrawer: false,
            });
          }}
        >
          <UserRoleBox
            visitData={this.state.visitData}
            onUpdateVisitData={(x) => {
              this.setState({
                visitData: x,
              });
            }}
          />
        </Drawer>
        <Drawer
          isOpen={this.state.showPastVisitsDrawer}
          onClose={() => {
            this.setState({
              showPastVisitsDrawer: false,
            });
          }}
          canEscapeKeyClose
          canOutsideClickClose
          icon="history"
          title={
            <ControlGroup>
              <Tag large minimal>
                Past Visits
              </Tag>
              <Button
                icon="download"
                text="Recent First"
                minimal
                intent={!this.state.order ? "success" : "none"}
                onClick={() =>
                  this.setState({ pastVisitDataOrder: "REVERSE_CHRONO" })
                }
              />
              <Button
                icon="upload"
                text="Oldest First"
                minimal
                intent={this.state.order ? "success" : "none"}
                onClick={() => this.setState({ pastVisitDataOrder: "CHRONO" })}
              />
            </ControlGroup>
          }
          className="bp5-dark grad axis"
          position="top"
          style={{ backgroundColor: "#26292c", overflowY: "scroll" }}
          size="80vh"
        >
          <PastVisit
            pastVisitData={this.state.pastVisitData}
            pastVisitDataOrder={this.state.pastVisitDataOrder}
          />
        </Drawer>
        {this.state.mode === "CLINICAL" ? (
          <ClinicalPatientHeader
            data={this.state}
            onUpdateVisitDataProp={async (prop, value) => {
              await this.onUpdateVisitDataProp(prop, value);
              this.setState({
                visitData: {
                  ...this.state.visitData,
                  [prop]: value,
                },
              });
            }}
            onLifecycleUpdate={(lifecycle) => this.updateLifecycle(lifecycle)}
            onOpenUsersDrawer={async () => {
              await this.getVisitData();
              await this.getVisitLifeCycle();
              this.setState({
                showUsersDrawer: true,
              });
            }}
            onOpenPastVisitsDrawer={async () => {
              await this.loadPastVisits();
              this.setState({
                showPastVisitsDrawer: true,
              });
            }}
          />
        ) : (
          <TemplateHeader
            data={this.state}
            onTemplatePropUpdate={(key, value) => {
              this.updateTemplateProp(key, value);
            }}
            onLifecycleUpdate={(lifecycle) => this.updateLifecycle(lifecycle)}
          />
        )}
        <div className="colfax flex__push__apart">
          <div style={composerStyle}> {this.renderTabs()}</div>
          {!["VISIT_PARAMETERS"].includes(this.state.selectedTabId) && (
            <div style={previewStyle}>
              <ClinicalRxPanel
                onHarleyDrawer={() =>
                  this.setState({
                    showHarleyDrawer: true,
                  })
                }
                recentItems={this.state.recentItems}
                removeVisitParameter={(id) => this.removeVisitParameter(id)}
                data={this.state}
                onChoice={(tabId) => {
                  this.setState({ selectedTabId: tabId });
                }}
                removeVisitEntity={(id, laterality) =>
                  this.removeVisitEntity(id, laterality)
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(ClinicalV2));
