import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  HTMLSelect,
  NumericInput,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import _ from "lodash";
const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};
class AddDiscount extends React.Component {
  state = {
    loading: false,
    typeOfDiscount: null,
    lineItemId: null,
    authorisedBy: null,
    discountSelector: null,
    discountAmount: null,
  };

  discountReasons = [
    "Cancellation",
    "Doctor Discretion",
    "Administrative Discretion",
    "Cross-Referral",
    "Post-Operative Visit",
    "Follow Up Visit",
    "Courtesy",
    "Family or Friend",
    "Service Discretion",
    "Clinical Trial",
    "Equipment Unavailable",
    "Doctor Unavailable",
    "Change of Doctor",
    "Change of Investigation",
    "Doctor Refused",
    "Patient Refused",
    "Waiting Time",
    "Discount",
    "Service Issue",
    "Insurance Issue",
  ];
  addDiscount = () => {
    let payload = {
      lineItemId: this.state.lineItemId,
      authorisedBy: this.state.authorisedBy,
      amountPaise: this.state.discountAmount,
      reason: this.state.reason,
    };
    this.props.addDiscount(payload);
  };
  addDiscDebounced = debounce(this.addDiscount, 1000);
  renderAction = () => {
    if (!this.state.reason) {
      return null;
    }
    if (!this.state.authorisedBy) {
      return null;
    }
    if (!this.state.lineItemId) {
      return null;
    }

    if (!this.state.discountSelector) {
      return null;
    }
    if (!this.state.discountAmount) {
      return null;
    }

    return (
      <Button
        loading={this.state.loading}
        intent="warning"
        rightIcon="arrow-right"
        text="Apply Discount"
        onClick={() => {
          this.setState({ loading: true }, () => this.addDiscDebounced());
        }}
      />
    );
  };
  renderLineItem = () => {
    let items = [{ label: "Select", value: null }];
    _.map(this.props.items, (each) => {
      if (
        each.payment.status === "APPROVED" &&
        each.payment.cash &&
        !each.packageParentLnItem
      ) {
        let str = "";
        if (each.kind === "consultation") {
          str = "Consultation: " + each.resource;
        }
        if (each.kind === "investigation") {
          str =
            each.resource + //OCT
            " " +
            each.investigationDetail.specificKind + // RNFL
            " " +
            each.investigationDetail.applicable; // Both Eyes
        }
        if (each.kind === "package_parent") {
          str = each.resource;
        }
        items.push({ label: str, value: each._id });
      }
    });

    return (
      <HTMLSelect
        options={items}
        onChange={(event) => {
          this.setState({
            lineItemId: event.currentTarget.value,
            discountAmount: null,
            discountSelector: null,
          });
        }}
      />
    );
  };
  renderDetail = () => {
    if (!this.state.discountSelector) {
      return null;
    }

    let lineItemIdx = _.findIndex(
      this.props.items,
      (each) => each._id === this.state.lineItemId
    );
    let rate = this.props.items[lineItemIdx].payment.cash;

    if (this.state.discountSelector === "Complementary") {
      return (
        <Tag large minimal intent="warning">
          INR {this.state.discountAmount / 100}
        </Tag>
      );
    }

    if (this.state.discountSelector === "Amount") {
      return (
        <>
          <NumericInput
            buttonPosition="none"
            minorStepSize={0.01}
            min={0}
            max={rate}
            clampValueOnBlur
            intent="primary"
            onValueChange={(value) =>
              this.setState({ discountAmount: value * 100 })
            }
          />{" "}
          <Tag large minimal intent="warning">
            INR {this.state.discountAmount / 100}
          </Tag>
        </>
      );
    }
    if (this.state.discountSelector === "Percentage") {
      return (
        <>
          {" "}
          <NumericInput
            leftIcon="percentage"
            buttonPosition="none"
            minorStepSize={1}
            min={0}
            max={100}
            clampValueOnBlur
            intent="primary"
            onValueChange={(value) =>
              this.setState({
                discountAmount: Math.round((rate * value) / 100),
              })
            }
          />
          <Tag large minimal intent="warning">
            INR {this.state.discountAmount / 100}
          </Tag>
        </>
      );
    }
  };

  renderSelector = () => {
    if (!this.state.lineItemId) {
      return null;
    }
    return (
      <>
        {" "}
        <HTMLSelect
          options={["Select", "Percentage", "Amount", "Complementary"]}
          onChange={(event) => {
            if (event.currentTarget.value !== "Select") {
              this.setState(
                {
                  discountSelector: event.currentTarget.value,
                  discountAmount: null,
                },
                () => {
                  if (this.state.discountSelector === "Complementary") {
                    let lineItemIdx = _.findIndex(
                      this.props.items,
                      (each) => each._id === this.state.lineItemId
                    );
                    let rate = this.props.items[lineItemIdx].payment.cash;

                    this.setState({ discountAmount: rate });
                  }
                }
              );
            }
          }}
        />
      </>
    );
  };
  render() {
    return (
      <Card
        className="bp5-dark"
        style={{
          backgroundColor: "#464B53",
          margin: "10px",
          textAlign: "left",
        }}
        elevation={Elevation.THREE}
      >
        <ControlGroup>
          <Tag large intent="warning">
            Add Discount
          </Tag>
          <Tag minimal large>
            Authorised By
          </Tag>
          <SingleSelectWrapper
            className="bp5-dark"
            items={this.props.docList}
            onChange={(item) => {
              this.setState({ authorisedBy: item });
            }}
          />
          {this.renderLineItem()}
          {this.renderSelector()}
          {this.renderDetail()}
          <Tag large minimal>
            Reason
          </Tag>
          <SingleSelectWrapper
            className="bp5-dark"
            items={this.discountReasons}
            onChange={(item) => {
              this.setState({ reason: item });
            }}
          />
          {this.renderAction()}
        </ControlGroup>
      </Card>
    );
  }
}

export default AddDiscount;
