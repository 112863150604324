import { Drawer } from "@blueprintjs/core";
import React from "react";
import Template from "./Template";
import TemplateMother from "./TemplateMother";

class TemplateDrawer extends React.Component {
  state = {
    chosenTemplateId: null,
  };
  render() {
    return (
      <Drawer
        isOpen={this.props.showTemplateDrawer}
        onClose={() => {
          this.setState({ chosenTemplateId: null });
          this.props.onClose();
        }}
        canEscapeKeyClose
        canOutsideClickClose
        icon="code-block"
        title="Templates"
        className="bp5-dark grad axis"
        position="right"
        style={{ backgroundColor: "#26292c" }}
        size="80vw"
      >
        <div style={{ overflow: "scroll", marginBottom: "20px" }}>
          {" "}
          {this.state.chosenTemplateId ? null : (
            <TemplateMother
              mode="clinical"
              setTemplateId={(id) => this.setState({ chosenTemplateId: id })}
            />
          )}
          {this.state.chosenTemplateId ? (
            <Template
              templateId={this.state.chosenTemplateId}
              onBulkAdd={this.props.onBulkAdd}
              onBack={() => this.setState({ chosenTemplateId: null })}
            />
          ) : null}
        </div>
      </Drawer>
    );
  }
}

export default TemplateDrawer;
