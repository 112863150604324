import { MultistepDialog, DialogStep, H3 } from "@blueprintjs/core";
import React from "react";
import ColourVision from "../elems/ColourVision";
import ContrastSens from "../elems/ContrastSens";
import Dilation from "../elems/Dilation";
import GlassAge from "../elems/GlassAge";
import IOPTable from "../elems/IOPTable";
import PupRx from "../elems/PupRx";
import Schirmer from "../elems/Schirmer";
import WorthFourDot from "../elems/WorthFourDot";
import VisTable from "../VisTable";

class VisionWizard extends React.Component {
  renderTitle = (data) => {
    return <H3>{data}</H3>;
  };
  render() {
    return (
      <div>
        <MultistepDialog
          isOpen={this.props.isOpen}
          className="bp5-dark axis"
          style={{ width: "90vw" }}
          icon="eye-open"
          initialStepIndex={0}
          title="Optometry"
          onClose={() => this.props.onClose()}
          finalButtonProps={{ onClick: () => this.props.onClose() }}
        >
          <DialogStep
            id="pup-rxn"
            panel={
              <div style={{ padding: "20px", height: "100%" }}>
                {this.renderTitle(
                  "Please record the pupillary reaction of the patient."
                )}
                <br />
                <PupRx
                  vision={this.props.vision}
                  updateVision={this.props.updateVision}
                />
              </div>
            }
            title="Pupillary Reaction"
          />
          <DialogStep
            id="iop"
            panel={
              <div style={{ padding: "20px", height: "100%" }}>
                {this.renderTitle(
                  "Please record the intraocular pressure of the patient."
                )}
                <br />
                <IOPTable
                  vision={this.props.vision}
                  updateVision={this.props.updateVision}
                />
              </div>
            }
            title="Intraocular Pressure"
          />
          <DialogStep
            id="glasses"
            panel={
              <div style={{ padding: "20px", height: "100%" }}>
                {this.renderTitle("Is the patient currently wearing glasses?")}
                <br />
                <GlassAge
                  large={true}
                  vision={this.props.vision}
                  updateVision={this.props.updateVision}
                />
              </div>
            }
            title="History of Glasses"
          />
          <DialogStep
            id="vision"
            panel={
              <div style={{ padding: "20px", height: "100%" }}>
                {this.renderTitle(
                  "Please record the vision and refraction of the patient."
                )}
                <br />
                <VisTable
                  vision={this.props.vision}
                  onCollapse={() => {}}
                  updateVision={this.props.updateVision}
                />
              </div>
            }
            title="Vision"
          />
          <DialogStep
            id="contrast"
            panel={
              <div style={{ padding: "20px", height: "100%" }}>
                {this.renderTitle(
                  "Please record the contrast vision of the patient on the Pelli-Robson Chart."
                )}
                <br />
                <ContrastSens
                  vision={this.props.vision}
                  updateVision={this.props.updateVision}
                />
              </div>
            }
            title="Contrast Sensitivity"
          />
          <DialogStep
            id="colour"
            panel={
              <div style={{ padding: "20px", height: "100%" }}>
                {this.renderTitle(
                  "Please check the colour vision of the patient."
                )}
                <br />
                <ColourVision
                  large={true}
                  colourVision={this.props.colourVision}
                  updateScalar={this.props.updateScalar}
                />
              </div>
            }
            title="Colour Vision"
          />{" "}
          <DialogStep
            id="schirmer"
            panel={
              <div style={{ padding: "20px", height: "100%" }}>
                {this.renderTitle(
                  "Please record the results of Schirmer's Test."
                )}
                <br />
                <Schirmer
                  vision={this.props.vision}
                  updateVision={this.props.updateVision}
                />
              </div>
            }
            title="Schirmer's Test"
          />{" "}
          <DialogStep
            id="worth"
            panel={
              <div style={{ padding: "20px", height: "100%" }}>
                {this.renderTitle(
                  "Please record the results of Worth's Four Dot Test."
                )}
                <br />
                <WorthFourDot
                  vision={this.props.vision}
                  updateVision={this.props.updateVision}
                />
              </div>
            }
            title="Worth's Four Dot Test"
          />{" "}
          <DialogStep
            id="dilation"
            panel={
              <div style={{ padding: "20px", height: "100%" }}>
                {this.renderTitle("Are you dilating this patient?")}
                <br />
                <Dilation
                  vision={this.props.vision}
                  updateVision={this.props.updateVision}
                />
              </div>
            }
            title="Dilation"
          />{" "}
        </MultistepDialog>{" "}
      </div>
    );
  }
}

export default VisionWizard;
