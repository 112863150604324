import React from "react";
import { Icon, MenuItem, Spinner, Tag } from "@blueprintjs/core";
import { Omnibar } from "@blueprintjs/select";

class Omni extends React.Component {
  state = {
    item: null,
    width: "100px",
  };

  renderMI = (item, query) => {
    return (
      <div className="axis">
        {item.item}
        {/*     {this.highlightText(item.item, query)}
         */}{" "}
        <br />
        <Tag minimal>{item.entityTypeString}</Tag>{" "}
        <Icon icon="caret-right" minimal />
        <Tag minimal>{item.helper}</Tag>
        {item.helper2 ? <Icon icon="caret-right" minimal /> : null}
        {item.helper2 ? <Tag minimal>{item.helper2}</Tag> : null}
      </div>
    );
  };
  renderItem = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    let label = null;

    return (
      <MenuItem
        className="axis"
        active={modifiers.active}
        disabled={modifiers.disabled}
        onClick={handleClick}
        text={this.renderMI(item, query)}
        shouldDismissPopover={false}
        label={label}
      />
    );
  };
  escapeRegExpChars = (text) => {
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
  };
  highlightText = (text, query) => {
    let lastIndex = 0;
    const words = query
      .split(/\s+/)
      .filter((word) => word.length > 0)
      .map(this.escapeRegExpChars);
    if (words.length === 0) {
      return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens = [];
    while (true) {
      const match = regexp.exec(text);
      if (!match) {
        break;
      }
      const length = match[0].length;
      const before = text.slice(lastIndex, regexp.lastIndex - length);
      if (before.length > 0) {
        tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
      tokens.push(rest);
    }
    return tokens;
  };
  handleItemSelect = (item) => {
    this.setState({ item: item.item });
    this.props.onChange(item);
  };
  filterItem = (query, item) => {
    /** Implement Any Random Search Here which returns a Boolean */
    /** Correspondingly IF you want aesthetics, change `highlightText` */
    return true;
  };

  render() {
    let inputProps = {};
    if (this.props.placeholder) {
      inputProps["placeholder"] = this.props.placeholder;
    }
    if (!this.props.width) {
      inputProps["style"] = { width: "300px" };
    }
    let items = this.props.items;
    return (
      <Omnibar
        key={this.props.isOpen}
        isOpen={this.props.isOpen}
        onClose={() => this.props.onClose()}
        className="axis bp5-dark"
        query={this.props.query}
        onQueryChange={(event) => this.props.update(event)}
        inputProps={{
          placeholder: this.props.placeholder || "Search...",
        }}
        itemPredicate={(query, item) => {
          return true;
        }}
        items={items}
        noResults={
          this.props.searching ? (
            <MenuItem
              disabled={true}
              className="axis"
              label={<Spinner size={20} />}
              text="Searching..."
            />
          ) : (
            <MenuItem className="axis" disabled={true} text="No results." />
          )
        }
        itemRenderer={this.renderItem}
        selectedItem={
          this.state.item
            ? this.state.item
            : this.props.selectedItem
            ? this.props.selectedItem
            : null
        }
        onItemSelect={this.handleItemSelect}
        inputValueRenderer={(item) => item}
        popoverProps={{ minimal: true }}
      />
    );
  }
}

export default Omni;
