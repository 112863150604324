import { H3, Tag } from "@blueprintjs/core";
import React from "react";
import Diagnosis from "../elems/Diagnosis";
import SingleSearch from "./SingleSearch";

class PrevDxPanel extends React.Component {
  renderTitle = () => {
    return <H3>Is there a history of previous eye diseases?</H3>;
  };
  renderCG = () => {
    return (
      <SingleSearch
        prefix="dx"
        whichArray="prevEyeDx"
        add={this.props.add}
        placeholder="Eye Diseases"
      />
    );
  };

  renderPrevEyeDx = () => {
    if (!this.props.prevEyeDx || !this.props.prevEyeDx.length) {
      return (
        <Tag minimal large>
          No previous eye diseases added.
        </Tag>
      );
    }
    return this.props.prevEyeDx.map((each) => {
      return (
        <Diagnosis
          data={each}
          update={(dxId, key, value) =>
            this.props.update("prevEyeDx", dxId, key, value)
          }
          remove={(dxId) => this.props.removeArray("prevEyeDx", dxId)}
        />
      );
    });
  };
  render() {
    return (
      <div style={{ padding: "20px", height: "100%" }}>
        {this.renderTitle()}

        <br />
        {this.renderCG()}
        <br />
        {this.renderPrevEyeDx()}
      </div>
    );
  }
}

export default PrevDxPanel;
