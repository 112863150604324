import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import InvestigationCard from "../elems/InvestigationCard";

class InvBooked extends React.Component {
  render() {
    return (
      <div>
        <ControlGroup>
          <Tag large minimal>
            This investigation has been booked, but not activated yet.
          </Tag>
        </ControlGroup>
        <br />
        <InvestigationCard
          data={this.props.data}
          update={(invId, key, value) =>
            this.props.update("INVESTIGATION", invId, key, value)
          }
          remove={(invId) => this.props.remove(invId)}
        />
      </div>
    );
  }
}

export default InvBooked;
