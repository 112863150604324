import {
  AnchorButton,
  Button,
  ControlGroup,
  Drawer,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import { fixUrlPrefix, initials, niceDate } from "../fns/util";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";

class HistoryDrop extends React.Component {
  state = {
    order: false,
  };
  iconsDelta = {
    Same: "ring",
    Increasing: "caret-up",
    Decreasing: "caret-down",
  };
  computeBestVision = (v) => {
    let final = [null, null];
    if (v.has_uncorrected) final = ["Uncorrected", v.uncorrected];
    if (v.has_pgp) final = ["PGP", v.pgp];
    if (v.has_corrected_dry) final = ["Dry", v.corrected_dry];
    if (v.has_corrected_dilated) final = ["Dilated", v.corrected_dilated];
    if (v.has_corrected_pmt) final = ["PMT", v.corrected_pmt];
    if (v.has_corrected_rx) final = ["Rx", v.corrected_rx];
    return final;
  };

  renderVitals = (obj) => {
    let vision = obj.vision;
    let vc = this.computeBestVision(vision);

    let iopEye = vision.at.le === "-" ? "nct" : "at";
    let iop = { le: vision[iopEye].le, re: vision[iopEye].re };
    return (
      <table
        className="bp5-html-table bp5-html-table-condensed  bp5-html-table-striped"
        style={{ fontSize: "smaller" }}
      >
        <thead>
          <tr>
            <th>{vc[0] || "No VA"}</th>
            <th>RE</th>
            <th>LE</th>
          </tr>
        </thead>
        <tbody>
          {vc[0] ? this.renderVisionTable(vc[1]) : null}
          {this.renderIop(iopEye, iop)}
        </tbody>
      </table>
    );
  };
  renderIop = (iopEye, iop) => {
    return (
      <tr>
        <td>{_.upperCase(iopEye)}</td>
        <td>{iop.re}</td>
        <td>{iop.le}</td>
      </tr>
    );
  };
  renderVisionTable = (v) => {
    return (
      <>
        <tr>
          <td>Dist</td>
          <td>{v.re.v_dist}</td>
          <td>{v.le.v_dist}</td>
        </tr>
        <tr>
          <td>PH</td>
          <td>{v.re.v_ph}</td>
          <td>{v.le.v_ph}</td>
        </tr>
        <tr>
          <td>Near</td>
          <td>{v.re.v_near}</td>
          <td>{v.le.v_near}</td>
        </tr>
      </>
    );
  };
  renderSymptoms = (obj) => {
    let symptoms = obj.symptoms;
    if (!_.isEmpty(symptoms)) {
      let strs = [];
      strs = symptoms.map((each) => [
        `${each.site}`,
        `${each.symptom}`,
        each.sinceLastVisit,
      ]);
      return strs.map((each) => {
        return (
          <ControlGroup>
            <Tag minimal intent="warning" multiline>
              {each[0]}
            </Tag>
            <Tag
              minimal
              multiline
              style={{ maxWidth: "80px" }}
              rightIcon={each[2] ? this.iconsDelta[each[2]] : null}
            >
              {each[1]}
            </Tag>
          </ControlGroup>
        );
      });
    }
  };
  renderDate = (obj) => {
    return (
      <ControlGroup vertical>
        <Tag minimal>{niceDate(obj.registrationTime)} </Tag>
        <Tag minimal intent="primary">
          Doc: {obj.doctor}
        </Tag>
        <Tag minimal>{obj.branch}</Tag>
        <Tag minimal>{obj.purpose}</Tag>
        <Tag minimal>Optom: {obj.vision.visionDoneBy || ""}</Tag>
        <Tag minimal>{obj._id}</Tag>
        <ControlGroup>
          <AnchorButton
            minimal
            icon="prescription"
            target="_blank"
            href={fixUrlPrefix(obj.rxPdfLink)}
          />
          <AnchorButton
            minimal
            icon="eye-on"
            target="_blank"
            href={fixUrlPrefix(obj.glassRxPdfLink)}
          />
          {/*  <Button
            minimal
            icon="double-chevron-right"
            onClick={() => {
              let str = "/" + this.props.genericPid + "/" + obj._id;
              this.setState({ redirect: str });
              this.props.history.push(str);
            }}
          /> */}
        </ControlGroup>
      </ControlGroup>
    );
  };
  renderFindingsRE = (obj) => {
    let fnd = obj.findings;
    let fnds = [];
    _.mapValues(fnd, (each) => {
      fnds.push(each);
    });
    fnds = _.flatten(fnds);
    let fndsG = _.groupBy(fnds, "site");
    let le = fndsG["Right Eye"];
    if (!_.isEmpty(le)) {
      let strs = [];
      strs = le.map((each) => [
        each.anatomy,
        `${each.item} ${each.qualifier || ""} ${each.splText || ""}`,
        each.sinceLastVisit,
      ]);
      return strs.map((each) => {
        return (
          <ControlGroup>
            {" "}
            <Tag minimal multiline intent="warning">
              {each[0]}
            </Tag>{" "}
            <Tag
              minimal
              multiline
              style={{ maxWidth: "80px" }}
              rightIcon={each[2] ? this.iconsDelta[each[2]] : null}
            >
              {each[1]}
            </Tag>
          </ControlGroup>
        );
      });
    }
  };
  renderFindingsLE = (obj) => {
    let fnd = obj.findings;
    let fnds = [];
    _.mapValues(fnd, (each) => {
      fnds.push(each);
    });
    fnds = _.flatten(fnds);
    let fndsG = _.groupBy(fnds, "site");
    let le = fndsG["Left Eye"];
    if (!_.isEmpty(le)) {
      let strs = [];
      strs = le.map((each) => [
        each.anatomy,
        `${each.item} ${each.qualifier || ""} ${each.splText || ""}`,
        each.sinceLastVisit,
      ]);
      return strs.map((each) => {
        return (
          <ControlGroup>
            {" "}
            <Tag minimal multiline intent="warning">
              {each[0]}
            </Tag>{" "}
            <Tag
              minimal
              multiline
              style={{ maxWidth: "80px" }}
              rightIcon={each[2] ? this.iconsDelta[each[2]] : null}
            >
              {each[1]}
            </Tag>
          </ControlGroup>
        );
      });
    }
  };
  renderDx = (obj) => {
    let dx = obj.eyeDx;
    if (!_.isEmpty(dx)) {
      let strs = [];
      strs = dx.map((each) => [initials(each.site), `${each.disease || ""} `]);
      return strs.map((each) => {
        return (
          <ControlGroup>
            <Tag minimal intent="warning" multiline>
              {each[0]}
            </Tag>
            <Tag minimal multiline style={{ maxWidth: "80px" }}>
              {each[1]}
            </Tag>
          </ControlGroup>
        );
      });
    }
  };
  renderInvProc = (obj) => {
    let investigations = obj.investigations;
    let procedures = obj.procedures;
    let ip = _.concat(investigations, procedures);
    if (!_.isEmpty(ip)) {
      let strs = [];
      strs = ip.map((each) => [
        initials(each.eye),
        `${each.type || ""} ${each.subtype || ""}`,
        each.summary,
      ]);
      return strs.map((each) => {
        return (
          <ControlGroup vertical>
            {" "}
            <ControlGroup>
              <Tag minimal intent="warning" multiline>
                {each[0]}
              </Tag>
              {each[1] ? (
                <Tag minimal multiline style={{ maxWidth: "80px" }}>
                  {each[1]}
                </Tag>
              ) : null}
            </ControlGroup>
            {each[2] ? (
              <Tag
                minimal
                intent="primary"
                multiline
                style={{ maxWidth: "90px" }}
              >
                {each[2]}
              </Tag>
            ) : null}
          </ControlGroup>
        );
      });
    }
  };
  renderPlan = (obj) => {
    let medicine = obj.medicines;
    let adv = obj.advice;
    let strs = [];
    if (!_.isEmpty(medicine)) {
      strs = medicine.map((each) => [
        each.eye,
        `${each.brand || ""} ${each.duration || ""}`,
      ]);
    }
    let advStr = [];
    if (!_.isEmpty(adv)) {
      advStr = adv.map((each) => ["Advice", `${each.text || ""} `]);
    }
    let followup = obj.followup;
    let fu = [
      [
        "FU",
        `${followup[0].when} ${followup[0].refer ? followup[0].referTo : ""}`,
      ],
    ];
    let arr = _.concat(strs, advStr, fu);
    if (!_.isEmpty(arr)) {
      return arr.map((each) => {
        return (
          <ControlGroup>
            <Tag
              minimal
              intent={
                each[0] === "Advice"
                  ? "primary"
                  : each[0] === "FU"
                  ? "danger"
                  : "success"
              }
              multiline
            >
              {each[0]}
            </Tag>
            <Tag minimal multiline style={{ maxWidth: "80px" }}>
              {each[1]}
            </Tag>
          </ControlGroup>
        );
      });
    }
  };
  renderSx = (obj) => {
    let sx = obj.plannedEyeSx;
    if (!_.isEmpty(sx)) {
      let strs = sx.map((each) => `${each.composeString || ""} `);
      return strs.map((each) => {
        return (
          <ControlGroup>
            <Tag
              minimal
              multiline
              intent="success"
              style={{ maxWidth: "80px" }}
            >
              {each}
            </Tag>
          </ControlGroup>
        );
      });
    }
  };
  renderRows = () => {
    let objs = this.props.data;
    let sortedObjs = objs.sort(
      (a, b) => new Date(b.registrationTime) - new Date(a.registrationTime)
    );
    if (this.state.order) {
      sortedObjs = _.reverse(sortedObjs);
    }
    return sortedObjs.map((each) => {
      return this.renderRow(each);
    });
  };
  renderTable = () => {
    return (
      <table
        className="bp5-html-table bp5-html-table-condensed  bp5-html-table-striped bp5-html-table-bordered"
        style={{ width: "100%", overflow: "scroll" }}
      >
        <thead>
          <tr>
            <th>Date</th>
            <th>Vitals</th>
            <th>Subjective</th>
            <th>Obj: Right Eye</th>
            <th>Obj: Left Eye</th>
            <th>Dx</th>
            {/*   <th>Inv/Proc</th> */}
            <th>Plan</th>
            <th>Sx Planned</th>
          </tr>
        </thead>
        <tbody style={{ width: "100%", overflow: "scroll" }}>
          {this.renderRows()}{" "}
        </tbody>
      </table>
    );
  };
  renderRow = (obj) => {
    return (
      <tr style={{ width: "100%", overflow: "scroll" }}>
        <td>{this.renderDate(obj)}</td>
        <td>{this.renderVitals(obj)}</td>
        <td>
          <ControlGroup vertical>{this.renderSymptoms(obj)}</ControlGroup>
        </td>
        <td>
          <ControlGroup vertical>{this.renderFindingsRE(obj)}</ControlGroup>
        </td>
        <td>
          <ControlGroup vertical>{this.renderFindingsLE(obj)}</ControlGroup>
        </td>
        <td>
          <ControlGroup vertical>{this.renderDx(obj)}</ControlGroup>
        </td>
        {/*  <td>
          {" "}
          <ControlGroup vertical>
            {this.renderInvProc(obj)}
          </ControlGroup>
        </td> */}
        <td>
          {" "}
          <ControlGroup vertical>{this.renderPlan(obj)}</ControlGroup>
        </td>
        <td>
          {" "}
          <ControlGroup vertical>{this.renderSx(obj)}</ControlGroup>
        </td>
      </tr>
    );
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Drawer
        isOpen={this.props.showPast}
        onClose={() => {
          this.props.onClose();
        }}
        canEscapeKeyClose
        canOutsideClickClose
        icon="history"
        title={
          <ControlGroup>
            <Tag large minimal>
              Past Visits
            </Tag>
            <Button
              icon="download"
              text="Recent First"
              minimal
              intent={!this.state.order ? "success" : "none"}
              onClick={() => this.setState({ order: false })}
            />
            <Button
              icon="upload"
              text="Oldest First"
              minimal
              intent={this.state.order ? "success" : "none"}
              onClick={() => this.setState({ order: true })}
            />
          </ControlGroup>
        }
        className="bp5-dark grad axis"
        position="top"
        style={{ backgroundColor: "#26292c" }}
        size="80vh"
      >
        <div
          className="bp5-dark"
          style={{
            width: "100%",
            padding: "10px",
            overflow: "scroll",
          }}
        >
          {this.renderTable()}
        </div>
      </Drawer>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(withRouter(HistoryDrop)));
