import "@pqina/pintura/pintura.css";
import { getEditorDefaults, createDefaultImageScrambler } from "@pqina/pintura";
import { PinturaEditor } from "@pqina/react-pintura";
import { useRef, useState } from "react";
import _ from "lodash";
import { masterDiagramOptions } from "../masterDiagramOptions";

const editorConfig = getEditorDefaults();
function Paint(props) {
  let config = props.config;
  const editorRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const handleEditorProcess = (imageWriterResult) => {
    props.onProcess(imageWriterResult);
  };
  let masterFile = _.find(masterDiagramOptions, {
    diagramType: config.diagramType,
  });
  let isEdited = config.finalFileKey;
  const loadImageBeforeEdit = () => {
    console.log("Loading image before edit");
    let annotations = isEdited
      ? config.imageState.annotation
      : masterFile.annotations;
    if (annotations && annotations.length > 0) {
      editorRef.current.editor.editImage(masterFile.url, {
        imageAnnotation: annotations,
      });
    }
  };

  return (
    <div className="Paint" style={{ height: "100%" }}>
      <PinturaEditor
        ref={editorRef}
        {...editorConfig}
        locale={_.assign({}, editorConfig.locale, masterFile.localeAdditionals)}
        imageRedactionRendering="pixelated"
        src={masterFile.url}
        stickers={masterFile.stickers}
        utils={masterFile.utils}
        enableButtonExport={true}
        imageScrambler={createDefaultImageScrambler({
          scrambleAmount: 3,
        })}
        onLoad={() => {
          setLoaded(true);
          if (!loaded) {
            loadImageBeforeEdit();
          }
        }}
        onProcess={handleEditorProcess}
      />
    </div>
  );
}

export default Paint;
