import React from "react";
import {
  Card,
  Tabs,
  Tab,
  Button,
  Elevation,
  NonIdealState,
} from "@blueprintjs/core";
import SpecularContainer from "./specular/SpecularContainer";
import InvBooked from "./InvBooked";
import InvActivated from "./InvActivated";
import BiometryContainer from "./biometry/BiometryContainer";
import OCTContainer from "./oct/OCTContainer";
import VFAContainer from "./vfa/VFAContainer";
import ASPContainer from "./asp/ASPContainer";
import DFPContainer from "./dfp/DFPContainer";

class InvContainer extends React.Component {
  returnPanel = (each) => {
    if (each.lifeCycle === "BOOKED") {
      return (
        <InvBooked
          data={each}
          update={this.props.updateInv}
          remove={this.props.removeInv}
        />
      );
    }
    if (each.lifeCycle === "ACTIVATED") {
      return <InvActivated data={each} patientData={this.props.patientData} />;
    }
    if (each.lifeCycle === "UPLOADED") {
      return (
        <InvActivated
          data={each}
          patientData={this.props.patientData}
          uploaded={true}
        />
      );
    }
    if (each.type === "DFP") {
      return (
        <Card
          elevation={Elevation.FOUR}
          style={{
            overflow: "scroll",
            backgroundColor: "#17191a",
          }}
        >
          <DFPContainer data={each} update={this.props.updateInv} />
        </Card>
      );
    }

    if (each.type === "ASP") {
      return (
        <Card
          elevation={Elevation.FOUR}
          style={{
            overflow: "scroll",
            backgroundColor: "#17191a",
          }}
        >
          <ASPContainer data={each} update={this.props.updateInv} />
        </Card>
      );
    }

    if (each.type === "OCT") {
      return (
        <Card
          elevation={Elevation.FOUR}
          style={{
            overflow: "scroll",
            backgroundColor: "#17191a",
          }}
        >
          <OCTContainer data={each} update={this.props.updateInv} />
        </Card>
      );
    }

    if (each.type === "Biometry") {
      return (
        <Card
          elevation={Elevation.FOUR}
          style={{
            overflow: "scroll",
            backgroundColor: "#17191a",
          }}
        >
          <BiometryContainer data={each} />
        </Card>
      );
    }
    if (each.type === "Specular") {
      return (
        <Card
          elevation={Elevation.FOUR}
          style={{
            overflow: "scroll",
            backgroundColor: "#17191a",
          }}
        >
          <SpecularContainer data={each} />
        </Card>
      );
    }
    if (each.type === "VFA") {
      return (
        <Card
          elevation={Elevation.FOUR}
          style={{
            overflow: "scroll",
            backgroundColor: "#17191a",
          }}
        >
          <VFAContainer data={each} update={this.props.updateInv} />
        </Card>
      );
    }
  };
  renderTabsForEachInv = () => {
    return this.props.data.map((each) => {
      return (
        <Tab
          id={each._id}
          title={`${each.type} ${
            each.subtype === "Generic" ? "" : each.subtype
          }`}
          panel={
            <div
              style={{
                width: "100%",
                overflow: "scroll",
              }}
            >
              {this.returnPanel(each)}
              <br />
            </div>
          }
        ></Tab>
      );
    });
  };

  /**
   *
   * BOOKED ACTIVATED UPLOADED PROCESSED DONE
   */
  render() {
    if (!this.props.data.length) {
      return (
        <NonIdealState
          className="bp5-dark"
          title="No Investigations Added."
          icon="media"
          description="Once investigations are done, they will be processed automatically and you can view them here. "
        />
      );
    }
    return (
      <div className="bp5-dark">
        <Card
          elevation={Elevation.FOUR}
          style={{
            width: "100%",
            backgroundColor: "#26292c",
          }}
        >
          <Tabs id="InvestigationTabs" vertical>
            {this.renderTabsForEachInv()}
            <Button
              icon="search"
              minimal
              text="Add Investigation"
              onClick={() => this.props.onSearch("inv", "investigations")}
            />
          </Tabs>
        </Card>{" "}
      </div>
    );
  }
}

export default InvContainer;
