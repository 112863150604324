import React from "react";
import _ from "lodash";
import { apiCallPost } from "../../../fns/util";
import cogoToast from "cogo-toast";
import { inject, observer } from "mobx-react";
import { NonIdealState, Spinner, Tabs, Tab } from "@blueprintjs/core";
import BiometryMeasures from "./BiometryMeasures";
import IOLCalc from "./IOLCalc";

class BiometryContainer extends React.Component {
  state = { loading: true, data: {} };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      this.setState({ loading: true });
      cogoToast.loading("Loading data...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/getInvestigation`,
        { _id: this.props.data._id }
      );
      cogoToast.success("Loaded data.", { position: "bottom-left" });
      this.setState({ loading: false, data: res.annotations });
    } catch (err) {
      cogoToast.error(
        "There was an error loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
    // IOL_MEASURED_VALUE, IOLS_LENS_TABLE_ROW
  };
  renderOneEye = (rows, values) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          flexDirection: "column",
          overflow: "scroll",
        }}
      >
        <div
          style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}
        >
          <BiometryMeasures data={values} />
          <IOLCalc data={rows} />
        </div>
      </div>
    );
  };
  renderTabsOrNot = () => {
    if (this.state.loading) {
      return null;
    }

    let annotations = this.state.data;
    let rows = _.filter(
      annotations,
      (each) => each.kind === "IOLS_LENS_TABLE_ROW"
    );

    let rowsRE = _.filter(rows, (each) => each.eye === "OD");
    let rowsLE = _.filter(rows, (each) => each.eye === "OS");
    let le_ = rowsLE.length;
    let re_ = rowsRE.length;
    let both = false;
    if (le_ && re_) {
      both = true;
    }
    if (both) {
      return (
        <Tabs id="biometry" large>
          <Tab
            id="RE"
            title="Right Eye"
            panel={this.renderOneEye(
              rowsRE,
              _.filter(
                _.filter(this.state.data, (each) => each.eye === "OD"),
                (each) => each.kind === "IOL_MEASURED_VALUE"
              )
            )}
          />{" "}
          <Tab
            id="LE"
            title="Left Eye"
            panel={this.renderOneEye(
              rowsLE,
              _.filter(
                _.filter(this.state.data, (each) => each.eye === "OS"),
                (each) => each.kind === "IOL_MEASURED_VALUE"
              )
            )}
          />
        </Tabs>
      );
    } else {
      let which = "OD";
      let whichA = rowsRE;
      if (le_) {
        which = "OS";
        whichA = rowsLE;
      }

      return this.renderOneEye(
        whichA,
        _.filter(
          _.filter(this.state.data, (each) => each.eye === which),
          (each) => each.kind === "IOL_MEASURED_VALUE"
        )
      );
    }
  };
  render() {
    if (this.state.loading) {
      return (
        <NonIdealState
          icon={<Spinner />}
          title="Loading data"
          description={`Loading data for ${this.props.data.type}`}
        />
      );
    }
    return this.renderTabsOrNot();
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(BiometryContainer));
