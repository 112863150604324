import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@blueprintjs/core";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
      onClick={() => {
        localStorage.setItem("auth0jwt", null);
        localStorage.setItem("authStore", null);
        logout({ returnTo: window.location.origin });
      }}
      minimal
      icon="log-out"
      intent="danger"
      className="navbar-button"
    >
      Log Out
    </Button>
  );
};
export default LogoutButton;
