import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";

import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class SysSx extends React.Component {
  state = {
    touched: false,
    text: null,
    editingWhen: false,
  };

  listOfDurations = [
    "Less than 6 months back",
    "6 months to 1 year back",
    "1 to 2 years back",
    "2 to 5 years back",
    "5 to 10 years back",
    "More than 10 years back",
  ];

  render() {
    return (
      <div>
        <ControlGroup>
          <Tag
            intent="primary"
            large
            minimal
            onRemove={() => {
              this.props.onRemove();
            }}
          >
            {this.props.data.surgery}
          </Tag>
          {this.state.editingWhen ? (
            <SingleSelectWrapper
              items={this.listOfDurations}
              selectedItem={this.props.data.when}
              onChange={(item) => {
                this.setState({ editingWhen: false });
                this.props.onUpdate("when", item);
              }}
            />
          ) : (
            <Tag
              minimal
              interactive
              onClick={() => this.setState({ editingWhen: true })}
            >
              {this.props.data.when}
            </Tag>
          )}
        </ControlGroup>
      </div>
    );
  }
}

export default SysSx;
