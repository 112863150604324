import { Button, Card, ControlGroup, Elevation, Tag } from "@blueprintjs/core";
import _ from "lodash";
import React from "react";
import ClinicalSectionRenderPreview from "./ClinicalSectionRenderPreview";
import { generateMasterVisitEntities } from "./masterVisitEntities";
import ClinicalVisitParametersPreview from "./ClinicalVisitParamsPreview";
import FilesPreview from "./FilesPreview";
import { inject, observer } from "mobx-react";

class ClinicalRxPanel extends React.Component {
  masterVisitEntities = generateMasterVisitEntities(
    this.props.authStore.prefix
  );
  renderHeader = () => {
    return (
      <Card elevation={Elevation.FOUR} className="card__container">
        <div className="flex__push__apart">
          <ControlGroup>
            <Tag large className="cerulean__bg" intent="success">
              <b>Rx Preview</b>
            </Tag>
            <Button
              icon="predictive-analysis"
              text="Talk To Harley"
              className="colfax"
              onClick={() => this.props.onHarleyDrawer()}
            />
          </ControlGroup>
        </div>
      </Card>
    );
  };
  renderSections = () => {
    let visitEntities = this.props.data.visitEntities;
    let sections = _.uniq(visitEntities.map((v) => v.sectionName));
    let master = this.masterVisitEntities.map((each) => each.sectionName);
    sections = _.sortBy(sections, (each) => {
      return _.indexOf(master, each);
    });
    return sections.map((each, index) => {
      return (
        <div style={{ marginBottom: "15px" }} key={index} className="colfax">
          <ClinicalSectionRenderPreview
            recentItems={this.props.recentItems}
            onChoice={this.props.onChoice}
            onRemove={(_id, laterality) =>
              this.props.removeVisitEntity(_id, laterality)
            }
            data={_.filter(visitEntities, (v) => v.sectionName === each)}
          />
        </div>
      );
    });
  };
  renderFiles = () => {
    if (this.props.data.files && this.props.data.files.length) {
      return <div>Files</div>;
    }
  };
  render() {
    return (
      <div className="colfax">
        <Card elevation={Elevation.FOUR} className="card__container">
          {this.renderHeader()}
          <ClinicalVisitParametersPreview
            data={this.props.data}
            removeVisitParameter={(id) => this.props.removeVisitParameter(id)}
            onChoice={(id) => this.props.onChoice(id)}
          />
          {this.renderSections()}
          <FilesPreview
            data={this.props.data.files}
            onChoice={(id) => this.props.onChoice(id)}
            onRemove={(_id) => this.props.removeVisitEntity(_id)}
          />
        </Card>
      </div>
    );
  }
}
// export default ClinicalRxPanel;
export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(ClinicalRxPanel));
