import {
  Button,
  Card,
  Collapse,
  ControlGroup,
  Elevation,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import MultiSelectWrapper from "../utils/MultiSelectWrapper";
import _ from "lodash";
import { nanoid } from "nanoid";
import cogoToast from "cogo-toast";
import ClinicalTextInput from "./ClinicalTextInput";
import { masterVisitParameters } from "./masterVisitParameters";
import { masterParameterBounds } from "./masterParameterBounds";
import { masterParameterOptions } from "./masterParameterOptions";
import { inject, observer } from "mobx-react";

const splitCombine = (xs, y) => {
  if (!xs) {
    return y;
  }
  if (xs.includes(y)) {
    return xs
      .split(", ")
      .filter((x) => x !== y)
      .join(", ");
  } else {
    return xs.split(", ").concat(y).join(", ");
  }
};

class ClinicalVisitParametersSection extends React.Component {
  state = {
    areDetailsOpen: true,
    whichGroupToBeAdded: "General",
    groupParameters: [],
    whichTypeToBeAdded: "",
    whichSubTypeToBeAdded: "",
    whichSubTypeToBeAddedMultiple: [],
    autoFocusAllowed: false,
    focus: [undefined, undefined, undefined],
    isUpdating: false,
  };

  componentDidMount() {
    this.setState({
      whichGroupToBeAdded: "General",
      groupParameters: _.filter(
        masterVisitParameters,
        (mvp) => mvp.workupGroup === "General"
      ).map((mvp) => mvp.nice),
    });
  }

  /** HEADER */
  renderHeader = () => {
    return (
      <Card
        elevation={Elevation.FOUR}
        style={{
          padding: "5px",
          fontSize: "small",
        }}
      >
        <div className="flex__push__apart">
          <ControlGroup>
            <Tag className="cerulean__bg" intent="success">
              <b>Vision & Work-Up</b>
            </Tag>
            {this.renderControlBar()}
            <Button
              small
              onClick={() =>
                this.setState({
                  areDetailsOpen: !this.state.areDetailsOpen,
                  autoFocusAllowed: this.state.areDetailsOpen,
                })
              }
              icon={
                this.state.areDetailsOpen
                  ? "double-chevron-up"
                  : "double-chevron-down"
              }
            />
          </ControlGroup>
        </div>
      </Card>
    );
  };
  renderControlBar = () => {
    if (!this.state.areDetailsOpen) {
      let summary = this.massageData();
      if (summary) {
        return <Tag minimal>{summary}</Tag>;
      } else {
        return <Tag minimal>Visual Acuity/IOP not added yet</Tag>;
      }
    }
    let master = masterVisitParameters;
    return (
      <ControlGroup>
        <SingleSelectWrapper
          disabled={this.state.isUpdating}
          placeholder="Workup Group"
          isColfax={true}
          showSelectProp={true}
          selectedItem={this.state.whichGroupToBeAdded}
          items={_.uniq(master.map((each) => each.workupGroup))}
          width="5em"
          onChange={(item) => {
            const groupParameters = _.filter(
              masterVisitParameters,
              (mvp) => mvp.workupGroup === item
            ).map((mvp) => mvp.nice);
            this.setState({
              groupParameters,
              whichGroupToBeAdded: item,
            });
          }}
        />

        <Tag minimal>Add Parameter</Tag>
        {this.state.isUpdating ? (
          <Spinner size={20} />
        ) : (
          <>
            <SingleSelectWrapper
              disabled={this.state.isUpdating}
              placeholder="Type of Parameter"
              isColfax={true}
              showSelectProp={true}
              selectedItem={this.state.whichTypeToBeAdded}
              items={this.state.groupParameters}
              onChange={(item) => {
                let subtypes = _.find(
                  master,
                  (x) => x.nice === item
                ).parameterSubtypes.map((each) => each.nice);
                if (subtypes.length === 1) {
                  this.setState({
                    whichTypeToBeAdded: item,
                    whichSubTypeToBeAdded: subtypes[0],
                    whichSubTypeToBeAddedMultiple: [subtypes[0]],
                  });
                } else if (!subtypes.length) {
                  this.setState({
                    whichTypeToBeAdded: item,
                  });
                } else {
                  this.setState({
                    whichTypeToBeAdded: item,
                    whichSubTypeToBeAdded: "",
                    whichSubTypeToBeAddedMultiple: [],
                  });
                }
              }}
            />
            {this.renderSubtypeChoice()}
          </>
        )}
      </ControlGroup>
    );
  };
  renderSubtypeChoice = () => {
    if (!this.state.whichTypeToBeAdded) {
      return null;
    }
    let master = masterVisitParameters;
    let subtypes = _.find(
      master,
      (x) => x.nice === this.state.whichTypeToBeAdded
    ).parameterSubtypes.map((each) => each.nice);
    if (subtypes.length === 1) {
      return (
        <ControlGroup>
          <Tag minimal>{subtypes[0]}</Tag>
          <Button
            small
            loading={this.state.isUpdating}
            className="colfax"
            icon="add"
            intent="success"
            outlined
            text={`Add ${this.state.whichTypeToBeAdded} (${this.state.whichSubTypeToBeAdded})`}
            onClick={() => this.addPatientParameter()}
          />
        </ControlGroup>
      );
    } else if (subtypes.length > 1) {
      let text = `Add ${this.state.whichTypeToBeAdded}`;
      if (this.state.whichSubTypeToBeAdded) {
        text += ` (${this.state.whichSubTypeToBeAdded})`;
      }
      return (
        <ControlGroup>
          {/* <SingleSelectWrapper
            disabled={this.state.isUpdating}
            placeholder="Subtype of Parameter"
            isColfax={true}
            showSelectProp={true}
            selectedItem={this.state.whichSubTypeToBeAdded}
            items={_.find(
              master,
              (x) => x.nice === this.state.whichTypeToBeAdded
            ).parameterSubtypes.map((each) => each.nice)}
            onChange={(item) => {
              this.setState({
                whichSubTypeToBeAdded: item,
              });
            }}
          /> */}
          <MultiSelectWrapper
            key={nanoid()}
            isColfax={true}
            showSelectProp={true}
            selectedItems={this.state.whichSubTypeToBeAddedMultiple}
            items={_.find(
              master,
              (x) => x.nice === this.state.whichTypeToBeAdded
            ).parameterSubtypes.map((each) => each.nice)}
            onChange={(items) => {
              this.setState({
                whichSubTypeToBeAddedMultiple: items,
              });
            }}
          />
          <Button
            small
            disabled={this.state.whichSubTypeToBeAddedMultiple.length == 0}
            loading={this.state.isUpdating}
            className="colfax"
            icon="add"
            intent="success"
            outlined
            text={(() => {
              if (this.state.whichSubTypeToBeAddedMultiple.length == 0) {
                return "Select Subtype(s)";
              }
              if (this.state.whichSubTypeToBeAddedMultiple.length == 1) {
                return `Add ${this.state.whichSubTypeToBeAddedMultiple[0]}`;
              }
              return "Add Multiple";
            })()}
            onClick={() => this.addPatientParameterMultiple()}
          />
          {/* <Button
            small
            disabled={!this.state.whichSubTypeToBeAdded}
            loading={this.state.isUpdating}
            className="colfax"
            icon="add"
            intent="success"
            outlined
            text={text}
            onClick={() => this.addPatientParameter()}
          /> */}
        </ControlGroup>
      );
    } else if (subtypes.length === 0) {
      return (
        <Button
          small
          loading={this.state.isUpdating}
          className="colfax"
          icon="add"
          intent="success"
          outlined
          text={`Add ${this.state.whichTypeToBeAdded}`}
          onClick={() => this.addPatientParameter()}
        />
      );
    }
  };
  massageData = () => {
    let current = this.props.data.visitParameters;
    if (!current.length) {
      return "";
    }
    let visionData = _.filter(current, (x) => x.parameterType === "VISION");
    let iopData = _.filter(
      current,
      (x) => x.parameterType === "INTRA_OCULAR_PRESSURE"
    );
    let visionString = "";
    let iopString = "";
    if (visionData.length) {
      visionData = visionData.sort((a, b) => {
        let master = masterVisitParameters;
        let masterSample = _.find(
          master,
          (x) => x.parameterType === a.parameterType
        );
        let subtypesInMaster = masterSample.parameterSubtypes;
        return (
          subtypesInMaster
            .map((item) => item.parameterType)
            .indexOf(a.parameterSubtype) -
          subtypesInMaster
            .map((item) => item.parameterType)
            .indexOf(b.parameterSubtype)
        );
      });
      let visionObj = _.last(visionData).data;

      let visionRE_DIST = _.find(
        visionObj,
        (x) => x.fieldType === "PINHOLE" && x.laterality === "RIGHT_EYE"
      ).value;

      if (!visionRE_DIST) {
        visionRE_DIST = _.find(
          visionObj,
          (x) => x.fieldType === "DISTANCE" && x.laterality === "RIGHT_EYE"
        ).value;
      }
      let visionRE_NEAR = _.find(
        visionObj,
        (x) => x.fieldType === "NEAR" && x.laterality === "RIGHT_EYE"
      ).value;

      let visionLE_DIST = _.find(
        visionObj,
        (x) => x.fieldType === "PINHOLE" && x.laterality === "LEFT_EYE"
      ).value;
      if (!visionLE_DIST) {
        visionLE_DIST = _.find(
          visionObj,
          (x) => x.fieldType === "DISTANCE" && x.laterality === "LEFT_EYE"
        ).value;
      }
      let visionLE_NEAR = _.find(
        visionObj,
        (x) => x.fieldType === "NEAR" && x.laterality === "LEFT_EYE"
      ).value;
      if (visionRE_DIST || visionRE_NEAR) {
        visionString += `[VA] RE: ${visionRE_DIST} ${visionRE_NEAR}`;
      }
      if (visionLE_DIST || visionLE_NEAR) {
        if (!visionString) {
          visionString += `[VA]`;
        }
        visionString += `  LE: ${visionLE_DIST} ${visionLE_NEAR}`;
      }
    }
    if (iopData.length) {
      iopData = iopData.sort((a, b) => {
        let master = masterVisitParameters;
        let masterSample = _.find(
          master,
          (x) => x.parameterType === a.parameterType
        );
        let subtypesInMaster = masterSample.parameterSubtypes;
        return (
          subtypesInMaster
            .map((item) => item.parameterType)
            .indexOf(a.parameterSubtype) -
          subtypesInMaster
            .map((item) => item.parameterType)
            .indexOf(b.parameterSubtype)
        );
      });
      let iopObj = _.last(iopData).data;
      let iopRE = _.find(
        iopObj,
        (x) => x.fieldType === "PRESSURE" && x.laterality === "RIGHT_EYE"
      ).value;
      if (iopRE) {
        iopString += `[IOP] RE: ${iopRE} mm Hg`;
      }
      let iopLE = _.find(
        iopObj,
        (x) => x.fieldType === "PRESSURE" && x.laterality === "LEFT_EYE"
      ).value;
      if (iopLE) {
        if (!iopString) {
          iopString += `[IOP]`;
        }
        iopString += `  LE: ${iopLE} mm Hg`;
      }
    }
    return `${visionString} ${iopString}`;
  };
  /** HEADER ENDS */

  addPatientParameter = () => {
    let type = this.state.whichTypeToBeAdded;
    let subtype = this.state.whichSubTypeToBeAdded || null;
    if (!type) return;
    let master = masterVisitParameters;
    let typeMaster = _.find(master, (x) => x.nice === type);
    let hasSubTypes = true;
    let subtypes = _.find(
      master,
      (x) => x.nice === this.state.whichTypeToBeAdded
    ).parameterSubtypes.map((each) => each.nice);
    if (!subtypes.length) {
      hasSubTypes = false;
    }
    let current = this.props.data.visitParameters;
    if (current.length) {
      let alreadyAdded;
      if (!hasSubTypes) {
        alreadyAdded = _.findIndex(current, (x) => x.nice === type);
      } else {
        alreadyAdded = _.findIndex(
          current,
          (x) => x.nice === type && x.subtypeNice === subtype
        );
      }
      if (alreadyAdded > -1) {
        cogoToast.error("You've already added this parameter.");
        this.setState({
          whichSubTypeToBeAdded: "",
          whichTypeToBeAdded: hasSubTypes ? "" : type,
        });
        return;
      }
    }
    let subtypeMaster;
    if (hasSubTypes) {
      subtypeMaster = _.find(
        typeMaster.parameterSubtypes,
        (x) => x.nice === subtype
      );
    }
    let data = [];

    if (typeMaster.hasLaterality) {
      let laterality = `RIGHT_${typeMaster.organ}`;
      let newDataRow = typeMaster.fields.map((each) => {
        return {
          ...each,
          value: each.inputType === "BOOLEAN" ? false : "",
          laterality: laterality,
        };
      });
      data = [...data, ...newDataRow];
      laterality = `LEFT_${typeMaster.organ}`;
      newDataRow = typeMaster.fields.map((each) => {
        return {
          ...each,
          value: each.inputType === "BOOLEAN" ? false : "",
          laterality: laterality,
        };
      });
      data = [...data, ...newDataRow];
    } else {
      let newDataRow = typeMaster.fields.map((each) => {
        return {
          ...each,
          value: each.inputType === "BOOLEAN" ? false : "",
          laterality: "",
        };
      });
      data = [...data, ...newDataRow];
    }
    let newPatientParameter = {
      parameterType: typeMaster.parameterType,
      nice: typeMaster.nice,
      short: typeMaster.short,
      hasLaterality: typeMaster.hasLaterality,
      organ: typeMaster.organ,
      parameterSubtype: hasSubTypes ? subtypeMaster.parameterSubtype : "",
      subtypeNice: hasSubTypes ? subtypeMaster.nice : "",
      subtypeShort: hasSubTypes ? subtypeMaster.short : "",
      data: data,
    };
    this.props.addVisitParameter(newPatientParameter);
    this.setState({
      whichSubTypeToBeAdded: "",
      whichTypeToBeAdded: "",
    });
  };

  addPatientParameterMultiple = async () => {
    let type = this.state.whichTypeToBeAdded;
    for (let subtype of this.state.whichSubTypeToBeAddedMultiple) {
      if (!type) return;
      let master = masterVisitParameters;
      let typeMaster = _.find(master, (x) => x.nice === type);
      let hasSubTypes = true;
      let subtypes = _.find(
        master,
        (x) => x.nice === this.state.whichTypeToBeAdded
      ).parameterSubtypes.map((each) => each.nice);
      if (!subtypes.length) {
        hasSubTypes = false;
      }
      let current = this.props.data.visitParameters;
      if (current.length) {
        let alreadyAdded;
        if (!hasSubTypes) {
          alreadyAdded = _.findIndex(current, (x) => x.nice === type);
        } else {
          alreadyAdded = _.findIndex(
            current,
            (x) => x.nice === type && x.subtypeNice === subtype
          );
        }
        if (alreadyAdded > -1) {
          cogoToast.error("You've already added this parameter.");
          this.setState({
            whichSubTypeToBeAdded: "",
            whichTypeToBeAdded: hasSubTypes ? "" : type,
          });
          return;
        }
      }
      let subtypeMaster;
      if (hasSubTypes) {
        subtypeMaster = _.find(
          typeMaster.parameterSubtypes,
          (x) => x.nice === subtype
        );
      }
      let data = [];

      if (typeMaster.hasLaterality) {
        let laterality = `RIGHT_${typeMaster.organ}`;
        let newDataRow = typeMaster.fields.map((each) => {
          return {
            ...each,
            value: each.inputType === "BOOLEAN" ? false : "",
            laterality: laterality,
          };
        });
        data = [...data, ...newDataRow];
        laterality = `LEFT_${typeMaster.organ}`;
        newDataRow = typeMaster.fields.map((each) => {
          return {
            ...each,
            value: each.inputType === "BOOLEAN" ? false : "",
            laterality: laterality,
          };
        });
        data = [...data, ...newDataRow];
      } else {
        let newDataRow = typeMaster.fields.map((each) => {
          return {
            ...each,
            value: each.inputType === "BOOLEAN" ? false : "",
            laterality: "",
          };
        });
        data = [...data, ...newDataRow];
      }
      let newPatientParameter = {
        parameterType: typeMaster.parameterType,
        nice: typeMaster.nice,
        short: typeMaster.short,
        hasLaterality: typeMaster.hasLaterality,
        organ: typeMaster.organ,
        parameterSubtype: hasSubTypes ? subtypeMaster.parameterSubtype : "",
        subtypeNice: hasSubTypes ? subtypeMaster.nice : "",
        subtypeShort: hasSubTypes ? subtypeMaster.short : "",
        data: data,
      };
      await this.props.addVisitParameter(newPatientParameter);
    }

    this.setState({
      whichSubTypeToBeAdded: "",
      whichTypeToBeAdded: "",
      whichSubTypeToBeAddedMultiple: [],
    });
  };

  renderPatientParametersAsAdded = () => {
    let patientParameters = this.props.data.visitParameters;
    if (!patientParameters) return null;
    if (!patientParameters.length) return null;
    // First we group the patient parameters by their TYPE_ --> parameterType. This is because we want to render them in separate tables.

    let typesOfParams = _.uniq(
      patientParameters.map((each) => each.parameterType)
    );
    return typesOfParams.map((eachType, tableIndex) => {
      // Now we render each table by TYPE_.
      // This now depends: if the TYPE_ has laterality, we render the table with laterality. If not, we render the table without laterality.
      // Also, we need to render the table with the SUBTYPEs if they exist.
      let typeSample = _.find(
        patientParameters,
        (x) => x.parameterType === eachType
      );
      let hasLaterality = typeSample.hasLaterality;
      let hasSubTypes = typeSample.parameterSubtype !== "";
      /**
       * subtype && laterality ==> 0
       * table with rows as subtypes, columns with laterality. (case 0)
       * example: vision. subtypes: PGP, etc. Laterality: Right, Left.
       * render table.
       *
       * subtype but no laterality ==> 1
       * example: ???
       *       *
       * no subtype but laterality ==>2
       * example: Pupillary Reaction. No subtypes. Laterality: Right, Left.
       * controlgroup with RE & LE.
       *
       *
       * no subtype and no laterality ==>3
       * example: Worth's Four Dot. No subtypes. No laterality.
       * controlgroup with input.
       *
       */
      let whichCase;
      if (hasSubTypes && hasLaterality) {
        whichCase = 0;
      } else if (hasSubTypes && !hasLaterality) {
        whichCase = 1;
      } else if (!hasSubTypes && hasLaterality) {
        whichCase = 2;
      } else if (!hasSubTypes && !hasLaterality) {
        whichCase = 3;
      }
      let whichSubTypes = _.filter(
        patientParameters,
        (x) => x.parameterType === eachType
      );
      if (whichCase === 0) {
        whichSubTypes.sort((a, b) => {
          let master = masterVisitParameters;
          let masterSample = _.find(
            master,
            (x) => x.parameterType === a.parameterType
          );
          let subtypesInMaster = masterSample.parameterSubtypes;
          return (
            subtypesInMaster
              .map((item) => item.parameterType)
              .indexOf(a.parameterSubtype) -
            subtypesInMaster
              .map((item) => item.parameterType)
              .indexOf(b.parameterSubtype)
          );
        });
        return (
          <table
            className="bp5-html-table bp5-compact"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            <thead>
              <tr>
                <th rowSpan={2} className="first_cell">
                  <Tag intent="success" large className="turquoise__bg">
                    {typeSample.nice}
                  </Tag>
                </th>
                {typeSample.data.map((each, index) => {
                  if (index === 0) {
                    return (
                      <th
                        colSpan={typeSample.data.length / 2}
                        className="vision_right_border first_cell"
                      >
                        <Tag intent="success" large className="green__bg">
                          {_.startCase(_.lowerCase(each.laterality))}
                        </Tag>
                      </th>
                    );
                  } else if (index === typeSample.data.length / 2) {
                    return (
                      <th
                        colSpan={typeSample.data.length / 2}
                        className="first_cell"
                      >
                        <Tag intent="success" large className="green__bg">
                          {_.startCase(_.lowerCase(each.laterality))}
                        </Tag>
                      </th>
                    );
                  } else {
                    return null;
                  }
                })}
              </tr>
              {typeSample.data.length > 2 && (
                <tr>
                  {typeSample.data.map((each, index) => {
                    return (
                      <th
                        key={nanoid()}
                        className={
                          index === typeSample.data.length / 2 - 1
                            ? "vision_right_border narrow_cell"
                            : "narrow_cell"
                        }
                      >
                        {each.nice}
                      </th>
                    );
                  })}
                </tr>
              )}
            </thead>
            <tbody>
              {whichSubTypes.map((each, rowIndex) => {
                return (
                  <tr key={nanoid()}>
                    <td className="narrow_cell first_cell">
                      <Tag
                        intent="success"
                        large
                        onRemove={() => {
                          this.props.removeVisitParameter(each._id);
                        }}
                        className="green__bg"
                      >
                        {each.subtypeNice}
                      </Tag>
                    </td>
                    {each.data.map((eachData, cellIndex) => {
                      const isDisabled = (
                        eachData.disabledForSubtypes || []
                      ).includes(each.parameterSubtype);
                      return (
                        <td
                          key={nanoid()}
                          className={
                            cellIndex === each.data.length / 2 - 1
                              ? "vision_right_border narrow_cell"
                              : "narrow_cell"
                          }
                        >
                          {this.renderCellValue(
                            eachData,
                            cellIndex,
                            each._id,
                            rowIndex,
                            tableIndex,
                            each.data.length,
                            whichSubTypes.length,
                            typesOfParams.length,
                            isDisabled
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      }
      if (whichCase === 1) {
        whichSubTypes.sort((a, b) => {
          let master = masterVisitParameters;
          let masterSample = _.find(
            master,
            (x) => x.parameterType === a.parameterType
          );
          let subtypesInMaster = masterSample.subtypes;
          return (
            subtypesInMaster
              .map((item) => item.parameterType)
              .indexOf(a.parameterSubtype) -
            subtypesInMaster
              .map((item) => item.parameterType)
              .indexOf(b.parameterSubtype)
          );
        });
        return (
          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <ControlGroup>
              <Tag intent="success" large className="turquoise__bg">
                {typeSample.nice}
              </Tag>
              <div>
                {whichSubTypes.map((each, rowIndex) => {
                  return (
                    <ControlGroup>
                      {" "}
                      <Tag
                        intent="success"
                        large
                        className="turquoise__bg"
                        onRemove={() => {
                          this.props.removeVisitParameter(each._id);
                        }}
                      >
                        {each.subtypeNice}
                      </Tag>
                      {each.data.map((eachData, cellIndex) => {
                        return (
                          <>
                            {this.renderCellValue(
                              eachData,
                              cellIndex,
                              each._id,
                              rowIndex,
                              tableIndex,
                              each.data.length,
                              whichSubTypes.length,
                              typesOfParams.length
                            )}
                          </>
                        );
                      })}
                    </ControlGroup>
                  );
                })}
              </div>
            </ControlGroup>
          </div>
        );
      }
      if (whichCase === 2) {
        return (
          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <ControlGroup>
              <Tag intent="success" large className="turquoise__bg">
                {typeSample.nice}
              </Tag>
              <div>
                {whichSubTypes.map((each, rowIndex) => {
                  return (
                    <ControlGroup>
                      <Button
                        minimal
                        icon="cross"
                        onClick={() => {
                          this.props.removeVisitParameter(each._id);
                        }}
                      />
                      {each.data.map((eachData, cellIndex) => {
                        return (
                          <ControlGroup>
                            <Tag intent="success" large className="green__bg">
                              {_.startCase(_.lowerCase(eachData.laterality))}
                            </Tag>
                            {this.renderCellValue(
                              eachData,
                              cellIndex,
                              each._id,
                              rowIndex,
                              tableIndex,
                              each.data.length,
                              whichSubTypes.length,
                              typesOfParams.length,
                              false,
                              this.props.authStore.prefix === "lotus"
                            )}
                          </ControlGroup>
                        );
                      })}
                    </ControlGroup>
                  );
                })}
              </div>
            </ControlGroup>
          </div>
        );
      }
      if (whichCase === 3) {
        return (
          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <ControlGroup>
              <Tag intent="success" large className="turquoise__bg">
                {typeSample.nice}
              </Tag>
              <div>
                {whichSubTypes.map((each, rowIndex) => {
                  return (
                    <ControlGroup>
                      <Button
                        minimal
                        icon="cross"
                        onClick={() => {
                          this.props.removeVisitParameter(each._id);
                        }}
                      />
                      {each.data.map((eachData, cellIndex) => {
                        return (
                          <ControlGroup>
                            {this.renderCellValue(
                              eachData,
                              cellIndex,
                              each._id,
                              rowIndex,
                              tableIndex,
                              each.data.length,
                              whichSubTypes.length,
                              typesOfParams.length
                            )}
                          </ControlGroup>
                        );
                      })}
                    </ControlGroup>
                  );
                })}
              </div>
            </ControlGroup>
          </div>
        );
      }
      return;
    });
  };
  renderCellValue = (
    eachData,
    cellIndex,
    id,
    rowIndex,
    tableIndex,
    numCells,
    numRows,
    numTables,
    isDisabled = false,
    showCopyButton = false
  ) => {
    if (
      isDisabled === true &&
      this.state.autoFocusAllowed &&
      this.state.focus[0] === tableIndex &&
      this.state.focus[1] === rowIndex &&
      this.state.focus[2] === cellIndex
    ) {
      if (cellIndex === numCells - 1) {
        if (rowIndex === numRows - 1) {
          if (tableIndex === numTables - 1) {
            tableIndex = undefined;
            rowIndex = undefined;
            cellIndex = undefined;
          } else {
            tableIndex = tableIndex + 1;
            rowIndex = 0;
            cellIndex = 0;
          }
        } else {
          cellIndex = 0;
          rowIndex = rowIndex + 1;
        }
      } else {
        cellIndex = cellIndex + 1;
      }

      this.setState({
        focus: [tableIndex, rowIndex, cellIndex],
      });
    }
    if (eachData.inputType === "OPTIONS") {
      let items = masterParameterOptions[eachData.options];
      if (
        eachData.fieldType === "GLASS_ADVISE" &&
        this.props.authStore.prefix === "disha"
      ) {
        items = ["Verify Existing Glasses", "Prescribed New Glasses"];
      }
      return (
        <SingleSelectWrapper
          disabled={isDisabled}
          autoFocus={
            this.state.autoFocusAllowed &&
            this.state.focus[0] === tableIndex &&
            this.state.focus[1] === rowIndex &&
            this.state.focus[2] === cellIndex
          }
          key={nanoid()}
          isColfax={true}
          showSelectProp={true}
          selectedItem={`${eachData.value}${
            eachData.value ? eachData.unit || "" : ""
          }`}
          placeholder={eachData.short}
          width={
            eachData.value && eachData.value.length > 2
              ? eachData.value.length * 1.08 > 15
                ? "15em"
                : eachData.value.length * 1.08 + "em"
              : "4em"
          }
          items={[...items, "Reset"]}
          onChange={(item) => {
            let param = _.find(
              this.props.data.visitParameters,
              (x) => x._id === id
            );
            param.data[cellIndex].value = item === "Reset" ? "" : item;
            this.props.updateVisitParameter(param);
            let autoFocusAllowed = true;
            if (cellIndex === param.data.length - 1) {
              if (rowIndex === numRows - 1) {
                if (tableIndex === numTables - 1) {
                  tableIndex = undefined;
                  rowIndex = undefined;
                  cellIndex = undefined;
                  autoFocusAllowed = false;
                } else {
                  tableIndex = tableIndex + 1;
                  rowIndex = 0;
                  cellIndex = 0;
                }
              } else {
                cellIndex = 0;
                rowIndex = rowIndex + 1;
              }
            } else {
              cellIndex = cellIndex + 1;
            }

            this.setState({
              focus: [tableIndex, rowIndex, cellIndex],
              autoFocusAllowed: autoFocusAllowed,
            });
          }}
        />
      );
    }
    if (eachData.inputType === "MULTISELECT_OPTIONS") {
      let items = masterParameterOptions[eachData.options];
      return (
        <MultiSelectWrapper
          autoFocus={
            this.state.autoFocusAllowed &&
            this.state.focus[0] === tableIndex &&
            this.state.focus[1] === rowIndex &&
            this.state.focus[2] === cellIndex
          }
          key={nanoid()}
          isColfax={true}
          showSelectProp={true}
          selectedItems={eachData.value.length ? eachData.value : []}
          placeholder={eachData.short}
          width={
            eachData.value && eachData.value.length > 2
              ? eachData.value.length * 1.08 > 15
                ? "15em"
                : eachData.value.length * 1.08 + "em"
              : "4em"
          }
          items={[...items, "Reset"]}
          onChange={(item) => {
            let param = _.find(
              this.props.data.visitParameters,
              (x) => x._id === id
            );
            param.data[cellIndex].value = item;
            /*        const oldData = param.data[cellIndex].value;
            param.data[cellIndex].value =
              item === "Reset" ? "" : splitCombine(oldData, item); */
            this.props.updateVisitParameter(param);
            let autoFocusAllowed = true;
            if (cellIndex === param.data.length - 1) {
              if (rowIndex === numRows - 1) {
                if (tableIndex === numTables - 1) {
                  tableIndex = undefined;
                  rowIndex = undefined;
                  cellIndex = undefined;
                  autoFocusAllowed = false;
                } else {
                  tableIndex = tableIndex + 1;
                  rowIndex = 0;
                  cellIndex = 0;
                }
              } else {
                cellIndex = 0;
                rowIndex = rowIndex + 1;
              }
            } else {
              cellIndex = cellIndex + 1;
            }
            this.setState({
              focus: [tableIndex, rowIndex, cellIndex],
              autoFocusAllowed: autoFocusAllowed,
            });
          }}
        />
      );
    }
    if (eachData.inputType === "TYPE") {
      let hasBound = false;
      if (eachData.bound) {
        hasBound = true;
      }
      let unitNormLb = null;
      let unitNormUb = null;

      if (hasBound) {
        let bound = masterParameterBounds[eachData.bound];
        unitNormLb = bound[0];
        unitNormUb = bound[1];
      }
      return (
        <ClinicalTextInput
          isLong={eachData.isLong}
          autoFocus={
            this.state.focus[0] === tableIndex &&
            this.state.focus[1] === rowIndex &&
            this.state.focus[2] === cellIndex
          }
          unit={eachData.unit || null}
          unitNormUb={unitNormUb || null}
          unitNormLb={unitNormLb || null}
          placeholder={eachData.short}
          value={eachData.value}
          onChange={(value) => {
            let param = _.find(
              this.props.data.visitParameters,
              (x) => x._id === id
            );
            param.data[cellIndex].value = value;
            this.props.updateVisitParameter(param);
            if (cellIndex === param.data.length - 1) {
              if (rowIndex === numRows - 1) {
                if (tableIndex === numTables - 1) {
                  tableIndex = undefined;
                  rowIndex = undefined;
                  cellIndex = undefined;
                } else {
                  tableIndex = tableIndex + 1;
                  rowIndex = 0;
                  cellIndex = 0;
                }
              } else {
                cellIndex = 0;
                rowIndex = rowIndex + 1;
              }
            } else {
              cellIndex = cellIndex + 1;
            }
            this.setState({ focus: [tableIndex, rowIndex, cellIndex] });
          }}
          showCopyButton={showCopyButton}
        />
      );
    }
    /**
     * eachData                     true        false
     * isPositiveGood     true      good        bad
     *                    false     bad         bad
     */
    if (eachData.inputType === "BOOLEAN") {
      return (
        <>
          <Tag
            large
            intent={
              eachData.value === eachData.isPositiveGood ? "success" : "danger"
            }
            icon={
              eachData.value === eachData.isPositiveGood ? null : "warning-sign"
            }
            onClick={() => {
              let param = _.find(
                this.props.data.visitParameters,
                (x) => x._id === id
              );
              param.data[cellIndex].value = !param.data[cellIndex].value;
              this.props.updateVisitParameter(param);
            }}
            interactive
          >
            {eachData.nice} {eachData.value ? "Present" : "Absent"}
          </Tag>
        </>
      );
    }
    return (
      <Tag large intent="success" className="vermillion__bg">
        ERROR::DATA_TYPE_INCORRECT
      </Tag>
    );
  };
  render() {
    return (
      <div>
        {this.renderHeader()}
        <Collapse isOpen={this.state.areDetailsOpen}>
          {this.renderPatientParametersAsAdded()}
        </Collapse>
      </div>
    );
  }
}
// export default ClinicalVisitParametersSection;
export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(ClinicalVisitParametersSection));
