import React from "react";
import MagicBar from "./MagicBar";
import { Button, Spinner, Tag } from "@blueprintjs/core";
import _ from "lodash";
import { inject, observer } from "mobx-react";

import Search from "../generic/Search";
import { apiCallPost } from "../fns/util";
import cogoToast from "cogo-toast";

class List extends React.Component {
  state = {
    new: true,
    splits: [],
    selectedSplitIndex: 0,
    isMagicBarOpen: false,
    takenNames: [],
    numResults: 0,
    lists: {},
    waitingButton: false,
  };
  componentDidMount() {
    this.getSplits();
    this.props.bookingStore.goBackTo = "/list";
  }

  getSplits = async () => {
    this.setState({ waiting: true });
    try {
      let res = await await apiCallPost(
        `/lists/${this.props.authStore.prefix}/get`,
        {}
      );
      this.setState({ waiting: false, splits: res }, () => {
        if (this.state.splits.length) {
          this.setState({ selectedSplitIndex: 0 }, () => {
            this.applySplit();
          });
        }
      });
    } catch (error) {
      cogoToast.error(
        "We ran into an error. Please check the console for more details.",
        { position: "bottom-left" }
      );

      this.setState({ waiting: false });
      console.log(error);
    }
  };

  renderSplitButtons = () => {
    return this.state.splits.map((each, index) => {
      return (
        <Button
          minimal
          text={each.splitName}
          active={this.state.selectedSplitIndex === index}
          onClick={() =>
            this.setState({ selectedSplitIndex: index }, () =>
              this.applySplit()
            )
          }
          onDoubleClick={() =>
            this.setState(
              {
                selectedSplitIndex: index,
                new: false,
              },
              () => this.setState({ isMagicBarOpen: true })
            )
          }
          style={{ minWidth: "fit-content" }}
        />
      );
    });
  };
  showThreads = () => {
    if (
      this.state.isMagicBarOpen ||
      this.state.waiting ||
      !this.state.splits.length ||
      this.state.selectedSplitIndex < 0
    ) {
      return null;
    } else {
      let _id = this.state.splits[this.state.selectedSplitIndex]._id;
      let list = this.state.lists[_id] || [];
      let listMod = list.map((each) => {
        return {
          ...each,
          docketNo: each.lineitems.map((x) => x.sysDocketNo).join("  "),
        };
      });
      return (
        <Search
          id={Math.floor(Math.random() * 1000)}
          list={listMod}
          numResults={this.state.numResults}
          onRefresh={() => this.applySplit("HARD")}
        />
      );
    }
  };
  toObject = (arg) => {
    let splitName = arg.splitName;
    let conditions = {};
    _.map(arg.conditions, (each) => {
      conditions[each.head] = each.value;
    });

    return {
      splitName: splitName,
      conditions: conditions,
      localId: this.state.splits.length,
      arrayForm: arg,
      _id: arg._id || "NO_ID",
    };
  };
  getSplitNames = () => {
    let names = [];
    _.map(this.state.splits, (each) => {
      names.push(each.splitName);
    });
    return names;
  };
  handleNewSplit = async (arg) => {
    cogoToast.loading("Trying to create new split...", {
      position: "bottom-left",
    });
    try {
      let res = await apiCallPost(
        `/lists/${this.props.authStore.prefix}/create`,
        this.toObject(arg)
      );
      this.setState(
        {
          splits: [...this.state.splits, res],
          selectedSplitIndex: this.state.splits.length,
          takenNames: [...this.state.takenNames, arg.splitName],
          isMagicBarOpen: false,
        },
        () => this.applySplit("HARD")
      );
      cogoToast.success("Split created.", { position: "bottom-left" });
    } catch (error) {
      cogoToast.error(
        "We ran into an error. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(error);
    }
  };
  updateSplit = async (arg) => {
    let current = this.state.splits;
    let idx = _.findIndex(current, (each) => {
      return each._id === arg._id;
    });
    cogoToast.loading("Trying to update split...", {
      position: "bottom-left",
    });
    try {
      let res = await apiCallPost(
        `/lists/${this.props.authStore.prefix}/update`,
        this.toObject(arg)
      );
      current[idx] = res;
      this.setState(
        {
          splits: current,
          selectedSplitIndex: idx,
          isMagicBarOpen: false,
        },
        () => this.applySplit("HARD")
      );
      cogoToast.success("Split updated.", { position: "bottom-left" });
    } catch (error) {
      cogoToast.error(
        "We ran into an error. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(error);
    }
  };
  deleteSplit = async (name, id) => {
    if (this.state.splits.length === 1) {
      cogoToast.error("You need at least one split.", {
        position: "bottom-left",
      });
      return;
    }
    let current = this.state.splits;
    let taken = this.state.takenNames;

    cogoToast.loading("Trying to delete split...", {
      position: "bottom-left",
    });
    try {
      await apiCallPost(`/lists/${this.props.authStore.prefix}/delete`, {
        _id: id,
      });
      _.remove(current, (each) => each._id === id);
      _.remove(taken, (each) => each === name);

      this.setState({
        splits: current,
        takenNames: taken,
        selectedSplitIndex: 0,
        isMagicBarOpen: false,
      });
      cogoToast.success("Split deleted.", { position: "bottom-left" });
    } catch (error) {
      cogoToast.error(
        "We ran into an error. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(error);
    }
  };

  applySplit = async (arg) => {
    let ints = performance.now();
    let _id = this.state.splits[this.state.selectedSplitIndex]._id;
    if (arg === "HARD" || !this.state.lists[_id]) {
      try {
        this.setState({ waitingButton: true });
        cogoToast.loading("Getting data...", { position: "bottom-left" });
        let res = await apiCallPost(
          `/lists/${this.props.authStore.prefix}/applyNew`,
          { _id: _id }
        );
        let outts = performance.now();
        let currentLists = this.state.lists;
        currentLists[_id] = res;
        this.setState({ lists: currentLists, waitingButton: false });
        cogoToast.success(
          `Loaded ${res.length} result${res.length >= 1 ? "s" : ""} in ${(
            (outts - ints) /
            1500
          ).toFixed(2)} seconds for fast search.`,
          { position: "bottom-right" }
        );
      } catch (error) {
        this.setState({ waitingButton: false });
        cogoToast.error(
          "We ran into an error. Please check the console for more details.",
          { position: "bottom-left" }
        );
      }
    }
  };
  render() {
    if (this.state.waiting) {
      return <Spinner />;
    }
    return (
      <div className="bp5-dark">
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            padding: "10px",
            margin: "5px",
            overflowX: "auto",
          }}
        >
          {this.renderSplitButtons()}
          <Button
            icon="git-new-branch"
            minimal
            onClick={() =>
              this.setState({ new: true }, () =>
                this.setState({
                  selectedSplitIndex: -1,
                  isMagicBarOpen: true,
                })
              )
            }
          />
          {this.state.waitingButton ? (
            <>
              <Button intent="primary" loading />
              <Tag minimal intent="primary">
                Loading Fresh Results...
              </Tag>
            </>
          ) : null}
        </div>
        <div> {this.showThreads()}</div>
        <MagicBar
          conditions={
            this.state.new
              ? null
              : this.state.splits[this.state.selectedSplitIndex].arrayForm
                  .conditions
          }
          splitName={
            this.state.new
              ? null
              : this.state.splits[this.state.selectedSplitIndex].arrayForm
                  .splitName
          }
          childKey={Math.round(Math.random() * 1000)}
          dbKey={
            this.state.new
              ? null
              : this.state.splits[this.state.selectedSplitIndex]._id
          }
          new={this.state.new}
          takenNames={this.state.takenNames}
          onSave={(arg) => this.handleNewSplit(arg)}
          onUpdate={(arg) => this.updateSplit(arg)}
          onDelete={(name, id) => this.deleteSplit(name, id)}
          isOpen={this.state.isMagicBarOpen}
          onClose={() => this.setState({ isMagicBarOpen: false })}
          closeMagicBar={() => this.setState({ isMagicBarOpen: false })}
        />
      </div>
    );
  }
}

export default inject("authStore", "bookingStore")(observer(List));
