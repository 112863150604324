import React from "react";
import { inject, observer } from "mobx-react";
import {
  Card,
  Elevation,
  H4,
  H6,
  Button,
  ControlGroup,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import _ from "lodash";
import { copyToClipboard, isInPastDateStrict } from "../fns/util";
import { toJS } from "mobx";
import cogoToast from "cogo-toast";
import { Redirect } from "react-router-dom";
import MatchPatient from "../singles/MatchPatient";

class BillingSummary extends React.Component {
  state = {
    redirectTo: null,
    isOpen: false,
    isMatchOpen: false,
    redirect: false,
    notes: null,
    isInvestigationDialogOpen: false,
  };

  saveNotes = async () => {
    if (!this.state.notes) {
      cogoToast.error("It seems like you are trying to save an empty note.", {
        position: "bottom-left",
      });
      return;
    }
    this.props.onNotes(this.state.notes);
  };

  renderEachPanel() {
    if (this.props.tpa_wise && this.props.tpa_wise.length) {
      return this.props.tpa_wise.map((each) => {
        return (
          <table class="bp5-html-table bp5-html-table-condensed">
            <tr>
              <td>
                <Tag intent="none">Panel</Tag>
              </td>
              <td>
                <Tag intent="warning">Discount</Tag>
              </td>
              <td>
                <Tag intent="primary">Approved</Tag>
              </td>
              <td>
                <Tag intent="warning">Used</Tag>
              </td>
              <td>
                <Tag>Available</Tag>
              </td>
              <td>
                <Tag intent="success">Reimbursed</Tag>
              </td>
            </tr>
            <tr>
              <td>{each.tpaName}</td>
              <td>INR {each.discount / 100} </td>
              <td>INR {each.approved / 100}</td>
              <td>INR {each.used / 100}</td>
              <td>INR {each.unused / 100}</td>
              <td>INR {each.reimbursed / 100}</td>
            </tr>
          </table>
        );
      });
    }
  }
  renderButtons = () => {
    let isPast = isInPastDateStrict(this.props.billingStore.billingDate);
    if (isPast) {
      return null;
    }
    let scope = toJS(this.props.authStore.scope);
    let isMediclaim = scope.includes("mediclaim");
    return (
      <>
        <ControlGroup vertical>
          <ControlGroup>
            <Button
              minimal
              icon="arrow-left"
              text="Go Back"
              intent="danger"
              onClick={() =>
                this.setState({
                  redirect: true,
                  redirectTo: this.props.bookingStore.goBackTo,
                })
              }
            />
          </ControlGroup>
          <ControlGroup>
            {" "}
            <Button
              outlined
              icon="credit-card"
              text="Payment"
              intent="success"
              disabled={this.props.summary.balance <= 0}
              onClick={() => this.props.toggle("PAYMENT")}
            />{" "}
            <Button
              outlined
              icon="percentage"
              text="Discount"
              intent="warning"
              disabled={this.props.summary.total <= 0}
              onClick={() => this.props.toggle("DISCOUNT")}
            />
            <Button
              outlined
              icon="double-chevron-down"
              text="Refund"
              intent="danger"
              disabled={this.props.summary.balance >= 0}
              onClick={() => this.props.toggle("REFUND")}
            />
          </ControlGroup>
          <ControlGroup>
            {!isMediclaim ? (
              <Button
                outlined
                icon="document"
                text="Cashless"
                intent="success"
                disabled
                onClick={() => this.props.toggle("CASHLESS")}
              />
            ) : null}
            {!isMediclaim ? (
              <Button
                outlined
                icon="document"
                text="Reimbursement"
                intent="success"
                disabled
                onClick={() => this.props.toggle("CASHLESS")}
              />
            ) : null}
          </ControlGroup>
          <ControlGroup>
            <Button
              outlined
              intent="primary"
              text="PDFs"
              icon="refresh"
              onClick={() => this.props.refreshPDFs()}
            />
          </ControlGroup>
        </ControlGroup>
        <br />
      </>
    );
  };
  renderMatch = () => {
    return (
      <MatchPatient
        isOpen={this.state.isMatchOpen}
        key={Math.floor(Math.random() * 1000)}
        onClose={() => this.setState({ isMatchOpen: false })}
      />
    );
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectTo} />;
    }

    return (
      <Card
        className="bp5-dark"
        style={{
          backgroundColor: "#464B53",
          textAlign: "left",
          margin: "5px",
          width: "100%",
        }}
        elevation={Elevation.THREE}
      >
        {this.renderMatch()}
        <div className="spaced-horizontal">
          <H4>
            {_.startCase(_.lowerCase(this.props.patientStore.patientName))} |{" "}
            {this.props.patientStore.patientAge || "NA"} Years
            <Button
              icon="edit"
              minimal
              onClick={() => {
                let s = this.props.patientStore.genericPid;
                let p = this.props.patientStore.patientPhone;
                let str = s;
                if (s.includes("via")) {
                  str = p;
                }
                this.setState({
                  redirect: true,
                  redirectTo: `/patientdetails/${str}`,
                });
              }}
            />
            <Button
              minimal
              disabled={
                !this.props.patientStore.genericPid.includes("via-newchart")
              }
              icon="inner-join"
              onClick={() =>
                this.setState({ isMatchOpen: !this.state.isMatchOpen })
              }
            />
          </H4>
          <H6>
            {this.props.patientStore.genericPid}{" "}
            <Button
              minimal
              icon="duplicate"
              onClick={() =>
                copyToClipboard(this.props.patientStore.genericPid)
              }
            />
            <Button
              minimal
              icon="camera"
              onClick={() => this.props.openPrescribedInvestigationsDrawer()}
            />
          </H6>
        </div>{" "}
        <Tag large minimal>
          Total:{" "}
        </Tag>
        <Tag large>INR {this.props.summary.total / 100} </Tag>
        <br />
        <br />
        <table class="bp5-html-table bp5-html-table-condensed">
          <tr>
            <td>
              <Tag intent="primary">Cash</Tag>
            </td>
            <td>
              <Tag intent="warning">Discount</Tag>
            </td>
            <td>
              <Tag intent="success">Paid</Tag>
            </td>{" "}
            <td>
              <Tag intent={this.state.balance > 0 ? "danger" : "warning"}>
                Balance
              </Tag>{" "}
            </td>
            <td>
              <Tag>Available</Tag>{" "}
            </td>
            <td>
              <Tag intent="danger">Refund Issued</Tag>
            </td>
          </tr>
          <tr>
            <td>INR {this.props.summary.total_cash / 100}</td>

            <td>INR {this.props.summary.less / 100}</td>

            <td>INR {this.props.summary.paid / 100}</td>

            <td>INR {this.props.summary.balance / 100}</td>
            <td>INR {this.props.summary.unapplied / 100}</td>

            <td>INR {this.props.summary.refund / 100}</td>
          </tr>
        </table>
        {this.renderEachPanel()}
        <table class="bp5-html-table bp5-html-table-condensed">
          <tr>
            <td>
              <Tag minimal intent="none">
                Add Note
              </Tag>
              <Button
                intent="success"
                outlined
                text="Save"
                disabled={!this.state.notes}
                onClick={() => this.saveNotes()}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextArea
                onChange={(event) =>
                  this.setState({ notes: event.target.value })
                }
              />
            </td>
          </tr>
        </table>
        <br />
      </Card>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "patientStore",
  "billingStore"
)(observer(BillingSummary));
