import { H3, Tag } from "@blueprintjs/core";
import React from "react";
import Symptom from "../elems/Symptom";
import SuggColl from "../SuggColl";
import SingleSearch from "./SingleSearch";

class SymPanel extends React.Component {
  renderTitle = () => {
    return <H3>What are the patient's symptoms?</H3>;
  };
  renderCG = () => {
    return (
      <SingleSearch
        prefix="sym"
        add={this.props.add}
        whichArray="symptoms"
        placeholder="Symptoms"
      />
    );
  };

  renderSymptoms = () => {
    if (!this.props.symptoms || !this.props.symptoms.length) {
      return (
        <Tag minimal large>
          No symptoms added.
        </Tag>
      );
    }
    return this.props.symptoms.map((each) => {
      return (
        <Symptom
          data={each}
          update={(symptomId, key, value) =>
            this.props.update("symptoms", symptomId, key, value)
          }
          remove={(symptomId) => this.props.remove(symptomId)}
        />
      );
    });
  };
  render() {
    return (
      <div style={{ padding: "20px", height: "100%" }}>
        {this.renderTitle()}
        <br />
        <SuggColl
          ana_prefix=""
          prefix="sym"
          visitId={this.props.visitId}
          addObjectInside={this.props.add}
        />
        {this.renderCG()}
        <br />
        {this.renderSymptoms()}
      </div>
    );
  }
}

export default SymPanel;
