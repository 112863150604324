import React from "react";
import { ControlGroup, Tag } from "@blueprintjs/core";
import MutMult from "../../utils/MutMult";

class GlassInst extends React.Component {
  state = {
    show: true,
  };
  renderCommands = () => {
    let listOfGlassTypes = [
      "Prescribed",
      "Same",
      "Single Vision",
      "Progressive",
      "Bifocal",
      "Trifocal",
      "Multifocal",
      "Executive",
      "D-Type",
      "Aspheric",
      "Anti-Reflective Coating",
      "Contact Lenses",
    ];
    return (
      <ControlGroup>
        <Tag
          large={this.state.show}
          minimal
          interactive
          onClick={() => this.setState({ show: !this.state.show })}
        >
          Glass Instruction
        </Tag>
        {this.state.show ? (
          <MutMult
            options={listOfGlassTypes}
            selected={this.props.vision.glassAdvice}
            onUpdate={(items) => this.props.updateVision("glassAdvice", items)}
          />
        ) : null}
      </ControlGroup>
    );
  };
  render() {
    return <div>{this.renderCommands()}</div>;
  }
}
export default GlassInst;
