import React from "react";
import _ from "lodash";
import {
  Button,
  ControlGroup,
  NonIdealState,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import cogoToast from "cogo-toast";
import { inject, observer } from "mobx-react";
import { apiCallPost } from "../../../fns/util";
import VFAVis from "./VFAVis";
import VFAMeasures from "./VFAMeasures";

class VFAContainer extends React.Component {
  state = { loading: false, data: {}, spectrum: false, showNum: false };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      this.setState({ loading: true });
      cogoToast.loading("Loading data...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/getInvestigation`,
        { _id: this.props.data._id }
      );
      cogoToast.success("Loaded data.", { position: "bottom-left" });
      this.setState({ loading: false, data: res });
    } catch (err) {
      cogoToast.error(
        "There was an error loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  removeHumanMeasure = async (id) => {
    try {
      cogoToast.loading("Removing measurement...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/removeAnnotation`,
        {
          _id: id,
        }
      );
      cogoToast.success("Removed data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd }, () => this.updateVFASummary());
    } catch (err) {
      cogoToast.error(
        "There was an error removing data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  editObjectInside = async (itemId, whichKey, value) => {
    try {
      cogoToast.loading("Editing finding...", { position: "bottom-left" });
      let item = _.find(
        this.state.data.annotations,
        (each) => each._id === itemId
      );
      item[whichKey === "qualifier" ? "valueQualifiers" : whichKey] = value;
      let payload = {
        ...item,
        againstId: null,
        annotationId: itemId,
        _id: this.props.data._id,
      };
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/editAnnotation`,
        payload
      );
      cogoToast.success("Edited data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd }, () => this.updateVFASummary());
    } catch (err) {
      cogoToast.error(
        "There was an error editing data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  addFinding = async (item) => {
    try {
      cogoToast.loading("Adding finding...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/addAnnotation`,
        {
          _id: this.props.data._id,
          kind: item.payload.kind,
          key: item.payload.key,
          value: "Present",
          unit: item.payload.unit,
          description: item.payload.description,
          valueQualifiers: item.helper,
          againstId: null,
          splText: "",
        }
      );
      cogoToast.success("Added data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd }, () => this.updateVFASummary());
    } catch (err) {
      cogoToast.error(
        "There was an error loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  updateVFASummary = () => {
    /** SINGLE EYE ONLY */
    let machineSummary = _.find(
      this.state.data.annotations,
      (each) => each.key === "VFA_summary"
    );

    let eyeString = _.concat(
      _.filter(
        this.state.data.annotations,
        (each) => each.kind === "VFA_HUMAN_FINDING"
      ).map(
        (each) =>
          `${each.description} ${
            each.valueQualifiers ? "(" + each.valueQualifiers + ")" : ""
          }`
      ),
      machineSummary.value
    ).join(", ");
    console.log(eyeString);
    this.props.update(
      "INVESTIGATION",
      this.props.data._id,
      "summary",
      eyeString
    );
    return;
    /*  if (machineSummary.length === 1) {
      let eyeString = _.concat(
        _.filter(
          this.state.data.annotations,
          (each) => each.kind === "VFA_HUMAN_FINDING"
        ).map((each) => `${each.description} ${each.valueQualifiers}`),
        machineSummary.value
      ).join(", ");
      this.props.update(
        "INVESTIGATION",
        this.props.data._id,
        "summary",
        eyeString
      );
      return;
    } else {
      let re = _.filter(
        this.state.data.annotations,
        (each) => each.eye === "OD"
      );
      let eyeStringRe = _.concat(
        _.filter(re, (each) => each.kind === "OCT_HUMAN_FINDING").map(
          (each) => `${each.description} ${each.valueQualifiers || ""}`
        ),
        _.find(machineSummary, (each) => each.eye === "OD").value
      ).join(", ");

      let le = _.filter(
        this.state.data.annotations,
        (each) => each.eye === "OS"
      );
      let eyeStringLe = _.concat(
        _.filter(le, (each) => each.kind === "OCT_HUMAN_FINDING").map(
          (each) => `${each.description} ${each.valueQualifiers || ""}`
        ),
        _.find(machineSummary, (each) => each.eye === "OS").value
      ).join(", ");

      this.props.update(
        "INVESTIGATION",
        this.props.data._id,
        "summary",
        `OD: ${eyeStringRe} | OS: ${eyeStringLe}`
      );
    } */
  };
  renderMeasures = () => {
    let dd = this.state.data.annotations;
    let humanFindings = _.filter(
      this.state.data.annotations,
      (each) => each.kind === "VFA_HUMAN_FINDING"
    );
    return (
      <VFAMeasures
        data={dd}
        invType={this.props.data.type}
        humanFindings={humanFindings}
        addFinding={(item) => this.addFinding(item)}
        editObjectInside={(itemId, whichKey, value) =>
          this.editObjectInside(itemId, whichKey, value)
        }
        removeHumanMeasure={this.removeHumanMeasure}
      />
    );
  };
  renderControlBar = () => {
    return (
      <ControlGroup style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Tag intent="primary" large>
          Field Visualisations
        </Tag>
        <Button
          minimal
          icon={this.state.showNum ? "array-numeric" : "widget"}
          onClick={() => this.setState({ showNum: !this.state.showNum })}
        />
        <Button
          minimal
          icon={this.state.spectrum ? "tint" : "contrast"}
          onClick={() => this.setState({ spectrum: !this.state.spectrum })}
        />
      </ControlGroup>
    );
  };
  renderRaw = () => {
    if (this.state.data.processed && this.state.data.processed.length) {
      let data = _.find(
        this.state.data.processed,
        (each) => each.kind === "VFA_HFA3_PATTERN_MATRIX"
      );
      let fovea = _.find(
        this.state.data.annotations,
        (each) => each.key === "fovealResponse"
      )["value"];
      return (
        <VFAVis
          data={data.data}
          title="Raw Signals"
          rowOffset={data.Y_axis - 1}
          columnOffset={data.X_axis - 1}
          fovea={parseInt(fovea)}
          showNum={this.state.showNum}
          spectrum={this.state.spectrum}
        />
      );
    }
  };
  renderTotal = () => {
    if (this.state.data.processed && this.state.data.processed.length) {
      let data = _.find(
        this.state.data.processed,
        (each) => each.kind === "VFA_HFA3_TOTAL_DEVIATION_MATRIX"
      );
      let flattened = _.flattenDeep(data.data).filter(Boolean);
      return (
        <VFAVis
          data={data.data}
          title="Age-Normalised Total Deviation"
          rowOffset={data.Y_axis - 1}
          columnOffset={data.X_axis - 1}
          max={parseInt(_.max(flattened))}
          showNum={this.state.showNum}
          spectrum={this.state.spectrum}
          range={true}
          min={parseInt(_.min(flattened))}
        />
      );
    }
  };
  renderPattern = () => {
    if (this.state.data.processed && this.state.data.processed.length) {
      let data = _.find(
        this.state.data.processed,
        (each) => each.kind === "VFA_HFA3_PATTERN_DEVIATION_MATRIX"
      );

      let flattened = _.flattenDeep(data.data).filter(Boolean);
      return (
        <VFAVis
          data={data.data}
          title="Pattern Deviation"
          rowOffset={data.Y_axis - 1}
          columnOffset={data.X_axis - 1}
          showNum={this.state.showNum}
          spectrum={this.state.spectrum}
          range={true}
          max={parseInt(_.max(flattened))}
          min={parseInt(_.min(flattened))}
        />
      );
    }
  };

  render() {
    if (this.state.loading) {
      return <NonIdealState icon={<Spinner />} title="Loading Data" />;
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {this.renderMeasures()}
        {this.renderControlBar()}
        <div style={{ display: "flex", flexWrap: "wrap", overflow: "scroll" }}>
          {this.renderRaw()}
          {this.renderTotal()}
          {this.renderPattern()}
        </div>
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(VFAContainer));
