import React from "react";
import {
  Collapse,
  ControlGroup,
  Tag,
  EditableText,
  Card,
  Elevation,
} from "@blueprintjs/core";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import FUItem from "./FUItem";

class PlanFollowUp extends React.Component {
  state = {
    collapse: true,
    notesText: null,
    notesTouched: false,
    editingRef: false,
  };
  followUpDurations = [
    "Based On Advised Procedures",
    "As and When Required/SOS",
    "In 1 Day",
    "In 2 Days",
    "In 3 Days",
    "In 5 Days",
    "In 1 Week",
    "In 10 Days",
    "In 2 Weeks",
    "In 3 Weeks",
    "In 4 Weeks",
    "In 1 Month",
    "In 6 Weeks",
    "In 2 Months",
    "In 3 Months",
    "In 4 Months",
    "In 5 Months",
    "In 6 Months",
    "In 1 Year",
  ];
  refOptions = [
    "Cardiologist",
    "Neurologist",
    "Orthopedician",
    "Rheumatologist",
    "Dermatologist",
    "Diabetologist",
    "Physician",
    "Pediatrician",
    "ENT Specialist",
    "Otolaryngologist",
    "Ophthalmologist",
    "Glaucoma Specialist",
    "Cataract Surgeon",
    "Refractive Surgeon",
    "Cornea Specialist",
    "Retina Specialist",
    "Oculoplasty Surgeon",
    "Pediatric Ophthalmologist",
    "Neuro-Ophthalmologist",
    "Uveitis Specialist",
  ];

  renderText = () => {
    let rf = this.props.data[0].refer ? this.props.data[0].referTo + " " : "";
    let txt = rf + this.props.data[0].when;
    let fut = [];
    if (this.props.fuItems && this.props.fuItems.length) {
      fut = this.props.fuItems.map((each) => each.type);
    }
    let invT = "";
    if (fut.length) {
      invT = fut.join(", ");
      invT = " with " + invT;
    }
    return txt + invT;
  };
  renderHeading = () => {
    return (
      <ControlGroup>
        <Tag
          large
          intent="primary"
          interactive
          onClick={() => this.setState({ collapse: !this.state.collapse })}
        >
          Next Steps
        </Tag>
        {this.state.collapse ? (
          <SingleSelectWrapper
            placeholder="Follow Up Duration"
            items={this.followUpDurations}
            selectedItem={this.props.data[0].when}
            onChange={(item) => {
              this.setState({ collapse: false });
              this.props.update(
                "followup",
                this.props.data[0]._id,
                "when",
                item
              );
            }}
          />
        ) : (
          <Tag minimal large>
            {this.renderText()}
          </Tag>
        )}
        <Tag
          minimal
          large
          interactive
          onClick={() => this.props.onSearch("fu", "followupWith")}
        >
          Add Follow Up investigations
        </Tag>
      </ControlGroup>
    );
  };

  renderFUItems = () => {
    if (!this.props.fuItems || !this.props.fuItems.length) {
      return (
        <ControlGroup>
          <Tag
            minimal
            large
            interactive
            onClick={() => this.props.onSearch("fu", "followupWith")}
          >
            No Follow Up Items Added.
          </Tag>
        </ControlGroup>
      );
    }

    return this.props.fuItems.map((each) => {
      return (
        <FUItem
          data={each}
          update={(fuId, key, value) =>
            this.props.update("followupWith", fuId, key, value)
          }
          remove={(fuId) => this.props.remove(fuId)}
        />
      );
    });
  };

  renderNextSteps = () => {
    let data = this.props.data[0];
    return (
      <ControlGroup style={{ marginTop: "5px" }}>
        {data.refer ? (
          <Tag
            minimal
            large
            interactive
            onClick={() =>
              this.props.update("followup", data._id, "refer", false)
            }
          >
            Refer To
          </Tag>
        ) : (
          <Tag
            minimal
            large
            interactive
            onClick={() =>
              this.props.update("followup", data._id, "refer", true)
            }
          >
            Follow Up With Me
          </Tag>
        )}
        {data.refer ? (
          <>
            {this.state.editingRef ? (
              <SingleSelectWrapper
                items={this.props.docList}
                onChange={(item) => {
                  this.setState({ editingRef: false });
                  this.props.update(
                    "followup",
                    this.props.data[0]._id,
                    "referTo",
                    item
                  );
                }}
              />
            ) : (
              <Tag
                minimal
                large
                interactive
                intent="primary"
                onClick={() => this.setState({ editingRef: true })}
              >
                {data.referTo}
              </Tag>
            )}
          </>
        ) : null}
      </ControlGroup>
    );
  };
  renderNotes = () => {
    if (!this.props.data[0].refer) {
      return null;
    }
    return (
      <div
        style={{ textAlign: "left", marginTop: "10px", marginBottom: "10px" }}
      >
        <EditableText
          multiline
          placeholder="You can add a referral note here."
          value={
            this.state.notesTouched
              ? this.state.notesText
              : this.props.data[0].referralNote
          }
          onChange={(event) =>
            this.setState({ notesText: event, notesTouched: true })
          }
          onConfirm={() =>
            this.props.update(
              "followup",
              this.props.data[0]._id,
              "referralNote",
              this.state.notesTouched
                ? this.state.notesText
                : this.props.data[0].referralNote
            )
          }
        />
      </div>
    );
  };

  render() {
    return (
      <Card
        style={{ padding: "0px", background: "none" }}
        elevation={Elevation.FOUR}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "start",
            justifyContent: "left",
            padding: "10px",
          }}
        >
          {this.renderHeading()}
          <Collapse isOpen={this.state.collapse}>
            {this.renderNextSteps()}
            {this.renderNotes()}
            {this.renderFUItems()}
          </Collapse>
        </div>
      </Card>
    );
  }
}

export default PlanFollowUp;
