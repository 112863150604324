import React from "react";
import {
  Collapse,
  ControlGroup,
  Tag,
  EditableText,
  Card,
  Elevation,
  Button,
} from "@blueprintjs/core";
import AsyncSelWrap from "../../utils/AsyncSelWrap";
import Advice from "./Advice";
import { inject, observer } from "mobx-react";
import { apiCallPost } from "../../fns/util";
import cogoToast from "cogo-toast";
import SuggColl from "../SuggColl";

class PlanAdvice extends React.Component {
  state = {
    collapse: true,
    notesText: null,
    notesTouched: false,
    searching: false,
    searchResults: [],
  };
  renderNotes = () => {
    return (
      <div
        style={{ textAlign: "left", marginTop: "10px", marginBottom: "10px" }}
      >
        <EditableText
          multiline
          placeholder="Any advice you enter here will be added to the prescription."
          value={
            this.state.notesTouched
              ? this.state.notesText
              : this.props.advNotes[0].text
          }
          onChange={(event) =>
            this.setState({ notesText: event, notesTouched: true })
          }
          onConfirm={() =>
            this.props.update(
              "advNotes",
              this.props.advNotes[0]._id,
              "text",
              this.state.notesTouched
                ? this.state.notesText
                : this.props.advNotes[0].text
            )
          }
        />
      </div>
    );
  };
  renderShort = () => {
    if (!this.props.data || !this.props.data.length) {
      return (
        <Tag minimal large>
          No advice added.
        </Tag>
      );
    }
    let adv = this.props.data.map((each) => each.text);
    let txt = adv.join(", ");
    return (
      <Tag minimal large multiline>
        {txt}
      </Tag>
    );
  };
  renderHeading = () => {
    return (
      <ControlGroup>
        <Tag
          large
          intent="primary"
          interactive
          onClick={() => this.setState({ collapse: !this.state.collapse })}
        >
          Advice
        </Tag>
        {!this.state.collapse ? (
          this.renderShort()
        ) : (
          <>
            {" "}
            <AsyncSelWrap
              placeholder="Advice..."
              searching={this.state.searching}
              items={this.state.searchResults}
              selectedItem={null}
              update={(item) => this.search(item)}
              onChange={(item) => {
                let dd = item.data;
                dd["_id_sr"] = item._id;
                dd["type"] = "new";
                dd["checked"] = false;
                this.props.add("advice", dd);
              }}
            />{" "}
            <Button
              minimal
              icon="trash"
              text="Remove Previous"
              intent="danger"
              onClick={() => this.props.removeAllPrevious()}
            />
          </>
        )}
      </ControlGroup>
    );
  };
  renderAdvice = () => {
    if (!this.props.data || !this.props.data.length) {
      return (
        <ControlGroup style={{ marginTop: "10px" }}>
          <Tag minimal large>
            No advice added.
          </Tag>
        </ControlGroup>
      );
    }
    return this.props.data.map((each) => {
      return (
        <Advice
          data={each}
          update={(adviceId, key, value) =>
            this.props.update("advice", adviceId, key, value)
          }
          remove={(adviceId) => this.props.remove(adviceId)}
        />
      );
    });
  };
  search = async (string) => {
    if (!string) {
      this.setState({ searchResults: [] });
      return;
    }
    try {
      this.setState({ searching: true });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/unisearch`,
        { prefix: "adv", query: string }
      );
      this.setState({ searchResults: res, searching: false });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  render() {
    return (
      <Card
        style={{ padding: "0px", background: "none" }}
        elevation={Elevation.FOUR}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "start",
            justifyContent: "left",
            padding: "10px",
          }}
        >
          {this.renderHeading()}
          <Collapse isOpen={this.state.collapse}>
            <SuggColl
              ana_prefix=""
              prefix="adv"
              visitId={this.props.visitId}
              addObjectInside={this.props.addObjectInside}
            />
            {this.renderAdvice()}
            {this.renderNotes()}
          </Collapse>
        </div>
      </Card>
    );
  }
}
export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(PlanAdvice));
