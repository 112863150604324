import { InputGroup, Tag, Button } from "@blueprintjs/core";
import React from "react";
import { copyToClipboard } from "../fns/util";
class ClinicalTextInput extends React.Component {
  state = {
    touched: false,
    value: "",
  };
  componentDidMount() {
    this.setState({ value: this.props.value });
  }
  checkIfWithinBounds = () => {
    let withinBounds = true;
    if (this.props.unitNormUb) {
      if (parseFloat(this.state.value) >= parseFloat(this.props.unitNormUb)) {
        withinBounds = false;
      }
    }
    if (this.props.unitNormLb) {
      if (parseFloat(this.state.value) <= parseFloat(this.props.unitNormLb)) {
        withinBounds = false;
      }
    }
    return withinBounds;
  };
  render() {
    let isWithinBounds = this.checkIfWithinBounds();
    return (
      <InputGroup
        autoFocus={this.props.autoFocus}
        intent={isWithinBounds ? "none" : "danger"}
        inputClassName="colfax"
        rightElement={
          this.props.unit ? (
            <Tag
              intent={isWithinBounds ? "none" : "danger"}
              minimal={isWithinBounds}
            >
              {this.props.unit}
            </Tag>
          ) : null
        }
        leftElement={
          <div>
            <Tag
              intent={isWithinBounds ? "none" : "danger"}
              minimal={isWithinBounds}
              icon={isWithinBounds ? null : "warning-sign"}
            >
              {this.props.placeholder}
            </Tag>
            {this.props.showCopyButton ? (
              <Button
                minimal
                icon="duplicate"
                onClick={() => copyToClipboard(this.state.value)}
              />
            ) : null}
          </div>
        }
        className={
          this.props.isLong ? "colfax big_input" : "colfax small_input"
        }
        placeholder={this.props.placeholder || "Enter..."}
        value={this.state.value}
        onBlur={() => {
          this.props.onChange(this.state.value);
        }}
        onChange={(e) => {
          this.setState({ value: e.target.value, touched: true });
        }}
      />
    );
  }
}

export default ClinicalTextInput;
