import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "mobx-react";
import RootStore from "./store";
import { Auth0Provider } from "@auth0/auth0-react";

//...
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://d8b59b7ad40307bd37b6749c9a827e22@o4505199151874048.ingest.us.sentry.io/4507414243901440",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // maskAllText: false,
      // blockAllMedia: false,
    }),
  ],

  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://api.newchart.care/",
    "https://wc-api-service.1m4ef7p6cs5hq.ap-south-1.cs.amazonlightsail.com",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="newchart.us.auth0.com"
      clientId="dYhTLJpTgcUDTUoXIK4ypOXBeVKKs3PF"
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
      audience="https://newchart.us.auth0.com/api/v2/"
      scope="read:current_user openid profile email"
    >
      <Provider
        bookingStore={RootStore.bookingStore}
        authStore={RootStore.authStore}
        listStore={RootStore.listStore}
        root={RootStore}
        billingStore={RootStore.billingStore}
        patientStore={RootStore.patientStore}
      >
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
