import {
  Button,
  Card,
  Collapse,
  ControlGroup,
  Elevation,
  InputGroup,
  Spinner,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import React from "react";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import { masterParameterOptions } from "./masterParameterOptions";
import _ from "lodash";
import {
  apiCallPost,
  apiCallPostAI,
  apiCallPostStreamingForAI,
  replaceNullAndUndefined,
} from "../fns/util";
import cogoToast from "cogo-toast";
import { masterSystemPrompts } from "./masterSystemPrompts";
import { inject, observer } from "mobx-react";
import CanonicalSearcher from "./CanonicalSearcher";

class ClinicalEntity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShortForm: props.isShortForm == null ? true : props.isShortForm,
      isLoading: true,
      polishOngoing: false,
      alchemyToken: null,
      gptConversationId: "",
      AIActionRunning: false,
      AIActionDone: false,
      parserInput: "",
      searchResults: [],
      entityName: "",
    };
  }

  checkIfChanged = (item) => {
    let currentItem = this.props.entity;
    let keys = _.keys(currentItem);
    let flag = false;
    for (var i = 0; i < keys.length; i++) {
      if (currentItem[keys[i]] !== item[keys[i]]) {
        flag = true;
      }
    }
    if (!flag) {
      this.setState({
        isSaving: true,
      });
      this.props.updateVisitEntity(item);
    }
  };
  componentDidMount() {
    let fields = this.props.subsection.fields;
    for (var i = 0; i < fields.length; i++) {
      this.setState({
        [fields[i].keyName]: this.props.entity[fields[i].keyName],
      });
    }
    this.setState({ isLoading: false, notes: this.props.entity.notes || "" });
  }

  /** PARSER STUFF */
  timeUnitConversion = {
    c: "to continue till next consultation",
    r: "till the end of the container/pack",
    h: "hour",
    d: "day",
    w: "week",
    m: "month",
    y: "year",
  };
  specialItemConversion = {
    sos: "only when needed as SOS",
    hs: "at bedtime",
    ac: "before meals",
    am: "in the morning",
    pm: "at night",
    ampm: "in the morning and at night",
    pc: "after meals",
    food: "with food",
    rice: "in rice grain amounts",
    tiny: "in rice grain amounts",
    rest: "till the rest of the phial",
    sx: "prior to surgery",
    st: "immediately",
    od: "once daily",
    bid: "twice a day",
    tid: "three times a day",
    qid: "four times a day",
    qod: "every other day",
    qh: "every hour",
    q2h: "every 2 hours",
    q3h: "every 3 hours",
    q4h: "every 4 hours",
    prn: "as needed",
    qd: "once a day",
    hs: "at bedtime",
    po: "by mouth",
    np: "no preference",
    bbf: "before breakfast",
    abc: "before meals",
    "ac&hs": "before meals and at bedtime",
    pc: "after meals",
    "alt.h": "every other hour",
    qwk: "weekly",
    biw: "twice a week",
    stat: "immediately",
    asap: "as soon as possible",
    npf: "nil per mouth, fasting",
    npo: "nothing by mouth",
    pv: "per vaginum",
    pr: "per rectum",
    iv: "intravenous",
    im: "intramuscular",
    sc: "subcutaneous",
    top: "topically",
    gtt: "drops",
    ung: "ointment",
    caps: "capsules",
    tabs: "tablets",
    supp: "suppository",
    inh: "inhalation",
    neb: "nebulizer",
    adlib: "as desired",
    wa: "while awake",
    j: "joint",
    et: "and",
    "ut dict": "as directed",
    a: "before",
    p: "after",
    c: "with",
    s: "without",
    aa: "of each",
    "a.c.": "before meals",
    ad: "up to",
    "ad lib": "as much as needed",
    aq: "water",
    "b.i.d": "twice a day",
    c: "with",
    cap: "capsule",
    d: "day",
    disp: "dispense",
    dtd: "give of such doses",
    et: "and",
    fl: "fluid",
    gtts: "drops",
    gr: "grain",
    hs: "at bedtime",
    ID: "intradermal",
    IP: "intraperitoneal",
    IT: "intrathecal",
    IVP: "intravenous push",
    IVPB: "intravenous piggyback",
    liq: "liquid",
    mEq: "milliequivalent",
    mg: "milligram",
    ml: "milliliter",
    noct: "night",
    "non rep": "do not repeat",
    "p.c.": "after meals",
    "per os": "by mouth",
    pl: "pill",
    "p.o.": "by mouth",
    "p.r.": "by rectum",
    "p.v.": "by vagina",
    "q.a.m.": "every morning",
    "q.d.": "every day",
    "q.h.": "every hour",
    "q.i.d.": "four times a day",
    "q.p.": "as much as you please",
    "q.w.": "every week",
    q2h: "every 2 hours",
    q3h: "every 3 hours",
    q4h: "every 4 hours",
    q6h: "every 6 hours",
    q8h: "every 8 hours",
    q12h: "every 12 hours",
    "q.s.": "as much as needed",
    r: "rectal",
    rep: "repeats",
    s: "without",
    sig: "write",
    sl: "sublingually, under the tongue",
    "s.o.s.": "if there is a need",
    "t.i.d.": "three times a day",
    "ut dict": "as directed",
    vag: "vaginally",
    x: "for",
    " ": "at",
  };
  eyeConversion = {
    BE: " in both eyes",
    OU: " in both eyes",
    b: " in both eyes",
    be: " in both eyes",
    ou: " in both eyes",
    LE: " in left eye only",
    OS: " in left eye only",
    l: " in left eye only",
    le: " in left eye only",
    os: " in left eye only",
    RE: " in right eye only",
    OD: " in right eye only",
    r: " in right eye only",
    re: " in right eye only",
    od: " in right eye only",
  };
  frequencyAbbreviations = {
    od: "once daily",
    bid: "twice a day",
    bd: "twice a day",
    tid: "three times a day",
    td: "three times a day",
    qid: "four times a day",
    qd: "four times a day",
    qod: "every other day",
    qh: "every hour",
    q2h: "every 2 hours",
    q3h: "every 3 hours",
    q4h: "every 4 hours",
    prn: "as needed",
    qd: "once a day",
    "alt.h": "every other hour",
    qwk: "weekly",
    biw: "twice a week",
  };
  parseFrequency = (arg) => {
    if (this.frequencyAbbreviations[arg]) {
      return this.frequencyAbbreviations[arg];
    }

    const numOfTimes = arg.split("/")[0];
    const period = arg.split("/")[1];

    let numOfTimesString = "";

    if (numOfTimes === "1") {
      numOfTimesString = "once ";
    } else if (numOfTimes === "2") {
      numOfTimesString = "twice ";
    } else if (numOfTimes === "3") {
      numOfTimesString = "thrice ";
    } else {
      numOfTimesString = numOfTimes + " times ";
    }

    let periodString = period ? this.parseDuration(period, "every ") : "daily ";

    return numOfTimesString + periodString;
  };
  capitaliseFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  parseDuration(arg, helper) {
    if (!arg) {
      return helper + "1 day";
    }

    const lastChar = arg.slice(-1);
    const unit = arg.substring(0, arg.length - 1);
    let durationString = "";

    if (this.timeUnitConversion[lastChar]) {
      durationString = helper + unit + " " + this.timeUnitConversion[lastChar];
      if (unit !== "1" && lastChar !== "c" && lastChar !== "r") {
        durationString += "s"; // Add 's' for plurals
      }
    } else {
      // If no unit is specified, default to "days"
      const dayCount = arg ? arg : "1";
      durationString = helper + dayCount + " day";
      if (dayCount !== "1") {
        durationString += "s"; // Add 's' for plurals
      }
    }

    return durationString;
  }
  parseSpecialItem = (arg) => this.specialItemConversion[arg];
  parseEye = (arg) => this.eyeConversion[arg] || "";
  constructStaggerString = (start, end, dur) => {
    let str = "";
    for (let i = start; i >= end; i--) {
      if (i === end) {
        str += ", and finally ";
      } else if (i !== start) {
        str += ", then ";
      }
      let f = this.parseFrequency(i.toString());
      let d = this.parseDuration(dur, "for ");
      str += f + d;
    }
    return str;
  };
  parseMedFreq = (arg) => {
    let medFreqDurElementArray = _.split(arg, ",");
    let medFreqString = "";

    medFreqDurElementArray.forEach((element, i) => {
      if (i && i !== medFreqDurElementArray.length - 1) {
        medFreqString += ", then ";
      }
      if (i === medFreqDurElementArray.length - 1 && i) {
        medFreqString += ", and finally ";
      }

      let [freqElement, durElement] = _.split(element, "x");

      if (freqElement.includes("...") && durElement) {
        let [start, end] = freqElement.split("...");
        medFreqString = this.constructStaggerString(
          parseInt(start),
          parseInt(end),
          durElement
        );
      } else {
        let freqString = this.parseFrequency(freqElement);

        let durString = this.parseDuration(durElement, "for ");
        medFreqString += `${freqString} ${durString}`;
      }
    });
    return medFreqString;
  };
  parseSpecial = (arg) =>
    arg
      .map((element, i) => {
        let conjunction =
          i && i !== arg.length - 1
            ? ", "
            : i === arg.length - 1 && i
            ? ", and "
            : "";
        return conjunction + this.parseSpecialItem(element);
      })
      .join("");
  parser = () => {
    const rawArray = _.split(this.state.parserInput, " ").filter(Boolean);
    let whichEye = "";
    let eyeOptions = _.keys(this.eyeConversion);
    if (eyeOptions.includes(rawArray[0])) {
      whichEye = this.parseEye(rawArray[0]);
      rawArray.shift();
    } else {
      whichEye = null;
    }
    let medFreqDurArray = rawArray[0];
    let medFreqString = "";
    let special;
    let flagHasMed = false;
    if (medFreqDurArray) {
      medFreqString = this.parseMedFreq(medFreqDurArray);
      flagHasMed = true;
      rawArray.shift();
      special = rawArray;
    } else {
      medFreqString = "";
      special = rawArray;
    }
    let specialString = this.parseSpecial(special);
    let instructionString = "";
    let compounderString = "; ";
    if (specialString && flagHasMed) {
      compounderString = "; ";
    } else {
      compounderString = "";
    }
    if (whichEye) {
      instructionString =
        medFreqString + compounderString + specialString + " -- " + whichEye;
    } else {
      instructionString =
        medFreqString + compounderString + specialString + ".";
    }
    return this.capitaliseFirst(instructionString);
  };

  /** PARSER ENDS */
  renderEachField = () => {
    let fields = this.props.subsection.fields;
    return fields.map((each, fieldIndex) => {
      let removeFlag = false;
      if (each.keyName === "entityName") {
        removeFlag = true;
      }
      let flag = false;
      if (each.isMandatory && !this.state[each.keyName]) {
        flag = true;
      }
      let isEyeSpecific = ["laterality", "anatomicalPart"].includes(
        each.keyName
      );
      if (isEyeSpecific) {
        let eyeIndex = _.findIndex(fields, { keyName: "organ" });
        if (eyeIndex > -1) {
          let isEye = this.state[fields[eyeIndex].keyName];
          if (isEye !== "EYE") {
            return null;
          }
        }
      }
      if (each.keyType === "TYPE") {
        let oldEntityName = this.props.entity.entityName;
        return (
          <ControlGroup key={fieldIndex}>
            <Tag
              minimal={!flag}
              intent={flag ? "danger" : "none"}
              icon={flag ? "warning-sign" : null}
            >
              {each.keyNameNice}
            </Tag>
            {removeFlag ? (
              <>
                {this.props.mode === "CLINICAL" ? (
                  <Button
                    className="colfax"
                    text="Use"
                    icon="endorsed"
                    intent="success"
                    minimal
                    disabled={
                      this.props.entity.organ === "EYE" &&
                      !this.props.entity.laterality
                    }
                    onClick={() => {
                      this.props.addVisitEntity();
                    }}
                  />
                ) : null}
                <Button
                  small
                  icon="double-chevron-up"
                  intent="warning"
                  minimal
                  onClick={() =>
                    this.setState({
                      isShortForm: true,
                    })
                  }
                />
                <Button
                  disabled={this.props.mode === "CLINICAL"}
                  minimal
                  small
                  icon="trash"
                  intent="danger"
                  onClick={() => {
                    this.props.removeVisitEntity();
                  }}
                />{" "}
              </>
            ) : null}
            {each.keyName === "entityName" ? (
              <CanonicalSearcher
                isClinicalComplicatedThing={true}
                query={oldEntityName}
                width="100px !important"
                trySearchingWith={true}
                trySearchingWithName={this.state.entityName || ""}
                entityType={this.props.subsection.searchEntityType}
                onSearch={() => {}}
                onUpdate={(item) => {
                  this.setState({ entityName: item });
                }}
                onChange={(item) => {
                  let entity2 = this.props.entity;
                  let itemId = item._id;
                  let itemLabel = item.entityName;

                  let keys = _.keys(item);
                  let exclude = ["_id", "__searchText", "entitySynonyms"];
                  let keysToBeUpdated = _.difference(keys, exclude);

                  for (var i = 0; i < keysToBeUpdated.length; i++) {
                    entity2[keysToBeUpdated[i]] = item[keysToBeUpdated[i]];
                  }
                  entity2["entityName"] = itemLabel;
                  entity2["edited"] = true;
                  entity2["canonicalId"] = [itemId];
                  entity2["entityName"] = itemLabel;
                  entity2["canonicalLinkedBy"] = "MANUAL_SELECTION";
                  entity2 = replaceNullAndUndefined(entity2);
                  this.checkIfChanged(entity2);
                }}
                onBlur={() => {
                  let entity = this.props.entity;
                  entity["entityName"] = this.state.entityName;
                  entity["edited"] = true;
                  entity["canonicalId"] = [];
                  entity["canonicalLinkedBy"] = "";
                  if (this.props.mode === "CLINICAL") {
                    return;
                  } else {
                    this.checkIfChanged(entity);
                  }
                }}
              />
            ) : (
              <InputGroup
                fill
                inputClassName="colfax"
                value={this.state[each.keyName]}
                className={each.isLong ? "colfax big_input" : "colfax"}
                onChange={(e) =>
                  this.setState({
                    [each.keyName]: e.target.value,
                  })
                }
                onBlur={() => {
                  let entity = this.props.entity;
                  let fields = this.props.subsection.fields;
                  let flag = false;
                  for (var i = 0; i < fields.length; i++) {
                    if (
                      entity[fields[i].keyName] !==
                      this.state[fields[i].keyName]
                    ) {
                      flag = true;
                      entity[fields[i].keyName] = this.state[fields[i].keyName];
                    }
                  }
                  if (flag) {
                    entity["edited"] = true;
                    this.setState({ isSaving: true });
                    this.props.updateVisitEntity(entity);
                  }
                }}
              />
            )}
          </ControlGroup>
        );
      } else if (each.keyType === "OPTIONS") {
        let items = masterParameterOptions[each.keyOptions];
        return (
          <ControlGroup key={fieldIndex}>
            <Tag
              minimal={!flag}
              intent={flag ? "danger" : "none"}
              icon={flag ? "warning-sign" : null}
            >
              {each.keyNameNice}
            </Tag>
            {removeFlag ? (
              <>
                {this.props.mode === "CLINICAL" ? (
                  <Button
                    className="colfax"
                    text="Use"
                    icon="endorsed"
                    intent="success"
                    minimal
                    disabled={
                      this.props.entity.organ === "EYE" &&
                      !this.props.entity.laterality
                    }
                    onClick={() => {
                      this.props.addVisitEntity();
                    }}
                  />
                ) : null}
                <Button
                  small
                  icon="double-chevron-up"
                  intent="warning"
                  minimal
                  onClick={() =>
                    this.setState({
                      isShortForm: true,
                    })
                  }
                />
                <Button
                  minimal
                  small
                  disabled={this.props.mode === "CLINICAL"}
                  icon="trash"
                  intent="danger"
                  onClick={() => {
                    this.props.removeVisitEntity();
                  }}
                />
              </>
            ) : null}
            <SingleSelectWrapper
              /* disabled={this.state.isSaving} */
              isColfax={true}
              showSelectProp={true}
              placeholder={each.keyNameNice}
              items={items}
              selectedItem={this.state[each.keyName]}
              onChange={(item) => {
                this.setState(
                  {
                    [each.keyName]: item,
                  },
                  () => {
                    let entity = this.props.entity;
                    for (var i = 0; i < fields.length; i++) {
                      entity[fields[i].keyName] = this.state[fields[i].keyName];
                    }
                    entity["edited"] = true;

                    this.checkIfChanged(entity);
                  }
                );
              }}
            />
          </ControlGroup>
        );
      } else if (each.keyType === "TYPE_LONG") {
        return (
          <ControlGroup key={fieldIndex} vertical>
            <ControlGroup>
              <Tag
                fill
                minimal={!flag}
                intent={flag ? "danger" : "none"}
                icon={flag ? "warning-sign" : null}
              >
                {each.keyNameNice}
              </Tag>
              {removeFlag ? (
                <>
                  {this.props.mode === "CLINICAL" ? (
                    <Button
                      className="colfax"
                      text="Use"
                      icon="endorsed"
                      intent="success"
                      minimal
                      disabled={
                        this.props.entity.organ === "EYE" &&
                        !this.props.entity.laterality
                      }
                      onClick={() => {
                        this.props.addVisitEntity();
                      }}
                    />
                  ) : null}
                  <Button
                    small
                    icon="double-chevron-up"
                    intent="warning"
                    minimal
                    onClick={() =>
                      this.setState({
                        isShortForm: true,
                      })
                    }
                  />
                  <Button
                    disabled={this.props.mode === "CLINICAL"}
                    minimal
                    small
                    icon="trash"
                    intent="danger"
                    onClick={() => {
                      this.props.removeVisitEntity();
                    }}
                  />
                </>
              ) : null}
            </ControlGroup>
            <div style={{ width: "41vw" }}>
              <TextArea
                fill
                /* disabled={this.state.isSaving} */
                autoResize
                className="colfax"
                value={this.state[each.keyName]}
                onChange={(e) =>
                  this.setState({
                    [each.keyName]: e.target.value,
                  })
                }
                onBlur={() => {
                  let entity = this.props.entity;
                  let fields = this.props.subsection.fields;
                  let flag = false;
                  for (var i = 0; i < fields.length; i++) {
                    if (
                      entity[fields[i].keyName] !==
                      this.state[fields[i].keyName]
                    ) {
                      flag = true;
                      entity[fields[i].keyName] = this.state[fields[i].keyName];
                    }
                  }
                  if (flag) {
                    entity["edited"] = true;
                    this.setState({ isSaving: true });
                    this.props.updateVisitEntity(entity);
                  }
                }}
              />
            </div>{" "}
            {each.hasParser && <>{this.renderParserInput(each)}</>}
          </ControlGroup>
        );
      } else if (each.keyType === "BOOLEAN") {
        return (
          <ControlGroup key={fieldIndex}>
            <Tag
              icon={this.state[each.keyName] ? each.trueIcon : each.falseIcon}
              minimal
              interactive
              onClick={() => {
                this.setState(
                  {
                    [each.keyName]: !this.state[each.keyName],
                  },
                  () => {
                    let entity = this.props.entity;
                    for (var i = 0; i < fields.length; i++) {
                      entity[fields[i].keyName] = this.state[fields[i].keyName];
                    }
                    entity["edited"] = true;

                    this.checkIfChanged(entity);
                  }
                );
              }}
            >
              {this.state[each.keyName] ? each.keyNameNice : each.keyNameFalse}
            </Tag>
          </ControlGroup>
        );
      }
    });
  };
  getTokenForAI = async () => {
    this.setState({ AIActionRunning: true, alchemyToken: null });

    const alToken = localStorage.getItem("alchemyToken");
    if (alToken) {
      this.setState({ alchemyToken: alToken, isLoading: false }, () =>
        this.getConversationId()
      );
      return;
    }

    try {
      let res = await apiCallPost(`/gateway/alchemy`, {});
      if (res) {
        this.setState({ alchemyToken: res.token }, () => {
          localStorage.setItem("alchemyToken", res.token);
          this.getConversationId();
        });
      }
    } catch (err) {
      console.log(err);
      // cogoToast.error("Oops! Something went wrong");
    }
  };
  getConversationId = async () => {
    let parserInput = this.state.parserInput;
    try {
      this.setState({
        AIActionRunning: true,
        AIActionDone: false,
      });
      let promptHead =
        masterSystemPrompts.MEDICINE_INSTRUCTION_PARSER.promptHead;
      let res = await apiCallPostAI(
        this.state.alchemyToken,
        `/chat/createChatConversation`,
        {
          promptHead:
            masterSystemPrompts.MEDICINE_INSTRUCTION_PARSER.promptHead,
          model: "gpt-4o",
          temperature: 0.9,
          engine: "openai",
          tool: "MEDICINE_INSTRUCTION_PARSER",
          applicationCtxId: this.props.data.visitId,
        }
      );
      this.setState({ gptConversationId: res._id }, () =>
        this.runAIAction(res._id, parserInput)
      );
    } catch (err) {
      this.setState({
        AIActionRunning: false,
        AIActionDone: false,
      });
      console.log(err);
      cogoToast.error("Oops! We ran into an error.");
    }
  };
  runAIAction = async (conversationId, parserInput) => {
    try {
      cogoToast.loading("Thinking");
      this.setState({
        AIActionRunning: true,
        parserInput: "",
        AIActionDone: false,
      });
      await apiCallPostStreamingForAI(
        "/chat/generateChatCompletionSSE",
        this.state.alchemyToken,

        {
          conversationId: conversationId,
          lastContext: {
            role: "user",
            content: parserInput,
          },
        },
        (data) => {
          this.setState({
            AIActionDone: false,
            parserInput: this.state.parserInput + data,
          });
        },
        () => {
          this.setState({
            AIActionDone: true,
            AIActionRunning: false,
          });
        }
      );
    } catch (err) {
      this.setState({
        AIActionDone: false,
        parserInput: parserInput,
        AIActionRunning: false,
      });
      cogoToast.error("Oops! We ran into an error.");
      console.log(err);
    }
  };
  renderParserInput = (each) => {
    return (
      <ControlGroup>
        <Tag intent="warning" minimal>
          Instruction Parser
        </Tag>
        <InputGroup
          disabled={this.state.AIActionRunning}
          fill
          inputClassName="colfax"
          value={this.state.parserInput}
          className={each.isLong ? "colfax big_input" : "colfax"}
          onChange={(e) =>
            this.setState(
              {
                parserInput: e.target.value,
              },
              () => {
                let text = this.parser(this.state.parserInput);
                this.setState({ [each.keyName]: text });
              }
            )
          }
          onBlur={() => {
            let entity = this.props.entity;
            let fields = this.props.subsection.fields;
            entity["edited"] = true;
            // wait for 3 seconds
            setTimeout(() => {
              if (this.state.AIActionRunning) {
                return;
              } else {
                let flag = false;
                for (var i = 0; i < fields.length; i++) {
                  if (
                    entity[fields[i].keyName] !== this.state[fields[i].keyName]
                  ) {
                    flag = true;
                    entity[fields[i].keyName] = this.state[fields[i].keyName];
                  }
                }
                if (flag) {
                  entity["edited"] = true;
                  this.setState({ isSaving: true });
                  this.props.updateVisitEntity(entity);
                }
              }
            }, 3000);
          }}
        />
        {this.state.AIActionDone ? (
          <Button
            icon="endorsed"
            intent="success"
            onClick={() => {
              let entity = this.props.entity;
              entity["instructions"] = this.state.parserInput;
              entity["edited"] = true;

              this.checkIfChanged(entity);
              this.setState({
                [each.keyName]: this.state.parserInput,
                AIActionDone: false,
                parserInput: "",
                alchemyToken: null,
                gptConversationId: "",
              });
            }}
          />
        ) : (
          <Button
            loading={this.state.AIActionRunning}
            icon="clean"
            intent="warning"
            onClick={() => this.getTokenForAI()}
          />
        )}
      </ControlGroup>
    );
  };
  renderNotesAndDelete = () => {
    let subsection = this.props.subsection;
    if (!subsection.hasNotes) {
      return null;
    }
    return (
      <ControlGroup>
        <Tag minimal icon="annotation"></Tag>{" "}
        <InputGroup
          /* disabled={this.state.isSaving} */
          small
          inputClassName="colfax"
          fill
          placeholder={this.props.subsection.notesHelper}
          value={this.state.notes}
          onChange={(e) =>
            this.setState({
              notes: e.target.value,
            })
          }
          onBlur={() => {
            let entity = this.props.entity;
            if (entity.notes !== this.state.notes) {
              entity.notes = this.state.notes;
              entity["edited"] = true;
              this.setState({
                isSaving: true,
              });
              this.props.updateVisitEntity(entity);
            }
          }}
        />
      </ControlGroup>
    );
  };
  renderShortForm = () => {
    return (
      <Collapse isOpen={this.state.isShortForm}>
        <Card
          elevation={Elevation.FOUR}
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            padding: "5px",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: `${
              this.props.mode === "CLINICAL" ? "#17191a" : ""
            }`,
          }}
          className="colfax"
        >
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "flex-start",
            }}
          >
            {this.props.entity.organ === "EYE" &&
            this.props.mode === "CLINICAL" ? (
              <ControlGroup>
                <Button
                  small
                  text="RE"
                  className={`${
                    this.props.entity.laterality === "RIGHT"
                      ? "forest__bg colfax"
                      : "colfax"
                  }`}
                  onClick={() => {
                    let entity = this.props.entity;
                    entity.laterality = "RIGHT";
                    entity["edited"] = true;
                    this.checkIfChanged(entity);
                  }}
                />
                <Button
                  small
                  className={`${
                    this.props.entity.laterality === "BILATERAL"
                      ? "forest__bg colfax"
                      : "colfax"
                  }`}
                  text="BE"
                  onClick={() => {
                    let entity = this.props.entity;
                    entity.laterality = "BILATERAL";
                    entity["edited"] = true;
                    this.checkIfChanged(entity);
                  }}
                />
                <Button
                  small
                  className={`${
                    this.props.entity.laterality === "LEFT"
                      ? "forest__bg colfax"
                      : "colfax"
                  }`}
                  text="LE"
                  onClick={() => {
                    let entity = this.props.entity;
                    entity.laterality = "LEFT";
                    entity["edited"] = true;
                    this.checkIfChanged(entity);
                  }}
                />
              </ControlGroup>
            ) : null}
            {this.props.entity.entityType === "MEDICINES" &&
            this.props.mode === "CLINICAL" ? (
              <ControlGroup>
                <Button
                  small
                  text="RE"
                  className={`${
                    this.props.entity.instructions.includes("RE: ")
                      ? "forest__bg colfax"
                      : "colfax"
                  }`}
                  onClick={() => {
                    let entity = this.props.entity;
                    if (entity.instructions.includes("RE: ")) {
                      entity.instructions = entity.instructions.replace(
                        "RE: ",
                        ""
                      );
                    } else {
                      entity.instructions = "RE: " + entity.instructions || "";
                    }
                    entity["edited"] = true;
                    this.checkIfChanged(entity);
                  }}
                />
                <Button
                  small
                  text="BE"
                  className={`${
                    this.props.entity.instructions.includes("BE: ")
                      ? "forest__bg colfax"
                      : "colfax"
                  }`}
                  onClick={() => {
                    let entity = this.props.entity;
                    if (entity.instructions.includes("BE: ")) {
                      entity.instructions = entity.instructions.replace(
                        "BE: ",
                        ""
                      );
                    } else {
                      entity.instructions = "BE: " + entity.instructions || "";
                    }
                    entity["edited"] = true;
                    this.checkIfChanged(entity);
                  }}
                />
                <Button
                  small
                  text="LE"
                  className={`${
                    this.props.entity.instructions.includes("LE: ")
                      ? "forest__bg colfax"
                      : "colfax"
                  }`}
                  onClick={() => {
                    let entity = this.props.entity;
                    if (entity.instructions.includes("LE: ")) {
                      entity.instructions = entity.instructions.replace(
                        "LE: ",
                        ""
                      );
                    } else {
                      entity.instructions = "LE: " + entity.instructions || "";
                    }
                    entity["edited"] = true;
                    this.checkIfChanged(entity);
                  }}
                />
              </ControlGroup>
            ) : null}
            {this.props.entity.anatomicalPart && (
              <Tag
                intent="success"
                className="turquoise__bg"
                multiline
                style={{
                  marginLeft: `${
                    this.props.entity.laterality &&
                    this.props.mode === "CLINICAL"
                      ? "5px"
                      : "5px"
                  }`,
                }}
              >
                {this.props.entity.anatomicalPart}
              </Tag>
            )}
            <Tag
              intent="success"
              className="cerulean__bg"
              multiline
              style={{
                marginLeft: `${
                  this.props.entity.laterality && this.props.mode === "CLINICAL"
                    ? "5px"
                    : "5px"
                }`,
              }}
            >
              {this.props.entity.entityName}
            </Tag>
            {this.props.entity.laterality && (
              <Tag
                intent="success"
                className="indigo__bg"
                multiline
                style={{
                  marginLeft: `${
                    this.props.entity.laterality &&
                    this.props.mode === "CLINICAL"
                      ? "5px"
                      : "5px"
                  }`,
                }}
              >
                {this.props.entity.laterality}
              </Tag>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            {this.props.mode === "CLINICAL" ? (
              <>
                {" "}
                {this.props.mode === "TEMPLATES" && (
                  <Tag minimal>{this.props.subsection.subsectionNiceName}</Tag>
                )}
                <Button
                  className="colfax"
                  text="Use"
                  icon="endorsed"
                  intent="success"
                  minimal
                  disabled={
                    this.props.entity.organ === "EYE" &&
                    !this.props.entity.laterality
                  }
                  onClick={() => {
                    this.props.addVisitEntity();
                  }}
                />
              </>
            ) : (
              <>
                {(this.props.mode === "TEMPLATES" ||
                  this.props.mode === "CLINICAL") && (
                  <Tag minimal>{this.props.subsection.subsectionNiceName}</Tag>
                )}
                <Button
                  disabled={this.props.mode === "CLINICAL"}
                  icon="trash"
                  intent="danger"
                  minimal
                  onClick={() => {
                    this.props.removeVisitEntity();
                  }}
                />
              </>
            )}
            <Button
              icon="double-chevron-down"
              intent="warning"
              minimal
              onClick={() =>
                this.setState({
                  isShortForm: false,
                })
              }
            />
          </div>
        </Card>
      </Collapse>
    );
  };
  render() {
    if (this.state.isLoading) {
      return null;
    }
    return (
      <div
        style={{ width: "100%" }}
        key={`${this.state.searchResults.length}__${this.props.entity._id}__${this.props.entity.updatedAt}`}
      >
        <Collapse isOpen={!this.state.isShortForm}>
          <Card
            elevation={Elevation.FOUR}
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              padding: "5px",
              width: "100%",
            }}
            className="colfax"
          >
            <div className="">{this.renderEachField()}</div>
            {this.renderNotesAndDelete()}
            {this.state.isSaving && (
              <Tag
                large
                minimal
                className="indigo_dark"
                icon={<Spinner size={20} />}
              >
                Saving...
              </Tag>
            )}
          </Card>
        </Collapse>
        <Collapse isOpen={this.state.isShortForm}>
          <div
            style={{ width: `${this.props.mode === "CLINICAL" ? "42vw" : ""}` }}
          >
            {this.renderShortForm()}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(ClinicalEntity));
