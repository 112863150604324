import React from "react";
import { Button } from "@blueprintjs/core";
import _ from "lodash";

class MutMult extends React.Component {
  state = {
    selected: [],
  };
  componentDidMount() {
    if (this.props.selected && this.props.selected.length) {
      this.setState({ selected: this.props.selected });
    }
  }
  toggleSign = (item) => {
    if (this.state.selected.includes(item)) {
      let t = this.state.selected;
      _.remove(t, (each) => each === item);
      this.setState({ selected: t }, () =>
        this.props.onUpdate(this.state.selected)
      );
    } else {
      let t = this.state.selected;
      t.push(item);
      this.setState({ selected: t }, () =>
        this.props.onUpdate(this.state.selected)
      );
    }
  };
  renderButtons = () => {
    let list = this.props.options;
    return list.map((each) => {
      let intent = "none";
      if (this.state.selected.includes(each)) {
        intent = "success";
      }
      return (
        <Button
          text={each}
          intent={intent}
          outlined={intent !== "success"}
          onClick={() => this.toggleSign(each)}
        />
      );
    });
  };
  render() {
    return <div>{this.renderButtons()}</div>;
  }
}

export default MutMult;
