import React from "react";
import {
  Collapse,
  ControlGroup,
  Tag,
  Card,
  Elevation,
  Button,
} from "@blueprintjs/core";

import Surgery from "./Surgery";
import { inject, observer } from "mobx-react";
import NewSx from "./NewSx";

class PlanSurgery extends React.Component {
  state = {
    collapse: true,
    notesText: null,
    notesTouched: false,
    searching: false,
    searchResults: [],
  };

  renderShort = () => {
    if (!this.props.data || !this.props.data.length) {
      return (
        <Tag minimal large>
          No surgeries advised.
        </Tag>
      );
    }
    let sx = this.props.data.map((each) => each.surgery);
    let txt = sx.join(", ");
    return (
      <Tag minimal large multiline>
        {txt}
      </Tag>
    );
  };

  renderHeading = () => {
    return (
      <ControlGroup>
        <Tag
          large
          intent="primary"
          interactive
          onClick={() => this.setState({ collapse: !this.state.collapse })}
        >
          Surgery
        </Tag>{" "}
        {this.addSxButton()}
      </ControlGroup>
    );
  };
  addSxButton = () => {
    let areWeTestingNew = true;
    let em;
    if (areWeTestingNew) {
      em = {
        when: null,
        anaesthesia: "Topical Anaesthesia",
        eye: null,
        sxSteps: [],
        sxClinicalItems: [],
        sxStepsJoiner: [],
        sxClinicalItemsJoiner: [],
      };
    } else {
      em = {
        when: "",
        anaesthesia: "Topical Anaesthesia",
        devices: [],
        medicines: [],
        implants: [],
        iolImplants: [],
        sxItems: [],
        composeString: "",
      };
    }
    return (
      <ControlGroup>
        {this.props.templateEditing ? (
          <Button minimal icon="add" onClick={() => this.props.add(em)} />
        ) : (
          <Tag
            minimal
            large
            interactive
            onClick={() => this.props.add("plannedEyeSx", em)}
          >
            Add a surgery
          </Tag>
        )}
      </ControlGroup>
    );
  };
  renderSurgeries = () => {
    if (!this.props.data || !this.props.data.length) {
      return null;
    }
    return this.props.data.map((each) => {
      if (each.nVer === 1) {
        return (
          <Surgery
            data={each}
            update={(sxId, props) =>
              this.props.update("plannedEyeSx", sxId, props)
            }
            remove={(sxId) => this.props.remove(sxId)}
          />
        );
      } else {
        return (
          <NewSx
            templateEditing={this.props.templateEditing}
            addInv={(invString) => this.props.onAddInvFromSx(invString)}
            data={this.props.templateEditing ? each.data : each}
            update={(sxId, props) =>
              this.props.update("plannedEyeSx", sxId, props)
            }
            remove={(sxId) => this.props.remove(sxId)}
          />
        );
      }
    });
  };

  render() {
    return (
      <Card
        style={{ padding: "0px", background: "none" }}
        elevation={Elevation.FOUR}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "start",
            justifyContent: "left",
            padding: "10px",
          }}
        >
          {this.renderHeading()}
          <Collapse isOpen={this.state.collapse}>
            {this.renderSurgeries()}
          </Collapse>
        </div>
      </Card>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(PlanSurgery));
