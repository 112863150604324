import React from "react";
import { Button, ControlGroup, Popover, Tag } from "@blueprintjs/core";
import _ from "lodash";
import MutMult from "../utils/MutMult";
import VASelector from "./elems/VASelector";
import NumInpVision from "./elems/NumInpVision";
import { niceDate } from "../fns/util";

class VisTable extends React.Component {
  state = {
    showPopper: false,
    context: "",
    showPrev: true,
  };
  listOfVisNotRec = [
    "Swelling",
    "Redness",
    "Congestion",
    "Pain",
    "Trauma",
    "S/P Surgery",
    "Uncooperative",
  ];

  renderVButtons = (copyValues) => {
    let arr = [
      "uncorrected",
      "pgp",
      "corrected_dry",
      "corrected_dilated",
      "corrected_pmt",
      "corrected_rx",
    ];
    let data = this.props.vision;
    let nice = [
      "Uncorrected",
      "Present Glass",
      "Dry",
      "Dilated",
      "PMT",
      "Final Rx",
    ];
    return arr.map((each) => {
      let hasArr = `has_${each}`;
      if (!data[hasArr]) {
        let idx = _.findIndex(arr, (x) => x === each);

        return this.renderEachVButton(hasArr, nice[idx], copyValues);
      } else {
        return null;
      }
    });
  };
  copyVision = (key) => {
    let visPayload = this.props.vision[this.state.context];

    this.props.updateVision(_.trimStart(key, "has_"), visPayload);
    this.setState({ showPopper: false, context: "" });
  };
  renderEachVButton = (key, nice, copyValues) => {
    return (
      <Tag
        minimal
        icon="small-plus"
        small
        interactive
        onClick={() => {
          this.props.updateVision(key, true);
          if (copyValues) {
            this.copyVision(key);
          }
        }}
      >
        {nice}
      </Tag>
    );
  };
  computeBestVision = (v) => {
    let final = [null, null];
    if (v.has_uncorrected) final = ["Uncorrected", v.uncorrected];
    if (v.has_pgp) final = ["PGP", v.pgp];
    if (v.has_corrected_dry) final = ["Dry", v.corrected_dry];
    if (v.has_corrected_dilated) final = ["Dilated", v.corrected_dilated];
    if (v.has_corrected_pmt) final = ["PMT", v.corrected_pmt];
    if (v.has_corrected_rx) final = ["Rx", v.corrected_rx];
    return final;
  };
  renderAllRows = () => {
    let arr = [
      "uncorrected",
      "pgp",
      "corrected_dry",
      "corrected_dilated",
      "corrected_pmt",
      "corrected_rx",
    ];

    let data = this.props.vision;

    let nice = [
      "Uncorrected",
      "Present Glass",
      "Dry",
      "Dilated",
      "PMT",
      "Final Rx",
    ];
    let hases = arr.map((each) => `has_${each}`);
    let flag = false;

    _.forEach(hases, (each) => {
      if (data[each]) {
        flag = true;
      }
    });

    if (flag) {
      return arr.map((each) => {
        let arrHas = `has_${each}`;

        if (data[arrHas]) {
          let idx = _.findIndex(arr, (x) => x === each);
          return this.renderRows(data[each], each, nice[idx]);
        } else {
          return null;
        }
      });
    } else {
      return (
        <tr>
          <td colSpan={15}>
            <ControlGroup>
              <Tag large minimal>
                Vision Not Recorded:
              </Tag>
              <MutMult
                selected={this.props.vision.notRecordedReason}
                options={this.listOfVisNotRec}
                onUpdate={(items) =>
                  this.props.updateVision("notRecordedReason", items)
                }
              />
            </ControlGroup>
          </td>
        </tr>
      );
    }
  };

  renderPopper = () => {
    return (
      <div>
        <ControlGroup vertical>{this.renderVButtons(true)}</ControlGroup>
      </div>
    );
  };
  copyVisionFromPast = (key) => {
    let acronymsArray = ["Uncorrected", "PGP", "Dry", "Dilated", "PMT", "Rx"];
    let visionKeysArray = [
      "uncorrected",
      "pgp",
      "corrected_dry",
      "corrected_dilated",
      "corrected_pmt",
      "corrected_rx",
    ];
    let past = this.props.past[0];
    let dataPrev = this.computeBestVision(past.vision);
    let whichKey =
      visionKeysArray[
        _.findIndex(acronymsArray, (each) => each === dataPrev[0])
      ];
    this.props.updateVision(whichKey, dataPrev[1]);
    this.props.updateVision(`has_${whichKey}`, true);
  };
  renderRows = (obj, obj_name, nice, old = false) => {
    return (
      <tr>
        <td>
          {old ? (
            <ControlGroup>
              <Tag
                minimal
                intent="warning"
                interactive
                icon="duplicate"
                onClick={() => this.copyVisionFromPast()}
              >
                {nice}
              </Tag>
            </ControlGroup>
          ) : (
            <Popover
              isOpen={this.state.showPopper && this.state.context === obj_name}
              onClose={() => this.setState({ showPopper: false, context: "" })}
            >
              <ControlGroup>
                {" "}
                <Tag
                  minimal
                  onRemove={() =>
                    this.props.updateVision(`has_${obj_name}`, false)
                  }
                >
                  {nice}
                </Tag>
                <Button
                  icon="duplicate"
                  small
                  minimal
                  onClick={() =>
                    this.setState(
                      {
                        context: obj_name,
                      },
                      () => {
                        this.setState({ showPopper: true });
                      }
                    )
                  }
                />
              </ControlGroup>

              {this.renderPopper()}
            </Popover>
          )}
        </td>
        <td>
          <VASelector
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.re.v_dist`, item)
            }
            type="VA"
            vision={obj.re.v_dist}
            old={old}
          />
        </td>
        <td>
          <VASelector
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.re.v_ph`, item)
            }
            type="VA"
            vision={obj.re.v_ph}
            old={old}
          />
        </td>
        <td>
          <VASelector
            type="VA_NEAR"
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.re.v_near`, item)
            }
            vision={obj.re.v_near}
            old={old}
          />
        </td>
        <td>
          <NumInpVision
            old={old}
            fix={2}
            value={obj.re.sph}
            major={1}
            minor={0.25}
            min={-20}
            max={30}
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.re.sph`, item)
            }
          />
        </td>
        <td>
          <NumInpVision
            old={old}
            fix={2}
            value={obj.re.cyl}
            major={1}
            minor={0.25}
            min={-20}
            max={30}
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.re.cyl`, item)
            }
          />
        </td>
        <td>
          <NumInpVision
            old={old}
            fix={0}
            value={obj.re.axis}
            major={10}
            minor={5}
            min={0}
            max={180}
            iop={true}
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.re.axis`, item)
            }
          />
        </td>
        <td style={{ borderRight: "2px solid #A7B6C2" }}>
          <NumInpVision
            old={old}
            fix={2}
            value={obj.re.add}
            major={1}
            minor={0.25}
            min={0}
            max={10}
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.re.add`, item)
            }
          />
        </td>
        <td>
          <VASelector
            old={old}
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.le.v_dist`, item)
            }
            type="VA"
            vision={obj.le.v_dist}
          />
        </td>{" "}
        <td>
          <VASelector
            old={old}
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.le.v_ph`, item)
            }
            type="VA"
            vision={obj.le.v_ph}
          />
        </td>
        <td>
          <VASelector
            old={old}
            type="VA_NEAR"
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.le.v_near`, item)
            }
            vision={obj.le.v_near}
          />
        </td>
        <td>
          <NumInpVision
            old={old}
            min={-20}
            max={30}
            fix={2}
            value={obj.le.sph}
            major={1}
            minor={0.25}
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.le.sph`, item)
            }
          />
        </td>
        <td>
          <NumInpVision
            old={old}
            min={-20}
            max={30}
            fix={2}
            value={obj.le.cyl}
            major={1}
            minor={0.25}
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.le.cyl`, item)
            }
          />
        </td>
        <td>
          <NumInpVision
            old={old}
            min={0}
            max={180}
            fix={0}
            value={obj.le.axis}
            major={10}
            minor={5}
            iop={true}
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.le.axis`, item)
            }
          />
        </td>
        <td>
          <NumInpVision
            old={old}
            min={0}
            max={10}
            fix={2}
            value={obj.le.add}
            major={1}
            minor={0.25}
            onUpdate={(item) =>
              this.props.updateVision(`${obj_name}.le.add`, item)
            }
          />
        </td>
      </tr>
    );
  };
  renderPast = () => {
    if (this.props.past && this.props.past.length && this.state.showPrev) {
      //let past = this.props.past[5];
      let past = this.props.past[0];
      let dataPrev = this.computeBestVision(past.vision);

      if (dataPrev[0]) {
        let prevDate = niceDate(past.onDay);
        return this.renderRows(
          dataPrev[1],
          "NA",
          `${dataPrev[0]} | ${prevDate}`,
          true
        );
      } else {
        return null;
      }
    }
    return null;
  };
  hasPastVision = () => {
    if (this.props.past && this.props.past.length) {
      let past = this.props.past[0];
      let dataPrev = this.computeBestVision(past.vision);
      if (dataPrev[0]) {
        return true;
      }
      return false;
    }
    return false;
  };
  renderVV = () => {
    return (
      <table class="bp5-html-table bp5-html-table-condensed bp5-html-table-bordered bp5-html-table-striped">
        <thead style={{ textAlign: "center" }}>
          <tr>
            <td>
              <ControlGroup>
                {" "}
                <Tag
                  minimal
                  interactive
                  onClick={() => this.props.onCollapse()}
                >
                  {this.props.vision.visionDoneBy || "Vision Not Done"}
                </Tag>
                {this.hasPastVision() ? (
                  <Tag
                    interactive
                    intent="warning"
                    icon="history"
                    minimal
                    onClick={() =>
                      this.setState({ showPrev: !this.state.showPrev })
                    }
                  ></Tag>
                ) : null}
              </ControlGroup>
            </td>
            <th>R/Dist</th> <th>PH</th> <th>Near</th>
            <th>Sph</th> <th>Cyl</th> <th>Axis</th>{" "}
            <th style={{ borderRight: "2px solid #A7B6C2" }}>Add</th>
            <th>L/Dist</th> <th>PH</th> <th>Near</th>
            <th>Sph</th> <th>Cyl</th> <th>Axis</th> <th>Add</th>
          </tr>
        </thead>
        <tbody>
          {this.renderPast()}
          {this.renderAllRows()}
        </tbody>
      </table>
    );
  };
  render() {
    return (
      <div style={{ marginRight: "5px" }}>
        {this.renderVV()}
        <ControlGroup>{this.renderVButtons(false)}</ControlGroup>
      </div>
    );
  }
}
export default VisTable;
