import {
  Button,
  Callout,
  Card,
  ControlGroup,
  Elevation,
  NonIdealState,
  Spinner,
  Tag,
  TextArea,
  FileInput,
  ProgressBar,
  AnchorButton,
} from "@blueprintjs/core";
import React from "react";
import _ from "lodash";
import RecordRTC from "recordrtc";
import cogoToast from "cogo-toast";
import {
  alchemyUrl,
  apiCallPostAI,
  apiCallPostCancellable,
  apiCallPostStreamingForAI,
  apiCallWhisper,
} from "../../fns/util";
import CodeRender from "./CodeRender";

class ChatterLanding extends React.Component {
  state = {
    isVision: false,
    alchemyToken: null,
    mode: "Normal", // Modes: 'Vision', 'Normal', 'Assistants'
    threadId: null,
    chatId: null,
    isLoading: true,
    isError: false,
    isRecording: false,
    recordedBlob: null,
    recordedUrl: null,
    recordingTime: 0,
    isBusy: false,
    userInputText: "",
    messages: [],
    uploadedFileUrl: null,
    uploadProgress: null,
  };
  componentDidMount() {
    this.getAlchemyToken();
  }
  getAlchemyToken = async () => {
    const alToken = localStorage.getItem("alchemyToken");
    if (alToken) {
      this.setState({ alchemyToken: alToken }, () => this.getChatId());
      return;
    }

    try {
      let res = await apiCallPostCancellable("/gateway/alchemy", {});
      if (res) {
        this.setState(
          {
            alchemyToken: res.token,

            isError: false,
          },
          () => {
            localStorage.setItem("alchemyToken", res.token);
            this.getChatId();
          }
        );
      }
    } catch (err) {
      this.setState({ isError: true });
    }
  };
  changeMode = (newMode) => {
    this.setState(
      {
        mode: newMode,
        isVision: newMode === "Vision" ? true : false,
        chatId: null,
        isLoading: false,
        isError: false,
        isRecording: false,
        recordedBlob: null,
        recordedUrl: null,
        recordingTime: 0,
        isBusy: false,
        userInputText: "",
        messages: [],
        uploadedFileUrl: null,
        uploadProgress: null,
      },
      () => {
        if (newMode === "Assistants") {
          this.createAssistantThread();
        } else {
          this.getAlchemyToken();
        }
      }
    );
  };
  createAssistantThread = async () => {
    try {
      const response = await apiCallPostAI(
        this.state.alchemyToken,
        "/assistant/createThread",
        {
          applicationCtxId: "NC_HARLEY_ASSISTANT_PLAYGROUND",
          tool: "NC_HARLEY_ASSISTANT",
        }
      );
      this.setState({ threadId: response.id });
    } catch (error) {
      console.error("Error creating assistant thread:", error);
    }
  };
  getChatId = async () => {
    let systemPrompt = `You are one of the top physicians in the world. You are board certified in internal medicine and in ophthalmology, and as a result you are well familiar with medical terms. Your name is Harley, and you are part of the team of Radical Health. You were trained by Radical Health, and your knowledge cut-off is December, 2023.
    
    You will be talking to a fellow doctor, and engaged in peer discussions. 
  
    For example, you may be tested about your medical knowledge. You must answer to the best of your abilities, and impress your peer. 

    You may be given some notes about a patient or a patient encounter, and asked questions about it. Once you have read this, you may be asked questions about the case, and you will answer as if you are on the top doctors in the world. 
    
    In all your responses and future conversations, whenever you write either a medicine, disease, finding or similar such clinical term, enclose them in <X></X> tags. Do not  If you are asked to respond in a table, you do so in markdown format. You must never use any HTML tags like <br> or anything else. You must never other than a table use markdown formatting such as ** or _. This is very important.

    You are especially skilled in doing tasks such as ranked differential diagnosis, as well as other diverse skills, like writing legal and medical documents. 
    
    You are highly evidence-based. Therefore, you should think step by step, explain your thinking and reasoning. Further, you must never resort to alternative medicine or other non-evidence based approaches, such as homeopathy, ayurveda or such. If asked about these, you must warn the user that not only are these approaches not evidence-based, they can be dangerous.

    You are talking to a doctor here, so speak freely, and in deep medical and technical terms. They should come out of this conversation extremely impressed.`;
    if (this.state.isVision) {
      systemPrompt += `If you are given an image, please use your extremely good skills at using vision to understand, take into consideration and analyse the given images.`;
    }
    if (this.props.mode === "CLINICAL") {
      systemPrompt += `In this particular case, here is the patient record you must work with: \n\n${this.transformPatientRecord()}`;
    }
    try {
      let payload = {
        applicationCtxId:
          this.props.mode === "CLINICAL"
            ? this.props.visitId
            : "NC_HARLEY_PLAYGROUND",
        promptTag: null,
        promptId: null,
        promptHead: [
          {
            role: "system",
            content: this.state.isVision
              ? [
                  {
                    type: "text",
                    text: systemPrompt,
                  },
                ]
              : systemPrompt,
          },
        ],
        model: "gpt-4o",
        tool: "NC_HARLEY",
        engine: "openai",
        temperature: 0.8,
      };
      let res = await apiCallPostAI(
        this.state.alchemyToken,
        "/chat/createChatConversation",
        payload
      );
      this.setState({ chatId: res._id, isLoading: false });
    } catch (err) {
      cogoToast.error("Error setting up AI.");
      this.setState({ isError: true });
    }
  };
  transcribeAudio = async () => {
    try {
      this.setState({
        isBusy: true,
      });
      const formData = new FormData();
      formData.append("tool", "NC_HARLEY_INPUT");
      formData.append("applicationCtxId", "NC_HARLEY_PLAYGROUND");
      formData.append("file", this.state.recordedBlob);
      formData.append(
        "prompt",
        "This is a snippet from a recording from an ophthalmic note. Transcribe it accurately in English. Carefully consider words to have the right technical spelling as is standard in medicine."
      );
      let res = await apiCallWhisper(
        `/stt/generateTranscription`,
        this.state.alchemyToken,
        formData,
        (done, total) => {}
      );
      this.setState({
        userInputText: res.text,
        isBusy: false,
      });
    } catch (err) {
      this.setState({
        isBusy: false,
        isRecording: false,
        recordingTime: 0,
        recordedBlob: null,
        recordedUrl: null,
        userInputText: "",
      });
      console.log(err);
      cogoToast.error(
        "We ran into an error transcribing this audio. Please try again."
      );
    }
  };
  mockFileUpload = async (file) => {
    let fd = new FormData();
    fd.append("tool", "NC_HARLEY_VISION");
    fd.append("file", file);

    let res = await apiCallWhisper(
      `/files/uploadFile`,
      this.state.alchemyToken,
      fd,
      (done, total) => {
        this.setState({
          uploadProgress: Math.round((done / total) * 100),
        });
      }
    );
    return res.url;
  };
  handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ uploadProgress: 0 });
      this.mockFileUpload(file).then((url) => {
        this.setState({ uploadedFileUrl: url, uploadProgress: 100 });
      });
    }
  };
  handleFileDelete = () => {
    this.setState({ uploadedFileUrl: null, uploadProgress: null });
  };
  sendUserMessage = async () => {
    if (this.state.mode === "Assistants" && this.state.threadId) {
      try {
        await apiCallPostAI(
          this.state.alchemyToken,
          "/assistant/addMessageToThread",
          {
            threadId: this.state.threadId,
            content: this.state.userInputText,
          }
        );
        this.setState({
          isBusy: true,
        });
        const runResponse = await apiCallPostAI(
          this.state.alchemyToken,
          "/assistant/queueThreadRun",
          {threadId: this.state.threadId,
												assistantId: "asst_TAAaeHnG5AIz8MXGfjbNNiQx", // DataAnalystBot (v2)
          }
        );

        this.pollRunStatus(this.state.threadId, runResponse.id);
      } catch (error) {
        console.error("Error in assistants mode:", error);
      }
    } else {
      let payload;
      if (this.state.isVision) {
        // Vision model active
        let contentArray = [{ type: "text", text: this.state.userInputText }];

        // Include image URL only if an image has been uploaded
        if (this.state.uploadedFileUrl) {
          contentArray.push({
            type: "image_url",
            image_url: { url: this.state.uploadedFileUrl, detail: "high" },
          });
        }

        payload = {
          conversationId: this.state.chatId,
          lastContext: {
            role: "user",
            content: contentArray,
          },
        };
      } else {
        // Non-vision model
        payload = {
          conversationId: this.state.chatId,
          lastContext: {
            role: "user",
            content: this.state.userInputText,
          },
        };
      }

      this.setState(
        {
          streamingResponse: "",
          isBusy: true,
          messages: [
            ...this.state.messages,
            {
              role: "user",
              content: this.state.userInputText,
            },
          ],
        },
        () => {
          this.setState({
            userInputText: "",
          });
        }
      );
      await apiCallPostStreamingForAI(
        "/chat/generateChatCompletionSSE",
        this.state.alchemyToken,
        payload,
        (data) => {
          this.setState({
            streamingResponse: this.state.streamingResponse + data,
          });
        },
        () => {
          this.setState({
            userInputText: "",
            isBusy: false,
            messages: [
              ...this.state.messages,
              {
                role: "assistant",
                content: this.state.streamingResponse,
              },
            ],
            streamingResponse: "",
            uploadedFileUrl: null,
            uploadProgress: null,
          });
        }
      );
    }
  };
  pollRunStatus = async (threadId, runId) => {
    try {
      let status = "";
      while (status !== "completed") {
        const statusResponse = await apiCallPostAI(
          this.state.alchemyToken,
          "/assistant/getRunStatus",
          {
            threadId,
            runId,
          }
        );
        status = statusResponse.status;
        if (status !== "completed") {
          await new Promise((resolve) => setTimeout(resolve, 2500));
        }
      }
      let respPartTwo = await this.renderThreadMessages(threadId);
      let respPartOne = await this.renderRunSteps(threadId, runId);
      let rr = _.reverse(respPartTwo);
      let final = rr;
      if (respPartOne && respPartOne.length > 0) {
        final = [...rr, ...respPartOne];
      }
      let finalSorted = _.sortBy(final, (x) => x.timestamp);
      this.setState({
        isBusy: false,
        messages: finalSorted,
        userInputText: "",
      });
    } catch (error) {
      console.error("Error while polling run status:", error);
    }
  };
  renderRunSteps = async (threadId, runId) => {
    try {
      const stepsResponse = await apiCallPostAI(
        this.state.alchemyToken,
        "/assistant/getRunSteps",
        {
          threadId,
          runId,
        }
      );
      let tt = await this.handleRunStepsResponse(stepsResponse.data);
      return tt;
    } catch (error) {
      console.error("Error getting run steps:", error);
    }
  };
  renderThreadMessages = async (threadId) => {
    try {
      const threadMessages = await apiCallPostAI(
        this.state.alchemyToken,
        "/assistant/getThreadMessages",
        {
          threadId,
          afterMessageId: null,
        }
      );
      let tt = await this.transformAsstArray(threadMessages.data);
      return tt;
    } catch (error) {
      console.error("Error getting run steps:", error);
    }
  };
  transformAsstArray = async (arr) => {
    const transformedArray = [];
    for (const element of arr) {
      const { role, content, created_at } = element;
      for (const contentItem of content) {
        if (contentItem.type === "text") {
          transformedArray.push({
            role,
            content: contentItem.text.value,
            type: "text",
            annotations: contentItem.text.annotations
              ? contentItem.text.annotations
              : [],
            timestamp: created_at,
          });
        } else if (contentItem.type === "image_file") {
          transformedArray.push({
            role,
            content: contentItem.image_file.file_id,
            type: "image",
            timestamp: created_at,
          });
        }
      }
    }
    return transformedArray;
  };
  transformAsstArrayOld = (arr) => {
    return arr.map((element) => {
      // Extract 'role' and 'content'
      const { role, content } = element;

      // Unroll 'content' array and join text values
      const contentText = content
        .map((contentItem) => contentItem.text.value)
        .join(" ");

      // Return new object with 'role' and consolidated 'content'
      return { role, content: contentText };
    });
  };
  handleRunStepsResponse = async (steps) => {
    let arr = [];
    for (const step of steps) {
      if (step.type === "tool_calls") {
        const { tool_calls } = step.step_details;
        for (const call of tool_calls) {
          if (call.type === "code_interpreter") {
            const { input } = call.code_interpreter;
            arr.push({
              role: "assistant",
              content: input,
              type: "code",
              timestamp: step.created_at,
            });
          }
        }
      }
    }
    return arr;
  };
  transformPatientRecord = () => {
    let ve = this.props.data.map((each) => {
      let str = "";
      if (each.laterality) {
        str += each.laterality + "EYE - ";
      }
      str += each.entityName;
      if (each.molecule) {
        str += " - Molecule: " + each.molecule;
      }
      if (each.notes) {
        str += " - Notes: " + each.notes;
      }
      if (each.instructions) {
        str += " - Instructions: " + each.instructions;
      }
      if (each.anaesthesia) {
        str += " - Anaesthesia: " + each.anaesthesia;
      }

      return {
        sectionName: each.sectionName,
        subsectionName: each.subsectionName,
        entity: str,
      };
    });

    let x = _.groupBy(ve, (each) => each.sectionName);
    let keysInX = Object.keys(x);
    for (var i = 0; i < keysInX.length; i++) {
      x[keysInX[i]] = _.groupBy(x[keysInX[i]], (each) => each.subsectionName);
    }

    let y = this.convertDataToString(x);
    return y;
  };
  convertDataToString = (data) => {
    let result = "";
    for (const [sectionKey, sectionValue] of Object.entries(data)) {
      let sectionContent = `${sectionKey}:`;
      for (const [subsectionKey, subsectionValue] of Object.entries(
        sectionValue
      )) {
        const entities = subsectionValue.map((item) => item.entity).join("\n");
        sectionContent += `\n\n${subsectionKey}:\n${entities}`;
      }
      result += (result ? "\n\n\n\n" : "") + sectionContent;
    }
    return result;
  };
  renderFileLinksIfAny = (annotations) => {
    if (!annotations || !annotations.length) {
      return null;
    }
    return annotations.map((x, ix) => {
      return (
        <AnchorButton
          key={ix}
          target="_blank"
          href={`${alchemyUrl}/assistant/GETFileContentOther/${x.file_path.file_id}`}
          icon="document-share"
          text={_.last(x.text.split("/"))}
        />
      );
    });
  };

  renderAIMessage = (text, type = null, annotations = []) => {
    if (this.state.mode !== "Assistants") {
      return (
        <div className="ai_message_container colfax">
          <Tag large intent="primary" className="cerulean__bg">
            Harley
          </Tag>
          <div className="chat_bubble ai_message">
            {this.renderMessageNicely(text)}
          </div>
        </div>
      );
    }
    if (type === "text") {
      return (
        <div className="ai_message_container colfax">
          <Tag large intent="primary" className="cerulean__bg">
            Harley
          </Tag>
          <div className="chat_bubble ai_message">
            {this.renderMessageNicely(this.filterMarkdownUrls(text))}
            {this.renderFileLinksIfAny(annotations)}
          </div>
        </div>
      );
    } else if (type === "image") {
      return (
        <div className="ai_message_container colfax">
          <Tag large intent="primary" className="lime__bg">
            Images from Harley
          </Tag>
          <div className="chat_bubble ai_message_image">
            <img
              src={`${alchemyUrl}/assistant/GETFileContentImg/${text}`}
              alt="Assistant Provided"
              width="50%"
            />
          </div>
        </div>
      );
    } else if (type === "code") {
      return (
        <div className="ai_message_container colfax">
          <Tag large intent="primary" className="rose__bg">
            Code from Harley
          </Tag>
          <div className="chat_bubble ai_message">
            <CodeRender text={text} />
          </div>
        </div>
      );
    }
  };
  processMarkdown = (str) => {
    const tokens = str.split(/(\*\*.*?\*\*|_.*?_|<X>.*?<\/X>)/);
    return tokens.map((token) => {
      if (token.startsWith("**") && token.endsWith("**")) {
        return (
          <Tag intent="warning" minimal>
            {token.slice(2, -2)}
          </Tag>
        );
      } else if (token.startsWith("_") && token.endsWith("_")) {
        return (
          <Tag intent="success" minimal>
            {token.slice(1, -1)}
          </Tag>
        );
      } else if (token.startsWith("<X>") && token.endsWith("</X>")) {
        return (
          <Tag intent="primary" minimal>
            {token.slice(3, -4)}
          </Tag>
        );
      } else {
        return token;
      }
    });
  };
  renderMessageNicely = (x) => {
    let message = this.filterMarkdownUrls(x);
    const lines = message.split("\n");
    let tableState = "none"; // can be 'none', 'header', 'rows'
    let codeState = "none"; // can be 'none', 'code'
    let headers = [];
    let rows = [];
    let codeLines = [];
    let elements = [];
    let codeTitle = "";

    lines.forEach((line) => {
      if (line.startsWith("|") && line.endsWith("|")) {
        if (line.includes("---")) {
          tableState = "rows";
        } else {
          const row = line.split("|").slice(1, -1);
          if (tableState === "none") {
            headers = row;
            tableState = "header";
          } else if (tableState === "rows") {
            rows.push(row);
          }
        }
      } else if (line.startsWith("```")) {
        if (codeState === "none") {
          codeState = "code";
          codeTitle = line.slice(3).trim();
          // Show a placeholder
          elements.push({ type: "codePlaceholder", title: codeTitle });
        } else {
          elements.pop(); // Remove the placeholder
          elements.push({
            type: "code",
            content: codeLines.join("\n"),
            title: codeTitle,
          });
          codeLines = [];
          codeState = "none";
          codeTitle = "";
        }
      } else {
        if (tableState !== "none") {
          elements.push({ type: "table", headers, rows });
          headers = [];
          rows = [];
          tableState = "none";
        }
        if (codeState === "code") {
          codeLines.push(line);
        } else {
          // handle the bold text
          if (line.includes("<X>") && line.includes("</X>")) {
            const boldTexts = line.split(/<X>|<\/X>/);
            elements.push({
              type: "text",
              content: boldTexts.map((boldText, index) =>
                index % 2 === 1 ? (
                  <Tag minimal intent="primary">
                    {boldText}
                  </Tag>
                ) : (
                  boldText
                )
              ),
            });
          } else if (line.includes("**") || line.includes("_")) {
            elements.push({
              type: "text",
              content: this.processMarkdown(line),
            });
          } else if (line.includes("`")) {
            const inlineCodes = line.split("`");
            elements.push({
              type: "text",
              content: inlineCodes.map((inlineCode, index) =>
                index % 2 === 1 ? <code>{inlineCode}</code> : inlineCode
              ),
            });
          } else {
            elements.push({ type: "text", content: line });
          }
        }
      }
    });

    if (headers.length > 0 && rows.length > 0) {
      elements.push({ type: "table", headers, rows });
    }

    return (
      <div className="colfax">
        {elements.map((element, index) => {
          if (element.type === "table") {
            return (
              <table
                key={index}
                className="bp5-dark bp5-html-table bp5-compact bp5-html-table-bordered bp5-html-table-striped colfax"
              >
                <thead>
                  <tr>
                    {element.headers.map((header, i) => (
                      <th key={i}>{this.processMarkdown(header.trim())}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {element.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>
                          {this.processMarkdown(cell.trim())}
                        </td>
                      ))}{" "}
                    </tr>
                  ))}
                </tbody>
              </table>
            );
          } else if (element.type === "text") {
            return (
              <p key={index} className="colfax">
                {element.content}
              </p>
            );
          } else if (element.type === "code") {
            return (
              <Callout key={index} title={element.title} className="colfax">
                <pre>
                  <code>{element.content}</code>
                </pre>
              </Callout>
            );
          } else if (element.type === "codePlaceholder") {
            return (
              <Callout key={index} title={element.title} className="colfax">
                <pre>
                  <code>Loading...</code>
                </pre>
              </Callout>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };
  renderUserMessage = (text) => {
    return (
      <div className="user_message_container colfax">
        <Tag large intent="success" className="turquoise__bg">
          You
        </Tag>
        <div className="chat_bubble user_message">
          {this.renderMessageNicely(text)}
        </div>
      </div>
    );
  };
  filterMarkdownUrls = (text) => {
    const regex = /\[.*\]\(.*\)/g;

    return text.replace(regex, "");
  };
  renderStreamingResponse = () => {
    if (!this.state.isBusy) {
      return null;
    }
    let text = this.state.streamingResponse;

    if (!text || !text.length) {
      return null;
    }
    return <>{this.renderAIMessage(text)}</>;
  };
  renderMessages = () => {
    let messages = this.state.messages;
    if (!messages.length) {
      return <NonIdealState icon="clean" description="Start chatting!" />;
    }
    return messages.map((message) => {
      if (message.role === "user") {
        return this.renderUserMessage(message.content);
      } else {
        return this.renderAIMessage(
          message.content,
          message.type ? message.type : null,
          message.annotations ? message.annotations : []
        );
      }
    });
  };
  startRecording = () => {
    this.setState({ isRecording: true, recordingTime: 0 });
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.recordRTC = RecordRTC(stream, {
          type: "audio",
          disableLogs: true,
        });
        this.recordRTC.startRecording();
        this.startTimer();
      })
      .catch((err) => {
        cogoToast.error("Failed to start recording.");
        console.error(err);
      });
  };
  stopRecording = () => {
    this.setState({ isRecording: false });
    this.stopTimer();
    this.recordRTC.stopRecording(() => {
      let blob = this.recordRTC.getBlob();
      let url = URL.createObjectURL(blob);
      this.setState({ recordedBlob: blob, recordedUrl: url }, () =>
        this.transcribeAudio()
      );
    });
  };
  getIconForMode = (mode) => {
    switch (mode) {
      case "Vision":
        return "media";
      case "Normal":
        return "chat";
      case "Assistants":
        return "helper-management"; // Replace with appropriate icon
      default:
        return "help";
    }
  };
  renderHeader = () => {
    return (
      <Card
        elevation={Elevation.FOUR}
        style={{
          padding: "5px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <ControlGroup>
          <Tag large intent="success" className="rose__bg">
            Harley
          </Tag>{" "}
          <Tag large intent="success" className="indigo__bg">
            Your Medical AI Assistant
          </Tag>
        </ControlGroup>
        <ControlGroup>
          {this.props.mode === "CLINICAL" ? (
            <ControlGroup>
              <Button
                disabled={this.state.isBusy || this.state.isRecording}
                text="Ask for more history"
                className="colfax"
                onClick={() =>
                  this.setState({
                    userInputText:
                      "Give me top 5 questions I can ask this patient to get a better history and narrow down my diagnosis and treatment plan.",
                  })
                }
              />
              <Button
                disabled={this.state.isBusy || this.state.isRecording}
                text="Ask for DDx"
                className="colfax"
                onClick={() =>
                  this.setState({
                    userInputText:
                      "Give me a ranked list of differential diagnosis for this patient, with reasoning.",
                  })
                }
              />
            </ControlGroup>
          ) : (
            <ControlGroup>
              {" "}
              {["Normal", "Vision", "Assistants"].map((mode) => (
                <Button
                  className="colfax"
                  key={mode}
                  icon={this.getIconForMode(mode)}
                  active={this.state.mode === mode}
                  onClick={() => this.changeMode(mode)}
                  text={
                    mode === "Assistants"
                      ? "Data"
                      : mode === "Normal"
                      ? "Text"
                      : "Vision"
                  }
                />
              ))}
              <Tag large intent="warning" minimal>
                This is a research preview. Feedback welcome!
              </Tag>
            </ControlGroup>
          )}
        </ControlGroup>
      </Card>
    );
  };
  startTimer = () => {
    this.timerID = setInterval(() => {
      this.setState({ recordingTime: this.state.recordingTime + 1 });
    }, 1000);
  };
  stopTimer = () => {
    clearInterval(this.timerID);
    this.timerID = null;
  };
  formatTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    return `${minutes}m${seconds}s`;
  };
  renderInputBox = () => {
    const uploadButton = this.state.isVision ? (
      <FileInput
        text="Choose file..."
        onInputChange={this.handleFileUpload}
        disabled={this.state.isBusy || this.state.isRecording}
      />
    ) : null;
    const uploadProgress = this.state.isVision ? (
      <Tag minimal large fill>
        {this.state.uploadProgress === null ? (
          "No file selected"
        ) : this.state.uploadProgress < 100 ? (
          <ProgressBar
            stripes
            intent="warning"
            value={this.state.uploadProgress}
          />
        ) : (
          "Upload complete"
        )}
        {this.state.uploadProgress === 100 && (
          <Button icon="cross" minimal onClick={this.handleFileDelete} />
        )}
      </Tag>
    ) : null;

    return (
      <div style={{ height: "100%" }}>
        <ControlGroup style={{ height: "100%" }}>
          <TextArea
            disabled={this.state.isBusy || this.state.isRecording}
            value={
              this.state.isRecording
                ? `Recording... ${this.formatTime(this.state.recordingTime)}`
                : this.state.userInputText
            }
            onChange={(e) => {
              this.setState({ userInputText: e.target.value });
            }}
            className="colfax"
            fill
            style={{
              height: "100%",
            }}
          />
          <ControlGroup vertical style={{ height: "100%" }}>
            {" "}
            {uploadButton}
            {uploadProgress}
          </ControlGroup>
          <ControlGroup vertical style={{ height: "100%" }}>
            {this.state.isRecording ? (
              <Button
                disabled={this.state.isBusy}
                loading={this.state.isBusy}
                style={{ height: "50%" }}
                icon="stop"
                onClick={() => this.stopRecording()}
              />
            ) : (
              <Button
                loading={this.state.isBusy}
                disabled={this.state.isBusy}
                style={{ height: "50%" }}
                icon="microphone"
                onClick={() =>
                  this.setState({ isRecording: true }, () =>
                    this.startRecording()
                  )
                }
              />
            )}
            <Button
              style={{ height: "50%" }}
              icon="send-message"
              disabled={this.state.isBusy || this.state.isRecording}
              loading={this.state.isBusy}
              onClick={() => this.sendUserMessage()}
            />
          </ControlGroup>
        </ControlGroup>
      </div>
    );
  };
  renderChatArea = () => {
    return (
      <Card
        style={{
          height: "80vh",
          padding: "5px",
          backgroundColor: "#17191a",
          fontSize: "medium",
          overflowY: "scroll",
        }}
        elevation={Elevation.FOUR}
      >
        <div style={{ height: "85%", overflowY: "scroll" }}>
          {this.renderMessages()} {this.renderStreamingResponse()}
        </div>
        <div style={{ height: "15%" }}>{this.renderInputBox()}</div>
      </Card>
    );
  };
  render() {
    if (this.state.isLoading) {
      return (
        <div
          style={{
            width: "100%",
            padding: "20px",
          }}
          className="colfax"
        >
          <Spinner size={20} />
        </div>
      );
    }
    if (this.state.isError) {
      return (
        <div
          style={{
            width: "100%",
            padding: "20px",
          }}
          className="colfax"
        >
          {" "}
          <NonIdealState
            icon="warning-sign"
            title="We ran into an error setting up the AI."
          />
        </div>
      );
    }
    return (
      <div
        style={{
          width: "100%",
          padding: "20px",
        }}
        className="colfax"
      >
        {this.renderHeader()}
        {this.renderChatArea()}
      </div>
    );
  }
}

export default ChatterLanding;
