import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import _ from "lodash";
import SingleSearch from "../../history-wizard/SingleSearch";
import Finding from "../../elems/Finding";

class VFAMeasures extends React.Component {
  getParam = (key) => {
    let data = this.props.data;
    return _.find(data, (each) => each.key === key);
  };
  renderParam = (key, minimal = true, intent = "primary") => {
    let param = this.getParam(key);
    return (
      <ControlGroup>
        <Tag minimal={minimal} large>
          {param.description}
        </Tag>
        <Tag minimal={minimal} intent={intent} large>
          {param.value} {param.unit === "unitless" ? "" : param.unit}
        </Tag>
      </ControlGroup>
    );
  };
  renderInfoBar = () => {
    return (
      <ControlGroup vertical>
        {this.renderParam("vfaTest", false)}
        {this.renderResults()}
      </ControlGroup>
    );
  };
  renderStrategy = () => {
    return (
      <ControlGroup vertical style={{ marginLeft: "5px" }}>
        <ControlGroup>
          {" "}
          <Tag minimal large>
            Test Strategy
          </Tag>
        </ControlGroup>

        {this.renderParam("testStrategy")}
        {this.renderParam("stimulusSize")}
        {this.renderParam("stimulusColor")}
        {this.renderParam("backgroundState")}
        {this.renderParam("ScreeningMode")}
        {this.renderParam("pupilDiameter")}
      </ControlGroup>
    );
  };
  renderReliability = () => {
    return (
      <ControlGroup vertical style={{ marginLeft: "5px" }}>
        <ControlGroup>
          {" "}
          <Tag minimal large>
            Test Reliability
          </Tag>
        </ControlGroup>

        {this.renderParam("fixationMonitor")}
        {this.renderParam("fixationTarget")}
        {this.renderParam("fixationTrials")}
        {this.renderParam("fixationErrors")}
        {this.renderParam(
          "falsePositiveErrors",
          this.getParam("falsePositiveErrors")["value"] >= 15 ? false : true,
          this.getParam("falsePositiveErrors")["value"] >= 15
            ? "danger"
            : "primary"
        )}
        {this.renderParam(
          "falseNegativeErrors",
          this.getParam("falseNegativeErrors")["value"] >= 15 ? false : true,
          this.getParam("falseNegativeErrors")["value"] >= 15
            ? "danger"
            : "primary"
        )}
      </ControlGroup>
    );
  };
  renderResults = () => {
    return (
      <ControlGroup vertical>
        <ControlGroup>
          <Tag minimal large>
            Foveal Response
          </Tag>
          <Tag intent="primary" large>
            {
              _.find(this.props.data, (each) => each.key === "fovealResponse")[
                "value"
              ]
            }{" "}
            dB
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <Tag minimal large>
            Visual Field Index
          </Tag>
          <Tag intent="primary" large>
            {_.find(this.props.data, (each) => each.key === "VFI")["value"] ===
            "None" ? (
              "NA"
            ) : (
              <>
                {_.find(this.props.data, (each) => each.key === "VFI")["value"]}
                %
              </>
            )}
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <Tag minimal large>
            Glaucoma Hemifield Test
          </Tag>
          <Tag intent="primary" large>
            {_.find(this.props.data, (each) => each.key === "GHT")["value"] ===
            "None" ? (
              "NA"
            ) : (
              <>
                {_.find(this.props.data, (each) => each.key === "GHT")["value"]}
              </>
            )}
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <Tag minimal large>
            Pattern Deviation (μ)
          </Tag>
          <Tag intent="primary" large>
            {
              _.find(this.props.data, (each) => each.key === "meanDeviation")[
                "value"
              ]
            }{" "}
            dB [{" "}
            {
              _.find(
                this.props.data,
                (each) => each.key === "meanDeviationProb"
              )["value"]
            }
            ]
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <Tag minimal large>
            Pattern Deviation (σ)
          </Tag>
          <Tag intent="primary" large>
            {
              _.find(
                this.props.data,
                (each) => each.key === "patternStdDeviation"
              )["value"]
            }{" "}
            dB [{" "}
            {
              _.find(
                this.props.data,
                (each) => each.key === "patternStdDeviationProb"
              )["value"]
            }
            ]
          </Tag>
        </ControlGroup>
      </ControlGroup>
    );
  };
  renderFindings = () => {
    if (this.props.humanFindings.length) {
      let hf = this.props.humanFindings;
      return hf.map((each) => {
        let obj = each;
        obj["type"] = "new";
        obj["qualifier"] = each.valueQualifiers;
        obj["item"] = each.description;
        obj["listOfQualifiers"] = each.listOfQualifiers;
        return (
          <Finding
            data={obj}
            duplicate={() => {}}
            remove={this.props.removeHumanMeasure}
            editObjectInside={this.props.editObjectInside}
          />
        );
      });
    }
  };
  renderSearch = () => {
    return (
      <div style={{ marginLeft: "5px" }}>
        <ControlGroup vertical>
          <ControlGroup>
            {" "}
            <Tag large intent="primary">
              Findings
            </Tag>
          </ControlGroup>

          <SingleSearch
            investigationType={this.props.invType}
            placeholder="VFA Observation"
            add={(item) => {
              this.props.addFinding(item);
            }}
          />
        </ControlGroup>
        {this.renderFindings()}
      </div>
    );
  };
  render() {
    if (this.props.data && this.props.data.length) {
      return (
        <div style={{ display: "flex", flexWrap: "wrap", overflow: "scroll" }}>
          {this.renderInfoBar()} {this.renderReliability()}
          {this.renderStrategy()} {this.renderSearch()}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default VFAMeasures;
