import React, { Component } from "react";

class TextWithEllipsis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ellipsis: ".", // Initialize the ellipsis state to a single dot
    };
  }

  componentDidMount() {
    // Start a timer when the component mounts
    this.timerID = setInterval(() => this.updateEllipsis(), 300);
  }

  componentWillUnmount() {
    // Clear the timer when the component unmounts
    clearInterval(this.timerID);
  }

  updateEllipsis() {
    // Update the ellipsis in the state
    this.setState((prevState) => {
      const currentEllipsis = prevState.ellipsis;
      if (currentEllipsis === "...") {
        return { ellipsis: "." };
      } else {
        return { ellipsis: currentEllipsis + "." };
      }
    });
  }

  render() {
    const { text } = this.props; // Get the text from props
    const { ellipsis } = this.state; // Get the current ellipsis from state

    return (
      <div style={{ fontSize: "small" }}>
        {text}
        {ellipsis} {/* Render the text with the ellipsis */}
      </div>
    );
  }
}

export default TextWithEllipsis;
