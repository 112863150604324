import React from "react";
import {
  Collapse,
  ControlGroup,
  Tag,
  Button,
  Card,
  Elevation,
} from "@blueprintjs/core";
import AsyncSelWrap from "../../utils/AsyncSelWrap";
import Medicine from "./Medicine";
import { inject, observer } from "mobx-react";
import { apiCallPost } from "../../fns/util";
import cogoToast from "cogo-toast";
import SuggColl from "../SuggColl";

class PlanMedicines extends React.Component {
  state = {
    collapse: true,
    notesText: null,
    notesTouched: false,
    searching: false,
    searchResults: [],
  };
  renderShort = () => {
    if (!this.props.data || !this.props.data.length) {
      return (
        <Tag minimal large>
          No medicines added.
        </Tag>
      );
    }
    let meds = this.props.data.map((each) => each.brand);
    let txt = meds.join(", ");
    return (
      <Tag minimal large multiline>
        {txt}
      </Tag>
    );
  };
  renderHeading = () => {
    return (
      <ControlGroup>
        <Tag
          large
          intent="primary"
          interactive
          onClick={() => this.setState({ collapse: !this.state.collapse })}
        >
          Medicines
        </Tag>
        <Button minimal icon="help" onClick={() => this.props.onShowTable()} />
        {!this.state.collapse ? (
          this.renderShort()
        ) : (
          <>
            {" "}
            <AsyncSelWrap
              placeholder="Medicines..."
              searching={this.state.searching}
              items={this.state.searchResults}
              selectedItem={null}
              update={(item) => this.search(item)}
              onChange={(item) => {
                let dd = item.data;
                dd["_id_sr"] = item._id;
                dd["type"] = "new";
                dd["checked"] = false;
                this.props.add("medicines", dd);
              }}
            />{" "}
            <Button
              minimal
              icon="trash"
              text="Remove Previous"
              intent="danger"
              onClick={() => this.props.removeAllPrevious()}
            />
          </>
        )}{" "}
      </ControlGroup>
    );
  };
  search = async (string) => {
    if (!string) {
      this.setState({ searchResults: [] });
      return;
    }
    try {
      this.setState({ searching: true });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/unisearch`,
        { prefix: "med", query: string }
      );
      this.setState({ searchResults: res, searching: false });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  renderMedicines = () => {
    if (!this.props.data || !this.props.data.length) {
      return (
        <ControlGroup style={{ marginTop: "10px" }}>
          <Tag minimal large>
            No medicines added.
          </Tag>
        </ControlGroup>
      );
    }
    return this.props.data.map((each) => {
      return (
        <Medicine
          data={each}
          update={(dxId, key, value) => {
            this.props.update("medicines", dxId, key, value);
          }}
          remove={(dxId) => this.props.remove(dxId)}
        />
      );
    });
  };
  render() {
    return (
      <Card
        style={{ padding: "0px", background: "none" }}
        elevation={Elevation.FOUR}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "start",
            justifyContent: "left",
            padding: "10px",
          }}
        >
          {this.renderHeading()}
          <Collapse isOpen={this.state.collapse}>
            <SuggColl
              ana_prefix=""
              prefix="med"
              visitId={this.props.visitId}
              addObjectInside={this.props.addObjectInside}
            />
            {this.renderMedicines()}
          </Collapse>
        </div>
      </Card>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(PlanMedicines));
