import { Tab, Tabs } from "@blueprintjs/core";
import React from "react";
import TemplateLanding from "./TemplateLanding";
import FavouritesLanding from "./FavouritesLanding";

class TemplatesFavourites extends React.Component {
  state = {
    selectedTabId: "TEMPLATES",
  };
  render() {
    return (
      <div
        style={{ width: "100%", padding: "20px" }}
        className="colfax bp5-dark"
      >
        <Tabs
          className="colfax bp5-dark"
          id="TABS_TEMPLATES_FAV"
          selectedTabId={this.state.selectedTabId}
          onChange={(newTabId) => this.setState({ selectedTabId: newTabId })}
        >
          <Tab id="TEMPLATES" panel={<TemplateLanding />} title="Templates" />
          <Tab
            id="FAVOURITES"
            panel={<FavouritesLanding mode="TEMPLATES" />}
            title="Favourites"
          />
        </Tabs>
      </div>
    );
  }
}

export default TemplatesFavourites;
