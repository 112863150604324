export const masterParameterBounds = {
  IOP: [10, 21],
  IPD: [40, 75],
  PBCT: [0, 100],
  STEREOPSIS_BOUND: [-999, 999],
  PTOSIS_DIST_BOUND: [-999, 999],
  NFV_BOUND: [-999, 999],
  PFV_BOUND: [-999, 999],
  VERGENCE_FACILITY_BOUND: [-999, 999],
  MEM_BOUND: [-999, 999],
  NPA_BOUND: [-999, 999],
  NRA_BOUND: [-999, 999],
  PRA_BOUND: [-999, 999],
  NPA_BOUND: [-999, 999],
  NPC_BREAK_BOUND: [-999, 999],
  NPC_RECOVERY_BOUND: [-999, 999],
};
