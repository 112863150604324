import { MultistepDialog, DialogStep } from "@blueprintjs/core";
import React from "react";
import AllergyPanel from "./AllergyPanel";
import BasicsPanel from "./BasicsPanel";
import CRxPanel from "./CRxPanel";
import FamHistPanel from "./FamHistPanel";
import PrevDxPanel from "./PrevDxPanel";
import PrevEyeSxPanel from "./PrevEyeSxPanel";
import SymPanel from "./SymPanel";
import SysDxPanel from "./SysDxPanel";
import SysSxPanel from "./SysSxPanel";

class HistoryWizard extends React.Component {
  /**
   *
   * TO DO: Previous Eye Dx from Current Dx
   * Current Eye Rx
   */
  render() {
    return (
      <div>
        <MultistepDialog
          isOpen={this.props.isOpen}
          canOutsideClickClose={false}
          canEscapeKeyClose={true}
          style={{ width: "90vw" }}
          className="bp5-dark axis"
          icon="automatic-updates"
          initialStepIndex={0}
          title="History and Work Up"
          onClose={() => this.props.onClose()}
          finalButtonProps={{
            onClick: () => {
              this.props.onClose();
            },
          }}
        >
          <DialogStep
            id="basics"
            panel={
              <BasicsPanel
                listDocs={this.props.listDocs}
                data={this.props.data}
                updateScalar={this.props.updateScalar}
                editVisitProps={this.props.editVisitProps}
                onUpdateTop={(id, value) => this.props.updateTop(id, value)}
                onUpdateToday={(id, value) => this.props.updateToday(id, value)}
              />
            }
            title="Start"
            nextButtonProps={{ text: "Start" }}
          />
          <DialogStep
            id="symptoms"
            panel={
              <SymPanel
                visitId={this.props.visitId}
                add={this.props.add}
                update={this.props.update}
                remove={this.props.remove}
                symptoms={this.props.data.todayVisit.symptoms}
              />
            }
            title="Symptoms"
          />
          <DialogStep
            id="crx"
            panel={
              <CRxPanel
                visitId={this.props.visitId}
                add={(whichArray, addToArray) =>
                  this.props.add("prev_medicines", addToArray)
                }
                update={this.props.update}
                removeCrx={this.props.removeCrx}
                prev_medicines={this.props.data.todayVisit.prev_medicines}
              />
            }
            title="Current Medications"
          />
          <DialogStep
            id="prev-dx"
            panel={
              <PrevDxPanel
                visitId={this.props.visitId}
                add={this.props.addArray}
                update={this.props.updateArray}
                removeArray={this.props.removeArray}
                prevEyeDx={this.props.data.prevEyeDx}
              />
            }
            title="Eye Diseases"
          />
          <DialogStep
            id="prev-eye-sx"
            panel={
              <PrevEyeSxPanel
                visitId={this.props.visitId}
                add={this.props.addArray}
                updateArray={this.props.updateArray}
                removeArray={this.props.removeArray}
                sysEyeSx={this.props.data.sysEyeSx}
              />
            }
            title="Eye Surgeries and Procedures"
          />
          {/*     <DialogStep
            id="current-eye-rx"
            panel={<CurrentEyeRxPanel />}
            title="Current Eye Medications"
          /> */}
          <DialogStep
            id="allergies"
            panel={
              <AllergyPanel
                visitId={this.props.visitId}
                add={this.props.addArray}
                allergies={this.props.data.allergies}
                removeArray={this.props.removeArray}
              />
            }
            title="Allergies"
          />{" "}
          <DialogStep
            id="sys-dx"
            panel={
              <SysDxPanel
                visitId={this.props.visitId}
                add={this.props.addArray}
                sysDx={this.props.data.sysDx}
                update={this.props.updateArray}
                removeArray={this.props.removeArray}
              />
            }
            title="Systemic Diseases"
          />
          <DialogStep
            id="sys-sx"
            panel={
              <SysSxPanel
                visitId={this.props.visitId}
                add={this.props.addArray}
                sysSx={this.props.data.sysSx}
                update={this.props.updateArray}
                removeArray={this.props.removeArray}
              />
            }
            title="Systemic Surgeries"
          />{" "}
          <DialogStep
            id="fam-hist"
            panel={
              <FamHistPanel
                visitId={this.props.visitId}
                famHist={this.props.data.famHist}
                add={this.props.addArray}
                update={this.props.updateArray}
                removeArray={this.props.removeArray}
              />
            }
            title="Family History"
          />
          {/*  <DialogStep
            id="labs"
            panel={<LabsPanel />}
            title="Labs and Reports"
          />{" "} */}
        </MultistepDialog>{" "}
      </div>
    );
  }
}

export default HistoryWizard;
