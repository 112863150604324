import { Tag } from "@blueprintjs/core";
import React from "react";
import { yearFromNow } from "../../fns/util";
import DateEntry from "../../utils/DateEntry";

class Age extends React.Component {
  state = { editingAge: false };
  render() {
    if (this.state.editingAge) {
      return (
        <DateEntry
          date={this.props.dob}
          onUpdate={(date) => {
            this.setState({ editingAge: false });
            this.props.update("dob", date);
          }}
        />
      );
    }
    return (
      <Tag
        minimal
        interactive
        onClick={() => this.setState({ editingAge: true })}
      >
        {yearFromNow(this.props.dob)[0]}&nbsp;
        {yearFromNow(this.props.dob)[1]}
      </Tag>
    );
  }
}

export default Age;
