import React from "react";
import { MenuItem, Button } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";

class MultiSelectWrapper extends React.Component {
  state = { items: [] };
  componentDidMount() {
    this.setState({ items: this.props.selectedItems });
  }
  renderItem = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        className={this.props.isColfax ? "colfax" : "axis"}
        active={modifiers.active}
        icon={this.isItemSelected(item) ? "tick" : "blank"}
        disabled={modifiers.disabled}
        /*  key={item} */
        onClick={handleClick}
        text={this.highlightText(item, query)}
        shouldDismissPopover={false}
      />
    );
  };
  handleTagRemove = (_tag, index) => {
    this.deselectItem(index);
  };
  getSelectedItemIndex = (item) => {
    return this.state.items.indexOf(item);
  };
  isItemSelected = (item) => {
    return this.getSelectedItemIndex(item) !== -1;
  };
  selectItem = (item) => {
    this.selectItems([item]);
  };
  selectItems = (itemArr) => {
    let newSetOfSelectedItems = this.state.items;
    this.setState({});
    itemArr.forEach((item) => {
      newSetOfSelectedItems = this.addItemToArray(this.state.items, item);
    });
    this.setState({ items: newSetOfSelectedItems }, () => {
      this.props.onChange(this.state.items);
    });
  };
  deselectItem = (index) => {
    let list = this.state.items;
    list = list.slice(0, index).concat(list.slice(index + 1));
    this.setState(
      {
        items: list,
      },
      () => this.props.onChange(this.state.items)
    );
  };

  handleItemSelect = (item) => {
    if (!this.isItemSelected(item)) {
      this.selectItem(item);
    } else {
      this.deselectItem(this.getSelectedItemIndex(item));
    }
  };
  handleItemsPaste = (items) => {
    this.selectItems(items);
  };

  renderCreateOption = (query, active, handleClick) => (
    <MenuItem
      icon="add"
      text={`Add "${query}"`}
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );

  filterItem = (query, item) => {
    /** Implement Any Random Search Here which returns a Boolean */
    /** Correspondingly IF you want aesthetics, change `highlightText` */
    const normalizedQuery = query.toLowerCase();
    const normalizedItem = item.toLowerCase();
    return `${normalizedItem}`.indexOf(normalizedQuery) >= 0;
  };

  highlightText = (text, query) => {
    let lastIndex = 0;
    const words = query
      .split(/\s+/)
      .filter((word) => word.length > 0)
      .map(this.escapeRegExpChars);
    if (words.length === 0) {
      return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens = [];
    while (true) {
      const match = regexp.exec(text);
      if (!match) {
        break;
      }
      const length = match[0].length;
      const before = text.slice(lastIndex, regexp.lastIndex - length);
      if (before.length > 0) {
        tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
      tokens.push(rest);
    }
    return tokens;
  };

  escapeRegExpChars = (text) => {
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
  };

  createItem = (text) => {
    return text;
  };

  /*   areItemsEqual = (itemA, itemB) => {
    return itemA.toLowerCase() === itemB.toLowerCase();
  }; */

  doesItemEqualQuery = (query, item) => {
    return item.toLowerCase() === query.toLowerCase();
  };

  arrayContainsItem = (array, item) => {
    return array.some((each) => each === item);
  };

  addItemToArray = (array, item) => {
    return [...array, item];
  };

  deleteItemFromArray = (array, item) => {
    return array.filter((each) => each !== item);
  };

  maybeAddCreatedItemToArray = (items, createdItems, item) => {
    let isNewlyCreatedItem = !this.arrayContainsItem(this.props.items, item);
    return {
      createdItems: isNewlyCreatedItem
        ? this.addItemToArray(createdItems, item)
        : createdItems,
      items: isNewlyCreatedItem ? this.addItemToArray(items, item) : item,
    };
  };

  maybeDeleteCreatedItemFromArray = (items, createdItems, item) => {
    let wasItemCreatedByUser = this.arrayContainsItem(createdItems, item);
    return {
      createdItems: wasItemCreatedByUser
        ? this.deleteItemFromArray(createdItems, item)
        : createdItems,
      items: wasItemCreatedByUser
        ? this.deleteItemFromArray(items, item)
        : items,
    };
  };
  handleClear = () => {
    this.setState({ items: [] });
  };
  render() {
    let intent = this.props.intent;
    const clearButton = this.state.items ? (
      this.state.items.length > 0 ? (
        <Button icon="cross" minimal={true} onClick={this.handleClear} />
      ) : null
    ) : null;
    return (
      <MultiSelect
        className={this.props.isColfax ? "colfax" : "axis"}
        initialContent={undefined}
        itemPredicate={this.filterItem}
        createNewItemFromQuery={this.createItem}
        createNewItemRenderer={this.renderCreateOption}
        placeholder={
          this.props.placeholder ? this.props.placeholder : "Search..."
        }
        itemRenderer={this.renderItem}
        resetOnSelect
        items={this.props.items}
        noResults={
          <MenuItem
            disabled={true}
            text="No results."
            className={this.props.isColfax ? "colfax" : "axis"}
          />
        }
        onItemSelect={this.handleItemSelect}
        onItemsPaste={this.handleItemsPaste}
        popoverProps={{ minimal: true }}
        tagRenderer={(item) => item}
        selectedItems={this.state.items}
        tagInputProps={{
          onRemove: this.handleTagRemove,
          rightElement: clearButton,
          tagProps: {
            minimal: true,
            intent: intent,
          },
        }}
      />
    );
  }
}
export default MultiSelectWrapper;
