import React from "react";
import {
  NonIdealState,
  Tag,
  Tree,
  Button,
  AnchorButton,
  Spinner,
  Popover,
  FormGroup,
  H3,
  TextArea,
  Switch,
  InputGroup,
  Icon,
  Tooltip,
  Alert,
  Dialog,
  ControlGroup,
} from "@blueprintjs/core";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  niceDate,
  apiCallPost,
  copyToClipboard,
  isInPastDateStrict,
  niceTime,
  isDateToday,
  dateFromNow,
  fixUrlPrefix,
  apiUrl,
} from "../fns/util";
import { Redirect } from "react-router-dom";
import { set, get } from "idb-keyval";

import MultiSelectWrapper from "../utils/MultiSelectWrapper";
import cogoToast from "cogo-toast";
import MatchPatient from "../singles/MatchPatient";
import MutEx from "../utils/MutEx";

class BookingViewPanel extends React.Component {
  state = {
    isPatientDetailOpen: false,
    isMatchOpen: false,
    redirect: null,
    newEnquiry: false,
    newEnquiryNumber: null,
    tree: [],
    currentPid: null,
    collapsedNodes: [],
    enquiryText: null,
    enquiryCategories: [],
    cancelCategories: [],
    cancelText: null,
    alertOpen: false,
    currentDocketNo: null,
    showCancellation: false,
    showTouchpoints: false,
  };
  getNewDetails = async (pid) => {
    try {
      cogoToast.loading("Refreshing list of appointments...", {
        position: "bottom-left",
      });
      let v = await apiCallPost(
        `/sidepane/${this.props.authStore.prefix}/previousVisits`,
        {
          PID: pid,
        }
      );
      cogoToast.info("Updated List Of Appointments.", {
        position: "bottom-left",
      });
      this.props.patientStore.patientPreviousVisits = v;
      if (v !== undefined) {
        await set(pid, { previousVisits: v, visitsLastUpdated: new Date() });
      }

      /*  try {
        let cc = await apiCallPost(
          `/clinical/${this.props.authStore.prefix}/sidePane`,
          {
            genericPid: pid,
          }
        );
        this.props.patientStore.clinicalRecordToday = cc.clinicalRecordToday;
        this.props.patientStore.tps = cc.touchpoints;
        this.props.patientStore.glassRxLink = cc.glassRxLink;
        this.props.patientStore.rxLink = cc.rxLink;
        this.props.patientStore.visitDone = cc.visitDone;
      } catch (err) {
        console.log(err);
        cogoToast.error("There was an error updating clinical record.", {
          position: "bottom-left",
        });
      } */
    } catch (err) {
      console.log(err);
      cogoToast.error(
        "There was an error updating the list of appointments automatically.",
        { position: "bottom-left" }
      );
    }
  };
  renderChildLabel = (each) => {
    if (each.Status === "CANCELLED") {
      let cancellationString = "No Reason Registered";
      if (each.CancelReason.length) {
        cancellationString = each.CancelReason.join(", ");
      }
      return (
        <>
          <Tag minimal>
            {each.Type === "INVESTIGATION"
              ? each.InvestigationType
              : each.SubClinic}
          </Tag>
          <Tooltip content={cancellationString} className="bp5-dark" minimal>
            <Button
              disabled
              small
              minimal
              text="Cancelled"
              intent="warning"
              tyle={{ fontSize: "x-small" }}
            />
          </Tooltip>
        </>
      );
    }

    if (each.Status === "DROPOUT") {
      return (
        <>
          <Tag minimal>
            {each.Type === "INVESTIGATION"
              ? each.InvestigationType
              : each.SubClinic}
          </Tag>
          <Tag minimal intent="danger" style={{ fontSize: "x-small" }}>
            Drop Out
          </Tag>
        </>
      );
    }

    if (each.Status === "BOOKED") {
      return (
        <>
          {" "}
          <Tag minimal onClick={() => copyToClipboard(each.DocketNo)}>
            {each.Type === "INVESTIGATION"
              ? each.InvestigationType
              : each.SubClinic}
          </Tag>
          {/*   <Button
            intent="danger"
            minimal
            small
            icon="trash"
            onClick={() =>
              this.setState({
                showCancellation: true,
                currentDocketNo: each.DocketNo,
              })
            }
          /> */}
          {/* <Button
            intent="warning"
            minimal
            small
            icon="edit"
            onClick={() =>
              this.setState({ currentDocketNo: each.DocketNo }, () =>
                this.onReschedule()
              )
            }
          /> */}
        </>
      );
    }

    return (
      <>
        <AnchorButton
          target="_blank"
          href={fixUrlPrefix(each.FileUrl)}
          text={
            each.Type === "INVESTIGATION"
              ? each.InvestigationType
              : each.SubClinic
          }
          onClick={() => (this.props.bookingStore.recordsViewed += 1)}
          minimal
          intent="success"
          small
        />
        {each.GlassFileUrl ? (
          <AnchorButton
            target="_blank"
            href={fixUrlPrefix(each.GlassFileUrl)}
            icon="eye-on"
            minimal
            intent="success"
            small
          />
        ) : null}
      </>
    );
  };
  onReschedule = async () => {
    this.props.bookingStore.newPatient = false;
    this.props.bookingStore.isReschedule = true;
    this.props.bookingStore.rescheduleDocketNo = this.state.currentDocketNo;
    cogoToast.loading("Attempting To Reschedule", { position: "bottom-left" });
    try {
      let res = await apiCallPost(
        `/mgmt/${this.props.authStore.prefix}/details/booking`,
        {
          DocketNo: this.state.currentDocketNo,
        }
      );

      this.props.bookingStore.rescheduleDetails = res;
      cogoToast.success("Proceeding To Rescheduling.", {
        position: "bottom-left",
      });
      this.setState({ redirect: "/booking" });
    } catch (err) {
      cogoToast.error(
        "Something went wrong. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  getIcon = (type, status) => {
    if (status === "DROPOUT") {
      return <Icon icon="warning-sign" intent="danger" />;
    }
    if (status === "CANCELLED") {
      return <Icon icon="delete" intent="warning" />;
    }

    if (status === "REGISTERED") {
      switch (type) {
        case "INVESTIGATION":
          return <Icon icon="media" intent="success" />;

        case "OPD":
          return <Icon icon="prescription" intent="success" />;

        default:
          return <Icon icon="circle" intent="none" />;
      }
    }

    return <Icon icon="circle" intent="none" />;
  };
  getChildren = (events) => {
    if (events && !events.length) {
      return null;
    }

    let childNodes = [];
    _.map(events, (each) => {
      childNodes.push({
        id: uuidv4(),
        icon: this.getIcon(each.Type, each.Status),
        label: this.renderChildLabel(each),
        secondaryLabel: (
          <div style={{ fontSize: "x-small" }}>
            {niceTime(each.Time)} {each.BranchName}
          </div>
        ),
      });
    });

    return childNodes;
  };
  navigateToBilling = (arg) => {
    this.props.billingStore.billingDate = arg;
    this.setState({ redirect: "/billing" });
  };
  txPrevVisits = () => {
    const pv = toJS(this.props.patientStore.patientPreviousVisits);

    let tx = [];
    _.forEach(pv, (value, key) => {
      var obj = {};
      obj["date"] = key;
      obj["events"] = value;
      tx.push(obj);
    });

    tx.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    _.reverse(tx);

    let tree = tx.map((each) => {
      let children = this.getChildren(each.events);
      let date = niceDate(each.date);
      let isToday = isDateToday(each.date);

      let label = isToday ? (
        <ControlGroup>
          {" "}
          <Tag large intent="primary">
            Today: {date}
          </Tag>{" "}
          <Button
            intent={isToday ? "success" : "warning"}
            minimal={!isToday}
            icon="diagnosis"
            onClick={() => this.getVisitsForDate(each.date)}
          />
        </ControlGroup>
      ) : (
        date
      );
      let icon = isInPastDateStrict(each.date) ? "symbol-square" : "square";
      let iconIntent = "none";
      if (isToday) {
        iconIntent = "success";
      } else if (isInPastDateStrict(each.date)) {
        iconIntent = "none";
      } else {
        iconIntent = "warning";
      }
      return {
        id: uuidv4(),
        isExpanded:
          _.indexOf(this.state.collapsedNodes, date) === -1 ? true : false,
        hasCaret: children ? true : false,
        icon: icon,
        label: label,
        secondaryLabel: (
          <ControlGroup>
            {isToday ? null : (
              <Button
                intent={isToday ? "success" : "warning"}
                minimal={!isToday}
                icon="diagnosis"
                onClick={() => this.getVisitsForDate(each.date)}
              />
            )}
            <Button
              minimal={!isToday}
              outlined={!isToday}
              icon={<Icon icon="credit-card" intent={iconIntent} />}
              onClick={() => this.navigateToBilling(each.date)}
            />
          </ControlGroup>
        ),
        childNodes: children,
      };
    });

    return tree;
  };

  getVisitsForDate = async (date) => {
    try {
      cogoToast.loading("Getting visit information...");
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/getAllVisitsOn`,
        {
          genericPid: this.props.patientStore.genericPid,
          onDay: new Date(date),
        }
      );

      if (res.clinicalRecordsPresent) {
        if (res.visits.length) {
          if (res.visits.length === 1) {
            this.setState(
              {
                clinicalVisitId: res.visits[0]._id,
                nVer: res.visits[0].nVer ? res.visits[0].nVer : 0,
              },
              () => this.navigateToClinical()
            );
          } else {
            this.setState({ visitChoices: res.visits, showVisitChooser: true });
          }
        } else {
          cogoToast.error("No visits found.");
          return;
        }
      }
    } catch (err) {
      cogoToast.error("Failed to load visit details");
      console.log(err);
    }
  };
  submitEnquiry = async () => {
    try {
      let payload = {
        new: this.state.newEnquiry,
        newNumber: this.state.newEnquiryNumber || "NA",
        category: this.state.enquiryCategories,
        comments: this.state.enquiryText || "NA",
        whichPatient: this.state.newEnquiry
          ? "NA"
          : this.props.patientStore.genericPid,
        whichName: this.state.newEnquiry
          ? "NA"
          : this.props.patientStore.patientName,
        whichPhone: this.state.newEnquiry
          ? "NA"
          : this.props.patientStore.patientPhone || "No Phone",
        whichAddress: this.state.newEnquiry
          ? "NA"
          : this.props.patientStore.patientAddress || "No Address",
      };
      if (!this.state.newEnquiry) {
        await apiCallPost(
          `/mgmt/${this.props.authStore.prefix}/promote-atlas/patient`,
          this.props.bookingStore.whichPatientInFocus
        );
      }
      cogoToast.loading("Logging enquiry.", { position: "bottom-right" });
      this.props.bookingStore.enquiryEnd = new Date();
      try {
        await apiCallPost(
          `/enquiry/${this.props.authStore.prefix}/addEnquiry`,
          payload
        );
        cogoToast.success("Logged Enquiry.", { position: "bottom-right" });
      } catch (err) {
        cogoToast.error(
          "Something went wrong. Please check the console for more details.",
          { position: "bottom-left" }
        );
        console.log(err);
      }

      /** SEGMENT */
    } catch (err) {
      console.log(err);
    }
  };
  onCancel = () => {
    return (
      <Dialog
        className="bp5-dark dialog"
        isOpen={this.state.showCancellation}
        canEscapeKeyClose
        canOutsideClickClose
        onClose={() => this.setState({ showCancellation: false })}
        title="Cancel Appointment"
      >
        <Alert
          className="bp5-dark"
          cancelButtonText="Go Back"
          confirmButtonText="Cancel Appointment"
          icon="warning-sign"
          intent="danger"
          canEscapeKeyCancel
          canOutsideClickCancel
          isOpen={this.state.alertOpen}
          onCancel={() => this.setState({ alertOpen: false })}
          onConfirm={() => this.submitCancel()}
        >
          <p>Are you sure you want to cancel the appointment?</p>
        </Alert>
        <div className="event-dialog">
          Cancelling Appointment with Booking Reference{" "}
          {this.state.currentDocketNo}
          <br />
          <br />
          <FormGroup
            helperText="Please choose the reasons for cancellation"
            label="Reason"
            labelFor="text-input"
            labelInfo="*"
            inline
          >
            <MultiSelectWrapper
              items={[
                "Booked In Error (Internal)",
                "Booked In Error (Patient)",
                "Medical Issue Resolved",
                "Doctor Unavailability",
                "Equipment Unavailability",
                "Location Unavailability",
                "Location Mismatch",
                "Doctor Mismatch",
                "Specialty Mismatch",
                "Already Visited Same Doctor",
                "Already Visited Different Doctor",
                "Already Visited Different Hospital",
                "Appointment Delay",
                "Too Ill To Attend",
                "Anxiety",
                "Other Medical Issues",
                "Family Concerns",
                "Work Concerns",
                "Financial Conerns",
                "Low Perceived Severity",
                "External Factors",
                "Other",
              ]}
              intent="success"
              placeholder="Search"
              selectedItems={[]}
              onChange={(items) => this.setState({ cancelCategories: items })}
            />
          </FormGroup>
          <br />
          <FormGroup
            helperText="Enter Notes Here."
            label="Comments"
            labelFor="text-input"
            inline
          >
            <TextArea
              onChange={(event) =>
                this.setState({ cancelText: event.target.value })
              }
            />
          </FormGroup>
          <br />
          <br />
          <Button
            fill
            icon="trash"
            text="Cancel Appointment"
            intent="danger"
            onClick={() => this.setState({ alertOpen: true })}
          />
        </div>
      </Dialog>
    );
  };
  updateVisitList = async () => {
    /** PATTERN TO UPDATE ARCHIVE */
    let pid = this.props.patientStore.genericPid; // specific to context
    let patientExistsInIdb = await get(pid);
    if (patientExistsInIdb) {
      if (patientExistsInIdb.previousVisits) {
        // there's something here!
        this.props.patientStore.patientPreviousVisits =
          patientExistsInIdb.previousVisits;

        let lastUpdate = new Date(patientExistsInIdb.visitsLastUpdated);

        let now = new Date();

        if (now.getTime() - lastUpdate.getTime() >= 0 * 60 * 1000) {
          this.getNewDetails(pid);
        }
      } else {
        // call the API now.
        this.props.patientStore.patientPreviousVisits = {};
        this.getNewDetails(pid);
      }
    } else {
      this.props.patientStore.patientPreviousVisits = {};
      this.getNewDetails(pid);
    }
    /** PATTERN END */
  };
  submitCancel = async () => {
    if (!this.state.currentDocketNo) {
      cogoToast.error(
        "There seems to be an error in selecting the right appointment. Please try again.",
        { position: "bottom-left" }
      );
      return;
    }

    if (!this.state.cancelCategories.length) {
      cogoToast.error("Please choose at least one reason for cancellation.", {
        position: "bottom-left",
      });
      return;
    }

    let payload = {
      DocketNo: this.state.currentDocketNo,
      reasonText: this.state.cancelText || "NA",
      reasonCategory: this.state.cancelCategories,
    };

    cogoToast.loading("Cancelling Appointment.", {
      position: "bottom-left",
    });

    try {
      await apiCallPost(
        `/mgmt/${this.props.authStore.prefix}/cancel/booking`,
        payload
      );
      this.setState({ alertOpen: false, showCancellation: false });
      cogoToast.success("Cancelled Appointment", {
        position: "bottom-left",
      });
      this.updateVisitList();
    } catch (err) {
      cogoToast.error(
        "Something went wrong. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  onEnquiry = () => {
    return (
      <div
        style={{ padding: "10px", minWidth: "fit-content" }}
        className="dialog"
      >
        <H3>Enquiry</H3>
        <FormGroup
          helperText="New Patient?"
          label="New?"
          labelFor="text-input"
          inline
        >
          <Switch
            onChange={(e) =>
              this.setState({ newEnquiry: !this.state.newEnquiry })
            }
          />
        </FormGroup>
        {this.state.newEnquiry ? (
          <FormGroup
            helperText="Phone Number"
            label="Phone"
            labelFor="text-input"
            inline
          >
            <InputGroup
              onChange={(event) =>
                this.setState({ newEnquiryNumber: event.target.value })
              }
            />
          </FormGroup>
        ) : null}

        <FormGroup
          helperText="Type of Enquiry"
          label="Category"
          labelFor="text-input"
          inline
        >
          <MultiSelectWrapper
            items={[
              "Mediclaim Desk",
              "Surgery Counselling",
              "Surgery Confirmation",
              "Surgery Cancellation",
              "Clarification on Medicines",
              "Doctor Availability",
              "Hospital Timings",
              "Location of Hospital",
              "Cancellation of Apppointment",
              "Flying Patient Enquiry",
              "No Sound",
              "Other",
            ]}
            intent="success"
            placeholder="Search"
            selectedItems={[]}
            onChange={(items) => this.setState({ enquiryCategories: items })}
          />
        </FormGroup>
        <FormGroup
          helperText="Enter Notes Here."
          label="Comments"
          labelFor="text-input"
          inline
        >
          <TextArea
            onChange={(event) =>
              this.setState({ enquiryText: event.target.value })
            }
          />
        </FormGroup>
        <Button
          fill
          text="Log Query"
          intent="success"
          onClick={() => this.submitEnquiry()}
        />
      </div>
    );
  };
  onBooking = async (isBooking = true) => {
    this.props.bookingStore.bookingStart = new Date();
    this.props.bookingStore.newPatient = false;
    this.props.bookingStore.isReschedule = false;
    this.props.bookingStore.rescheduleDocketNo = null;
    this.props.bookingStore.rescheduleDetails = {};
    try {
      cogoToast.loading("Loading...");

      /** CHECK */
      await apiCallPost(
        `/mgmt/${this.props.authStore.prefix}/promote-atlas/patient`,
        this.props.bookingStore.whichPatientInFocus
      );
    } catch (err) {
      cogoToast.error("Failed to promote patient data.");
      console.log(err);
    }
    let s = this.props.patientStore.genericPid;
    let p = this.props.patientStore.patientPhone;
    let str = s;
    if (s.includes("via")) {
      str = p;
    }
    if (isBooking) {
      this.setState({ redirect: `/booking/${str}` });
    } else {
      this.setState({ redirect: `/patientdetails/${str}` });
    }
  };
  handleNodeClick = (nodeData) => {
    if (_.indexOf(this.state.collapsedNodes, nodeData.label) > -1) {
      let clone = this.state.collapsedNodes;
      _.remove(clone, (each) => each === nodeData.label);
      this.setState({ collapsedNodes: clone });
    } else {
      this.setState({
        collapsedNodes: [...this.state.collapsedNodes, nodeData.label],
      });
    }
  };
  renderMatch = () => {
    return (
      <MatchPatient
        isOpen={this.state.isMatchOpen}
        key={Math.floor(Math.random() * 1000)}
        onClose={() => this.setState({ isMatchOpen: false })}
      />
    );
  };

  renderPanels = () => {
    let p = toJS(this.props.patientStore.panels);
    if (p && p.length) {
      return p.map((each) => {
        return <Tag minimal>{each.panel}</Tag>;
      });
    } else {
      return <Tag minimal>No Insurance Panel Recorded</Tag>;
    }
  };
  renderLegacyLinks = () => {
    let prefix = (this.props.authStore.prefix || "").toLowerCase();
    if (prefix === "nayonika" || prefix === "nctraining") {
      return [
        <br />,
        <AnchorButton
          outlined
          icon="document-share"
          intent="success"
          href={`${apiUrl}/sidepane/${this.props.authStore.prefix}/legacy/${this.props.patientStore.genericPid}`}
          target="_blank"
          text="Legacy Files"
        />,
      ];
    }
    return null;
  };
  navigateToClinical = () => {
    let prefix = "/clinical/";
    if (this.state.nVer === 2) {
      prefix = "/clinical/v2/";
    }
    this.setState({
      redirect:
        prefix +
        this.props.patientStore.genericPid +
        "/" +
        this.state.clinicalVisitId,
    });
  };
  renderTouchPoints = () => {
    let timeline = toJS(this.props.patientStore.tps);
    return timeline.map((each) => {
      return (
        <ControlGroup style={{ marginBottom: "5px" }}>
          <Tag minimal intent="primary">
            {_.capitalize(_.lowerCase(each.type))}
          </Tag>
          <Tag minimal>{each.doneBy}</Tag>
          <Tag minimal>{niceTime(new Date(each.ts))}</Tag>
          <Tag minimal intent="warning">
            {dateFromNow(new Date(each.ts), false)}
          </Tag>
        </ControlGroup>
      );
    });
  };
  /*  renderClinical = () => {
    return (
      <>
        {this.props.patientStore.clinicalRecordToday ? (
          <ControlGroup>
            <Button
              icon="diagnosis"
              text="Clinical Record"
              outlined
              intent="primary"
              rightIcon="arrow-right"
              onClick={() => this.navigateToClinical()}
            />
            <Tag
              minimal
              interactive
              onClick={() => {
                if (this.props.patientStore.clinicalRecordToday) {
                  this.setState({
                    showTouchpoints: !this.state.showTouchpoints,
                  });
                }
              }}
            >
              {this.props.patientStore.clinicalRecordToday
                ? "Has Visit Today"
                : "No Visit Today"}
            </Tag>
            {this.props.patientStore.visitDone ? (
              <>
                <AnchorButton
                  icon="prescription"
                  outlined
                  intent="success"
                  target="_blank"
                  href={fixUrlPrefix(this.props.patientStore.rxLink)}
                />
                <AnchorButton
                  icon="eye-on"
                  outlined
                  intent="success"
                  target="_blank"
                  href={fixUrlPrefix(this.props.patientStore.glassRxLink)}
                />
              </>
            ) : null}
          </ControlGroup>
        ) : null}

        <br />
        <Collapse isOpen={this.state.showTouchpoints}>
          {" "}
          {this.renderTouchPoints()}
        </Collapse>
      </>
    );
  }; */
  renderVisitChoices = () => {
    if (!this.state.showVisitChooser) {
      return null;
    }
    if (!this.state.visitChoices.length) {
      return null;
    }
    let arr = this.state.visitChoices.map(
      (each) => `${each._id}| ${each.doctor} at ${each.branch}`
    );
    let isSelected = this.state.visitChosen
      ? _.filter(
          this.state.visitChoices,
          (each) => each._id === this.state.visitChosen
        ).map((each) => `${each._id}| ${each.doctor} at ${each.branch}`)[0]
      : null;

    return (
      <MutEx
        options={arr}
        selected={isSelected}
        onUpdate={(str) => {
          this.setState({
            visitChosen: _.find(
              this.state.visitChoices,
              (each) => each._id === str.split("|")[0]
            )._id,
            nVer: _.find(
              this.state.visitChoices,
              (each) => each._id === str.split("|")[0]
            ).nVer
              ? _.find(
                  this.state.visitChoices,
                  (each) => each._id === str.split("|")[0]
                ).nVer
              : 0,
          });
        }}
      />
    );
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (!this.props.patientStore.genericPid) {
      return (
        <NonIdealState
          className="bp5-dark"
          title="No Patient Selected"
          icon="search"
          description="Please select a patient to preview."
        />
      );
    }
    let to = toJS(this.props.patientStore.patientPreviousVisits);
    return (
      <div className="booking-view-panel bp5-dark">
        <Alert
          className="axis bp5-dark"
          cancelButtonText="Cancel"
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          confirmButtonText="Navigate To Visit"
          icon="diagnosis"
          intent="success"
          isOpen={this.state.showVisitChooser}
          onCancel={() =>
            this.setState({
              showVisitChooser: false,
              visitChoices: [],
              visitChosen: null,
            })
          }
          onConfirm={() => {
            if (!this.state.visitChosen) {
              cogoToast.error("Please choose a visit!");
              return null;
            }
            this.setState(
              {
                showVisitChooser: false,
                visitChoices: [],
                clinicalVisitId: this.state.visitChosen,
              },
              () => this.navigateToClinical()
            );
          }}
        >
          {_.isEmpty(this.state.visitChoices) ? null : (
            <>
              {" "}
              Which visit do you want to view?
              <br /> <br /> {this.renderVisitChoices()}
            </>
          )}
        </Alert>
        {this.onCancel()}
        {this.renderMatch()}
        <div className="booking-view-large">
          {_.startCase(_.lowerCase(this.props.patientStore.patientName))}
        </div>
        <Tag intent="primary" minimal>
          {this.props.patientStore.genericPid}
        </Tag>{" "}
        <Tag intent="none" minimal>
          {this.props.patientStore.patientPhone || "No Phone Recorded"}
        </Tag>{" "}
        <Button
          minimal
          icon="edit"
          onClick={() => {
            this.onBooking(false);
          }}
        />
        <Button
          minimal
          icon="timeline-events"
          onClick={() => {
            this.onBooking(true);
          }}
        />
        <br />
        {this.props.patientStore.addlSearchTerm
          ? [
              <Tag intent="none" minimal>
                Addl. Search Term: {this.props.patientStore.addlSearchTerm}
              </Tag>,
              <br />,
            ]
          : null}
        <Tag intent="none" minimal>
          {this.props.patientStore.patientAge
            ? `${this.props.patientStore.patientAge} Years`
            : "Unknown"}
        </Tag>{" "}
        <Tag intent="none" minimal>
          {this.props.patientStore.patientSex || "Unknown"}
        </Tag>
        <Popover className="bp5-dark">
          <Button
            minimal
            icon="help"
            /** SEGMENT */
            onClick={() => (this.props.bookingStore.enquiryStart = new Date())}
          />
          {this.onEnquiry()}
        </Popover>
        <Button minimal icon="refresh" onClick={() => this.updateVisitList()} />
        <Button
          minimal
          icon="inner-join"
          disabled={
            !this.props.patientStore.genericPid.includes("via-newchart")
          }
          onClick={() =>
            this.setState({ isMatchOpen: !this.state.isMatchOpen })
          }
        />
        <br />
        {this.renderPanels()}
        {this.renderLegacyLinks()}
        <br /> <br /> {/* {this.renderClinical()} <br /> <br /> */}
        {/** CHECK */}
        {_.isEmpty(to) ? (
          <Spinner size={20} />
        ) : (
          <Tree
            contents={this.txPrevVisits()}
            className="bp5-dark"
            onNodeCollapse={this.handleNodeClick}
            onNodeExpand={this.handleNodeClick}
          />
        )}{" "}
      </div>
    );
  }
}

export default inject(
  "authStore",
  "bookingStore",
  "billingStore",
  "patientStore"
)(observer(BookingViewPanel));
