import React from "react";
import { inject, observer } from "mobx-react";
import {
  AnchorButton,
  Button,
  Card,
  ControlGroup,
  Elevation,
  InputGroup,
  NonIdealState,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import cogoToast from "cogo-toast";
import { apiCallPost, niceDateTime } from "../../fns/util";

class TemplateMother extends React.Component {
  state = {
    isSearch: true,
    isCreatingNew: false,
    isSearching: false,
    newTemplateName: null,
    newTemplateSpeciality: null,
    newTemplateType: null,
    searchName: null,
    searchSpeciality: null,
    searchType: null,
    showOnlyMine: false,
    searchResults: [],
  };
  specialities = [
    "Cataract",
    "Glaucoma",
    "Cornea",
    "Retina",
    "Oculoplasty",
    "Neuro-Ophthalmology",
    "Uveitis",
    "Refractive",
    "General",
  ];
  templateTypes = ["Surgical", "Clinical"];
  createNewTemplate = async () => {
    try {
      this.setState({ isCreatingNew: true });
      let res = await apiCallPost(
        `/clinical-template/${this.props.authStore.prefix}/createTemplate`,
        {
          speciality: this.state.newTemplateSpeciality,
          templateName: this.state.newTemplateName,
          templateType: this.state.newTemplateType,
        }
      );

      this.setState({
        isCreatingNew: false,
        newTemplateName: null,
        newTemplateSpeciality: null,
        isSearch: true,
        searchResults: [res, ...this.state.searchResults],
      });
    } catch (err) {
      this.setState({ isCreatingNew: false });
      cogoToast.error("We ran into an error.");
    }
  };
  searchTemplates = async () => {
    try {
      this.setState({ isSearching: true });
      let res = await apiCallPost(
        `/clinical-template/${this.props.authStore.prefix}/searchTemplates`,
        {
          prefix: this.state.searchName,
          speciality: this.state.searchSpeciality,
          templateType: this.state.newTemplateType,
          showOnlyMine: this.state.showOnlyMine,
        }
      );

      this.setState({
        isSearching: false,
        searchResults: res,
      });
    } catch (err) {
      this.setState({ isSearching: false });
      cogoToast.error("We ran into an error.");
    }
  };
  renderEachTemplateCard = (each) => {
    return (
      <Card elevation={Elevation.FOUR}>
        {" "}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <>
            <ControlGroup>
              <Tag
                large
                intent={
                  each.templateType === "Clinical" ? "success" : "warning"
                }
                icon={each.templateType === "Clinical" ? "prescription" : "cut"}
              >
                {each.templateName}
              </Tag>
              <Tag intent="primary">{each.speciality}</Tag>
            </ControlGroup>
            <ControlGroup>
              <Tag large minimal icon="person">
                {each.createdBy}
              </Tag>
              <Tag minimal>Last updated: {niceDateTime(each.updatedAt)}</Tag>
              <Tag minimal intent="primary">
                # of Uses: {each.nUses}
              </Tag>
              {this.props.mode === "clinical" ? (
                <Button
                  icon="document-share"
                  text="Details"
                  onClickCapture={() => this.props.setTemplateId(each._id)}
                />
              ) : (
                <AnchorButton
                  icon="document-share"
                  text="Details"
                  outlined
                  href={`/templates/${each._id}/edit`}
                  target="_blank noopener noreferrer"
                />
              )}
            </ControlGroup>
          </>
        </div>{" "}
        <br />
        {each.templateDescription ? (
          <p
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              textAlign: "start",
            }}
          >
            {each.templateDescription}
          </p>
        ) : null}
      </Card>
    );
  };
  renderSearchResults = () => {
    if (this.state.isSearching) {
      return (
        <NonIdealState
          icon={<Spinner />}
          title="Searching..."
          description="Searching for templates"
        />
      );
    }
    if (!this.state.searchResults.length) {
      return (
        <NonIdealState
          icon="warning-sign"
          title="No results"
          description="Please search again, or create a new template"
        />
      );
    }
    return this.state.searchResults.map((each) => {
      return (
        <div style={{ marginBottom: "20px" }}>
          {this.renderEachTemplateCard(each)}
        </div>
      );
    });
  };
  renderHeaderCard = () => {
    return (
      <Card elevation={Elevation.FOUR} style={{ marginBottom: "20px" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <ControlGroup>
            <Tag large intent="primary">
              Clinical Templates
            </Tag>{" "}
            <Tag
              large
              minimal
              intent="primary"
              interactive
              onClick={() => this.setState({ isSearch: true })}
            >
              Search
            </Tag>
            {this.state.isSearch ? (
              <>
                {" "}
                <Tag minimal>Name</Tag>
                <InputGroup
                  value={this.state.searchName}
                  onChange={(event) =>
                    this.setState({ searchName: event.target.value })
                  }
                />
                <Tag
                  minimal
                  rightIcon={this.state.searchSpeciality ? "cross" : null}
                  interactive={this.state.searchSpeciality}
                  onClick={() => this.setState({ searchSpeciality: null })}
                >
                  Speciality
                </Tag>
                <SingleSelectWrapper
                  showSelectProp={true}
                  items={this.specialities}
                  selectedItem={this.state.searchSpeciality}
                  onChange={(item) => this.setState({ searchSpeciality: item })}
                />
                <Tag
                  minimal
                  rightIcon={this.state.searchType ? "cross" : null}
                  interactive={this.state.searchType}
                  onClick={() => this.setState({ searchType: null })}
                >
                  Type
                </Tag>
                <SingleSelectWrapper
                  showSelectProp={true}
                  items={this.templateTypes}
                  selectedItem={this.state.searchType}
                  onChange={(item) => this.setState({ searchType: item })}
                />
                <Tag
                  large
                  intent={this.state.showOnlyMine ? "success" : "primary"}
                  interactive
                  onClick={() =>
                    this.setState({ showOnlyMine: !this.state.showOnlyMine })
                  }
                >
                  {this.state.showOnlyMine
                    ? "My Templates Only"
                    : "All Templates"}
                </Tag>
                <Button
                  icon="search"
                  text="Search"
                  loading={this.state.isSearching}
                  onClick={() => this.searchTemplates()}
                />
              </>
            ) : null}
          </ControlGroup>
          {this.props.mode === "clinical" ? null : (
            <ControlGroup>
              <Tag
                large
                minimal
                intent="success"
                interactive
                onClick={() => this.setState({ isSearch: false })}
              >
                New Template
              </Tag>
              {this.state.isSearch ? null : (
                <>
                  {" "}
                  <Tag minimal>Name</Tag>
                  <InputGroup
                    value={this.state.newTemplateName}
                    onChange={(event) =>
                      this.setState({ newTemplateName: event.target.value })
                    }
                  />
                  <Tag minimal>Speciality</Tag>
                  <SingleSelectWrapper
                    showSelectProp={true}
                    items={this.specialities}
                    selectedItem={this.state.newTemplateSpeciality}
                    onChange={(item) =>
                      this.setState({ newTemplateSpeciality: item })
                    }
                  />
                  <Tag minimal>Type</Tag>
                  <SingleSelectWrapper
                    showSelectProp={true}
                    items={this.templateTypes}
                    selectedItem={this.state.newTemplateType}
                    onChange={(item) =>
                      this.setState({ newTemplateType: item })
                    }
                  />
                  {this.state.newTemplateName &&
                  this.state.newTemplateSpeciality &&
                  this.state.newTemplateType ? (
                    <Button
                      onClick={() => this.createNewTemplate()}
                      icon="add"
                      text="Create"
                      loading={this.state.isCreatingNew}
                    />
                  ) : null}
                </>
              )}
            </ControlGroup>
          )}
        </div>
      </Card>
    );
  };
  render() {
    return (
      <div style={{ margin: "20px" }}>
        {this.renderHeaderCard()}
        {this.renderSearchResults()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(TemplateMother));
