import { H3, Tag } from "@blueprintjs/core";
import React from "react";
import FamHist from "../elems/FamHist";
import SuggColl from "../SuggColl";
import SingleSearch from "./SingleSearch";

class FamHistPanel extends React.Component {
  renderTitle = () => {
    return (
      <H3>Does the patient have any family history of inheritable diseases?</H3>
    );
  };
  renderCG = () => {
    return (
      <SingleSearch
        prefix="fh"
        whichArray="famHist"
        add={this.props.add}
        placeholder="Family History"
      />
    );
  };

  renderPrevEyeDx = () => {
    if (!this.props.famHist || !this.props.famHist.length) {
      return (
        <Tag minimal large>
          No family history of diseases added.
        </Tag>
      );
    }
    return this.props.famHist.map((each) => {
      return (
        <FamHist
          data={each}
          onUpdate={(key, value) =>
            this.props.update("famHist", each._id, key, value)
          }
          onRemove={() => this.props.removeArray("famHist", each._id)}
        />
      );
    });
  };
  render() {
    return (
      <div style={{ padding: "20px", height: "100%" }}>
        {this.renderTitle()}
        <br />
        <SuggColl
          ana_prefix=""
          prefix="fh"
          visitId={this.props.visitId}
          addObjectInside={this.props.add}
        />
        {this.renderCG()}
        <br />
        {this.renderPrevEyeDx()}
      </div>
    );
  }
}

export default FamHistPanel;
