import React from "react";
import cogoToast from "cogo-toast";
import { inject, observer } from "mobx-react";
import { apiCallPost } from "../../../fns/util";
import OCTMeasures from "./OCTMeasures";
import _ from "lodash";
import OCTCarousel from "./OCTCarousel";
import OCTCurrent from "./OCTCurrent";
import { NonIdealState, Spinner, Tab, Tabs } from "@blueprintjs/core";

class OCTContainer extends React.Component {
  state = {
    loading: true,
    data: {},
    selectedScanIdx: 0,
    human_measures_applicable: [],
  };
  componentDidMount() {
    this.getData();
    this.getApplicables();
  }
  getData = async () => {
    try {
      this.setState({ loading: true });
      cogoToast.loading("Loading data...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/getInvestigation`,
        { _id: this.props.data._id }
      );
      cogoToast.success("Loaded data.", { position: "bottom-left" });
      this.setState({ loading: false, data: res });
    } catch (err) {
      cogoToast.error(
        "There was an error loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  selectForPrint = async (processedId, status) => {
    try {
      cogoToast.loading("Selecting for print...", { position: "bottom-left" });
      await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/setPrintSelectionStatus`,
        { _id: this.props.data._id, processedId: processedId, status: !status }
      );
      let ss = this.state.data;
      let pp = ss.processed;
      let whichScan = _.findIndex(pp, (each) => each._id === processedId);
      pp[whichScan].selectForPrint = !status;
      ss.processed = pp;
      this.setState({ data: ss });
      cogoToast.success("Done.", { position: "bottom-left" });
    } catch (err) {
      cogoToast.error(
        "There was an error setting data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  getApplicables = async () => {
    try {
      cogoToast.loading("Loading data...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/getApplicableMeasurements`,
        { investigationType: this.props.data.type }
      );
      cogoToast.success("Loaded data.", { position: "bottom-left" });
      this.setState({ human_measures_applicable: res });
    } catch (err) {
      cogoToast.error(
        "There was an error loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  addHumanMeasure = async (item, _id) => {
    try {
      cogoToast.loading("Adding measurement...", { position: "bottom-left" });
      let whichScan = _.find(
        this.state.data.processed,
        (each) => each._id === _id
      );
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/addAnnotation`,
        {
          _id: this.props.data._id,
          kind: item.kind,
          key: item.key,
          value: "0",
          unit: item.unit,
          description: item.description,
          againstId: _id,
          splText: "",
          eye: whichScan.eye,
        }
      );
      cogoToast.success("Added data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd });
    } catch (err) {
      cogoToast.error(
        "There was an error loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  addFinding = async (item, _id) => {
    try {
      cogoToast.loading("Adding finding...", { position: "bottom-left" });
      let whichScan = _.find(
        this.state.data.processed,
        (each) => each._id === _id
      );
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/addAnnotation`,
        {
          _id: this.props.data._id,
          kind: item.payload.kind,
          key: item.payload.key,
          value: "Present",
          unit: item.payload.unit,
          description: item.payload.description,
          valueQualifiers: item.helper,
          againstId: _id,
          splText: "",
          eye: whichScan.eye,
        }
      );
      cogoToast.success("Added data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd }, () => this.updateOCTSummary());
    } catch (err) {
      cogoToast.error(
        "There was an error loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  editHumanMeasure = async (value, item, whichId) => {
    try {
      cogoToast.loading("Editing measurement...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/editAnnotation`,
        {
          annotationId: item._id,
          _id: this.props.data._id,
          kind: item.kind,
          key: item.key,
          value: value.toString(),
          unit: item.unit,
          description: item.description,
          againstId: whichId,
          splText: "",
        }
      );
      cogoToast.success("Edited data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd });
    } catch (err) {
      cogoToast.error(
        "There was an error editing data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  removeHumanMeasure = async (id) => {
    try {
      cogoToast.loading("Removing measurement...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/removeAnnotation`,
        {
          _id: id,
        }
      );
      cogoToast.success("Removed data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd }, () => this.updateOCTSummary());
    } catch (err) {
      cogoToast.error(
        "There was an error removing data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  editObjectInside = async (itemId, whichKey, value, scanId) => {
    try {
      cogoToast.loading("Editing finding...", { position: "bottom-left" });
      let item = _.find(
        this.state.data.annotations,
        (each) => each._id === itemId
      );
      item[whichKey === "qualifier" ? "valueQualifiers" : whichKey] = value;
      let payload = {
        ...item,
        againstId: scanId,
        annotationId: itemId,
        _id: this.props.data._id,
      };
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/editAnnotation`,
        payload
      );
      cogoToast.success("Edited data.", { position: "bottom-left" });
      let dd = this.state.data;
      dd["annotations"] = res;
      this.setState({ data: dd }, () => this.updateOCTSummary());
    } catch (err) {
      cogoToast.error(
        "There was an error editing data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };

  flipImages = async (whichScan) => {
    try {
      cogoToast.loading("Flipping images -- this may take a while...");
      await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/flipOctImages`,
        {
          investigationId: whichScan.linkedInvProc,
          patientId: whichScan.patient,
          visitId: whichScan.visit,
          eye: whichScan.eye,
        }
      );
      cogoToast.success("Flipped images. Now loading images...", {
        position: "bottom-left",
      });
      this.getData();
    } catch (err) {
      cogoToast.error(
        "There was an error flipping the images. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  updateOCTSummary = () => {
    let machineSummary = _.filter(
      this.state.data.annotations,
      (each) => each.key === "OCT_summary"
    );

    if (machineSummary.length === 1) {
      let eyeString = _.concat(
        _.filter(
          this.state.data.annotations,
          (each) => each.kind === "OCT_HUMAN_FINDING"
        ).map(
          (each) =>
            `${each.description} ${
              each.valueQualifiers ? "(" + each.valueQualifiers + ")" : ""
            }`
        ),
        machineSummary[0].value
      ).join(", ");
      this.props.update(
        "INVESTIGATION",
        this.props.data._id,
        "summary",
        eyeString
      );
      return;
    } else {
      let re = _.filter(
        this.state.data.annotations,
        (each) => each.eye === "OD"
      );
      let eyeStringRe = _.concat(
        _.filter(re, (each) => each.kind === "OCT_HUMAN_FINDING").map(
          (each) =>
            `${each.description} ${
              each.valueQualifiers ? "(" + each.valueQualifiers + ")" : ""
            }`
        ),
        _.find(machineSummary, (each) => each.eye === "OD").value
      ).join(", ");

      let le = _.filter(
        this.state.data.annotations,
        (each) => each.eye === "OS"
      );
      let eyeStringLe = _.concat(
        _.filter(le, (each) => each.kind === "OCT_HUMAN_FINDING").map(
          (each) =>
            `${each.description} ${
              each.valueQualifiers ? "(" + each.valueQualifiers + ")" : ""
            }`
        ),
        _.find(machineSummary, (each) => each.eye === "OS").value
      ).join(", ");

      this.props.update(
        "INVESTIGATION",
        this.props.data._id,
        "summary",
        `OD: ${eyeStringRe} | OS: ${eyeStringLe}`
      );
    }
  };

  renderTabsOrNot = () => {
    if (this.state.loading) {
      return null;
    }

    let annotations = this.state.data.annotations;
    let annotationsLE = _.filter(annotations, (each) => each.eye === "OS");
    let annotationsRE = _.filter(annotations, (each) => each.eye === "OD");
    let le_ = annotationsLE.length;
    let re_ = annotationsRE.length;
    let both = false;
    if (le_ && re_) {
      both = true;
    }
    if (both) {
      return (
        <Tabs id="oct" large>
          <Tab
            id="RE"
            title="Right Eye"
            panel={this.renderOneEye(
              annotationsRE,
              _.filter(this.state.data.processed, (each) => each.eye === "OD")
            )}
          />{" "}
          <Tab
            id="LE"
            title="Left Eye"
            panel={this.renderOneEye(
              annotationsLE,
              _.filter(this.state.data.processed, (each) => each.eye === "OS")
            )}
          />
        </Tabs>
      );
    } else {
      let which = "OD";
      let whichA = annotationsRE;
      if (le_) {
        which = "OS";
        whichA = annotationsLE;
      }

      return this.renderOneEye(
        whichA,
        _.filter(this.state.data.processed, (each) => each.eye === which)
      );
    }
  };
  renderOneEye = (annotations, processed) => {
    let dd = _.filter(
      processed,
      (each) => each.kind === "OCT_SEGMENT_VOXEL_SLICE_IMAGE"
    );
    let whichScan = _.find(
      dd,
      (each) =>
        each.sliceIndex ===
        (this.state.selectedScanIdx < dd.length
          ? this.state.selectedScanIdx
          : 0)
    );
    let humanMeasures = _.filter(
      annotations,
      (each) => each.kind === "OCT_HUMAN_MEASUREMENT"
    );
    let humanFindings = _.filter(
      annotations,
      (each) => each.kind === "OCT_HUMAN_FINDING"
    );

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <OCTCurrent
            ssi={
              _.filter(
                annotations,
                (each) => each.kind === "OCT_OPTOVUE_MEASUREMENT"
              )[0]["ssi"] || "NA"
            }
            applicable={this.state.human_measures_applicable}
            addHumanMeasure={(item) =>
              this.addHumanMeasure(item, whichScan._id)
            }
            humanMeasures={_.filter(
              humanMeasures,
              (each) => each.againstId === whichScan._id
            )}
            editHumanMeasure={(value, item) =>
              this.editHumanMeasure(value, item, whichScan._id)
            }
            addFinding={(item) => this.addFinding(item, whichScan._id)}
            humanFindings={_.filter(
              humanFindings,
              (each) => each.againstId === whichScan._id
            )}
            editObjectInside={(itemId, whichKey, value) =>
              this.editObjectInside(itemId, whichKey, value, whichScan._id)
            }
            removeHumanMeasure={this.removeHumanMeasure}
            guiding={_.find(
              processed,
              (each) => each.kind === "OCT_GUIDING_IMAGE"
            )}
            selectForPrint={() =>
              this.selectForPrint(whichScan._id, whichScan.selectForPrint)
            }
            invType={this.props.data.type}
            data={whichScan}
            length={dd.length}
            flipImages={() => this.flipImages(whichScan)}
            onLeft={() =>
              this.setState({
                selectedScanIdx:
                  this.state.selectedScanIdx === 0
                    ? dd.length - 1
                    : this.state.selectedScanIdx - 1,
              })
            }
            onRight={() =>
              this.setState({
                selectedScanIdx:
                  this.state.selectedScanIdx === dd.length - 1
                    ? 0
                    : this.state.selectedScanIdx + 1,
              })
            }
          />
          <OCTCarousel
            data={_.filter(
              processed,
              (each) => each.kind === "OCT_SEGMENT_VOXEL_SLICE_IMAGE"
            )}
            onSetIndex={(idx) => this.setState({ selectedScanIdx: idx })}
          />
        </div>
        <OCTMeasures
          data={_.filter(
            annotations,
            (each) => each.kind === "OCT_OPTOVUE_MEASUREMENT"
          )}
        />
      </>
    );
  };

  render() {
    if (!this.state.data.annotations || !this.state.data.annotations.length) {
      return (
        <div>
          {" "}
          <NonIdealState icon={<Spinner />} title="Loading data..." />
        </div>
      );
    }
    if (this.state.loading) {
      return (
        <div>
          {" "}
          <NonIdealState icon={<Spinner />} title="Loading data..." />
        </div>
      );
    }
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {this.renderTabsOrNot()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(OCTContainer));
