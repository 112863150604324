import React from "react";
import { Collapse, ControlGroup, Tag } from "@blueprintjs/core";

import { inject, observer } from "mobx-react";
import SysInvCard from "./SysInvCard";

class PlanSysInv extends React.Component {
  state = { collapse: true };

  renderShort = () => {
    if (!this.props.data || !this.props.data.length) {
      return (
        <Tag minimal large>
          No systemic investigations added.
        </Tag>
      );
    }
    let inv = this.props.data.map((each) => each.testName);
    let txt = inv.join(", ");
    return (
      <Tag minimal large multiline>
        {txt}
      </Tag>
    );
  };
  renderHeading = () => {
    return (
      <ControlGroup>
        <Tag large intent="primary">
          Systemic Investigations
        </Tag>
        {!this.state.collapse ? (
          this.renderShort()
        ) : (
          <Tag
            icon="plus"
            minimal
            large
            interactive
            onClick={() => this.props.onSearch("sysinv", "sysinv")}
          ></Tag>
        )}
      </ControlGroup>
    );
  };
  renderInvestigations = () => {
    if (!this.props.data || !this.props.data.length) {
      return (
        <ControlGroup style={{ marginTop: "10px" }}>
          <Tag minimal large>
            No systemic investigations added.
          </Tag>
        </ControlGroup>
      );
    }
    return this.props.data.map((each) => {
      return (
        <>
          <SysInvCard
            key={each._id}
            data={each}
            update={(value) =>
              this.props.update("sysinv", each._id, "value", value)
            }
            remove={() => this.props.remove("sysinv", each._id)}
          />
        </>
      );
    });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "start",
          justifyContent: "left",
          padding: `${this.props.horizontal ? "0px" : "10px"}`,
        }}
      >
        {this.renderHeading()}
        <Collapse isOpen={this.state.collapse}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {this.renderInvestigations()}
          </div>
        </Collapse>
      </div>
    );
  }
}
export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(PlanSysInv));
