import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  InputGroup,
  Tag,
  NumericInput,
} from "@blueprintjs/core";
import React from "react";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};
class CaptureRefund extends React.Component {
  state = {
    loading: false,
    site: null,
    channel: null,
    accNo: null,
    ifsc: null,
    reason: null,
    amountPaise: null,
  };
  componentDidMount() {
    this.setState({ amountPaise: this.props.max });
  }
  refundModes = ["Cash", "Card", "UPI", "Bank Transfer"];
  refundReasons = [
    "Billing Error",
    "Cancellation",
    "Internal Referral",
    "Equipment Unavailable",
    "Doctor Unavailable",
    "Change of Doctor",
    "Change of Investigation",
    "Doctor Refused",
    "Patient Refused",
    "Waiting Time",
    "Discount",
    "Service Issue",
    "Insurance Issue",
  ];
  issueRefund = () => {
    let reference = {};
    if (["Cash", "Card", "UPI"].includes(this.state.channel)) {
      reference = { reason: this.state.reason };
    } else {
      reference = {
        reason: this.state.reason,
        ifsc: this.state.ifsc,
        accNo: this.state.accNo,
      };
    }
    let payload = {
      amountPaise: this.state.amountPaise,
      channel: this.state.channel,
      reference: reference,
      site: this.state.site || this.props.sites[0],
    };

    this.props.addRefund(payload);
  };
  issueRefundDebounced = debounce(this.issueRefund, 1000);

  renderDetail = () => {
    if (!this.state.amountPaise) {
      return null;
    }
    if (!this.state.channel) {
      return null;
    }
    if (["Cash", "Card", "UPI"].includes(this.state.channel)) {
      return;
    }

    if (this.state.channel === "Bank Transfer") {
      return (
        <>
          <Tag large minimal>
            Account Number
          </Tag>
          <InputGroup
            onChange={(event) => this.setState({ accNo: event.target.value })}
          />
          <Tag large minimal>
            IFSC Code
          </Tag>
          <InputGroup
            onChange={(event) => this.setState({ ifsc: event.target.value })}
          />
        </>
      );
    }
  };
  renderSiteSelector = () => {
    if (this.props.sites.length >= 2) {
      return (
        <>
          {" "}
          <Tag large minimal>
            Site
          </Tag>
          <SingleSelectWrapper
            className="bp5-dark"
            items={this.props.sites}
            onChange={(item) => {
              this.setState({ site: item });
            }}
          />
        </>
      );
    }
    return null;
  };
  renderAction = () => {
    if (!this.state.channel) {
      return null;
    }
    if (!this.state.reason) {
      return null;
    }
    if (!this.state.amountPaise) {
      return null;
    }
    if (this.props.sites.length >= 2 && !this.state.site) {
      return;
    }
    if (["Cash", "Card", "UPI"].includes(this.state.channel)) {
      return (
        <Button
          loading={this.state.loading}
          rightIcon="arrow-right"
          intent="danger"
          text={`Confirm ${this.state.channel} Refund`}
          onClick={() => {
            this.setState({ loading: true }, () => this.issueRefundDebounced());
          }}
        />
      );
    }
    if (
      this.state.channel === "Bank Transfer" &&
      this.state.accNo &&
      this.state.ifsc
    ) {
      return (
        <Button
          loading={this.state.loading}
          rightIcon="arrow-right"
          intent="warning"
          text="Request Bank Transfer"
          onClick={() => {
            this.setState({ loading: true }, () => this.issueRefundDebounced());
          }}
        />
      );
    }
  };
  render() {
    return (
      <Card
        className="bp5-dark"
        style={{
          backgroundColor: "#464B53",
          margin: "10px",
          textAlign: "left",
        }}
        elevation={Elevation.THREE}
      >
        <ControlGroup>
          <Tag large intent="danger">
            Refund
          </Tag>
          <Tag large minimal>
            Amount
          </Tag>
          <NumericInput
            buttonPosition="none"
            minorStepSize={0.01}
            min={0}
            max={this.props.max / 100}
            clampValueOnBlur
            intent="danger"
            value={this.state.amountPaise / 100}
            onValueChange={(value) =>
              this.setState({ amountPaise: value * 100 })
            }
          />
          {this.renderSiteSelector()}
          <Tag large minimal>
            Channel of Refund
          </Tag>
          <SingleSelectWrapper
            className="bp5-dark"
            items={this.refundModes}
            onChange={(item) => {
              this.setState({ channel: item });
            }}
          />
          {this.renderDetail()}
          <Tag large minimal>
            Reason
          </Tag>
          <SingleSelectWrapper
            className="bp5-dark"
            items={this.refundReasons}
            onChange={(item) => {
              this.setState({ reason: item });
            }}
          />
          {this.renderAction()}
        </ControlGroup>
      </Card>
    );
  }
}

export default CaptureRefund;
