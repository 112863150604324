import {
  Button,
  ControlGroup,
  InputGroup,
  NumericInput,
  Tag,
} from "@blueprintjs/core";

import React from "react";

import { inject, observer } from "mobx-react";

class SysInvCard extends React.Component {
  state = {
    editingEye: false,
    editingSubtype: false,
    viewing: 0,
    touched: false,
    text: "",
    loading: false,
    value: 0,
  };

  renderNotDone = (data) => {
    return (
      <div>
        <ControlGroup
          vertical
          style={{ marginTop: `${this.props.horizontal ? "0px" : "10px"}` }}
        >
          <ControlGroup style={{ maxWidth: "70vw" }}>
            <Tag minimal large intent="primary">
              {data.testName}
            </Tag>
            <Tag minimal onRemove={() => this.props.remove()}>
              {data.modality}
            </Tag>
            {this.props.templateEditing ? null : (
              <>{this.renderDetails(data)}</>
            )}
          </ControlGroup>
        </ControlGroup>
      </div>
    );
  };
  renderDetails = (data) => {
    if (data.typeOfTest === "Boolean") {
      return (
        <Tag
          interactive
          onClick={() => this.props.update(!data.value)}
          large
          minimal={
            data.isPositiveGood
              ? data.value
                ? true
                : false
              : data.value
              ? false
              : true
          }
          intent={
            data.isPositiveGood
              ? data.value
                ? "success"
                : "danger"
              : data.value
              ? "danger"
              : "success"
          }
        >
          {data.value ? "Present/Detected" : "Absent/Not Detected"}
        </Tag>
      );
    }
    if (data.typeOfTest === "Narrative") {
      return (
        <InputGroup
          fill
          value={this.state.touched ? this.state.text : data.value}
          onChange={(event) =>
            this.setState({ text: event.target.value, touched: true })
          }
          rightElement={
            this.state.touched ? (
              <Button
                icon="tick"
                onClick={() => {
                  this.props.update(this.state.text);
                  this.setState({ touched: false });
                }}
              />
            ) : null
          }
        />
      );
    } else if (data.typeOfTest === "Quantitative") {
      let string = "";
      let isInRange = true;
      if (data.unitNormLb && data.unitNormUb) {
        if (data.value >= data.unitNormLb && data.value <= data.unitNormUb) {
          isInRange = true;
        } else {
          isInRange = false;
        }
        string = "Range: " + data.unitNormLb + " to " + data.unitNormUb;
      } else if (data.unitNormLb) {
        if (data.value >= data.unitNormLb) {
          isInRange = true;
        } else {
          isInRange = false;
        }
        string = "Range: More than " + data.unitNormLb;
      } else if (data.unitNormUb) {
        if (data.value <= data.unitNormUb) {
          isInRange = true;
        } else {
          isInRange = false;
        }
        string = "Range: Less than " + data.unitNormUb;
      }
      if (string) {
        if (data.unit) {
          string = string + " " + data.unit;
        }
      }
      return (
        <ControlGroup>
          {string ? (
            <Tag minimal={isInRange} intent={isInRange ? "success" : "danger"}>
              {string}
            </Tag>
          ) : null}
          <NumericInput
            onValueChange={(ignore, valAsString) =>
              this.setState({ touched: true, value: valAsString })
            }
            buttonPosition="none"
            rightElement={data.unit ? <Tag minimal>{data.unit}</Tag> : null}
            value={this.state.touched ? this.state.value : data.value}
          />
          {this.state.touched ? (
            <Button
              icon="tick"
              onClick={() => {
                this.props.update(Number(this.state.value));
                this.setState({ touched: false });
              }}
            />
          ) : null}
        </ControlGroup>
      );
    }
  };

  render() {
    let style = {};
    if (!this.props.horizontal) {
      style = {
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
      };
    }
    return <div style={style}>{this.renderNotDone(this.props.data)}</div>;
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(SysInvCard));
