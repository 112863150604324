import React from "react";
import { MenuItem, Tag } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import _ from "lodash";

class SingleSelectWrapper extends React.Component {
  state = {
    item: null,
  };
  renderItem = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    let label = null;
    let itemSample = this.props.items[0];
    if (typeof itemSample === "string") {
      // do nothing!
    } else if (typeof itemSample === "object") {
      let itemNow = _.find(this.props.items, (each) => each.title === item);
      label = itemNow.type;
    }
    return (
      <MenuItem
        className={this.props.isColfax ? "colfax" : "axis"}
        active={modifiers.active}
        /* icon={this.isItemSelected(item) ? "tick" : "blank"} */
        disabled={modifiers.disabled}
        /*  key={item} */
        onClick={handleClick}
        text={this.highlightText(item, query)}
        shouldDismissPopover={false}
        label={label}
      />
    );
  };
  escapeRegExpChars = (text) => {
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
  };
  highlightText = (text, query) => {
    let lastIndex = 0;
    const words = query
      .split(/\s+/)
      .filter((word) => word.length > 0)
      .map(this.escapeRegExpChars);
    if (words.length === 0) {
      return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens = [];
    while (true) {
      const match = regexp.exec(text);
      if (!match) {
        break;
      }
      const length = match[0].length;
      const before = text.slice(lastIndex, regexp.lastIndex - length);
      if (before.length > 0) {
        tokens.push(before);
      }
      lastIndex = regexp.lastIndex;
      tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
      tokens.push(rest);
    }
    return tokens;
  };
  handleItemSelect = (item) => {
    let itemSample = this.props.items[0];
    if (typeof itemSample === "string") {
      this.setState({ item: item });
      this.props.onChange(item);
    } else if (typeof itemSample === "object") {
      let retItem = _.find(this.props.items, (each) => each.title === item);
      this.setState({ item: item });
      this.props.onChange(retItem);
    } else {
      this.props.onChange("ITEM_INCOMPATIBLE_ERROR");
    }
  };
  filterItem = (query, item) => {
    /** Implement Any Random Search Here which returns a Boolean */
    /** Correspondingly IF you want aesthetics, change `highlightText` */
    const normalizedQuery = query.toLowerCase();
    const normalizedItem = item.toLowerCase();
    return `${normalizedItem}`.indexOf(normalizedQuery) >= 0;
  };

  render() {
    if (!this.props.items || !this.props.items.length) {
      return <Tag minimal>No Items To Search</Tag>;
    }
    let inputProps = {};
    if (this.props.placeholder) {
      inputProps["placeholder"] = this.props.placeholder;
    }
    if (this.props.width) {
      inputProps["style"] = { width: this.props.width };
    }
    if (this.props.autoFocus) {
      inputProps["autoFocus"] = this.props.autoFocus;
    }
    if (this.props.isColfax) {
      inputProps["className"] = "colfax";
      inputProps["inputClassName"] = "colfax";
    } else {
      inputProps["className"] = "axis";
      inputProps["inputClassName"] = "axis";
    }
    let items = [];
    let itemSample = this.props.items[0];
    if (typeof itemSample === "string") {
      // do nothing!
      items = this.props.items;
    } else if (typeof itemSample === "object") {
      items = this.props.items.map((each) => each.title);
    } else {
      return <Tag minimal>Incompatible Item Types</Tag>;
    }

    return (
      <Suggest
        disabled={!!this.props.disabled}
        className={this.props.isColfax ? "colfax" : "axis"}
        inputProps={inputProps}
        inputClassName={this.props.isColfax ? "colfax" : "axis"}
        itemPredicate={this.filterItem}
        items={items}
        noResults={
          <MenuItem disabled={true} text="No results." className="axia" />
        }
        itemRenderer={this.renderItem}
        selectedItem={
          this.props.showSelectProp
            ? this.props.selectedItem
            : this.props.selectedItem || this.state.item || null
        }
        onItemSelect={this.handleItemSelect}
        inputValueRenderer={(item) => item}
        popoverProps={{ minimal: true }}
      />
    );
  }
}

export default SingleSelectWrapper;
