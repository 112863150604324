import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import { initials } from "../../fns/util";
import MutEx from "../../utils/MutEx";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class Symptom extends React.Component {
  state = {
    editingSite: false,
    editingDuration: false,
    editingOnset: false,
    editingProgression: false,
  };
  listOfOnset = ["Sudden", "Gradual"];
  listOfEyes = [
    "Left Eye",
    "Right Eye",
    "Left Eye > Right Eye",
    "Right Eye > Left Eye",
    "Both Eyes",
    "Systemic",
  ];
  listOfDuration = [
    "1 day",
    "1 to 3 days",
    "3 to 7 days",
    "1 to 2 weeks",
    "2 to 4 weeks",
    "1 month to 3 months",
    "3 to 6 months",
    "6 months to 1 year",
    "1 year to 2 years",
    "2 to 5 years",
    "More than 5 years",
    "Since birth",
  ];
  listOfProgression = ["Improving", "Worsening", "Stable", "Fluctuating"];

  renderOldSymptom = (data) => {
    return (
      <ControlGroup style={{ marginTop: "5px" }}>
        <Tag minimal large>
          {initials(data.site)}
        </Tag>
        <Tag
          large
          intent={data.checked ? "primary" : "danger"}
          minimal={data.checked}
          onRemove={() => this.props.remove(data._id)}
        >
          {data.symptom}
        </Tag>
        <Tag
          large
          minimal
          icon="double-chevron-down"
          interactive
          intent={data.sinceLastVisit === "Decreasing" ? "success" : null}
          onClick={() =>
            this.props.update(data._id, "sinceLastVisit", "Decreasing")
          }
        ></Tag>
        <Tag
          large
          minimal
          icon="double-chevron-up"
          interactive
          intent={data.sinceLastVisit === "Increasing" ? "danger" : null}
          onClick={() =>
            this.props.update(data._id, "sinceLastVisit", "Increasing")
          }
        ></Tag>
        <Tag
          large
          minimal
          icon="ring"
          interactive
          intent={data.sinceLastVisit === "Same" ? "warning" : null}
          onClick={() => this.props.update(data._id, "sinceLastVisit", "Same")}
        ></Tag>
        {data.sinceLastVisit ? (
          <Tag minimal large>
            Since Last Visit: {data.sinceLastVisit}
          </Tag>
        ) : null}
        {!data.checked ? (
          <Tag
            large
            minimal
            icon="tick"
            interactive
            onClick={() => this.props.update(data._id, "checked", true)}
          ></Tag>
        ) : null}
      </ControlGroup>
    );
  };
  renderNewSymptom = (data) => {
    return (
      <ControlGroup style={{ marginTop: "5px" }}>
        {this.props.templateEditing ? null : (
          <MutEx
            large={!this.props.isSmall}
            minimal={false}
            options={["RE", "BE", "LE", "RE>LE", "LE>RE", "S"]}
            onUpdate={(option) => {
              this.props.update(data._id, "site", option);
            }}
            selected={data.site}
          />
        )}
        {/* {this.state.editingSite ? (
          <SingleSelectWrapper
            items={this.listOfEyes}
            onChange={(item) => {
              this.props.update(data._id, "site", item);
              this.setState({ editingSite: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large
            interactive
            onClick={() => this.setState({ editingSite: true })}
          >
            {initials(data.site)}
          </Tag>
        )} */}

        <Tag
          large
          intent="primary"
          minimal
          onRemove={() => this.props.remove(data._id)}
        >
          {data.symptom}
        </Tag>

        {this.state.editingOnset ? (
          <SingleSelectWrapper
            items={this.listOfOnset}
            onChange={(item) => {
              this.props.update(data._id, "onset", item);
              this.setState({ editingOnset: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large
            interactive
            onClick={() => this.setState({ editingOnset: true })}
          >
            {data.onset} Onset
          </Tag>
        )}

        {this.state.editingProgression ? (
          <SingleSelectWrapper
            items={this.listOfProgression}
            onChange={(item) => {
              this.props.update(data._id, "progression", item);
              this.setState({ editingProgression: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large
            interactive
            onClick={() => this.setState({ editingProgression: true })}
          >
            {data.progression || "Progression"}
          </Tag>
        )}

        {this.state.editingDuration ? (
          <SingleSelectWrapper
            items={this.listOfDuration}
            onChange={(item) => {
              this.props.update(data._id, "duration", item);
              this.setState({ editingDuration: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large
            interactive
            onClick={() => this.setState({ editingDuration: true })}
          >
            {data.duration || "Duration"}
          </Tag>
        )}
      </ControlGroup>
    );
  };

  renderSymptoms = () => {
    if (this.props.data.type === "old") {
      return this.renderOldSymptom(this.props.data);
    } else {
      return this.renderNewSymptom(this.props.data);
    }
  };

  render() {
    return <div>{this.renderSymptoms()}</div>;
  }
}

export default Symptom;
