import React from "react";
import { Drawer } from "@blueprintjs/core";
import InvContainer from "./InvContainer";

class InvDrawer extends React.Component {
  render() {
    return (
      <Drawer
        isOpen={this.props.showInv}
        onClose={() => {
          this.props.onClose();
        }}
        canEscapeKeyClose
        canOutsideClickClose
        icon="media"
        title="Investigations"
        className="bp5-dark grad axis"
        position="right"
        style={{
          backgroundColor: "#26292c",
          padding: "5px",
          overflow: "scroll",
        }}
        size="95vw"
      >
        <InvContainer
          data={this.props.data}
          updateInv={this.props.updateInv}
          removeInv={this.props.removeInv}
          patientData={this.props.patientData}
          onSearch={this.props.onSearch}
        />
      </Drawer>
    );
  }
}

export default InvDrawer;
