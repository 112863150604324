import { ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class ColourVision extends React.Component {
  state = {
    editingColour: false,
  };
  listOfColour = [
    "Not Tested",
    "Normal",
    "Anomaly",
    "Protanomaly",
    "Deuteranomaly",
    "Protanopia",
    "Deuteranopia",
    "Monochromatopsia",
    "Tritanomaly",
    "Tritanopia",
  ];
  render() {
    return (
      <ControlGroup>
        <Tag large minimal>
          Colour Vision
        </Tag>
        {this.state.editingColour ? (
          <SingleSelectWrapper
            items={this.listOfColour}
            selectedItem={this.props.colourVision}
            onChange={(item) => {
              this.props.updateScalar("colorVision", item);
              this.setState({ editingColour: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large={true}
            intent={
              ["Normal", "Not Tested"].includes(this.props.colourVision)
                ? "primary"
                : "warning"
            }
            interactive
            icon="tint"
            onClick={() => this.setState({ editingColour: true })}
          >
            {this.props.colourVision}
          </Tag>
        )}
      </ControlGroup>
    );
  }
}

export default ColourVision;
