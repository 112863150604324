import React from "react";
import {
  Tag,
  ControlGroup,
  Card,
  Elevation,
  AnchorButton,
  Button,
} from "@blueprintjs/core";

import {
  copyToClipboard,
  fixUrlPrefix,
  niceDate,
  niceDateTime,
} from "../fns/util";

class BillingLedgerItem extends React.Component {
  renderHeadTag = () => {
    switch (this.props.item.kind) {
      case "ADD_CASHLESS_AMOUNT":
        return (
          <Tag large intent="success" round icon="selection">
            Cashless Application
          </Tag>
        );
      case "SET_CASHLESS_APPROVAL":
        return (
          <Tag large intent="success" round icon="bank-account">
            Cashless Approval
          </Tag>
        );
      case "REQUEST_APPROVAL":
        return (
          <Tag large intent="warning" round icon="double-chevron-right">
            Request Approval
          </Tag>
        );
      case "RESET":
        return (
          <Tag large intent="danger" round icon="reset">
            Reset
          </Tag>
        );
      case "APPROVE":
        return (
          <Tag large intent="success" round icon="endorsed">
            Rate Approval
          </Tag>
        );
      case "ADD_PAYMENT":
        return (
          <Tag large intent="success" round icon="dollar">
            Cash Payment
          </Tag>
        );
      case "ADD_REFUND_CASH":
        return (
          <Tag large icon="double-chevron-down" round intent="danger">
            Refund
          </Tag>
        );
      case "ADD_DISCOUNT_GLOBAL":
        return (
          <Tag large icon="percentage" round intent="warning">
            Overall Discount
          </Tag>
        );
      case "ADD_DISCOUNT_LOCAL":
        return (
          <Tag large icon="percentage" round intent="warning">
            Line Item Discount
          </Tag>
        );
      case "ACTIVATE":
        return (
          <Tag large icon="confirm" round intent="primary">
            Activation
          </Tag>
        );
      case "ADD_NOTE":
        return (
          <Tag large icon="annotation" round intent="none">
            Note
          </Tag>
        );
      case "ADD_REIMBURSEMENT":
        return (
          <Tag large intent="success" round icon="bank-account">
            Reimbursement
          </Tag>
        );
      default:
        return null;
    }
  };
  paymentRefs = () => {
    let arg = this.props.item.details.channel;
    if (arg === "Cash") {
      return null;
    }
    if (arg === "Card") {
      return (
        <>
          <Tag large minimal>
            Last 4 Digits
          </Tag>
          <Tag large>{this.props.item.details.reference.last4}</Tag>
          <Tag large minimal>
            Cardholder
          </Tag>
          <Tag large>{this.props.item.details.reference.cardName}</Tag>
        </>
      );
    }

    if (arg === "Cheque") {
      return (
        <>
          <Tag large minimal>
            Cheque Number
          </Tag>
          <Tag large>{this.props.item.details.reference.chqNo}</Tag>
          <Tag large minimal>
            Dated
          </Tag>

          <Tag large>{niceDate(this.props.item.details.reference.chqDate)}</Tag>
        </>
      );
    }

    if (arg === "UPI" || "PayTM") {
      return (
        <>
          <Tag large minimal>
            Linked Phone Number for {arg}
          </Tag>
          <Tag large>{this.props.item.details.reference.phone}</Tag>
        </>
      );
    }
  };
  refundRefs = () => {
    if (["Cash", "Card", "UPI"].includes(this.props.item.details.channel)) {
      return null;
    }
    return (
      <>
        <Tag large minimal>
          Account Number
        </Tag>
        <Tag large>{this.props.item.details.reference.accNo}</Tag>
        <Tag large minimal>
          IFSC Code
        </Tag>
        <Tag large>{this.props.item.details.reference.ifsc}</Tag>
      </>
    );
  };
  renderDetails = () => {
    switch (this.props.item.kind) {
      case "ADD_CASHLESS_AMOUNT":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Service
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.lineItemText}
            </Tag>
            <Tag large minimal>
              Amount
            </Tag>
            <Tag large intent="primary">
              INR {this.props.item.details.amount / 100}
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() => copyToClipboard(this.props.item._id)}
            />
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "SET_CASHLESS_APPROVAL":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Panel
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.panel}
            </Tag>
            <Tag large minimal>
              Reference
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.reference}
            </Tag>
            <Tag large minimal>
              Amount
            </Tag>
            <Tag large intent="primary">
              INR {this.props.item.details.amount / 100}
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() => copyToClipboard(this.props.item._id)}
            />
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "REQUEST_APPROVAL":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Service
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.lineItemText}
            </Tag>
            <Tag large minimal>
              Panel
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.panelText}
            </Tag>
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "RESET":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Service
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.lineItemText}
            </Tag>
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "APPROVE":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Service
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.lineItemText}
            </Tag>
            <Tag large minimal>
              Panel
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.panelText}
            </Tag>
            <Tag large minimal>
              Rate
            </Tag>
            <Tag large intent="primary">
              INR {this.props.item.details.rate / 100}
            </Tag>
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "ADD_PAYMENT":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Amount
            </Tag>
            <Tag large intent="primary">
              INR {this.props.item.details.amount / 100}
            </Tag>
            <Tag large minimal>
              Mode
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.channel}
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() => copyToClipboard(this.props.item._id)}
            />
            {this.paymentRefs()}
            &nbsp;&nbsp;&nbsp;
            <AnchorButton
              icon="document-share"
              minimal
              intent="success"
              text="Receipt"
              href={fixUrlPrefix(this.props.item.details.url)}
              target="_blank"
            />
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "ADD_REFUND_CASH":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Amount
            </Tag>
            <Tag large intent="danger">
              INR {this.props.item.details.amount / 100}
            </Tag>
            <Tag large minimal>
              Mode
            </Tag>
            <Tag large intent="danger">
              {this.props.item.details.channel}
            </Tag>
            {this.refundRefs()}
            &nbsp;&nbsp;&nbsp;
            <AnchorButton
              icon="document-share"
              minimal
              intent="success"
              text="Receipt"
              href={fixUrlPrefix(this.props.item.details.url)}
              target="_blank"
            />
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "ADD_DISCOUNT_GLOBAL":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Authorised By
            </Tag>
            <Tag large intent="warning">
              {this.props.item.details.authorisedBy}
            </Tag>
            <Tag large minimal>
              Amount
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() => copyToClipboard(this.props.item._id)}
            />
            <Tag large intent="warning">
              INR {this.props.item.details.amount / 100}
            </Tag>
            <Tag large minimal>
              Reason
            </Tag>
            <Tag large multiline>
              {this.props.item.details.reason}
            </Tag>
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "ADD_DISCOUNT_LOCAL":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Authorised By
            </Tag>
            <Tag large intent="warning">
              {this.props.item.details.authorisedBy}
            </Tag>
            <Tag large minimal>
              Amount
            </Tag>
            <Tag large intent="warning">
              INR {this.props.item.details.amount / 100}
            </Tag>
            <Button
              minimal
              icon="duplicate"
              onClick={() => copyToClipboard(this.props.item._id)}
            />
            <Tag large minimal>
              For
            </Tag>
            <Tag large intent="warning">
              {this.props.item.details.lineItemText}
            </Tag>
            <Tag large minimal>
              Reason
            </Tag>
            <Tag large multiline>
              {this.props.item.details.reason}
            </Tag>
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "ACTIVATE":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Service
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.lineItemText}
            </Tag>
            <Tag large minimal>
              Reference
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.sysOpdId}
            </Tag>
            &nbsp;&nbsp;&nbsp;
            {this.props.item.details.physRx ? (
              <AnchorButton
                icon="document-share"
                minimal
                intent="success"
                text="Prescription"
                href={fixUrlPrefix(this.props.item.details.physRx)}
                target="_blank"
              />
            ) : null}
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "ADD_NOTE":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Note
            </Tag>
            <Tag
              large
              multiline
              style={{ maxWidth: "75%", lineBreak: "anywhere" }}
            >
              {this.props.item.details.text}
            </Tag>
            &nbsp;&nbsp;&nbsp;
          </>
        );
      case "ADD_REIMBURSEMENT":
        return (
          <>
            {" "}
            &nbsp;&nbsp;&nbsp;
            <Tag large minimal>
              Service
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.lineItemText}
            </Tag>
            <Tag large minimal>
              Amount
            </Tag>
            <Tag large intent="primary">
              INR {this.props.item.details.amount / 100}
            </Tag>
            <Tag large minimal>
              Panel
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.panel}
            </Tag>
            <Tag large minimal>
              Reference
            </Tag>
            <Tag large intent="primary">
              {this.props.item.details.claimId}
            </Tag>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
          </>
        );
      default:
        return null;
    }
  };
  renderUser = () => {
    return (
      <>
        <Tag minimal>{this.props.item.userName}</Tag>
        <Tag minimal>{niceDateTime(this.props.item.ts)}</Tag>
      </>
    );
  };
  render() {
    return (
      <Card
        className="bp5-dark"
        style={{
          backgroundColor: "#464B53",
          margin: "10px",
          textAlign: "left",
        }}
        elevation={Elevation.THREE}
      >
        <ControlGroup>
          {this.renderHeadTag()}
          {this.renderDetails()}
          {this.renderUser()}
        </ControlGroup>
      </Card>
    );
  }
}

export default BillingLedgerItem;
