import React from "react";
import { inject, observer } from "mobx-react";
import {
  Button,
  ControlGroup,
  Tag,
  NumericInput,
  AnchorButton,
  Spinner,
} from "@blueprintjs/core";
import _ from "lodash";
import { toJS } from "mobx";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import cogoToast from "cogo-toast";
import {
  copyToClipboard,
  niceDate,
  isInFutureDateStrict,
  isInPastDateStrict,
  fixUrlPrefix,
} from "../fns/util";

class BillingItem extends React.Component {
  state = {
    // awaitingActivation: false,
    waiting: false,
    ratePanel: null,
    eye: null,
    detail: null,
    estimate: null,
    payor: null,
    cashless: 0,
  };
  investigationEyes = ["Left Eye", "Right Eye", "Both Eyes"];

  panels = ["Self"];
  policies = ["General", "Semi-Private", "Private"];
  panelList = () => {
    let isPast = isInFutureDateStrict(
      this.props.billingStore.billingDate,
      this.props.item.bookedOn
    );
    if (isPast) {
      return this.panels;
    }
    return _.concat(this.panels, ["Self (Flying)"]);
  };
  renderBookedDateTag = () => {
    let isPast = isInFutureDateStrict(
      this.props.billingStore.billingDate,
      this.props.item.bookedOn
    );
    let isPackage =
      this.props.item.packageParentLnItem ||
      this.props.item.kind === "package_parent";

    if (isPast) {
      return (
        <ControlGroup vertical>
          <Tag minimal intent="primary">
            Booked: {niceDate(this.props.item.bookedOn)}
          </Tag>
          <Tag minimal intent="none">
            {this.props.item.site}
          </Tag>
          {isPackage ? (
            <Tag minimal intent="success" icon="box">
              {this.props.item.kind === "package_parent"
                ? "Package"
                : "Part of Package"}
            </Tag>
          ) : null}
        </ControlGroup>
      );
    }
    return (
      <ControlGroup vertical>
        <Tag intent="danger">Flying: {niceDate(this.props.item.bookedOn)}</Tag>{" "}
        <Tag minimal intent="none">
          {this.props.item.site}
        </Tag>
        {isPackage ? (
          <Tag minimal intent="success" icon="box">
            {this.props.item.kind === "package_parent"
              ? "Package"
              : "Part of Package"}
          </Tag>
        ) : null}
      </ControlGroup>
    );
  };
  renderType = () => {
    if (this.props.item.kind === "investigation") {
      return (
        <ControlGroup vertical>
          {" "}
          <Tag large minimal>
            {_.startCase(this.props.item.resource)}
          </Tag>
          {this.renderBookedDateTag()}
        </ControlGroup>
      );
    }
    if (this.props.item.kind === "package_parent") {
      return (
        <ControlGroup vertical>
          {" "}
          <Tag large minimal>
            {_.startCase(this.props.item.resource)}
          </Tag>
          {this.renderBookedDateTag()}
        </ControlGroup>
      );
    }
    if (this.props.item.kind === "consultation") {
      return (
        <ControlGroup vertical>
          <Tag large minimal>
            Consultation
          </Tag>{" "}
          {this.renderBookedDateTag()}
        </ControlGroup>
      );
    }
  };
  renderDetails = () => {
    /**
     *
     * Check if -->
     *     status is Approved --> All good.
     *     status is not approved -->
     *           if date of service > today
     *                allow changes
     *           if not
     *                don't.
     */
    /* if (this.props.item.kind === "consultation") {
      return (
        <ControlGroup vertical>
          <Tag large minimal>
            {this.props.item.resource}
          </Tag>
          {this.props.item.isApptPrecancelled ? (
            <Tag minimal intent="warning" icon="warning-sign">
              Pre-Cancelled
            </Tag>
          ) : null}
          {this.props.item.isApptPostcancelled ? (
            <Tag minimal intent="danger" icon="warning-sign">
              Post-Cancelled
            </Tag>
          ) : null}
        </ControlGroup>
      );
    } */
    if (
      this.props.item.kind === "consultation" &&
      this.props.item.payment.status !== "APPROVED"
    ) {
      let isPast = isInPastDateStrict(this.props.billingStore.billingDate);
      if (isPast) {
        return (
          <ControlGroup vertical>
            <Tag large minimal>
              {this.props.item.resource || "NA"}
            </Tag>
            <Tag large minimal>
              {this.props.item.investigationDetail.specificKind || "NA"}
            </Tag>
          </ControlGroup>
        );
      }
      return (
        <ControlGroup vertical>
          <Tag large minimal>
            {this.props.item.resource}
          </Tag>
          <SingleSelectWrapper
            className="bp5-dark"
            selectedItem={
              this.props.item.investigationDetail.specificKind || null
            }
            items={this.props.item.applicableChoice}
            onChange={(item) => {
              this.setState({ detail: item });
              this.props.onInvEye(this.props.item._id, this.state.eye, item);
            }}
          />
        </ControlGroup>
      );
    }
    if (
      this.props.item.kind === "consultation" &&
      this.props.item.payment.status === "APPROVED"
    ) {
      return (
        <ControlGroup vertical>
          <Tag large minimal>
            {this.props.item.resource}
          </Tag>
          <Tag large minimal>
            {this.props.item.investigationDetail.specificKind}
          </Tag>
        </ControlGroup>
      );
    }
    if (
      this.props.item.kind === "investigation" &&
      (this.props.item.isApptPrecancelled ||
        this.props.item.isApptPostcancelled)
    ) {
      return (
        <ControlGroup vertical>
          <Tag large minimal>
            {this.props.item.referringDoctor || "NA"}
          </Tag>
          <Tag large minimal>
            {this.props.item.investigationDetail.applicable || "NA"}
          </Tag>
          <Tag large minimal>
            {this.props.item.investigationDetail.specificKind || "NA"}
          </Tag>
          {this.props.item.isApptPrecancelled ? (
            <Tag minimal intent="warning" icon="warning-sign">
              Pre-Cancelled
            </Tag>
          ) : null}
          {this.props.item.isApptPostcancelled ? (
            <Tag minimal intent="danger" icon="warning-sign">
              Post-Cancelled
            </Tag>
          ) : null}
        </ControlGroup>
      );
    }

    if (
      this.props.item.kind === "investigation" &&
      this.props.item.payment.status !== "APPROVED"
    ) {
      let isPast = isInPastDateStrict(this.props.billingStore.billingDate);
      if (isPast) {
        return (
          <ControlGroup vertical>
            <Tag large minimal>
              {this.props.item.referringDoctor || "NA"}
            </Tag>
            <Tag large minimal>
              {this.props.item.investigationDetail.applicable || "NA"}
            </Tag>
            <Tag large minimal>
              {this.props.item.investigationDetail.specificKind || "NA"}
            </Tag>
          </ControlGroup>
        );
      }
      return (
        <ControlGroup vertical>
          <SingleSelectWrapper
            className="bp5-dark"
            selectedItem={this.props.item.referringDoctor || null}
            items={this.props.docList}
            onChange={(item) => {
              this.setState({ referredBy: item });
              this.props.onReferred(this.props.item._id, item);
            }}
          />
          <SingleSelectWrapper
            className="bp5-dark"
            selectedItem={
              this.props.item.investigationDetail.applicable || null
            }
            items={this.investigationEyes}
            onChange={(item) => {
              this.setState({ eye: item });
              this.props.onInvEye(this.props.item._id, item, this.state.detail);
            }}
          />
          <SingleSelectWrapper
            className="bp5-dark"
            selectedItem={
              this.props.item.investigationDetail.specificKind || null
            }
            items={this.props.item.applicableChoice}
            onChange={(item) => {
              this.setState({ detail: item });
              this.props.onInvEye(this.props.item._id, this.state.eye, item);
            }}
          />
        </ControlGroup>
      );
    }
    if (
      this.props.item.kind === "investigation" &&
      this.props.item.payment.status === "APPROVED"
    ) {
      return (
        <ControlGroup vertical>
          <Tag large minimal>
            {this.props.item.referringDoctor}
          </Tag>
          <Tag large minimal>
            {this.props.item.investigationDetail.applicable}
          </Tag>
          <Tag large minimal>
            {this.props.item.investigationDetail.specificKind}
          </Tag>
        </ControlGroup>
      );
    }
    if (this.props.item.kind === "package_parent") {
      return (
        <ControlGroup vertical>
          <Tag large minimal>
            {this.props.item.referringDoctor}
          </Tag>
          <Tag large minimal>
            {this.props.item.investigationDetail.applicable}
          </Tag>
          <Tag large minimal>
            {this.props.item.investigationDetail.specificKind}
          </Tag>
        </ControlGroup>
      );
    }
  };
  canConfirm = () => {
    // true ==> disabled
    let scope = toJS(this.props.authStore.scope);

    let ratePanel = this.state.ratePanel || this.props.item.payment.panel;
    if (this.props.item.kind === "investigation") {
      if (!this.props.item.referringDoctor) {
        return true;
      }
      if (!this.props.item.investigationDetail.applicable) {
        return true;
      }
      if (!this.props.item.investigationDetail.specificKind) {
        return true;
      }
    }
    /** for Panel */
    if (!ratePanel) {
      return true;
    }
    let isBillingAllowed = ratePanel.includes("Self");

    if (!scope.includes("mediclaim") && !isBillingAllowed) {
      return true;
    }
    return false;
  };
  renderRatePanel = () => {
    if (this.props.item.kind === "investigation") {
      if (!this.props.item.referringDoctor) {
        return (
          <ControlGroup vertical>
            <Tag large minimal>
              Referring Doctor missing.
            </Tag>
          </ControlGroup>
        );
      }
      if (!this.props.item.investigationDetail.applicable) {
        return (
          <ControlGroup vertical>
            <Tag large minimal>
              Investigation detail missing.
            </Tag>
          </ControlGroup>
        );
      }
      if (!this.props.item.investigationDetail.specificKind) {
        return (
          <ControlGroup vertical>
            <Tag large minimal>
              Investigation detail missing.
            </Tag>
          </ControlGroup>
        );
      }
    }
    let ratePanel = this.state.ratePanel || this.props.item.payment.panel;
    let isPast = isInPastDateStrict(this.props.billingStore.billingDate);
    if (isPast) {
      return (
        <ControlGroup vertical>
          <Tag large minimal>
            {ratePanel || "NA"}
          </Tag>
        </ControlGroup>
      );
    }
    if (this.props.item.payment.status === "NONE") {
      return (
        <ControlGroup vertical>
          <SingleSelectWrapper
            className="bp5-dark"
            selectedItem={ratePanel || null}
            items={this.props.item.applicablePanels}
            onChange={(item) => this.setState({ ratePanel: item })}
          />
          <Button
            minimal
            text="Approval"
            intent="primary"
            rightIcon="arrow-right"
            outlined
            disabled={!ratePanel}
            onClick={() => this.props.onRequest(this.props.item._id, ratePanel)}
          />
        </ControlGroup>
      );
    }
    if (this.props.item.payment.status === "PENDING") {
      return (
        <ControlGroup vertical>
          <SingleSelectWrapper
            className="bp5-dark"
            selectedItem={ratePanel || null}
            items={this.props.item.applicablePanels}
            onChange={(item) => this.setState({ ratePanel: item })}
          />

          <Button
            icon="tick"
            text="Confirm"
            intent="success"
            minimal
            //  disabled={this.canConfirm()}
            onClick={() => this.props.onApprove(this.props.item._id, ratePanel)}
          />
        </ControlGroup>
      );
    }
    if (this.props.item.payment.status === "APPROVED") {
      return (
        <ControlGroup vertical>
          <Tag large minimal intent="primary">
            {this.props.item.payment.panel}
          </Tag>

          <Button
            icon="reset"
            text="Reset"
            minimal
            intent="danger"
            disabled={this.props.item.activated}
            onClick={() => this.props.onReset(this.props.item._id)}
          />
        </ControlGroup>
      );
    }
  };
  editCashless = () => {
    let tpa_wise = this.props.tpa_wise;
    let idx = _.findIndex(
      tpa_wise,
      (each) => each.tpaName === this.props.item.payment.panel
    );

    if (idx < 0) {
      return null;
    }
    let max = _.min([tpa_wise[idx].approved, this.props.item.payment.rate]);

    return (
      <NumericInput
        buttonPosition="none"
        small
        max={max / 100}
        min={0}
        clampValueOnBlur
        onValueChange={(value) => this.setState({ cashless: value * 100 })}
      />
    );
  };
  onEditCashless = () => {
    if (!this.state.editing) {
      this.setState({ editing: true });
    }
    if (this.state.editing) {
      let tpa_wise = this.props.tpa_wise;
      let idx = _.findIndex(
        tpa_wise,
        (each) => each.tpaName === this.props.item.payment.panel
      );
      if (idx < 0) {
        return null;
      }
      let max = _.min([tpa_wise[idx].approved, this.props.item.payment.rate]);

      if (!this.state.cashless) {
        cogoToast.error("Please enter a valid amount.", {
          position: "bottom-left",
        });
        return;
      }
      if (this.state.cashless > max) {
        cogoToast.error("Please enter a valid amount.", {
          position: "bottom-left",
        });
        return;
      }
      this.props.onCashlessUpdate(
        this.props.item._id,
        this.state.cashless,
        this.props.item.payment.panel
      );
      this.setState({ editing: false });
    }
  };
  renderDiscount = () => {
    if (this.props.item.discount && this.props.item.discount.amount) {
      return (
        <ControlGroup>
          <Tag minimal>Discount</Tag>
          <Tag intent="warning" fill>
            INR {this.props.item.discount.amount / 100}
          </Tag>
        </ControlGroup>
      );
    }
    return null;
  };
  renderAmount = () => {
    if (this.props.item.payment.status !== "APPROVED") {
      let isPast = isInPastDateStrict(this.props.billingStore.billingDate);
      if (isPast) {
        return (
          <ControlGroup vertical>
            <Tag large minimal intent="primary">
              {this.props.item.payment.rate
                ? `INR ${this.props.item.payment.rate / 100}`
                : "NA"}
            </Tag>
          </ControlGroup>
        );
      }
      return (
        <>
          <Tag large minimal intent="primary">
            {this.props.item.payment.rate
              ? `INR ${this.props.item.payment.rate / 100}`
              : "Pending"}
          </Tag>
          <Tag small minimal>
            Tentative
          </Tag>
        </>
      );
    }
    let isPast = isInPastDateStrict(this.props.billingStore.billingDate);
    let scope = toJS(this.props.authStore.scope);
    let isMediclaim = scope.includes("mediclaim");
    let isAllowed = isMediclaim && !isPast;
    return (
      <ControlGroup vertical>
        <ControlGroup>
          <Tag minimal>Rate</Tag>
          <Tag intent="primary" fill>
            {this.props.item.payment.base_rate
              ? `INR ${this.props.item.payment.base_rate / 100}`
              : "NA"}
          </Tag>
        </ControlGroup>
        {this.renderDiscount()}
        <ControlGroup>
          <ControlGroup vertical>
            <Tag minimal intent="success">
              Cash
            </Tag>
            <Tag minimal>
              {this.state.editing ? (
                <>
                  {" "}
                  INR{" "}
                  {(this.props.item.payment.rate - this.state.cashless) / 100}
                </>
              ) : (
                <> INR {this.props.item.payment.cash / 100} </>
              )}
            </Tag>
          </ControlGroup>
          <ControlGroup vertical>
            <Tag minimal intent="primary">
              Cashless
            </Tag>
            {this.state.editing ? (
              <>{this.editCashless()}</>
            ) : (
              <Tag minimal>
                {" "}
                {this.props.item.payment.cashless
                  ? `INR ${this.props.item.payment.cashless / 100}`
                  : "NA"}{" "}
              </Tag>
            )}
            {!isAllowed ? (
              <></>
            ) : (
              <Button
                small
                minimal
                icon={this.state.editing ? "tick-circle" : "edit"}
                intent={this.state.editing ? "success" : "none"}
                onClick={() => this.onEditCashless()}
                disabled={
                  this.props.item.payment.panel === "Self" ||
                  this.state.cashless > this.props.item.payment.rate
                }
              />
            )}
          </ControlGroup>
        </ControlGroup>
      </ControlGroup>
    );
  };
  isActivationDisabled = () => {
    if (this.props.unapplied >= this.props.item.payment.cash) {
      return false;
    } else {
      return true;
    }
  };
  renderAction = (isPackageItem) => {
    if (
      this.props.item.isApptPrecancelled ||
      this.props.item.isApptPostcancelled
    ) {
      return null;
    }
    if (this.props.item.payment.status !== "APPROVED") {
      return (
        <Tag small minimal>
          NA
        </Tag>
      );
    }
    if (this.props.activating && this.props.isNew) {
      return <Spinner />;
    }
    if (this.props.item.activated) {
      return (
        <ControlGroup>
          <AnchorButton
            outlined
            icon="document-share"
            intent="success"
            href={fixUrlPrefix(this.props.item.physReceipt)}
            target="_blank"
          />

          {this.props.item.physRx ? (
            <AnchorButton
              outlined
              icon="prescription"
              intent="primary"
              href={fixUrlPrefix(this.props.item.physRx)}
              target="_blank"
            />
          ) : null}
          <Button
            minimal
            icon="duplicate"
            onClick={() => copyToClipboard(this.props.item._id)}
          />
        </ControlGroup>
      );
    }
    let isPast = isInPastDateStrict(this.props.billingStore.billingDate);
    if (isPast) {
      return null;
    }
    let isFuture = isInFutureDateStrict(this.props.billingStore.billingDate);
    if (isFuture) {
      return null;
    }
    if (this.props.awaitingActivation) {
      return <Spinner />;
    }
    // if (isPackageItem) {
    //   return (
    //     <Tag small minimal>
    //       Part of Package
    //     </Tag>
    //   );
    // }
    return (
      <Button
        text="Activate"
        icon="endorsed"
        outlined
        intent="success"
        disabled={this.isActivationDisabled()}
        onClick={() => {
          this.props.onApply(this.props.item._id);
        }}
      />
    );
  };
  renderRow = (isPackageItem) => {
    if (isPackageItem) {
      return (
        <tr className="bp5-dark">
          <td>{this.renderType()}</td>
          <td>{this.renderDetails()}</td>
          <td />
          <td />
          <td>{this.renderAction(isPackageItem)}</td>
        </tr>
      );
    } else {
      return (
        <tr className="bp5-dark">
          <td>{this.renderType()}</td>
          <td>{this.renderDetails()}</td>
          <td>{this.renderRatePanel()}</td>
          <td>{this.renderAmount()}</td>
          <td>{this.renderAction(isPackageItem)}</td>
        </tr>
      );
    }
  };
  render() {
    const isPackageItem =
      this.props.item.kind === "investigation" &&
      !!this.props.item.packageParentLnItemName;
    return this.renderRow(isPackageItem);
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore"
)(observer(BillingItem));
