import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import getDocDefinition from "./docDefinition";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function printDoc(printParams, gridApi, columnApi, typeOfGrid) {
  const docDefinition = getDocDefinition(
    printParams,
    gridApi,
    columnApi,
    typeOfGrid
  );

  pdfMake.createPdf(docDefinition).download();
}

export default printDoc;
