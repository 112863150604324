import React from "react";
import SplitPane from "react-split-pane";
const Pane = require("react-split-pane/lib/Pane");

class SplitPanel extends React.Component {
  render() {
    return (
      <SplitPane
        split="vertical"
        defaultSize={100}
        primary="second"
        pane2Style={{
          display: "flex",
          overflowY: "scroll",
          overflowX: "scroll",
        }}
      >
        <Pane initialSize="75%">
          <div className="large-left-panel">
            {this.props.left ? this.props.left : "No Component"}
          </div>
        </Pane>
        <Pane>
          <div className="small-right-panel">
            {this.props.right ? this.props.right : "No Component"}
          </div>
        </Pane>
      </SplitPane>
    );
  }
}

export default SplitPanel;
