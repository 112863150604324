import { nanoid } from "nanoid";

const masterSprites_Disc = [
  "/images/CLINICAL_SPRITES/ring.png",
  "/images/CLINICAL_SPRITES/cdr.png",
  "/images/CLINICAL_SPRITES/DiscHaemorrhage.png",
  "/images/CLINICAL_SPRITES/ThinningPattern.gif",
  "/images/CLINICAL_SPRITES/SwollenDisc.png",
  "/images/CLINICAL_SPRITES/RPEDetachment.png",
  "/images/CLINICAL_SPRITES/RPEAtrophy.png",
  "/images/CLINICAL_SPRITES/PigmentEpitheliumDetachment.png",
  "/images/CLINICAL_SPRITES/PeripapillaryAtrophy.png",
  "/images/CLINICAL_SPRITES/Papilloedema.png",
  "/images/CLINICAL_SPRITES/PRPPostPole.png",
  "/images/CLINICAL_SPRITES/OpticDiscPit.png",
  "/images/CLINICAL_SPRITES/NewVesselPattern.gif",
  "/images/CLINICAL_SPRITES/NerveFibreDefect.png",
  "/images/CLINICAL_SPRITES/DiscPallor.png",
  "/images/CLINICAL_SPRITES/DiabeticNV.png",
];
const masterSprites = [
  "/images/CLINICAL_SPRITES/VitreousOpacity.png",
  "/images/CLINICAL_SPRITES/VitelliformMacularLesion.png",
  "/images/CLINICAL_SPRITES/VPattern.gif",
  "/images/CLINICAL_SPRITES/Tube.png",
  "/images/CLINICAL_SPRITES/TraumaPattern.gif",
  "/images/CLINICAL_SPRITES/TranslucentPattern.gif",
  "/images/CLINICAL_SPRITES/TractionRetinalDetachment.png",
  "/images/CLINICAL_SPRITES/TrabyFlap.png",
  "/images/CLINICAL_SPRITES/TrabyConjIncision.png",
  "/images/CLINICAL_SPRITES/ThinningPattern.gif",
  "/images/CLINICAL_SPRITES/Telangiectasis.png",
  "/images/CLINICAL_SPRITES/SwollenDisc.png",
  "/images/CLINICAL_SPRITES/SectorPRPPostPole.png",
  "/images/CLINICAL_SPRITES/SectorIridectomy.png",
  "/images/CLINICAL_SPRITES/RetinalVeinOcclusionPostPole.png",
  "/images/CLINICAL_SPRITES/RetinalArteryOcclusionPostPole.png",
  "/images/CLINICAL_SPRITES/RPEDetachment.png",
  "/images/CLINICAL_SPRITES/RPEAtrophy.png",
  "/images/CLINICAL_SPRITES/RK.png",
  "/images/CLINICAL_SPRITES/PreRetinalHaemorrhage.png",
  "/images/CLINICAL_SPRITES/PosteriorSynechia.png",
  "/images/CLINICAL_SPRITES/PostSubcapCataract.png",
  "/images/CLINICAL_SPRITES/PigmentEpitheliumDetachment.png",
  "/images/CLINICAL_SPRITES/PeripapillaryAtrophy.png",
  "/images/CLINICAL_SPRITES/Papilloedema.png",
  "/images/CLINICAL_SPRITES/PSCPattern.gif",
  "/images/CLINICAL_SPRITES/PRPPostPole.png",
  "/images/CLINICAL_SPRITES/PI_hover.png",
  "/images/CLINICAL_SPRITES/PI.png",
  "/images/CLINICAL_SPRITES/PCV.png",
  "/images/CLINICAL_SPRITES/PCIOL.png",
  "/images/CLINICAL_SPRITES/OpticDiscPit.png",
  "/images/CLINICAL_SPRITES/OedemaPatternBullous.gif",
  "/images/CLINICAL_SPRITES/OedemaPattern.gif",
  "/images/CLINICAL_SPRITES/NuclearCataract.png",
  "/images/CLINICAL_SPRITES/NoPattern.gif",
  "/images/CLINICAL_SPRITES/NewVesselPattern.gif",
  "/images/CLINICAL_SPRITES/NerveFibreDefect.png",
  "/images/CLINICAL_SPRITES/Microaneurysm.png",
  "/images/CLINICAL_SPRITES/MeshworkPatternMedium.gif",
  "/images/CLINICAL_SPRITES/MeshworkPatternLight.gif",
  "/images/CLINICAL_SPRITES/MeshworkPatternHeavy.gif",
  "/images/CLINICAL_SPRITES/Malyugin.png",
  "/images/CLINICAL_SPRITES/MacularThickening.png",
  "/images/CLINICAL_SPRITES/MacularHole.png",
  "/images/CLINICAL_SPRITES/MacularGrid.png",
  "/images/CLINICAL_SPRITES/MacularDystrophy.png",
  "/images/CLINICAL_SPRITES/Macroaneurysm.png",
  "/images/CLINICAL_SPRITES/LatticePattern.gif",
  "/images/CLINICAL_SPRITES/LasikFlap.png",
  "/images/CLINICAL_SPRITES/LaserSpot.png",
  "/images/CLINICAL_SPRITES/LaserPattern.gif",
  "/images/CLINICAL_SPRITES/IrisTrauma.png",
  "/images/CLINICAL_SPRITES/IRMA.png",
  "/images/CLINICAL_SPRITES/HardExudate.png",
  "/images/CLINICAL_SPRITES/HardDrusen.png",
  "/images/CLINICAL_SPRITES/Geographic.png",
  "/images/CLINICAL_SPRITES/FuchsPattern.gif",
  "/images/CLINICAL_SPRITES/Fuchs.png",
  "/images/CLINICAL_SPRITES/Freehand.png",
  "/images/CLINICAL_SPRITES/FocalLaser.png",
  "/images/CLINICAL_SPRITES/FibrousProliferation.png",
  "/images/CLINICAL_SPRITES/Episcleritis.png",
  "/images/CLINICAL_SPRITES/EpiretinalMembrane.png",
  "/images/CLINICAL_SPRITES/EndothelialKeratoplasty.png",
  "/images/CLINICAL_SPRITES/Drusen.png",
  "/images/CLINICAL_SPRITES/DiscPallor.png",
  "/images/CLINICAL_SPRITES/DiscHaemorrhage.png",
  "/images/CLINICAL_SPRITES/DiabeticNV.png",
  "/images/CLINICAL_SPRITES/CystoidMacularOedema.png",
  "/images/CLINICAL_SPRITES/Cypass.png",
  "/images/CLINICAL_SPRITES/CryoPattern.gif",
  "/images/CLINICAL_SPRITES/CribriformPatternSmall.gif",
  "/images/CLINICAL_SPRITES/CribriformPattern.gif",
  "/images/CLINICAL_SPRITES/CottonWoolSpot.png",
  "/images/CLINICAL_SPRITES/CorticalCataract.png",
  "/images/CLINICAL_SPRITES/CornealThinning.png",
  "/images/CLINICAL_SPRITES/CornealScar.png",
  "/images/CLINICAL_SPRITES/CornealGraft.png",
  "/images/CLINICAL_SPRITES/Circinate.png",
  "/images/CLINICAL_SPRITES/ChoroidalNaevus.png",
  "/images/CLINICAL_SPRITES/ChoroidalHaemorrhage.png",
  "/images/CLINICAL_SPRITES/CNV.png",
  "/images/CLINICAL_SPRITES/BrownSpotPattern.gif",
  "/images/CLINICAL_SPRITES/BlotHaemorrhage.png",
  "/images/CLINICAL_SPRITES/Bleb.png",
  "/images/CLINICAL_SPRITES/AntSynech.png",
  "/images/CLINICAL_SPRITES/AntPVRPattern.gif",
  "/images/CLINICAL_SPRITES/AngleNV.png",
  "/images/CLINICAL_SPRITES/APattern.gif",
  "/images/CLINICAL_SPRITES/ACMaintainer.png",
  "/images/CLINICAL_SPRITES/ACIOL.png",
];
const masterSprites_Anterior = [
  "/images/CLINICAL_SPRITES/EndothelialKeratoplasty.png",
  "/images/CLINICAL_SPRITES/TrabyFlap.png",
  "/images/CLINICAL_SPRITES/Episcleritis.png",
  "/images/CLINICAL_SPRITES/PI_hover.png",
  "/images/CLINICAL_SPRITES/PI.png",
  "/images/CLINICAL_SPRITES/PCIOL.png",
  "/images/CLINICAL_SPRITES/PostSubcapCataract.png",
  "/images/CLINICAL_SPRITES/NuclearCataract.png",
  "/images/CLINICAL_SPRITES/CorticalCataract.png",
  "/images/CLINICAL_SPRITES/TrabyConjIncision.png",
  "/images/CLINICAL_SPRITES/LasikFlap.png",
  "/images/CLINICAL_SPRITES/CornealThinning.png",
  "/images/CLINICAL_SPRITES/CornealScar.png",
  "/images/CLINICAL_SPRITES/Bleb.png",
  "/images/CLINICAL_SPRITES/OedemaPatternBullous.gif",
  "/images/CLINICAL_SPRITES/OedemaPattern.gif",
  "/images/CLINICAL_SPRITES/RK.png",
  "/images/CLINICAL_SPRITES/Tube.png",
  "/images/CLINICAL_SPRITES/PEE.png",
  "/images/CLINICAL_SPRITES/mucus.png",
  "/images/CLINICAL_SPRITES/Keratoconus.png",
  "/images/CLINICAL_SPRITES/SubconjunctivalHaemorrhage.png",
  "/images/CLINICAL_SPRITES/CornealInfiltrate.png",
  "/images/CLINICAL_SPRITES/SuperficialVessels.png",
  "/images/CLINICAL_SPRITES/EpithelialDefect.png",
  "/images/CLINICAL_SPRITES/CornealGraft.png",
  "/images/CLINICAL_SPRITES/AntSynech.png",
  "/images/CLINICAL_SPRITES/AntPVRPattern.gif",
  "/images/CLINICAL_SPRITES/AngleNV.png",
  "/images/CLINICAL_SPRITES/APattern.gif",
  "/images/CLINICAL_SPRITES/ACMaintainer.png",
  "/images/CLINICAL_SPRITES/ACIOL.png",
];
export const masterDiagramOptions = [
  // Amsler Grid Diagram added by Suckru
  {
    diagramType: "AMSLER_GRID",
    short: "AMSLER",
    nice: "Amsler Grid",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/20_20_GRID_CHART_BG.png",
    stickers: [],
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {},
  },
  // Diplopia Chart added by Suckru 
  // Diplopia Chart fix by Suckru
  {
    diagramType: "DIPLOPIA_CHART",
    short: "DIPLOPIA",
    nice: "Diplopia Chart",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/DiplopiaChart.png",
    stickers: [
      {
        src: "/images/SPRITE_GREEN.png",
        width: 30,
        height: 200,
        thumb: "/images/SPRITE_GREEN_TH.png",
      },
      {
        src: "/images/SPRITE_RED.png",
        width: 30,
        height: 200,
        thumb: "/images/SPRITE_RED_TH.png",
      },
    ],
    annotations: [
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_GREEN.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 400,
        y: 125,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_RED.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 500,
        y: 125,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_GREEN.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 900,
        y: 125,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_RED.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 1000,
        y: 125,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_GREEN.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 1400,
        y: 125,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_RED.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 1500,
        y: 125,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_GREEN.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 400,
        y: 450,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_RED.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 500,
        y: 450,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_GREEN.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 900,
        y: 450,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_RED.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 1000,
        y: 450,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_GREEN.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 1400,
        y: 450,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_RED.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 1500,
        y: 450,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_GREEN.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 400,
        y: 775,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_RED.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 500,
        y: 775,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_GREEN.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 900,
        y: 775,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_RED.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 1000,
        y: 775,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_GREEN.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 1400,
        y: 775,
        id: nanoid(),
        opacity: 1,
      },
      {
        backgroundColor: [0, 0, 0, 0],
        width: 30,
        height: 200,
        aspectRatio: 0.15,
        backgroundImage: "/images/SPRITE_RED.png",
        disableStyle: [
          "cornerRadius",
          "backgroundColor",
          "strokeColor",
          "strokeWidth",
        ],
        x: 1500,
        y: 775,
        id: nanoid(),
        opacity: 1,}
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_GREEN.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 75,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_RED.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 135,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_GREEN.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 325,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_RED.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 135 + 250,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_GREEN.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 75 + 250 + 250,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_RED.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 135 + 250 + 250,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_GREEN.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 75,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_RED.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 135,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_GREEN.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 325,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_RED.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 135 + 250,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_GREEN.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 75 + 250 + 250,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
      // {
      //   backgroundColor: [0, 0, 0, 0],
      //   width: 30,
      //   height: 200,
      //   aspectRatio: 0.15,
      //   backgroundImage: "/images/SPRITE_RED.png",
      //   disableStyle: [
      //     "cornerRadius",
      //     "backgroundColor",
      //     "strokeColor",
      //     "strokeWidth",
      //   ],
      //   x: 135 + 250 + 250,
      //   y: 25 + 250 + 250,
      //   id: nanoid(),
      //   opacity: 1,
      // },
    ],
    utils: ["sticker", "annotate"],
    localeAdditionals: {
      stickerLabel: "Quick Add",
    },
  },
  // EOM Chart added by Suckru
  {
    diagramType: "EOM_CHART",
    short: "EOM",
    nice: "EOM Chart",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/3_ARROW_CHART_BG.png",
    stickers: [
      {
        src: "/images/SPRITE_MINUS_FOUR.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_MINUS_FOUR_TH.png",
      },
      { 
        src: "/images/SPRITE_MINUS_THREE.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_MINUS_THREE_TH.png",
      },
      {
        src: "/images/SPRITE_MINUS_TWO.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_MINUS_TWO_TH.png",
      },
      {
        src: "/images/SPRITE_MINUS_ONE.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_MINUS_ONE_TH.png",
      },
      {
        src: "/images/SPRITE_ZERO.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_ZERO_TH.png",
      },
      {
        src: "/images/SPRITE_PLUS_ONE.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_PLUS_ONE_TH.png",
      },
      {
        src: "/images/SPRITE_PLUS_TWO.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_PLUS_TWO_TH.png",
      },
      {
        src: "/images/SPRITE_PLUS_THREE.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_PLUS_THREE_TH.png",
      },
      {
        src: "/images/SPRITE_PLUS_FOUR.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_PLUS_FOUR_TH.png",
      },
    ],
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {
      stickerLabel: "Quick Add",
    },
  },
  //  PR Grid added by Suckru
  {
    diagramType: "PR_GRID",
    short: "PR_GRID",
    nice: "Perception of Rays",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/X_CHART_BG.png",
    stickers: [
      {
        src: "/images/SPRITE_MINUS.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_MINUS_TH.png",
      },

      {
        src: "/images/SPRITE_PLUS.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_PLUS_TH.png",
      },
    ],
    annotations: [],
    utils: ["sticker", "annotate"],
    localeAdditionals: {
      stickerLabel: "Quick Add",
    },
  },
  // Gonioscopy Diagram added by Suckru
  {
    diagramType: "GONIO",
    short: "GONIO",
    nice: "Gonioscopy",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/X_CHART_BG.png",
    // url: "/images/Gonioscopy Diagram.png",
    stickers: [
      {
        src: "/images/0.png",
        width: 75,
        height: 75,
        thumb: "/images/0_TH.png",
      },
      {
        src: "/images/sl.png",
        width: 75,
        height: 75,
        thumb: "/images/sl_TH.png",
      },
      {
        src: "/images/atm.png",
        width: 75,
        height: 75,
        thumb: "/images/atm_TH.png",
      },
      {
        src: "/images/ptm.png",
        width: 75,
        height: 75,
        thumb: "/images/ptm_TH.png",
      },
      {
        src: "/images/ss.png",
        width: 75,
        height: 75,
        thumb: "/images/ss_TH.png",
      },
      {
        src: "/images/cbb.png",
        width: 75,
        height: 75,
        thumb: "/images/cbb_TH.png",
      },
      
      {
        src: "/images/Pigmentation.png",
        width: 250,
        height: 125,
        thumb: "/images/Pigmentation_TH.png",
      },
      {
        src: "/images/PAS.png",
        width: 75,
        height: 75,
        thumb: "/images/PAS_TH.png",
      },
      {
        src: "/images/Goniosynechiae.png",
        width: 275,
        height: 125,
        thumb: "/images/GonioSynechiae_TH.png",
      },
      {
        src: "/images/Angle_Recession.png",
        width: 275,
        height:125,
        thumb: "/images/Angle_Recession_TH.png",
      },
      {
        src: "/images/SC.png",
        width: 250,
        height: 150,
        thumb: "/images/SC_TH.png",
      },
      {
        src: "/images/FB.png",
        width: 250,
        height: 125,
        thumb: "/images/FB_TH.png",
      },
      {
        src: "/images/Cyst.png",
        width: 100,
        height: 75,
        thumb: "/images/Cyst_TH.png",
      },
    ],
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {
      stickerLabel: "Quick Add",
    },
  },
  // Gonioscopy with Indentation Diagram added by Suckru
  {
    diagramType: "GONIO_INDENT",
    short: "GONIO_INDENT",
    nice: "Gonioscopy with Indentation",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/GonioIndent2.png",
    stickers: [
      {
        src: "/images/0.png",
        width: 75,
        height: 75,
        thumb: "/images/0_TH.png",
      },
      {
        src: "/images/sl.png",
        width: 75,
        height: 75,
        thumb: "/images/sl_TH.png",
      },
      {
        src: "/images/atm.png",
        width: 75,
        height: 75,
        thumb: "/images/atm_TH.png",
      },
      {
        src: "/images/ptm.png",
        width: 75,
        height: 75,
        thumb: "/images/ptm_TH.png",
      },
      {
        src: "/images/ss.png",
        width: 75,
        height: 75,
        thumb: "/images/ss_TH.png",
      },
      {
        src: "/images/cbb.png",
        width: 75,
        height: 75,
        thumb: "/images/cbb_TH.png",
      },
      
      {
        src: "/images/Pigmentation.png",
        width: 250,
        height: 125,
        thumb: "/images/Pigmentation_TH.png",
      },
      {
        src: "/images/PAS.png",
        width: 75,
        height: 75,
        thumb: "/images/PAS_TH.png",
      },
      {
        src: "/images/Goniosynechiae.png",
        width: 275,
        height: 125,
        thumb: "/images/GonioSynechiae_TH.png",
      },
      {
        src: "/images/Angle_Recession.png",
        width: 275,
        height:125,
        thumb: "/images/Angle_Recession_TH.png",
      },
      {
        src: "/images/SC.png",
        width: 250,
        height: 150,
        thumb: "/images/SC_TH.png",
      },
      {
        src: "/images/FB.png",
        width: 250,
        height: 125,
        thumb: "/images/FB_TH.png",
      },
      {
        src: "/images/Cyst.png",
        width: 100,
        height: 75,
        thumb: "/images/Cyst_TH.png",
      },
    ],
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {
      stickerLabel: "Quick Add",
    },
  },
  // Amsler Grid Diagram added by Suckru
  {
    diagramType: "AMSLER_GRID",
    short: "AMSLER",
    nice: "Amsler Grid",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/20_20_GRID_CHART_BG.png",
    stickers: [],
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {},
  },
  // Anterior Segment Diagram added by Suckru
  {
    diagramType: "ANT_SEG",
    short: "ANT_SEG",
    nice: "Anterior Segment",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/AnteriorSegment.png",
    stickers: masterSprites_Anterior.map((x) => {
      return {
        src: x,
        width: 100,
        height: 100,
        thumb: x,
      };
    }),
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {},
  },
  // Posterior Segment Diagram added by Suckru
  {
    diagramType: "POST_SEG",
    short: "POST_SEG",
    nice: "Posterior Segment",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/POST_SEG_BG.png",
    stickers: masterSprites.map((x) => {
      return {
        src: x,
        width: 100,
        height: 100,
      };
    }),
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {},
  },
  // Cornea Diagram added by Suckru
  // Stickers added to Cornea Diagram by Suckru
  {
    diagramType: "CORNEA_DIAGRAM",
    short: "CORNEA",
    nice: "Cornea Diagram",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/CorneaDiagram2.jpg",
    stickers: masterSprites_Anterior.map((x) => {
      return {
        src: x,
        width: 100,
        height: 100,
      };
    }),
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {},
  },
  // Ocular Surface Disorder Diagram added by Suckru
  {
    diagramType: "OCULAR_SURFACE_DISORDER",
    short: "OCULAR_SURFACE",
    nice: "Ocular Surface Disorder",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/OcularSurfaceDisorder(1).png",
    stickers: masterSprites.map((x) => {
      return {
        src: x,
        width: 100,
        height: 100,
      };
    }),
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {},
  },
  // Botox Chart added by Suckru
  {
    diagramType: "BOTOX_CHART",
    short: "BOTOX",
    nice: "Botox Chart",
    hasLaterality: false,
    organ: "MUSCLES",
    url: "/images/BotoxChart(1).png",
    stickers: [],
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {},
  },
  // Socket Diagram added by Suckru
  {
    diagramType: "SOCKET_DIAGRAM",
    short: "Socket Diagram",
    nice: "Socket Diagram",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/SocketDiagram.png",
    stickers: [],
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {},
  },
  // Lid Diagram added by Suckru
  {
    diagramType: "LIDS_DIAGRAM",
    short: "Lid Diagram",
    nice: "Lid Diagram",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/Lids.png",
    stickers: masterSprites.map((x) => {
      return {
        src: x,
        width: 100,
        height: 100,
      };
    }),
    annotations: [],
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {},
  },
  // 9 Gazes Diagram added by Suckru
  {
    diagramType: "9_GAZE_DIAGRAM",
    short: "9GAZEDIAGRAM",
    nice: "9 Gaze Diagram",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/DiplopiaChart.png",
    stickers: [
      {
        src: "/images/SPRITE_MINUS_FOUR.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_MINUS_FOUR_TH.png",
      },
      { 
        src: "/images/SPRITE_MINUS_THREE.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_MINUS_THREE_TH.png",
      },
      {
        src: "/images/SPRITE_MINUS_TWO.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_MINUS_TWO_TH.png",
      },
      {
        src: "/images/SPRITE_MINUS_ONE.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_MINUS_ONE_TH.png",
      },
      {
        src: "/images/SPRITE_ZERO.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_ZERO_TH.png",
      },
      {
        src: "/images/SPRITE_PLUS_ONE.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_PLUS_ONE_TH.png",
      },
      {
        src: "/images/SPRITE_PLUS_TWO.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_PLUS_TWO_TH.png",
      },
      {
        src: "/images/SPRITE_PLUS_THREE.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_PLUS_THREE_TH.png",
      },
      {
        src: "/images/SPRITE_PLUS_FOUR.png",
        width: 75,
        height: 75,
        thumb: "/images/SPRITE_PLUS_FOUR_TH.png",
      },
    ],
    // annotations: [
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 75,
    //     y: 25,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135,
    //     y: 25,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 325,
    //     y: 25,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135 + 250,
    //     y: 25,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 75 + 250 + 250,
    //     y: 25,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135 + 250 + 250,
    //     y: 25,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 75,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 325,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135 + 250,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 75 + 250 + 250,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135 + 250 + 250,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 75,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 325,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135 + 250,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 75 + 250 + 250,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135 + 250 + 250,
    //     y: 25 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 75,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 325,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135 + 250,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 75 + 250 + 250,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135 + 250 + 250,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 75,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 325,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135 + 250,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_GREEN.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 75 + 250 + 250,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    //   {
    //     backgroundColor: [0, 0, 0, 0],
    //     width: 30,
    //     height: 200,
    //     aspectRatio: 0.15,
    //     backgroundImage: "/images/SPRITE_RED.png",
    //     disableStyle: [
    //       "cornerRadius",
    //       "backgroundColor",
    //       "strokeColor",
    //       "strokeWidth",
    //     ],
    //     x: 135 + 250 + 250,
    //     y: 25 + 250 + 250,
    //     id: nanoid(),
    //     opacity: 1,
    //   },
    // ],
    utils: ["sticker", "annotate"],
    localeAdditionals: {
      stickerLabel: "Quick Add",
    },
  },
  // Disc Diagram Added by Suckru
  {
    diagramType: "DISC_DIAGRAM",
    short: "DISC_DIAGRAM",
    nice: "Disc Diagram",
    hasLaterality: true,
    organ: "EYE",
    url: "/images/DiscDiagram2.jpg",
   
    stickers: masterSprites_Disc.map((x) => {
      return {
        
        
        src: x,
        width: 100,
        height: 100,

      };
      
    }),
    utils: ["sticker", "crop", "annotate"],
    localeAdditionals: {
      stickerLabel: "Quick Add",
    },
  },
];
      