import React from "react";
import { ControlGroup, Tag } from "@blueprintjs/core";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class WorthFourDot extends React.Component {
  state = { editingPupil: false };
  listOfPRs = [
    "Normal Binocular Response",
    "Harmonious ARC with Manifested Squint",
    "Uncrossed Diplopia with Esotropia",
    "Crossed Diplopia with Exotropia",
    "Right Eye Suppressed",
    "Left Eye Suppressed",
  ];
  renderPupil = () => {
    return (
      <ControlGroup>
        <Tag minimal large>
          Worth's Four Dot Test
        </Tag>
        {this.state.editingPupil ? (
          <SingleSelectWrapper
            items={this.listOfPRs}
            selectedItem={this.props.vision.worth}
            onChange={(item) => {
              this.props.updateVision("worth", item);
              this.setState({ editingPupil: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large
            interactive
            intent="primary"
            onClick={() => this.setState({ editingPupil: true })}
          >
            {this.props.vision.worth}
          </Tag>
        )}
      </ControlGroup>
    );
  };
  render() {
    return <div>{this.renderPupil()}</div>;
  }
}

export default WorthFourDot;
