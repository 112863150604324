import React from "react";
import { inject, observer } from "mobx-react";
import {
  Button,
  Card,
  ControlGroup,
  EditableText,
  Elevation,
  H3,
  NonIdealState,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import Hotkeys from "react-hot-keys";
import _ from "lodash";
import cogoToast from "cogo-toast";
import { apiCallPost, niceDateTime } from "../../fns/util";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import Omni from "../../utils/Omni";
import Symptom from "../elems/Symptom";
import Finding from "../elems/Finding";
import Diagnosis from "../elems/Diagnosis";
import InvestigationCard from "../elems/InvestigationCard";
import SysInvCard from "../elems/SysInvCard";
import Medicine from "../elems/Medicine";
import Advice from "../elems/Advice";
import PlanSurgery from "../elems/PlanSurgery";
import MutEx from "../../utils/MutEx";

class Template extends React.Component {
  state = {
    isLoading: true,
    templateNotFound: false,
    templateId: null,
    prefix: null,
    omniOpen: false,
    searching: false,
    searchResults: [],
    items: [],
  };
  specialities = [
    "Cataract",
    "Glaucoma",
    "Cornea",
    "Retina",
    "Oculoplasty",
    "Neuro-Ophthalmology",
    "Uveitis",
    "Refractive",
    "General",
  ];
  templateTypes = ["Surgical", "Clinical"];
  followUpDurations = [
    "Based On Advised Procedures",
    "As and When Required/SOS",
    "In 1 Day",
    "In 2 Days",
    "In 3 Days",
    "In 5 Days",
    "In 1 Week",
    "In 10 Days",
    "In 2 Weeks",
    "In 3 Weeks",
    "In 4 Weeks",
    "In 1 Month",
    "In 6 Weeks",
    "In 2 Months",
    "In 3 Months",
    "In 4 Months",
    "In 5 Months",
    "In 6 Months",
    "In 1 Year",
  ];
  refOptions = [
    "Me",
    "Cardiologist",
    "Neurologist",
    "Orthopedician",
    "Rheumatologist",
    "Dermatologist",
    "Diabetologist",
    "Physician",
    "Pediatrician",
    "ENT Specialist",
    "Otolaryngologist",
    "Ophthalmologist",
    "Glaucoma Specialist",
    "Cataract Surgeon",
    "Refractive Surgeon",
    "Cornea Specialist",
    "Retina Specialist",
    "Oculoplasty Surgeon",
    "Pediatric Ophthalmologist",
    "Neuro-Ophthalmologist",
    "Uveitis Specialist",
  ];
  componentDidMount() {
    this.routeParamMatch();
  }
  routeParamMatch = () => {
    if (this.props.match) {
      let ss = this.props.match.params.templateId;
      if (ss) {
        this.getTemplateDetails(ss);
      } else {
        this.setState({ templateId: null });
      }
    } else {
      this.setState({ templateId: this.props.templateId }, () =>
        this.getTemplateDetails(this.props.templateId)
      );
    }
  };
  /**Search */
  onKeyDown(keyName, event) {
    if (keyName === "command+k" || keyName === "shift+k") {
      if (this.props.templateId) {
        return;
      }
      event.preventDefault();
      this.setState({ omniOpen: true, prefix: "" });
    }
  }
  search = async (string) => {
    if (!string) {
      this.setState({ searchResults: [] });
      return;
    }

    let prefixes = [
      "sym",
      "sign",
      "dx",
      "adv",
      "plan-sx",
      "inv",
      "proc",
      "med",
      "all",
      "fh",
      "sys-dx",
      "sys-sx",
      "prev-sx",
      "fu",
      "sysinv",
    ];

    let txtPs = string.split(" ");

    let hit = null;
    for (var i = 0; i < prefixes.length; i++) {
      if (prefixes[i] === txtPs[0]) {
        hit = prefixes[i];
        break;
      }
    }
    let pp = { prefix: hit || this.state.prefix || "", query: string };
    try {
      this.setState({ searching: true });
      let res = await apiCallPost(
        `/clinical/${this.props.authStore.prefix}/unisearch`,
        pp
      );
      this.setState({ searchResults: res, searching: false });
    } catch (err) {
      cogoToast.error("There was an error in searching.", {
        position: "bottom-left",
      });
    }
  };
  onSearch = (prefix) => {
    if (this.props.templateId) {
      cogoToast.info("You can't edit this template from the clinical page.");
      return;
    }
    this.setState({
      prefix: prefix,
      omniOpen: true,
    });
  };
  onAddOmni = async (item) => {
    if (this.props.templateId) {
      cogoToast.info("You can't edit this template from the clinical page.");
      return;
    }
    let payload = {
      entityArray: item.entityArray,
      templateId: this.state.templateId,
    };
    let dd = item.data;
    dd["_id_sr"] = item._id;
    if (item.entityArray === "findings") {
      dd["type"] = "new";
      dd["checked"] = false;
      dd["site"] = null;
      dd["sinceLastVisit"] = null;
      dd["anatomy"] = item.helper;
      dd["listOfQualifiers"] = item.listOfQualifiers;
      dd["qualifier"] = item.helper2;
    }
    if (
      [
        "famHist",
        "prevEyeDx",
        "sysDx",
        "sysEyeSx",
        "sysSx",
        "allergies",
        "followupWith",
      ].includes(item.entityArray)
    ) {
      cogoToast.info(
        "Sorry, we can't add that to a template because it is not a visit specific information."
      );
      return;
    }
    if (item.entityArray === "investigations") {
      dd["listOfSubTypes"] = item.listOfSubtypes;
    }
    if (item.entityArray === "symptoms") {
      dd["type"] = "new";
      dd["checked"] = false;
    }
    if (item.entityArray === "eyeDx") {
      dd["type"] = "new";
      dd["checked"] = false;
      dd["query"] = false;
      dd["site"] = "Both Eyes";
      dd["anatomy"] = item.helper;
    }
    if (item.entityArray === "advice") {
      dd["type"] = "new";
      dd["checked"] = false;
    }
    if (item.entityArray === "medicines") {
      dd["type"] = "new";
      dd["checked"] = false;
    }
    if (item.entityArray === "sysinv") {
      dd["type"] = "new";
      dd["checked"] = false;
      dd["value"] =
        item.data.typeOfTest === "Boolean"
          ? false
          : item.data.typeOfTest === "Quantitative"
          ? 0
          : "Notes";
    }
    payload["data"] = dd;
    try {
      let res = await apiCallPost(
        `/clinical-template/${this.props.authStore.prefix}/addItem`,
        payload
      );
      this.setState({ items: [...this.state.items, res] });
    } catch (err) {
      cogoToast.error("Error adding item.");
    }
  };
  getAllTemplateContents = async () => {
    try {
      this.setState({ isLoadingTemplateItems: true });

      let res = await apiCallPost(
        `/clinical-template/${this.props.authStore.prefix}/loadTemplateItems`,
        { templateId: this.state.templateId }
      );
      this.setState({ items: res, isLoadingTemplateItems: false });
    } catch (err) {
      this.setState({ isLoadingTemplateItems: false });
      cogoToast.error("Failed to load template items.");
    }
  };
  updateItem = async (itemId, data, key, value, entityArray) => {
    if (entityArray === "medicines" && key === "durationObject") {
      data["duration"] = value.duration;
      data["durationText"] = value.durationText;
    } else {
      data[key] = value;
    }
    if (this.props.templateId) {
      let tt = this.state.items;
      let idx = _.findIndex(tt, (each) => each._id === itemId);
      tt[idx][data] = data;
      this.setState({ items: tt });
      return;
    }

    try {
      cogoToast.loading("Updating item...");
      let res = await apiCallPost(
        `/clinical-template/${this.props.authStore.prefix}/updateItem`,
        {
          templateId: this.state.templateId,
          itemId: itemId,
          entityArray: entityArray,
          data: data,
        }
      );
      let tt = this.state.items;
      let idx = _.findIndex(tt, (each) => each._id === itemId);
      tt[idx] = res;
      this.setState({ items: tt });
      cogoToast.success("Updated item.");
    } catch (err) {
      cogoToast.error("Failed to update item.");
    }
  };
  removeItem = async (itemId) => {
    if (this.props.templateId) {
      let tt = this.state.items;
      let idx = _.findIndex(tt, (each) => each._id === itemId);
      tt.splice(idx, 1);
      this.setState({ items: tt });
      return;
    }
    try {
      cogoToast.loading("Removing item...");
      await apiCallPost(
        `/clinical-template/${this.props.authStore.prefix}/removeItem`,
        {
          templateId: this.state.templateId,
          itemId: itemId,
        }
      );
      let tt = this.state.items;
      let idx = _.findIndex(tt, (each) => each._id === itemId);
      tt.splice(idx, 1);
      this.setState({ items: tt });
      cogoToast.success("Removed item.");
    } catch (err) {
      cogoToast.error("Failed to remove item.");
    }
  };
  addItemsToClinical = () => {
    let newUses = this.state.nUses + 1;
    this.editTemplateProp("nUses", newUses);
    /** First let's get all the entityArrays */
    let entityArrayList = _.uniq(
      this.state.items.map((each) => each.entityArray)
    );
    let obj = {};
    let i = 0;
    for (i = 0; i < entityArrayList.length; i++) {
      let x = entityArrayList[i];
      obj[x] = _.filter(this.state.items, (each) => each.entityArray === x).map(
        (each) => each.data
      );
    }
    this.props.onBulkAdd(obj, this.state.selectedEye);
  };
  /** Template Contents */
  renderTemplateContentsHeader = () => {
    return (
      <div>
        <ControlGroup style={{ marginBottom: "20px" }}>
          <Tag large minimal>
            Template Contents
          </Tag>
        </ControlGroup>
        {this.renderEyeChoice()}
      </div>
    );
  };
  renderEyeChoice = () => {
    if (this.state.editMode !== "clinical") {
      return null;
    }
    return (
      <div>
        <ControlGroup style={{ marginBottom: "20px" }}>
          <Tag
            large
            minimal={!this.state.selectedEye}
            intent={this.state.selectedEye ? "primary" : "danger"}
          >
            Choose Eye
          </Tag>
          <MutEx
            options={["RE", "BE", "LE"]}
            selected={this.state.selectedEye}
            onUpdate={(item) => this.setState({ selectedEye: item })}
          />
          {this.state.selectedEye ? (
            <Button
              intent="success"
              outlined
              icon="endorsed"
              text="Add Template"
              onClick={() => this.addItemsToClinical()}
            />
          ) : null}
        </ControlGroup>
        <br />
        <b> Note</b>: Out of abundance of caution, the eye selected in this
        template will not be auto-applied to{" "}
        <b>medicines, procedures and surgeries </b>
        advised. You should select the eye carefully for those elements in the
        prescription. <br />
        <br />
      </div>
    );
  };
  renderSymptoms = () => {
    let items = _.filter(
      this.state.items,
      (each) => each.entityArray === "symptoms"
    );
    return (
      <div>
        <ControlGroup>
          <Tag large intent="primary">
            Symptoms
          </Tag>
          <Button minimal icon="add" onClick={() => this.onSearch("sym")} />
        </ControlGroup>
        <br />
        {items.length ? (
          <>
            {items.map((each) => {
              return (
                <Symptom
                  templateEditing={true}
                  key={`${each._id}__${each.updatedAt}`}
                  remove={(dataId) => this.removeItem(each._id)}
                  data={each.data}
                  update={(dataId, key, value) => {
                    this.updateItem(
                      each._id,
                      each.data,
                      key,
                      value,
                      "symptoms"
                    );
                  }}
                />
              );
            })}
          </>
        ) : (
          <ControlGroup>
            <Tag large minimal>
              No symptoms added.
            </Tag>
          </ControlGroup>
        )}
      </div>
    );
  };
  renderFindings = () => {
    let items = _.filter(
      this.state.items,
      (each) => each.entityArray === "findings"
    );
    return (
      <div style={{ marginTop: "20px" }}>
        <ControlGroup>
          <Tag large intent="primary">
            Observations
          </Tag>
          <Button minimal icon="add" onClick={() => this.onSearch("sign")} />
        </ControlGroup>
        <br />
        {items.length ? (
          <>
            {items.map((each) => {
              return (
                <Finding
                  duplicate={() =>
                    cogoToast.info(
                      "Double-clicking on the finding on the clinical page will replicate it to the other eye. In templates, this is not functional."
                    )
                  }
                  templateEditing={true}
                  key={`${each._id}__${each.updatedAt}`}
                  remove={(dataId) => this.removeItem(each._id)}
                  data={each.data}
                  editObjectInside={(dataId, key, value) => {
                    this.updateItem(
                      each._id,
                      each.data,
                      key,
                      value,
                      "findings"
                    );
                  }}
                />
              );
            })}
          </>
        ) : (
          <ControlGroup>
            <Tag large minimal>
              No observations added.
            </Tag>
          </ControlGroup>
        )}
      </div>
    );
  };
  renderDiagnosis = () => {
    let items = _.filter(
      this.state.items,
      (each) => each.entityArray === "eyeDx"
    );
    return (
      <div style={{ marginTop: "20px" }}>
        <ControlGroup>
          <Tag large intent="primary">
            Diagnosis
          </Tag>
          <Button minimal icon="add" onClick={() => this.onSearch("dx")} />
        </ControlGroup>
        <br />
        {items.length ? (
          <>
            {items.map((each) => {
              return (
                <Diagnosis
                  templateEditing={true}
                  key={`${each._id}__${each.updatedAt}`}
                  remove={(dataId) => this.removeItem(each._id)}
                  data={each.data}
                  update={(dataId, key, value) => {
                    this.updateItem(each._id, each.data, key, value, "eyeDx");
                  }}
                />
              );
            })}
          </>
        ) : (
          <ControlGroup>
            <Tag large minimal>
              No diagnoses added.
            </Tag>
          </ControlGroup>
        )}
      </div>
    );
  };
  renderInvestigations = () => {
    let items = _.filter(
      this.state.items,
      (each) => each.entityArray === "investigations"
    );
    return (
      <div style={{ marginTop: "20px" }}>
        <ControlGroup>
          <Tag large intent="primary">
            Investigations
          </Tag>
          <Button minimal icon="add" onClick={() => this.onSearch("inv")} />
        </ControlGroup>
        <br />
        {items.length ? (
          <>
            {items.map((each) => {
              return (
                <InvestigationCard
                  templateEditing={true}
                  key={`${each._id}__${each.updatedAt}`}
                  remove={(dataId) => this.removeItem(each._id)}
                  data={each.data}
                  update={(dataId, key, value) => {
                    this.updateItem(
                      each._id,
                      each.data,
                      key,
                      value,
                      "investigations"
                    );
                  }}
                />
              );
            })}
          </>
        ) : (
          <ControlGroup>
            <Tag large minimal>
              No investigations added.
            </Tag>
          </ControlGroup>
        )}
      </div>
    );
  };
  renderSystemicInvestigations = () => {
    let items = _.filter(
      this.state.items,
      (each) => each.entityArray === "sysinv"
    );
    return (
      <div style={{ marginTop: "20px" }}>
        <ControlGroup>
          <Tag large intent="primary">
            Systemic Investigations
          </Tag>
          <Button minimal icon="add" onClick={() => this.onSearch("sysinv")} />
        </ControlGroup>
        <br />
        {items.length ? (
          <>
            {items.map((each) => {
              return (
                <SysInvCard
                  templateEditing={true}
                  key={`${each._id}__${each.updatedAt}`}
                  remove={(dataId) => this.removeItem(each._id)}
                  data={each.data}
                />
              );
            })}
          </>
        ) : (
          <ControlGroup>
            <Tag large minimal>
              No systemic investigations added.
            </Tag>
          </ControlGroup>
        )}
      </div>
    );
  };
  renderMedicines = () => {
    let items = _.filter(
      this.state.items,
      (each) => each.entityArray === "medicines"
    );
    return (
      <div style={{ marginTop: "20px" }}>
        <ControlGroup>
          <Tag large intent="primary">
            Medicines
          </Tag>
          <Button minimal icon="add" onClick={() => this.onSearch("med")} />
        </ControlGroup>
        <br />
        {items.length ? (
          <>
            {items.map((each) => {
              return (
                <Medicine
                  templateEditing={true}
                  key={`${each._id}__${each.updatedAt}`}
                  remove={(dataId) => this.removeItem(each._id)}
                  data={each.data}
                  update={(dataId, key, value) => {
                    this.updateItem(
                      each._id,
                      each.data,
                      key,
                      value,
                      "medicines"
                    );
                  }}
                />
              );
            })}
          </>
        ) : (
          <ControlGroup>
            <Tag large minimal>
              No medicines added.
            </Tag>
          </ControlGroup>
        )}
      </div>
    );
  };
  renderAdvice = () => {
    let items = _.filter(
      this.state.items,
      (each) => each.entityArray === "advice"
    );
    return (
      <div style={{ marginTop: "20px" }}>
        <ControlGroup>
          <Tag large intent="primary">
            Advice
          </Tag>
          <Button minimal icon="add" onClick={() => this.onSearch("adv")} />
        </ControlGroup>
        <br />
        {items.length ? (
          <>
            {items.map((each) => {
              return (
                <Advice
                  templateEditing={true}
                  key={`${each._id}__${each.updatedAt}`}
                  remove={(dataId) => this.removeItem(each._id)}
                  data={each.data}
                  update={(dataId, key, value) => {
                    this.updateItem(each._id, each.data, key, value, "advice");
                  }}
                />
              );
            })}
          </>
        ) : (
          <ControlGroup>
            <Tag large minimal>
              No advice added.
            </Tag>
          </ControlGroup>
        )}
      </div>
    );
  };
  renderSurgeries = () => {
    let items = _.filter(
      this.state.items,
      (each) => each.entityArray === "plannedEyeSx"
    );
    return (
      <PlanSurgery
        templateEditing={true}
        data={items}
        add={async (sx) => {
          let payload = {
            entityArray: "plannedEyeSx",
            templateId: this.state.templateId,
            data: sx,
          };
          try {
            let res = await apiCallPost(
              `/clinical-template/${this.props.authStore.prefix}/addItem`,
              payload
            );

            res["data"]["_id"] = res._id;

            this.setState({ items: [...this.state.items, res] });
          } catch (err) {
            cogoToast.error("Error adding item.");
          }
        }}
        update={async (entityArray, sxId, props) => {
          try {
            cogoToast.loading("Updating item...");
            let res = await apiCallPost(
              `/clinical-template/${this.props.authStore.prefix}/updateItem`,
              {
                templateId: this.state.templateId,
                itemId: sxId,
                entityArray: entityArray,
                data: props,
              }
            );
            let tt = this.state.items;
            let idx = _.findIndex(tt, (each) => each._id === sxId);
            tt[idx] = res;
            this.setState({ items: tt });
            cogoToast.success("Updated item.");
          } catch (err) {
            cogoToast.error("Failed to update item.");
          }
        }}
        remove={(sxId) => this.removeItem(sxId)}
        onAddInvFromSx={() => {}}
      />
    );
  };
  renderFollowUp = () => {
    let items = _.filter(
      this.state.items,
      (each) => each.entityArray === "follow_up_template"
    );
    let tt = items.length ? items[0].data : null;

    return (
      <div style={{ marginTop: "20px" }}>
        <ControlGroup>
          <Tag large intent="primary">
            Follow Up
          </Tag>
          {items.length ? null : (
            <Button
              minimal
              icon="add"
              onClick={async () => {
                let payload = {
                  entityArray: "follow_up_template",
                  templateId: this.state.templateId,
                  data: {
                    refer: false,
                    referTo: null,
                    when: null,
                  },
                };
                try {
                  let res = await apiCallPost(
                    `/clinical-template/${this.props.authStore.prefix}/addItem`,
                    payload
                  );
                  this.setState({ items: [...this.state.items, res] });
                } catch (err) {
                  cogoToast.error("Error adding item.");
                }
              }}
            />
          )}
        </ControlGroup>
        <br />
        {items.length ? (
          <>
            <ControlGroup>
              <Tag minimal intent={tt.refer ? "warning" : "success"} large>
                {tt.refer ? "Refer to" : "Follow up with"}
              </Tag>
              <SingleSelectWrapper
                showSelectProp={true}
                items={this.refOptions}
                selectedItem={tt.referTo}
                onChange={(item) => {
                  this.updateItem(
                    items[0]._id,
                    tt,
                    "referTo",
                    item,
                    "follow_up_template"
                  );
                  if (item === "Me") {
                    this.updateItem(
                      items[0]._id,
                      tt,
                      "refer",
                      false,
                      "follow_up_template"
                    );
                  } else {
                    this.updateItem(
                      items[0]._id,
                      tt,
                      "refer",
                      true,
                      "follow_up_template"
                    );
                  }
                }}
              />
              <Tag minimal large>
                Duration
              </Tag>
              <SingleSelectWrapper
                showSelectProp={true}
                items={this.followUpDurations}
                selectedItem={tt.when}
                onChange={(item) => {
                  this.updateItem(
                    items[0]._id,
                    tt,
                    "when",
                    item,
                    "follow_up_template"
                  );
                }}
              />
            </ControlGroup>
          </>
        ) : (
          <ControlGroup>
            <Tag large minimal>
              No follow up advice added.
            </Tag>
          </ControlGroup>
        )}
      </div>
    );
  };
  /** Basic Details APIs */
  getTemplateDetails = async (templateId) => {
    try {
      cogoToast.loading("Loading template");
      let res = await apiCallPost(
        `/clinical-template/${this.props.authStore.prefix}/loadTemplateById`,
        { templateId: templateId }
      );

      this.setState(
        {
          isLoading: false,
          templateId: res._id,
          ...res,
          editMode: this.props.match
            ? this.props.match.params.mode
            : "clinical",
        },
        () => this.getAllTemplateContents()
      );
    } catch (err) {
      this.setState({ isLoading: false, templateId: null });
      cogoToast.error("Template not found");
    }
  };
  editTemplateProp = async (key, value) => {
    try {
      cogoToast.loading("Updating template");
      await apiCallPost(
        `/clinical-template/${this.props.authStore.prefix}/editTemplateProp`,
        { templateId: this.state.templateId, key: key, value: value }
      );

      this.setState({ [key]: value });
      cogoToast.success("Updated template");
    } catch (err) {
      cogoToast.error("Error in updating template.");
    }
  };
  renderTemplateContents = () => {
    if (this.state.isLoadingTemplateItems) {
      return (
        <div style={{ marginTop: "20px" }}>
          <NonIdealState icon={<Spinner />} title="Loading template items" />
        </div>
      );
    }
    return (
      <div style={{ marginTop: "20px" }}>
        {this.renderTemplateContentsHeader()}
        <Card
          style={{ padding: "10px", background: "none", marginBottom: "10px" }}
          elevation={Elevation.FOUR}
        >
          {this.renderSymptoms()}
        </Card>
        <Card
          style={{ padding: "10px", background: "none", marginBottom: "10px" }}
          elevation={Elevation.FOUR}
        >
          {this.renderFindings()}
        </Card>
        <Card
          style={{ padding: "10px", background: "none", marginBottom: "10px" }}
          elevation={Elevation.FOUR}
        >
          {" "}
          {this.renderDiagnosis()}
        </Card>
        <Card
          style={{ padding: "10px", background: "none", marginBottom: "10px" }}
          elevation={Elevation.FOUR}
        >
          {this.renderInvestigations()}
        </Card>
        <Card
          style={{ padding: "10px", background: "none", marginBottom: "10px" }}
          elevation={Elevation.FOUR}
        >
          {" "}
          {this.renderSystemicInvestigations()}
        </Card>
        <Card
          style={{ padding: "10px", background: "none", marginBottom: "10px" }}
          elevation={Elevation.FOUR}
        >
          {this.renderMedicines()}
        </Card>
        <Card
          style={{ padding: "10px", background: "none", marginBottom: "10px" }}
          elevation={Elevation.FOUR}
        >
          {" "}
          {this.renderAdvice()}
        </Card>
        {this.renderSurgeries()}{" "}
        <Card
          style={{
            padding: "10px",
            background: "none",
            marginBottom: "10px",
            marginTop: "10px",
          }}
          elevation={Elevation.FOUR}
        >
          {" "}
          {this.renderFollowUp()}
        </Card>
      </div>
    );
  };
  /** Basic Details */
  renderName = () => {
    if (this.state.editMode === "edit") {
      return (
        <H3>
          <EditableText
            value={this.state.templateName}
            onChange={(value) => this.setState({ templateName: value })}
            onConfirm={() => {
              this.editTemplateProp("templateName", this.state.templateName);
            }}
          />
        </H3>
      );
    } else {
      return (
        <ControlGroup>
          <Tag
            large
            intent={
              this.state.templateType === "Clinical" ? "success" : "warning"
            }
            icon={
              this.state.templateType === "Clinical" ? "prescription" : "cut"
            }
          >
            {this.state.templateName}
          </Tag>
          <Tag intent="primary">{this.state.speciality}</Tag>
          <Button
            icon="cross"
            text="Back To Search"
            onClick={() => this.props.onBack()}
          />
        </ControlGroup>
      );
    }
  };
  renderSpecialityAndType = () => {
    if (this.state.editMode === "edit") {
      return (
        <ControlGroup>
          {" "}
          <Tag minimal>Speciality</Tag>
          <SingleSelectWrapper
            showSelectProp={true}
            items={this.specialities}
            selectedItem={this.state.speciality}
            onChange={(item) =>
              this.setState({ speciality: item }, () => {
                this.editTemplateProp("speciality", item);
              })
            }
          />
          <Tag minimal>Type</Tag>
          <SingleSelectWrapper
            showSelectProp={true}
            items={this.templateTypes}
            selectedItem={this.state.templateType}
            onChange={(item) =>
              this.setState({ templateType: item }, () => {
                this.editTemplateProp("templateType", item);
              })
            }
          />
        </ControlGroup>
      );
    } else {
      return (
        <ControlGroup>
          {" "}
          <Tag
            intent={
              this.state.templateType === "Clinical" ? "success" : "warning"
            }
            icon={
              this.state.templateType === "Clinical" ? "prescription" : "cut"
            }
          >
            {this.state.speciality}
          </Tag>
        </ControlGroup>
      );
    }
  };
  renderHeader = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          {" "}
          <div>
            <ControlGroup style={{ marginBottom: "10px" }}>
              {this.renderName()}
            </ControlGroup>
            {this.props.templateId ? null : (
              <ControlGroup style={{ marginBottom: "10px" }}>
                {this.renderSpecialityAndType()}
              </ControlGroup>
            )}
          </div>
          <ControlGroup>
            <Tag large minimal icon="person">
              {this.state.createdBy}
            </Tag>
            <Tag minimal>
              Last updated: {niceDateTime(this.state.updatedAt)}
            </Tag>
            <Tag minimal intent="primary">
              # of Uses: {this.state.nUses}
            </Tag>
          </ControlGroup>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <b>Description</b>
          <br />
          <EditableText
            disabled={this.state.editMode !== "edit"}
            intent="warning"
            minLines={3}
            multiline={true}
            value={this.state.templateDescription}
            onChange={(value) => this.setState({ templateDescription: value })}
            onConfirm={() =>
              this.editTemplateProp(
                "templateDescription",
                this.state.templateDescription
              )
            }
          />
        </div>
      </div>
    );
  };
  renderBasicContainer = () => {
    if (this.state.isLoading) {
      return <NonIdealState icon={<Spinner />} title="Loading template" />;
    }
    if (!this.state.templateId) {
      return <NonIdealState icon="error" title="Template ID not found." />;
    }
    return (
      <div>
        {this.renderHeader()}
        {this.renderTemplateContents()}
      </div>
    );
  };
  render() {
    return (
      <>
        <Hotkeys
          keyName="shift+k, command+k"
          onKeyDown={this.onKeyDown.bind(this)}
        >
          <Omni
            isOpen={this.state.omniOpen}
            query={this.state.query}
            onClose={() => this.setState({ omniOpen: false, prefix: null })}
            placeholder="Universal Search..."
            searching={this.state.searching}
            items={this.state.searchResults}
            selectedItem={null}
            update={(item) => {
              this.setState({ query: item });
              this.search(item);
            }}
            onChange={(item) => {
              this.onAddOmni(item);
            }}
          />
          <div style={{ margin: "20px" }}>
            <Card
              style={{ backgroundColor: "#26292c" }}
              elevation={Elevation.FOUR}
            >
              {this.renderBasicContainer()}
            </Card>
          </div>
        </Hotkeys>
      </>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(Template));
