import React from "react";
import { inject, observer } from "mobx-react";
import {
  Card,
  NonIdealState,
  Elevation,
  Tag,
  ControlGroup,
} from "@blueprintjs/core";
import {
  isDateToday,
  isInFutureDateStrict,
  isInPastDateStrict,
  niceDate,
} from "../fns/util";
import BillingItem from "./BillingItem";

class BillingServices extends React.Component {
  state = {
    waiting: false,
    awaitingActivation: false,
  };

  /** TO DO
   *
   * --> Get Investigation Options
   */
  getInvestigationOptions = () => {};
  renderBillingItems = () => {
    if (this.state.waiting) {
      return null;
    }
    if (!this.props.items || !this.props.items.length) {
      return (
        <NonIdealState
          icon="bank-account"
          title="No Services Added"
          description="You can start by booking a service for the patient."
        />
      );
    }
    return this.props.items.map((each) => {
      return (
        <BillingItem
          key={each._id}
          onApprove={(id, panel, policy) =>
            this.props.onApprove(id, panel, policy)
          }
          tpa_wise={this.props.tpa_wise}
          onCashlessUpdate={(id, cashless, panel) =>
            this.props.onCashlessUpdate(id, cashless, panel)
          }
          activating={this.props.activating}
          isNew={this.props.isNew}
          onReset={(id) => this.props.onReset(id)}
          onRequest={(id, panel) => this.props.onRequest(id, panel)}
          onApply={(id) => {
            this.setState({ awaitingActivation: true }, async () => {
              await this.props.onActivate(id);
              this.setState({ awaitingActivation: false });
            });
          }}
          onReferred={(id, doc) => this.props.onReferred(id, doc)}
          onInvEye={(id, eye, detail) => this.props.onInvEye(id, eye, detail)}
          item={each}
          docList={this.props.doctorList}
          panelNames={this.props.panelNames}
          unapplied={this.props.unapplied}
          tpaUnapplied={this.props.tpaUnapplied}
          childKey={Math.round(Math.random() * 1000)}
          awaitingActivation={this.state.awaitingActivation}
        />
      );
    });
  };
  renderDateTag = () => {
    if (!this.props.date) {
      return null;
    }
    let isToday = isDateToday(this.props.date);
    let isPast = isInPastDateStrict(this.props.date);
    let isFuture = isInFutureDateStrict(this.props.date);
    if (isToday) {
      return (
        <Tag large intent="success">
          Today
        </Tag>
      );
    }
    if (isPast) {
      return (
        <Tag large intent="warning">
          Past Date
        </Tag>
      );
    }
    if (isFuture) {
      return (
        <Tag large intent="danger">
          Future Date
        </Tag>
      );
    }
  };

  render() {
    return (
      <Card
        className="bp5-dark"
        style={{
          backgroundColor: "#464B53",
          textAlign: "left",
          margin: "5px",
          width: "100%",
        }}
        elevation={Elevation.THREE}
      >
        <ControlGroup>
          <Tag large minimal>
            {niceDate(this.props.date)}
          </Tag>
          {this.renderDateTag()}
        </ControlGroup>{" "}
        <div>
          <table class="bp5-html-table bp5-html-table-condensed">
            <thead>
              <tr>
                <th>Service</th>
                <th>Details</th>
                <th>Panel</th>
                <th>Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{this.renderBillingItems()}</tbody>
          </table>
        </div>
      </Card>
    );
  }
}

export default inject("bookingStore", "authStore")(observer(BillingServices));
