import { Collapse, ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";

class OCTVert extends React.Component {
  state = {
    isOpen: false,
  };
  renderEach = (param, spl = false) => {
    return (
      <ControlGroup>
        <Tag
          minimal={!spl}
          interactive={spl}
          onClick={() => {
            if (spl) {
              this.setState({ isOpen: !this.state.isOpen });
            }
          }}
        >
          {param.description}
        </Tag>
        <Tag
          intent="primary"
          large
          minimal={!spl}
          interactive={spl}
          onClick={() => {
            if (spl) {
              this.setState({ isOpen: !this.state.isOpen });
            }
          }}
        >
          {param.value} {param.unit ? param.unit : ""}
        </Tag>
      </ControlGroup>
    );
  };
  renderOth = () => {
    let others = this.props.data.others;
    return others.map((each) => {
      return this.renderEach(each, false);
    });
  };
  render() {
    return (
      <div>
        {this.renderEach(this.props.data.average, true)}
        <Collapse isOpen={this.state.isOpen}>
          <ControlGroup vertical>{this.renderOth()}</ControlGroup>
        </Collapse>
      </div>
    );
  }
}

export default OCTVert;
