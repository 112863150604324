import { ControlGroup, EditableText, Tag } from "@blueprintjs/core";
import React from "react";
import { initials, niceDate } from "../../fns/util";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class PrevEyeSx extends React.Component {
  state = {
    touched: false,
    text: null,
    editingWhen: false,
    editingAnaesthesia: false,
    touchedYear: false,
  };
  listOfAnaesthesia = [
    "Local Anaesthesia",
    "Topical Anaesthesia",
    "General Anaesthesia",
    "General Anaesthesia (Short)",
  ];
  listOfEyes = ["Left Eye", "Right Eye", "Both Eyes"];
  listOfDurations = [
    "Less than 6 months back",
    "6 months to 1 year back",
    "1 to 2 years back",
    "2 to 5 years back",
    "5 to 10 years back",
    "More than 10 years back",
  ];
  renderIn = () => {
    if (this.props.data.outside) {
      return null;
    }
    return (
      <ControlGroup>
        <Tag minimal>{this.props.data.doctor}</Tag>
        <Tag minimal>{initials(this.props.data.anaesthesia)}</Tag>
        <Tag minimal>{niceDate(this.props.data.date)}</Tag>
      </ControlGroup>
    );
  };
  renderOut = () => {
    if (!this.props.data.outside) {
      return null;
    }
    return (
      <ControlGroup>
        <div>
          {" "}
          <EditableText
            placeholder="Outside Doctor"
            value={
              this.state.touched ? this.state.text : this.props.data.outsideDoc
            }
            onChange={(event) => {
              this.setState({ touched: true, text: event });
            }}
            onConfirm={() => {
              this.props.onUpdate(
                "outsideDoc",
                this.state.touched
                  ? this.state.text
                  : this.props.data.outsideDoc
              );
            }}
          />
        </div>
        <div style={{ fontSize: "small" }}>
          {" "}
          <EditableText
            placeholder="Year of Sx"
            value={
              this.state.touchedYear ? this.state.when : this.props.data.when
            }
            onChange={(event) => {
              this.setState({ touchedYear: true, when: event });
            }}
            onConfirm={() => {
              this.props.onUpdate(
                "when",
                this.state.touchedYear ? this.state.when : this.props.data.when
              );
            }}
          />
        </div>
      </ControlGroup>
    );
  };
  render() {
    return (
      <>
        <ControlGroup>
          {this.state.editingEye ? (
            <SingleSelectWrapper
              items={this.listOfEyes}
              selectedItem={this.props.data.eye}
              onChange={(item) => {
                this.setState({ editingEye: false });
                this.props.onUpdate("eye", item);
              }}
            />
          ) : (
            <Tag
              minimal
              interactive
              onClick={() => {
                if (this.props.data.outside) {
                  this.setState({ editingEye: true });
                }
              }}
            >
              {initials(this.props.data.eye)}
            </Tag>
          )}

          <Tag
            intent="primary"
            minimal
            large
            onRemove={() => {
              if (this.props.data.outside) {
                this.props.onRemove();
              }
            }}
          >
            {this.props.data.surgery}
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <EditableText
            placeholder="Doctor/Institution"
            value={
              this.state.touched ? this.state.text : this.props.data.outsideDoc
            }
            onChange={(event) => {
              this.setState({ touched: true, text: event });
            }}
            onConfirm={() => {
              this.props.onUpdate(
                "outsideDoc",
                this.state.touched
                  ? this.state.text
                  : this.props.data.outsideDoc
              );
            }}
          />
          <EditableText
            placeholder="When?"
            value={
              this.state.touchedYear ? this.state.when : this.props.data.when
            }
            onChange={(event) => {
              this.setState({ touchedYear: true, when: event });
            }}
            onConfirm={() => {
              this.props.onUpdate(
                "when",
                this.state.touchedYear ? this.state.when : this.props.data.when
              );
            }}
          />
        </ControlGroup>
      </>
    );
  }
}

export default PrevEyeSx;
