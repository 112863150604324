/**
 * inputTypes :
 *   OPTIONS
 *   TYPE {isLong}
 */

export const masterVisitParameters = [
  {
    workupGroup: "General",
    parameterType: "VISION",
    nice: "Visual Acuity",
    short: "VA",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [
      { parameterSubtype: "UNCORRECTED", nice: "Uncorrected", short: "UCVA" },
      {
        parameterSubtype: "DRY_AR",
        nice: "Dry AR",
        short: "Dry AR",
      },
      {
        parameterSubtype: "DILATED_AR",
        nice: "Dilated AR",
        short: "Dil AR",
      },
      {
        parameterSubtype: "PRESENT_GLASS",
        nice: "Present Glass",
        short: "PGVA",
      },
      {
        parameterSubtype: "DRY_REFRACTION",
        nice: "Dry Refraction",
        short: "Dry",
      },
      {
        parameterSubtype: "DILATED_REFRACTION",
        nice: "Dilated Refraction",
        short: "Dilated",
      },
      {
        parameterSubtype: "DRY_RETINOSCOPY",
        nice: "Dry Retinoscopy",
        short: "Dry Ret.",
      },
      {
        parameterSubtype: "DILATED_RETINOSCOPY",
        nice: "Dilated Retinoscopy",
        short: "Dil. Ret.",
      },
      {
        parameterSubtype: "POST_MYDRIASIS_TEST",
        nice: "Post Mydriasis Test",
        short: "PMT",
      },
      {
        parameterSubtype: "FINAL_ACCEPTANCE",
        nice: "Final Acceptance",
        short: "Rx",
      },
      {
        parameterSubtype: "FINAL_ACCEPTANCE_INTERMEDIATE",
        nice: "Final Acceptance (Intermediate)",
        short: "Rx Inter",
      },
    ],
    fields: [
      {
        fieldType: "DISTANCE",
        nice: "Distance",
        short: "DIST",
        inputType: "OPTIONS",
        options: "DISTANCE_VISUAL_ACUITY",
        altInputType: "OPTIONS",
        altOptions: "LOGMAR",
        disabledForSubtypes: [
          "DRY_AR",
          "DILATED_AR",
          "DILATED_RETINOSCOPY",
          "DRY_RETINOSCOPY",
        ],
      },
      {
        fieldType: "PINHOLE",
        nice: "Pinhole",
        short: "PH",
        inputType: "OPTIONS",
        options: "DISTANCE_VISUAL_ACUITY",
        altInputType: "OPTIONS",
        altOptions: "LOGMAR",
        disabledForSubtypes: [
          "DRY_AR",
          "DILATED_AR",
          // "FINAL_ACCEPTANCE",
          // "FINAL_ACCEPTANCE_INTERMEDIATE",
          "DILATED_RETINOSCOPY",
          "DRY_RETINOSCOPY",
        ],
      },
      {
        fieldType: "NEAR",
        nice: "Near",
        short: "NEAR",
        inputType: "OPTIONS",
        options: "NEAR_VISUAL_ACUITY",
        altInputType: "OPTIONS",
        altOptions: "LOGMAR",
        disabledForSubtypes: [
          "DRY_AR",
          "DILATED_AR",
          "DILATED_RETINOSCOPY",
          "DRY_RETINOSCOPY",
        ],
      },
      {
        fieldType: "SPHERICAL",
        nice: "Sphere",
        short: "SPH",
        inputType: "OPTIONS",
        options: "DIOPTRES",
        altInputType: "OPTIONS",
        altOptions: "LOGMAR",
        disabledForSubtypes: ["UNCORRECTED"],
      },
      {
        fieldType: "CYLINDRICAL",
        nice: "Cylinder",
        short: "CYL",
        inputType: "OPTIONS",
        options: "DIOPTRES",
        altInputType: "OPTIONS",
        altOptions: "LOGMAR",
        disabledForSubtypes: ["UNCORRECTED"],
      },
      {
        fieldType: "AXIS",
        nice: "Axis",
        short: "AXIS",
        inputType: "OPTIONS",
        options: "AXIS",
        altInputType: "OPTIONS",
        altOptions: "LOGMAR",
        disabledForSubtypes: ["UNCORRECTED"],
      },
      {
        fieldType: "ADD",
        nice: "Add",
        short: "ADD",
        inputType: "OPTIONS",
        options: "DIOPTRES",
        altInputType: "OPTIONS",
        altOptions: "LOGMAR",
        disabledForSubtypes: ["UNCORRECTED"],
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "INTRA_OCULAR_PRESSURE",
    nice: "Intraocular Pressure",
    short: "IOP",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [
      {
        parameterSubtype: "NON_CONTACT_TONOMETRY",
        nice: "Non-contact Tonometry",
        short: "NCT",
      },
      {
        parameterSubtype: "APPLANATION_TONOMETRY",
        nice: "Applanation Tonometry",
        short: "AT",
      },
      {
        parameterSubtype: "NON_CONTACT_TONOMETRY_POST_DIL",
        nice: "Non-contact Tonometry (Post Dil.)",
        short: "NCT_POST_DIL",
      },
      {
        parameterSubtype: "APPLANATION_TONOMETRY_POST_DIL",
        nice: "Applanation Tonometry (Post Dil.)",
        short: "AT_POST_DIL",
      },
      {
        parameterSubtype: "PERKINS_TONOMETRY",
        nice: "Perkin's Tonometry",
        short: "PT",
      },
      {
        parameterSubtype: "TONOPEN",
        nice: "Tonopen",
        short: "Pen",
      },
      {
        parameterSubtype: "OCULAR_RESPONSE_ANALYZER",
        nice: "Ocular Response Analyzer",
        short: "ORA",
      },
      {
        parameterSubtype: "CORVIS_TONOMETRY",
        nice: "Corvis Tonometry",
        short: "Corvis",
      },
      {
        parameterSubtype: "PNEUMOTONOMETRY",
        nice: "Pneumotonometry",
        short: "Pneumo",
      },
      {
        parameterSubtype: "ICARE_CONTACT_TONOMETRY",
        nice: "iCare Contact Tonometry",
        short: "iCare",
      },
      {
        parameterSubtype: "DYNAMIC_CONTOUR_TONOMETRY",
        nice: "Dynamic Contour Tonometry",
        short: "DCT",
      },
      {
        parameterSubtype: "BIORESONATOR_ART",
        nice: "BioResonator",
        short: "ART",
      },
      {
        parameterSubtype: "SENSIMED_TRIGGERFISH",
        nice: "Sensimed TriggerFish Continuous",
        short: "Continuous",
      },
    ],
    fields: [
      {
        fieldType: "PRESSURE",
        nice: "IOP",
        short: "IOP",
        inputType: "TYPE",
        unit: "mm Hg",
        bound: "IOP",
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "KERATOMETRY",
    nice: "Keratometry",
    short: "K",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [
      {
        parameterSubtype: "AUTOMATED_REFRACTION",
        nice: "AR",
        short: "AR",
      },
      {
        parameterSubtype: "OPTICAL_BIOMETRY",
        nice: "Optical Biometry",
        short: "Optical",
      },
      {
        parameterSubtype: "ULTRASOUND_BIOMETRY",
        nice: "Ultrasound Biometry",
        short: "Ultrasound",
      },
    ],
    fields: [
      {
        fieldType: "K1",
        nice: "K1",
        short: "K1",
        inputType: "TYPE",
        unit: "D@",
      },
      {
        fieldType: "A1",
        nice: "Axis",
        short: "Axis",
        inputType: "TYPE",
        unit: "°",
      },
      {
        fieldType: "K2",
        nice: "K2",
        short: "K2",
        inputType: "TYPE",
        unit: "D@",
      },
      {
        fieldType: "A2",
        nice: "Axis",
        short: "Axis",
        inputType: "TYPE",
        unit: "°",
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "INTERPUPILLARY_DISTANCE",
    nice: "Inter-Pupillary Distance",
    short: "IPD",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "IPD",
        nice: "IPD",
        short: "IPD",
        inputType: "TYPE",
        unit: "mm",
        bound: "IPD",
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "GLASS_RX",
    nice: "Spectacle Prescription",
    short: "GLASS_RX",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "GLASS_ADVISE",
        nice: "Glasses Advised",
        short: "Glasses Advised",
        inputType: "OPTIONS",
        options: "GLASS_ADVISE",
        isLong: true,
      },
      {
        fieldType: "GLASS_RECOMMENDATION",
        nice: "Glasses Recommendation",
        short: "Glasses Recommendation",
        inputType: "MULTISELECT_OPTIONS",
        options: "GLASS_RECOMMENDATION",
        isLong: true,
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "VISUAL_ACUITY_NOTES",
    nice: "Visual Acuity Notes",
    short: "Notes",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "VISUAL_ACUITY_NOTES",
        nice: "Visual Acuity Notes",
        short: "Notes",
        inputType: "TYPE",
        isLong: true,
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "PUPILLARY_REACTION",
    nice: "Pupillary Reaction",
    short: "PR",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "PUPILLARY_REACTION",
        nice: "Pupillary Reaction",
        short: "PR",
        inputType: "OPTIONS",
        options: "PUPILLARY_REACTION",
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "CONTRAST_SENSITIVITY",
    nice: "Contrast Sensitivity",
    short: "CS",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "CONTRAST_SENSITIVITY",
        nice: "Contrast Sensitivity",
        short: "CS",
        inputType: "OPTIONS",
        options: "CONSTRAST_SENSITIVITY",
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "COLOUR_VISION_STATUS",
    nice: "Colour Vision Status",
    short: "Colour Vision",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "COLOUR_VISION_STATUS",
        nice: "Colour Vision Status",
        short: "Colour Vision",
        inputType: "OPTIONS",
        options: "COLOUR_VISION",
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "COLOUR_VISION_EXAMINATION",
    nice: "Colour Vision Examination",
    short: "Notes",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "COLOUR_VISION_TEST_TYPE",
        nice: "Colour Vision Test",
        short: "Test",
        inputType: "OPTIONS",
        options: "COLOUR_VISION_TEST",
      },
      {
        fieldType: "COLOUR_VISION_EXAMINATION",
        nice: "Colour Vision Examination",
        short: "Notes",
        inputType: "TYPE",
        isLong: true,
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "SCHIRMER_TEST",
    nice: "Schirmer's Test",
    short: "Schirmer",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [
      {
        parameterSubtype: "SCHIRMER",
        nice: "Schirmer's 1",
        short: "SCH1",
      },
      {
        parameterSubtype: "SCHIRMER2",
        nice: "Schirmer's 2",
        short: "SCH2",
      },
      {
        parameterSubtype: "SCHIRMER3",
        nice: "Schirmer's 3",
        short: "SCH3",
      },
    ],
    fields: [
      {
        fieldType: "SCHIRMER",
        nice: "Schirmer's Test",
        short: "Schirmer",
        inputType: "OPTIONS",
        options: "SCHIRMER",
      },
      {
        fieldType: "SCHIRMER_DURATION",
        nice: "Duration",
        short: "Duration",
        inputType: "OPTIONS",
        options: "SCHIRMER_DURATION",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "WORTH_FOUR_DOT",
    nice: "Worth's Four Dot Test",
    short: "Worth's",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "WORTH_FOUR_DOT",
        nice: "Worth's Four Dot Test",
        short: "Worth's",
        inputType: "OPTIONS",
        options: "WORTH",
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "FUNCTIONAL_EYES",
    nice: "Functional Eyes",
    short: "Functional Eyes",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "FUNCTIONAL_EYES",
        nice: "Functional Eyes",
        short: "Functional Eyes",
        inputType: "OPTIONS",
        options: "LATERALITIES",
      },
    ],
  },
  {
    workupGroup: "General",
    parameterType: "BINOC_VISION",
    nice: "Binocular Visual Acuity",
    short: "BVA",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "DISTANCE",
        nice: "Distance",
        short: "DIST",
        inputType: "OPTIONS",
        options: "DISTANCE_VISUAL_ACUITY",
      },
      {
        fieldType: "NEAR",
        nice: "Near",
        short: "NEAR",
        inputType: "OPTIONS",
        options: "NEAR_VISUAL_ACUITY",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "PBCT",
    nice: "Prism Bar Cover Test",
    short: "PBCT",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "PBCT",
        nice: "PD",
        short: "PD",
        inputType: "TYPE",
        unit: "PD",
        bound: "PBCT",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "STEREOPSIS",
    nice: "Stereopsis",
    short: "STEREOPSIS",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "STEREOPSIS",
        nice: "STEREOPSIS",
        short: "STEREOPSIS",
        inputType: "TYPE",
        unit: "″",
        bound: "STEREOPSIS_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "NFV",
    nice: "Negative Fusional Vergence",
    short: "NFV",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "NFV_DISTANCE",
        nice: "Negative Fusional Vergence (Distance)",
        short: "NFV (D)",
        inputType: "TYPE",
        unit: "PD",
        bound: "NFV_BOUND",
      },
      {
        fieldType: "NFV_NEAR",
        nice: "Negative Fusional Vergence (Near)",
        short: "NFV (N)",
        inputType: "TYPE",
        unit: "PD",
        bound: "NFV_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "PFV",
    nice: "Positive Fusional Vergence",
    short: "PFV",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "PFV_DISTANCE",
        nice: "Positive Fusional Vergence (Distance)",
        short: "PFV (D)",
        inputType: "TYPE",
        unit: "PD",
        bound: "PFV_BOUND",
      },
      {
        fieldType: "PFV_NEAR",
        nice: "Positive Fusional Vergence (Near)",
        short: "PFV (N)",
        inputType: "TYPE",
        unit: "PD",
        bound: "PFV_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "VERGENCE_FACILITY",
    nice: "Vergence Facility",
    short: "VF",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "VF_DISTANCE",
        nice: "Vergence Facility (Distance)",
        short: "VF (Distance)",
        inputType: "TYPE",
        unit: "cpm",
        bound: "VERGENCE_FACILITY_BOUND",
      },
      {
        fieldType: "VF_NEAR",
        nice: "Vergence Facility (Near)",
        short: "VF (Near)",
        inputType: "TYPE",
        unit: "cpm",
        bound: "VERGENCE_FACILITY_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "MEM",
    nice: "Monocular Estimate Method",
    short: "MEM",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "MEM",
        nice: "Monocular Estimate Method",
        short: "MEM",
        inputType: "TYPE",
        unit: "D",
        bound: "MEM_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "NPA",
    nice: "Near Point of Accommodation",
    short: "NPA",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "NPA",
        nice: "Near Point of Accommodation",
        short: "NPA",
        inputType: "TYPE",
        unit: "cm",
        bound: "NPA_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "NRA",
    nice: "Negative Relative Accommodation",
    short: "NRA",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "NRA",
        nice: "Negative Relative Accommodation",
        short: "NRA",
        inputType: "TYPE",
        unit: "D",
        bound: "NRA_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "PRA",
    nice: "Positive Relative Accommodation",
    short: "PRA ",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "PRA",
        nice: "Positive Relative Accommodation",
        short: "PRA",
        inputType: "TYPE",
        unit: "D",
        bound: "PRA_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "NPC",
    nice: "Near Point Convergence",
    short: "NPC (B/R)",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [
      {
        parameterSubtype: "RAF",
        nice: "RAF",
        short: "RAF",
      },
      {
        parameterSubtype: "RED_FILTER",
        nice: "Red Filter",
        short: "Red Filter",
      },
    ],
    fields: [
      {
        fieldType: "NPC_BREAK",
        nice: "NPC Break",
        short: "NPC (B)",
        inputType: "TYPE",
        unit: "cm",
        bound: "NPC_BREAK_BOUND",
      },
      {
        fieldType: "NPC_RECOVERY",
        nice: "NPC Recovery",
        short: "NPC (R)",
        inputType: "TYPE",
        unit: "cm",
        bound: "NPC_RECOVERY_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "NPC",
    nice: "Near Point Convergence",
    short: "NPC (B/R)",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [
      {
        parameterSubtype: "RAF",
        nice: "RAF",
        short: "RAF",
      },
      {
        parameterSubtype: "RED_FILTER",
        nice: "Red Filter",
        short: "Red Filter",
      },
    ],
    fields: [
      {
        fieldType: "NPC_BREAK",
        nice: "NPC Break",
        short: "NPC (B)",
        inputType: "TYPE",
        unit: "cm",
        bound: "NPC_BREAK_BOUND",
      },
      {
        fieldType: "NPC_RECOVERY",
        nice: "NPC Recovery",
        short: "NPC (R)",
        inputType: "TYPE",
        unit: "cm",
        bound: "NPC_RECOVERY_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "ACCOMODATIVE_FACILITY",
    nice: "Accomodative Facility",
    short: "AF",
    hasLaterality: false,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "LENS",
        nice: "Lens",
        short: "Lens",
        inputType: "OPTIONS",
        options: "ACCOMODATIVE_FACILITY_LENS",
        unit: "D",
        bound: "NPC_BREAK_BOUND",
      },
      {
        fieldType: "RE",
        nice: "RE",
        short: "RE",
        inputType: "TYPE",
        unit: "cpm",
        bound: "NPC_RECOVERY_BOUND",
      },
      {
        fieldType: "LE",
        nice: "LE",
        short: "LE",
        inputType: "TYPE",
        unit: "cpm",
        bound: "NPC_RECOVERY_BOUND",
      },
      {
        fieldType: "BE",
        nice: "BE",
        short: "BE",
        inputType: "TYPE",
        unit: "cpm",
        bound: "NPC_RECOVERY_BOUND",
      },
    ],
  },
  {
    workupGroup: "Squint Evaluation",
    parameterType: "HIRSCHBERG_TEST",
    nice: "Hirschberg Test",
    short: "HT",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [],
    fields: [
      {
        fieldType: "HIRSCHBERG_TEST_VALUE",
        nice: "Value",
        short: "Value",
        inputType: "TYPE",
        unit: "PD",
        bound: "NPC_BREAK_BOUND",
      },
    ],
  },
  {
    workupGroup: "Ptosis Workup",
    parameterType: "PTOSIS",
    nice: "Ptosis Workup",
    short: "PTOSIS",
    hasLaterality: true,
    organ: "EYE",
    parameterSubtypes: [
      {
        parameterSubtype: "PFH",
        nice: "Palpebral Fissure Height",
        short: "PTOSIS_PFH",
      },
      { parameterSubtype: "PTOSIS_BFS", nice: "Brow Fat Span", short: "BFS" },
      {
        parameterSubtype: "PTOSIS_TPS",
        nice: "Tarsal Platform Show",
        short: "TPS",
      },
      {
        parameterSubtype: "PTOSIS_MRD1",
        nice: "MRD1",
        short: "MRD1",
      },
      {
        parameterSubtype: "PTOSIS_MRD2",
        nice: "MRD2",
        short: "MRD2",
      },
    ],
    fields: [
      {
        fieldType: "PTOSIS_DIST",
        nice: "Distance",
        short: "Dist.",
        inputType: "TYPE",
        unit: "mm",
        bound: "PTOSIS_DIST_BOUND",
      },
    ],
  },
  // {
  //   workupGroup: "Squint Evaluation",
  //   parameterType: "NPC",
  //   nice: "Near Point Convergence",
  //   short: "NPC (B/R)",
  //   hasLaterality: false,
  //   organ: "EYE",
  //   parameterSubtypes: [
  //     {
  //       parameterSubtype: "RAF",
  //       nice: "RAF",
  //       short: "RAF",
  //     },
  //     {
  //       parameterSubtype: "RED_FILTER",
  //       nice: "Red Filter",
  //       short: "Red Filter",
  //     },
  //   ],
  //   fields: [
  //     {
  //       fieldType: "NPC_BREAK",
  //       nice: "NPC Break",
  //       short: "NPC (B)",
  //       inputType: "TYPE",
  //       unit: "cm",
  //       bound: "NPC_BREAK_BOUND",
  //     },
  //     {
  //       fieldType: "NPC_RECOVERY",
  //       nice: "NPC Recovery",
  //       short: "NPC (R)",
  //       inputType: "TYPE",
  //       unit: "cm",
  //       bound: "NPC_RECOVERY_BOUND",
  //     },
  //   ],
  // },
];

/** Other Samples:
 * 
 * 
 *  {
    type: "BLOOD_PRESSURE",
    nice: "Blood Pressure",
    short: "BP",
    hasLaterality: false,
    organ: "HEART",
    subtypes: [],
    fields: [
      {
        type: "SYSTOLIC_BLOOD_PRESSURE",
        nice: "Systolic",
        short: "Sys",
        inputType: "TYPE",
        unit: "mm Hg",
        unitNormUb: 130,
        unitNormLb: 115,
      },
      {
        type: "DIASTOLIC_BLOOD_PRESSURE",
        nice: "Diastolic",
        short: "Dia",
        inputType: "TYPE",
        unit: "mm Hg",
        unitNormUb: 85,
        unitNormLb: 70,
      },
    ],
  },

  {
    type: "COVID_19_PCR",
    nice: "COVID-19 RT-PCR",
    short: "COV19",
    hasLaterality: false,
    organ: "SYSTEMIC",
    subtypes: [],
    fields: [
      {
        type: "TEST_RESULT",
        nice: "RT PCR Result",
        short: "Result",
        inputType: "BOOLEAN",
        isPositiveGood: true,
      },
      {
        type: "TEST_RESULT_2",
        nice: "AntiBody Result",
        short: "Result",
        inputType: "BOOLEAN",
        isPositiveGood: false,
      },
    ],
  },
 * 
 * 
 * 
 * 
 */
