import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  InputGroup,
  NumericInput,
  Tag,
} from "@blueprintjs/core";

import React from "react";

import SingleSelectWrapper from "../utils/SingleSelectWrapper";

class SetCashless extends React.Component {
  state = {
    amountPaise: null,
    panel: null,
    claimId: null,
    approvalBasis: null,
  };
  approvalBasis = ["Pre-Approved Rate", "Via CCN"];

  renderAction = () => {
    if (!this.state.panel) {
      return null;
    }
    if (!this.state.amountPaise) {
      return null;
    }
    if (this.state.approvalBasis === "Via CCN" && !this.state.claimId) {
      return null;
    }
    return (
      <Button
        rightIcon="arrow-right"
        intent="success"
        text="Confirm TPA Cashless Amount"
        onClick={() => this.props.setCashless(this.state)}
      />
    );
  };
  render() {
    return (
      <Card
        className="bp5-dark"
        style={{
          backgroundColor: "#464B53",
          margin: "10px",
          textAlign: "left",
        }}
        elevation={Elevation.THREE}
      >
        <ControlGroup>
          <Tag large intent="primary">
            Set Cashless Approval
          </Tag>
          <Tag large minimal>
            Amount
          </Tag>
          <NumericInput
            buttonPosition="none"
            minorStepSize={0.01}
            min={0}
            clampValueOnBlur
            intent="primary"
            onValueChange={(value) =>
              this.setState({ amountPaise: value * 100 })
            }
          />

          <Tag large minimal>
            Panel
          </Tag>
          <SingleSelectWrapper
            className="bp5-dark"
            items={this.props.panels}
            onChange={(item) => {
              this.setState({ panel: item });
            }}
          />
          <Tag large minimal>
            Approval Basis
          </Tag>
          <SingleSelectWrapper
            className="bp5-dark"
            items={this.approvalBasis}
            onChange={(item) => {
              this.setState({ approvalBasis: item });
            }}
          />
          {this.state.approvalBasis === "Via CCN" ? (
            <>
              {" "}
              <Tag large minimal>
                Claim ID
              </Tag>
              <InputGroup
                onChange={(event) =>
                  this.setState({ claimId: event.target.value })
                }
              />
            </>
          ) : null}

          {this.renderAction()}
        </ControlGroup>
      </Card>
    );
  }
}

export default SetCashless;
