import {
  MultistepDialog,
  H3,
  Tag,
  DialogStep,
  ControlGroup,
} from "@blueprintjs/core";
import React from "react";
import _ from "lodash";
import Finding from "../elems/Finding";
import SuggColl from "../SuggColl";
import { inject, observer } from "mobx-react";
import AsyncSelWrap from "../../utils/AsyncSelWrap";
import { apiCallPost } from "../../fns/util";
import cogoToast from "cogo-toast";

class ObsWizard extends React.Component {
  state = {
    isOpen: false,
    collapse: true,
    showWnls: true,
    searchResultsLe: [],
    searchingLe: false,
    searchResultsRe: [],
    searchingRe: false,
    searchResultsBe: [],
    searchingBe: false,
  };

  normals = {
    "Extraocular Muscles": "Movements Full and Free",
    "Lids and Adnexa": "WNL",
    Conjunctiva: "WNL",
    Sclera: "WNL",
    Cornea: "Clear",
    "Anterior Chamber": "Deep and Quiet",
    Iris: "WNL",
    Lens: "Clear",
    Vitreous: "Clear",
    Retina: "WNL",
    Disc: "WNL",
  };
  search = async (string, sr, sing, each) => {
    if (!string) {
      this.setState({ [sr]: [] });
      return;
    }
    try {
      this.setState({ [sing]: true, [sr]: [] }, async () => {
        let res = await apiCallPost(
          `/clinical/${this.props.authStore.prefix}/unisearch`,
          { prefix: "sign", query: string, ana_prefix: _.lowerCase(each) }
        );
        this.setState({ [sr]: res, [sing]: false });
      });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  renderOneAnat = (anat) => {
    let data = this.props.data[anat];
    return (
      <table
        className="bp5-html-table   bp5-html-table-striped"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th>Right Eye</th>
            <th>Left Eye</th>
          </tr>
        </thead>
        <tbody>{this.renderRows(data, anat)}</tbody>
      </table>
    );
  };

  renderRows = (data, which) => {
    let right = _.filter(data, (each) => each.site === "Right Eye");
    let left = _.filter(data, (each) => each.site === "Left Eye");
    return (
      <tr>
        <td>
          <ControlGroup vertical>
            {this.renderEachCell(right, which)}
          </ControlGroup>
        </td>
        <td>
          <ControlGroup vertical>
            {this.renderEachCell(left, which)}
          </ControlGroup>
        </td>
      </tr>
    );
  };
  renderEachCell = (list, anat) => {
    return list.map((each) => {
      return (
        <Finding
          data={each}
          editObjectInside={(elementId, key, value) =>
            this.props.editObjectInside(
              ["findings", anat],
              elementId,
              key,
              value
            )
          }
          duplicate={() => {
            let dd = _.cloneDeep(each);
            dd["site"] = each.site === "Right Eye" ? "Left Eye" : "Right Eye";
            this.props.addObjectInside("findings", dd, anat);
          }}
          remove={(findingId) =>
            this.props.remove(["findings", anat], findingId)
          }
        />
      );
    });
  };
  renderTitle = (data) => {
    return <H3>{data}</H3>;
  };
  renderAnats = () => {
    let anats = _.keys(this.props.data);
    return anats.map((each) => {
      return (
        <DialogStep
          panel={
            <div style={{ padding: "20px", height: "100%" }}>
              {this.renderTitle(each)}
              <SuggColl
                ana_prefix={each}
                prefix="sign"
                visitId={this.props.visitId}
                addObjectInside={this.props.addObjectInside}
              />
              <ControlGroup>
                <Tag minimal>Search: {each}</Tag>
                <AsyncSelWrap
                  placeholder="Right Eye Signs..."
                  searching={this.state.searchingRe}
                  items={this.state.searchResultsRe}
                  selectedItem={null}
                  update={(item) =>
                    this.search(item, "searchResultsRe", "searchingRe", each)
                  }
                  onChange={(item) => {
                    let dd = item.data;
                    dd["_id_sr"] = item._id;
                    dd["type"] = "new";
                    dd["checked"] = false;
                    dd["site"] = "Right Eye";
                    dd["sinceLastVisit"] = null;
                    dd["anatomy"] = item.helper;
                    dd["listOfQualifiers"] = item.listOfQualifiers;
                    dd["qualifier"] = item.helper2;
                    this.props.addObjectInside("findings", dd, item.helper);
                  }}
                />
                <AsyncSelWrap
                  placeholder="Both Eyes Signs..."
                  searching={this.state.searchingBe}
                  items={this.state.searchResultsBe}
                  selectedItem={null}
                  update={(item) =>
                    this.search(item, "searchResultsBe", "searchingBe", each)
                  }
                  onChange={(item) => {
                    let dd = item.data;
                    dd["_id_sr"] = item._id;
                    dd["type"] = "new";
                    dd["checked"] = false;
                    dd["site"] = "Left Eye";
                    dd["anatomy"] = item.helper;
                    dd["sinceLastVisit"] = null;
                    dd["listOfQualifiers"] = item.listOfQualifiers;
                    dd["qualifier"] = item.helper2;
                    this.props.addObjectInside("findings", dd, item.helper);
                    let cc = _.cloneDeep(dd);
                    cc["site"] = "Right Eye";
                    this.props.addObjectInside("findings", cc, item.helper);
                  }}
                />
                <AsyncSelWrap
                  placeholder="Left Eye Signs..."
                  searching={this.state.searchingLe}
                  items={this.state.searchResultsLe}
                  selectedItem={null}
                  update={(item) =>
                    this.search(item, "searchResultsLe", "searchingLe", each)
                  }
                  onChange={(item) => {
                    let dd = item.data;
                    dd["_id_sr"] = item._id;
                    dd["type"] = "new";
                    dd["checked"] = false;
                    dd["site"] = "Left Eye";
                    dd["sinceLastVisit"] = null;
                    dd["anatomy"] = item.helper;
                    dd["listOfQualifiers"] = item.listOfQualifiers;
                    dd["qualifier"] = item.helper2;
                    this.props.addObjectInside("findings", dd, item.helper);
                  }}
                />
              </ControlGroup>

              {this.renderOneAnat(each)}
            </div>
          }
          id={each}
          title={each}
        />
      );
    });
  };
  render() {
    return (
      <div>
        <MultistepDialog
          isOpen={this.props.isOpen}
          className="bp5-dark axis"
          style={{ width: "90vw" }}
          icon="diagnosis"
          title="Observations"
          onClose={() => this.props.onClose()}
          finalButtonProps={{
            onClick: () => this.props.onClose(),
          }}
          resetOnClose={false}
        >
          {this.renderAnats()}
        </MultistepDialog>
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(ObsWizard));
