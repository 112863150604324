import {
  AnchorButton,
  Button,
  Card,
  ControlGroup,
  Elevation,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import {
  apiCallPostAI,
  apiCallPostCancellable,
  copyToClipboard,
  niceDateTime,
} from "../fns/util";
import TextWithEllipsis from "./TextWithEllipsis";

class VisitRecordings extends React.Component {
  state = { alchemyToken: "", isLoading: true, isError: false, recordings: [] };
  componentDidMount() {
    this.getAlchemyToken();
  }
  getAlchemyToken = async () => {
    const alToken = localStorage.getItem("alchemyToken");
    if (alToken) {
      this.setState({ alchemyToken: alToken, isLoading: false }, () =>
        this.getRecordingsByVisit()
      );
      return;
    }

    try {
      let res = await apiCallPostCancellable("/gateway/alchemy", {});
      if (res) {
        this.setState(
          {
            alchemyToken: res.token,
            isLoading: false,
            isError: false,
          },
          () => {
            localStorage.setItem("alchemyToken", res.token);
            this.getRecordingsByVisit();
          }
        );
      }
    } catch (err) {
      this.setState({ isError: true, isLoading: false });
      //  cogoToast.error("Error getting Alchemy token.");
    }
  };
  getRecordingsByVisit = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      let res = await apiCallPostAI(
        this.state.alchemyToken,
        "/stt/searchTranscriptions",
        {
          applicationCtxId: this.props.visitId,
          tool: ["NC_VOICE_INPUT"],
        }
      );
      this.setState({ recordings: res, isLoading: false });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
    }
  };
  renderHeader = () => {
    return (
      <div className="flex__push__apart">
        <ControlGroup>
          <Tag large intent="success" className="turquoise__bg">
            Audio Recordings In This Visit
          </Tag>
        </ControlGroup>
        <ControlGroup>
          <Button
            icon="refresh"
            onClick={() => this.getAlchemyToken()}
            loading={this.state.isLoading}
          />
        </ControlGroup>
      </div>
    );
  };
  renderAudioRecordings = () => {
    if (!this.state.recordings.length) return null;
    return this.state.recordings.map((each, index) => {
      return (
        <Card
          key={index}
          elevation={Elevation.THREE}
          style={{
            marginLeft: "10px",
            marginRight: "10px",
            padding: "5px",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag large intent="success" className="rose__bg">
              {each.userName}
            </Tag>
            <Tag large intent="success" className="indigo__bg">
              {niceDateTime(each.createdAt)}
            </Tag>
            <Button
              className="colfax"
              icon="duplicate"
              minimal
              text="Copy Transcript"
              onClick={() => {
                copyToClipboard(each.transcription.text);
              }}
            />
            <AnchorButton
              download
              minimal
              icon="cloud-download"
              text="Download"
              className="colfax"
              href={each.audioUrl}
              target="_blank"
            />{" "}
            <audio controls style={{ height: "30px" }}>
              <source src={each.audioUrl} type="audio/wav" />
            </audio>
          </div>

          <p style={{ marginTop: "10px", fontSize: "small" }}>
            {each.transcription.text}
          </p>
        </Card>
      );
    });
  };
  render() {
    if (this.state.isLoading)
      return (
        <div>
          <ControlGroup>
            <Tag large intent="warning">
              <TextWithEllipsis text="Loading recordings" />
            </Tag>
          </ControlGroup>
        </div>
      );
    if (this.state.isError)
      return (
        <div>
          <Tag large intent="danger">
            We ran into an error.
          </Tag>
        </div>
      );
    return (
      <div>
        {this.renderHeader()}
        <div
          style={{
            maxHeight: "40vh",
            overflowY: "scroll",
          }}
        >
          {this.renderAudioRecordings()}
        </div>
      </div>
    );
  }
}
export default VisitRecordings;
