import React from "react";
import { Tag } from "@blueprintjs/core";

class SuggCollClick extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);

    // hack doubleclick
    this.doubleClickTimeout = 250;
    this.clickedTimeout = null;
  }
  handleClick() {
    if (!this.clickedTimeout) {
      this.clickedTimeout = setTimeout(() => {
        this.clickedTimeout = null;
        this.props.onSingleClick();
      }, this.doubleClickTimeout);
    }
  }
  handleDoubleClick() {
    clearTimeout(this.clickedTimeout);
    this.clickedTimeout = null;
    this.props.onDoubleClick();
  }

  render() {
    return (
      <Tag
        minimal
        large
        interactive
        onClick={this.handleClick}
        onDoubleClick={this.handleDoubleClick}
        icon={this.props.icon}
      >
        {this.props.text}
      </Tag>
    );
  }
}

export default SuggCollClick;
