import React from "react";
import { inject, observer } from "mobx-react";
import cogoToast from "cogo-toast";
import { apiCallPostCancellable } from "../fns/util";
import BookingViewThread from "./BookingViewThread";
import _ from "lodash";
import { set, get } from "idb-keyval";

class BookingThreadContainer extends React.Component {
  constructor(props) {
    super(props);
    this.currentFocus = React.createRef();
    this.focus = this.focus.bind(this);
    this.threadRefs = {};
  }

  state = {
    listOfThreads: [],
  };
  getNewDetails = async (pid) => {
    try {
      let v = await apiCallPostCancellable(
        `/sidepane/${this.props.authStore.prefix}/previousVisits`,
        {
          PID: pid,
        }
      );

      this.props.patientStore.patientPreviousVisits = v;
      if (v !== undefined) {
        await set(pid, { previousVisits: v, visitsLastUpdated: new Date() });
      }
    } catch (err) {
      console.log(err);
      cogoToast.error(
        "There was an error updating the list of appointments automatically.",
        { position: "bottom-left" }
      );
    }
  };

  getClinical = async (pid) => {
    try {
      let cc = await apiCallPostCancellable(
        `/clinical/${this.props.authStore.prefix}/sidePane`,
        {
          genericPid: pid,
        }
      );
      this.props.patientStore.clinicalRecordToday = cc.clinicalRecordToday;
      this.props.patientStore.tps = cc.touchpoints;
      this.props.patientStore.glassRxLink = cc.glassRxLink;
      this.props.patientStore.rxLink = cc.rxLink;
      this.props.patientStore.visitDone = cc.visitDone;
    } catch (err) {
      console.log(err);
      cogoToast.error("There was an error updating clinical record.", {
        position: "bottom-left",
      });
    }
  };

  async focus(index) {
    this.threadRefs[index].focus(this.props.listOfThreads[index].name);

    this.props.patientStore.patientName = this.props.listOfThreads[index].name;
    this.props.patientStore.patientPhone =
      this.props.listOfThreads[index].phone;
    this.props.patientStore.genericPid = this.props.listOfThreads[index].sysPid;
    this.props.patientStore.patientAge =
      new Date().getFullYear() -
      new Date(this.props.listOfThreads[index].dob).getFullYear();
    this.props.patientStore.patientSex = this.props.listOfThreads[index].sex;
    this.props.patientStore.panels = this.props.listOfThreads[index].panels;
    /** CHECK */
    this.props.patientStore.patientPreviousVisits = {};
    this.props.bookingStore.whichPatientInFocus =
      this.props.listOfThreads[index];

    /** PATTERN TO UPDATE ARCHIVE */
    let pid = this.props.listOfThreads[index].sysPid; // specific to context
    let patientExistsInIdb = await get(pid);
    if (patientExistsInIdb) {
      if (patientExistsInIdb.previousVisits) {
        // there's something here!
        this.props.patientStore.patientPreviousVisits =
          patientExistsInIdb.previousVisits;

        let lastUpdate = new Date(patientExistsInIdb.visitsLastUpdated);

        let now = new Date();

        if (now.getTime() - lastUpdate.getTime() >= 5 * 60 * 1000) {
          this.getNewDetails(pid);
          this.getClinical(pid);
        }
      } else {
        // call the API now.
        this.props.patientStore.patientPreviousVisits = {};
        this.getNewDetails(pid);
        this.getClinical(pid);
      }
    } else {
      this.props.patientStore.patientPreviousVisits = {};
      this.getNewDetails(pid);
      this.getClinical(pid);
    }
    /** PATTERN END */
  }
  renderThreads = () => {
    return this.props.listOfThreads.map((data, index) => {
      return (
        <BookingViewThread
          promoteKey={data}
          id={data.name}
          key={data.name}
          name={data.name}
          raw={data}
          text={
            this.props.bookingStore.isList
              ? `${
                  _.split(data.text, "|")[0] +
                  "|" +
                  _.split(data.text, "|")[1] +
                  "| " +
                  _.startCase(_.lowerCase(_.split(data.text, "|")[2]))
                }`
              : "No Data"
          }
          panels={data.panels}
          address={data.address || "No Address"}
          pid={data.sysPid}
          phone={data.phone || "No Phone"}
          referTo={this.props.bookingStore.isList ? data.referTo : "No Data"}
          age={new Date().getFullYear() - new Date(data.dob).getFullYear()}
          sex={data.sex}
          ref={(ref) => (this.threadRefs[index] = ref)}
          down={() => {
            var next =
              index === this.props.listOfThreads.length - 1 ? 0 : index + 1;
            this.focus(next);
          }}
          up={() => {
            var next =
              index === 0 ? this.props.listOfThreads.length - 1 : index - 1;
            this.focus(next);
          }}
          escape={(event) => this.props.escape(event)}
        />
      );
    });
  };
  render() {
    return <div>{this.renderThreads()}</div>;
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "patientStore"
)(observer(BookingThreadContainer));
