import { Drawer } from "@blueprintjs/core";
import React from "react";

class SyntaxTable extends React.Component {
  render() {
    return (
      <Drawer
        isOpen={this.props.showTable}
        onClose={() => {
          this.props.onClose();
        }}
        canEscapeKeyClose
        canOutsideClickClose
        icon="diagnosis"
        title="Syntax Helper"
        className="bp5-dark grad axis"
        position="right"
        style={{ backgroundColor: "#26292c" }}
        size="50vw"
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            overflow: "scroll",
            marginTop: "10px",
          }}
        >
          <table class="bp5-html-table bp5-html-table-striped">
            <thead>
              <tr>
                <th>Example</th>
                <th>Syntax</th>
                <th>Translation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  If you want to prescribe a medicine in both eyes once a day
                  for 5 days..
                </td>
                <td>BE 1x5</td>
                <td>
                  <code>Once daily for 5 days -- in both eyes.</code>
                </td>
              </tr>
              <tr>
                <td>Let's say you want to do that, but in the right eye...</td>
                <td>RE 1x5</td>
                <td>
                  <code>Once daily for 5 days -- in right eye only.</code>
                </td>
              </tr>
              <tr>
                <td>
                  Imagine you want to prescribe it for 2 months? Just append
                  'm'. Or 'w' for weeks.
                </td>
                <td>RE 1x2m</td>
                <td>
                  <code>Once daily for 2 months -- in right eye only.</code>
                </td>
              </tr>
              <tr>
                <td>Want to stagger it off? No problem, just put commas!</td>
                <td>RE 5x5,4x5,3x5</td>
                <td>
                  <code>
                    5 times daily for 5 days, then 4 times daily for 5 days, and
                    finally thrice daily for 5 days -- in right eye only.
                  </code>
                </td>
              </tr>
              <tr>
                <td>
                  Instead of a specific duration, you might want it to continue:
                  no worries; just write 'c' as the duration, or write 'r' to
                  continue till the end of the phial!
                </td>
                <td>RE 5x5,4x5,3xc</td>
                <td>
                  <code>
                    5 times daily for 5 days, then 4 times daily for 5 days, and
                    finally thrice daily to continue till next consultation --
                    in right eye only.
                  </code>
                </td>
              </tr>
              <tr>
                <td>
                  Want to factor out the frequency? Let's say, once every 4
                  hours? Just write 1/4h! (Or 1/4d! or 1/4w!)
                </td>
                <td>BE 1/4hx5</td>
                <td>
                  <code>Once every 4 hours for 5 days -- in both eyes.</code>
                </td>
              </tr>
              <tr>
                <td>
                  You can even add special instructions: sx, am, pm, ampm, hs,
                  sos, pc, ac, tiny or rice, abf, bbf, stop, punctal, continue
                  and food!
                </td>
                <td>1/2dx5w sos food rice</td>
                <td>
                  <code>
                    Once every 2 days for 5 weeks; only when needed as SOS, with
                    food, and in rice grain amounts.
                  </code>
                </td>
              </tr>
              <tr>
                <td>Play with this to build complicated expressions!</td>
                <td>BE 1/4hx3,3x1w,1x1w,1/3dx1m,1/1wxc pc</td>
                <td>
                  <code>
                    Once every 4 hours for 3 days, then thrice daily for 1 week,
                    then once daily for 1 week, then once every 3 days for 1
                    month, and finally once every week to continue till next
                    consultation; after meals -- in both eyes.
                  </code>
                </td>
              </tr>
              <tr>
                <td>Bonus...</td>
                <td>BE 4...1x5</td>
                <td>
                  <code>?</code>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Drawer>
    );
  }
}

export default SyntaxTable;
