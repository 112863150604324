import cogoToast from "cogo-toast";
import moment from "moment";
import _ from "lodash";

const axios = require("axios");
export const apiUrl = "https://api.newchart.care";
// export const apiUrl = "http://localhost:5000";
// export const apiUrl =
//   "https://13dd-2401-4900-1c69-8567-54a7-7ad3-9299-c8c1.ngrok-free.app";

export const alchemyUrl =
  "https://wc-api-service.1m4ef7p6cs5hq.ap-south-1.cs.amazonlightsail.com";
/* export const alchemyUrl = "https://b90d-116-212-182-116.ngrok-free.app"; */
/* const apiUrl =
  "https://86a5-2401-4900-1c68-c510-9d1a-2f5e-c593-4214.ngrok-free.app"; */

const CancelToken = axios.CancelToken;
let cancel;

export const fixUrlPrefix = (url) => {
  if (!url) return url;
  if (!url.includes("http")) {
    return apiUrl + url;
  } else {
    return url;
  }
};

/** AI API CALLS */

export const apiCallPostAI = async (accessToken, path, datum) => {
  if (!accessToken) {
    cogoToast.error("Access token not found.");
    throw new Error("Access Token not found");
  }
  const resp = await axios.post(alchemyUrl + path, datum, {
    credentials: "include",
    headers: { Authorization: `${accessToken}` },
  });

  return resp.data;
};

export const apiCallWhisper = async (path, token, fd, callback) => {
  let accessToken = token;
  if (!accessToken) {
    cogoToast.error("Access token not found.");
    throw new Error("Access Token not found");
  }
  const resp = await axios.post(alchemyUrl + path, fd, {
    credentials: "include",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: accessToken,
    },
    onUploadProgress: (progressEvent) =>
      callback(progressEvent.loaded, progressEvent.total),
  });
  return resp.data;
};

export const apiCallPostStreamingForAI = async (
  path,
  token,
  datum,
  onData,
  onEnd
) => {
  let accessToken = token;
  if (!accessToken) {
    cogoToast.error("Access token not found.");
    throw new Error("Access Token not found");
  }
  const resp = await fetch(alchemyUrl + path, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(datum),
  });
  const reader = resp.body.getReader();
  reader.read().then(function pump({ done, value }) {
    if (done) {
      onEnd();
      return;
    }
    let utf8decoder = new window.TextDecoder();
    onData(utf8decoder.decode(value));
    return reader.read().then(pump);
  });
};

export const apiCallPostProgress = async (path, datum, callback) => {
  let accessToken = localStorage.getItem("auth0jwt");
  if (!accessToken) {
    cogoToast.error("Access token not found.");
    throw new Error("Access Token not found");
  }

  const resp = await axios.post(apiUrl + path, datum, {
    credentials: "include",
    headers: { Authorization: accessToken },
    onUploadProgress: (progressEvent) =>
      callback(progressEvent.loaded, progressEvent.total),
  });
  return resp.data;
};
/** AI API CALLS */
export const startLowerString = (data) => {
  // Break data into words
  let words = data.split(/[_ ]+/);
  let result = "";
  // Process each word
  if (words.length > 5) {
    result = _.upperFirst(_.lowerCase(data));
  } else {
    words = words.map((word) => {
      if (
        word.length > 3 ||
        (word.length <= 3 && word.charAt(0) === word.charAt(0).toLowerCase())
      ) {
        // Convert to lowercase and then to start case
        return _.startCase(_.lowerCase(word));
      }
      return word;
    });
    result = words.join(" ");
  }

  // Combine words back together

  return result;
};
export const apiCallGet = async (path) => {
  let accessToken = localStorage.getItem("auth0jwt");
  if (!accessToken) {
    cogoToast.error("Access token not found.");
    throw new Error("Access Token not found");
  }
  const resp = await axios.get(apiUrl + path, {
    credentials: "include",
    headers: { Authorization: accessToken },
  });
  return resp.data;
};

export const apiCallPost = async (path, datum) => {
  let accessToken = localStorage.getItem("auth0jwt");
  if (!accessToken) {
    cogoToast.error("Access token not found.");
    throw new Error("Access Token not found");
  }

  const resp = await axios.post(apiUrl + path, datum, {
    credentials: "include",
    headers: { Authorization: accessToken },
  });
  return resp.data;
};

export const apiCallPostCancellable = async (path, datum) => {
  if (cancel !== undefined) {
    cancel();
  }
  try {
    let accessToken = localStorage.getItem("auth0jwt");
    if (!accessToken) {
      throw new Error("Access Token not found");
    }
    const res = await axios.post(apiUrl + path, datum, {
      credentials: "include",
      headers: { Authorization: accessToken },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
    return res.data;
  } catch (err) {
    if (axios.isCancel(err)) {
    } else return err;
  }
};

export const customSortLodash = (arr, sortOrder) => {
  // Use _.sortBy from lodash to sort based on the index in sortOrder
  return _.sortBy(arr, (item) => {
    return sortOrder.indexOf(item);
  });
};

export const formatAge = (givenDateOfBirth) => {
  let dob = new Date(givenDateOfBirth);
  let now = new Date();
  let ageMilliseconds = now - dob;
  let ageHours = Math.floor(ageMilliseconds / (1000 * 60 * 60));
  let ageDays = Math.floor(ageMilliseconds / (1000 * 60 * 60 * 24));
  let ageYears = Math.floor(ageDays / 365.25);
  let ageMonths = Math.floor((ageDays - ageYears * 365.25) / 30.44);
  let birthDayThisYear = new Date(
    now.getFullYear(),
    dob.getMonth(),
    dob.getDate()
  );
  let diffDays = Math.ceil((birthDayThisYear - now) / (1000 * 60 * 60 * 24));
  let isBirthdaySoon = diffDays >= -14 && diffDays <= 14;
  let ordinal =
    (ageYears + 1) % 10 === 1 && (ageYears + 1) % 100 !== 11
      ? "st"
      : (ageYears + 1) % 10 === 2 && (ageYears + 1) % 100 !== 12
      ? "nd"
      : (ageYears + 1) % 10 === 3 && (ageYears + 1) % 100 !== 13
      ? "rd"
      : "th";
  let birthdayMessage = isBirthdaySoon
    ? ` (${diffDays > 0 ? ageYears + 1 : ageYears}${ordinal} Birthday ${
        diffDays > 0
          ? "in " + diffDays + " days"
          : "was " + -diffDays + " days ago"
      })`
    : "";

  if (ageHours < 24) {
    return `Neonate (${ageHours} hour${ageHours > 1 ? "s" : ""})`;
  } else if (ageDays < 35) {
    return `Neonate (${ageDays} day${ageDays > 1 ? "s" : ""})`;
  } else if (ageYears < 1) {
    let remainingDays = Math.round(ageDays - ageMonths * 30.44);
    let ordinalMonth =
      (ageMonths + 1) % 10 === 1 && (ageMonths + 1) % 100 !== 11
        ? "st"
        : (ageMonths + 1) % 10 === 2 && (ageMonths + 1) % 100 !== 12
        ? "nd"
        : (ageMonths + 1) % 10 === 3 && (ageMonths + 1) % 100 !== 13
        ? "rd"
        : "th";
    let monthBirthdayMessage = isBirthdaySoon
      ? ` (${ageMonths + 1}${ordinalMonth} Month Birthday ${
          diffDays > 0
            ? "in " + diffDays + " days"
            : "was " + -diffDays + " days ago"
        })`
      : "";
    return `Infant (${ageMonths} month${ageMonths > 1 ? "s" : ""}${
      remainingDays > 0
        ? ", " + remainingDays + " day" + (remainingDays > 1 ? "s" : "")
        : ""
    })${monthBirthdayMessage}`;
  } else {
    let ageTerm =
      ageYears < 3
        ? "Toddler"
        : ageYears < 12
        ? "Child"
        : ageYears < 18
        ? "Adolescent"
        : ageYears < 60
        ? "Adult"
        : "Senior Citizen";
    return `${ageTerm} (${ageYears} year${ageYears > 1 ? "s" : ""}${
      ageMonths > 0
        ? ", " + ageMonths + " month" + (ageMonths > 1 ? "s" : "")
        : ""
    })${birthdayMessage}`;
  }
};
export const lastDayOfMonth = (d) => {
  let y = new Date(d).getFullYear();
  let m = new Date(d).getMonth();
  return new Date(y, m + 1, 0).getDate();
};
export const replaceNullAndUndefined = (obj) => {
  for (const key in obj) {
    if (obj[key] === null || typeof obj[key] === "undefined") {
      obj[key] = "";
    }
  }
  return obj;
};
export const initials = (txt) => {
  if (!txt) {
    return "NA";
  }
  return txt
    .split(" ")
    .map((n) => n[0])
    .join("");
};

export const niceDate = (date) => {
  return new Date(date).toLocaleDateString("en-IN", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};

export const niceDateWithWeek = (date) => {
  return new Date(date).toLocaleDateString("en-IN", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};
export const niceTime = (date) => {
  return new Date(date).toLocaleTimeString("en-IN", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const niceDateTime = (date) => {
  return new Date(date).toLocaleTimeString("en-IN", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const niceDateTimeWithWeek = (date) => {
  return new Date(date).toLocaleTimeString("en-IN", {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};
export const niceDateTimeFull = (date) => {
  return new Date(date).toLocaleTimeString("en-IN", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

export const isInPastDate = (date, compareDate = new Date()) => {
  let dateInQuestion = new Date(date);
  let compareTo = new Date(compareDate);
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);

  if (compareTo - dateInQuestion >= 0) {
    // compareDate or Future
    return true;
  } else {
    return false;
  }
};
export const isInPastDateStrict = (date, compareDate = new Date()) => {
  // if date in question is in the past wrt compareDate return true.
  let dateInQuestion = new Date(date);
  let compareTo = new Date(compareDate);
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);

  if (compareTo - dateInQuestion > 0) {
    // Future
    return true;
  } else {
    return false;
  }
};

export const isInFutureDate = (date, compareDate = new Date()) => {
  let dateInQuestion = new Date(date);
  let compareTo = new Date(compareDate);
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);

  if (compareTo - dateInQuestion <= 0) {
    // compareDate or Future
    return true;
  } else {
    return false;
  }
};
export const isInFutureDateStrict = (date, compareDate = new Date()) => {
  let dateInQuestion = new Date(date);
  let compareTo = new Date(compareDate);
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);
  if (compareTo - dateInQuestion < 0) {
    // Future
    return true;
  } else {
    return false;
  }
};

export const isDateToday = (date) => {
  let dateInQuestion = new Date(date);
  let compareTo = new Date();
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);

  if (compareTo - dateInQuestion === 0) {
    return true;
  } else {
    return false;
  }
};
export const areTwoDatesSame = (d1, d2) => {
  let dateInQuestion = new Date(d1);
  let compareTo = new Date(d2);
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);

  if (compareTo - dateInQuestion === 0) {
    return true;
  } else {
    return false;
  }
};

export const yearFromNow = (d) => {
  const ageYr = moment().diff(moment(d), "year");
  if (ageYr > 0) {
    return [ageYr, ageYr === 1 ? "year" : "years"];
  }
  const ageMth = moment().diff(moment(d), "month");
  if (ageMth > 0) {
    return [ageMth, ageMth === 1 ? "month" : "months"];
  }
  const ageDay = moment().diff(moment(d), "day");
  if (ageDay > 0) {
    return [ageDay, ageDay === 1 ? "day" : "days"];
  }
  return ["NA", ""];
};

export const dateFromNow = (date, bool) => {
  return moment(date).fromNow(bool);
};
export const datesBetween = (date1, date2) => {
  let d1 = moment(date1);
  let d2 = moment(date2);
  return d1.from(d2, false);
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  cogoToast.success("Copied to clipboard", { position: "bottom-left" });
};

export const timeStringHrs = (minutes) => {
  let timeString = `${minutes}m`;
  if (minutes >= 60) {
    let hrs = Math.floor(minutes / 60);
    let mins = minutes % 60;
    timeString = `${hrs}h ${mins}m`;
  }
  return timeString;
};
export const indianRupee = (amountPaise, fractionDigits, inPaise = true) => {
  let amount = amountPaise;
  if (inPaise) {
    amount = parseInt(amountPaise / 100);
  } else {
    amount = parseInt(amountPaise);
  }
  return amount.toLocaleString("en-IN", {
    maximumFractionDigits: fractionDigits,
    style: "currency",
    currency: "INR",
  });
};

export const transposeDioptre = (
  { sphere = 0, cylinder = 0, axis = 0, near = 0, pd = null },
  focal = "Both"
) => {
  sphere = parseFloat(sphere);
  cylinder = parseFloat(cylinder);
  axis = parseFloat(axis);
  near = parseFloat(near);
  if (pd) {
    pd = parseInt(pd);
  }
  if (cylinder > 0) {
    sphere = sphere + cylinder;
    cylinder = -cylinder;
    axis = (axis + 90) % 180;
  }
  let dioptre = { sphere, cylinder, axis, near };
  let sphString = sphere.toFixed(2);
  let cylString = "";
  let addString = "";
  if (cylinder) {
    cylString = " x " + cylinder.toFixed(2) + " x " + axis;
  } else {
    cylString = " DS";
  }
  if (near) {
    addString = " Add " + near.toFixed(2);
  }

  let pdString = "";
  if (pd) {
    pdString = " with PD: " + pd + " mm";
  }
  let dioptreString = "";
  if (focal === "Both") {
    dioptreString = sphString + cylString + addString + pdString;
  }
  if (focal === "Near Only") {
    dioptreString = _.trimStart(addString + pdString);
  }
  if (focal === "Near Only" && !near) {
    dioptreString = "Plano" + pdString;
  }
  if (focal === "Far Only") {
    dioptreString = sphString + cylString + pdString;
  }

  dioptre["string"] = dioptreString;
  return dioptre;
};

export const renderCorrectedIop = (c, i) => {
  if (!i) {
    return i;
  }
  if (!c) {
    return i;
  }
  if (i === "---") {
    return "---";
  }
  let iop = Number(i);
  let cct = Number(c);
  if (cct <= 554 && cct >= 536) {
    return iop;
  }
  if (cct <= 535 && cct >= 516) {
    return iop + 1;
  }
  if (cct <= 515 && cct >= 506) {
    return iop + 2;
  }
  if (cct <= 505 && cct >= 496) {
    return iop + 3;
  }
  if (cct <= 495 && cct >= 476) {
    return iop + 4;
  }
  if (cct <= 475 && cct >= 466) {
    return iop + 5;
  }
  if (cct <= 465 && cct >= 446) {
    return iop + 6;
  }
  if (cct <= 445) {
    return iop + 7;
  }
  if (cct <= 574 && cct >= 555) {
    return iop - 1;
  }
  if (cct <= 584 && cct >= 575) {
    return iop - 2;
  }
  if (cct <= 594 && cct >= 585) {
    return iop - 3;
  }
  if (cct <= 614 && cct >= 595) {
    return iop - 4;
  }
  if (cct <= 624 && cct >= 615) {
    return iop - 5;
  }
  if (cct <= 644 && cct >= 625) {
    return iop - 6;
  }
  if (cct >= 645) {
    return iop - 7;
  }
};

/* 
nameOfFunction = async () => {
  try {
    cogoToast.loading("Text...", { position: "bottom-left" });
    let payload = {};
    let res = await apiCallPost(
      `/path/${this.props.authStore.prefix}/endpoint`,
      payload
    );
    cogoToast.success("Text", { position: "bottom-left" });

    this.setState({ lineItems: res });
  } catch (err) {
    cogoToast.error(
      "There was an error ACTION. Please check the console for more details.",
      { position: "bottom-left" }
    );
    console.log(err);
  }
};
 */
