import { Drawer, Tag } from "@blueprintjs/core";
import React from "react";

class PrefixTable extends React.Component {
  render() {
    return (
      <Drawer
        isOpen={this.props.showPrefix}
        onClose={() => {
          this.props.onClose();
        }}
        canEscapeKeyClose
        canOutsideClickClose
        icon="search"
        title="Prefix Helper"
        className="bp5-dark grad axis"
        position="right"
        style={{ backgroundColor: "#26292c" }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            overflow: "scroll",
            marginTop: "10px",
          }}
        >
          <table class="bp5-html-table bp5-html-table-striped ">
            <thead>
              <tr>
                <th>Prefix</th>
                <th>Refers To</th>
                <th>Sample Searches</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("sym", "symptoms")}
                  >
                    <code>sym</code>
                  </Tag>
                </td>
                <td>Symptoms</td>
                <td>
                  <code>sym redness, sym itch</code>
                </td>
              </tr>

              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("sign", "findings")}
                  >
                    <code>sign</code>
                  </Tag>
                </td>
                <td>Signs and Findings</td>
                <td>
                  <code>sign cnvm, sign ns1</code>
                </td>
              </tr>

              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("dx", "eyeDx")}
                  >
                    <code>dx</code>
                  </Tag>
                </td>
                <td>Eye Diseases</td>
                <td>
                  <code>dx cataract, dx npdr</code>
                </td>
              </tr>

              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("adv", "advice")}
                  >
                    <code>adv</code>
                  </Tag>
                </td>
                <td>adv</td>
                <td>
                  <code>adv cold compress, adv sunglass</code>
                </td>
              </tr>

              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("inv", "investigations")}
                  >
                    <code>inv</code>
                  </Tag>
                </td>
                <td>Investigations</td>
                <td>
                  <code>inv oct, inv biometry</code>
                </td>
              </tr>

              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("proc", "procedures")}
                  >
                    <code>proc</code>
                  </Tag>
                </td>
                <td>Procedures</td>
                <td>
                  <code>proc yag, proc laser</code>
                </td>
              </tr>
              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("fu", "followUpWith")}
                  >
                    <code>fu</code>
                  </Tag>
                </td>
                <td>Follow Up Investigations and Procedures</td>
                <td>
                  <code>fu ecg, fu oct </code>
                </td>
              </tr>
              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("all", "allergies")}
                  >
                    <code>all</code>
                  </Tag>
                </td>
                <td>Allergies</td>
                <td>
                  <code>all sulfa, all homa</code>
                </td>
              </tr>

              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("fh", "famHist")}
                  >
                    <code>fh</code>
                  </Tag>
                </td>
                <td>Family History</td>
                <td>
                  <code>fh glaucoma, fh diabetes</code>
                </td>
              </tr>

              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("sys-dx", "sysDx")}
                  >
                    <code>sys-dx</code>
                  </Tag>
                </td>
                <td>Systemic Diseases</td>
                <td>
                  <code>sys-dx dm, sys-dx htn</code>
                </td>
              </tr>

              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("sys-sx", "sysSx")}
                  >
                    <code>sys-sx</code>
                  </Tag>
                </td>
                <td>Systemic Surgeries</td>
                <td>
                  <code>sys-sx cns, sys-sx angio</code>
                </td>
              </tr>

              <tr>
                <td>
                  <Tag
                    minimal
                    interactive
                    onClick={() => this.props.onSearch("prev-sx", "sysEyeSx")}
                  >
                    <code>prev-sx</code>
                  </Tag>
                </td>
                <td>Previous Eye Surgeries (Done Elsewhere)</td>
                <td>
                  <code>prev-sx accentrix, prev-sx phaco</code>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Drawer>
    );
  }
}

export default PrefixTable;
