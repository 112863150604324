import React from "react";
import _ from "lodash";
import { generateMasterVisitEntities } from "../masterVisitEntities";
import {
  apiCallPost,
  apiCallPostAI,
  apiCallPostStreamingForAI,
} from "../../fns/util";
import cogoToast from "cogo-toast";
import { masterSystemPrompts } from "../masterSystemPrompts";
import { Card, ControlGroup, Spinner, Tag } from "@blueprintjs/core";
import { inject, observer } from "mobx-react";

class TurboSection extends React.Component {
  state = {
    type: "",
    textToStructure: "",
    section: null,
    subsection: null,
    statusText: "",
    outputText: "",
    isAIActivityRunning: false,
    isLoading: true,
    isDone: false,
    isError: false,
  };
  componentDidMount() {
    let text = this.props.data.text;
    let splits = text.split("|||").map((x) => _.trim(x));
    let type = splits[0];
    let tt = splits[1];
    let section, subsection;
    if (type === "INVESTIGATIONS_AND_PROCEDURES") {
      section = "INVESTIGATIONS_AND_PROCEDURES";
      subsection = {
        subsectionName: "INVESTIGATIONS_AND_PROCEDURES",
        subsectionNiceName: "Investigations and Procedures",
        searchEntityType: "INVESTIGATION",
        useAIActions: ["FILES_DICTATION_STRUCTURE"],
      };
    } else {
      let mve = generateMasterVisitEntities(this.props.authStore.prefix);
      for (let i = 0; i < mve.length; i++) {
        let subsections = mve[i].subsections;
        for (let j = 0; j < subsections.length; j++) {
          if (subsections[j].subsectionName === type) {
            section = mve[i];
            subsection = subsections[j];
            break;
          }
        }
      }
      if (!section || !subsection) {
        this.setState(
          {
            isError: true,
          },
          () => {
            this.props.onError();
          }
        );
        return;
      }
    }
    this.setState(
      {
        type: type,
        textToStructure: tt,
        section: section,
        subsection: subsection,
        isLoading: false,
      },
      () => this.promptChainer()
    );
  }
  createNew = async (payload) => {
    try {
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/extra/createInvestigationFile`,
        payload
      );
      this.props.onFile(res);
    } catch (err) {
      console.log(err);
      cogoToast.error("Error creating file.");
    }
  };
  onDone = () => {
    let text = this.props.data.text;
    let splits = text.split("|||").map((x) => _.trim(x));
    let type = splits[0];
    if (type === "INVESTIGATIONS_AND_PROCEDURES") {
      let rawText = this.state.outputText;
      let arr = rawText
        .split("\n")
        .filter(Boolean)
        .map((each) => _.trim(each, " "))
        .map((each) => each.split("|||"));
      let processedData = [];
      for (var i = 0; i < arr.length; i++) {
        let newElem = {};
        newElem["entityName"] = arr[i][0];
        newElem["laterality"] = arr[i][1] === "NONE" ? "" : arr[i][1];
        newElem["organ"] = newElem["laterality"] ? "EYE" : "";
        newElem["notes"] = _.trim(arr[i][1], " ");
        processedData.push(newElem);
      }
      processedData.forEach((each) => {
        let payload = { ...each };
        payload["patientId"] = this.props.patientId;
        payload["fileFrom"] = each.entityName;
        payload["organ"] = each.organ || "";
        payload["anatomicalPart"] = each.anatomicalPart || "";
        payload["laterality"] = each.laterality || "";
        payload["associatedVisitId"] = this.props.visitId;
        payload["notes"] = each.notes || "";
        payload["data"] = each.data && each.data.length ? each.data : [];
        this.createNew(payload);
      });
      this.setState(
        {
          isAIActivityRunning: false,
          isDone: true,
        },
        () => this.props.onDoneTime(new Date())
      );
    } else {
      let structured = this.state.subsection.afterAIActions(
        this.state.outputText
      );
      let isArray = structured.isArray;
      let processedData = structured.processedData;
      if (!isArray) {
        processedData = [processedData];
      }
      processedData.forEach((each) => {
        let subsection = this.state.subsection;
        let fields = subsection.fields;
        let newEntity = {};
        for (var i = 0; i < fields.length; i++) {
          let field = fields[i];
          newEntity[field.keyName] = each[field.keyName]
            ? each[field.keyName]
            : field.keyType === "BOOLEAN"
            ? false
            : "";
        }
        let payload = { ...each };
        payload["entityType"] =
          this.state.subsection.searchEntityType ||
          `${this.state.subsection.subsectionName}`;
        payload["sectionName"] = this.state.section.sectionName;
        payload["subsectionName"] = this.state.subsection.subsectionName;
        payload["provenance"] = "TURBO_AI_GENERATED";
        payload["numSearchesBeforeAdding"] = 0;
        payload["canonicalId"] = [];
        payload["canonicalLinkedBy"] = "";
        payload["edited"] = false;
        payload["notes"] = each.notes || "";
        this.props.addVisitEntity(payload);
      });
      this.setState(
        {
          isAIActivityRunning: false,
          isDone: true,
        },
        () => this.props.onDoneTime(new Date())
      );
    }
  };
  promptChainer = async () => {
    let payloadContent = this.state.textToStructure;
    try {
      this.setState({
        statusText: "Warming up model",
        isAIActivityRunning: true,
        isDone: false,
      });
      let createChatRes = await apiCallPostAI(
        this.props.alchemyToken,
        "/chat/createChatConversation",
        {
          applicationCtxId: this.props.visitId,
          tool: this.state.subsection.useAIActions[0],
          promptHead:
            masterSystemPrompts[this.state.subsection.useAIActions[0]]
              .promptHead,
          model: "gpt-4o",
          temperature: 0.9,
          engine: "openai",
        }
      );
      let chatId = createChatRes._id;
      try {
        this.setState({
          statusText:
            masterSystemPrompts[this.state.subsection.useAIActions[0]]
              .statusText,
          outputText: "",
        });
        let payload = {
          conversationId: chatId,
          lastContext: {
            role: "user",
            content: payloadContent,
          },
        };
        await apiCallPostStreamingForAI(
          "/chat/generateChatCompletionSSE",
          this.props.alchemyToken,
          payload,
          (data) => {
            this.setState({
              outputText: this.state.outputText + data,
            });
          },
          () => {
            this.onDone();
          }
        );
      } catch (err) {
        this.setState(
          {
            isError: true,
          },
          () => {
            this.props.onError();
          }
        );
        console.log(err);
        cogoToast.error("We ran into an error polishing the transcription.");
      }
    } catch (err) {
      this.setState(
        {
          isError: true,
        },
        () => {
          this.props.onError();
        }
      );
      console.log(err);
      cogoToast.error(
        "We ran into an error initating the transcription clean up."
      );
    }
  };
  render() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    if (this.state.isError) {
      return (
        <Card className="card__container">
          <p className="small_text">
            We ran into an error. The text was: {this.props.data.text}
          </p>
        </Card>
      );
    }
    if (this.state.isDone) {
      return (
        <Card className="card__container">
          <ControlGroup>
            <Tag minimal intent="success">
              {this.state.subsection.subsectionNiceName}
            </Tag>
            <Tag icon="endorsed" intent="success" className="forest__bg">
              Done!
            </Tag>
          </ControlGroup>
          <p className="small_text">{this.state.textToStructure}</p>
        </Card>
      );
    }
    if (this.state.isAIActivityRunning) {
      return (
        <Card className="card__container">
          <ControlGroup>
            <Tag minimal>{this.state.subsection.subsectionNiceName}</Tag>
            <Spinner size={20} />
          </ControlGroup>
          <p className="small_text">{this.state.outputText}</p>
        </Card>
      );
    }

    return (
      <Card className="card__container">
        <p className="small_text">
          {this.state.subsection.subsectionNiceName}:{" "}
          {this.state.textToStructure}
        </p>
      </Card>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(TurboSection));
