import {
  Button,
  Card,
  Collapse,
  ControlGroup,
  Elevation,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import { niceDateTime } from "../fns/util";
import MultiSelectWrapper from "../utils/MultiSelectWrapper";
import Avatar from "react-avatar";
import _ from "lodash";
import MutEx from "../utils/MutEx";

class UserCard extends React.Component {
  state = {
    detail: false,
  };
  scopeList = [
    "booking",
    "billing",
    "mediclaim",
    "scheduling",
    "users",
    "reports",
    "lists",
    "clinical",
    "doctor",
    "optom",
    "agent",
    "meta-chrono",
    "meta-prices",
  ];
  renderPrefs = () => {
    let appPrefs = [];
    _.forEach(this.props.user.preferences, (each, key) => {
      if (
        _.intersection(this.props.user.scopes, each.applicable_scopes).length
      ) {
        let obj = {
          what: key,
          ...each,
        };
        appPrefs.push(obj);
      }
    });
    return appPrefs.map((each) => {
      return (
        <ControlGroup>
          <Tag large minimal>
            {each.Name}
          </Tag>
          <MutEx
            options={each.choice}
            selected={each.value}
            onUpdate={(updated) => {
              this.props.updatePrefs(this.props.user._id, each.what, updated);
            }}
          />
        </ControlGroup>
      );
    });
  };
  render() {
    return (
      <Card
        style={{
          background: "none",
          margin: "30px",
        }}
        className="bp5-dark"
        elevation={Elevation.FOUR}
      >
        {!this.state.detail ? (
          <ControlGroup fill>
            <Tag
              minimal
              large
              interactive
              fill
              intent={
                this.props.user.scopes.includes("users")
                  ? "success"
                  : this.props.user.scopes.includes("doctor")
                  ? "primary"
                  : null
              }
              onClick={() => this.setState({ detail: true })}
            >
              {this.props.user.name}
            </Tag>
          </ControlGroup>
        ) : (
          <Collapse isOpen={this.state.detail} keepChildrenMounted={false}>
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "row",
                overflowX: "scroll",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Avatar
                  name={this.props.user.name}
                  email={this.props.user.email}
                  src={this.props.user.image}
                  size={120}
                  round
                  style={{ boxShadow: "1px 2px 5px black" }}
                />
              </div>
              <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                <ControlGroup vertical>
                  <Tag
                    large
                    intent="primary"
                    interactive
                    onClick={() => this.setState({ detail: false })}
                  >
                    {this.props.user.name}
                  </Tag>
                  <Tag
                    large
                    minimal
                    intent={this.props.user.isActive ? "success" : "danger"}
                  >
                    {this.props.user.isActive ? "Active" : "Inactive"}
                  </Tag>
                  <Tag large minimal intent="none">
                    {this.props.user.email}
                  </Tag>
                  {!this.props.user.isActive ? (
                    <Button
                      fill
                      outlined
                      intent="success"
                      text="Activate"
                      icon="endorsed"
                      onClick={() => this.props.toggle(this.props.user._id)}
                    />
                  ) : (
                    <Button
                      fill
                      outlined
                      intent="warning"
                      text="Suspend"
                      icon="disable"
                      onClick={() => this.props.toggle(this.props.user._id)}
                    />
                  )}
                </ControlGroup>
              </div>
              <div style={{ paddingRight: "20px" }}>
                <ControlGroup vertical>
                  <ControlGroup>
                    <Tag intent="primary" minimal>
                      Last Logged In
                    </Tag>
                    <Tag intent="primary" fill minimal>
                      {niceDateTime(this.props.user.lastLoggedIn)}
                    </Tag>
                  </ControlGroup>
                  <ControlGroup>
                    <Tag intent="warning" minimal>
                      OAuth ID
                    </Tag>
                    <Tag intent="warning" fill minimal>
                      {this.props.user.oauthId}
                    </Tag>
                  </ControlGroup>
                  <ControlGroup>
                    <Tag intent="success" minimal>
                      User ID
                    </Tag>
                    <Tag intent="success" fill minimal>
                      {this.props.user._id}
                    </Tag>
                  </ControlGroup>
                </ControlGroup>
                {this.renderPrefs()}
              </div>
              <div style={{ paddingRight: "20px" }}>
                <ControlGroup vertical>
                  <ControlGroup>
                    <Tag intent="primary" minimal>
                      Signed Up
                    </Tag>
                    <Tag intent="primary" fill minimal>
                      {niceDateTime(this.props.user.signedUp)}
                    </Tag>
                  </ControlGroup>
                  <ControlGroup>
                    <Tag intent="warning" minimal>
                      Identity Provider
                    </Tag>
                    <Tag intent="warning" fill minimal>
                      {this.props.user.identityProvider}
                    </Tag>
                  </ControlGroup>
                  <ControlGroup>
                    <Tag intent="success" minimal>
                      Added By
                    </Tag>
                    <Tag intent="success" fill minimal>
                      {this.props.user.addedBy}
                    </Tag>
                  </ControlGroup>
                </ControlGroup>
              </div>
              <div>
                <ControlGroup vertical>
                  <Tag intent="primary" minimal large fill>
                    Scopes
                  </Tag>
                  {this.props.myScope.includes("users") ? (
                    <MultiSelectWrapper
                      items={this.scopeList}
                      selectedItems={this.props.user.scopes}
                      onChange={(items) =>
                        this.props.scopeChange(this.props.user._id, items)
                      }
                    />
                  ) : (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {this.props.user.scopes.map((each) => (
                        <Tag minimal>{each}</Tag>
                      ))}
                    </div>
                  )}
                </ControlGroup>
              </div>
            </div>
          </Collapse>
        )}
      </Card>
    );
  }
}

export default UserCard;
