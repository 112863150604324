import { Button, Tooltip } from "@blueprintjs/core";
import React from "react";
import { observer, inject } from "mobx-react";
import { copyToClipboard } from "../fns/util";
import LoginButton from "../utils/LoginButton";

class Landing extends React.Component {
  render() {
    return (
      <div className="landing bp5-dark">
        <div className="searchbar-title">NewChart</div>
        <br />

        <LoginButton />

        <div className="footer">
          Radical Health <br />
          <br />
          <br />
          <div style={{ fontSize: "smaller" }}>Internal Use Only</div> <br />
          <br />
          <Tooltip
            content="human@radicalhealth.care"
            className="bp5-dark"
            minimal
          >
            <Button
              minimal
              intent="primary"
              text="Email Support"
              rightIcon="duplicate"
              icon="envelope"
              onClick={() => copyToClipboard("human@radicalhealth.care")}
            />
          </Tooltip>
          <Tooltip content="+919560094626" className="bp5-dark" minimal>
            <Button
              minimal
              intent="success"
              text="Call Support"
              rightIcon="duplicate"
              icon="phone"
              onClick={() => copyToClipboard("+919560094626")}
            />
          </Tooltip>
        </div>
      </div>
    );
  }
}
export default inject("authStore")(observer(Landing));
