import React from "react";
import { generateMasterVisitEntities } from "./masterVisitEntities";
import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { inject, observer } from "mobx-react";
import GPTPrompter from "./GPTPrompter";
import CanonicalSearcher from "./CanonicalSearcher";
import _ from "lodash";
import ClinicalEntity from "./ClinicalEntity";

class ClinicalSubsection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numSearches: 0,
      section: null,
      subsection: null,
      selectedInputMode: "",
      searchText: "",
    };
    this.masterVisitEntities = generateMasterVisitEntities(
      this.props.authStore.prefix
    );
    this.lastEntityRef = React.createRef();
  }
  componentDidMount() {
    if (!this.props.sectionName || !this.props.subsectionName) return;
    let section = this.masterVisitEntities.find(
      (x) => x.sectionName === this.props.sectionName
    );
    let subsection = section.subsections.find(
      (y) => y.subsectionName === this.props.subsectionName
    );
    this.setState({ section, subsection }, () => {
      if (this.props.mode === "CLINICAL") {
        if (this.state.subsection.hasAI) {
          let defMode = "AI";
          // Hospital overrides...
          if (this.state.subsection.fieldType === "SEARCH") {
            if (this.props.defaultClinicalSearchMode) {
              defMode = this.props.defaultClinicalSearchMode;
            }
          }
          this.setState({
            selectedInputMode: defMode, // this.props.defaultClinicalSearchMode ?? "AI",
          });
        } else if (this.state.subsection.fieldType === "SEARCH") {
          this.setState({
            selectedInputMode: "Search",
          });
        }
      } else {
        if (this.state.subsection.aiInTemplate) {
          this.setState({
            selectedInputMode: "AI",
          });
        } else if (this.state.subsection.fieldType === "SEARCH") {
          this.setState({
            selectedInputMode: "Search",
          });
        }
      }
    });
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.visitEntities.length > 0 &&
      prevProps.visitEntities.length !== this.props.visitEntities.length
    ) {
      if (this.lastEntityRef && this.lastEntityRef.current) {
        this.lastEntityRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
  renderHeader = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "42vw" }}>
        <Card
          elevation={Elevation.THREE}
          style={{
            padding: "5px",
            width: "42vw",
          }}
        >
          <ControlGroup vertical>
            <ControlGroup>
              <Tag minimal>Currently you are entering:</Tag>
            </ControlGroup>
            <ControlGroup style={{ width: "35vw" }}>
              <Tag intent="success" className="forest_dark" multiline>
                {this.state.section.sectionNiceName}
              </Tag>
              <Tag multiline minimal>
                {this.state.section.helper}
              </Tag>
            </ControlGroup>
            <ControlGroup style={{ width: "35vw" }}>
              <Tag intent="success" className="cerulean_dark" multiline>
                {this.state.subsection.subsectionNiceName}
              </Tag>
              <Tag multiline minimal>
                {this.state.subsection.helper}
              </Tag>
            </ControlGroup>
          </ControlGroup>
        </Card>
      </div>
    );
  };
  renderGPTArea = () => {
    return (
      <GPTPrompter
        visitId={this.props.visitId}
        promptChain={this.state.subsection.useAIActions}
        onDone={(output) => {
          let structured = this.state.subsection.afterAIActions(output);
          let isArray = structured.isArray;
          let processedData = structured.processedData;
          if (!isArray) {
            processedData = [processedData];
          }
          processedData.forEach((each) => {
            let subsection = this.state.subsection;
            let fields = subsection.fields;
            let newEntity = {};
            for (var i = 0; i < fields.length; i++) {
              let field = fields[i];
              newEntity[field.keyName] = each[field.keyName]
                ? each[field.keyName]
                : field.keyType === "BOOLEAN"
                ? false
                : "";
            }
            let payload = { ...each };
            payload["entityType"] =
              this.state.subsection.searchEntityType ||
              `${this.state.subsection.subsectionName}`;
            payload["sectionName"] = this.state.section.sectionName;
            payload["subsectionName"] = this.state.subsection.subsectionName;
            payload["provenance"] = "AI_GENERATED";
            payload["numSearchesBeforeAdding"] = 0;
            payload["canonicalId"] = [];
            payload["canonicalLinkedBy"] = "";
            payload["edited"] = false;
            payload["notes"] = each.notes || "";
            if (
              payload["organ"] &&
              payload["organ"] === "EYE" &&
              !payload["laterality"]
            ) {
              payload["laterality"] = "BILATERAL";
            }
            this.props.addVisitEntity(payload);
          });
        }}
      />
    );
  };
  renderClinicalSearch = () => {
    return (
      <div style={{ display: "flex" }}>
        <CanonicalSearcher
          searchTagName={_.startCase(
            _.lowerCase(this.state.subsection.searchEntityType)
          )}
          placeholder={this.state.subsection.subsectionNiceName}
          onChange={(item) => {
            let subsection = this.state.subsection;
            let fields = subsection.fields;
            let newEntity = {};
            for (var i = 0; i < fields.length; i++) {
              let field = fields[i];
              newEntity[field.keyName] = item[field.keyName]
                ? item[field.keyName]
                : field.keyType === "BOOLEAN"
                ? false
                : "";
            }
            let payload = {
              ...newEntity,
            };
            payload["entityType"] =
              this.state.subsection.searchEntityType ||
              `${this.state.subsection.subsectionName}`;
            payload["sectionName"] = this.state.section.sectionName;
            payload["subsectionName"] = this.state.subsection.subsectionName;
            payload["provenance"] = "SEARCH";
            payload["numSearchesBeforeAdding"] = this.state.numSearches;
            payload["canonicalId"] = [item._id];
            payload["canonicalLinkedBy"] = "CLINICAL_SEARCH";
            payload["edited"] = false;
            payload["notes"] = "";
            if (
              payload["organ"] &&
              payload["organ"] === "EYE" &&
              !payload["laterality"]
            ) {
              payload["laterality"] = "BILATERAL";
            }
            delete payload._id;
            delete payload.item;
            this.setState({ searchText: "" });
            this.props.addVisitEntity(payload);
          }}
          onSearch={() => {
            this.setState({
              numSearches: this.state.numSearches + 1,
            });
          }}
          onType={(item) => {
            this.setState({ searchText: item });
          }}
          entityType={this.state.subsection.searchEntityType}
        />

        {this.props.mode === "CLINICAL" && this.state.numSearches > 2 && (
          <Button
            small
            className="colfax"
            icon="add"
            text="Add Manually"
            onClick={() => {
              let section = this.state.section;
              let subsection = this.state.subsection;
              let fields = subsection.fields;
              let newEntity = {};
              for (var i = 0; i < fields.length; i++) {
                let field = fields[i];
                newEntity[field.keyName] =
                  field.keyType === "BOOLEAN" ? false : "";
              }
              newEntity["entityType"] =
                subsection.searchEntityType || `${subsection.subsectionName}`;
              newEntity["entityName"] = this.state.searchText;
              newEntity["provenance"] = "MANUAL";
              newEntity["canonicalId"] = [];
              newEntity["canonicalLinkedBy"] = "";
              newEntity["edited"] = false;
              newEntity["notes"] = "";
              newEntity["numSearchesBeforeAdding"] = this.state.numSearches;
              newEntity["sectionName"] = section.sectionName;
              newEntity["subsectionName"] = subsection.subsectionName;
              if (
                newEntity["organ"] &&
                newEntity["organ"] === "EYE" &&
                !newEntity["laterality"]
              ) {
                newEntity["laterality"] = "BILATERAL";
              }
              this.props.addVisitEntity(newEntity);
            }}
          />
        )}
      </div>
    );
  };
  renderInputCard = () => {
    let options = [];
    if (this.props.mode === "CLINICAL") {
      if (this.state.subsection.hasAI) {
        options.push("AI");
      }
      if (this.state.subsection.fieldType === "SEARCH") {
        options.push("Search");
      }
    } else {
      if (this.state.subsection.aiInTemplate) {
        options.push("AI");
      }
      if (this.state.subsection.fieldType === "SEARCH") {
        options.push("Search");
      }
    }
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Card
          elevation={Elevation.THREE}
          style={{
            padding: "5px",
          }}
        >
          <ControlGroup>
            <Tag minimal>Input Modes Available:</Tag>
            {options.map((each, i) => (
              <Button
                key={i}
                className={
                  this.state.selectedInputMode === each
                    ? "colfax rose_dark"
                    : "colfax"
                }
                small
                minimal={this.state.selectedInputMode !== each}
                text={each}
                onClick={() => this.setState({ selectedInputMode: each })}
              />
            ))}
          </ControlGroup>
          {this.state.selectedInputMode === "AI" && this.renderGPTArea()}
          {this.state.selectedInputMode === "Search" &&
            this.renderClinicalSearch()}
        </Card>
      </div>
    );
  };
  renderEntities = () => {
    let entities = this.props.visitEntities;
    let sectionName = this.state.section.sectionName;
    let subsectionName = this.state.subsection.subsectionName;
    let filteredEntities = entities.filter(
      (x) =>
        x.sectionName === sectionName && x.subsectionName === subsectionName
    );
    if (this.props.reverseEntityOrder) {
      filteredEntities = _.reverse([...filteredEntities]);
    }

    return (
      <div
        key={`
        ${filteredEntities.map((x) => `${x._id}_${x.updatedAt}`).join("_")}
          `}
      >
        {filteredEntities.map((each, index) => {
          let recentItems = this.props.recentItems;
          let recentItemIds = recentItems.map((x) => x._id);
          let myId = each._id;
          let className = "no__bg";
          let isRecent = _.includes(recentItemIds, myId);
          if (isRecent) {
            let which = _.filter(recentItems, (x) => x._id === myId);
            if (which.length > 1) {
              className = "cerulean__bg";
            } else {
              className = which[0].type === "ADD" ? "lime__bg" : "cerulean__bg";
            }
          }

          return (
            <div
              style={{ paddingBottom: "1px" }}
              className={className}
              key={`${index}__${each._id}__${each.updatedAt}__${filteredEntities.length}`}
              ref={
                index === filteredEntities.length - 1
                  ? this.lastEntityRef
                  : null
              }
            >
              <ClinicalEntity
                isShortForm={each.isShortForm}
                data={each}
                key={`${index}__${each._id}__${each.updatedAt}`}
                subsection={this.state.subsection}
                entity={each}
                updateVisitEntity={(item) => {
                  this.props.updateVisitEntity(item);
                }}
                removeVisitEntity={() => {
                  this.props.removeVisitEntity(each._id);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    if (!this.state.section || !this.state.subsection)
      return <Spinner size={20} />;
    return (
      <div
        style={{
          borderLeft: "1px solid #404854",
          paddingLeft: "10px",
          width: "43vw",
        }}
      >
        <Card
          style={{
            padding: "3px",
            backgroundColor: "#111418",
            marginBottom: "10px",
          }}
        >
          {this.renderHeader()}
        </Card>
        <Card
          style={{
            padding: "3px",
            backgroundColor: "#111418",
            marginBottom: "10px",
          }}
        >
          {this.renderInputCard()}
        </Card>

        {this.renderEntities()}
      </div>
    );
  }
}

// export default ClinicalSubsection;

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(ClinicalSubsection));
