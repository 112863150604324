import {
  Button,
  Card,
  ControlGroup,
  NonIdealState,
  Spinner,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import React from "react";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import ClinicalLoader from "./ClinicalLoader";
import {
  apiCallPostAI,
  apiCallPostCancellable,
  apiCallPostStreamingForAI,
  apiCallWhisper,
} from "../fns/util";
import cogoToast from "cogo-toast";
import RecordRTC from "recordrtc";
import TextWithEllipsis from "./TextWithEllipsis";
import { masterSystemPrompts } from "./masterSystemPrompts";

class GPTPrompter extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    alchemyToken: "",
    isAIActivityRunning: false,
    mode: "TYPE",
    statusText: "Waiting For You",
    /** AUDIO CONTROLS */
    recordingTime: 0,
    recordedBlob: null,
    recordedUrl: null,
    /** TEXT INPUTS */
    inputText: "",
    /** AI OUTPUT TEXTS */
    outputText: "",
  };
  componentDidMount() {
    this.getAlchemyToken();
  }
  /** API Calls */
  getAlchemyToken = async () => {
    const alToken = localStorage.getItem("alchemyToken");
    if (alToken) {
      this.setState({ alchemyToken: alToken, isLoading: false });
      return;
    }

    try {
      let res = await apiCallPostCancellable("/gateway/alchemy", {});
      if (res) {
        this.setState(
          {
            alchemyToken: res.token,
            isLoading: false,
            isError: false,
          },
          () => {
            localStorage.setItem("alchemyToken", res.token);
          }
        );
      }
    } catch (err) {
      this.setState({ isError: true, isLoading: false });
      // cogoToast.error("Error getting Alchemy token.");
    }
  };
  transcribeAudio = async () => {
    try {
      this.setState({
        mode: "TRANSCRIBE",
        isAIActivityRunning: true,
        statusText: "Uploading your audio",
      });
      const formData = new FormData();
      formData.append("tool", "NC_VOICE_INPUT");
      // TODO: INCLUDE VISIT ID
      formData.append("applicationCtxId", this.props.visitId);
      formData.append("file", this.state.recordedBlob);
      formData.append(
        "prompt",
        "This is a snippet from a recording from an ophthalmic note. Transcribe it accurately in English. Carefully consider words to have the right technical spelling as is standard in medicine."
      );
      let res = await apiCallWhisper(
        `/stt/generateTranscription`,
        this.state.alchemyToken,
        formData,
        (done, total) => {
          let donePercentage = done / total;
          if (done / total > 0.99) {
            this.setState({
              statusText: `Transcribing the audio now`,
            });
          }
          this.setState({
            statusText: `Uploading your audio: ${
              donePercentage.toFixed(2) * 100
            }% `,
          });
        }
      );
      this.setState(
        {
          outputText: res.text,
        },
        () => this.polishTranscription()
      );
    } catch (err) {
      this.setState({
        mode: "TYPE",
        recordingTime: 0,
        recordedBlob: null,
        recordedUrl: null,
        outputText: "",
        inputText: "",
      });
      console.log(err);
      cogoToast.error(
        "We ran into an error transcribing this audio. Please try again."
      );
    }
  };
  polishTranscription = async () => {
    let transcriptionOutput = this.state.outputText;
    if (!transcriptionOutput) {
      return;
    }
    try {
      this.setState({
        mode: "CLEAN_UP",
        statusText: "Warming up model",
      });
      let createChatRes = await apiCallPostAI(
        this.state.alchemyToken,
        "/chat/createChatConversation",
        {
          applicationCtxId: "",
          tool: "NC_TRANSCRIPT_CLEAN_UP",
          promptHead: masterSystemPrompts.POLISH_TRANSCRIPT.promptHead,
          model: "gpt-4o",
          temperature: 0.9,
          engine: "openai",
        }
      );
      let chatId = createChatRes._id;
      try {
        this.setState({
          statusText: masterSystemPrompts.POLISH_TRANSCRIPT.statusText,
          outputText: "",
        });
        let payload = {
          conversationId: chatId,
          lastContext: {
            role: "user",
            content: transcriptionOutput,
          },
        };
        await apiCallPostStreamingForAI(
          "/chat/generateChatCompletionSSE",
          this.state.alchemyToken,
          payload,
          (data) => {
            this.setState({
              outputText: this.state.outputText + data,
            });
          },
          () => {
            this.promptChainer(0);
          }
        );
      } catch (err) {
        this.setState({
          mode: "TYPE",
          inputText: transcriptionOutput,
        });
        console.log(err);
        cogoToast.error("We ran into an error polishing the transcription.");
      }
    } catch (err) {
      this.setState({
        mode: "TYPE",
        inputText: transcriptionOutput,
      });
      console.log(err);
      cogoToast.error(
        "We ran into an error initating the transcription clean up."
      );
    }
  };
  promptChainer = async (index) => {
    let promptChain = this.props.promptChain;
    if (index >= promptChain.length) {
      this.props.onDone(this.state.outputText);
      this.setState({
        mode: "TYPE",
        isAIActivityRunning: false,
        inputText: "",
      });
      return;
    }
    let payloadContent = this.state.outputText;
    try {
      this.setState({
        mode: "GPT_ACTIVITY",
        statusText: "Warming up model",
      });
      let createChatRes = await apiCallPostAI(
        this.state.alchemyToken,
        "/chat/createChatConversation",
        {
          applicationCtxId: "",
          tool: promptChain[index],
          promptHead: masterSystemPrompts[promptChain[index]].promptHead,
          model: "gpt-4o",
          temperature: 0.9,
          engine: "openai",
        }
      );
      let chatId = createChatRes._id;
      try {
        this.setState({
          statusText: masterSystemPrompts[promptChain[index]].statusText,
          outputText: "",
        });
        let payload = {
          conversationId: chatId,
          lastContext: {
            role: "user",
            content: payloadContent,
          },
        };
        await apiCallPostStreamingForAI(
          "/chat/generateChatCompletionSSE",
          this.state.alchemyToken,
          payload,
          (data) => {
            this.setState({
              outputText: this.state.outputText + data,
            });
          },
          () => {
            this.promptChainer(index + 1);
          }
        );
      } catch (err) {
        this.setState({
          isAIActivityRunning: false,
          mode: "TYPE",
          inputText: payloadContent,
        });
        console.log(err);
        cogoToast.error("We ran into an error polishing the transcription.");
      }
    } catch (err) {
      this.setState({
        isAIActivityRunning: false,
        mode: "TYPE",
        inputText: payloadContent,
      });
      console.log(err);
      cogoToast.error(
        "We ran into an error initating the transcription clean up."
      );
    }
  };
  /** AUDIO RECORDER */
  startRecording = () => {
    this.setState({ isRecording: true });
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.recordRTC = RecordRTC(stream, {
          type: "audio",
          disableLogs: true,
        });
        this.recordRTC.startRecording();
        this.startTimer();
      })
      .catch((err) => {
        cogoToast.error("Failed to start recording.");
      });
  };
  stopRecording = () => {
    this.setState({ isRecording: false });
    this.stopTimer();
    this.recordRTC.stopRecording(() => {
      let blob = this.recordRTC.getBlob();
      let url = URL.createObjectURL(blob);
      this.setState({ recordedBlob: blob, recordedUrl: url }, () => {
        this.transcribeAudio();
      });
    });
  };
  startTimer = () => {
    this.timerID = setInterval(() => {
      this.setState({ recordingTime: this.state.recordingTime + 1 });
    }, 1000);
  };
  stopTimer = () => {
    clearInterval(this.timerID);
    this.timerID = null;
  };
  formatTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    return `${minutes}m${seconds}s`;
  };
  /** RENDER */
  renderFooterBar = () => {
    if (["CLEAN_UP", "GPT_ACTIVITY"].includes(this.state.mode)) {
      return (
        <div>
          <div
            className="colfax"
            style={{
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <TextWithEllipsis text={this.state.statusText} />
            </div>
            <ClinicalLoader />
          </div>
          {this.state.outputText && (
            <Card style={{ padding: "3px", fontSize: "smaller" }}>
              {this.state.outputText}
            </Card>
          )}
        </div>
      );
    }
    if (this.state.mode === "TRANSCRIBE") {
      return (
        <div
          className="colfax"
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <TextWithEllipsis text={this.state.statusText} />
          </div>
          <ClinicalLoader />
        </div>
      );
    }
    if (this.state.mode === "RECORD") {
      return (
        <div
          className="colfax"
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <TextWithEllipsis text={this.state.statusText} />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            <Tag
              intent="success"
              large
              className="vermillion_dark"
              icon="record"
            >
              {this.formatTime(this.state.recordingTime)}
            </Tag>
            <Button
              icon="stop"
              text="Stop Recording"
              className="colfax"
              onClick={() => {
                this.stopRecording();
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        className="colfax"
        style={{ display: "flex", flexWrap: "nowrap", justifyContent: "end" }}
      >
        <Button
          icon="microphone"
          text=""
          className="colfax"
          onClick={() =>
            this.setState(
              {
                mode: "RECORD",
                recordedBlob: null,
                recordedUrl: null,
                recordingTime: 0,
                statusText: "Recording",
              },
              () => this.startRecording()
            )
          }
        />
        <Button
          loading={this.state.isAIActivityRunning}
          icon="send-message"
          className="colfax"
          onClick={() => {
            this.setState(
              {
                isAIActivityRunning: true,
                outputText: this.state.inputText,
              },
              () => this.promptChainer(0)
            );
          }}
        />
      </div>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <Spinner size={20} />;
    }
    if (this.state.isError) {
      return (
        <NonIdealState
          icon="warning-sign"
          title="We ran into an error setting up the AI."
        />
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <ControlGroup>
          <Tag minimal>Type or record, and let AI write it out for you.</Tag>
        </ControlGroup>
        <TextArea
          style={{
            fontSize: "small",
          }}
          readOnly={this.state.isAIActivityRunning}
          className="colfax"
          fill
          autoResize
          value={this.state.inputText}
          onChange={(e) => {
            this.setState({ inputText: e.target.value });
          }}
        />
        {this.renderFooterBar()}
      </div>
    );
  }
}

export default inject("authStore")(observer(GPTPrompter));
