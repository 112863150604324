import { Button, ControlGroup, Tag } from "@blueprintjs/core";
import React from "react";
import AsyncSelWrap from "../utils/AsyncSelWrap";
import _ from "lodash";

import { inject, observer } from "mobx-react";
import cogoToast from "cogo-toast";
import { apiCallPost, apiCallPostCancellable, niceDate } from "../fns/util";
import { TimePicker } from "@blueprintjs/datetime";

class EachUserRole extends React.Component {
  state = {
    searchingUsers: false,
    userSearchResults: [],
    userItem: null,
    userId: null,
    userName: null,
    changed: false,
  };
  componentDidMount() {
    let { whichRole, visitData } = this.props;
    let { keyName, niceName } = whichRole;
    let isDone = visitData[keyName];
    let activityDoneBy = `${keyName}By`;
    let activityDoneAt = `${keyName}At`;
    let activityDoneById = `${keyName}ById`;
    let obj = {
      isDone,
      keyName,
      niceName,
      activityDoneAt,
      activityDoneBy,
      activityDoneById,
    };
    if (isDone) {
      obj = {
        ...obj,
        [activityDoneBy]: visitData[activityDoneBy],
        [activityDoneAt]: visitData[activityDoneAt],
        userId: visitData[activityDoneById],
      };
    }
    this.setState({
      ...obj,
    });
  }
  setClinicalTimeStamps = async (remove = false) => {
    let day = new Date(this.props.visitData.appointmentTime);
    let time = new Date(this.state[this.state.activityDoneAt]);
    let payload = {
      patientId: this.props.visitData.patient,
      visitId: this.props.visitData._id,
      event: this.props.whichRole.eventName,
      personId: this.state.userId,
      ts: new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate(),
        time.getHours(),
        time.getMinutes(),
        time.getSeconds(),
        time.getMilliseconds()
      ),
    };
    if (remove) {
      payload = {
        ...payload,
        remove: true,
      };
    }
    try {
      cogoToast.loading("Saving...");
      let res = await apiCallPost(
        `/clinical2/${this.props.authStore.prefix}/setClinicalTimeStamps`,
        payload
      );
      this.props.onUpdateVisitData(res);
      cogoToast.success("Saved!");
      this.setState({
        changed: false,
        isDone: true,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error(
        "There was an error in saving. Please check the console for more details."
      );
    }
  };
  search = async (
    string,
    searchResArray,
    isSearchingShowSpinner,
    selectedItem,
    apiUrl
  ) => {
    if (!string) {
      this.setState({
        [searchResArray]: [],
        [selectedItem]: null,
      });
      return;
    }
    try {
      this.setState({ [isSearchingShowSpinner]: true });
      let au = apiUrl;
      let res = await apiCallPostCancellable(au, {
        prefix: string || "",
      });
      let results = res;
      if (res) {
        this.setState({
          [searchResArray]: results,
          [isSearchingShowSpinner]: false,
        });
      }
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  renderUserTagOrSearch = () => {
    return (
      <ControlGroup>
        <Tag minimal icon="user" large></Tag>
        <AsyncSelWrap
          isColfax={true}
          placeholder="Search Users..."
          searching={this.state.searchingUsers}
          items={this.state.userSearchResults.map((each) => {
            return {
              ...each,
              item: each.name,
              entityTypeString: each.email,
              helper: each.name,
            };
          })}
          selectedItem={this.state[this.state.activityDoneBy] || null}
          update={(item) => {
            this.search(
              item,
              "userSearchResults",
              "searchingUsers",
              "userItem",
              `/users/${this.props.authStore.prefix}/search`
            );
          }}
          onChange={(item) => {
            this.setState({
              changed: true,
              userItem: item,
              userId: item._id,
              [this.state.activityDoneBy]: item.name,
            });
          }}
        />
      </ControlGroup>
    );
  };

  isDeleteRendered = () => {
    if (this.state.niceName === "Vision") {
      return false;
    }
    if (this.state.niceName === "Exit from Doctor's Chamber") {
      return false;
    }
    if (this.state.isDone) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <tr>
        <td
          style={{
            verticalAlign: "middle",
          }}
        >
          <ControlGroup>
            <Tag
              large
              intent="success"
              className={
                this.state.changed
                  ? "sepia__bg"
                  : this.state.isDone
                  ? "forest__bg"
                  : "rose__bg"
              }
              icon={
                this.state.changed
                  ? "high-priority"
                  : this.state.isDone
                  ? "tick"
                  : "warning-sign"
              }
            >
              {this.state.niceName}
            </Tag>
            {this.isDeleteRendered() ? (
              <Button
                icon="trash"
                minimal
                intent="danger"
                onClick={() => this.setClinicalTimeStamps(true)}
              />
            ) : null}
          </ControlGroup>
        </td>
        <td
          style={{
            verticalAlign: "middle",
          }}
        >
          {this.renderUserTagOrSearch()}
        </td>
        <td>
          {this.state[this.state.activityDoneAt] ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag minimal icon="time" large></Tag>
              <TimePicker
                className="colfax"
                useAmPm={true}
                showArrowButtons={true}
                inputProps={{ inputClassName: "colfax", className: "colfax" }}
                value={
                  this.state[this.state.activityDoneAt]
                    ? new Date(this.state[this.state.activityDoneAt])
                    : new Date()
                }
                onChange={(t) => {
                  this.setState({
                    [this.state.activityDoneAt]: t,
                    changed: true,
                  });
                }}
              />
            </div>
          ) : (
            <Button
              icon="stopwatch"
              onClick={() =>
                this.setState({
                  [this.state.activityDoneAt]: new Date(),
                  changed: true,
                })
              }
            />
          )}
        </td>
        <td
          style={{
            verticalAlign: "middle",
          }}
        >
          {this.state.changed ? (
            <Button
              disabled={
                !this.state[this.state.activityDoneAt] ||
                !this.state[this.state.activityDoneBy]
              }
              intent="warning"
              text="Save"
              icon="endorsed"
              onClick={() => this.setClinicalTimeStamps()}
            />
          ) : null}
        </td>
      </tr>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(EachUserRole));
