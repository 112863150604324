import React from "react";
import { ControlGroup, Tag } from "@blueprintjs/core";
import _ from "lodash";

class BiometryMeasures extends React.Component {
  getParam = (k) => {
    return _.find(this.props.data, (each) => each.key === k);
  };
  renderParams = (params) => {
    return params.map((each) => {
      if (this.getParam(each) && this.getParam(each)["value"]) {
        return (
          <ControlGroup>
            <Tag minimal large>
              {this.getParam(each)["description"]}
            </Tag>
            <Tag intent="primary" large minimal>
              {" "}
              {this.getParam(each)["value"]}{" "}
              {this.getParam(each)["unit"] === "unitless"
                ? ""
                : this.getParam(each)["unit"]}
            </Tag>
          </ControlGroup>
        );
      }
      return null;
    });
  };

  render() {
    return (
      <div>
        <ControlGroup>
          <Tag large intent="primary">
            Biometric Measurements
          </Tag>
          <Tag large minimal>
            Keratometric Index
          </Tag>
          <Tag large minimal intent="primary">
            {this.getParam("KREFINDEX")["value"]}
          </Tag>
          <Tag large minimal>
            Keratometric Value
          </Tag>
          <Tag large minimal intent="primary">
            {this.getParam("K")["value"]} D
          </Tag>
        </ControlGroup>
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
          <div style={{ marginRight: "10px" }}>
            <ControlGroup vertical>
              {this.renderParams(["LS", "AL", "CCT", "AD", "LT", "ACD"])}
            </ControlGroup>
          </div>
          <div style={{ marginRight: "10px" }}>
            <ControlGroup vertical>
              <ControlGroup>
                <Tag minimal large>
                  K Flat
                </Tag>
                <Tag minimal intent="primary" large>
                  {" "}
                  {this.getParam("KFLAT")["value"]}{" "}
                  {this.getParam("KFLAT")["unit"]} @{" "}
                  {this.getParam("AXISFLAT")["value"]}
                  {this.getParam("AXISFLAT")["unit"]}
                </Tag>
              </ControlGroup>
              <ControlGroup>
                <Tag minimal large>
                  K Steep
                </Tag>
                <Tag minimal intent="primary" large>
                  {" "}
                  {this.getParam("KSTEEP")["value"]}{" "}
                  {this.getParam("KSTEEP")["unit"]} @{" "}
                  {this.getParam("AXISSTEEP")["value"]}
                  {this.getParam("AXISSTEEP")["unit"]}
                </Tag>
              </ControlGroup>
              <ControlGroup>
                <Tag minimal large>
                  Astigmatism
                </Tag>
                <Tag minimal intent="primary" large>
                  {" "}
                  {this.getParam("AST")["value"]} {this.getParam("AST")["unit"]}{" "}
                  @ {this.getParam("ASTAXIS")["value"]}
                  {this.getParam("ASTAXIS")["unit"]}
                </Tag>
              </ControlGroup>
            </ControlGroup>
          </div>
          <div style={{ marginRight: "10px" }}>
            <ControlGroup vertical>
              {this.renderParams(["WTW", "PD", "VERT"])}
            </ControlGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default BiometryMeasures;
