import { NonIdealState, Spinner, Tab, Tabs } from "@blueprintjs/core";
import React from "react";
import SpecularGraph from "./SpecularGraph";
import SpecularImage from "./SpecularImage";
import SpecularMeasures from "./SpecularMeasures";
import _ from "lodash";
import { apiCallPost } from "../../../fns/util";
import cogoToast from "cogo-toast";
import { inject, observer } from "mobx-react";

class SpecularContainer extends React.Component {
  state = { loading: true, data: {} };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      this.setState({ loading: true });
      cogoToast.loading("Loading data...", { position: "bottom-left" });
      let res = await apiCallPost(
        `/invsync/${this.props.authStore.prefix}/getInvestigation`,
        { _id: this.props.data._id }
      );
      cogoToast.success("Loaded data.", { position: "bottom-left" });
      this.setState({ loading: false, data: res });
    } catch (err) {
      cogoToast.error(
        "There was an error loading data. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  renderOneEye = (annotation, processed) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          flexDirection: "column",
          overflow: "scroll",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <SpecularImage imageSrc={processed} />
          <SpecularMeasures data={annotation} />{" "}
          <SpecularGraph
            data={_.find(
              annotation,
              (each) => each.key === "SPECULAR_hgShapeDistributionGraph"
            )}
            graphType="shape"
          />
          <SpecularGraph
            data={_.find(
              annotation,
              (each) => each.key === "SPECULAR_hgAreaDistGraph"
            )}
            graphType="area"
          />
        </div>
      </div>
    );
  };
  renderTabsOrNot = () => {
    if (this.state.loading) {
      return null;
    }

    let annotations = this.state.data.annotations;
    let annotationsLE = _.filter(annotations, (each) => each.eye === "LE");
    let annotationsRE = _.filter(annotations, (each) => each.eye === "RE");
    let le_ = annotationsLE.length;
    let re_ = annotationsRE.length;
    let both = false;
    if (le_ && re_) {
      both = true;
    }
    if (both) {
      return (
        <Tabs id="specular" large>
          <Tab
            id="RE"
            title="Right Eye"
            panel={this.renderOneEye(
              annotationsRE,
              _.find(this.state.data.processed, (each) => each.eye === "RE")
            )}
          />{" "}
          <Tab
            id="LE"
            title="Left Eye"
            panel={this.renderOneEye(
              annotationsLE,
              _.find(this.state.data.processed, (each) => each.eye === "LE")
            )}
          />
        </Tabs>
      );
    } else {
      let which = "RE";
      let whichA = annotationsRE;
      if (le_) {
        which = "LE";
        whichA = annotationsLE;
      }

      return this.renderOneEye(
        whichA,
        _.find(this.state.data.processed, (each) => each.eye === which)
      );
    }
  };
  render() {
    if (this.state.loading) {
      return (
        <NonIdealState
          icon={<Spinner />}
          title="Loading data"
          description={`Loading data for ${this.props.data.type}`}
        />
      );
    }
    return this.renderTabsOrNot();
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(SpecularContainer));
