import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class OCTCarousel extends React.Component {
  renderEach = (each) => {
    let style = {};
    if (each.selectForPrint) {
      style = { border: "2px solid #939496" };
    }
    return (
      <div
        onClick={() => this.props.onSetIndex(each.sliceIndex)}
        style={{ position: "relative" }}
      >
        <div style={{ position: "absolute" }}>{each.sliceIndex + 1}</div>
        <img src={each.psUrl} height="80px" style={style} alt={each.psUrl} />
      </div>
    );
  };
  renderCarousel = () => {
    return this.props.data.map((each) => {
      return this.renderEach(each);
    });
  };
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 100,
      slidesToShow: 4,
      slidesToScroll: 1,
      centerMode: true,
    };
    return (
      <div style={{ width: "700px" }}>
        <Slider {...settings}>{this.renderCarousel()} </Slider>
      </div>
    );
  }
}

export default OCTCarousel;
