import {
  Card,
  ControlGroup,
  Elevation,
  Spinner,
  Tag,
  Button,
} from "@blueprintjs/core";
import cogoToast from "cogo-toast";
import React from "react";
import { apiCallPost, initials } from "../../fns/util";
import AsyncSelWrap from "../../utils/AsyncSelWrap";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import MutEx from "../../utils/MutEx";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class NewSx extends React.Component {
  state = {
    isLoading: true,
    changed: false,
    isUpdating: false,
    sxItemName: "",
    sxSteps: [],
    sxClinicalItems: [],
    priceBook: [],
    isSxSearching: false,
    sxSearchResults: [],
    isItemSearching: false,
    itemSearchResults: [],
    sxStepsJoiner: [],
    sxClinicalItemsJoiner: [],
    composeString: "",
  };
  listOfAnaesthesia = [
    "Topical Anaesthesia",
    "General Anaesthesia",
    "General Anaesthesia (Short)",
    "Local Anaesthesia",
    "Facial Block",
    "Lid Block",
  ];
  listOfWhens = [
    "Urgently",
    "As Early As Possible",
    "Within 1 Month",
    "Within 2 Months",
    "By Next January",
    "By Next February",
    "By Next March",
    "By Next April",
    "By Next May",
    "By Next June",
    "By Next July",
    "By Next August",
    "By Next September",
    "By Next October",
    "By Next November",
    "By Next December",
    "After Durga Puja",
    "Before Durga Puja",
    "After Eid",
    "Before Eid",
    "After Diwali",
    "Before Diwali",
  ];
  composeString = (setState = true) => {
    let str = "";
    str += (this.state.eye || "Eye Not Selected ") + " : ";
    let sxStr = "";
    if (this.state.sxSteps.length) {
      for (var i = 0; i < this.state.sxSteps.length; i++) {
        sxStr += this.state.sxSteps[i].sxStepName;
        if (i !== this.state.sxSteps.length - 1) {
          sxStr += this.state.sxStepsJoiner[i] ? " + " : " / ";
        }
      }
    } else {
      sxStr = "";
    }
    let itemStr = "";
    if (this.state.sxClinicalItems.length) {
      for (i = 0; i < this.state.sxClinicalItems.length; i++) {
        itemStr += this.state.sxClinicalItems[i].itemName;
        if (i !== this.state.sxClinicalItems.length - 1) {
          itemStr += this.state.sxClinicalItemsJoiner[i] ? " + " : " / ";
        }
      }
    } else {
      itemStr = "";
    }
    str += sxStr;
    if (itemStr) {
      str += " with " + itemStr;
    }
    str +=
      " ↓ " +
      (this.state.anaesthesia
        ? initials(this.state.anaesthesia)
        : "No Anaesthesia Selected");
    str += this.state.when ? " - " + this.state.when : "";
    this.setState({ composeString: str }, () => {
      if (setState) {
        this.setState({ changed: true });
      }
    });
  };
  componentDidMount() {
    this.setState(
      {
        ...this.props.data,
      },

      () => this.setState({ isLoading: false }, () => this.composeString(false))
    );
  }
  search = async (str, resArr, isSearch) => {
    if (!str) {
      this.setState({ [resArr]: [] });
      return;
    }
    try {
      this.setState({ [isSearch]: true });
      let res, r;
      if (resArr === "sxSearchResults") {
        res = await apiCallPost(
          `/meta-sx/${this.props.authStore.prefix}/getSxSteps`,
          { prefix: str }
        );
        r = res.map((each) => {
          return {
            ...each,
            item: each.sxStepName,
            entityTypeString: each.specialisation,
            helper: each.schedulingTag,
          };
        });
      } else {
        res = await apiCallPost(
          `/ims/${this.props.authStore.prefix}/getItems`,
          { prefix: str, filterOutPkgChildren: true }
        );
        r = res.map((each) => {
          return {
            ...each,
            item: each.itemName,
            entityTypeString: each.parentTemplateName,
            helper: each.itemClass,
            helper2: each.budgetName,
          };
        });
      }

      this.setState({ [resArr]: r, [isSearch]: false });
    } catch (err) {
      cogoToast.error(
        "There was an error in searching. Please check the console for more details.",
        { position: "bottom-left" }
      );
      console.log(err);
    }
  };
  renderAdderSection = (
    headline,
    searchPlaceholder,
    isSearching,
    searchRes,
    addedArray,
    itemId,
    itemName,
    joinerArray
  ) => {
    return (
      <ControlGroup>
        <Tag minimal large intent="primary">
          {headline}
        </Tag>
        <AsyncSelWrap
          placeholder={searchPlaceholder}
          searching={this.state[isSearching]}
          items={this.state[searchRes] || []}
          selectedItem={null}
          update={(item) => this.search(item, searchRes, isSearching)}
          onChange={(item) => {
            let sss = this.state[addedArray];
            let idx = _.findIndex(sss, (each) => each[itemId] === item._id);
            if (idx >= 0) {
              cogoToast.error("It seems like this is already added.");
              return;
            } else {
              sss.push({
                [itemId]: item._id,
                [itemName]: item.item,
              });
              let jjj = this.state[joinerArray];
              jjj.push(true);
              this.setState(
                {
                  [addedArray]: sss,
                  [joinerArray]: jjj,
                  changed: true,
                },
                () => {
                  this.composeString();
                  if (item.preSxProcSearchStrings) {
                    this.props.addInv(item.preSxProcSearchStrings);
                  }
                }
              );
            }
          }}
        />
      </ControlGroup>
    );
  };

  renderAddedItems = (arr, itemId, itemName, joinerArray) => {
    let toRender = this.state[arr];
    if (!toRender.length) {
      return <Tag minimal>No items added.</Tag>;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {toRender.map((each, idx) => {
          return (
            <ControlGroup>
              {" "}
              <Tag
                large
                minimal
                intent="primary"
                interactive
                onRemove={() => {
                  let idx = _.findIndex(
                    toRender,
                    (x) => x[itemId] === each[itemId]
                  );
                  toRender.splice(idx, 1);
                  let x = this.state[joinerArray];
                  x.splice(idx, 1);
                  this.setState(
                    {
                      [arr]: toRender,
                      [joinerArray]: x,
                      changed: true,
                    },
                    () => this.composeString()
                  );
                }}
              >
                {each[itemName]}
              </Tag>
              {idx === toRender.length - 1 ? null : (
                <Tag
                  icon={this.state[joinerArray][idx] ? "plus" : "slash"}
                  minimal
                  interactive
                  onClick={() => {
                    let x = this.state[joinerArray];
                    x[idx] = !x[idx];
                    this.setState({ [joinerArray]: x, changed: true }, () =>
                      this.composeString()
                    );
                  }}
                ></Tag>
              )}
            </ControlGroup>
          );
        })}
      </div>
    );
  };

  renderSave = () => {
    if (this.state.changed) {
      if (!this.state.sxSteps.length) {
        return null;
      }
      return (
        <ControlGroup>
          <Button
            text="Save and Update"
            intent="success"
            icon="endorsed"
            onClick={() => {
              let obj = {
                when: this.state.when,
                anaesthesia: this.state.anaesthesia,
                eye: this.state.eye,
                sxSteps: this.state.sxSteps,
                sxClinicalItems: this.state.sxClinicalItems,
                sxStepsJoiner: this.state.sxStepsJoiner,
                sxClinicalItemsJoiner: this.state.sxClinicalItemsJoiner,
                composeString: this.state.composeString,
              };
              this.props.update(this.state._id, obj);
              this.setState({ changed: false });
            }}
          />
        </ControlGroup>
      );
    }
  };
  render() {
    if (this.state.isLoading) {
      return (
        <Card
          elevation={Elevation.FOUR}
          style={{
            margin: "20px",
          }}
        >
          <Spinner />{" "}
        </Card>
      );
    }
    return (
      <div>
        <Card
          className="bp5-dark"
          elevation={Elevation.FOUR}
          style={{
            marginTop: "10px",
            padding: "5px",
            backgroundColor: `${this.state.changed ? "#26292c" : "#17191a"}`,
          }}
        >
          <ControlGroup>
            {this.props.templateEditing ? null : (
              <>
                {" "}
                {this.state.eye ? null : (
                  <Tag
                    minimal={this.state.eye}
                    large
                    intent={this.state.eye ? "none" : "danger"}
                  >
                    Eye
                  </Tag>
                )}
                <MutEx
                  minimal={false}
                  options={["RE", "LE"]}
                  onUpdate={(option) => {
                    this.setState(
                      {
                        eye: option,
                        changed: true,
                      },
                      () => this.composeString()
                    );
                  }}
                  selected={this.state.eye}
                />
              </>
            )}

            {this.state.anaesthesia ? null : (
              <Tag
                minimal={this.state.anaesthesia}
                large
                intent={this.state.anaesthesia ? "none" : "danger"}
              >
                Anaesthesia
              </Tag>
            )}

            <SingleSelectWrapper
              showSelectProp={true}
              selectedItem={this.state.anaesthesia}
              items={this.listOfAnaesthesia}
              onChange={(item) => {
                this.setState({ anaesthesia: item, changed: true }, () =>
                  this.composeString()
                );
              }}
            />
            {this.state.when ? null : (
              <Tag
                minimal={this.state.when}
                large
                intent={this.state.when ? "none" : "danger"}
              >
                When
              </Tag>
            )}

            <SingleSelectWrapper
              showSelectProp={true}
              items={this.listOfWhens}
              selectedItem={this.state.when}
              onChange={(item) => {
                this.setState({ when: item, changed: true }, () =>
                  this.composeString()
                );
              }}
            />
            <Tag minimal large>
              Add Elements
            </Tag>
            {this.renderAdderSection(
              "Surgeries",
              "Search Sx...",
              "isSxSearching",
              "sxSearchResults",
              "sxSteps",
              "sxStepId",
              "sxStepName",
              "sxStepsJoiner"
            )}
            {this.renderAdderSection(
              "Items",
              "Search Items...",
              "isItemSearching",
              "itemSearchResults",
              "sxClinicalItems",
              "itemId",
              "itemName",
              "sxClinicalItemsJoiner"
            )}
            <Button
              icon="trash"
              minimal
              intent="danger"
              onClick={() => this.props.remove(this.state._id)}
            />
          </ControlGroup>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Tag minimal large>
              Planned Sx
            </Tag>
            {this.renderAddedItems(
              "sxSteps",
              "sxStepId",
              "sxStepName",
              "sxStepsJoiner"
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Tag minimal large>
              Items Needed
            </Tag>
            {this.renderAddedItems(
              "sxClinicalItems",
              "itemId",
              "itemName",
              "sxClinicalItemsJoiner"
            )}
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <ControlGroup>
              <Tag large minimal multiline intent="success">
                Rx: {this.state.composeString}
              </Tag>
            </ControlGroup>{" "}
            {this.renderSave()}
          </div>
        </Card>
      </div>
    );
  }
}

export default inject("authStore")(observer(NewSx));
