import React from "react";
import { ControlGroup, Tag } from "@blueprintjs/core";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class ContrastSens extends React.Component {
  state = { editingPupilL: false, editingPupilR: false };
  listOfPRs = [
    "0.05",
    "0.20",
    "0.35",
    "0.50",
    "0.65",
    "0.80",
    "0.95",
    "1.10",
    "1.25",
    "1.40",
    "1.55",
    "1.70",
    "1.85",
    "2.00",
    "2.15",
    "2.30",
  ];
  renderPupil = () => {
    return (
      <ControlGroup>
        <Tag minimal large>
          Contrast Sensitivity
        </Tag>

        <Tag minimal large>
          R
        </Tag>

        {this.state.editingPupilR ? (
          <SingleSelectWrapper
            items={this.listOfPRs}
            selectedItem={this.props.vision.contrast.re}
            onChange={(item) => {
              this.props.updateVision("contrast.re", item);
              this.setState({ editingPupilR: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large
            intent="primary"
            interactive
            onClick={() => this.setState({ editingPupilR: true })}
          >
            {this.props.vision.contrast.re}
          </Tag>
        )}

        <Tag minimal large>
          L
        </Tag>
        {this.state.editingPupilL ? (
          <SingleSelectWrapper
            items={this.listOfPRs}
            selectedItem={this.props.vision.contrast.le}
            onChange={(item) => {
              this.props.updateVision("contrast.le", item);
              this.setState({ editingPupilL: false });
            }}
          />
        ) : (
          <Tag
            minimal
            large
            interactive
            intent="primary"
            onClick={() => this.setState({ editingPupilL: true })}
          >
            {this.props.vision.contrast.le}
          </Tag>
        )}
      </ControlGroup>
    );
  };
  render() {
    return <div>{this.renderPupil()}</div>;
  }
}

export default ContrastSens;
