import React from "react";
import _ from "lodash";
import OCTHori from "./OCTHori";
import "@toast-ui/chart/dist/toastui-chart.min.css";
import { NestedPieChart } from "@toast-ui/react-chart";

class OCTMeasures extends React.Component {
  renderNPC = () => {
    if (this.props.data && this.props.data.length) {
      if (this.props.data[0].scanType === "RNFL") {
        let mother = _.find(
          this.props.data,
          (each) => each.key === "Retinal Nerve Fibre Layer"
        );
        let sub = mother.subMeasurements;
        let arr = _.find(
          sub,
          (each) => each.key === "rnfl_thickness_section"
        ).value;
        let eye = mother.eye === "OD";

        let dataOuter = [];

        _.map(arr, (each) => {
          dataOuter.push({ name: each, data: 1 });
        });
        if (!eye) {
          let dOD = _.concat(
            _.reverse(_.take(dataOuter, 8)),
            _.reverse(_.takeRight(dataOuter, 8))
          );
          dataOuter = dOD;
        }
        let seriesColours = [];
        let danger = "#FF7373";
        let success = "#3DCC91";
        let inter = "#FFB366";
        let ranges = {
          inferior: [126, 15.8],
          superior: [117.2, 16.13],
          nasal: [75, 13.9],
          temporal: [70.6, 10.8],
        };
        /** https://pubmed.ncbi.nlm.nih.gov/22549477/
         * https://github.com/nhn/tui.chart/blob/main/docs/en/chart-nestedPie.md
         */
        let arrTemp = dataOuter.map((each) => each.name);
        _.map(arrTemp, (each, index) => {
          let str1 = eye ? "temporal" : "nasal";
          let str2 = eye ? "nasal" : "temporal";
          if (index <= 1) {
            // T
            if (
              each <= ranges[str1][0] + ranges[str1][1] &&
              each >= ranges[str1][0] - ranges[str1][1]
            ) {
              seriesColours.push(success);
            } else if (
              each <= ranges[str1][0] + 1.25 * ranges[str1][1] &&
              each >= ranges[str1][0] - 1.25 * ranges[str1][1]
            ) {
              seriesColours.push(inter);
            } else {
              seriesColours.push(danger);
            }
          } else if (index <= 5) {
            // S
            if (
              each <= ranges["superior"][0] + ranges["superior"][1] &&
              each >= ranges["superior"][0] - ranges["superior"][1]
            ) {
              seriesColours.push(success);
            } else if (
              each <= ranges["superior"][0] + 1.25 * ranges["superior"][1] &&
              each >= ranges["superior"][0] - 1.25 * ranges["superior"][1]
            ) {
              seriesColours.push(inter);
            } else {
              seriesColours.push(danger);
            }
          } else if (index <= 9) {
            // N
            if (
              each <= ranges[str2][0] + ranges[str2][1] &&
              each >= ranges[str2][0] - ranges[str2][1]
            ) {
              seriesColours.push(success);
            } else if (
              each <= ranges[str2][0] + 1.25 * ranges[str2][1] &&
              each >= ranges[str2][0] - 1.25 * ranges["nasal"][1]
            ) {
              seriesColours.push(inter);
            } else {
              seriesColours.push(danger);
            }
          } else if (index <= 13) {
            // T
            if (
              each <= ranges["inferior"][0] + ranges["inferior"][1] &&
              each >= ranges["inferior"][0] - ranges["inferior"][1]
            ) {
              seriesColours.push(success);
            } else if (
              each <= ranges["inferior"][0] + 1.25 * ranges["inferior"][1] &&
              each >= ranges["inferior"][0] - 1.25 * ranges["inferior"][1]
            ) {
              seriesColours.push(inter);
            } else {
              seriesColours.push(danger);
            }
          } else {
            // I
            if (
              each <= ranges[str1][0] + ranges[str1][1] &&
              each >= ranges[str1][0] - ranges[str1][1]
            ) {
              seriesColours.push(success);
            } else if (
              each <= ranges[str1][0] + 1.25 * ranges[str1][1] &&
              each >= ranges[str1][0] - 1.25 * ranges[str1][1]
            ) {
              seriesColours.push(inter);
            } else {
              seriesColours.push(danger);
            }
          }
        });
        let dataInter = [
          {
            name: _.find(sub, (each) => each.key === "rnfl_thickness_temporal")
              .value,
            data: 1,
          },
          {
            name: _.find(sub, (each) => each.key === "rnfl_thickness_superior")
              .value,
            data: 1,
          },
          {
            name: _.find(sub, (each) => each.key === "rnfl_thickness_nasal")
              .value,
            data: 1,
          },
          {
            name: _.find(sub, (each) => each.key === "rnfl_thickness_inferior")
              .value,
            data: 1,
          },
        ];

        let dataIn = [
          {
            name: _.find(
              sub,
              (each) => each.key === "rnfl_thickness_s_hemisphere"
            ).value,
            data: 1,
          },
          {
            name: _.find(
              sub,
              (each) => each.key === "rnfl_thickness_i_hemisphere"
            ).value,
            data: 1,
          },
        ];
        if (!eye) {
          let temp = dataInter[0];
          dataInter[0] = dataInter[2];
          dataInter[2] = temp;
        }
        let interColours = [];
        for (var i = 0; i < dataInter.length; i++) {
          let str1 = eye ? "temporal" : "nasal";
          let str2 = eye ? "nasal" : "temporal";
          if (i === 0) {
            if (
              dataInter[i].data <= ranges[str1][0] + ranges[str1][1] &&
              dataInter[i].data >= ranges[str1][0] - ranges[str1][1]
            ) {
              interColours.push(success);
            } else if (
              dataInter[i].data <= ranges[str1][0] + 1.25 * ranges[str1][1] &&
              dataInter[i].data >= ranges[str1][0] - 1.25 * ranges[str1][1]
            ) {
              interColours.push(inter);
            } else {
              interColours.push(danger);
            }
          }
          if (i === 1) {
            if (
              dataInter[i].data <=
                ranges["superior"][0] + ranges["superior"][1] &&
              dataInter[i].data >= ranges["superior"][0] - ranges["superior"][1]
            ) {
              interColours.push(success);
            } else if (
              dataInter[i].data <=
                ranges["superior"][0] + 1.25 * ranges["superior"][1] &&
              dataInter[i].data >=
                ranges["superior"][0] - 1.25 * ranges["superior"][1]
            ) {
              interColours.push(inter);
            } else {
              interColours.push(danger);
            }
          }
          if (i === 2) {
            if (
              dataInter[i].data <= ranges[str2][0] + ranges[str2][1] &&
              dataInter[i].data >= ranges[str2][0] - ranges[str2][1]
            ) {
              interColours.push(success);
            } else if (
              dataInter[i].data <= ranges[str2][0] + 1.25 * ranges[str2][1] &&
              dataInter[i].data >= ranges[str2][0] - 1.25 * ranges[str2][1]
            ) {
              interColours.push(inter);
            } else {
              interColours.push(danger);
            }
          }
          if (i === 3) {
            if (
              dataInter[i].data <=
                ranges["inferior"][0] + ranges["inferior"][1] &&
              dataInter[i].data >= ranges["inferior"][0] - ranges["inferior"][1]
            ) {
              interColours.push(success);
            } else if (
              dataInter[i].data <=
                ranges["inferior"][0] + 1.25 * ranges["inferior"][1] &&
              dataInter[i].data >=
                ranges["inferior"][0] - 1.25 * ranges["inferior"][1]
            ) {
              interColours.push(inter);
            } else {
              interColours.push(danger);
            }
          }
        }
        let innerColours = [];
        for (var j = 0; j < dataInter.length; j++) {
          if (j === 0) {
            if (
              dataIn[j].data <= ranges["superior"][0] + ranges["superior"][1] &&
              dataIn[j].data >= ranges["superior"][0] - ranges["superior"][1]
            ) {
              innerColours.push(success);
            } else if (
              dataIn[j].data <=
                ranges["superior"][0] + 1.25 * ranges["superior"][1] &&
              dataIn[j].data >=
                ranges["superior"][0] - 1.25 * ranges["superior"][1]
            ) {
              innerColours.push(inter);
            } else {
              innerColours.push(danger);
            }
          }
          if (j === 1) {
            if (
              dataIn[j].data <= ranges["inferior"][0] + ranges["inferior"][1] &&
              dataIn[j].data >= ranges["inferior"][0] - ranges["inferior"][1]
            ) {
              innerColours.push(success);
            } else if (
              dataIn[j].data <=
                ranges["inferior"][0] + 1.25 * ranges["inferior"][1] &&
              dataIn[j].data >=
                ranges["inferior"][0] - 1.25 * ranges["inferior"][1]
            ) {
              innerColours.push(inter);
            } else {
              innerColours.push(danger);
            }
          }
        }

        const theme = {
          chart: {
            fontFamily: "axis",
            backgroundColor: "none",
          },
          title: {
            fontFamily: "axis",
            color: "#e6e6e6",
          },
          series: {
            detail: {
              colors: seriesColours,
              dataLabels: {
                fontFamily: "axis",
                fontSize: 1,
                useSeriesColor: true,
              },
              pieSeriesName: {
                useSeriesColor: false,
              },
            },
            inter: {
              colors: interColours,
              dataLabels: {
                fontFamily: "axis",
                fontSize: 1,
                useSeriesColor: true,
              },
              pieSeriesName: {
                useSeriesColor: false,
              },
            },
            inner: {
              colors: innerColours,
              dataLabels: {
                fontFamily: "axis",
                fontSize: 1,
                useSeriesColor: true,
              },
              pieSeriesName: {
                useSeriesColor: false,
              },
            },

            lineWidth: 2,
            strokeStyle: "#000000",
          },
          exportMenu: {
            button: {
              backgroundColor: "#26292c",
              borderColor: "#26292c",
            },
            panel: {
              borderColor: "#26292c",
              header: {
                fontFamily: "axis",
                color: "#e6e6e6",
                fontWeight: 700,
                backgroundColor: "#26292c",
              },
              body: {
                fontFamily: "axis",
                color: "#e6e6e6",
                fontWeight: "500",
                backgroundColor: "#26292c",
              },
            },
          },
        };

        const options = {
          usageStatistics: false,
          legend: {
            visible: false,
          },
          series: {
            detail: {
              dataLabels: {
                visible: true,
                pieSeriesName: {
                  visible: true,
                },
              },
              radiusRange: {
                inner: "70%",
                outer: "100%",
              },
              angleRange: {
                start: -90,
                end: 270,
              },
            },
            inter: {
              dataLabels: {
                visible: true,
                pieSeriesName: {
                  visible: true,
                },
              },
              radiusRange: {
                inner: "35%",
                outer: "65%",
              },
              angleRange: {
                start: -135,
                end: 225,
              },
            },
            inner: {
              dataLabels: {
                visible: true,
                pieSeriesName: {
                  visible: true,
                },
              },
              radiusRange: {
                inner: "5%",
                outer: "30%",
              },
              angleRange: {
                start: -90,
                end: 270,
              },
            },
          },
          chart: {
            width: 600,
            height: 600,
            title: "RNFL Thickness (µm)",
          },
          theme: theme,
        };

        const data = {
          series: [
            { name: "inner", data: dataIn },
            { name: "inter", data: dataInter },
            { name: "detail", data: dataOuter },
          ],
        };
        return <NestedPieChart data={data} options={options} />;
      }
    }
  };

  renderMeasures = () => {
    if (this.props.data && this.props.data.length) {
      if (this.props.data[0].scanType === "RNFL") {
        return;
      }
    }
    let keys = this.props.data.map((each) => each.key);
    let mm = _.uniq(keys);
    _.remove(mm, (each) => each === "OCT_summary");
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          overflow: "scroll",
          flexDirection: "column",
        }}
      >
        {mm.map((tt) => {
          return (
            <div style={{ marginTop: "5px" }}>
              {" "}
              <OCTHori
                head={tt}
                data={_.filter(this.props.data, (each) => each.key === tt)}
              />{" "}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    if (!this.props.data || !this.props.data.length) {
      return "No data";
    }
    return (
      <div>
        {this.renderMeasures()} {this.renderNPC()}
      </div>
    );
  }
}

export default OCTMeasures;
