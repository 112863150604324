import React from "react";
import {
  apiCallPost,
  apiCallPostAI,
  apiCallPostCancellable,
  apiCallPostStreamingForAI,
  apiCallWhisper,
} from "../../fns/util";
import cogoToast from "cogo-toast";
import { inject, observer } from "mobx-react";
import {
  Button,
  Card,
  ControlGroup,
  Elevation,
  NonIdealState,
  Spinner,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import TurboAudio from "./TurboAudio";
import TextWithEllipsis from "../TextWithEllipsis";
import { masterSystemPrompts } from "../masterSystemPrompts";
import ClinicalLoader from "../ClinicalLoader";
import _ from "lodash";
import TurboSection from "./TurboSection";
import { diffChars } from "diff";

class TurboMode extends React.Component {
  state = {
    alchemyToken: null,
    isTypingMode: false,
    isLoading: true,
    isError: false,
    hasAudioFileArrived: false,
    statusText: "",
    audioFile: null,
    audioTime: 0,
    uploadTime: 0,
    transcriptionTime: 0,
    transcribedText: "",
    polishedText: "",
    polishTime: 0,
    polishStartTime: 0,
    diffData: [],
    turboStructureTime: 0,
    turboSectionStartTime: 0,
    turboStructureText: "",
    turboSections: [],
    turboHangingText: "",
    turboTimes: [],
    turboStructureStartTime: 0,
    totalAsOfNow: 0,
  };
  componentDidMount() {
    this.getAlchemyToken();
    this.interval = setInterval(() => {
      this.pushStats();
    }, 7000);
  }
  getAlchemyToken = async () => {
    const alToken = localStorage.getItem("alchemyToken");
    if (alToken) {
      this.setState({ alchemyToken: alToken, isLoading: false });
      return;
    }
    try {
      let res = await apiCallPostCancellable("/gateway/alchemy", {});
      if (res) {
        this.setState(
          {
            alchemyToken: res.token,
            isLoading: false,
            isError: false,
          },
          () => {
            localStorage.setItem("alchemyToken", res.token);
          }
        );
      }
    } catch (err) {
      this.setState({ isError: true, isLoading: false });
    }
  };
  formatTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${seconds}`;
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  pushStats = async () => {
    if (!this.state.audioFile) {
      return;
    }
    if (
      !this.state.turboTimes ||
      !this.state.turboTimes.length ||
      !this.state.turboSections ||
      !this.state.turboSections.length
    ) {
      return;
    }
    let isDone =
      this.state.turboStructureTime &&
      this.state.turboSections.length &&
      this.state.turboTimes.length === this.state.turboSections.length;
    console.log("Is Done", isDone);
    if (!isDone) {
      return;
    }
    let dur = 0;
    if (isDone) {
      let turboTimeFinal = _.max(this.state.turboTimes);
      let turboSectionStartTime = this.state.turboSectionStartTime;
      dur =
        Math.floor((turboTimeFinal - turboSectionStartTime) / 1000) -
        this.state.turboStructureTime;
    }
    let total =
      this.state.uploadTime +
      this.state.transcriptionTime +
      dur +
      this.state.polishTime +
      this.state.turboStructureTime;
    if (this.state.totalAsOfNow === total) {
      return;
    }
    this.setState({
      totalAsOfNow: total,
    });

    let payload = {
      visit: this.props.data.visitId,
      audioRecordingTime: this.state.audioTime,
      audioTranscriptionTime: this.state.transcriptionTime,
      correctionTime: this.state.polishTime,
      structuringTime: this.state.turboStructureTime,
      parallelTime: dur,
      totalTime: total,
      correctionId: this.state.correctionId || "NA",
      structuringId: this.state.structuringId || "NA",
    };
    try {
      await apiCallPost(
        `/clinical-stat-counter/${this.props.authStore.prefix}/pushStats`,
        payload
      );
    } catch (err) {
      console.log(err);
    }
  };
  transcribeAudio = async (audioFile, audioTime = 0) => {
    try {
      this.setState({
        audioFile: audioFile,
        audioTime: audioTime,
        hasAudioFileArrived: true,
        transcribedText: "",
        uploadTime: 0,
        transcriptionTime: 0,
        statusText: "Uploading your audio",
      });
      let startTime = new Date();
      let transcribeStartTime = 0;
      const formData = new FormData();
      formData.append("tool", "NC_VOICE_INPUT");
      formData.append("applicationCtxId", this.props.data.visitId);
      formData.append("file", audioFile);
      formData.append(
        "prompt",
        "Phaco,OCT,Cup-Disc Ratio,CD Ratio,NS1,NS2,NS3,NS4,NS5,Vitrectomy,Retina On,Retina Attached,SPK,Klean Tears,PCIOL,Pred Forte,Tear Drops,Zylopred,Milflox,Milflodex,Vigamox,SICS,WNL, Refresh Tears,TA,GA,LA,PSC,Iobet,Dorzox - T"
      );
      let res = await apiCallWhisper(
        `/stt/generateTranscription`,
        this.state.alchemyToken,
        formData,
        (done, total) => {
          let donePercentage = done / total;
          if (done / total > 0.99) {
            this.setState(
              {
                uploadTime: this.state.uploadTime
                  ? this.state.uploadTime
                  : Math.floor((new Date() - startTime) / 1000),
                statusText: `Transcribing the audio now`,
              },
              () => {
                transcribeStartTime = transcribeStartTime
                  ? transcribeStartTime
                  : new Date();
              }
            );
          } else {
            this.setState({
              statusText: `Uploading your audio: ${
                donePercentage.toFixed(2) * 100
              }% `,
            });
          }
        }
      );
      this.setState(
        {
          transcriptionTime: Math.floor(
            (new Date() - transcribeStartTime) / 1000
          ),
          transcribedText: res.text,
        },
        () => this.polishTranscription()
      );
    } catch (err) {
      this.setState({
        isError: false,
        hasAudioFileArrived: false,
        statusText: "",
        audioFile: null,
        audioTime: 0,
        uploadTime: 0,
        transcriptionTime: 0,
        transcribedText: "",
        polishedText: "",
        polishTime: 0,
        polishStartTime: 0,
        diffData: [],
        turboStructureTime: 0,
        turboSectionStartTime: 0,
        turboStructureText: "",
        turboSections: [],
        turboHangingText: "",
        turboTimes: [],
        turboStructureStartTime: 0,
      });
      console.log(err);
      cogoToast.error(
        "We ran into an error transcribing this audio. Please try again."
      );
    }
  };
  calculateDiff() {
    const { transcribedText, polishedText, diffData } = this.state;

    // Initialize an offset to keep track of how much of the transcribedText has been polished
    let offset = 0;

    // Calculate the diff between the new chunk of polishedText and the corresponding segment of transcribedText
    const diff = diffChars(
      transcribedText.substring(offset, offset + polishedText.length),
      polishedText
    );
    offset += polishedText.length;
    const augmentedDiff = diff.map((part, index) => ({
      ...part,
      flash: !diffData[index] || part.value !== diffData[index]?.value,
    }));
    const remainingTranscribedText = transcribedText.substring(offset);
    if (remainingTranscribedText) {
      augmentedDiff.push({
        value: remainingTranscribedText,
        added: false,
        removed: false,
        flash: false,
      });
    }
    this.setState({ diffData: augmentedDiff });
  }
  polishTranscription = async () => {
    let transcriptionOutput = this.state.transcribedText;
    if (!transcriptionOutput) {
      cogoToast.error("We couldn't understand your audio.");
      this.setState({
        isError: false,
        hasAudioFileArrived: false,
        statusText: "",
        audioFile: null,
        audioTime: 0,
        uploadTime: 0,
        transcriptionTime: 0,
        transcribedText: "",
        polishedText: "",
        polishTime: 0,
        polishStartTime: 0,
        diffData: [],
        turboStructureTime: 0,
        turboSectionStartTime: 0,
        turboStructureText: "",
        turboSections: [],
        turboHangingText: "",
        turboTimes: [],
        turboStructureStartTime: 0,
      });
      return;
    }
    try {
      this.setState({
        polishStartTime: new Date(),
      });
      this.setState({
        statusText: "Warming up model",
        polishedText: "",
      });
      let createChatRes = await apiCallPostAI(
        this.state.alchemyToken,
        "/chat/createChatConversation",
        {
          applicationCtxId: this.props.data.visitId,
          tool: "NC_TURBO_CLEAN_UP",
          promptHead: masterSystemPrompts.TURBO_POLISH.promptHead,
          model: "gpt-4o",
          temperature: 0.9,
          engine: "openai",
        }
      );
      let chatId = createChatRes._id;
      this.setState({
        correctionId: chatId,
      });
      try {
        this.setState({
          statusText: masterSystemPrompts.TURBO_POLISH.statusText,
          polishedText: "",
        });
        let payload = {
          conversationId: chatId,
          lastContext: {
            role: "user",
            content: transcriptionOutput,
          },
        };
        await apiCallPostStreamingForAI(
          "/chat/generateChatCompletionSSE",
          this.state.alchemyToken,
          payload,
          (data) => {
            this.setState(
              {
                polishedText: this.state.polishedText + data,
              },
              () => this.calculateDiff()
            );
          },
          () => {
            let endTime = new Date();
            let duration = Math.floor(
              (endTime - this.state.polishStartTime) / 1000
            );
            this.setState(
              {
                polishTime: duration,
              },
              () => {
                this.turboStructureTranscript();
              }
            );
          }
        );
      } catch (err) {
        this.setState({
          isError: false,
          hasAudioFileArrived: false,
          statusText: "",
          audioFile: null,
          audioTime: 0,
          uploadTime: 0,
          transcriptionTime: 0,
          transcribedText: "",
          polishedText: "",
          polishTime: 0,
          polishStartTime: 0,
          diffData: [],
          turboStructureTime: 0,
          turboSectionStartTime: 0,
          turboStructureText: "",
          turboSections: [],
          turboHangingText: "",
          turboTimes: [],
          turboStructureStartTime: 0,
        });
        console.log(err);
        cogoToast.error("We ran into an error polishing the transcription.");
      }
    } catch (err) {
      this.setState({
        isError: false,
        hasAudioFileArrived: false,
        statusText: "",
        audioFile: null,
        audioTime: 0,
        uploadTime: 0,
        transcriptionTime: 0,
        transcribedText: "",
        polishedText: "",
        polishTime: 0,
        polishStartTime: 0,
        diffData: [],
        turboStructureTime: 0,
        turboSectionStartTime: 0,
        turboStructureText: "",
        turboSections: [],
        turboHangingText: "",
        turboTimes: [],
        turboStructureStartTime: 0,
      });
      console.log(err);
      cogoToast.error(
        "We ran into an error initating the transcription clean up."
      );
    }
  };
  turboStructureTranscript = async () => {
    let polishedText = this.state.polishedText;
    if (!polishedText) {
      cogoToast.error("We couldn't understand your audio.");
      this.setState({
        isError: false,
        hasAudioFileArrived: false,
        statusText: "",
        audioFile: null,
        audioTime: 0,
        uploadTime: 0,
        transcriptionTime: 0,
        transcribedText: "",
        polishedText: "",
        polishTime: 0,
        polishStartTime: 0,
        diffData: [],
        turboStructureTime: 0,
        turboSectionStartTime: 0,
        turboStructureText: "",
        turboSections: [],
        turboHangingText: "",
        turboTimes: [],
        turboStructureStartTime: 0,
      });
      return;
    }
    if (polishedText === "<NONE>") {
      cogoToast.error("We couldn't understand your audio.");
      this.setState({
        isError: false,
        hasAudioFileArrived: false,
        statusText: "",
        audioFile: null,
        audioTime: 0,
        uploadTime: 0,
        transcriptionTime: 0,
        transcribedText: "",
        polishedText: "",
        polishTime: 0,
        polishStartTime: 0,
        diffData: [],
        turboStructureTime: 0,
        turboSectionStartTime: 0,
        turboStructureText: "",
        turboSections: [],
        turboHangingText: "",
        turboTimes: [],
        turboStructureStartTime: 0,
      });
      return;
    }
    try {
      this.setState({
        turboStructureStartTime: new Date(),
        statusText: "Warming up model",
        turboStructureText: "",
      });

      let createChatRes = await apiCallPostAI(
        this.state.alchemyToken,
        "/chat/createChatConversation",
        {
          applicationCtxId: this.props.data.visitId,
          tool: "NC_TURBO_STRUCTURE",
          promptHead: masterSystemPrompts.TURBO_STRUCTURE.promptHead,
          model: "gpt-4o",
          temperature: 0.9,
          engine: "openai",
        }
      );
      let chatId = createChatRes._id;
      this.setState({
        structuringId: chatId,
      });
      try {
        this.setState({
          statusText: masterSystemPrompts.TURBO_STRUCTURE.statusText,
          turboStructureText: "",
        });
        let payload = {
          conversationId: chatId,
          lastContext: {
            role: "user",
            content: polishedText,
          },
        };
        await apiCallPostStreamingForAI(
          "/chat/generateChatCompletionSSE",
          this.state.alchemyToken,
          payload,
          (data) => {
            if (!this.state.turboSectionStartTime) {
              this.setState({
                turboSectionStartTime: new Date(),
              });
            }
            let hasDataEncounteredNewLine = data.includes("\n");
            this.setState((prevState) => {
              let newTurboSections = [...prevState.turboSections];
              let newTurboHangingText = prevState.turboHangingText;

              if (hasDataEncounteredNewLine) {
                let splitData = data.split("\n");
                let lastBit = _.last(splitData);

                if (splitData.length === 2) {
                  newTurboSections.push({
                    text: newTurboHangingText + splitData[0],
                  });
                  newTurboHangingText = lastBit;
                } else if (splitData.length > 2) {
                  newTurboSections.push({
                    text: newTurboHangingText + _.first(splitData),
                  });
                  newTurboSections = newTurboSections.concat(
                    splitData
                      .slice(1, splitData.length - 1)
                      .map((x) => ({ text: x }))
                  );
                  newTurboHangingText = lastBit;
                }
              } else {
                newTurboHangingText += data;
              }

              return {
                turboSections: newTurboSections,
                turboHangingText: newTurboHangingText,
              };
            });
          },
          () => {
            let endTime = new Date();
            let duration = Math.floor(
              (endTime - this.state.turboStructureStartTime) / 1000
            );

            this.setState((prevState) => {
              let newTurboSections = [...prevState.turboSections];
              if (prevState.turboHangingText !== "") {
                newTurboSections.push({ text: prevState.turboHangingText });
              }
              return {
                turboSections: newTurboSections,
                turboHangingText: "",
                turboStructureTime: duration,
                statusText: "Structuring in parallel",
              };
            });
          }
        );
      } catch (err) {
        this.setState({
          isError: false,
          hasAudioFileArrived: false,
          statusText: "",
          audioFile: null,
          audioTime: 0,
          uploadTime: 0,
          transcriptionTime: 0,
          transcribedText: "",
          polishedText: "",
          polishTime: 0,
          polishStartTime: 0,
          diffData: [],
          turboStructureTime: 0,
          turboSectionStartTime: 0,
          turboStructureText: "",
          turboSections: [],
          turboHangingText: "",
          turboTimes: [],
          turboStructureStartTime: 0,
        });
        console.log(err);
        cogoToast.error("We ran into an error structuring the transcription.");
      }
    } catch (err) {
      this.setState({
        isError: false,
        hasAudioFileArrived: false,
        statusText: "",
        audioFile: null,
        audioTime: 0,
        uploadTime: 0,
        transcriptionTime: 0,
        transcribedText: "",
        polishedText: "",
        polishTime: 0,
        polishStartTime: 0,
        diffData: [],
        turboStructureTime: 0,
        turboSectionStartTime: 0,
        turboStructureText: "",
        turboSections: [],
        turboHangingText: "",
        turboTimes: [],
        turboStructureStartTime: 0,
      });
      console.log(err);
      cogoToast.error(
        "We ran into an error initating the transcription structuring."
      );
    }
  };
  renderHeader = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "42vw" }}>
        <Card
          elevation={Elevation.THREE}
          style={{
            padding: "5px",
            width: "42vw",
          }}
        >
          <ControlGroup vertical>
            <ControlGroup style={{ width: "35vw" }}>
              <Tag intent="success" className="rose__bg" multiline large>
                Turbo Mode
              </Tag>
              <Tag multiline minimal>
                Speak in the entire Rx in one go.
              </Tag>
              <Button
                small
                icon={this.state.isTypingMode ? "text-highlight" : "headset"}
                className="colfax"
                onClick={() => {
                  this.setState({
                    isTypingMode: !this.state.isTypingMode,
                  });
                }}
                text={
                  this.state.isTypingMode
                    ? "Current Mode: Typing"
                    : "Current Mode: Recording"
                }
              />
            </ControlGroup>
          </ControlGroup>
        </Card>
      </div>
    );
  };
  renderTranscriptionStatus = () => {
    if (!this.state.hasAudioFileArrived || this.state.polishedText) {
      return null;
    }
    if (!this.state.transcribedText) {
      return null;
    }
    return (
      <Card className="card__container">
        <Tag minimal className="cerulean__bg" style={{ marginBottom: "5px" }}>
          Transcribed Text
        </Tag>
        <br />
        <p className="small_text">{this.state.transcribedText}</p>
      </Card>
    );
  };
  renderPolishStatus = () => {
    if (!this.state.transcribedText) {
      return null;
    }
    if (!this.state.polishedText) {
      return null;
    }
    if (!this.state.polishStartTime) {
      return null;
    }
    return (
      <Card className="card__container" style={{ marginTop: "10px" }}>
        <Tag minimal className="cerulean__bg" style={{ marginBottom: "5px" }}>
          Polished Text
        </Tag>
        <br />
        {this.state.diffData.map((part, index) => {
          let cN = "colfax small_text";
          if (part.removed) {
            cN += " rose__bg strike_through";
          }
          if (part.added) {
            cN += " forest__bg";
          }
          if (part.flash) {
            cN += " flash";
          }
          return (
            <span key={index} className={cN}>
              {part.value}
            </span>
          );
        })}
      </Card>
    );
  };
  renderStructuredPanel = () => {
    if (!this.state.polishedText) {
      return null;
    }
    if (!this.state.turboStructureStartTime) {
      return null;
    }
    return (
      <Card className="card__container" style={{ marginTop: "10px" }}>
        <Tag minimal className="cerulean__bg" style={{ marginBottom: "5px" }}>
          Structured Text
        </Tag>
        <br />
        {this.state.turboSections.map((each, index) => {
          return (
            <TurboSection
              key={index}
              alchemyToken={this.state.alchemyToken}
              data={each}
              visitId={this.props.data.visitId}
              patientId={this.props.data.patientId}
              addVisitEntity={this.props.addVisitEntity}
              onDoneTime={(time) => {
                this.setState({
                  turboTimes: [...this.state.turboTimes, time],
                });
              }}
              onFile={(file) => this.props.onFile(file)}
              onError={() =>
                this.setState({
                  isError: true,
                })
              }
            />
          );
        })}
        <div className="small_text">{this.state.turboHangingText}</div>
      </Card>
    );
  };
  renderStatsPanel = () => {
    let isDone =
      this.state.turboStructureTime &&
      this.state.turboSections.length &&
      this.state.turboTimes.length === this.state.turboSections.length;
    let dur = 0;
    if (isDone) {
      let turboTimeFinal = _.max(this.state.turboTimes);
      let turboSectionStartTime = this.state.turboSectionStartTime;
      dur =
        Math.floor((turboTimeFinal - turboSectionStartTime) / 1000) -
        this.state.turboStructureTime;
    }
    let total =
      this.state.uploadTime +
      this.state.transcriptionTime +
      dur +
      this.state.polishTime +
      this.state.turboStructureTime;
    return (
      <Card className="card__container" style={{ marginTop: "10px" }}>
        <ControlGroup>
          <Tag intent="success" className="lime__bg">
            AI Status Card
          </Tag>
          {isDone ? (
            <Button
              icon="reset"
              text="Start Over"
              className="colfax"
              onClick={() => {
                this.setState({
                  isError: false,
                  hasAudioFileArrived: false,
                  statusText: "",
                  audioFile: null,
                  audioTime: 0,
                  uploadTime: 0,
                  transcriptionTime: 0,
                  transcribedText: "",
                  polishedText: "",
                  polishTime: 0,
                  polishStartTime: 0,
                  diffData: [],
                  turboStructureTime: 0,
                  turboSectionStartTime: 0,
                  turboStructureText: "",
                  turboSections: [],
                  turboHangingText: "",
                  turboTimes: [],
                  turboStructureStartTime: 0,
                });
              }}
            />
          ) : (
            <ClinicalLoader />
          )}
        </ControlGroup>
        {isDone ? null : (
          <ControlGroup>
            <Spinner size={20} />
            <TextWithEllipsis text={this.state.statusText} />
          </ControlGroup>
        )}
        {this.state.audioTime > 0 ? (
          <ControlGroup>
            <Tag icon="headset" minimal>
              Audio duration: {this.formatTime(this.state.audioTime)}
            </Tag>
          </ControlGroup>
        ) : null}
        {this.state.uploadTime > 0 ? (
          <ControlGroup>
            <Tag icon="cloud-upload" minimal>
              Uploaded In: {this.formatTime(this.state.uploadTime)}
            </Tag>
          </ControlGroup>
        ) : null}{" "}
        {this.state.transcriptionTime > 0 && (
          <ControlGroup>
            <Tag icon="manually-entered-data" minimal>
              Transribed In: {this.formatTime(this.state.transcriptionTime)}
            </Tag>
          </ControlGroup>
        )}{" "}
        {this.state.polishTime > 0 ? (
          <ControlGroup>
            <Tag icon="clean" minimal>
              Polished In: {this.formatTime(this.state.polishTime)}
            </Tag>
          </ControlGroup>
        ) : null}
        {this.state.turboStructureTime > 0 && (
          <ControlGroup>
            <Tag icon="merge-columns" minimal>
              Structured into {this.state.turboSections.length} sections in:{" "}
              {this.formatTime(this.state.turboStructureTime)}
            </Tag>
          </ControlGroup>
        )}
        {isDone ? (
          <ControlGroup>
            <Tag icon="layout-auto" minimal>
              Processed {this.state.turboSections.length} sections in parallel
              with additional time: {this.formatTime(dur)}
            </Tag>
          </ControlGroup>
        ) : null}
        {isDone ? (
          <ControlGroup>
            <Tag icon="stopwatch" minimal className="forest__bg">
              Total: {this.formatTime(total)}
            </Tag>
          </ControlGroup>
        ) : null}
      </Card>
    );
  };
  renderTextInputArea = () => {
    return (
      <div style={{ width: "100%", marginTop: "15px" }}>
        <ControlGroup>
          <Tag large intent="danger">
            Type Here
          </Tag>{" "}
        </ControlGroup>
        <TextArea
          disabled={
            this.state.isLoading ||
            this.state.isError ||
            this.state.hasAudioFileArrived
          }
          className="colfax"
          style={{ fontSize: "small" }}
          fill
          autoResize
          value={this.state.transcribedText}
          onChange={(e) =>
            this.setState({
              transcribedText: e.target.value,
            })
          }
        />{" "}
        <ControlGroup>
          <Button
            disabled={
              !this.state.transcribedText ||
              this.state.isLoading ||
              this.state.isError ||
              this.state.hasAudioFileArrived
            }
            icon="clean"
            text="Start Processing With AI"
            className="colfax"
            onClick={() => {
              this.setState(
                {
                  hasAudioFileArrived: true,
                },
                () => {
                  this.polishTranscription();
                }
              );
            }}
          />
        </ControlGroup>
      </div>
    );
  };
  render() {
    if (this.state.isLoading) {
      return <div>{<Spinner />}</div>;
    }
    if (this.state.isError || !this.state.alchemyToken) {
      return (
        <div>
          <NonIdealState
            icon="warning-sign"
            description="Couldn't connect to AI servers. Try refreshing the page?"
          />
        </div>
      );
    }
    return (
      <div style={{ width: "42vw" }} className="colfax">
        {this.renderHeader()}
        {this.state.isTypingMode ? (
          this.renderTextInputArea()
        ) : (
          <>
            {!this.state.hasAudioFileArrived && (
              <TurboAudio
                hasAudioFileArrived={this.state.hasAudioFileArrived}
                onAudioFile={(file, time) => {
                  this.transcribeAudio(file, time);
                }}
              />
            )}
          </>
        )}

        {this.state.hasAudioFileArrived && this.renderStatsPanel()}
        {this.renderTranscriptionStatus()}
        {this.renderPolishStatus()}
        {this.renderStructuredPanel()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(TurboMode));
