import { observable, decorate } from "mobx";
import { persist } from "mobx-persist";

export class BookingStore {
  whichPatientInFocus = {};
  searchesToAction = 0;
  searchStart = new Date();
  recordsViewed = 0;
  enquiryStart = new Date();
  enquiryEnd = new Date();
  bookingStart = new Date();
  datesSearched = 0;
  bookingEnd = new Date();
  newPatient = false;
  /** WILL FAIL */
  rescheduleDetails = {};
  isReschedule = false;
  rescheduleDocketNo = null;
  isList = false;
  goBackTo = null;
}

decorate(BookingStore, {
  whichPatientInFocus: [persist("object"), observable],
  searchesToAction: [persist, observable],
  newPatient: [persist, observable],
  searchStart: [persist("object"), observable],
  recordsViewed: [persist, observable],
  enquiryStart: [persist("object"), observable],
  enquiryEnd: [persist("object"), observable],
  bookingStart: [persist("object"), observable],
  datesSearched: [persist, observable],
  bookingEnd: [persist("object"), observable],
  rescheduleDetails: [persist("object"), observable],
  isReschedule: [persist, observable],
  rescheduleDocketNo: [persist, observable],
  isList: [persist, observable],
  goBackTo: [persist, observable],
});
export default new BookingStore();
