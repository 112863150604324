import React from "react";
import { inject, observer } from "mobx-react";
import {
  Button,
  ControlGroup,
  Dialog,
  InputGroup,
  NonIdealState,
  Tag,
} from "@blueprintjs/core";
import { niceDate, apiCallPost, apiCallPostCancellable } from "../fns/util";
import cogoToast from "cogo-toast";

class MatchPatient extends React.Component {
  state = {
    possibleProfiles: [],
    searchPid: null,
    name: null,
    dob: null,
    sex: null,
    pid: null,
    phone: null,
  };
  renderSearch = () => {
    return (
      <ControlGroup>
        <InputGroup
          onChange={(event) => this.setState({ searchPid: event.target.value })}
        />
        <Button
          disabled={!this.state.searchPid}
          minimal
          intent="primary"
          rightIcon="search"
          text="Search"
          fill
          onClick={() => this.searchResults()}
        />
      </ControlGroup>
    );
  };
  searchResults = async () => {
    try {
      cogoToast.loading("Searching For Profiles...", {
        position: "bottom-left",
      });
      let res = await apiCallPost(
        `/billing/${this.props.authStore.prefix}/query_pid_match`,
        { prefixPid: this.state.searchPid }
      );
      this.setState({ possibleProfiles: res });

      cogoToast.success(`Search Complete with ${res.length} results.`, {
        position: "bottom-left",
      });
    } catch (err) {
      cogoToast.error("Could not find a match.", {
        position: "bottom-left",
      });
      console.log(err);
    }
  };
  renderDetails = () => {
    if (!this.state.possibleProfiles.length) {
      return (
        <NonIdealState
          className="bp5-dark"
          icon="ban-circle"
          title="No matches found"
          description="Please search with a patient ID."
        />
      );
    }

    return this.state.possibleProfiles.map((each) => {
      return (
        <ControlGroup>
          <ControlGroup vertical>
            <ControlGroup fill>
              <Tag large minimal>
                PID
              </Tag>
              <Tag large intent="success">
                {each.sysPid}
              </Tag>
            </ControlGroup>

            <ControlGroup fill>
              <Tag large minimal>
                Name
              </Tag>
              <Tag large intent="primary">
                {each.name}
              </Tag>
            </ControlGroup>
            <ControlGroup fill>
              <Tag large minimal>
                Phone
              </Tag>
              <Tag large minimal intent="success">
                {each.phone}
              </Tag>
            </ControlGroup>
            <ControlGroup fill>
              <Tag large minimal>
                DoB
              </Tag>
              <Tag large minimal intent="warning">
                {" "}
                {niceDate(each.dob)}
              </Tag>
            </ControlGroup>
            <ControlGroup fill>
              <Tag large minimal>
                Sex
              </Tag>
              <Tag large minimal intent="none">
                {each.sex}
              </Tag>
            </ControlGroup>
          </ControlGroup>
          <Button
            intent="success"
            text="Merge Profiles"
            outlined
            icon="merge-columns"
            onClick={() => {
              this.setState({ matched: each }, () => this.match(each.sysPid));
            }}
          />
        </ControlGroup>
      );
    });
  };
  match = async (sysPid) => {
    let payload = {
      setPid: sysPid,
      genericPid: this.props.patientStore.genericPid,
    };

    try {
      cogoToast.loading("Merging Profiles...", {
        position: "bottom-left",
      });
      await apiCallPost(
        `/billing/${this.props.authStore.prefix}/set_pid_match`,
        payload
      );

      cogoToast.success("Merged. Now refreshing details.", {
        position: "bottom-left",
      });
      try {
        let res = await apiCallPost(
          `/util/${this.props.authStore.prefix}/patient/getAtlas`,
          { genericPid: sysPid }
        );

        this.props.patientStore.patientName = res.name;
        this.props.patientStore.genericPid = sysPid;
        this.props.patientStore.patientAge =
          new Date().getFullYear() - new Date(res.dob).getFullYear();
        this.props.patientStore.patientSex = res.sex;
        this.props.patientStore.patientPhone = res.phone;
        this.props.patientStore.panels = res.panels;
        let v = await apiCallPostCancellable(
          `/sidepane/${this.props.authStore.prefix}/previousVisits`,
          {
            PID: sysPid,
          }
        );
        this.props.patientStore.patientPreviousVisits = v;
        // reload window -- so that state is reset...
        window.location.reload();
      } catch (err) {
        cogoToast.error("Error getting new details.", {
          position: "bottom-left",
        });
        console.log(err);
      }
      this.props.onClose();
    } catch (err) {
      cogoToast.error("Error merging.", {
        position: "bottom-left",
      });
      console.log(err);
    }
  };
  render() {
    return (
      <Dialog
        className="bp5-dark dialog"
        isOpen={this.props.isOpen}
        canEscapeKeyClose
        canOutsideClickClose
        onClose={() => this.props.onClose()}
        title="Merge Patient"
        icon="inner-join"
        style={{ width: "fit-content" }}
      >
        <div className="event-dialog bp5-dark">
          <ControlGroup vertical>
            <Tag large minimal intent="success">
              Merging with: {this.props.patientStore.genericPid} |{" "}
              {this.props.patientStore.patientName}
            </Tag>
            {this.renderSearch()}
            <br />
            {this.renderDetails()}
          </ControlGroup>
        </div>
      </Dialog>
    );
  }
}

export default inject(
  "bookingStore",
  "patientStore",
  "authStore"
)(observer(MatchPatient));
