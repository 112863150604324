import { Button, ControlGroup, EditableText, Tag } from "@blueprintjs/core";
import React from "react";
import { initials } from "../../fns/util";

import MutEx from "../../utils/MutEx";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class Diagnosis extends React.Component {
  state = {
    editingSite: false,
    editingWhen: false,
    when: "",
  };
  listOfEyes = ["Left Eye", "Right Eye", "Both Eyes"];

  renderDiagnosis = (data) => {
    let st = {};
    if (!this.props.isOld) {
      st = { marginTop: "5px" };
    }
    return (
      <ControlGroup style={st}>
        {this.props.isOld ? (
          <>
            {this.state.editingSite ? (
              <SingleSelectWrapper
                items={["Right Eye", "Left Eye", "Both Eyes"]}
                selectedItem={data.site}
                onChange={(item) => {
                  this.props.update("site", item);
                  this.setState({ editingSite: false });
                }}
              />
            ) : (
              <Tag
                minimal
                interactive
                onClick={() => this.setState({ editingSite: true })}
              >
                {initials(data.site)}
              </Tag>
            )}
          </>
        ) : (
          <>
            {this.props.templateEditing ? null : (
              <MutEx
                large={this.props.isOld ? false : true}
                minimal={this.props.isOld ? true : false}
                options={["RE", "BE", "LE"]}
                onUpdate={(option) => {
                  let opt = {
                    RE: "Right Eye",
                    LE: "Left Eye",
                    BE: "Both Eyes",
                  }[option];
                  this.props.update(data._id, "site", opt);
                }}
                selected={((x) => {
                  let lu = {
                    "Right Eye": "RE",
                    "Left Eye": "LE",
                    "Both Eyes": "BE",
                  };
                  return lu[x];
                })(data.site)}
              />
            )}
          </>
        )}
        {this.props.isOld ? null : (
          <Tag
            minimal={!data.query}
            large
            intent="warning"
            interactive
            onClick={() => this.props.update(data._id, "query", !data.query)}
            icon="help"
          ></Tag>
        )}{" "}
        <Tag
          large
          intent={
            data.type === "old"
              ? data.checked
                ? "primary"
                : "danger"
              : "primary"
          }
          minimal={data.type === "old" ? (data.checked ? true : false) : true}
          onRemove={() => this.props.remove(data._id)}
        >
          {data.disease}
        </Tag>
        {this.props.isOld ? null : (
          <>
            {data.icd11 ? (
              <Tag minimal intent="warning">
                {data.icd11}
              </Tag>
            ) : null}
            {data.type === "old" && !data.checked ? (
              <Button
                minimal
                icon="tick"
                onClick={() => this.props.update(data._id, "checked", true)}
              />
            ) : null}
            {data.isOld ? (
              <EditableText
                value={this.state.editingWhen ? this.state.when : data.when}
                placeholder="When?"
                onChange={(value) =>
                  this.setState({ when: value, editingWhen: true })
                }
                onConfirm={() => {
                  this.props.update(
                    data._id,
                    "when",
                    this.state.editingWhen ? this.state.when : data.when
                  );
                  this.setState({ editingWhen: false });
                }}
              />
            ) : null}{" "}
          </>
        )}
      </ControlGroup>
    );
  };

  render() {
    return <div>{this.renderDiagnosis(this.props.data)}</div>;
  }
}

export default Diagnosis;
