import React from "react";
import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import Landing from "./micro/generic/Landing";
import Navigation from "./micro/generic/Navigation";
import SplitPanel from "./micro/utils/SplitPanel";
import Search from "./micro/generic/Search";
import BookingViewPanel from "./micro/generic/BookingViewPanel";
import BillDetail from "./micro/billing/BillDetail";
import List from "./micro/list/List";
import { apiCallPost } from "./micro/fns/util";
import { NonIdealState, Spinner } from "@blueprintjs/core";
import DataGrid from "./micro/datagrid/DataGrid";
import UserManagement from "./micro/users/UserManagement";
import Clinical from "./micro/opto/Clinical";
import BookingTopV2 from "./micro/booking-v2/BookingTopV2";
import { withAuth0 } from "@auth0/auth0-react";
import cogoToast from "cogo-toast";
import PatientDetails from "./micro/singles/PatientDetails";
import TemplateMother from "./micro/opto/templates/TemplateMother";
import Template from "./micro/opto/templates/Template";
import ScheduleSummary from "./micro/booking-v2/ScheduleSummary";
import CashTill from "./micro/billing/CashTill";
import ClinicDashboard from "./micro/opto/elems/ClinicDashboard";
import BuildInfo from "./BuildInfo";
import ClinicalV2 from "./micro/clinical/ClinicalV2";
import ChatterLanding from "./micro/clinical/chatter/ChatterLanding";
import TemplatesFavourites from "./micro/clinical/templates/TemplatesFavourites";
import HorizonLanding from "./micro/clinical/chatter/HorizonLanding";
import * as Sentry from "@sentry/react";
class App extends React.Component {
  state = {
    authing: false,
    hydrated: false,
  };
  componentDidMount = async () => {
    console.log(BuildInfo());
    this.pollID = this.poll();
  };
  poll = () => {
    var a = setInterval(() => {
      let isHydrated = this.props.root.getHydration();

      if (isHydrated) {
        this.setState({ hydrated: true });
        this.getJWT();
      } else {
      }
    }, 1000);
    return a;
  };
  getJWT = async () => {
    let x = await this.props.auth0.getAccessTokenSilently({
      audience: "https://newchart.us.auth0.com/api/v2/",
      scope: "read:current_user openid profile email",
    });
    localStorage.setItem("auth0jwt", x);
    this.clearPoll();
    return;
  };
  clearPoll = async () => {
    clearInterval(this.pollID);
    try {
      this.setState({ authing: true });
      let {
        _id,
        prefix,
        scope,
        name,
        sudoCanSwitchTo,
        supportLegacyPid,
        rxClinicalOptions,
        supportAddlSearch,
      } = await apiCallPost("/auth/me", {
        user: this.props.auth0.user,
        // _id: _id,
        // prefix: prefix,
      });
      // Sentry.setUser({ name: name });
      this.props.authStore.prefix = prefix;
      this.props.authStore.isLoggedIn = true;
      this.props.authStore.scope = scope;
      this.props.authStore.name = name;
      this.props.authStore.sudoCanSwitchTo = sudoCanSwitchTo || [];
      this.props.authStore.supportLegacyPid = supportLegacyPid;
      this.props.authStore.rxClinicalOptions = rxClinicalOptions || {};
      this.props.authStore.supportAddlSearch = supportAddlSearch;
      this.setState({ authing: false }, () => this.getLists());
    } catch (err) {
      this.setState({ authing: false });
      localStorage.setItem("authStore", null);
      localStorage.setItem("auth0jwt", null);
      cogoToast.error("Unauthorized User");
      if (this.props.auth0.isAuthenticated) {
        this.props.auth0.logout({ returnTo: window.location.origin });
      }
    }
  };
  getLists = async () => {
    let resDoc = await apiCallPost(
      `/rai/${this.props.authStore.prefix}/getAllConsultants`,
      {}
    );
    let doctorList = [];
    _.map(resDoc, (each) => doctorList.push(each.name));
    this.props.listStore.doctorList = doctorList;
    let resSite = await apiCallPost(
      `/rai/${this.props.authStore.prefix}/getAllSites`,
      {}
    );
    this.props.listStore.siteList = resSite;
    let resPanels = await apiCallPost(
      `/rai/${this.props.authStore.prefix}/getAllPanels`
    );
    this.props.listStore.panelList = resPanels.map((each) => each.name);
  };
  render() {
    if (!this.state.hydrated) {
      return (
        <div className="App">
          <div className="main">
            <NonIdealState
              className="bp5-dark"
              icon={<Spinner />}
              title="Loading..."
              description="We're ensuring your data is consistent..."
            />
          </div>
        </div>
      );
    }
    if (this.state.authing) {
      return (
        <div className="App">
          <div className="main">
            <NonIdealState
              className="bp5-dark"
              icon={<Spinner />}
              title="Loading..."
              description="We're verifying authentication status..."
            />
          </div>
        </div>
      );
    }
    if (!this.props.auth0.isAuthenticated) {
      return (
        <div className="App bp5-dark">
          <BrowserRouter>
            <Route path="/" component={Landing} />
          </BrowserRouter>
        </div>
      );
    }
    if (!this.props.authStore.isLoggedIn) {
      return (
        <div className="App bp5-dark">
          <BrowserRouter>
            <Route path="/" component={Landing} />
          </BrowserRouter>
        </div>
      );
    }
    return (
      <div className="App bp5-dark">
        <div className="main">
          <BrowserRouter>
            <Route path="/" component={Navigation} />
            <Route
              path="/"
              exact
              render={(props) => (
                <SplitPanel
                  {...props}
                  left={<Search />}
                  right={<BookingViewPanel />}
                />
              )}
            />
            <Route path="/users" exact component={UserManagement} />
            <Route path="/report" exact component={DataGrid} />
            <Route path="/booking/:searchStr?" exact component={BookingTopV2} />
            <Route path="/billing" exact component={BillDetail} />
            <Route path="/clinic" exact component={Clinical} />
            <Route
              path="/clinical/:patientId/:visitId"
              exact
              component={Clinical}
            />
            <Route
              path="/clinical/v2/:patientId/:visitId"
              exact
              component={ClinicalV2}
            />
            <Route
              path="/clinicaldashboard"
              exact
              component={ClinicDashboard}
            />
            <Route
              path="/list"
              exact
              render={(props) => (
                <SplitPanel
                  {...props}
                  left={<List />}
                  right={<BookingViewPanel />}
                />
              )}
            />
            <Route
              path="/patientdetails/:searchStr?"
              exact
              component={PatientDetails}
            />
            <Route path="/templates/" exact component={TemplatesFavourites} />
            <Route path="/templates/:templateId" exact component={ClinicalV2} />
            <Route path="/templates2/" exact component={TemplateMother} />
            <Route
              path="/templates/:templateId/:mode"
              exact
              component={Template}
            />{" "}
            <Route path="/chat/" exact component={ChatterLanding} />
            <Route path="/horizon/" exact component={HorizonLanding} />
            <Route path="/schedules" exact component={ScheduleSummary} />
            <Route path="/cashtill" exact component={CashTill} />
          </BrowserRouter>
        </div>
      </div>
    );
  }
}

export default inject(
  "root",
  "authStore",
  "bookingStore",
  "listStore"
)(observer(withAuth0(App)));
