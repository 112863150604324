import { ControlGroup, EditableText, Tag } from "@blueprintjs/core";
import React from "react";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";

class Finding extends React.Component {
  state = {
    editQualifier: false,
  };
  renderNewFinding = (data) => {
    return (
      <ControlGroup style={{ marginBottom: "5px" }} vertical>
        <ControlGroup>
          <Tag
            large
            intent="primary"
            minimal
            onRemove={() => this.props.remove(data._id)}
            interactive
            onDoubleClick={() => this.props.duplicate()}
          >
            {data.item}
          </Tag>
          {this.state.editQualifier &&
          data.listOfQualifiers &&
          data.listOfQualifiers.length ? (
            <SingleSelectWrapper
              items={data.listOfQualifiers}
              onChange={(item) => {
                this.setState({ editQualifier: false });
                this.props.editObjectInside(data._id, "qualifier", item);
              }}
            />
          ) : (
            <>
              {data.listOfQualifiers && data.listOfQualifiers.length ? (
                <Tag
                  minimal
                  large
                  interactive
                  onClick={() => this.setState({ editQualifier: true })}
                >
                  {data.qualifier}
                </Tag>
              ) : null}
            </>
          )}
        </ControlGroup>
        {this.props.templateEditing ? null : (
          <div style={{ fontSize: "small" }}>
            <EditableText
              placeholder="Special Notes"
              value={this.state.textTouched ? this.state.splText : data.splText}
              onChange={(value) => {
                this.setState({ textTouched: true, splText: value });
              }}
              onConfirm={() =>
                this.props.editObjectInside(
                  data._id,
                  "splText",
                  this.state.textTouched ? this.state.splText : data.splText
                )
              }
            />
          </div>
        )}
      </ControlGroup>
    );
  };
  renderOldFinding = (data) => {
    return (
      <ControlGroup style={{ marginBottom: "5px" }} vertical>
        <ControlGroup>
          <Tag
            large
            intent={data.checked ? "primary" : "danger"}
            minimal={data.checked}
            onRemove={() => this.props.remove(data._id)}
            interactive
          >
            {data.item}
          </Tag>

          {this.state.editQualifier &&
          data.listOfQualifiers &&
          data.listOfQualifiers.length ? (
            <SingleSelectWrapper
              items={data.listOfQualifiers}
              onChange={(item) => {
                this.setState({ editQualifier: false });
                this.props.editObjectInside(data._id, "qualifier", item);
              }}
            />
          ) : (
            <>
              {data.listOfQualifiers && data.listOfQualifiers.length ? (
                <Tag
                  minimal
                  large
                  interactive
                  onClick={() => this.setState({ editQualifier: true })}
                >
                  {data.qualifier}
                </Tag>
              ) : null}
            </>
          )}

          <Tag
            large
            minimal
            icon="double-chevron-down"
            interactive
            intent={data.sinceLastVisit === "Decreasing" ? "success" : null}
            onClick={() =>
              this.props.editObjectInside(
                data._id,
                "sinceLastVisit",
                "Decreasing"
              )
            }
          ></Tag>
          <Tag
            large
            minimal
            icon="double-chevron-up"
            interactive
            intent={data.sinceLastVisit === "Increasing" ? "danger" : null}
            onClick={() =>
              this.props.editObjectInside(
                data._id,
                "sinceLastVisit",
                "Increasing"
              )
            }
          ></Tag>
          <Tag
            large
            minimal
            icon="ring"
            interactive
            intent={data.sinceLastVisit === "Same" ? "warning" : null}
            onClick={() =>
              this.props.editObjectInside(data._id, "sinceLastVisit", "Same")
            }
          ></Tag>
          {!data.checked ? (
            <Tag
              large
              minimal
              icon="tick"
              interactive
              onClick={() =>
                this.props.editObjectInside(data._id, "checked", true)
              }
            ></Tag>
          ) : null}
        </ControlGroup>
        <div style={{ fontSize: "small" }}>
          <EditableText
            placeholder="Special Notes"
            value={this.state.textTouched ? this.state.splText : data.splText}
            onChange={(value) => {
              this.setState({ textTouched: true, splText: value });
            }}
            onConfirm={() =>
              this.props.editObjectInside(
                data._id,
                "splText",
                this.state.textTouched ? this.state.splText : data.splText
              )
            }
          />
        </div>
      </ControlGroup>
    );
  };

  renderFindings = () => {
    if (this.props.data.type === "old") {
      return this.renderOldFinding(this.props.data);
    } else {
      return this.renderNewFinding(this.props.data);
    }
  };
  render() {
    return <div>{this.renderFindings()}</div>;
  }
}

export default Finding;
