import { ControlGroup, Tag, Button } from "@blueprintjs/core";
import React from "react";
import { initials } from "../../fns/util";
import MultiSelectWrapper from "../../utils/MultiSelectWrapper";
import MutEx from "../../utils/MutEx";
import SingleSelectWrapper from "../../utils/SingleSelectWrapper";
import _ from "lodash";

class Surgery extends React.Component {
  state = {
    editingSite: false,
    editingWhen: false,
    editingAnaesthesia: false,
    editingProgression: false,
    eye: null,
    anaesthesia: null,
    devices: null,
    medicines: null,
    iolImplants: null,
    sxItems: null,
    when: "",
    composeString: "NA",
  };

  listOfAnaesthesia = [
    "Topical Anaesthesia",
    "General Anaesthesia",
    "General Anaesthesia (Short)",
    "Local Anaesthesia",
    "Facial Block",
    "Lid Block",
  ];
  listOfEyes = ["Left Eye", "Right Eye", "Both Eyes"];
  listOfWhens = [
    "Urgently",
    "As Early As Possible",
    "Within 1 Month",
    "Within 2 Months",
    "By Next January",
    "By Next February",
    "By Next March",
    "By Next April",
    "By Next May",
    "By Next June",
    "By Next July",
    "By Next August",
    "By Next September",
    "By Next October",
    "By Next November",
    "By Next December",
    "After Durga Puja",
    "Before Durga Puja",
    "After Eid",
    "Before Eid",
    "After Diwali",
    "Before Diwali",
  ];
  listOfDevices = [
    "Punctum dilator",
    "Malyugin ring",
    "Gupta ring",
    "Bhattacharjee ring",
    "Iris hook",
    "CTR (Capsular tension ring)",
    "AMG (Amniotic membrane graft)",
    "Dermofat graft",
  ];
  listOfMedicines = [
    "Viscoat",
    "Healon",
    "Mannitol",
    "BBG (Brilliant blue green)",
    "Fluorescein",
    "MMC (Mitomycin C)",
    "5-FU (5-Fluorouracil)",
    "Glue ",
    "Glue (Tisseel)",
    "Cyanoacrylate glue",
    "Tricort",
  ];
  listOfImplants = [
    "AGV (Ahmed glaucoma valve)",
    "AADI (Aurolab aqueous drainage implant)",
    "Acrylic orbital implant",
    "Ologen implant",
    "HA (Hydroxyapatite orbital implant)",
    "Silicon orbital implant",
  ];
  listOfIols = [
    "Aspheric IOL",
    "Hydrophobic IOL",
    "Hydrophilic IOL",
    "Toric IOL",
    "Multifocal IOL",
    "Trifocal IOL",
    "Single piece IOL",
    "Multi piece IOL",
    "Foldable IOL",
    "Rigid IOL",
    "ACRILISA",
    "ACRIOL",
    "ACRIOL TOR",
    "ACRIVUE",
    "ACRY (IQ)",
    "ACRY (MP)",
    "ACRY (SP)",
    "ACRY ALL",
    "ACRY FOLD",
    "AKREOS",
    "APPA (SP)",
    "AT-LISA(80)",
    "AURO IOL",
    "AURO SCARA",
    "AURO SQ",
    "AUROIOL SQ",
    "AUROVUE",
    "CLARIFLEX",
    "EXPAND",
    "FLEXIFOLD",
    "IDIFF",
    "IOC LENS",
    "IRIS CLAW",
    "MATRIX",
    "ML 60",
    "NASPRO",
    "OCUFLEX",
    "OII ACOII PC",
    "PROPAK(IQ)",
    "RAYNER",
    "RESTOR",
    "RYCF",
    "SENSAR MP",
    "SENSAR SP",
    "SI40NB",
    "SMPL LENS",
    "SOFLEX",
    "SQ",
    "SUPERFLEX",
    "SUPRAPHOB",
    "SWISS",
    "TECHNIS Z9",
    "TECNIS-1",
    "TECNIS TORIC",
    "TICL",
    "INDIAN HYDROPHILIC",
    "INDIAN HYDROPHOBIC",
    "ALCON TORIC",
    "UNIV. AC",
    "ZEISS CT",
    "SUPRAPHOB TORIC",
    "T1 MF IOL",
    "AURO ACIOL",
    "REVISION",
    "PROPACK",
    "SOFPORT",
    "ANIRIDIA IOL",
    "SULCOFLEX",
    "ISERT",
    "ACRYSERT (IQ)",
    "ACRYSOF IQ",
    "ACRYSOF MP",
    "PIKPAK",
    "SPECTRAPHOB",
    "SYMFONY",
    "EYEPCL",
    "AURIUM IOL",
    "HYDRACLARITY",
    "SUPRAPHOB MF IOL",
    "IPCL",
    "ULTRASERT IQ",
    "U.S TORIC",
    "TORIC MF IOL",
    "HANITA",
    "RESTOR TORIC",
    "SYMFONY TORIC",
    "ACRIDIFF",
    "PANOPTIX",
    "LUCIA",
    "PANOPTIX TORIC",
    "HOYA",
    "HOYA ISERT",
    "OCUFINE",
    "TECNIS EYHANCE",
    "TECNIS-1 PRELOADED",
    "ITECH",
    "ULTIMA",
    "EYHANCE",
    "OCUFINE",
    "ATLISA",
    "KLARION",
    "CT ASPHINA",
    "CLAREON",
    "TECNIS SYNERGY",
    "OCUPAP",
    "PREJET PRELOADED",
    "TRINITY MULTIFOCAL",
    "OCUPAP",
    "TRIPHOBIC",
    "VIVINEX",
    "TIPCL",
    "SULCOFIX",
    "ENVISTA",
    "AUROVUE EV",
    "EYE-O-CARE",
    "ZEISS TORIC",
    "ZEISS AT LISA",
  ];

  listOfSxItems = [
    "IV Accentrix",
    "IV Razumab",
    "IV Eylea",
    "IV Pagenax",
    "IVTA (IV Triamcinolone acetonide)",
    "IV Ozurdex",
    "IV Dexamethasone",
    "IOAB (Intraocular antibiotic) injection",
    "Intravitreal antibiotic injection",
    "IV Moxifloxacin",
    "IV Ganciclovir",
    "IV Vancomycin",
    "IV Ceftazidime",
    "IV Dexamethasone",
    "IV Amikacin",
    "IV Amphotericin B",
    "IV Cotrimazole",
    "IV Clindamycin",
    "IV Foscarnet",
    "IV Methotrexate",
    "IV Voriconazole",
    "IV Avastin",
    "Intravitreal air injection",
    "IV C3F8",
    "SiO (Silicon oil) injection ",
    "V (Vitrectomy)",
    "MP (Membrane peeling) ",
    "EL (Endolaser)",
    "FGE (Fluid gas exchange)",
    "FAX (Fluid air exchange)",
    "C3F8",
    "SF6",
    "ILM (Internal limiting membrane) peeling",
    "ERM (Epiretinal membrane) removal",
    "Air",
    "SB (Scleral buckling)",
    "BB (Belt buckle)",
    "Internal choroidal drainage",
    "External choroidal drainage",
    "IOFB (Intraocular foreign body) removal",
    "Nucleus removal",
    "PFCL (Perfluorocarbon liquid)",
    "Cryotherapy",
    "ARC (Anterior retinal cryotherapy)",
    "VL (Vitreous lavage)",
    "SOR (Silicon oil removal)",
    "Re-SOI",
    "Re-Vit",
    "Lensectomy",
    "AV (Anterior vitrectomy)",
    "SiO (Silicon oil) tap",
    "IV tPA (tissue plasminogen activator)",
    "PST (Posterior subtenon tricort)",
    "PPC (Pars plana capsulotomy)",
    "Phacoemulsification ",
    "Phaco + PCIOL",
    "Phaco + ACIOL",
    "Phaco + SFIOL",
    "Phaco + Glued IOL",
    "ECCE + PCIOL",
    "ECCE + ACIOL",
    "ECCE + SFIOL",
    "ECCE + Glued IOL",
    "SICS",
    "SICS+ PCIOL",
    "SICS+ ACIOL",
    "SICS+ SFIOL",
    "SICS+ Glued IOL",
    "ICCE",
    "ICCE + ACIOL",
    "ICCE + SFIOL",
    "ICCE + Glued IOL",
    "IOL exchange",
    "PCIOL implantation",
    "ACIOL implantation",
    "IOL repositioning",
    "IOL explantation",
    "Intracameral antibiotic injection",
    "IC Moxifloxacin",
    "IC Ganciclovir",
    "IC Vancomycin",
    "IC Ceftazidime",
    "IC Dexamethasone",
    "IC Amikacin",
    "IC Amphotericin B",
    "IC Cotrimazole",
    "IC Clindamycin",
    "IC Foscarnet",
    "IC Methotrexate",
    "IC Voriconazole",
    "IC Avastin",
    "Intracameral air injection",
    "IC C3F8",
    "IC Accentrix",
    "IC Razumab",
    "IC Eylea",
    "IC Pagenax",
    "IC Dexamethasone",
    "Cyclocryotherapy",
    "DLCP (Diode laser cyclophotocoagulation)",
    "ECP (Endoscopic Cyclophotocoagulation)",
    "Trab (Trabeculectomy)",
    "Trabeculotomy",
    "AGV (Ahmed glaucoma valve)",
    "AADI (Aurolab aqueous drainage implant)",
    "Bleb revision",
    "Bleb needling",
    "Paracentesis",
    "Surgical PI (peripheral iridectomy)",
    "AC wash",
    "Peritomy",
    "Conjunctival cyst removal",
    "Pterygium excision with graft",
    "Pterygium excision with conjunctival autograft (CLAU)",
    "Pterygium excision without graft",
    "Conjunctival suture removal",
    "OSSN excision",
    "Conjunctival suture removal",
    "Conjunctival tear repair",
    "Excision of conjunctival granuloma",
    "Conjunctival mass excision",
    "PK (Penetrating keratoplasty)",
    "OPK (Optical penetrating keratoplasty)",
    "TPK (Therapeutic penetrating keratoplasty)",
    "ICL (Implantable collamer lens)",
    "Toric ICL (Implantable collamer lens)",
    "DMEK (Descemet's membrane endothelial keratoplasty)",
    "DSAEK (Descemet's stripping automated endothelial keratoplasty)",
    "DSEK (Descemet's stripping endothelial keratoplasty)",
    "DALK (Deep anterior lamellar keratoplasty)",
    "C3R (Corneal collagen cross-linking with riboflavin)",
    "LASIK",
    "LASEK",
    "Contoura LASIK",
    "Contoura LASIK",
    "iLASIK",
    "SMILE (Small incision lenticule extraction)",
    "Corneal suture removal",
    "PRK (Photorefractive keratectomy)",
    "PTK (Phototherapeutic keratectomy)",
    "RK (Radial keratotomy)",
    "EDTA chelation",
    "Corneal tattooing",
    "Corneal foreign body removal",
    "Corneal debridement",
    "Diamond burr polishing",
    "BCL (Bandage contact lens)",
    "Caterpillar hair removal",
    "Corneal tear repair",
    "Medial tarsorrhaphy",
    "Lateral tarsorrhaphy",
    "Temporary tarsorrhaphy",
    "Permanent tarsorrhaphy",
    "3 snip procedure",
    "DCR (Dacryocystorhinostomy)",
    "External DCR (dacryocystorhinostomy)",
    "Externa DCR (dacryocystorhinostomy) with intubation",
    "Dressing",
    "Nasal DCR (dacryocystorhinostomy)",
    "DCT (Dacryocystectomy)",
    "Fracture repair",
    "Globe repair",
    "Evisceration",
    "Lateral orbitotomy",
    "Lid suture removal",
    "Enucleation",
    "Lid repair",
    "Lid reconstruction",
    "Entropion surgery",
    "Ectropion surgery",
    "Lateral tarsal strip",
    "Temporary punctal plug",
    "Permanent punctal plug",
    "Socket reconstruction",
    "Lacrimal fistulectomy",
    "Probing and syringing",
    "Suture removal",
    "Electroepilation",
    "Squint surgery - 1 muscle",
    "Squint surgery - 2 muscles",
    "Squint surgery - 3 muscles",
    "Squint surgery - 4 muscles",
    "Scleral suture removal",
    "Scleral patch graft",
    "Scleral tear repair",
    "Corneoscleral tear repair",
    "Synechiolysis",
    "Abscess drainage",
    "Bubbling",
    "Cyst removal",
    "IVMP (Intravenous methyl prednisolone)",
    "EUA (Examination under anaesthesia)",
  ];

  compose = (obj) => {
    let txt = obj.eye;
    txt += ": ";
    txt += obj.sxItems.join(" + ");
    if (obj.iolImplants.length) {
      txt += " IOL: ";
      txt += obj.iolImplants.join("/");
    }
    if (obj.implants.length) {
      txt += " Implants: ";
      txt += obj.implants.join(" + ");
    }
    let dd = _.concat(obj.devices, obj.medicines);

    if (dd.length) {
      txt += " with ";
      txt += dd.join(" + ");
    }
    txt += " ↓";
    let an = obj.anaesthesia;
    txt += initials(an);
    txt += " - ";
    txt += obj.when || ""; // TODO(sjd)
    console.log(txt);
    return txt;
  };
  onSave = () => {
    let payload = {
      eye: this.state.eye || this.props.data.eye,
      when: this.state.when || this.props.data.when,
      anaesthesia: this.state.anaesthesia || this.props.data.anaesthesia,
      devices: this.state.devices || this.props.data.devices,
      medicines: this.state.medicines || this.props.data.medicines,
      implants: this.state.implants || this.props.data.implants,
      iolImplants: this.state.iolImplants || this.props.data.iolImplants,
      sxItems: this.state.sxItems || this.props.data.sxItems,
    };

    payload["composeString"] = this.compose(payload);
    this.setState({ composeString: this.compose(payload) });
    this.props.update(this.props.data._id, payload);
    this.setState({
      touched: false,
      anaesthesia: null,
      devices: null,
      medicines: null,
      implants: null,
      iolImplants: null,
      sxItems: null,
    });
  };
  renderSx = () => {
    let data = this.props.data;
    return (
      <ControlGroup vertical>
        <ControlGroup>
          <MutEx
            minimal={false}
            options={["RE", "BE", "LE"]}
            onUpdate={(option) => {
              this.setState({ touched: true, editingSite: false, eye: option });
            }}
            selected={this.state.eye || data.eye}
          />

          <MultiSelectWrapper
            placeholder="Surgeries"
            items={this.listOfSxItems}
            selectedItems={this.state.sxItems || data.sxItems}
            onChange={(items) => {
              this.setState({ touched: true, sxItems: items });
            }}
          />
        </ControlGroup>
        <ControlGroup>
          {this.state.editingAnaesthesia ? (
            <SingleSelectWrapper
              items={this.listOfAnaesthesia}
              onChange={(item) => {
                this.setState({
                  touched: true,
                  editingAnaesthesia: false,
                  anaesthesia: item,
                });
              }}
            />
          ) : (
            <Tag
              minimal
              large
              interactive
              onClick={() => this.setState({ editingAnaesthesia: true })}
            >
              {initials(this.state.anaesthesia || data.anaesthesia)}
            </Tag>
          )}
          <MultiSelectWrapper
            placeholder="Devices"
            items={this.listOfDevices}
            selectedItems={this.state.devices || data.devices}
            onChange={(items) => {
              this.setState({ devices: items, touched: true });
            }}
          />
          <MultiSelectWrapper
            placeholder="Medicines"
            items={this.listOfMedicines}
            selectedItems={this.state.medicines || data.medicines}
            onChange={(items) => {
              this.setState({ medicines: items, touched: true });
            }}
          />
          <MultiSelectWrapper
            placeholder="Implants"
            items={this.listOfImplants}
            selectedItems={this.state.implants || data.implants}
            onChange={(items) => {
              this.setState({ implants: items, touched: true });
            }}
          />
        </ControlGroup>
        <ControlGroup>
          <MultiSelectWrapper
            placeholder="IOL Implants"
            items={this.listOfIols}
            selectedItems={this.state.iolImplants || data.iolImplants}
            onChange={(items) => {
              this.setState({ touched: true, iolImplants: items });
            }}
          />
        </ControlGroup>
        <ControlGroup>
          {this.state.editingWhen ? (
            <SingleSelectWrapper
              items={this.listOfWhens}
              onChange={(item) => {
                this.setState({
                  touched: true,
                  editingWhen: false,
                  when: item,
                });
              }}
            />
          ) : (
            <Tag
              minimal
              large
              interactive
              onClick={() => this.setState({ editingWhen: true })}
            >
              {this.state.when || data.when}
            </Tag>
          )}
          {this.state.touched ? (
            <>
              <Tag large intent="danger" minimal>
                Unsaved Changes!
              </Tag>
              <Button
                text="Save Changes"
                icon="endorsed"
                intent="success"
                onClick={() => this.onSave()}
              />
            </>
          ) : (
            <>
              <Tag
                multiline
                intent="primary"
                minimal
                style={{ width: "500px" }}
              >
                {this.state.composeString}
              </Tag>
              <Button
                icon="trash"
                text="Remove surgery"
                minimal
                intent="danger"
                onClick={() => this.props.remove(this.props.data._id)}
              />
            </>
          )}{" "}
        </ControlGroup>
      </ControlGroup>
    );
  };

  render() {
    return <div style={{ marginBottom: "5px" }}>{this.renderSx()}</div>;
  }
}

export default Surgery;
