import {
  AnchorButton,
  Button,
  Callout,
  Card,
  Collapse,
  ControlGroup,
  Elevation,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import { inject, observer } from "mobx-react";
import React from "react";
import {
  customSortLodash,
  formatAge,
  niceDateTime,
  niceTime,
} from "../fns/util";
import _ from "lodash";
import VisitRecordings from "./VisitRecordings";
import SingleSelectWrapper from "../utils/SingleSelectWrapper";
import { nanoid } from "nanoid";

class ClinicalPatientHeader extends React.Component {
  state = {
    arePanelAddressDetailsOpen: false,
    areCommentsOpen: false,
    enteredComment: "",
  };

  renderPatientDetails = () => {
    let regnTime = new Date(this.props.data.visitData.registrationTime);
    let doneTime = this.props.data.visitData.done
      ? new Date(this.props.data.visitData.doneAt)
      : new Date();

    let diff = doneTime - regnTime;
    let diffInSecs = Math.floor(diff / 1000);
    let hrsDiff = Math.floor(diffInSecs / 3600);
    let minsDiff = Math.floor((diffInSecs - hrsDiff * 3600) / 60);
    let diffStr = `${hrsDiff}h${minsDiff}m`;
    let diffClassName = "lime__bg";
    if (hrsDiff > 1) {
      diffClassName = "rose__bg";
    } else if (minsDiff > 30) {
      diffClassName = "sepia__bg";
    }
    return (
      <div>
        <Card elevation={Elevation.FOUR} className="card__container">
          <div className="flex__push__apart">
            <ControlGroup>
              {this.props.data.visitData.needsAttn ? (
                <Tag className="rose__bg" intent="success" icon="warning-sign">
                  Emergency
                </Tag>
              ) : null}
              <Button
                icon="history"
                onClick={() => this.props.onOpenPastVisitsDrawer()}
              />
              <Tag intent="warning" large>
                {this.props.data.name}
              </Tag>
              <Tag intent="success" className="turquoise__bg">
                {this.props.data.sysPid}
              </Tag>{" "}
              <Tag intent="success" large className="violet__bg">
                {formatAge(this.props.data.dob)}
              </Tag>
              <Tag intent="success" large className="sepia__bg">
                {this.props.data.sex}
              </Tag>
              <Tag intent="success" className="gray5__bg">
                Purpose of Visit:
              </Tag>
              <SingleSelectWrapper
                key={nanoid()}
                isColfax={true}
                showSelectProp={true}
                selectedItem={this.props.data.visitData.purpose}
                placeholder={this.props.data.visitData.purpose}
                width={
                  this.props.data.visitData.purpose &&
                  this.props.data.visitData.purpose.length > 2
                    ? this.props.data.visitData.purpose.length * 1.08 > 15
                      ? "15em"
                      : this.props.data.visitData.purpose.length * 1.08 + "em"
                    : "4em"
                }
                items={[
                  "General",
                  "Routine Check Up",
                  "Post-Operative Visit",
                  "Investigations/Report Review",
                  "Referral",
                  "PMT",
                  "Cornea",
                  "Glaucoma",
                  "Retina",
                  "Pediatric",
                  "Oculoplasty",
                  "Squint",
                  "2nd Opinion",
                  "Emergency",
                  "Follow Up Visit",
                  "2nd Follow Up Visit",
                  "3rd Follow Up Visit",
                  "4th Follow Up Visit",
                  "5th Follow Up Visit",
                  "Recurring Follow Up Visit",
                ]}
                onChange={(item) => {
                  this.props.onUpdateVisitDataProp("purpose", item);
                }}
              />
              <Tag intent="success" className={diffClassName}>
                Waiting: {diffStr}
              </Tag>
              {/*  <Tag intent="success" className="turquoise__bg">
                Number of Prior Visits: 10
              </Tag> */}
            </ControlGroup>
            <ControlGroup>
              <Button
                icon="people"
                onClick={() => this.props.onOpenUsersDrawer()}
              />
              {this.renderLifecycles()}
              <Button
                minimal={!this.state.arePanelAddressDetailsOpen}
                icon={
                  this.state.arePanelAddressDetailsOpen
                    ? "double-chevron-up"
                    : "double-chevron-down"
                }
                onClick={() =>
                  this.setState({
                    arePanelAddressDetailsOpen:
                      !this.state.arePanelAddressDetailsOpen,
                  })
                }
              />
              {/*  <Button
                minimal={!this.state.areCommentsOpen}
                icon="chat"
                onClick={() =>
                  this.setState({
                    areCommentsOpen: !this.state.areCommentsOpen,
                  })
                }
              /> */}
            </ControlGroup>
          </div>
        </Card>
      </div>
    );
  };
  renderPatientAddressDetails = () => {
    return (
      <Collapse isOpen={this.state.arePanelAddressDetailsOpen}>
        <Card elevation={Elevation.FOUR} className="card__container">
          <div className="flex__push__apart">
            <ControlGroup>
              {this.props.data.panels.length > 0 ? (
                <Tag intent="success" large className="lime__bg">
                  Panel{this.props.data.panels.length > 1 ? "s: " : ":"}{" "}
                  {this.props.data.panels.map((x) => x.panel).join(", ")}
                </Tag>
              ) : (
                <Tag intent="success" large className="lime__bg">
                  Self-Paying Patient
                </Tag>
              )}
            </ControlGroup>
            <ControlGroup>
              <Tag
                intent="success"
                large
                className="violet__bg"
                icon="area-of-interest"
              >
                {this.props.data.address}
              </Tag>
              <Tag
                intent="success"
                large
                className="rose__bg"
                icon="mobile-phone"
              >
                {this.props.data.phone}
              </Tag>
            </ControlGroup>
          </div>
        </Card>
      </Collapse>
    );
  };
  renderComments = () => {
    return (
      <Collapse isOpen={this.state.areCommentsOpen}>
        <Card elevation={Elevation.FOUR} className="card__container">
          {this.renderNewComment()}
          {this.renderCommentsForPatient()}
        </Card>
      </Collapse>
    );
  };
  renderCommentsForPatient = () => {
    let comments = this.props.data.comments;
    if (!comments) {
      return null;
    }
    if (!comments.length) {
      return null;
    }
    // sort comments by date
    comments = comments.sort((a, b) => {
      return b.date - a.date;
    });
    return comments.map((each) => {
      return (
        <Callout className="card__container">
          <ControlGroup>
            <Tag intent="success" className="lime__bg">
              {niceDateTime(each.date)} by {each.user}
            </Tag>
          </ControlGroup>
          <p style={{ textAlign: "left" }}>{each.text}</p>
        </Callout>
      );
    });
  };
  renderNewComment = () => {
    return (
      <div>
        <TextArea
          className="colfax"
          fill
          value={this.state.enteredComment}
          onChange={(event) =>
            this.setState({ enteredComment: event.target.value })
          }
        />
        <ControlGroup>
          <Button
            className="colfax"
            icon="comment"
            outlined
            intent="success"
            text="Add New Internal Note/Comment"
            onClick={() => {
              this.props.addComment(this.state.enteredComment);
              this.setState({ enteredComment: "" });
            }}
          />{" "}
          <Tag intent="success" className="lime__bg">
            These notes are only visible to you and your staff.
          </Tag>
        </ControlGroup>
      </div>
    );
  };
  getEventFromLifecycle = (type) => {
    let lifecycleEvents = this.props.data.lifecycleEvents;
    let event = lifecycleEvents.find((each) => each.type === type);
    return event;
  };
  renderLifecycles = () => {
    let sortOrder = [
      "BILLING",
      "HISTORY_DONE",
      "DILATION_DONE",
      "VISION_DONE",
      "VISIT_CHECKOUT",
      "COUNSELLING_DONE",
    ];
    let lifecycleEvents = this.props.data.lifecycleEvents;
    let events = lifecycleEvents.map((each) => each.type);
    let sortedEvents = customSortLodash(events, sortOrder);
    let latestEvent = _.last(sortedEvents);
    if (latestEvent === "BILLING") {
      return (
        <ControlGroup>
          <Tag minimal icon="credit-card">
            {niceTime(this.getEventFromLifecycle("BILLING").ts)}
          </Tag>
          <Button
            intent="success"
            className="forest__bg colfax"
            icon="tint"
            text="Dilation"
            onClick={() => this.props.onLifecycleUpdate("DILATION_DONE")}
          />
          <Button
            icon="eye-open"
            text="Vision"
            intent="success"
            className="cerulean__bg colfax"
            onClick={() => this.props.onLifecycleUpdate("VISION_DONE")}
          />
        </ControlGroup>
      );
    }
    if (latestEvent === "DILATION_DONE") {
      return (
        <ControlGroup>
          <Tag minimal icon="credit-card">
            {niceTime(this.getEventFromLifecycle("BILLING").ts)}
          </Tag>
          <Tag minimal icon="tint">
            {niceTime(this.getEventFromLifecycle("DILATION_DONE").ts)}
          </Tag>
          <Button
            icon="eye-open"
            text="Vision"
            intent="success"
            className="cerulean__bg colfax"
            onClick={() => this.props.onLifecycleUpdate("VISION_DONE")}
          />
        </ControlGroup>
      );
    }
    if (latestEvent === "VISION_DONE") {
      return (
        <ControlGroup>
          <Tag minimal icon="credit-card">
            {niceTime(this.getEventFromLifecycle("BILLING").ts)}
          </Tag>
          {sortedEvents.includes("DILATION_DONE") && (
            <Tag minimal icon="tint">
              {niceTime(this.getEventFromLifecycle("DILATION_DONE").ts)}
            </Tag>
          )}
          <AnchorButton
            text={niceTime(this.getEventFromLifecycle("VISION_DONE").ts)}
            icon="eye-open"
            href={`https://print.newchart.care/glass2/${this.props.data.visitId}`}
            target="_blank"
            className="colfax green__bg"
            intent="success"
            rightIcon="document-share"
          />
          <Button
            intent="success"
            className="cerulean__bg colfax"
            icon="prescription"
            text="Check Out"
            onClick={() => this.props.onLifecycleUpdate("VISIT_CHECKOUT")}
          />
        </ControlGroup>
      );
    }
    if (latestEvent === "VISIT_CHECKOUT") {
      return (
        <ControlGroup>
          <Tag minimal icon="credit-card">
            {niceTime(this.getEventFromLifecycle("BILLING").ts)}
          </Tag>
          {sortedEvents.includes("DILATION_DONE") && (
            <Tag minimal icon="tint">
              {niceTime(this.getEventFromLifecycle("DILATION_DONE").ts)}
            </Tag>
          )}
          {/* <Tag minimal icon="eye-open">
            {niceTime(this.getEventFromLifecycle("VISION_DONE").ts)}
          </Tag> */}
          <AnchorButton
            text={niceTime(this.getEventFromLifecycle("VISION_DONE").ts)}
            icon="eye-open"
            href={`https://print.newchart.care/glass2/${this.props.data.visitId}`}
            target="_blank"
            className="colfax green__bg"
            intent="success"
            rightIcon="document-share"
          />
          <AnchorButton
            text={niceTime(this.getEventFromLifecycle("VISIT_CHECKOUT").ts)}
            icon="prescription"
            href={
              this.props.authStore.prefix == "VisionTree"
                ? `https://print.newchart.care/vt/rx2/${this.props.data.visitId}`
                : `https://print.newchart.care/rx2/${this.props.data.visitId}`
            }
            target="_blank"
            className="colfax rose__bg"
            intent="success"
            rightIcon="document-share"
          />
          <Button
            icon="chat"
            text="Counselling"
            intent="success"
            className="cerulean__bg colfax"
            onClick={() => this.props.onLifecycleUpdate("COUNSELLING_DONE")}
          />
        </ControlGroup>
      );
    }
    if (latestEvent === "COUNSELLING_DONE") {
      return (
        <ControlGroup>
          <Tag minimal icon="credit-card">
            {niceTime(this.getEventFromLifecycle("BILLING").ts)}
          </Tag>
          {sortedEvents.includes("DILATION_DONE") && (
            <Tag minimal icon="tint">
              {niceTime(this.getEventFromLifecycle("DILATION_DONE").ts)}
            </Tag>
          )}
          <AnchorButton
            text={niceTime(this.getEventFromLifecycle("VISION_DONE").ts)}
            icon="eye-open"
            href={`https://print.newchart.care/glass2/${this.props.data.visitId}`}
            target="_blank"
            className="colfax green__bg"
            intent="success"
            rightIcon="document-share"
          />
          <AnchorButton
            intent="success"
            text={niceTime(this.getEventFromLifecycle("VISIT_CHECKOUT").ts)}
            icon="prescription"
            href={
              this.props.authStore.prefix == "VisionTree"
                ? `https://print.newchart.care/vt/rx2/${this.props.data.visitId}`
                : `https://print.newchart.care/rx2/${this.props.data.visitId}`
            }
            target="_blank"
            className="colfax rose__bg"
            rightIcon="document-share"
          />
          <Tag minimal icon="chat">
            {niceTime(this.getEventFromLifecycle("COUNSELLING_DONE").ts)}
          </Tag>
        </ControlGroup>
      );
    }
  };
  renderVisitRecordings = () => {
    return (
      <div>
        <Collapse isOpen={this.state.arePanelAddressDetailsOpen}>
          <Card elevation={Elevation.FOUR} className="card__container">
            <VisitRecordings visitId={this.props.data.visitId} />
          </Card>
        </Collapse>
      </div>
    );
  };
  render() {
    return (
      <div className="colfax">
        {this.renderPatientDetails()}
        {this.renderPatientAddressDetails()}
        {this.renderVisitRecordings()}
      </div>
    );
  }
}

export default inject(
  "bookingStore",
  "authStore",
  "billingStore",
  "patientStore",
  "listStore"
)(observer(ClinicalPatientHeader));
